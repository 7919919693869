import React from 'react';

import { StyledFieldSection } from './styled/FieldSection.styled';

/* 
    This is reusable component wrapper for input fields.
    Used in modal form and other forms. Check the usage in used files for more specific example.
*/
const FieldsSection = ({ children }) => {
    return <StyledFieldSection>{children}</StyledFieldSection>;
};

export { FieldsSection };
