import React from 'react';

import { AdminDashboardTabs } from './AdminDashboardTabs';
import { StyledDashboardHeader } from './styled/DashboardHeader.styled';

const DashboardHeader = ({ history }) => {
    return (
        <StyledDashboardHeader>
            <div className="admin-dashboard-header-wrapper">
                <div className="nav-list-items">
                    <AdminDashboardTabs history={history} />
                </div>
            </div>
        </StyledDashboardHeader>
    );
};

export { DashboardHeader };
