import React, { useContext, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import TabItem from 'shared/components/DashboardTabs/TabItem';
import PermissionsContext from 'shared/utils/permissionStore';

import { StyledAllTabs } from './styled/AllTabs.styled';
import Tab from './Tab';
import { FilterProvider } from '../contexts/FilterContext';
import {
    shouldRequestsPageTabBeDisabled,
    setDefaultTabRequestsPage
} from '../utils/requestsPermissionsHelper';

const DEFAULT_TAB_INDEX = 0;

const AllTabs = ({ history }) => {
    const counts = useSelector(state => state.request.totalCounts);
    const [activeTabIndex, setActiveTabIndex] = useState(DEFAULT_TAB_INDEX);
    const [redirectFilterState, setRedirectFilterState] = useState({});
    const permissions = useContext(PermissionsContext).permissions;

    const {
        location: { state }
    } = history;

    useEffect(() => {
        if (!isEmpty(state)) {
            const { subsetTab, filters } = state;
            setActiveTabIndex(
                setDefaultTabRequestsPage(subsetTab, permissions)
            );
            setRedirectFilterState(filters);
            window.scrollTo(0, 0);
        } else {
            setActiveTabIndex(setDefaultTabRequestsPage(0, permissions));
        }
        return () => {
            setActiveTabIndex(null);
            setRedirectFilterState({});
            window.history.replaceState({}, document.title);
        };
    }, []);

    const TABS = [
        {
            label: 'Pending',
            requestStatusId: 1,
            totalCount: counts.pending,
            disabled: shouldRequestsPageTabBeDisabled,
            defaultSort: [{ id: 'entryDate', desc: true }]
        },
        {
            label: 'Approved',
            requestStatusId: 2,
            totalCount: counts.approved,
            disabled: shouldRequestsPageTabBeDisabled,
            defaultSort: [{ id: 'entryDate', desc: true }]
        },
        {
            label: 'Delivered',
            requestStatusId: 4,
            totalCount: counts.delivered,
            disabled: shouldRequestsPageTabBeDisabled,
            defaultSort: [{ id: 'entryDate', desc: true }]
        },
        {
            label: 'Rejected',
            requestStatusId: 3,
            totalCount: counts.rejected,
            disabled: shouldRequestsPageTabBeDisabled,
            defaultSort: [{ id: 'entryDate', desc: true }]
        },
        {
            label: 'Cancelled',
            requestStatusId: 5,
            totalCount: counts.cancelled,
            disabled: shouldRequestsPageTabBeDisabled,
            defaultSort: [{ id: 'entryDate', desc: true }]
        }
    ];

    const handleTabChange = tabIndex => {
        setActiveTabIndex(tabIndex);
    };

    return (
        <StyledAllTabs>
            <div className="tabs">
                <ol className="tab-list">
                    {TABS.map((tab, index) => {
                        return (
                            <TabItem
                                activeTab={`${TABS[activeTabIndex].label} (${TABS[activeTabIndex].totalCount})`}
                                key={tab.requestStatusId}
                                label={`${tab.label} (${tab.totalCount})`}
                                onClick={() => handleTabChange(index)}
                                redirectFilterState={redirectFilterState}
                                disabled={tab.disabled(tab.label, permissions)}
                            />
                        );
                    })}
                </ol>
                <div className="tab-content">
                    {TABS.map((tab, index) => {
                        if (index !== activeTabIndex) return undefined;
                        return (
                            <FilterProvider key={tab.requestStatusId}>
                                <Tab
                                    permissions={permissions}
                                    requestStatusId={tab.requestStatusId}
                                    tabName={tab.label.toLowerCase()}
                                    defaultSort={tab.defaultSort}
                                    disabled={tab.disabled(
                                        tab.label,
                                        permissions
                                    )}
                                />
                            </FilterProvider>
                        );
                    })}
                </div>
            </div>
        </StyledAllTabs>
    );
};

export default AllTabs;
