import { isEmpty } from 'lodash';

export const normalizeRequestData = requestData => {
    let temptModel = requestData;
    for (const key in temptModel) {
        temptModel[key] = isEmpty(temptModel?.[key]?.toString())
            ? null
            : temptModel[key];
    }

    return temptModel;
};
