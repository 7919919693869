import React from 'react';

export const SelectIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
        >
            <rect
                width="17"
                height="17"
                x="0.5"
                y="0.5"
                fill="#fff"
                rx="4.5"
            ></rect>
            <path
                stroke="#03A8F5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6 7.5l3 3 3-3"
            ></path>
            <rect
                width="17"
                height="17"
                x="0.5"
                y="0.5"
                stroke="#03A8F5"
                rx="4.5"
            ></rect>
        </svg>
    );
};

export default SelectIcon;
