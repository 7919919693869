export const namesMapping = {
    mentormaterId: {
        label: 'Type of MentorMater',
        name: 'mentormaterId'
    },
    trialPeriod: { label: 'Trial Period', name: 'trialPeriod' },
    workingTime: { label: 'Working Time', name: 'workingTime' },
    locationId: { label: 'Dedicated Office', name: 'locationId' },
    workLocation: { label: 'Work Location', name: 'workLocation' },
    reportsToId: { label: 'Reports to', name: 'reportsToId' },
    levelId: { label: 'Level', name: 'levelId' },
    departmentId: { label: 'Department', name: 'departmentId' },
    typeOfWorkId: { label: 'Type of Work', name: 'typeOfWorkId' },
    positionTitle: { label: 'Title', name: '' },
    hiringChannelId: {
        label: 'Hiring Channel',
        name: 'hiringChannelId'
    },
    hiringChannelText: {
        label: 'Hiring Channel Text',
        name: 'hiringChannelText'
    },
    voluntaryTypeId: { label: 'Voluntary', name: 'voluntaryTypeId' },
    reasonForLeavingId: {
        label: 'Reason for leaving',
        name: 'reasonForLeavingId'
    },
    leaverTypeId: { label: 'Leaver type', name: 'leaverTypeId' },
    industryId: { label: 'Industry', name: 'industryId' }
};
