import { Link } from 'react-router-dom';

import { StyledNavigationItem } from './styled/NavigationItem.styled';

export const NavigationItem = ({ route, label, active, disabled }) => {
    let className = 'nav-item';

    if (active) {
        className += ' nav-item-active';
    }

    if (disabled) {
        className += ' nav-item-disabled';
    }

    return (
        <StyledNavigationItem>
            <div>
                <div className={className}>
                    <div className="label">
                        {!disabled && <Link to={route}>{label}</Link>}
                        {disabled && <div>{label}</div>}
                    </div>
                </div>
            </div>
        </StyledNavigationItem>
    );
    s;
};
