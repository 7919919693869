import { CloseIcon } from 'shared/theme/assets/icons/CloseIcon';

import { StyledSelectedOption } from './styled/SelectedOption.styled';

const SelectedOption = ({
    item,
    onChange,
    options,
    handleValues,
    values,
    value
}) => {
    const handleClose = e => {
        const permissionLabel =
            e.target.parentNode.parentNode.parentNode.firstElementChild
                .innerText;

        const filteredOptions = options.filter(
            o => o.label !== permissionLabel
        );
        const filteredValues = values.filter(val => val !== value);

        handleValues('permissions', filteredValues);
        onChange(filteredOptions);
    };

    return (
        <StyledSelectedOption>
            <div className="selected-item" key={item.value}>
                <div className="name">{item.label}</div>
                <div className="icon-holder-inbound">
                    <CloseIcon onClick={handleClose} />
                </div>
            </div>
        </StyledSelectedOption>
    );
};

export { SelectedOption };
