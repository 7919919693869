import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const uploadPicture = async (imageFile, personId) => {
    const extension = imageFile.type.split('/')[1];
    const formFile = new FormData();
    formFile.append('file', imageFile);
    formFile.append('personId', personId);
    formFile.append('extension', extension);

    const data = await httpService.post(url.UPLOAD_PICTURE(), formFile, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return data.data;
};

export default { uploadPicture };
