import styled from 'styled-components';

const StyledFooter = styled.div`
    margin-left: ${({ className }) => className === 'special' && '-90px'};
    margin-right: ${({ className }) => className === 'special' && '-90px'};
    margin-top: 35px;
    border-top: 1px solid var(--whiteGray);
    display: flex;
    justify-content: space-between;
    padding: 28px 90px 27px;
    .footer-right-section {
        display: flex;
        div {
            padding-right: 34px;
        }
        margin-right: -34px;
    }

    @media only screen and (min-width: 2559px) {
        position: ${({ className }) =>
            className === 'special' ? 'relative' : 'absolute'};
        bottom: 0;
        width: 100%;
    }
`;

export { StyledFooter };
