export const getFormData = (object, rootName, ignoreList, existingFormData) => {
    let formData = existingFormData ?? new FormData();

    const appendFormData = (data, root) => {
        if (!shouldIgnore(root)) {
            root = root || '';
            if (data instanceof File) {
                formData.append(root, data);
            } else if (Array.isArray(data)) {
                data.forEach((item, index) =>
                    appendFormData(item, `${root}[${index}]`)
                );
            } else if (typeof data === 'object' && data) {
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            appendFormData(data[key], key);
                        } else {
                            appendFormData(data[key], `${root}.${key}`);
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data);
                }
            }
        }
    };

    const shouldIgnore = root => {
        return (
            Array.isArray(ignoreList) &&
            ignoreList.some(function (x) {
                return x === root;
            })
        );
    };

    appendFormData(object, rootName);

    return formData;
};
