import moment from 'moment';

const excludedFields = [
    'startDate',
    'endDate',
    'reasonForLeavingId',
    'leaverTypeId',
    'voluntaryTypeId',
    'hiringChannelText'
];

export const shouldShowTakesEffectModal = (initialValues, inputName) => {
    const startDateValue = initialValues['startDate']?.toString() ?? '';
    const dateNotInFuture =
        startDateValue && moment().endOf('day') > moment(startDateValue);
    return (
        !excludedFields.includes(inputName) &&
        (dateNotInFuture || !startDateValue)
    );
};
