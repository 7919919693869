import { groupBy, orderBy } from 'lodash';
import moment from 'moment';

const FORMAT = 'MM/DD/YYYY';

export const mapData = (historicalItems, dropDownState) => {
    const {
        typesOfMentormater,
        locations,
        reasonsForLeaving,
        leaverTypes,
        voluntaryTypes,
        levels,
        typesOfWork,
        hiringChannels,
        departments,
        industries,
        managers,
        positions
    } = dropDownState;

    const result = historicalItems.map((item, index) => {
        switch (item.changedPropertyName) {
            case 'Name':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Latin Name',
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'Name'
                };
            case 'NameBg':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Bulgarian Name',
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'NameBg'
                };
            case 'LevelId':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Level',
                    oldValue:
                        item.oldValue !== null
                            ? levels.find(x => x.id == item.oldValue)
                                  .description
                            : null,
                    newValue:
                        item.newValue !== null
                            ? levels.find(x => x.id == item.newValue)
                                  .description
                            : null,
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'LevelId'
                };
            case 'PositionId':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Position',
                    oldValue:
                        item.oldValue !== null
                            ? positions.find(x => x.id == item.oldValue)
                                  .description
                            : null,
                    newValue:
                        item.newValue != null
                            ? positions.find(x => x.id == item.newValue)
                                  .description
                            : null,
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'PositionId'
                };
            case 'DepartmentId':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Department',
                    oldValue:
                        item.oldValue !== null
                            ? departments.find(x => x.id == item.oldValue)
                                  .description
                            : null,
                    newValue:
                        item.newValue != null
                            ? departments.find(x => x.id == item.newValue)
                                  .description
                            : null,
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'DepartmentId'
                };
            case 'IndustryId':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Industry',
                    oldValue:
                        item.oldValue !== null
                            ? industries.find(x => x.id == item.oldValue)
                                  .description
                            : null,
                    newValue:
                        item.newValue != null
                            ? industries.find(x => x.id == item.newValue)
                                  .description
                            : null,
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'IndustryId'
                };
            case 'LocationId':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Dedicated Office',
                    oldValue:
                        item.oldValue !== null
                            ? locations.find(x => x.id == item.oldValue)
                                  .description
                            : null,
                    newValue:
                        item.newValue !== null
                            ? locations.find(x => x.id == item.newValue)
                                  .description
                            : null,
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'LocationId'
                };
            case 'MentormaterId':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Type of Mentormater',
                    oldValue:
                        item.oldValue !== null
                            ? typesOfMentormater.find(
                                  x => x.id == item.oldValue
                              ).description
                            : null,
                    newValue:
                        item.newValue != null
                            ? typesOfMentormater.find(
                                  x => x.id == item.newValue
                              ).description
                            : null,
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'MentormaterId'
                };
            case 'LeaverTypeId':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Leaver Type',
                    oldValue:
                        item.oldValue !== null
                            ? leaverTypes.find(x => x.id == item.oldValue)
                                  .description
                            : null,
                    newValue:
                        item.newValue != null
                            ? leaverTypes.find(x => x.id == item.newValue)
                                  .description
                            : null,
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'LeaverTypeId'
                };
            case 'VoluntaryTypeId':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Voluntary',
                    oldValue:
                        item.oldValue !== null
                            ? voluntaryTypes.find(x => x.id == item.oldValue)
                                  .description
                            : null,
                    newValue:
                        item.newValue != null
                            ? voluntaryTypes.find(x => x.id == item.newValue)
                                  .description
                            : null,
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'VoluntaryTypeId'
                };
            case 'TypeOfWorkId':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Type Of Work',
                    oldValue:
                        item.oldValue !== null
                            ? typesOfWork.find(x => x.id == item.oldValue)
                                  .description
                            : null,
                    newValue:
                        item.newValue != null
                            ? typesOfWork.find(x => x.id == item.newValue)
                                  .description
                            : null,
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'TypeOfWorkId'
                };
            case 'ProfileCreation':
                return generateDateData(
                    item,
                    'Profile Creation',
                    'ProfileCreation'
                );
            case 'EndDate':
                return generateDateData(item, 'End Date', 'EndDate');
            case 'ReportsToId':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Reports To',
                    oldValue:
                        item.oldValue !== null
                            ? managers.find(x => x.id == item.oldValue)
                                  .description
                            : null,
                    newValue:
                        item.newValue != null
                            ? managers.find(x => x.id == item.newValue)
                                  .description
                            : null,
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'ReportsToId'
                };
            case 'HiringChannelId':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Hiring Channel',
                    oldValue:
                        item.oldValue !== null
                            ? hiringChannels.find(x => x.id == item.oldValue)
                                  .description
                            : null,
                    newValue:
                        item.newValue != null
                            ? hiringChannels.find(x => x.id == item.newValue)
                                  .description
                            : null,
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'HiringChannelId'
                };
            case 'ReasonForLeavingId':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Main Reason for Leaving',
                    oldValue:
                        item.oldValue !== null
                            ? reasonsForLeaving.find(x => x.id == item.oldValue)
                                  .description
                            : null,
                    newValue:
                        item.newValue != null
                            ? reasonsForLeaving.find(x => x.id == item.newValue)
                                  .description
                            : null,
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'ReasonForLeavingId'
                };
            case 'TrialPeriod':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Trial Period',
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'TrialPeriod'
                };
            case 'WorkingTime':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Working Time',
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'WorkingTime'
                };
            case 'WorkLocation':
                return {
                    ...item,
                    isSpecial: false,
                    changedPropertyName: 'Work Location',
                    editValue: item.newValue,
                    oldEditValue: item.oldValue,
                    initialPropName: 'WorkLocation'
                };
            case 'OnLeave':
                return {
                    ...item,
                    isSpecial: false,
                    editValue: item.forPeriod,
                    changedPropertyName: 'On Leave',
                    initialPropName: 'OnLeave'
                };
            case 'Special':
                return {
                    ...item,
                    isSpecial: true,
                    changedPropertyName: 'End Date - Changes'
                };
        }
    });
    return result;
};

export const groupByYear = data => {
    const nonNullItems = data.filter(item => item !== undefined); //additional handling if something wrong is returned by BE

    // group the existing items by their year ('2020'/'2021'/'2022' etc)
    const groupedByYear = groupBy(nonNullItems, function (item) {
        return item?.changedDate.substring(0, 4);
    });

    const groupedItems = [];
    for (const property in groupedByYear) {
        const propItems = groupedByYear[property];
        groupedItems.push({ title: property, timelineItems: propItems });
    }

    const sorted = orderBy(groupedItems, gi => gi.title, ['desc']);

    return sorted;
};

export const HISTORICAL_DATA_OPTIONS = [
    { label: 'All', value: 'AllId' },
    { label: 'Name in English', value: 'Name' },
    { label: 'Name in Bulgarian', value: 'NameBg' },
    { label: 'Level', value: 'LevelId' },
    { label: 'Position Title', value: 'PositionId' },
    { label: 'Department', value: 'DepartmentId' },
    { label: 'Dedicated Office', value: 'LocationId' },
    { label: 'Work Location', value: 'WorkLocation' },
    { label: 'Type of MentorMater', value: 'MentormaterId' },
    { label: 'Type of Work', value: 'TypeOfWorkId' },
    { label: 'Last Day', value: 'EndDate' },
    { label: 'Reports To', value: 'ReportsToId' },
    { label: 'Hiring Channel', value: 'HiringChannelId' },
    { label: 'On Leave', value: 'OnLeave' }
];

export const reduxInitialState = data => {
    let newFormedData = [];
    let counter = 0;
    let concatData = {};
    for (let i = data.length - 1; i >= 0; i--) {
        let item = data[i];
        if (counter > 0) {
            item = {
                ...item,
                sortOrder: sortValuesEndDate[item.changedPropertyName]
            };
            concatData = {
                ...concatData,
                items: [...concatData.items, item]
            };
            if (counter === 1) {
                concatData = {
                    ...concatData,
                    items: [
                        ...concatData.items.sort(
                            (a, b) => a.sortOrder - b.sortOrder
                        )
                    ]
                };
                newFormedData.unshift(concatData);
            }
            counter--;
            continue;
        } else {
            concatData = {};
        }

        if (endDatePropChanges.includes(item.changedPropertyName)) {
            concatData = {
                changedDate: item.changedDate,
                period: item.period,
                changedPropertyName: 'Special',
                items: [
                    {
                        ...item,
                        sortOrder: sortValuesEndDate[item.changedPropertyName]
                    }
                ]
            };
            counter = 3;
            continue;
        }

        newFormedData.unshift(item);
    }

    return newFormedData;
};

const generateDateData = (item, changedPropertyName, initialPropName) => {
    return {
        ...item,
        isSpecial: false,
        changedPropertyName: changedPropertyName,
        oldValue:
            item.oldValue !== null
                ? moment(item.oldValue).format(FORMAT)
                : null,
        newValue:
            item.newValue !== null
                ? moment(item.newValue).format(FORMAT)
                : null,
        editValue: item.newValue,
        oldEditValue: item.oldValue,
        initialPropName: initialPropName
    };
};

export const endDatePropChanges = [
    'EndDate',
    'ReasonForLeavingId',
    'LeaverTypeId',
    'VoluntaryTypeId'
];
const sortValuesEndDate = {
    ['EndDate']: 1,
    ['ReasonForLeavingId']: 2,
    ['LeaverTypeId']: 3,
    ['VoluntaryTypeId']: 4
};
