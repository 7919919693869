import { isEmpty } from 'lodash';
import moment from 'moment';

const FORMAT = 'MM/DD/YYYY';

export const formatDateByTimezone = date => {
    return !isEmpty(date) ? moment(date).format(FORMAT) : '';
};

export const getAbsoluteMonths = date => {
    //if the date is null use the current moment for the calculation
    let months = isEmpty(date)
        ? Number(moment().format('MM'))
        : Number(moment(date).format('MM'));
    let years = isEmpty(date)
        ? Number(moment().format('YYYY'))
        : Number(moment(date).format('YYYY'));
    return months + years * 12;
};

export const calculateOverallWorkingPeriod = workingPeriods => {
    let tempPeriods = [];
    workingPeriods.map(item => {
        const start = !isEmpty(item.startDate)
            ? moment(item.startDate)
            : moment();
        const end = !isEmpty(item.leaveDate)
            ? moment(item.leaveDate)
            : moment();

        const months = end.diff(start, 'months');
        const duration = moment.duration(months, 'months');

        tempPeriods.push({
            years: duration._data.years,
            months: duration._data.months
        });
    });

    let flattenedPeriods = tempPeriods.map((period, i) =>
        period.months === 12
            ? {
                  years: checkAndSetNegativeToZero((period.years += 1)),
                  months: 0
              }
            : {
                  years: checkAndSetNegativeToZero(period.years),
                  months: checkAndSetNegativeToZero(period.months)
              }
    );

    let sum = flattenedPeriods.reduce(
        (a, b) => {
            return {
                years: a.years + b.years + (a.months + b.months > 12 ? 1 : 0),
                months: (a.months + b.months) % 12
            };
        },
        { years: 0, months: 0 }
    );

    // final check
    if (sum.months === 12) {
        sum.years += 1;
        sum.months = 0;
    }

    return `${sum.years} y ${sum.months} mo`;
};

const checkAndSetNegativeToZero = number => {
    return number < 0 ? 0 : number;
};

export const calculateAndFormatTimeDifference = (startDate, endDate) => {
    //if the given value is null we'll default to moment() to avoid inaccurate calculations
    const start = !isEmpty(startDate) ? moment(startDate) : moment();
    const end = !isEmpty(endDate) ? moment(endDate) : moment();

    const months = end.diff(start, 'months');
    const duration = moment.duration(months, 'months');

    let res = ``;
    if (duration._data.years > 0) {
        res += `${duration._data.years} y`;
    }
    res += ` ${checkAndSetNegativeToZero(duration._data.months)} mo`;
    return res;
};

export const isInTrial = workingPeriod => {
    const { leaveDate, probationPeriodEnd } = workingPeriod;

    const format = 'YYYY-MM-DD';
    const formattedLeaveDate = isEmpty(leaveDate)
        ? null
        : moment(leaveDate).format(format);
    const formattedProbationDate = isEmpty(probationPeriodEnd)
        ? null
        : moment(probationPeriodEnd).format(format);
    const formattedToday = moment().format(format);

    //if there is no probation period, we should not display
    if (formattedProbationDate === null) {
        return false;
    }

    //person not left
    if (formattedLeaveDate === null) {
        //probationPeriodEnd < today --- probation has ended
        //if probation period has ended before today, we should not display
        if (moment(formattedProbationDate).isBefore(formattedToday)) {
            return false;
        }
        //probationPeriodEnd >= today --- probation has not ended
        //if probation period will end in future or today, we should display
        else {
            return true;
        }
    }
    //person left
    else {
        //if leaveDate is in future or today
        if (moment(formattedLeaveDate).isSameOrAfter(formattedToday)) {
            //probationPeriodEnd < today --- probation has ended
            //if probation period has ended before today, we should not display
            if (moment(formattedProbationDate).isBefore(formattedToday)) {
                return false;
            }
            //probationPeriodEnd >= today --- probation has not ended
            //if probation period will end in future or today, we should display
            else {
                return true;
            }
        }
        //if leaveDate is in the past, we do not show trial at all
        else {
            return false;
        }
    }
};

export const calculatePeriodInMonths = (startDate, endDate) => {
    return moment(new Date(endDate)).diff(
        moment(new Date(startDate)),
        'months'
    );
};

export const isFutureDate = date => {
    return moment(new Date(date)).isAfter();
};

export const isPastDate = date => {
    return moment(new Date(date)).isBefore();
};

export const calculateOverallOnLeavePeriod = workingPeriods => {
    return (
        workingPeriods.reduce((overAllTime, workingPeriod) => {
            const overAllTimeForWorkingPeriod =
                workingPeriod.onLeavePeriods.reduce(
                    (overAllTimeForWorkingPeriod, onLeavePeriod) => {
                        if (
                            isFutureDate(onLeavePeriod.startDate) &&
                            !onLeavePeriod.endDate
                        )
                            return 0;
                        const period = calculatePeriodInMonths(
                            onLeavePeriod.startDate,
                            onLeavePeriod.endDate || moment(new Date())
                        );
                        return overAllTimeForWorkingPeriod + period;
                    },
                    0
                );
            return overAllTime + overAllTimeForWorkingPeriod;
        }, 0) + ' mo'
    );
};

export const getLatestEndDate = workingPeriods => {
    const endDates = workingPeriods
        .map(workingPeriod => {
            if (!isEmpty(workingPeriod.endDate))
                return moment(workingPeriod.endDate);
        })
        .filter(Boolean);

    return moment.max(endDates).format('YYYY-MM-DD');
};
