import httpService from 'shared/services/httpService';

import url from '../../apiUrls';

const getRequests = async params => {
    const data = await httpService.get(url.GET_REQUESTS(), { params });
    return data.data;
};

const getRequestCounts = async () => {
    const data = await httpService.get(url.GET_REQUEST_COUNTS());
    return data.data;
};

const patchRequest = async (benefitRequestId, requestData) => {
    const data = await httpService.patch(
        url.UPDATE_REQUESTS(benefitRequestId),
        requestData
    );
    return data.data;
};

const getBenefitRequestMessages = async () => {
    const { data } = await httpService.get(url.GET_BENEFIT_REQUEST_MESSAGES());
    return data;
};

export default {
    getRequests,
    patchRequest,
    getRequestCounts,
    getBenefitRequestMessages
};
