import React, { useContext } from 'react';

import {
    setDefaultTab,
    shouldTabBeDisabled
} from 'reports/utils/BenefitReport/reportsPermissionHelper';
import PermissionsContext from 'shared/utils/permissionStore';

import BenefitReportTab from './BenefitReportTab';
import { NewReportTab } from './NewReportTab';
import { StyledAllTabs } from './styled/AllTabs.styled';
import TabsWrapper from '../../shared/components/DashboardTabs/TabsWrapper';

export const AllTabs = ({ history }) => {
    const permissions = useContext(PermissionsContext).permissions;

    const defaultTab = setDefaultTab(permissions);
    const TABS = [
        {
            label: 'All',
            value: 1,
            disabled: shouldTabBeDisabled,
            child: props => (
                <NewReportTab
                    reportElement={props.element}
                    history={props.history}
                />
            )
        },
        {
            label: 'Benefits',
            value: 2,
            disabled: shouldTabBeDisabled,
            child: props => <BenefitReportTab />
        },
        {
            label: 'PTO',
            value: 3,
            disabled: shouldTabBeDisabled,
            child: props => <></>
        },
        {
            label: 'WFH',
            value: 4,
            disabled: shouldTabBeDisabled,
            child: props => <></>
        },
        {
            label: 'Annual Reviews',
            value: 5,
            disabled: shouldTabBeDisabled,
            child: props => <></>
        }
    ];

    const handleTabChange = () => {};

    const tabElements = TABS.map((element, i) => {
        return (
            <div
                key={i}
                label={`${element.label}`}
                disabled={element.disabled(element.label, permissions)}
            >
                {element.child({ element, history })}
            </div>
        );
    });

    return (
        <StyledAllTabs>
            <TabsWrapper
                activeItemIndex={defaultTab}
                handleTabChange={handleTabChange}
            >
                {tabElements}
            </TabsWrapper>
        </StyledAllTabs>
    );
};
