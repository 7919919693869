import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import moment from 'moment';
import { getLatestEndDate } from 'people/utils/dateHelpers';
import {
    canAddWorkingPeriod,
    getDisabledClassNameAddWorkingPeriod
} from 'people/utils/peoplePermissionsHelper';
import { useDispatch, useSelector } from 'react-redux';

import { StyledMainInformation } from './styled/MainInformation.styled';
import { WorkingPeriodBody } from './WorkingPeriodBody';
import { fetchMainInformationDropDowns } from '../../../../redux/slices/dropdown';
import {
    addWorkingPeriod,
    fetchWorkingPeriods,
    resetWorkingPeriodState
} from '../../../../redux/slices/workingPeriod';
import { CustomButton } from '../../../../shared/components/Button';
import { Spinner } from '../../../../shared/components/Spinner';
import { ClockIcon } from '../../../../shared/theme/assets/icons/ClockIcon';
import { DetailsMainContainer } from '../../shared/DetailsMainContainer';
import { HistoricalDataModal } from '../HistoricalData/HistoricalDataModal';

const MainInformation = ({
    personId,
    historicalModalOpen = true,
    permissions
}) => {
    const [allowAddWorkingPeriod, setAllowAddWorkingPeriod] = useState([]);
    const [allowDelete, setAllowDelete] = useState(false);
    const [isHistoricalModalOpen, setIsHistoricalModalOpen] =
        useState(historicalModalOpen);
    const { workingPeriods, isLoading } = useSelector(
        state => state.workingPeriods
    );
    const dropDownState = useSelector(state => state.dropdown);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchWorkingPeriods(personId));
        dispatch(fetchMainInformationDropDowns());
        return () => {
            dispatch(resetWorkingPeriodState());
        };
    }, []);
    useEffect(() => {
        if (workingPeriods.length > 0) {
            setAllowAddWorkingPeriod(
                workingPeriods.map(wp => !isEmpty(wp.endDate))
            );
        }
        /** Allow delete only if there are more than one working periods. */
        if (workingPeriods.length > 1) {
            setAllowDelete(true);
        } else {
            setAllowDelete(false);
        }
    }, [workingPeriods]);

    const renderWorkingPeriods = (workingPeriod, index) => {
        const workingPeriodsCount = workingPeriods.length;

        let previousWorkingPeriodEndDate = null;
        if (workingPeriodsCount > 1 && index < workingPeriodsCount - 1) {
            const nextIndex = index + 1;
            previousWorkingPeriodEndDate = workingPeriods[nextIndex].endDate;
        }

        let nextWorkingPeriodStartDate = null;
        if (workingPeriodsCount > 1 && index !== 0) {
            const previousIndex = index - 1;
            nextWorkingPeriodStartDate =
                workingPeriods[previousIndex].startDate;
        }

        return (
            <WorkingPeriodBody
                personId={personId}
                initialValues={workingPeriod}
                isSectionOpen={index === 0}
                workingPeriodIndex={index}
                previousWorkingPeriodEndDate={previousWorkingPeriodEndDate}
                nextWorkingPeriodStartDate={nextWorkingPeriodStartDate}
                setAllowAddWorkingPeriod={setAllowAddWorkingPeriod}
                allowDelete={allowDelete}
                key={`Working period ${index}`}
                permissions={permissions}
            />
        );
    };

    const generateHistoryButton = () => {
        return (
            <CustomButton
                type="normal"
                text={'Historical data'}
                iconComponent={ClockIcon}
                disabled={
                    dropDownState.isLoading || !permissions.canSeeHistoricalData
                }
                onClick={handleHistoricalDataClick}
            />
        );
    };

    const handleAddWorkingPeriod = () => {
        const requestData = {
            personId,
            startDate: moment(getLatestEndDate(workingPeriods))
                .add(1, 'd')
                .format('YYYY-MM-DD')
        };
        dispatch(addWorkingPeriod(requestData));
    };

    const handleHistoricalDataClick = () => {
        if (permissions.canSeeHistoricalData) {
            setIsHistoricalModalOpen(true);
        }
    };

    return (
        <DetailsMainContainer
            title={'Main information'}
            extraTitleRightElement={generateHistoryButton}
        >
            <StyledMainInformation>
                {isLoading || dropDownState.isLoading ? (
                    <Spinner />
                ) : (
                    <>
                        {workingPeriods.map(renderWorkingPeriods)}
                        {allowAddWorkingPeriod.every(wp => wp === true) && (
                            <div
                                data-testid="add-working-period-section"
                                className={`add-working-period-section ${getDisabledClassNameAddWorkingPeriod(
                                    permissions
                                )}`}
                                onClick={() =>
                                    canAddWorkingPeriod(permissions)
                                        ? handleAddWorkingPeriod()
                                        : undefined
                                }
                            >
                                + Add new working period
                            </div>
                        )}
                    </>
                )}
                {isHistoricalModalOpen && (
                    <HistoricalDataModal
                        history={history}
                        personId={personId}
                        isModalOpen={isHistoricalModalOpen}
                        setIsModalOpen={setIsHistoricalModalOpen}
                        title={'Historical Data'}
                    />
                )}
            </StyledMainInformation>
        </DetailsMainContainer>
    );
};

export default MainInformation;
