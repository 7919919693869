import React from 'react';

export const CloseIcon = ({ onClick, name }) => {
    return (
        <svg
            onClick={onClick}
            name={name}
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            fill="none"
            viewBox="0 0 8 8"
        >
            <path
                stroke="#7F919E"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M1 1l6 6M7 1L1 7"
            ></path>
        </svg>
    );
};
