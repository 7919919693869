import { isEqual } from 'lodash';
import { decimalRegex } from 'shared/constants/regexConstants';

const ERROR_MESSAGES_RICH_TEXT = {
    benefitTypeDescription:
        'Benefit Type Description should not exceed 1000 characters.',
    benefitTypeOverview:
        'Benefit Type Overview should not exceed 1000 characters.',
    benefitTypeHowToUse:
        'Benefit Type How to Use should not exceed 1000 characters.',
    benefitTypeContacts:
        'Benefit Type Contacts should not exceed 1000 characters.'
};

export const MAX_LENGTH_RICH_TEXT = 1000;
export const MAX_LENGTH_SHORT_DESCRIPTION = 100;
export const MIN_LENGTH_SHORT_DESCRIPTION = 3;
export const MAX_LENGTH_IMAGE_ALT_TEXT = 120;
export const MAX_LENGTH_CONTENTFUL_ID = 64;
export const CONTENTFULID_REGEX_PATTERN = /^[a-zA-Z0-9-_.]{1,64}$/;

class BenefitTypeValidator {
    constructor(values) {
        this.errors = {};
        this.values = values;
    }

    validateImage = () => {
        if (!this.values.benefitTypeImage) {
            this.errors.benefitTypeImage = 'Benefit Type Image is required.';
        }

        return this;
    };

    validateImageAltText = imageAltTextMaxLength => {
        if (!this.values.benefitTypeImageAltText?.trim()) {
            this.errors.benefitTypeImageAltText =
                'Benefit type image alt text is required.';
        } else if (
            this.values.benefitTypeImageAltText.length > imageAltTextMaxLength
        ) {
            this.errors.benefitTypeImageAltText =
                'Benefit type image alt text can be up to 120 characters long.';
        }

        return this;
    };

    validateName = () => {
        if (!this.values.benefitTypeName?.trim()) {
            this.errors.benefitTypeName = 'Benefit Type Name is required.';
        }

        return this;
    };

    validateShortDescription = (
        shortDescriptionMaxLength,
        shortDescriptionMinLength
    ) => {
        if (!this.values.benefitTypeShortDescription?.trim()) {
            this.errors.benefitTypeShortDescription =
                'Benefit Type short description is required.';
        } else if (
            this.values.benefitTypeShortDescription?.trim().length >
            shortDescriptionMaxLength
        ) {
            this.errors.benefitTypeShortDescription =
                'Benefit Type Short Description should not exceed 100 characters.';
        } else if (
            this.values.benefitTypeShortDescription?.trim().length <
            shortDescriptionMinLength
        ) {
            this.errors.benefitTypeShortDescription =
                'Benefit Type Short Description should be more than 3 characters.';
        }

        return this;
    };

    validatePrices = () => {
        this.errors.benefitPrices = [];

        this.values.benefitPrices.forEach(benefitPriceObj => {
            const activeBenefits = this.values.benefitPrices.filter(
                bp => bp.active
            );
            const isLast = isEqual(
                activeBenefits[activeBenefits.length - 1],
                benefitPriceObj
            );

            const { benefitPrice, startDate, endDate, active } =
                benefitPriceObj;

            let errors = {};

            if (!active) {
                return {};
            }

            let temptBenefitPrice = benefitPrice.toString();
            if (temptBenefitPrice?.trim() !== '') {
                if (
                    isNaN(temptBenefitPrice) ||
                    !decimalRegex.test(temptBenefitPrice)
                ) {
                    errors = {
                        ...errors,
                        benefitPrice: `Benefit price should be in correct format.`
                    };
                }
            } else {
                errors = {
                    ...errors,
                    benefitPrice: `Benefit price is required.`
                };
            }

            if (!startDate) {
                errors = {
                    ...errors,
                    startDate: `Benefit price start date is required.`
                };
            }

            if (!isLast && !endDate) {
                errors = {
                    ...errors,
                    endDate: `Benefit price end date is required.`
                };
            }

            this.errors.benefitPrices.push(errors);
        });

        if (
            this.errors.benefitPrices.every(bp => Object.keys(bp).length === 0)
        ) {
            delete this.errors.benefitPrices;
        }

        return this;
    };

    validateContentfulId = (
        contentfulIdMaxLength,
        contentfulIdRegexPattern
    ) => {
        if (
            this.values.benefitContentfulId &&
            this.values.benefitContentfulId.length > contentfulIdMaxLength
        ) {
            this.errors.benefitContentfulId = `Benefit Type Contentful ID can be a maximum of ${contentfulIdMaxLength} symbols.`;
        } else if (
            this.values.benefitContentfulId &&
            !contentfulIdRegexPattern.test(this.values.benefitContentfulId)
        ) {
            this.errors.benefitContentfulId = `Benefit Type Contentful ID format is not valid.`;
        }

        return this;
    };

    validateRichTextMaxLength = (richTextMaxLength, errorMessages) => {
        const richTextFieldNames = Object.keys(errorMessages);

        richTextFieldNames.forEach(fieldName => {
            if (
                this.values[fieldName]?.plainText?.trim()?.length >
                richTextMaxLength
            ) {
                this.errors[fieldName] = errorMessages[fieldName];
            }
        });

        return this;
    };
}

export const prepareBenefitPrices = benefitPrices => {
    const transformedBenefitPrices = [];
    let previousActive = null;

    benefitPrices.forEach((benefitPrice, index) => {
        transformedBenefitPrices.push({
            benefitPrice,
            previousActive,
            nextActive: benefitPrices
                .slice(index + 1)
                .find(price => price.active)
        });

        if (benefitPrice.active) {
            previousActive = benefitPrice;
        }
    });

    return transformedBenefitPrices;
};

export const validateBenefitType = values => {
    const validator = new BenefitTypeValidator(values);

    validator
        .validateImage()
        .validateImageAltText(MAX_LENGTH_IMAGE_ALT_TEXT)
        .validateName()
        .validateShortDescription(
            MAX_LENGTH_SHORT_DESCRIPTION,
            MIN_LENGTH_SHORT_DESCRIPTION
        )
        .validatePrices()
        .validateRichTextMaxLength(
            MAX_LENGTH_RICH_TEXT,
            ERROR_MESSAGES_RICH_TEXT
        )
        .validateContentfulId(
            MAX_LENGTH_CONTENTFUL_ID,
            CONTENTFULID_REGEX_PATTERN
        );

    return validator.errors;
};
