/* istanbul ignore file */
import { configureStore as createStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import { checkTokenExpirationMiddleware } from './middlewares/checkTokenMiddleware';
import { rootReducer } from './rootReducer';

const configureStore = () => {
    const store = createStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({ thunk }).concat(
                checkTokenExpirationMiddleware
            )
    });

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./rootReducer', () =>
            store.replaceReducer(rootReducer)
        );
    }

    return store;
};

export { configureStore };
