import { Tooltip } from 'antd';
import { EditIcon } from 'shared/theme/assets/icons/EditIcon';
import { TrashIcon } from 'shared/theme/assets/icons/TrashIcon';

import { StyledIcons } from './styled/StyledIcons.styled';
import { simpleTableBackgroundHelper } from './tableUtil';

export const createRoleTableColumns = (
    handleOpenEdit,
    handleDelete,
    permissions
) => {
    return [
        {
            Header: 'Role',
            id: 'role',
            accessor: 'role',
            Cell: ({ original }) => {
                return (
                    <div className="tooltip-span-text">
                        <Tooltip title={original.name} placement="bottomLeft">
                            <span>{original.name}</span>
                        </Tooltip>
                    </div>
                );
            },
            minWidth: 150,
            resizable: false,
            sortable: true,
            fixed: 'left',
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Permissions',
            id: 'permissions',
            accessor: 'permissions',
            Cell: ({ original }) => {
                const permissionsText = original.permissions
                    .map(p => p.description)
                    .join(', ');

                return (
                    <div className="tooltip-span-text">
                        <Tooltip title={permissionsText} placement="bottomLeft">
                            <span>{permissionsText}</span>
                        </Tooltip>
                    </div>
                );
            },
            resizable: true,
            sortable: true,
            minWidth: 250,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Actions',
            id: 'actions',
            accessor: 'actions',
            Cell: ({ original }) => (
                <>
                    <StyledIcons>
                        <div className="icons">
                            <div
                                data-testid="role-edit"
                                className="icon icon-edit"
                                onClick={() => {
                                    permissions.canEditRole
                                        ? handleOpenEdit(original)
                                        : undefined;
                                }}
                            >
                                <EditIcon />
                            </div>
                            <div
                                data-testid="role-delete"
                                className="icon icon-delete"
                                onClick={() => {
                                    permissions.canDeleteRole
                                        ? handleDelete(original)
                                        : undefined;
                                }}
                            >
                                <TrashIcon />
                            </div>
                        </div>
                    </StyledIcons>
                </>
            ),
            resizable: true,
            sortable: false,
            minWidth: 90,
            maxWidth: 350,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        }
    ];
};

const typeHandlerHelper = (state, rowInfo, column) => {
    return simpleTableBackgroundHelper(state, rowInfo, column);
};
