import styled from 'styled-components';

import { CustomModal } from '../../../../../shared/components/Modal';

const StyledHistoricalDataModal = styled(CustomModal)`
    width: 928px !important;
    height: 81%;
    padding-bottom: 0;
    .error {
        padding: 20px;
    }
    .ant-modal-content {
        .ant-modal-header {
            margin-left: 20px;
            margin-right: 20px;
        }
        .ant-modal-body {
            margin-right: 0px;
            margin-left: 0px;
            height: calc(81vh - 71px);
            .historical-items {
                overflow-x: hidden;
                overflow-y: auto;
                height: calc(81vh - 161px);
            }
        }
        .custom-select-div {
            margin-top: 0px;
            padding-top: 16px;
            padding-bottom: 20px;
            padding-left: 20px;
        }
        .ant-modal-footer {
            padding: 0px;
            display: none;
        }
    }

    .custom-modal-header {
        display: flex;
        justify-content: space-between;
    }
    .custom-modal-footer {
        display: flex;
        justify-content: flex-end;
    }
    .modal-body-section {
        .filter {
            display: flex;
            justify-content: space-between;
            box-shadow: 2px 3px 10px rgba(39, 70, 91, 0.16);
            .history-modal-edit-btn {
                padding-top: 20px;
                padding-right: 20px;
                .btn-normal {
                    height: 34px;
                }
            }
        }
    }
    .loading-handler {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        padding-top: 170px;
        height: 400px;
    }
    @media screen and (max-width: 768px) {
        width: 590px !important;
    }
    @media screen and (max-width: 426px) {
        .ant-select-selector {
            width: 200px !important;
        }
    }
`;

export { StyledHistoricalDataModal };
