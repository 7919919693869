import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';

import { StyledMainDashboard } from './styled/MainDashboard.styled';
import peopleService from '../../people/services/peopleService';
import { Main } from '../../shared/components/layout/Main';
import { errorNotification } from '../../shared/components/Notifications';
import { Spinner } from '../../shared/components/Spinner';
import { checkTokenExp, redirectTokenExp } from '../../utils/tokenHelper';
import { DepartmentCounter } from '../components/Main/DepartmentCounter';
import { MentormaterTypeCounter } from '../components/Main/MentormaterTypeCounter';
import { OfficeCounter } from '../components/Main/OfficeCounter';
import { SummaryCounter } from '../components/Main/SummaryCounter';
import { calculateFakeValues } from '../utils/Main/departmentCounterHelper';

const DEFAULT_FILTERS = {
    departmentId: undefined,
    levelId: undefined,
    locationId: undefined,
    gender: undefined,
    managerId: undefined,
    positionId: undefined,
    mentormaterTypeId: undefined,
    startDate: undefined,
    startDateBtnState: undefined,
    endDate: undefined,
    endDateBtnState: undefined
};

const DEFAULT_HANDLERS = {
    loading: true,
    error: undefined,
    data: {}
};

const MainDashboard = props => {
    const { history } = props;
    const [handlers, setHandlers] = useState(DEFAULT_HANDLERS);
    const { loading, error, data } = handlers;
    const pageTitle = 'Humate';

    const setHandler = (which, value) => {
        setHandlers(previous => ({ ...previous, [which]: value }));
    };

    //This section is required for the first component in the router, so it can redirect
    //to page we want to be redirected
    useEffect(() => {
        const redirectUrl = sessionStorage.getItem('redirectUrl');
        if (!isEmpty(redirectUrl) && redirectUrl !== window.location.pathName) {
            sessionStorage.clear();
            history.push(redirectUrl);
        }
    }, []);

    useEffect(() => {
        const getCounterData = async () => {
            try {
                if (checkTokenExp()) {
                    redirectTokenExp();
                }
                const res = await peopleService.getCurrentPeopleCounter();
                const {
                    data: {
                        summaryCounter,
                        departmentCounter,
                        locationCounter,
                        mentormaterTypeCounter
                    }
                } = res;
                setHandlers({
                    ...handlers,
                    data: {
                        summaryCounter,
                        departmentCounter,
                        locationCounter,
                        mentormaterTypeCounter
                    },
                    loading: false
                });
            } catch (e) {
                errorNotification(e.message);
                setHandler('error', e.message);
            } finally {
                setHandler('loading', false);
            }
        };
        getCounterData();
    }, []);

    return (
        <StyledMainDashboard>
            <Helmet title={pageTitle} />
            <Main
                hasHeader={true}
                hasNavigation={true}
                hasHeading={false}
                hasCustomHeading={true}
                // If needed use and implement
                // customHeadingComponent={<MainHeader history={history} />}
                hasFooter={true}
                history={history}
            >
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        {error ? (
                            <>
                                <div
                                    className="main-error"
                                    data-testid="main-error"
                                >
                                    {error}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="top-wrapper">
                                    <SummaryCounter
                                        data={data.summaryCounter}
                                        history={history}
                                        data-testid="summary-counter"
                                    />
                                    <OfficeCounter
                                        data={data.locationCounter}
                                        history={history}
                                    />
                                    <MentormaterTypeCounter
                                        data={data.mentormaterTypeCounter}
                                        history={history}
                                        defaultFilters={DEFAULT_FILTERS}
                                    />
                                </div>
                                <div className="bottom-wrapper">
                                    <DepartmentCounter
                                        data={data.departmentCounter}
                                        history={history}
                                        defaultFilters={DEFAULT_FILTERS}
                                        calculatedData={calculateFakeValues(
                                            data.departmentCounter
                                        )}
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
            </Main>
        </StyledMainDashboard>
    );
};

export default MainDashboard;
