import { isEmpty } from 'lodash';

import { deviceLinkUrl } from '../../shared/constants/linkConstants';
import { SuccessIcon } from '../../shared/theme/assets/icons/SuccessIcon';

export const transformLink = link => {
    var pattern = /^((http|https):\/\/)/;
    if (isEmpty(link)) {
        return link;
    } else {
        if (!pattern.test(link)) {
            link = 'https://' + link;
        }
        return link;
    }
};

export const transformWorkplaceLink = email => {
    var result = ``;
    if (isEmpty(email)) {
        return result;
    } else {
        result = `${deviceLinkUrl}=${email}`;
        return result;
    }
};

export const statusElementOpen = isSaved => {
    return isSaved ? (
        <>
            Saved <SuccessIcon />
        </>
    ) : (
        <div className="status-state">Loading...</div>
    );
};
