import { useState } from 'react';

import Icon from '@ant-design/icons';
import { MultiSelect } from 'react-multi-select-component';

import { StyledCustomMultiSelect } from './styled/CustomMultiSelect.styled';
import { SelectIcon } from '../theme/assets/icons/SelectIcon';
import { SelectIconUp } from '../theme/assets/icons/SelectIconUp';

export const CustomMultiSelect = ({
    canCreate = false,
    name,
    className,
    label,
    hasSelectAll,
    disableSearch,
    hideSuffix,
    options,
    onChange = () => {}, // setFieldValue needed when used in formik,
    onSelection = () => {},
    selected,
    placeholder = '',
    customValueRenderer = undefined,
    onClick = () => {}
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const suffix = () => {
        if (hideSuffix) {
            return null;
        }
        return suffixIcon;
    };

    const suffixIcon = isOpen ? (
        <Icon className="icon" component={SelectIconUp} />
    ) : (
        <Icon className="icon" component={SelectIcon} />
    );

    const handleChange = val => {
        const multipleValues = val.map(v => v.value);
        onChange(name, multipleValues, val);

        onSelection(val);
    };

    const customPlaceholderValueRenderer = (selected, _options) => {
        return selected.length
            ? selected.map(({ label }) => label).join(', ')
            : placeholder && placeholder.trim() !== ''
            ? placeholder
            : 'Select';
    };

    return (
        <StyledCustomMultiSelect>
            <div className={`custom-select-div ${className}`}>
                {label && <div className="custom-select-label">{label}</div>}
                <MultiSelect
                    isCreatable={canCreate}
                    options={options}
                    value={selected}
                    onChange={handleChange}
                    labelledBy="Select"
                    hasSelectAll={hasSelectAll}
                    disableSearch={disableSearch}
                    ArrowRenderer={() => suffix()}
                    onMenuToggle={() => {
                        setIsOpen(prev => {
                            onClick(!prev);
                            return !prev;
                        });
                    }}
                    ClearSelectedIcon={null}
                    valueRenderer={
                        customValueRenderer
                            ? customValueRenderer
                            : customPlaceholderValueRenderer
                    }
                ></MultiSelect>
            </div>
        </StyledCustomMultiSelect>
    );
};
