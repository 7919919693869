import styled from 'styled-components';

const ActionsSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    height: 70px;

    .filter-icons-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        .people-count-info {
            padding-right: 20px;
        }
    }
`;

export default ActionsSection;
