import React from 'react';

import { CloseOutlined } from '@ant-design/icons';

import { PeriodPicker } from './PeriodPicker';
import { SelectedFilterTags } from './SelectedFilterTags';
import CustomYearPicker from '../../../../shared/components/CustomYearPicker';
import { CustomSelect } from '../../../../shared/components/Select';
import { GENDER_SELECT_OPTIONS } from '../../../utils/Dashboard/filtersUtil';
import { MONTH_SELECT_OPTIONS } from '../../../utils/Dashboard/filtersUtil';

const AdministrationId = 10;
const AdministrationLabel = 'Administration';

export const FiltersBox = ({
    filterState,
    handleHide,
    handleFilterValueUpdate,
    departments,
    levels,
    positions,
    managers,
    locations,
    mentormaterTypes,
    benefitTypeNames,
    selectedTags,
    onTagRemove,
    setIsInside,
    handleFiltersReset,
    tab
}) => {
    const handleMouseOver = e => {
        if (e.target) {
            setIsInside(true);
        }
    };

    const handleMouseLeave = e => {
        if (e.target) {
            setIsInside(false);
        }
    };
    return (
        <div
            className="filters-box"
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
        >
            <div className="filters-box-header">
                <div className="filters-box-heading">Filter</div>
                <div
                    data-testid="filters-hide-button"
                    className="filters-box-actions"
                    onClick={handleHide}
                >
                    <CloseOutlined />
                </div>
            </div>
            <div className="filters-box-body">
                <PeriodPicker
                    name="startDate"
                    label={'Start Date'}
                    onChange={(name, val, btnState) =>
                        handleFilterValueUpdate(name, val, btnState)
                    }
                    value={filterState.startDate}
                    disabledDate={filterState.endDate}
                    validateDateInFuture={false}
                    showFooter={tab === 'people'}
                />
                <PeriodPicker
                    name="endDate"
                    label={'End Date'}
                    onChange={(name, val, btnState) =>
                        handleFilterValueUpdate(name, val, btnState)
                    }
                    value={filterState.endDate}
                    disabledDate={filterState.startDate}
                    validateDateInFuture={true}
                    showFooter={tab === 'people'}
                />

                {tab === 'people' && (
                    <>
                        <CustomSelect
                            label={'Starting Month'}
                            name={'startMonth'}
                            placeholder={'Select a starting month in MM'}
                            value={filterState.startMonth}
                            onChange={(name, val) =>
                                handleFilterValueUpdate(name, val)
                            }
                            options={MONTH_SELECT_OPTIONS}
                        />

                        <CustomYearPicker
                            label={'Starting year'}
                            name="startYear"
                            placeholder={'Select a starting year in MM'}
                            onChange={(name, val, btnState) =>
                                handleFilterValueUpdate(name, val, btnState)
                            }
                            value={filterState.startYear}
                        />

                        <CustomSelect
                            label={'Department'}
                            placeholder={'Select a department'}
                            name="departmentId"
                            value={
                                filterState.departmentId == AdministrationId
                                    ? ''
                                    : filterState.departmentId
                            }
                            onChange={(name, val) =>
                                handleFilterValueUpdate(name, val)
                            }
                            options={departments}
                        />

                        <CustomSelect
                            label={'Level'}
                            placeholder={'Select a level'}
                            name="levelId"
                            value={filterState.levelId}
                            onChange={(name, val) =>
                                handleFilterValueUpdate(name, val)
                            }
                            options={levels}
                        />
                    </>
                )}
                <CustomSelect
                    label={'Location'}
                    placeholder={'Select a Location'}
                    name="locationId"
                    value={filterState.locationId}
                    onChange={(name, val, option) =>
                        handleFilterValueUpdate(name, val, option.children)
                    }
                    options={locations}
                />
                {tab === 'people' && (
                    <>
                        <CustomSelect
                            label={'Type of MentorMater'}
                            placeholder={'Select a type'}
                            name="mentormaterTypeId"
                            value={filterState.mentormaterTypeId}
                            onChange={(name, val) =>
                                handleFilterValueUpdate(name, val)
                            }
                            options={mentormaterTypes}
                        />

                        <CustomSelect
                            label="Position"
                            placeholder={'Select a position'}
                            options={positions}
                            name={'positionId'}
                            value={filterState.positionId}
                            onChange={(name, val) =>
                                handleFilterValueUpdate(name, val)
                            }
                            hasInput={true}
                        />

                        <CustomSelect
                            label="Reports to"
                            placeholder={'Select a Reporter'}
                            options={managers}
                            name={'managerId'}
                            value={filterState.managerId}
                            onChange={(name, val) =>
                                handleFilterValueUpdate(name, val)
                            }
                            hasInput={true}
                        />

                        <CustomSelect
                            label={'Gender'}
                            placeholder={'Select a Gender'}
                            name="gender"
                            value={filterState.gender}
                            onChange={(name, val) =>
                                handleFilterValueUpdate(name, val)
                            }
                            options={GENDER_SELECT_OPTIONS}
                        />
                    </>
                )}
                {tab === 'benefits' && (
                    <CustomSelect
                        label={'Benefits'}
                        placeholder={'Select a benefit'}
                        name="benefitTypeId"
                        value={filterState.benefitTypeId}
                        onChange={(name, val, option) =>
                            handleFilterValueUpdate(name, val, option.children)
                        }
                        options={benefitTypeNames}
                        hasInput={true}
                    />
                )}
            </div>
            <div className="filters-box-footer">
                {/*add tags here on state change*/}
                <SelectedFilterTags
                    selected={selectedTags}
                    handleRemove={onTagRemove}
                    handleFiltersReset={handleFiltersReset}
                />
            </div>
        </div>
    );
};
