import React, { useEffect } from 'react';

import { Redirect, Route } from 'react-router-dom';
import { canNavigate } from 'shared/utils/navigationHelper';

import authService from '../../auth/services/authService';
import { STATIC_ROUTES_PATHS } from '../../routes';

export const CustomRoute = route => {
    const redirectUrl = sessionStorage.getItem('redirectUrl');
    useEffect(() => {
        const locationState = route.location.state;
        if (
            locationState !== undefined &&
            locationState?.hasOwnProperty('from') &&
            !redirectUrl
        ) {
            sessionStorage.setItem('redirectUrl', locationState.from);
        }
    }, []);

    if (route.authenticated && !authService.getJwt()) {
        return (
            <Redirect
                exact={route.exact}
                path={route.path}
                to={{
                    pathname: STATIC_ROUTES_PATHS.login,
                    state: { from: route.computedMatch.url }
                }}
            />
        );
    }

    const hasAccess = canNavigate(route.path);

    return hasAccess ? (
        <Route
            exact={route.exact}
            path={route.path}
            render={props => <route.component {...props} />}
        />
    ) : (
        <Redirect to={'/unauthorized'} exact />
    );
};
