export const validateForm = ({ cardId }) => {
    let errors = {};

    if (cardId) {
        let cardIdError;
        if (cardId?.trim() === '') {
            cardIdError = `Personal Id cannot contain only spaces.`;
        }
        errors = { ...errors, cardId: cardIdError };
    }

    return errors;
};
