import React, { useState, useEffect, useRef } from 'react';

import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { StyledEmployeeDetailsHeader } from './styled/EmployeeDetailsHeader.styled';
import { deletePerson } from '../../../redux/slices/people';
import { uploadProfilePicture } from '../../../redux/slices/picture';
import { STATIC_ROUTES_PATHS } from '../../../routes';
import { CustomConfirmationModal } from '../../../shared/components/ConfirmationModal';
import { Media } from '../../../shared/components/Media';
import { UploadFileInput } from '../../../shared/components/UploadFileInput';
import { imageRegex } from '../../../shared/constants/regexConstants';
import { EditIcon } from '../../../shared/theme/assets/icons/EditIcon';
import { PersonModal } from '../shared/PersonModal';

const EmployeeDetailsHeader = ({
    person,
    history,
    personId,
    allowDelete,
    permissions
}) => {
    const { name, nameBG, positionTitle, photo, gender } = person;
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const inputRef = useRef();
    const { isPersonDeleted } = useSelector(state => state.people);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isPersonDeleted) {
            history.push(STATIC_ROUTES_PATHS.peopleDashboard);
        }
    }, [isPersonDeleted]);

    const modelDataForEditModal = {
        name: person.name,
        nameBg: person.nameBG,
        workEmail: person.workEmail,
        personalPhone: person.phoneNumber,
        oaid: person.oaid,
        excluCVUrl: person.excluCVUrl
    };

    const openEditMainInfoModal = () => {
        setIsEditModalOpen(true);
    };

    const handleDeletePerson = personId => {
        setIsDeleteModalOpen(false);
        dispatch(deletePerson(personId));
    };

    const uploadFile = file => {
        if (!file) {
            return;
        }

        if (!file.type.match(imageRegex)) return;

        dispatch(uploadProfilePicture(file, personId));
    };

    const handleImgClick = () => {
        inputRef.current.click();
    };

    return (
        <StyledEmployeeDetailsHeader>
            <div className="employee-details-header-wrapper">
                <div className="employee-details-container">
                    <UploadFileInput
                        inputRef={inputRef}
                        uploadFile={uploadFile}
                    />
                    <div className="employee-pic">
                        <Media
                            dataTestid="upload-picture"
                            className={`avatar ${
                                permissions.canEditPersonInformation
                                    ? ''
                                    : 'disabled'
                            }`}
                            photoUrl={photo}
                            onClick={() =>
                                permissions.canEditPersonInformation
                                    ? handleImgClick()
                                    : undefined
                            }
                        />
                        {gender && (
                            <div className="gender-indicator">{gender}</div>
                        )}
                    </div>
                    <div className="employee-basic-info">
                        <div className="employee-name">
                            {name} {!isEmpty(nameBG) && <>({nameBG})</>}
                            <div
                                data-testid="edit-person-button"
                                className={`icon-holder ${
                                    permissions.canEditPersonInformation
                                        ? ''
                                        : 'disabled'
                                }`}
                                onClick={() =>
                                    permissions.canEditPersonInformation
                                        ? openEditMainInfoModal()
                                        : undefined
                                }
                            >
                                <EditIcon />
                            </div>
                        </div>
                        <div className="employee-title-delete-wrapper">
                            <div className="employee-title">
                                {positionTitle}
                            </div>
                            {allowDelete && (
                                <div
                                    data-testid="delete-person-button"
                                    className={`delete-section ${
                                        permissions.canDeletePerson
                                            ? ''
                                            : 'disabled'
                                    }`}
                                    onClick={() =>
                                        permissions.canDeletePerson
                                            ? setIsDeleteModalOpen(true)
                                            : undefined
                                    }
                                >
                                    Delete record
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isEditModalOpen && (
                <PersonModal
                    history={history}
                    isModalOpen={isEditModalOpen}
                    setIsModalOpen={setIsEditModalOpen}
                    title={'Edit Information/Profile'}
                    initialValues={modelDataForEditModal}
                    action={'updated'}
                    personId={personId}
                />
            )}
            {isDeleteModalOpen && (
                <CustomConfirmationModal
                    visible={isDeleteModalOpen}
                    setVisible={setIsDeleteModalOpen}
                    title={'Confirmation'}
                    contentText={`You are going to delete ${person.name}’s record.`}
                    handleConfirm={() => handleDeletePerson(personId)}
                    dataTestid={'delete-person-confirmation'}
                />
            )}
        </StyledEmployeeDetailsHeader>
    );
};

export default EmployeeDetailsHeader;
