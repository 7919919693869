import RichText from 'shared/components/RichText';

import { MAX_LENGTH_RICH_TEXT } from '../../../utils/shared/benefitModalHelper';

const OverviewTab = ({
    handleRichTextChange,
    values,
    errors,
    setFieldValue,
    setFieldTouched,
    touched
}) => {
    return (
        <RichText
            label="Overview"
            placeholder="Benefit Overview ..."
            value={values.benefitTypeOverview.html}
            name={'benefitTypeOverview'}
            hasError={
                touched.benefitTypeOverview && errors?.benefitTypeOverview
            }
            onChange={(name, values) =>
                handleRichTextChange(name, values, setFieldValue)
            }
            maxLength={MAX_LENGTH_RICH_TEXT}
            id={'benefitTypeOverview'}
            setFieldTouched={setFieldTouched}
        />
    );
};

export default OverviewTab;
