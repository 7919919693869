const unclickableClassName = 'unclickable';

export const canAccessPeopleCurrent = permissions =>
    permissions.canViewPeoplePage && permissions.canViewCurrentTab;

export const canAccessPeopleLeavers = permissions =>
    permissions.canViewPeoplePage && permissions.canViewLeaversTab;

export const canAccessPeopleNew = permissions =>
    permissions.canViewPeoplePage && permissions.canViewNewTab;

export const canAccessPeopleEx = permissions =>
    permissions.canViewPeoplePage && permissions.canViewExTab;

export const getClassForCurrent = permissions => {
    if (canAccessPeopleCurrent(permissions)) {
        return '';
    }

    return unclickableClassName;
};

export const getClassNameForNew = permissions => {
    if (canAccessPeopleNew(permissions)) {
        return '';
    }

    return unclickableClassName;
};

export const getClassForLeavers = permissions => {
    if (canAccessPeopleNew(permissions)) {
        return '';
    }

    return unclickableClassName;
};
