/* eslint-disable prettier/prettier */
export const nameRegex = /^[A-Za-zöÖäÄüÜ\-\' ]*$/;

export const emailRegex = '^[^s]+@[^s]+.[^s]+$';

export const emailRegexString = /^[^\s]+@[^\s]+\.[^\s]+$/;

export const phoneRegex = '^[0-9-()+]{0,20}$';

export const latinAndBulgarianLettersRegex = "^[A-Za-zöÖäÄüÜА-я-' ]+$";

export const globalSearchRegex = "^[A-Za-zöÖäÄüÜА-я-'@. ]+$";

export const tableSearchRegex = "^[A-Za-zöÖäÄüÜА-я-' ]+$";

export const bulgarianLettersRegex = /^[А-я\-\' ]*$/;

export const positionRegex = '^[A-Za-z-&-,/. ]*$';

export const openIdRegex = '^[0-9]+$';

export const bulgarianEgnRegex = '^[0-9]{10}$';

export const imageRegex = /image.*/;

export const decimalRegex = /^\d*(\.\d*)?$/;
