import { isEmpty } from 'lodash';

//SELECT util
export const GENDER_SELECT_OPTIONS = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' }
];

export const MONTH_SELECT_OPTIONS = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 }
];

//function used to help obtaining the selected filter item
export const getSelectedItem = (values, stateValueId, stateValueProp) => {
    if (values && values.length > 0) {
        const item = !isEmpty(values)
            ? values.find(el => el.value === stateValueId)
            : { label: undefined, value: undefined };
        if (item !== undefined) {
            item.stateValueProp = stateValueProp;
        }
        return item;
    }
};

//function used to transform the selected date range
export const formatSelectedDate = (value, btnStateValue, stateValueProp) => {
    if (!isEmpty(value)) {
        const dateLabel = !isEmpty(btnStateValue)
            ? `${
                  btnStateValue.charAt(0).toUpperCase() + btnStateValue.slice(1)
              } ${value}`
            : `${value}`;
        const itemValue = {
            label: dateLabel,
            value: value,
            stateValueProp: stateValueProp
        };
        return itemValue;
    } else {
        return undefined;
    }
};

//function used to return an array of the selected items to fulfill the tags logic
export const flatSelectedFilters = (
    peopleState,
    departments,
    levels,
    locations,
    managers,
    positions,
    typesOfMentormater,
    benefitTypeNames
) => {
    let selected = [];
    const {
        departmentId,
        gender,
        levelId,
        locationId,
        managerId,
        positionId,
        mentormaterTypeId,
        startDate,
        startDateBtnState,
        endDate,
        endDateBtnState,
        startMonth,
        startYear,
        benefitTypeId
    } = peopleState;

    const departmentValue = getSelectedItem(
        departments,
        departmentId,
        'departmentId'
    );
    const levelValue = getSelectedItem(levels, levelId, 'levelId');
    const positionValue = getSelectedItem(positions, positionId, 'positionId');
    const managerValue = getSelectedItem(managers, managerId, 'managerId');
    const locationValue = getSelectedItem(locations, locationId, 'locationId');

    const genderValue = getSelectedItem(
        GENDER_SELECT_OPTIONS,
        gender,
        'gender'
    );
    const mentormaterTypeValue = getSelectedItem(
        typesOfMentormater,
        mentormaterTypeId,
        'mentormaterTypeId'
    );
    const startDateValue = formatSelectedDate(
        startDate,
        startDateBtnState,
        'startDate'
    );
    const endDateValue = formatSelectedDate(
        endDate,
        endDateBtnState,
        'endDate'
    );

    const startMonthValue = getSelectedItem(
        MONTH_SELECT_OPTIONS,
        startMonth,
        'startMonth'
    );

    const startYearValue = formatSelectedDate(startYear, '', 'startYear');

    const benefitValue = getSelectedItem(
        benefitTypeNames,
        benefitTypeId,
        'benefitTypeId'
    );

    selected.push(
        departmentValue,
        levelValue,
        positionValue,
        managerValue,
        locationValue,
        genderValue,
        mentormaterTypeValue,
        startDateValue,
        endDateValue,
        startMonthValue,
        startYearValue,
        benefitValue
    );

    return selected;
};
