import styled from 'styled-components';

const StyledMainDashboard = styled.div`
    .main {
        max-width: inherit !important;
        min-height: 100vh !important;
        padding: 14px 90px !important;
    }
    .main .ant-spin.ant-spin-lg.ant-spin-spinning {
        padding-top: 100px;
        display: flex;
        justify-content: center;
    }
    .top-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .bottom-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .main-error {
        margin-top: 20px;
    }

    @media screen and (max-width: 1440px) {
        .top-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
    }
`;

export { StyledMainDashboard };
