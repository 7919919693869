import { useEffect, useRef } from 'react';

const useInfiniteScroll = ({ onLoadMore, isLoading, hasMore }) => {
    const boundaryRef = useRef();
    const onLoadMoreRef = useRef();
    onLoadMoreRef.current = onLoadMore;

    useEffect(() => {
        const intersectObserver = new IntersectionObserver(
            elements => {
                elements.forEach(element => {
                    if (element.isIntersecting) {
                        onLoadMoreRef.current();
                    }
                });
            },
            { rootMargin: '50px' }
        );
        if (boundaryRef.current && !isLoading && hasMore) {
            intersectObserver.observe(boundaryRef.current);
        }

        return () => {
            intersectObserver.disconnect();
        };
    }, [isLoading, hasMore]);

    return boundaryRef;
};

export default useInfiniteScroll;
