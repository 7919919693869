import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { RelativeSubSection } from './RelativeSubSection';
import {
    addRelativeMember,
    resetRelativeMemberLoadingStack
} from '../../../../redux/slices/relativeMember';
import { errorNotification } from '../../../../shared/components/Notifications';
import { statusElementOpen } from '../../../utils/employeeDetailsHelper';
import { DetailsInnerContainer } from '../../shared/DetailsInnerContainer';

const RelativeInformation = ({ isSectionOpen, personId, permissions }) => {
    const [isOpen, setIsOpen] = useState(isSectionOpen);
    const [isSaved, setIsSaved] = useState(false);
    const [callCounter, setCallCounter] = useState(0);
    const [showInfoText, setShowInfoText] = useState(false);
    const dispatch = useDispatch();
    const { relativeMembers, isLoadingStack, error } = useSelector(
        state => state.relativeMembers
    );

    useEffect(() => {
        if (!isEmpty(isLoadingStack)) {
            const loadingValues = Object.values(isLoadingStack);
            const tempt = [];
            loadingValues.map(x => tempt.push(...Object.values(x)));
            tempt.some(v => v === true) ? setIsSaved(false) : setIsSaved(true);
        }
    }, [isLoadingStack]);

    useEffect(() => {
        if (error) {
            errorNotification(error, 5);
            dispatch(resetRelativeMemberLoadingStack());
        }
    }, [error]);

    const handleSection = () => {
        setIsOpen(!isOpen);
        if (isOpen) {
            dispatch(resetRelativeMemberLoadingStack());
            setShowInfoText(false);
            setCallCounter(0);
        }
    };

    const handleAddRelativeMember = () => {
        dispatch(addRelativeMember(personId));
    };

    return (
        <DetailsInnerContainer
            bodyTitle={'Relatives'}
            isOpen={isOpen}
            statusText={showInfoText && statusElementOpen(isSaved)}
            handleSection={handleSection}
        >
            <div className="section-splitter"></div>
            {relativeMembers.map((fm, index) => {
                return (
                    <RelativeSubSection
                        key={`${fm.personRelativeMemberId} relative member`}
                        initialValues={fm}
                        relativeMemberIndex={index}
                        callCounter={callCounter}
                        setCallCounter={setCallCounter}
                        setShowInfoText={setShowInfoText}
                        personId={personId}
                        permissions={permissions}
                    />
                );
            })}
            <div
                data-testid="add-working-period-section"
                className="add-working-period-section"
                onClick={handleAddRelativeMember}
            >
                + Add new relative member
            </div>
        </DetailsInnerContainer>
    );
};

export { RelativeInformation };
