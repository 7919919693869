import React from 'react';

export const SelectIconUp = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                stroke="#03A8F5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M12 10.5l-3-3-3 3"
            ></path>
            <rect
                width="17"
                height="17"
                x="17.5"
                y="17.5"
                stroke="#03A8F5"
                rx="4.5"
                transform="rotate(180 17.5 17.5)"
            ></rect>
        </svg>
    );
};
