import { createSlice } from '@reduxjs/toolkit';
import departmentService from 'admin/services/departmentService';
import { errorNotification } from 'shared/components';

const initialState = {
    take: 10,
    sortBy: 'UpdatedOn',
    searchPhrase: null,
    sortAsc: false,
    loadActive: true,

    departments: [],
    currentTotal: 0,
    departmentsTotalCount: 0,

    isLoading: false,
    error: null,
    isEditMode: false,
    isAddedSuccessfully: undefined,
    isEditedSuccessfully: undefined,
    isLoadingFetchSingle: false,

    singleDepartmentData: {
        id: 0,
        name: '',
        active: false,
        isAdministration: false
    }
};

const { reducer: departmentReducer, actions } = createSlice({
    name: 'department',
    initialState,
    reducers: {
        // Fetch departments
        changeLoadActive: (state, action) => {
            state.loadActive = action.payload;
        },
        updateSearchPhrase: (state, action) => {
            state.searchPhrase = action.payload;
        },
        resetNotifications: state => {
            state.isAddedSuccessfully = undefined;
            state.isEditedSuccessfully = undefined;
        },
        fetchDepartmentsStart: state => {
            state.isLoading = true;
        },
        fetchDepartmentsSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.isLoading = false;
            state.error = null;
            state.departments = [...state.departments, ...data];
            state.currentTotal = state.currentTotal + data.length;
            state.departmentsTotalCount = totalCount;
        },
        fetchDepartmentsFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        changeOrderState: (state, action) => {
            const {
                payload: { sortBy, sortAsc }
            } = action;
            state.sortBy = sortBy;
            state.sortAsc = sortAsc;
        },

        updateDepartmentSuccess: state => {
            state.isLoadingEdit = false;
            state.isLoading = false;
            state.isEditedSuccessfully = true;
        },
        updateDepartmentFailure: state => {
            state.isLoadingEdit = false;
            state.isLoading = false;
        },

        // clear state
        cleanState: state => {
            state.take = 10;
            state.departments = [];
            state.currentTotal = 0;
            state.departmentsTotalCount = 0;
            state.isLoading = false;
            state.error = null;
        },

        resetFilters: state => {
            state.skip = 0;
            state.take = 10;
            state.sortBy = 'UpdatedOn';
            state.sortAsc = false;
            state.searchPhrase = '';
            state.departments = [];
            state.currentTotal = 0;
            state.departmentsTotalCount = 0;
            state.isLoading = false;
            state.error = null;
            state.loadActive = true;
        },

        setLoadStateToActive: state => {
            state.active = true;
        },

        updateDepartmentStart: state => {
            state.isLoading = true;
            state.editError = null;
            state.isLoadingEdit = true;
        },

        resetShowUpdate: state => {
            state.showUpdateMessage = false;
        },
        createDepartmentStart: state => {
            state.isLoading = true;
            state.isLoadingCreate = true;
        },
        createDepartmentFailure: (state, action) => {
            state.isLoading = false;
            state.isLoadingCreate = false;
            state.error = action.payload;
        },
        createDepartmentSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.isLoading = false;
            state.isLoadingCreate = false;
            state.showCreateMessage = true;
            state.error = null;

            state.departments = [...data];
            state.currentTotal = data.length;
            state.departmentsTotalCount = totalCount;
            state.isAddedSuccessfully = true;
        },

        resetDepartmentModal: state => {
            state.singleDepartmentData = initialState.singleDepartmentData;
            state.isEditMode = false;
        },

        fetchSingleDepartmentStart: state => {
            state.isLoadingFetchSingle = true;
            state.isEditMode = true;
        },

        fetchSingleDepartmentSuccess: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.error = null;
            state.singleDepartmentData = action.payload;
        },

        fetchSingleDepartmentFailure: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.error = action.payload;
        },
        reloadDepartmentsSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.departments = data;
            state.currentTotal = data.length;
            state.departmentsTotalCount = totalCount;
        }
    }
});

const fetchDepartments = isScrolled => {
    return async (dispatch, getState) => {
        if (getState().department.isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchDepartmentsStart());
            let skip = getState().department.currentTotal;
            let take = getState().department.take;

            if (!isScrolled) {
                skip = 0;
                take = 10;
                dispatch(actions.cleanState());
            }
            const { sortAsc, sortBy, searchPhrase, loadActive } =
                getState().department;
            const resp = await departmentService.getAllDepartments({
                skip,
                take,
                sortBy,
                sortAsc,
                searchPhrase,
                loadActive
            });
            const totalCount = resp.metadata.totalCount;
            const data = resp.data;
            dispatch(actions.fetchDepartmentsSuccess({ data, totalCount }));
        } catch (err) {
            dispatch(actions.fetchDepartmentsFailure(err));
        }
    };
};

const changeOrder = orderValue => {
    const { id, desc } = orderValue[0];
    const sortBy = id.charAt(0).toUpperCase() + id.slice(1);
    const sortAsc = !desc;
    return async dispatch => {
        dispatch(actions.changeOrderState({ sortBy, sortAsc }));
    };
};

const resetDepartmentState = () => {
    return dispatch => {
        dispatch(actions.cleanState());
    };
};

const resetFilters = () => {
    return dispatch => {
        dispatch(actions.resetFilters());
    };
};

const resetDepartment = () => {
    return dispatch => {
        dispatch(actions.resetDepartmentModal());
    };
};

const resetShowUpdateMessage = () => {
    return dispatch => {
        dispatch(actions.resetShowUpdate());
    };
};

const fetchSingleDepartment = id => {
    return async (dispatch, getState) => {
        if (getState().role.isLoadingFetchSingle) {
            return;
        }

        try {
            dispatch(actions.fetchSingleDepartmentStart());
            const data = await departmentService.getDepartmentById(id);
            dispatch(actions.fetchSingleDepartmentSuccess(data.data));
        } catch (err) {
            dispatch(
                actions.fetchSingleDepartmentFailure('Something went wrong.')
            );
        }
    };
};

const createDepartment = requestData => {
    return async (dispatch, getState) => {
        if (getState().department.isLoadingCreate) {
            return;
        }

        try {
            dispatch(actions.createDepartmentStart());
            await departmentService.addDepartment(requestData);
            const skip = 0;
            const { take, sortAsc, sortBy, loadActive, searchPhrase } =
                getState().department;
            const resp = await departmentService.getAllDepartments({
                skip,
                sortBy,
                sortAsc,
                take,
                loadActive,
                searchPhrase
            });
            const {
                data,
                metadata: { totalCount }
            } = resp;
            dispatch(actions.createDepartmentSuccess({ data, totalCount }));
        } catch (error) {
            const errorMessage =
                error.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.createDepartmentFailure(errorMessage));
        }
    };
};

const reloadDepartments = async (dispatch, getState) => {
    const { sortAsc, sortBy, searchPhrase, loadActive } = getState().department;

    const skip = 0;
    const take = 10;

    const resp = await departmentService.getAllDepartments({
        skip,
        take,
        sortBy,
        sortAsc,
        searchPhrase,
        loadActive
    });
    const {
        data,
        metadata: { totalCount }
    } = resp;
    dispatch(actions.reloadDepartmentsSuccess({ data, totalCount }));
};

const updateDepartment = (id, departmentData) => {
    return async (dispatch, getState) => {
        try {
            dispatch(actions.updateDepartmentStart());
            await departmentService.updateDepartment(id, departmentData);
            await reloadDepartments(dispatch, getState);
            await resetFilters();
            dispatch(actions.updateDepartmentSuccess());
            dispatch(actions.resetDepartmentModal());
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.updateDepartmentFailure(errorMessage));
        }
    };
};

const resetNotificationState = () => {
    return async dispatch => dispatch(actions.resetNotifications());
};

const updateSearchPhrase = searchPhrase => {
    return async dispatch => dispatch(actions.updateSearchPhrase(searchPhrase));
};

const changeActiveState = loadActive => {
    return async dispatch => dispatch(actions.changeLoadActive(loadActive));
};

export {
    departmentReducer,
    actions as departmentActions,
    updateSearchPhrase,
    fetchDepartments,
    changeOrder,
    resetDepartmentState,
    resetFilters,
    resetDepartment,
    resetShowUpdateMessage,
    fetchSingleDepartment,
    createDepartment,
    updateDepartment,
    resetNotificationState,
    changeActiveState
};
