import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
    resetPeopleLoadingStack,
    updatePersonalInformation
} from '../../../../redux/slices/people';
import { CustomDateSelector } from '../../../../shared/components/DatePicker';
import { FieldsSection } from '../../../../shared/components/FieldsSection';
import { CustomInput } from '../../../../shared/components/Input';
import { errorNotification } from '../../../../shared/components/Notifications';
import { CustomSelect } from '../../../../shared/components/Select';
import {
    selectPlaceholder,
    setAllowClear,
    setClassName
} from '../../../../utils/componentPropHelpers';
import {
    mapDataForDropdown,
    mapNationalitiesForDropdown
} from '../../../../utils/dropdownHelper';
import { GENDER_SELECT_OPTIONS } from '../../../utils/Dashboard/filtersUtil';
import { validateForm } from '../../../utils/Details/PersonalInformation/generalInformationFormHelper';
import { checkAndUpdateByPersonalID } from '../../../utils/Details/PersonalInformation/sharedHelper';
import { statusElementOpen } from '../../../utils/employeeDetailsHelper';
import { normalizeRequestData } from '../../../utils/modelMapper';
import { DetailsInnerContainer } from '../../shared/DetailsInnerContainer';

const INITIAL_VALUES = {
    birthday: null,
    personalId: null,
    gender: null,
    clothingSizeId: undefined,
    personalEmail: null,
    addressById: null,
    nationality: null
};

const GeneralInformation = ({
    isSectionOpen,
    initialValues = INITIAL_VALUES,
    personId,
    permissions
}) => {
    const [isOpen, setIsOpen] = useState(isSectionOpen);
    const [errors, setErrors] = useState({});
    const [isSaved, setIsSaved] = useState(false);
    const [showInfoText, setShowInfoText] = useState(false);
    const [callCounter, setCallCounter] = useState(0);
    const [initialValuesFormik, setInitialValuesFormik] = useState({
        ...initialValues
    });
    const dropdownsState = useSelector(state => state.dropdown);
    const { isLoadingStack, errorStack } = useSelector(state => state.people);
    const dispatch = useDispatch();

    const clothSizeOptions = mapDataForDropdown(dropdownsState.clothSizes);
    const nationalityOptions = mapNationalitiesForDropdown(
        dropdownsState.nationalities
    );

    const validate = values => {
        const errorsIncome = validateForm(values);
        setErrors(errorsIncome);
    };

    const handleSection = () => {
        setIsOpen(!isOpen);
        if (isOpen) {
            dispatch(resetPeopleLoadingStack());
            setShowInfoText(false);
            setCallCounter(0);
        }
    };

    const handleBlur = (
        inputName,
        value,
        setFieldValue = () => {},
        permissions
    ) => {
        const newValue = value?.toString().trim() ?? '';
        const initialVal = initialValuesFormik[inputName]?.toString() ?? '';
        if (newValue !== initialVal && !errors[inputName]) {
            setShowInfoText(true);
            setInitialValuesFormik(prev => {
                let temp = { ...prev };
                temp[inputName] = value;
                return temp;
            });
            setCallCounter(prev => prev + 1);

            let requestObj = { ...initialValuesFormik, [inputName]: value };

            requestObj = checkAndUpdateByPersonalID(
                inputName,
                value,
                requestObj,
                setFieldValue
            );

            dispatch(
                updatePersonalInformation(
                    callCounter + 1,
                    personId,
                    normalizeRequestData(requestObj),
                    permissions
                )
            );
        }
    };

    useEffect(() => {
        return () => {
            dispatch(resetPeopleLoadingStack());
        };
    }, []);

    useEffect(() => {
        if (errorStack) {
            errorNotification(errorStack, 5);
            dispatch(resetPeopleLoadingStack());
        }
    }, [errorStack]);

    useEffect(() => {
        if (!isEmpty(isLoadingStack)) {
            Object.values(isLoadingStack).some(v => v === true)
                ? setIsSaved(false)
                : setIsSaved(true);
        }
    }, [isLoadingStack]);

    return (
        <DetailsInnerContainer
            bodyTitle={'General'}
            isOpen={isOpen}
            statusText={showInfoText && statusElementOpen(isSaved)}
            handleSection={handleSection}
        >
            <Formik initialValues={initialValuesFormik} validate={validate}>
                {props => {
                    const { values, setFieldValue } = props;

                    return (
                        <Form>
                            <div className="section-splitter"></div>
                            <FieldsSection>
                                <div className="section-inputs">
                                    <CustomInput
                                        className={setClassName(
                                            errors?.personalId
                                        )}
                                        label="Personal ID"
                                        name="personalId"
                                        placeholder={
                                            permissions.canViewPersonalId
                                                ? 'Type...'
                                                : ''
                                        }
                                        allowClear={true}
                                        specialEnter={true}
                                        disabled={
                                            !permissions.canViewPersonalId
                                        }
                                        isNormalChange={false}
                                        value={values.personalId}
                                        onChange={(name, val) => {
                                            setFieldValue(name, val);
                                        }}
                                        onBlur={name => {
                                            handleBlur(
                                                name,
                                                values.personalId,
                                                setFieldValue,
                                                permissions
                                            );
                                        }}
                                    />
                                    <CustomDateSelector
                                        value={values.birthday}
                                        label="Birth Date"
                                        name="birthday"
                                        onChange={setFieldValue}
                                        onBlur={(name, val) => {
                                            handleBlur(
                                                name,
                                                val,
                                                null,
                                                permissions
                                            );
                                        }}
                                    />
                                    <CustomInput
                                        className={setClassName(
                                            errors?.personalEmail
                                        )}
                                        label="Personal email"
                                        name="personalEmail"
                                        placeholder="Type..."
                                        allowClear={true}
                                        specialEnter={true}
                                        isNormalChange={false}
                                        value={values.personalEmail}
                                        onChange={(name, val) => {
                                            setFieldValue(name, val);
                                        }}
                                        onBlur={name => {
                                            handleBlur(
                                                name,
                                                values.personalEmail,
                                                null,
                                                permissions
                                            );
                                        }}
                                    />
                                    <CustomSelect
                                        value={values.nationality}
                                        label="Nationality"
                                        name="nationality"
                                        placeholder={selectPlaceholder}
                                        allowClear={setAllowClear(
                                            values.nationality
                                        )}
                                        options={nationalityOptions}
                                        onChange={setFieldValue}
                                        onBlur={(name, val) => {
                                            handleBlur(
                                                name,
                                                val,
                                                null,
                                                permissions
                                            );
                                        }}
                                        hasInput={true}
                                    />
                                    <CustomSelect
                                        value={values.gender}
                                        label="Gender"
                                        name="gender"
                                        placeholder={selectPlaceholder}
                                        allowClear={setAllowClear(
                                            values.gender
                                        )}
                                        options={GENDER_SELECT_OPTIONS}
                                        onChange={setFieldValue}
                                        onBlur={(name, val) => {
                                            handleBlur(
                                                name,
                                                val,
                                                null,
                                                permissions
                                            );
                                        }}
                                    />
                                    <CustomInput
                                        className={setClassName(
                                            errors?.addressById
                                        )}
                                        label="Address By ID"
                                        name="addressById"
                                        placeholder="Type..."
                                        allowClear={true}
                                        specialEnter={true}
                                        isNormalChange={false}
                                        value={values.addressById}
                                        onChange={(name, val) => {
                                            setFieldValue(name, val);
                                        }}
                                        onBlur={name => {
                                            handleBlur(
                                                name,
                                                values.addressById,
                                                null,
                                                permissions
                                            );
                                        }}
                                    />
                                </div>
                            </FieldsSection>
                            <div className="section-splitter"></div>
                            <FieldsSection>
                                <div className="section-inputs">
                                    <CustomSelect
                                        value={values.clothingSizeId}
                                        label="Size"
                                        name="clothingSizeId"
                                        placeholder={selectPlaceholder}
                                        allowClear={setAllowClear(
                                            values.clothingSizeId
                                        )}
                                        options={clothSizeOptions}
                                        onChange={setFieldValue}
                                        onBlur={(name, val) => {
                                            handleBlur(
                                                name,
                                                val,
                                                null,
                                                permissions
                                            );
                                        }}
                                    />
                                </div>
                            </FieldsSection>
                        </Form>
                    );
                }}
            </Formik>
        </DetailsInnerContainer>
    );
};

export { GeneralInformation };
