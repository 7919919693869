import { useContext } from 'react';

import {
    canAccessPeopleCurrent,
    canAccessPeopleEx,
    canAccessPeopleNew,
    canAccessPeopleLeavers,
    getClassForCurrent,
    getClassNameForNew,
    getClassForLeavers
} from 'main/utils/Main/permissionsHelper';
import PermissionsContext from 'shared/utils/permissionStore';

import { StyledSummaryCounter } from './styled/SummaryCounter.styled';
import { STATIC_ROUTES_PATHS } from '../../../routes';
import { CurrentEmployeesIcon } from '../../../shared/theme/assets/icons/CurrentEmployeesIcon';
import { LeaverEmployeesIcon } from '../../../shared/theme/assets/icons/LeaverEmployeesIcon';
import { NewEmployeesIcon } from '../../../shared/theme/assets/icons/NewEmpoyeesIcon';

export const SummaryCounter = ({ data, history }) => {
    const permissions = useContext(PermissionsContext).permissions;
    const { currentEmployeesCount, newEmployeesCount, leaverEmployeesCount } =
        data;

    const handleOnTotalClick = () => {
        history.push({
            pathname: STATIC_ROUTES_PATHS.peopleDashboard,
            state: { subsetTab: 1 }
        });
    };

    const handleOnNewClick = () => {
        history.push({
            pathname: STATIC_ROUTES_PATHS.peopleDashboard,
            state: { subsetTab: 2 }
        });
    };

    const handleOnLeaversClick = () => {
        history.push({
            pathname: STATIC_ROUTES_PATHS.peopleDashboard,
            state: { subsetTab: 3 }
        });
    };

    return (
        <StyledSummaryCounter>
            <div className="summary-counter-content">
                <div
                    className={`summary-box ${getClassForCurrent(permissions)}`}
                    onClick={
                        canAccessPeopleCurrent(permissions)
                            ? handleOnTotalClick
                            : undefined
                    }
                >
                    <div className="labels">
                        <div className="label" data-testid="total-label">
                            Total Employees
                        </div>
                        <div className="count">{currentEmployeesCount}</div>
                    </div>
                    <div className="icons">
                        <CurrentEmployeesIcon />
                    </div>
                </div>
                <div
                    className={`summary-box ${getClassNameForNew(permissions)}`}
                    onClick={
                        canAccessPeopleNew(permissions)
                            ? handleOnNewClick
                            : undefined
                    }
                >
                    <div className="labels">
                        <div className="label" data-testid="new-label">
                            New Starters
                        </div>
                        <div className="count">{newEmployeesCount}</div>
                    </div>
                    <div className="icons">
                        <NewEmployeesIcon />
                    </div>
                </div>
                <div
                    className={`summary-box ${getClassForLeavers(permissions)}`}
                    onClick={
                        canAccessPeopleLeavers(permissions)
                            ? handleOnLeaversClick
                            : undefined
                    }
                >
                    <div className="labels">
                        <div className="label" data-testid="leaver-label">
                            Leavers
                        </div>
                        <div className="count">{leaverEmployeesCount}</div>
                    </div>
                    <div className="icons">
                        <LeaverEmployeesIcon />
                    </div>
                </div>
            </div>
        </StyledSummaryCounter>
    );
};
