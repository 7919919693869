import 'rc-tabs/assets/index.css';

import React, { useEffect, useState } from 'react';

import {
    getDefaultTabPeopleDetails,
    shouldPersonDetailsTabBeDisabled
} from 'people/utils/peoplePermissionsHelper';
import Tabs, { TabPane } from 'rc-tabs';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import { useParams, useHistory } from 'react-router-dom';
import { STATIC_ROUTES_PATHS } from 'routes';

import { Benefits } from './Benefits/Benefits';
import MainInformation from './MainInformation/MainInformation';
import { PersonalInformation } from './PersonalInformation/PersonalInformation';
import { StyledEmployeeInfoTabs } from './styled/EmployeeInfoTabs.styled';

//ArrowIcons
const arrowPath =
    'M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h' +
    '-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v' +
    '60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91' +
    '.5c1.9 0 3.8-0.7 5.2-2L869 536.2c14.7-12.8 14.7-35.6 0-48.4z';

const getSvg = (path, style = {}, svgStyle = {}) => {
    return (
        <i style={style}>
            <svg
                viewBox="0 0 1024 1024"
                width="1em"
                height="1em"
                fill="currentColor"
                style={{ verticalAlign: '-.125em ', ...svgStyle }}
            >
                <path d={path} p-id="5827" />
            </svg>
        </i>
    );
};

const next = getSvg(arrowPath);
const prev = getSvg(
    arrowPath,
    {},
    {
        transform: 'scaleX(-1)'
    }
);

const TABS = [
    {
        placeholder: 'loading',
        tabName: 'Main Information',
        key: 'main-information',
        disabled: shouldPersonDetailsTabBeDisabled,
        component: props => <MainInformation {...props} />
    },
    {
        placeholder: 'loading',
        tabName: 'Personal Information',
        disabled: shouldPersonDetailsTabBeDisabled,
        key: 'personal-information',
        component: props => <PersonalInformation {...props} />
    },
    {
        placeholder: 'loading',
        tabName: 'Benefits',
        disabled: shouldPersonDetailsTabBeDisabled,
        key: 'benefits',
        component: props => <Benefits {...props} />
    },
    {
        placeholder: 'loading',
        tabName: 'Learning',
        disabled: shouldPersonDetailsTabBeDisabled,
        key: 'learning',
        component: props => {}
    },
    {
        placeholder: 'loading',
        tabName: 'Annual Reviews',
        disabled: shouldPersonDetailsTabBeDisabled,
        key: 'annual-reviews',
        component: props => {}
    },
    {
        placeholder: 'loading',
        tabName: 'Inventory',
        disabled: shouldPersonDetailsTabBeDisabled,
        key: 'inventory',
        component: props => {}
    }
];

const EmployeeInfoTabs = ({ personId, permissions }) => {
    const params = useParams();
    const history = useHistory();

    const [employeeInfoTabState, setEmployeeInfoTabState] = useState({
        tabBarPosition: 'top',
        activeKey: getDefaultTabPeopleDetails(permissions, params.activeTab),
        useIcon: false
    });

    const disabledTabs = TABS.map(
        tab => tab.disabled(tab.tabName, permissions) && tab.key
    ).filter(Boolean);

    useEffect(() => {
        if (disabledTabs.includes(params.activeTab)) {
            history.replace(
                STATIC_ROUTES_PATHS.personDetails
                    .replace(':id', personId)
                    .replace(
                        ':activeTab',
                        getDefaultTabPeopleDetails(
                            permissions,
                            params.activeTab
                        )
                    )
            );
        }
    }, [params.activeKey]);

    const onChange = activeKey => {
        history.push(
            STATIC_ROUTES_PATHS.personDetails
                .replace(':id', personId)
                .replace(':activeTab', activeKey)
        );
        setEmployeeInfoTabState(prev => {
            return { ...prev, activeKey: activeKey };
        });
    };

    const saveBar = bar => bar;

    const { activeKey, tabBarPosition, useIcon } = employeeInfoTabState;

    const cls = (useIcon && 'rc-tabs-custom-icon') || undefined;

    const iconProps = employeeInfoTabState.useIcon
        ? {
              nextIcon: next,
              prevIcon: prev
          }
        : {};

    return (
        <StyledEmployeeInfoTabs>
            <div className="employee-info-tabs">
                <Tabs
                    activeKey={activeKey}
                    className={cls}
                    tabBarPosition={tabBarPosition}
                    renderTabBar={() => (
                        <ScrollableInkTabBar
                            ref={saveBar}
                            onTabClick={key => {}}
                            {...iconProps}
                        />
                    )}
                    renderTabContent={() => <TabContent />}
                    onChange={onChange}
                >
                    {TABS.map(tab => (
                        <TabPane
                            placeholder={tab.placeholder}
                            tab={tab.tabName}
                            disabled={tab.disabled(tab.tabName, permissions)}
                            key={tab.key}
                        >
                            {tab.component({
                                personId,
                                historicalModalOpen: false,
                                permissions
                            })}
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        </StyledEmployeeInfoTabs>
    );
};

export default EmployeeInfoTabs;
