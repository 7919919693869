import React from 'react';

import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Routes from './routes';

const App = ({ msalInstance }) => {
    return (
        <MsalProvider instance={msalInstance}>
            <BrowserRouter>
                <Switch>
                    <Routes />
                </Switch>
            </BrowserRouter>
        </MsalProvider>
    );
};

export default App;
