import styled from 'styled-components';

const StyledDashboardHeader = styled.div`
    .admin-dashboard-header-wrapper {
        display: flex;
        flex-direction: column;
        height: 110px;
        padding: 24px 90px 0px 90px;
        height: 4rem;
    }
    .nav-list-items {
        display: flex;
        margin-top: -12px;
        margin-bottom: 0px;
        padding: 0px;
        .spinner-div {
            .ant-spin.ant-spin-lg.ant-spin-spinning {
                padding-top: 100px;
                display: flex;
                justify-content: center;
            }
        }
    }
    .nav-list-items > div {
        width: 100%;
    }
`;

export { StyledDashboardHeader };
