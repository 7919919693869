import React, { useMemo, useState } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { CustomInput } from 'shared/components';
import { CustomCheckbox } from 'shared/components/Checkbox';
import { setClassName } from 'utils/componentPropHelpers';

import DownloadButton from './DownloadButton';
import StyledActionsSection from './styled/ActionsSection.styled';
import { benefitReportActions } from '../../../redux/slices/benefitReport';
import CustomMonthPicker from '../../../shared/components/CustomMonthPicker';
import { CustomSelect } from '../../../shared/components/Select';
import {
    enumToDropdownOptions,
    mapDataForDropdownWithActive
} from '../../../utils/dropdownHelper';
import {
    benefitColumnNames,
    benefitFilters
} from '../../utils/BenefitReport/benefitReportHelper';

const ActionsSection = () => {
    const dispatch = useDispatch();
    const { monthPickerStartValue, monthPickerEndValue, includeOnlyFinishing } =
        useSelector(state => state.benefitReport);

    const { benefitReportsTypes } = useSelector(state => state.dropdown);
    const [fieldErrors, setFieldErrors] = useState({
        monthStartPeriodError: null,
        benefitsError: null
    });

    const handlerSelectBenefits = (val, selectedOption) => {
        const { children: label, value } = selectedOption;
        setFieldErrors(prev => ({
            ...prev,
            benefitsError: null
        }));
        dispatch(benefitReportActions.addBenefitsTag({ label, value }));
    };

    const handlerSelectColumns = (val, selectedOption) => {
        const { value, children: label } = selectedOption;

        dispatch(benefitReportActions.addColumnsTag({ label, value }));
    };

    const handlerSelectFilters = (val, selectedOption) => {
        const { value, children: label } = selectedOption;

        dispatch(benefitReportActions.addFiltersTag({ label, value }));
    };

    const handlePickerStartChange = val => {
        setFieldErrors(prev => ({
            ...prev,
            monthStartPeriodError: null
        }));
        dispatch(
            benefitReportActions.setMonthPickerStartValue(
                moment(val).format('MM/DD/YYYY')
            )
        );
    };

    const handlePickerEndChange = val => {
        const value = val && moment(val).format('MM/DD/YYYY');
        dispatch(benefitReportActions.setMonthPickerEndValue(value));
    };

    const handleCheckboxChange = event => {
        dispatch(
            benefitReportActions.setIncludeOnlyFinishing(event.target.checked)
        );
    };

    const benefitsDropdownOptions = useMemo(
        () =>
            benefitReportsTypes &&
            mapDataForDropdownWithActive(benefitReportsTypes),
        [benefitReportsTypes]
    );
    const columnsDropdownOptions = enumToDropdownOptions(benefitColumnNames);
    const filterDropdownOptions = enumToDropdownOptions(benefitFilters);

    return (
        <StyledActionsSection>
            <div className="selectors-section">
                <div className="selector">
                    <CustomSelect
                        className={`wider ${setClassName(
                            fieldErrors?.benefitsError
                        )}`}
                        label="Benefit"
                        placeholder={'Type or select benefit'}
                        options={benefitsDropdownOptions}
                        name={'benefitType'}
                        value={''}
                        onSelect={handlerSelectBenefits}
                        hasInput={true}
                    />
                </div>
                <div className="selector">
                    <CustomMonthPicker
                        label="Month"
                        name="startDate"
                        value={monthPickerStartValue}
                        className={setClassName(
                            fieldErrors?.monthStartPeriodError
                        )}
                        onChange={handlePickerStartChange}
                    />
                </div>
                {/* <div className="selector">
                    <CustomMonthPicker
                        label="Date End"
                        name="endDate"
                        value={monthPickerEndValue}
                        allowClear={true}
                        disabledDate={monthPickerStartValue}
                        onChange={handlePickerEndChange}
                    />
                </div>
                <div className="selector">
                    <CustomSelect
                        label={'File Columns'}
                        placeholder={'Select a column name'}
                        name="propName"
                        value={''}
                        options={columnsDropdownOptions}
                        onSelect={handlerSelectColumns}
                    />
                </div>
                <div className="selector">
                    <CustomSelect
                        label="Filter"
                        placeholder={'Select a filter'}
                        name={'filter'}
                        value={''}
                        options={filterDropdownOptions}
                        onSelect={handlerSelectFilters}
                    />
                </div> */}
                <div className="selector checkbox">
                    <CustomCheckbox
                        checked={includeOnlyFinishing}
                        onChange={handleCheckboxChange}
                        text="Include only finishing with use on month"
                    />
                </div>
            </div>
            <DownloadButton setFieldErrors={setFieldErrors} />
        </StyledActionsSection>
    );
};

export default ActionsSection;
