import styled from 'styled-components';

const SelectedFilterTags = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    .tags {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        color: var(--mainBlue);
        width: 395px;
    }

    .tags .tag {
        padding-bottom: 5px;
    }

    .tags .tag.ant-tag {
        padding: 6px 10px;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: var(--mainBlue);
        background: rgba(28, 132, 254, 0.15);
        border-radius: 5px;
        border: 0px;
        margin-top: 4px;
    }

    .tags .tag.ant-tag .ant-tag-close-icon svg {
        margin-bottom: 1px;
        color: var(--secondaryBlue);
    }

    .tags .tag.ant-tag .ant-tag-close-icon svg path {
        stroke-width: 40;
        stroke: var(--secondaryBlue);
    }

    .clear-all {
        align-self: flex-start;
        color: var(--mainBlue);
        padding-left: 11px;
        border-left: 1px solid var(--gray);
        font-size: 16px;
        margin-top: 5px;
    }

    .clear-all .close-icon {
        display: inline-flex;
        padding-left: 5px;
    }

    .clear-all .close-icon span.anticon.anticon-close {
        background: rgba(153, 153, 153, 0.16);
        border-radius: 6px;
        padding: 6px;
        color: var(--mainBlue);
    }

    .clear-all .close-icon span.anticon.anticon-close svg path {
        stroke-width: 50;
        stroke: var(--mainBlue);
    }

    .clear-all .close-icon svg {
        width: 8px;
        height: 8px;
    }
`;

export default SelectedFilterTags;
