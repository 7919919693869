import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { workingPeriodActions } from './workingPeriod';
import benefitService from '../../people/services/benefitService';

const initialState = {
    currentBenefits: [],
    pastBenefits: [],
    error: null,
    isLoading: false,
    isLoadingStack: {},
    fakeId: -1,
    //Used for refetching benefits when there is an update in working period end date.
    shouldRefetchBenefits: false
};

const { reducer: benefitReducer, actions } = createSlice({
    name: 'benefits',
    initialState,
    reducers: {
        //Create benefit
        createBenefitStart: state => {
            state.isLoading = true;
        },
        createBenefitSuccess: (state, action) => {
            state.isLoading = false;
            const {
                payload: { data }
            } = action;
            state.currentBenefits = data.currentBenefits;
            state.pastBenefits = data.pastBenefits;
            state.error = null;
        },
        createBenefitFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        createFakeBenefit: (state, action) => {
            const newFakeBenefit = action.payload;
            const newFakeId = state.fakeId;
            newFakeBenefit.benefitCoverPeriodId = newFakeId;
            state.currentBenefits = [newFakeBenefit, ...state.currentBenefits];
            state.fakeId--;
        },

        //Fetch Benefits
        fetchBenefitsStart: state => {
            state.isLoading = true;
        },
        fetchBenefitsSuccess: (state, action) => {
            const {
                payload: { data }
            } = action;
            state.currentBenefits = data.currentBenefits;
            state.pastBenefits = data.pastBenefits;
            state.isLoading = false;
            state.error = null;
            state.shouldRefetchBenefits = false;
        },
        fetchBenefitsFailure: (state, action) => {
            state.currentBenefits = [];
            state.pastBenefits = [];
            state.isLoading = false;
            state.error = action.payload;
            state.shouldRefetchBenefits = false;
        },

        //Delete benefit
        deleteBenefitStart: state => {
            state.isLoading = true;
        },
        deleteBenefitSuccess: (state, action) => {
            state.isLoading = false;
            state.currentBenefits = state.currentBenefits.filter(
                x => x.benefitCoverPeriodId !== action.payload
            );
            state.error = null;
        },
        deleteBenefitFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        //Update Benefits
        updateBenefitsStart: (state, action) => {
            const {
                payload: { benefitIndex, callIndex }
            } = action;
            state.isLoadingStack[benefitIndex] = {
                ...state.isLoadingStack[benefitIndex],
                [callIndex]: true
            };
        },
        updateBenefitsSuccess: (state, action) => {
            const {
                payload: { benefitIndex, callIndex, requestData, fileList }
            } = action;
            state.currentBenefits[benefitIndex] = {
                ...requestData,
                files: fileList
            };
            state.isLoadingStack[benefitIndex] = {
                ...state.isLoadingStack[benefitIndex],
                [callIndex]: undefined
            };
            state.error = null;
        },
        updateBenefitsFailure: (state, action) => {
            const {
                payload: { benefitIndex, callIndex, errorMessage }
            } = action;
            state.isLoadingStack[benefitIndex] = {
                ...state.isLoadingStack[benefitIndex],
                [callIndex]: undefined
            };
            state.error = errorMessage;
        },

        //Upload benefit file
        uploadFileStart: state => {
            state.isLoading = true;
        },
        uploadFileSuccess: (state, action) => {
            const {
                payload: { responseFile, benefitId }
            } = action;
            let index = state.currentBenefits.findIndex(
                x => x.benefitCoverPeriodId === benefitId
            );
            if (index !== -1) {
                state.currentBenefits[index].files.push(responseFile.data);
            } else {
                index = state.pastBenefits.findIndex(
                    x => x.benefitCoverPeriodId === benefitId
                );
                state.pastBenefits[index].files.push(responseFile.data);
            }
            state.isLoading = false;
            state.error = null;
        },
        uploadFileFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        //Delete benefit file
        deleteFileStart: state => {
            state.isLoading = true;
        },
        deleteFileSuccess: (state, action) => {
            const {
                payload: { fileId, benefitId, isPast }
            } = action;

            if (isPast) {
                const index = state.pastBenefits.findIndex(
                    x => x.benefitCoverPeriodId === benefitId
                );
                state.pastBenefits[index] = {
                    ...state.pastBenefits[index],
                    files: state.pastBenefits[index].files.filter(
                        f => f.uid !== fileId
                    )
                };
            } else {
                const index = state.currentBenefits.findIndex(
                    x => x.benefitCoverPeriodId === benefitId
                );

                state.currentBenefits[index] = {
                    ...state.currentBenefits[index],
                    files: state.currentBenefits[index].files.filter(
                        f => f.uid !== fileId
                    )
                };
            }

            state.isLoading = false;
            state.error = null;
        },
        deleteFileFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        //Reset benefit loading state
        resetLoadingStack: state => {
            state.isLoadingStack = {};
            state.error = null;
        },

        //Clear the state
        clearState: state => {
            state.currentBenefits = [];
            state.pastBenefits = [];
            state.error = null;
            state.isLoading = false;
            state.isLoadingStack = {};
            state.fakeId = -1;
            state.shouldRefetchBenefits = false;
        }
    },
    extraReducers: {
        [workingPeriodActions.updateEndDateWorkingPeriodSuccess]: state => {
            state.shouldRefetchBenefits = true;
        }
    }
});

const addBenefit = newBenefitData => {
    return async (dispatch, getState) => {
        const isLoading = getState().isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.createBenefitStart());
            if (newBenefitData.personRelativeMemberId < 0) {
                newBenefitData.personRelativeMemberId = null;
            }
            newBenefitData = {
                ...newBenefitData,
                startDate:
                    newBenefitData.startDate !== null
                        ? moment(newBenefitData.startDate).format('YYYY-MM-DD')
                        : null,
                endDate:
                    newBenefitData.endDate !== null
                        ? moment(newBenefitData.endDate).format('YYYY-MM-DD')
                        : null
            };
            await benefitService.postBenefit(newBenefitData);
            const benefits = await benefitService.getAllBenefitsByPersonId(
                newBenefitData.personId
            );
            dispatch(actions.createBenefitSuccess(benefits));
        } catch (err) {
            dispatch(actions.createBenefitFailure('Something went wrong'));
        }
    };
};

const createFakeBenefit = () => {
    return async dispatch => {
        dispatch(
            actions.createFakeBenefit({
                benefitCoverPeriodId: null,
                coveredById: null,
                personRelativeMemberId: null,
                benefitTypeId: null,
                personId: null,
                cardId: null,
                startDate: null,
                endDate: null
            })
        );
    };
};

const fetchAllBenefitsByPersonId = personId => {
    return async (dispatch, getState) => {
        const isLoading = getState().isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchBenefitsStart());
            const benefits = await benefitService.getAllBenefitsByPersonId(
                personId
            );
            dispatch(actions.fetchBenefitsSuccess(benefits));
        } catch (err) {
            dispatch(actions.fetchBenefitsFailure('Something went wrong'));
        }
    };
};

const updateBenefit = (
    benefitIndex,
    callIndex,
    benefitId,
    requestData,
    fileList
) => {
    return async (dispatch, getState) => {
        const state = getState();

        const pastBenefits = state.benefits.pastBenefits;
        const currentBenefits = state.benefits.currentBenefits;

        const previousBenefitState =
            pastBenefits.find(x => x.benefitCoverPeriodId === benefitId) ??
            currentBenefits.find(x => x.benefitCoverPeriodId === benefitId);

        try {
            dispatch(
                actions.updateBenefitsStart({
                    benefitIndex,
                    callIndex
                })
            );
            if (requestData.personRelativeMemberId < 0) {
                requestData.personRelativeMemberId = null;
            }
            requestData = {
                ...requestData,
                startDate:
                    requestData.startDate !== null
                        ? moment(requestData.startDate).format('YYYY-MM-DD')
                        : null,
                endDate:
                    requestData.endDate !== null
                        ? moment(requestData.endDate).format('YYYY-MM-DD')
                        : null
            };
            await benefitService.patchBenefits(benefitId, requestData);
            dispatch(
                actions.updateBenefitsSuccess({
                    benefitIndex,
                    callIndex,
                    requestData,
                    fileList
                })
            );

            if (
                moment(previousBenefitState.endDate).format('YYYY-MM-DD') !==
                    moment(requestData.endDate).format('YYYY-MM-DD') ||
                previousBenefitState.coveredById !== requestData.coveredById
            ) {
                dispatch(fetchAllBenefitsByPersonId(requestData.personId));
            }
        } catch (err) {
            var message = err.response.data.messages[0];

            dispatch(
                actions.updateBenefitsFailure({
                    benefitIndex,
                    callIndex,
                    errorMessage: message ? message : 'Something went wrong'
                })
            );
        }
    };
};

const removeBenefit = benefitId => {
    return async (dispatch, getState) => {
        const isLoading = getState().isLoading;
        if (isLoading) {
            return;
        }
        try {
            dispatch(actions.deleteBenefitStart());

            if (benefitId > 0) {
                await benefitService.deleteBenefitById(benefitId);
            }

            dispatch(actions.deleteBenefitSuccess(benefitId));
        } catch (err) {
            dispatch(actions.deleteBenefitFailure('Something went wrong'));
        }
    };
};

const resetBenefitLoadingStack = () => {
    return dispatch => {
        dispatch(actions.resetLoadingStack());
    };
};

const uploadFile = (file, personId, benefitId, documentTypeId) => {
    return async (dispatch, getState) => {
        const isLoading = getState().isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.uploadFileStart());

            const responseFile = await benefitService.uploadFile(
                file,
                personId,
                benefitId,
                documentTypeId
            );
            dispatch(actions.uploadFileSuccess({ responseFile, benefitId }));
        } catch (err) {
            dispatch(actions.uploadFileFailure('Something went wrong'));
        }
    };
};

const deleteFile = (fileId, benefitId, isPast) => {
    return async (dispatch, getState) => {
        const isLoading = getState().isLoading;

        if (isLoading) {
            return;
        }
        try {
            dispatch(actions.deleteFileStart());

            if (fileId > 0) {
                await benefitService.deleteFileById(fileId);
            }

            dispatch(actions.deleteFileSuccess({ fileId, benefitId, isPast }));
        } catch (err) {
            dispatch(actions.deleteFileFailure('Something went wrong'));
        }
    };
};

const resetBenefitState = () => {
    return dispatch => {
        dispatch(actions.clearState());
    };
};

export {
    addBenefit,
    createFakeBenefit,
    benefitReducer,
    fetchAllBenefitsByPersonId,
    removeBenefit,
    updateBenefit,
    uploadFile,
    deleteFile,
    resetBenefitLoadingStack,
    resetBenefitState
};
