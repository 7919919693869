import styled from 'styled-components';

const StyledSubsetTabSearch = styled.div`
    @media screen and (max-width: 768px) {
        .customInput {
            width: 351px;
        }
    }
`;

export default StyledSubsetTabSearch;
