import styled from 'styled-components';

const StyledTextArea = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    .ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn {
        background: var(--primaryWhite);
        border: 1px solid var(--gray) !important;
        box-sizing: border-box;
        border-radius: 10px;
        flex: 1;
    }

    .input-label {
        font-size: 14px;
        font-weight: 600;
    }

    textarea {
        border: none;
        font-weight: normal;
        border-radius: 10px;
        &:disabled {
            color: var(--darkGray);
        }
    }

    .counter {
        text-align: right;
        color: rgba(0, 0, 0, 0.45);
    }

    &.error {
        .ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn {
            border-color: var(--red) !important;
        }

        .counter {
            color: var(--red);
        }

        .ant-input:focus,
        .ant-input-affix-wrapper:focus {
            box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.2);
        }
    }
`;

export default StyledTextArea;
