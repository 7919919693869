import React, { useState, useEffect } from 'react';

import { Upload } from 'antd';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateBenefit,
    removeBenefit,
    addBenefit,
    deleteFile
} from 'redux/slices/benefit';
import {
    CustomInput,
    CustomSelect,
    CustomDateSelector,
    FieldsSection,
    CustomConfirmationModal
} from 'shared/components';
import { successNotification } from 'shared/components/Notifications';
import { selectPlaceholder, setClassName } from 'utils/componentPropHelpers';
import {
    getCoveredByOptions,
    getRelativeMemberOptions,
    mapDataForDropdown
} from 'utils/dropdownHelper';

import { StyledBenefit } from './styled/Benefit.styled';
import { validateForm } from '../../../utils/Details/Benefits/benefitsFormHelper';
import { normalizeRequestData } from '../../../utils/modelMapper';

export const Benefit = ({
    benefit,
    benefitIndex,
    personId,
    setShowInfoText,
    callCounter,
    setCallCounter,
    onCurrentBenefitUpdate,
    isPast,
    disableCoveredByMentorMateOption,
    disabledDate
}) => {
    const INITIAL_VALUES = {
        benefitCoverPeriodId: benefit.benefitCoverPeriodId,
        coveredById: benefit.coveredById,
        personId: benefit.personId,
        benefitTypeId: benefit.benefitTypeId,
        cardId: benefit.cardId,
        benefitTypeName: benefit?.benefitTypeName,
        startDate: benefit.startDate,
        endDate: benefit.endDate,
        personRelativeMemberId:
            benefit.personRelativeMemberId ?? benefit.personId * -1
    };

    const isNewBenefit = INITIAL_VALUES.benefitCoverPeriodId < 0;

    const [initialValuesFormik, setInitialValuesFormik] = useState({
        ...INITIAL_VALUES
    });
    const [errors, setErrors] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [benefitTypes, personName, relativeMembers] = useSelector(state => [
        state.dropdown.benefitTypes,
        state.people.person.name,
        state.dropdown.relativeMembers
    ]);

    const dispatch = useDispatch();

    const benefitTypeOptions = isPast ? [] : mapDataForDropdown(benefitTypes);
    const relativeMemberOptions = getRelativeMemberOptions(
        personName,
        personId,
        relativeMembers
    );

    const getValidCoveredByOptions = (coveredByOptions, benefitTypeId) => {
        if (!benefitTypeId) {
            return coveredByOptions;
        }

        let next = coveredByOptions;

        const availableCoverageTypes = benefitTypes.find(
            bt => bt.id === benefitTypeId
        )?.coverageTypes;

        next[0].disabled =
            !availableCoverageTypes ||
            !availableCoverageTypes.includes(1) ||
            disableCoveredByMentorMateOption;
        next[1].disabled =
            !availableCoverageTypes || !availableCoverageTypes.includes(2);

        return next;
    };

    const [coveredByOptions, setCoveredByOptions] = useState(
        getValidCoveredByOptions(
            getCoveredByOptions(personName),
            initialValuesFormik.benefitTypeId
        )
    );

    const isValidId = id => id && id > 0;

    const validate = values => {
        const errorsIncome = validateForm(values);
        setErrors(errorsIncome);
    };

    const handleBlur = (inputName, value) => {
        const newValue = value?.toString().trim() ?? '';
        const initialVal = initialValuesFormik[inputName]?.toString() ?? '';
        if (newValue !== initialVal && !errors[inputName]) {
            setInitialValuesFormik(prev => {
                let temp = { ...prev };
                temp[inputName] = value;
                return temp;
            });
            const checkValues = { ...initialValuesFormik, [inputName]: value };
            if (
                isValidId(checkValues?.benefitTypeId) &&
                isValidId(checkValues?.coveredById) &&
                checkValues?.startDate &&
                checkValues?.personRelativeMemberId
            ) {
                setCallCounter(prev => prev + 1);
                setShowInfoText(true);

                let requestObj = { ...initialValuesFormik, [inputName]: value };

                if (benefit.benefitCoverPeriodId > 0) {
                    dispatch(
                        updateBenefit(
                            benefitIndex,
                            callCounter + 1,
                            benefit.benefitCoverPeriodId,
                            normalizeRequestData(requestObj),
                            fileList
                        )
                    );

                    if (!isPast) {
                        onCurrentBenefitUpdate(requestObj);
                    }
                }

                if (benefit.benefitCoverPeriodId < 0) {
                    requestObj.personId = parseInt(personId, 10);
                    dispatch(addBenefit(normalizeRequestData(requestObj)));
                }
            }
        }
    };
    const [clickedFile, setClickedFile] = useState();

    const handleDelete = () => {
        setIsModalOpen(false);

        if (clickedFile === undefined) {
            dispatch(removeBenefit(benefit.benefitCoverPeriodId));
        } else {
            dispatch(
                deleteFile(
                    clickedFile.uid,
                    benefit.benefitCoverPeriodId,
                    isPast
                )
            );
            setFileList(fileList.filter(item => item.uid !== clickedFile.uid));

            successNotification(`Successfully deleted file`, 5);
        }
    };

    const handlePreview = file => {
        const newWindow = window.open(
            file.url,
            '_blank',
            'noopener,noreferrer'
        );
        if (newWindow) newWindow.opener = null;
    };

    const [fileList, setFileList] = useState(benefit.files);

    const fileProps = {
        showUploadList: {
            showRemoveIcon: true,
            showPreviewIcon: true
        },
        onRemove: file => {
            setClickedFile(file);
            setIsModalOpen(true);
        }
    };

    return (
        <StyledBenefit data-testid={`benefit-${benefit.benefitCoverPeriodId}`}>
            <Formik initialValues={INITIAL_VALUES} validate={validate}>
                {props => {
                    const { values, setFieldValue } = props;

                    const benefitTypeName = isPast
                        ? benefit.benefitTypeName
                        : values.benefitTypeId;

                    const personField = !values.personRelativeMemberId
                        ? null
                        : values.personRelativeMemberId;
                    return (
                        <Form>
                            <div className="section-splitter"></div>
                            <FieldsSection>
                                <div className="section-inputs">
                                    <CustomSelect
                                        value={benefitTypeName}
                                        label="Benefit *"
                                        name="benefitTypeId"
                                        placeholder={selectPlaceholder}
                                        options={
                                            isNewBenefit
                                                ? benefitTypeOptions.filter(
                                                      x => x.active
                                                  )
                                                : benefitTypeOptions
                                        }
                                        onChange={(name, val) => {
                                            setCoveredByOptions(prev => {
                                                return getValidCoveredByOptions(
                                                    prev,
                                                    val
                                                );
                                            });
                                            setFieldValue(name, val);
                                        }}
                                        disabled={isPast}
                                        onBlur={(name, val) => {
                                            handleBlur(name, val);
                                        }}
                                    />

                                    <CustomSelect
                                        value={values.coveredById}
                                        label="Covered by *"
                                        name="coveredById"
                                        placeholder={selectPlaceholder}
                                        options={coveredByOptions}
                                        disabled={
                                            isPast || !values.benefitTypeId
                                        }
                                        onChange={(name, val) => {
                                            setFieldValue(name, val);
                                        }}
                                        onBlur={(name, val) => {
                                            handleBlur(name, val);
                                        }}
                                    />

                                    <CustomInput
                                        className={setClassName(errors?.cardId)}
                                        value={values.cardId}
                                        label="Card ID"
                                        name="cardId"
                                        placeholder="Type..."
                                        allowClear={true}
                                        specialEnter={true}
                                        isNormalChange={false}
                                        disabled={isPast}
                                        onChange={(name, val) => {
                                            setFieldValue(name, val);
                                        }}
                                        onBlur={name => {
                                            handleBlur(name, values.cardId);
                                        }}
                                    />
                                    <CustomSelect
                                        value={personField}
                                        label="Person *"
                                        name="personRelativeMemberId"
                                        placeholder={selectPlaceholder}
                                        options={relativeMemberOptions}
                                        onChange={(name, val) => {
                                            setFieldValue(name, val);
                                        }}
                                        disabled={isPast}
                                        onBlur={(name, val) => {
                                            handleBlur(name, val);
                                        }}
                                    />
                                    <CustomDateSelector
                                        value={values.startDate}
                                        label="Start *"
                                        name="startDate"
                                        onChange={setFieldValue}
                                        allowClear={false}
                                        disabled={isPast}
                                        disabledDate={
                                            values.coveredById == 1 &&
                                            disabledDate
                                        }
                                        onBlur={(name, val) => {
                                            handleBlur(name, val);
                                        }}
                                    />
                                    <CustomDateSelector
                                        value={values.endDate}
                                        label="End"
                                        name="endDate"
                                        specialDate={true}
                                        onChange={setFieldValue}
                                        disabledDate={values.startDate}
                                        onBlur={(name, val) => {
                                            handleBlur(name, val);
                                        }}
                                    />
                                </div>
                                {fileList && (
                                    <div className="custom-upload-div">
                                        <div className="custom-upload-label">
                                            Uploaded files
                                        </div>
                                        <Upload
                                            {...fileProps}
                                            fileList={fileList}
                                            onPreview={handlePreview}
                                        />
                                    </div>
                                )}
                            </FieldsSection>
                            {!isPast && (
                                <div
                                    data-testid="delete-section"
                                    className="delete-section"
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    Delete benefit
                                </div>
                            )}
                        </Form>
                    );
                }}
            </Formik>
            {isModalOpen && (
                <CustomConfirmationModal
                    visible={isModalOpen}
                    setVisible={setIsModalOpen}
                    title={'Confirmation'}
                    contentText={
                        clickedFile === undefined
                            ? `You are going to delete this benefit?`
                            : `You are going to delete this file?`
                    }
                    handleConfirm={handleDelete}
                />
            )}
        </StyledBenefit>
    );
};
