import { getKey } from 'utils/getKey';

export const shouldPeoplePageTabBeDisabled = (label, permissions) => {
    if (!permissions.canViewPeoplePage) {
        return true;
    }

    if (label == 'All') {
        return !(
            permissions.canViewAllTab &&
            permissions.canViewCurrentTab &&
            permissions.canViewLeaversTab &&
            permissions.canViewNewTab &&
            permissions.canViewExTab
        );
    }

    if (label == 'Current') {
        return !permissions.canViewCurrentTab;
    }

    if (label == 'New') {
        return !permissions.canViewNewTab;
    }

    if (label == 'Leavers') {
        return !permissions.canViewLeaversTab;
    }

    if (label == 'Ex') {
        return !permissions.canViewExTab;
    }

    return true;
};

export const setDefaultTabPeoplePage = (tabNumber, permissions) => {
    const tabLabelMappingPeoplePage = {
        0: 'All',
        1: 'Current',
        2: 'New',
        3: 'Leavers',
        4: 'Ex'
    };

    const currentPeopleTabNumber = 1;

    if (
        !shouldPeoplePageTabBeDisabled(
            tabLabelMappingPeoplePage[tabNumber],
            permissions
        )
    ) {
        return tabNumber;
    }

    for (const tabNumber in tabLabelMappingPeoplePage) {
        if (Object.hasOwnProperty.call(tabLabelMappingPeoplePage, tabNumber)) {
            const label = tabLabelMappingPeoplePage[tabNumber];

            if (!shouldPeoplePageTabBeDisabled(label, permissions)) {
                return Number(tabNumber);
            }
        }
    }

    return currentPeopleTabNumber;
};

export const shouldPersonDetailsTabBeDisabled = (label, permissions) => {
    if (!permissions.canViewPeopleDetailsPage) {
        return true;
    }

    if (label === 'Main Information') {
        return !permissions.canSeeMainInformationTab;
    }

    if (label === 'Personal Information') {
        return !permissions.canSeePersonalInformationTab;
    }

    if (label === 'Benefits') {
        return !permissions.canSeePersonBenefitsTab;
    }

    return true;
};

export const getDefaultTabPeopleDetails = (permissions, activeTab) => {
    const tabPermissions = {
        'main-information': permissions.canSeeMainInformationTab,
        'personal-information': permissions.canSeePersonalInformationTab,
        benefits: permissions.canSeePersonBenefitsTab
    };

    if (tabPermissions[activeTab]) {
        return activeTab;
    }

    return getKey(tabPermissions, value => value);
};

export const canSeeTabsPeopleDetails = permissions => {
    return (
        permissions.canViewPeopleDetailsPage &&
        (permissions.canSeeMainInformationTab ||
            permissions.canSeePersonalInformationTab ||
            permissions.canSeePersonBenefitsTab)
    );
};

export const canAddWorkingPeriod = permissions => {
    return (
        permissions.canViewPeopleDetailsPage &&
        permissions.canSeeMainInformationTab &&
        permissions.canAddWorkingPeriod
    );
};

export const canEditWorkingPeriod = permissions => {
    return (
        permissions.canViewPeopleDetailsPage &&
        permissions.canSeeMainInformationTab &&
        permissions.canEditWorkingPeriod
    );
};

export const canDeleteWorkingPeriod = permissions => {
    return (
        permissions.canViewPeopleDetailsPage &&
        permissions.canSeeMainInformationTab &&
        permissions.canDeleteWorkingPeriod
    );
};

export const getDisabledClassNameAddWorkingPeriod = permissions => {
    if (canAddWorkingPeriod(permissions)) {
        return '';
    }

    return 'disabled';
};

export const getDisabledClassNameDeleteWorkingPeriod = permissions => {
    if (canDeleteWorkingPeriod(permissions)) {
        return '';
    }

    return 'disabled';
};
