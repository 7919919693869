import React, { useEffect, useState } from 'react';

import { CustomSelect } from './Select';
import { StyledAsyncSearchSelect } from './styled/AsyncSearchSelect.styled';
import { mapDataForDropdown } from '../../utils/dropdownHelper';

const DEBOUNCE_TIME = 600;

export const AsyncSearchSelect = ({
    name,
    value,
    setValue,
    setValueOption,
    placeholder = '',
    label,
    defaultOption,
    displayDefaultOption = true,
    disabled = false,
    getData,
    allowClear,
    onBlur = () => {},
    transformFunction = mapDataForDropdown,
    initialCallProps = ['', []] //set for generic call if we want with more than one extra param
}) => {
    let TIMER = null;
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    // initial fetch.
    useEffect(() => {
        handleOptionsSearch(...initialCallProps);
    }, []);

    // consecutive fetches for options.
    const handleOptionsSearch = (filter, extraProps = []) => {
        //debounce the call.
        clearTimeout(TIMER);
        TIMER = setTimeout(async () => {
            setLoading(true);
            const resp = await getData(filter, ...extraProps);
            const transformed = transformFunction(resp);
            setOptions(transformed);
            setLoading(false);
        }, DEBOUNCE_TIME);
    };

    let selectOptions = displayDefaultOption
        ? [defaultOption, ...options]
        : options;

    return (
        <>
            <StyledAsyncSearchSelect>
                <div className="async-search-select">
                    {label && (
                        <div className="async-search-select-label">{label}</div>
                    )}
                    <CustomSelect
                        name={name}
                        options={selectOptions}
                        value={value}
                        onChange={(name, val) => {
                            if (setValueOption) {
                                setValueOption(name, val);
                                if (
                                    val === '' ||
                                    val === null ||
                                    val === undefined
                                ) {
                                    handleOptionsSearch('');
                                }
                            } else {
                                setValue(val);
                            }
                        }}
                        disabled={disabled}
                        placeholder={placeholder}
                        loading={loading}
                        filterOption={false}
                        hasInput={true}
                        onBlur={onBlur}
                        onSearch={handleOptionsSearch}
                        allowClear={allowClear}
                    />
                </div>
            </StyledAsyncSearchSelect>
        </>
    );
};
