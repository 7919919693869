import Checkbox from 'antd/lib/checkbox/Checkbox';

import { StyledCheckbox } from './styled/CustomCheckbox.styled';

export const CustomCheckbox = ({
    checked,
    onChange,
    text,
    disabled,
    className
}) => {
    const handleChange = event => {
        onChange(event);
    };
    return (
        <StyledCheckbox>
            <div className={`${className ? `${className}` : ''}`}>
                <Checkbox
                    checked={checked}
                    onClick={handleChange}
                    disabled={disabled}
                >
                    {text}
                </Checkbox>
            </div>
        </StyledCheckbox>
    );
};
