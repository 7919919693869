import styled from 'styled-components';

const StyledBenefitPriceWrapper = styled.div`
    button {
        font-size: 14px;
        line-height: 20px;
        &.btn-add {
            color: var(--mainBlue);
        }
        &.btn-delete {
            color: var(--darkGray);
            margin-left: auto;
        }
    }
    .bottom-section {
        display: flex;
        width: 100%;
        padding-top: 25px;
        &.last {
            padding-top: 10px;
        }
    }
`;

export default StyledBenefitPriceWrapper;
