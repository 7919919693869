import React, { useContext, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import PermissionsContext from 'shared/utils/permissionStore';
import { useDebounce } from 'use-debounce';

import { GlobalSearch } from './GlobalSearch';
import Navigation from './Navigation';
import { StyledHeader } from './styled/Header.styled';
import authService from '../../../auth/services/authService';
import { PersonModal } from '../../../people/components/shared/PersonModal';
import peopleService from '../../../people/services/peopleService';
import { STATIC_ROUTES_PATHS } from '../../../routes';
import { CustomButton } from '../../../shared/components/Button';
import { globalSearchRegex } from '../../constants/regexConstants';
import AddUserIcon from '../../theme/assets/icons/AddUserIcon';
import { Heading } from '../Heading';
import { Media } from '../Media';
import ProfileSubmenu from '../ProfileSubmenu';

const Header = props => {
    const { history } = props;
    const [userData, setUserData] = useState({
        email: '',
        picture: ''
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const [autocompleteOptions, setAutocompleteOptions] = useState(undefined);
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue] = useDebounce(searchValue, 500);
    const permissions = useContext(PermissionsContext).permissions;

    const maxLettersLength = 100;

    const borderButton = {
        border: '1px solid #03A8F5',
        boxSizing: 'border-box',
        borderRadius: '6px'
    };

    //fetch user data
    useEffect(() => {
        const res = authService.getUserData();
        if (res != null) {
            const { email, picture } = res;
            setUserData({ email: email, picture: picture });
        }
    }, []);

    //fetch data for the global search with the debounced val
    useEffect(() => {
        getAutocompleteOptions(debouncedSearchValue);
    }, [debouncedSearchValue]);

    //clear the search val & the autocomplete options when the input is hidden
    useEffect(() => {
        if (!showAutocomplete) {
            setSearchValue('');
            setAutocompleteOptions([]);
        }
    }, [showAutocomplete]);

    //helper function used to transform the single render item of the autocomplete options
    const renderItem = (value, text, label) => ({
        value: value,
        label: (
            <>
                <span dangerouslySetInnerHTML={{ __html: label }} />
            </>
        ),
        text: text
    });

    const getAutocompleteOptions = async value => {
        if (
            debouncedSearchValue !== undefined &&
            debouncedSearchValue.length >= 3
        ) {
            const matchedPeople = await peopleService.searchPeople(value);
            const autocompleteOptions = matchedPeople.map(person => ({
                value: person.personId.toString(),
                label: person.personData
            }));
            const transformedAutocompleteOptions = autocompleteOptions.map(
                (item, i) => {
                    const regex = new RegExp(value, 'i');
                    const replacedLabel = item.label.replace(
                        regex,
                        `<strong>${value.toUpperCase()}</strong>`
                    );
                    return renderItem(item.value, item.label, replacedLabel);
                }
            );
            setAutocompleteOptions(transformedAutocompleteOptions);
        } else {
            setAutocompleteOptions([]);
        }
    };

    const handleAutocompleteSelect = (value, option) => {
        setSearchValue(option.text);
        setAutocompleteOptions([]);
        history.push(
            STATIC_ROUTES_PATHS.personDetails
                .replace(':id', value)
                .replace(':activeTab', 'main-information')
        );
    };

    const handleAutocompleteClick = () => {
        setShowAutocomplete(true);
    };

    const handleOnChange = value => {
        setSearchValue(value);
    };

    const handleInputBlur = () => {
        setShowAutocomplete(false);
        handleOnChange(undefined);
    };

    const handleKeyPress = e => {
        const pressedKey = String.fromCharCode(
            !e.charCode ? e.which : e.charCode
        );

        const matchesRegex = new RegExp(globalSearchRegex).test(pressedKey);

        if (maxLettersLength) {
            const isAboveLimit = e.target.value.length > maxLettersLength - 1;

            const shouldProhibitTyping = matchesRegex && isAboveLimit;
            if (shouldProhibitTyping) {
                e.preventDefault();
            }
        }

        if (!matchesRegex) {
            e.preventDefault();
        }
    };

    const handlePaste = e => {
        const clipboardString = e.clipboardData.getData('Text').toString();
        const matchesRegex = new RegExp(globalSearchRegex).test(
            clipboardString
        );
        let shouldProhibitPaste;
        //if current value exists compare the clipboard data against it
        if (!isEmpty(searchValue)) {
            const totalLength = searchValue.length + clipboardString.length;
            shouldProhibitPaste =
                !matchesRegex || totalLength > maxLettersLength;
        } else {
            shouldProhibitPaste =
                !matchesRegex || clipboardString.length > maxLettersLength;
        }

        if (shouldProhibitPaste) {
            e.preventDefault();
        }
    };

    return (
        <StyledHeader>
            <div className="header">
                <div className="header-inner">
                    <div className="header-navigation">
                        <div className="logo">
                            <Heading size={4} text={'Humate'} />
                        </div>
                        <Navigation />
                    </div>
                    <div className="widgets">
                        <GlobalSearch
                            autoCompleteClassName={'header-autocomplete'}
                            inputClassName={'"header-autocomplete-input"'}
                            inputPlaceholder={'Search by name and email'}
                            shouldShowInput={showAutocomplete}
                            options={autocompleteOptions}
                            value={searchValue}
                            autoCompleteOnChange={handleOnChange}
                            autoCompleteOnSelect={handleAutocompleteSelect}
                            autoCompleteOnClick={handleAutocompleteClick}
                            autoCompleteOnPaste={handlePaste}
                            inputOnBlur={handleInputBlur}
                            inputOnKeyPress={handleKeyPress}
                            allowClear={true}
                        />
                        <div className="header-buttons">
                            <CustomButton
                                style={borderButton}
                                className={'button'}
                                iconComponent={AddUserIcon}
                                text={'Add Employee'}
                                onClick={() => setIsModalOpen(true)}
                                disabled={!permissions.canCreateNewPerson}
                            />
                        </div>
                        <div className="icons">
                            <div className="user-profile-icon">
                                <Media
                                    className={'wide-avatar-bordered'}
                                    photoUrl={userData.picture}
                                />
                                <div className="sub-menu">
                                    <ProfileSubmenu
                                        userEmail={userData.email}
                                        history={history}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <PersonModal
                    history={history}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    title={'Create Person'}
                    action={'created'}
                />
            )}
        </StyledHeader>
    );
};

export default Header;
