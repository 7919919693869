import React, { useContext } from 'react';

import { createRoleTableColumns } from 'admin/utils/Dashboard/roleColumns';
import { createUserTableColumns } from 'admin/utils/Dashboard/usersColumns';
import {
    setDefaultTab,
    shouldDisableTab
} from 'admin/utils/shared/adminPermissionsHelper';
import PermissionsContext from 'shared/utils/permissionStore';

import { BenefitsTab } from './BenefitsTab';
import { DiscountsTab } from './DiscountsTab';
import { OffersTab } from './OffersTab';
import { ResourcesTab } from './ResourcesTab';
import { RolesTab } from './RolesTab';
import { StyledDashboardTabs } from './styled/DashboardTabs.styled';
import { UsersTab } from './UsersTab';
import TabsWrapper from '../../../shared/components/DashboardTabs/TabsWrapper';
import { createBenefitTableColumns } from '../../utils/Dashboard/benefitColumns';
import { createDiscountTableColumns } from '../../utils/Dashboard/discountColumns';
import { createOfferTableColumns } from '../../utils/Dashboard/offerColumns';

export const AdminDashboardTabs = ({ history }) => {
    const permissions = useContext(PermissionsContext).permissions;

    const defaultTab = setDefaultTab(permissions);
    const TABS = [
        {
            label: 'Flexible Benefits',
            value: 1,
            disabled: shouldDisableTab,
            handleCreateColumns: createBenefitTableColumns,
            child: props => (
                <BenefitsTab
                    subset={props.element}
                    handleCreateColumns={props.element.handleCreateColumns}
                    disabled={props.element.disabled}
                    permissions={props.permissions}
                />
            )
        },
        {
            label: 'Non-Flexible Benefits',
            value: 2,
            disabled: shouldDisableTab,
            handleCreateColumns: createBenefitTableColumns,
            child: props => <></>
        },
        {
            label: 'Discounts',
            value: 3,
            disabled: shouldDisableTab,
            handleCreateColumns: createDiscountTableColumns,
            child: props => {
                return (
                    <DiscountsTab
                        subset={props.element}
                        handleCreateColumns={props.element.handleCreateColumns}
                        disabled={props.element.disabled}
                        permissions={props.permissions}
                    />
                );
            }
        },
        {
            label: 'Offers',
            value: 4,
            disabled: shouldDisableTab,
            handleCreateColumns: createOfferTableColumns,
            child: props => {
                return (
                    <OffersTab
                        subset={props.element}
                        handleCreateColumns={props.element.handleCreateColumns}
                        disabled={props.element.disabled}
                        permissions={props.permissions}
                    />
                );
            }
        },
        {
            label: 'Roles',
            value: 5,
            disabled: shouldDisableTab,
            handleCreateColumns: createRoleTableColumns,
            child: props => {
                return (
                    <RolesTab
                        subset={props.element}
                        handleCreateColumns={props.element.handleCreateColumns}
                        disabled={props.element.disabled}
                        permissions={props.permissions}
                    />
                );
            }
        },
        {
            label: 'Users',
            value: 6,
            disabled: shouldDisableTab,
            handleCreateColumns: createUserTableColumns,
            child: props => (
                <UsersTab
                    subset={props.element}
                    handleCreateColumns={props.element.handleCreateColumns}
                    disabled={props.element.disabled}
                    permissions={props.permissions}
                />
            )
        },
        {
            label: 'Resources',
            value: 7,
            disabled: shouldDisableTab,
            child: props => (
                <ResourcesTab
                    subset={props.element}
                    disabled={props.element.disabled}
                    permissions={props.permissions}
                />
            )
        }
    ];

    const tabElements = TABS.map((element, i) => {
        return (
            <div
                key={`${i}tab`}
                label={`${element.label}`}
                disabled={element.disabled(element.label, permissions)}
            >
                {element.child({ element, history, permissions })}
            </div>
        );
    });

    const handleTabChange = tab => {};

    return (
        <StyledDashboardTabs>
            {defaultTab != null ? (
                <TabsWrapper
                    activeItemIndex={defaultTab}
                    handleTabChange={handleTabChange}
                >
                    {tabElements}
                </TabsWrapper>
            ) : (
                <>
                    <div className="tab-error">
                        An error occurred and data could not be loaded.
                    </div>
                </>
            )}
        </StyledDashboardTabs>
    );
};
