import React from 'react';

export const CurrentEmployeesIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="45"
            fill="none"
            viewBox="0 0 45 45"
        >
            <rect
                width="45"
                height="45"
                fill="#1C84FE"
                fillOpacity="0.12"
                rx="22.5"
            ></rect>
            <g
                stroke="#1C84FE"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                clipPath="url(#clip0)"
            >
                <path d="M26.875 30.375v-1.75a3.5 3.5 0 00-3.5-3.5h-7a3.5 3.5 0 00-3.5 3.5v1.75M19.875 21.625a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM32.125 30.375v-1.75a3.5 3.5 0 00-2.625-3.386M26 14.739a3.5 3.5 0 010 6.781"></path>
            </g>
            <defs>
                <clipPath id="clip0">
                    <path
                        fill="#fff"
                        d="M0 0H21V21H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
