import styled from 'styled-components';

export const StyledFieldSection = styled.div`
    .section-inputs {
        display: flex;
        flex-wrap: wrap;
        margin-right: -20px;
        padding: 0px 0px 0px 0px;
        .customInput,
        .custom-select-div,
        .custom-ac,
        .custom-datepicker {
            margin: 0px;
            width: var(--defaultInputWrapWidth);
            padding-bottom: 20px;
            margin-right: 20px;
            .custom-select-label {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 2px;
                padding: 0px;
                height: 21.5px;
            }
            .ant-select-selector {
                width: var(--defaultInputWrapWidth) !important;
            }
            .ant-picker {
                width: var(--defaultInputWrapWidth) !important;
            }
            input {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                color: var(--darkNavy);
            }
        }
        .no-label {
            padding-top: 23.5px;
        }
    }
`;
