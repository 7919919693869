import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
    fetchSingleBenefitType,
    resetShowUpdateMessage,
    updateBenefitTypeVisibility,
    updateBenefitType
} from 'redux/slices/benefitType';
import { CustomConfirmationModal } from 'shared/components';
import { successNotification } from 'shared/components/Notifications';
import { getFormData } from 'utils/getFormData';

import { CustomTableWithFixedColumns } from '../../../../shared/components/CustomTableWithFixedColumns';
import { prepareBenefitTypeUpdate } from '../../../utils/shared/updateBenefitHelper';
import { BenefitTypeModal } from '../../shared/BenefitTypeModal/BenefitTypeModal';

export const BenefitTypesTable = ({
    totalCount,
    defaultSort,
    handleCreateTableColumns,
    pageSize,
    loading,
    noDataText,
    hasPagination,
    sorted,
    handleSortUpdate,
    boundaryRef,
    permissions
}) => {
    const dispatch = useDispatch();
    const { showUpdateMessage, benefitTypeSingleData, currentBenefits } =
        useSelector(state => state.benefitType);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [isVisibilityModalOpen, setIsVisibilityModalOpen] = useState(false);
    const [selectedBenefitId, setSelectedBenefitId] = useState(null);

    useEffect(() => {
        if (showUpdateMessage) {
            successNotification('Successfully updated Benefit.');
            dispatch(resetShowUpdateMessage());
        }
    }, [showUpdateMessage]);

    const closeModalCallback = () => {
        setSelectedBenefitId(null);
    };

    const handleOpenEdit = benefitTypeId => {
        setSelectedBenefitId(benefitTypeId);
        setEditModalOpen(true);
        dispatch(fetchSingleBenefitType(benefitTypeId));
    };

    const handleEditBenefitType = requestData => {
        window.scrollTo(0, 0);
        const benefitTypeId = selectedBenefitId;
        const convertedRequestData = prepareBenefitTypeUpdate(
            requestData,
            benefitTypeSingleData
        );
        const formData = getFormData(convertedRequestData);
        dispatch(updateBenefitType(benefitTypeId, formData));
        setEditModalOpen(false);
    };

    const handleToggleVisibility = benefitTypeId => {
        setSelectedBenefitId(benefitTypeId);
        setIsVisibilityModalOpen(true);
    };

    const handleVisibilityModalCancel = () => {
        setSelectedBenefitId(null);
        setIsVisibilityModalOpen(false);
    };

    const handleToggleVisibilityConfirm = () => {
        dispatch(updateBenefitTypeVisibility(selectedBenefitId));
        setSelectedBenefitId(null);
        setIsVisibilityModalOpen(false);
    };

    return (
        <div className="table-wrapper">
            <CustomTableWithFixedColumns
                total={totalCount}
                defaultSorted={defaultSort}
                columns={handleCreateTableColumns(
                    currentBenefits,
                    handleOpenEdit,
                    handleToggleVisibility,
                    permissions
                )}
                data={currentBenefits}
                pageSize={pageSize}
                loading={loading}
                noDataText={noDataText}
                hasPagination={hasPagination}
                sorted={sorted}
                handleSortingChange={handleSortUpdate}
                sortable={true}
                resizable={false}
            />
            <section ref={boundaryRef} />
            {editModalOpen && (
                <BenefitTypeModal
                    title={'Edit Flexible Benefit'}
                    visible={editModalOpen}
                    isEditModal={true}
                    setVisible={setEditModalOpen}
                    handleSubmit={handleEditBenefitType}
                    closeCallback={closeModalCallback}
                />
            )}
            {isVisibilityModalOpen && (
                <CustomConfirmationModal
                    visible={isVisibilityModalOpen}
                    setVisible={setIsVisibilityModalOpen}
                    closeCallback={handleVisibilityModalCancel}
                    handleConfirm={handleToggleVisibilityConfirm}
                    secondaryTitle={
                        'Are you sure you want to show/hide the benefit?'
                    }
                />
            )}
        </div>
    );
};
