import {
    phoneRegex,
    openIdRegex,
    bulgarianLettersRegex,
    nameRegex,
    emailRegexString,
    positionRegex
} from '../../shared/constants/regexConstants';

export const disableSubmitButton = (values, error) => {
    if (
        values.name &&
        !error?.name &&
        values.workEmail &&
        !error?.workEmail &&
        !error?.personalPhone &&
        !error?.workEmail &&
        !error?.nameBg &&
        !error?.excluCVUrl
    ) {
        return false;
    } else {
        return true;
    }
};

export const advancedDisableSubmitButton = (values, error) => {
    if (
        values.name &&
        !error?.name &&
        !error?.personalPhone &&
        !error?.workEmail &&
        !error?.nameBg &&
        !error?.excluCVUrl &&
        values.startDate &&
        !error?.startDate &&
        values.workingTime &&
        !error?.workingTime &&
        values.workEmail &&
        !error?.workEmail &&
        !error?.trialPeriod &&
        !error?.workLocation &&
        !error?.positionTitle &&
        !error?.hiringChannelText
    ) {
        return false;
    } else {
        return true;
    }
};

export const validateForm = values => {
    const {
        name,
        nameBg,
        workEmail,
        personalPhone,
        oaid,
        excluCVUrl,
        workingTime
    } = values;

    let errors = {};

    if (name) {
        let nameError;
        const nameRegExp = new RegExp(nameRegex);
        if (name === '') {
            nameError = 'Name is required.';
        } else if (name?.trim() === '') {
            nameError = 'Name is required.';
        } else if (name.length > 40) {
            nameError = 'Name is too long.';
        } else if (!nameRegExp.test(name)) {
            nameError = 'Name contains invalid characters.';
        }
        errors = { ...errors, name: nameError };
    }
    if (nameBg) {
        let nameBgError;
        const bgLettersRegex = new RegExp(bulgarianLettersRegex);
        if (nameBg?.trim() === '') {
            nameBgError = `Bulgarian Name cannot contain only spaces.`;
        } else if (nameBg.length > 40) {
            nameBgError = 'Bulgarian Name is too long.';
        } else if (!bgLettersRegex.test(nameBg)) {
            nameBgError = 'Bulgarian Name contains invalid characters.';
        }
        errors = { ...errors, nameBg: nameBgError };
    }
    if (workEmail) {
        let workEmailError;
        if (workEmail === '' || workEmail?.trim() === '') {
            workEmailError = 'Work email is required.';
        }
        /** This validation is on purpose like that, with RegExp there are some cases. */
        if (!emailRegexString.test(workEmail)) {
            workEmailError = `Work Email should contain only valid characters.`;
        } else if (workEmail.length > 100) {
            workEmailError = `Work Email should contains up to 100 characters.`;
        }
        errors = { ...errors, workEmail: workEmailError };
    }
    if (personalPhone) {
        let personalPhoneError;
        const temporaryPhone = personalPhone.replace(/\s/g, '');
        const phoneRegexExp = new RegExp(phoneRegex);
        if (temporaryPhone === '') {
            personalPhoneError = `Phone cannot contain only spaces.`;
        } else if (!phoneRegexExp.test(temporaryPhone)) {
            personalPhoneError = `Phone contains invalid characters or it's too long.`;
        }
        errors = { ...errors, personalPhone: personalPhoneError };
    }
    if (excluCVUrl) {
        let excluCVUrlError;
        if (excluCVUrl?.trim() === '') {
            excluCVUrlError = `Exclu CV Url cannot contain only spaces.`;
        }
        errors = { ...errors, excluCVUrl: excluCVUrlError };
    }

    if (!workingTime) {
        const workingTimeError = 'Working Time is required.';
        errors = { ...errors, workingTime: workingTimeError };
    }
    return errors;
};

export const validateWorkingPeriod = values => {
    const { startDate, workingTime, trialPeriod, workLocation, positionTitle } =
        values;

    let errors = {};

    const intRegexExp = new RegExp(openIdRegex);
    if (startDate) {
        let startDateError;
        if (startDate === null) {
            workingTimeError = `Start date is required.`;
        }
        errors = { ...errors, startDate: startDateError };
    }
    if (workingTime) {
        let workingTimeError;
        if (!intRegexExp.test(workingTime)) {
            workingTimeError = `Working time can contains only numbers.`;
        } else if (parseInt(workingTime) < 0 || parseInt(workingTime) > 16) {
            workingTimeError = 'Working time is between 0 and 16 hours.';
        }
        errors = { ...errors, workingTime: workingTimeError };
    }
    if (trialPeriod) {
        let trialPeriodError;
        if (!intRegexExp.test(trialPeriod)) {
            trialPeriodError = `Working time can contains only numbers.`;
        } else if (parseInt(trialPeriod) < 0 || parseInt(trialPeriod) > 24) {
            trialPeriodError = 'Trial period is between 0 and 24 hours.';
        }
        errors = { ...errors, trialPeriod: trialPeriodError };
    }
    if (workLocation) {
        let workLocationError;
        if (workLocation?.trim() === '') {
            workLocationError = `Work location cannot contains only spaces.`;
        } else if (workLocation?.length > 50) {
            workLocationError = 'Work location is too long.';
        }
        errors = { ...errors, workLocation: workLocationError };
    }
    if (positionTitle) {
        let positionTitleError;
        const positionRegExp = new RegExp(positionRegex);
        if (positionTitle?.trim() === '') {
            positionTitleError = `Position cannot contain only spaces.`;
        } else if (positionTitle.length > 100) {
            positionTitleError = 'Position is too long.';
        } else if (!positionRegExp.test(positionTitle)) {
            positionTitleError = 'Position contains invalid characters.';
        }
        errors = { ...errors, positionTitle: positionTitleError };
    }

    return errors;
};

export const validateHiringChannel = values => {
    const { hiringChannelText } = values;
    let errors = {};

    if (hiringChannelText) {
        let hiringChannelTextError;
        if (hiringChannelText?.trim() === '') {
            hiringChannelTextError = `Hiring channel cannot contains only spaces.`;
        } else if (hiringChannelText?.length > 50) {
            hiringChannelTextError = 'Hiring channel is too long.';
        }
        errors = { ...errors, hiringChannelText: hiringChannelTextError };
    }

    return errors;
};

export const generatesNameConfirmationText = (
    setConfirmationExtraText,
    setIsConfirmationOpen,
    namesExistsArray,
    stateData
) => {
    setConfirmationExtraText(prev => {
        if (namesExistsArray.every(x => x === true)) {
            return ' both names';
        } else if (namesExistsArray[0]) {
            return ` name(Latin): "${stateData.name}"`;
        } else if (namesExistsArray[1]) {
            return ` name(Cyrillic): "${stateData.nameBg}"`;
        }
    });
    setIsConfirmationOpen(true);
};
