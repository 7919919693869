import { useEffect, useState } from 'react';

import { handleSubmit } from 'admin/utils/shared/levelsHelper';
import SubsetTabSearch from 'people/components/Dashboard/PeopleSubsetTab/SubsetTabSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
    createLevel,
    fetchLevels,
    updateSearchPhrase,
    resetNotificationState,
    changeOrder,
    resetFilters,
    fetchSingleLevel,
    updateLevel,
    resetLevel,
    changeActiveState
} from 'redux/slices/level';
import { CustomButton } from 'shared/components';
import { ActivityStatusPicker } from 'shared/components/ActivityStatusPicker';
import Footer from 'shared/components/layout/Footer';
import { successNotification } from 'shared/components/Notifications';
import useInfiniteScroll from 'shared/hooks/useInfiniteScroll';

import { StyledLevelsSubtab } from './styled/LevelsSubtab.styled';
import { LevelsTable } from './Table/LevelsTable';
import { LevelModal } from '../shared/LevelModal/LevelModal';

export const LevelsSubtab = ({ handleCreateColumns, permissions }) => {
    const dispatch = useDispatch();

    const {
        levels,
        searchPhrase,
        isLoading,
        totalCount,
        isAddedSuccessfully,
        isEditedSuccessfully,
        sortBy,
        sortAsc,
        levelsTotalCount,
        isEditMode,
        loadActive
    } = useSelector(state => state.level);

    useEffect(() => {
        if (isEditedSuccessfully) {
            successNotification('Successfully updated the Level.');
        } else if (isAddedSuccessfully) {
            successNotification('Successfully added a Level.');
        }

        dispatch(resetNotificationState());
    }, [isAddedSuccessfully, isEditedSuccessfully]);

    const [sortState, setSortState] = useState([
        { id: 'updatedOn', desc: true }
    ]);

    const [isLevelModalOpen, setIsLevelModalOpen] = useState(false);
    const [editModalLevelId, setEditModalLevelId] = useState(undefined);
    const [fetchLevelForEdit, setFetchLevelForEdit] = useState(false);

    useEffect(() => {
        if (editModalLevelId && fetchLevelForEdit) {
            dispatch(fetchSingleLevel(editModalLevelId));
            setIsLevelModalOpen(true);
            setFetchLevelForEdit(false);
        }
    }, [fetchLevelForEdit]);

    useEffect(() => {
        dispatch(fetchLevels(false));
    }, [searchPhrase, sortBy, sortAsc, loadActive]);

    useEffect(() => {
        return () => {
            dispatch(resetFilters());
        };
    }, []);

    const handleSortUpdate = value => {
        setSortState(value);
        dispatch(changeOrder(value));
        dispatch(resetLevel());
    };

    // infinite scroll handling
    const boundaryRef = useInfiniteScroll({
        onLoadMore: () => {
            dispatch(fetchLevels(true));
        },
        isLoading,
        hasMore: Boolean(levels.length < levelsTotalCount)
    });

    const handleValueUpdate = (_, searchPhrase) => {
        dispatch(updateSearchPhrase(searchPhrase));
    };

    const handleLevelModalOpen = (isOpen, levelId) => {
        if (isOpen && levelId) {
            setEditModalLevelId(levelId);
            setFetchLevelForEdit(true);
            return;
        }

        setIsLevelModalOpen(isOpen);
        setEditModalLevelId(undefined);
        dispatch(resetLevel());
    };

    return (
        <>
            <StyledLevelsSubtab>
                <div className="levels-container">
                    <div className="levels-extra-header">
                        <SubsetTabSearch
                            search={{ handleValueUpdate }}
                            allowLettersOnly={false}
                        />
                        <ActivityStatusPicker
                            activeButtonOnClick={() =>
                                dispatch(changeActiveState(true))
                            }
                            inactiveButtonOnClick={() =>
                                dispatch(changeActiveState(false))
                            }
                            loadActive={loadActive}
                        />
                        <CustomButton
                            type={'normal'}
                            text={'Add Level'}
                            onClick={() => handleLevelModalOpen(true)}
                            disabled={!permissions.canAddResources}
                        />
                    </div>
                    <LevelsTable
                        totalCount={totalCount}
                        handleCreateTableColumns={() =>
                            handleCreateColumns(
                                handleLevelModalOpen,
                                permissions
                            )
                        }
                        levelsData={levels}
                        pageSize={10}
                        loading={isLoading}
                        noDataText={'No Levels Found'}
                        hasPagination={false}
                        sorted={sortState}
                        handleSortUpdate={handleSortUpdate}
                        boundaryRef={boundaryRef}
                        permissions={permissions}
                    />

                    <LevelModal
                        title={isEditMode ? 'Edit Level' : 'Add Level'}
                        visible={isLevelModalOpen}
                        setVisible={handleLevelModalOpen}
                        handleSubmit={formikValues =>
                            handleSubmit(
                                editModalLevelId,
                                formikValues,
                                dispatch,
                                createLevel,
                                updateLevel,
                                handleLevelModalOpen
                            )
                        }
                    />
                </div>
            </StyledLevelsSubtab>
            {!Boolean(levels.length < totalCount) && (
                <Footer className={'special'} />
            )}
        </>
    );
};
