import { useMemo } from 'react';

import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import {
    CustomButton,
    CustomSelect,
    CustomDateSelector,
    TextArea,
    CustomInput
} from 'shared/components';
import { mapDataForDropdown } from 'utils/dropdownHelper';

import BenefitRequestInfo from './BenefitRequestInfo';
import { STATUSES_IDS, mapDataForMessagesDropdown } from './utils';
import StyledModal from '../styled/Modal.styled';

const RequestModal = ({ isVisible, onClose, requestInfo }) => {
    const benefitStatuses = useSelector(
        state => state.dropdown.benefitStatuses
    );
    const benefitRequestMessages = useSelector(
        state => state.request.benefitRequestMessages
    );
    const messageOptions = useMemo(
        () =>
            mapDataForMessagesDropdown(
                benefitRequestMessages,
                STATUSES_IDS.REJECTED
            ),
        [benefitRequestMessages]
    );

    let INITIAL_VALUES = {
        benefitRequestStatusId: STATUSES_IDS.REJECTED,
        benefitRequestTemplateMessageId:
            requestInfo.latestSentMessage.benefitRequestTemplateMessageId,
        benefitRequestMessage:
            requestInfo.latestSentMessage.message ??
            benefitRequestMessages.find(
                ({ benefitRequestTemplateMessageId }) =>
                    benefitRequestTemplateMessageId ===
                    requestInfo.latestSentMessage
                        .benefitRequestTemplateMessageId
            ).message,
        startDate: requestInfo.startDate,
        endDate: requestInfo.endDate
    };

    if (requestInfo.showRelativeMemberInformation) {
        INITIAL_VALUES = {
            ...INITIAL_VALUES,
            relativeMemberName: requestInfo.relativeMemberName,
            relativeMemberNameBg: requestInfo.relativeMemberNameBg
        };
    }

    return (
        <Formik initialValues={INITIAL_VALUES} enableReinitialize>
            {({ values }) => (
                <StyledModal
                    visible={isVisible}
                    setVisible={onClose}
                    closable={true}
                    enableClose={true}
                    footer={
                        <div className="footer">
                            <CustomButton
                                text="Cancel"
                                type="normal footer-button"
                                onClick={onClose}
                            />
                            <CustomButton
                                text="Submit"
                                type="filled footer-button"
                                disabled
                            />
                        </div>
                    }
                >
                    <BenefitRequestInfo requestInfo={requestInfo} />
                    <Form>
                        {requestInfo.showRelativeMemberInformation && (
                            <div className="row">
                                <CustomInput
                                    label="Family Member Name (Latin)"
                                    value={values.relativeMemberName}
                                    name="relativeMemberName"
                                    id="relativeMemberName"
                                    disabled
                                />
                                <CustomInput
                                    label="Family Member Name (Cyrillic)"
                                    value={values.relativeMemberNameBg}
                                    name="relativeMemberNameBg"
                                    id="relativeMemberNameBg"
                                    disabled
                                />
                            </div>
                        )}
                        <div className="row">
                            <CustomSelect
                                label="Status"
                                name="benefitRequestStatusId"
                                id="benefitRequestStatusId"
                                value={values.benefitRequestStatusId}
                                options={mapDataForDropdown(benefitStatuses)}
                                disabled
                            />
                            <CustomSelect
                                label="Message"
                                name="benefitRequestTemplateMessageId"
                                id="benefitRequestTemplateMessageId"
                                options={messageOptions}
                                disabled
                            />
                        </div>
                        <TextArea
                            id="benefitRequestMessage"
                            label="Message Text"
                            allowClear={true}
                            value={values.benefitRequestMessage}
                            name={'benefitRequestMessage'}
                            disabled
                            rows={4}
                        />
                        <div className="row">
                            <CustomDateSelector
                                label="Start Date"
                                name="startDate"
                                id="startDate"
                                value={values.startDate}
                                disabledDate={requestInfo.entryDate}
                                disabled
                            />
                            <CustomDateSelector
                                label="End Date"
                                name="endDate"
                                id="endDate"
                                value={values.endDate}
                                disabledDate={
                                    values.startDate
                                        ? values.startDate
                                        : requestInfo.entryDate
                                }
                                disabled
                            />
                        </div>
                    </Form>
                </StyledModal>
            )}
        </Formik>
    );
};

export default RequestModal;
