import styled from 'styled-components';

const StyledBenefitRequestInfo = styled.div`
    .label {
        font-weight: bold;
    }
    .italic {
        font-style: italic;
    }
`;

export default StyledBenefitRequestInfo;
