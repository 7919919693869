import styled from 'styled-components';

const StyledActivityStatusPicker = styled.div`
    .inactive-button {
        height: 34px;
        width: 68px;
        color: #03a8f5;
        font-weight: 900;
        margin-left: 10px !important;

        :hover {
            color: #1c84fe;
        }

        &.active {
            color: white;
            background-color: #03a8f5 !important;
        }
    }
`;

export { StyledActivityStatusPicker };
