import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { resetShowUpdateMessage } from 'redux/slices/level';
import { successNotification } from 'shared/components/Notifications';

import { CustomTableWithFixedColumns } from '../../../../shared/components/CustomTableWithFixedColumns';

export const LevelsTable = ({
    totalCount,
    defaultSort,
    handleCreateTableColumns,
    pageSize,
    loading,
    noDataText,
    hasPagination,
    sorted,
    handleSortUpdate,
    boundaryRef,
    permissions,
    handleOpenLevelModal
}) => {
    const dispatch = useDispatch();
    const { showUpdateMessage, levels } = useSelector(state => state.level);

    useEffect(() => {
        if (showUpdateMessage) {
            successNotification('Successfully updated the Level.');
            dispatch(resetShowUpdateMessage());
        }
    }, [showUpdateMessage]);

    return (
        <div className="table-wrapper">
            <CustomTableWithFixedColumns
                height="auto"
                total={totalCount}
                defaultSorted={defaultSort}
                columns={handleCreateTableColumns(
                    handleOpenLevelModal,
                    permissions
                )}
                data={levels}
                pageSize={pageSize}
                loading={loading}
                noDataText={noDataText}
                hasPagination={hasPagination}
                sorted={sorted}
                handleSortingChange={handleSortUpdate}
                sortable={true}
                resizable={false}
            />
            <section ref={boundaryRef} />
        </div>
    );
};
