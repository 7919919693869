import React from 'react';

import { Tag } from 'antd';

export const ColourTag = ({
    selection,
    color,
    text,
    closable,
    handleOnClose
}) => {
    const onCloseHandler = () => {
        handleOnClose(selection);
    };

    return (
        <div className="custom-tag">
            <Tag
                selection={selection}
                color={color}
                closable={closable}
                onClose={onCloseHandler}
            >
                {text}
            </Tag>
        </div>
    );
};
