import React, { useContext } from 'react';

import { Pie } from '@ant-design/charts';
import { canAccessPeopleCurrent } from 'main/utils/Main/permissionsHelper';
import PermissionsContext from 'shared/utils/permissionStore';

import { StyledMentormaterTypeCounter } from './styled/MentormaterTypeCounter.styled';
import { STATIC_ROUTES_PATHS } from '../../../routes';
import { errorNotification } from '../../../shared/components/Notifications';

export const MentormaterTypeCounter = ({ data, history, defaultFilters }) => {
    const permissions = useContext(PermissionsContext).permissions;

    const {
        homeEmployeesCount,
        homeOfficeEmployeesCount,
        flexibleRemoteEmployeesCount,
        contractorEmployeesCount
    } = data;

    const total =
        homeEmployeesCount +
        homeOfficeEmployeesCount +
        flexibleRemoteEmployeesCount +
        contractorEmployeesCount;

    var pieData = [
        {
            filterValueId: 2,
            type: `Home (${homeEmployeesCount})`,
            value: homeEmployeesCount
        },
        {
            filterValueId: 1,
            type: `Home + Office (${homeOfficeEmployeesCount})`,
            value: homeOfficeEmployeesCount
        },
        {
            filterValueId: 3,
            type: `Flexible Remote (${flexibleRemoteEmployeesCount})`,
            value: flexibleRemoteEmployeesCount
        },
        {
            filterValueId: 4,
            type: `Contractor (${contractorEmployeesCount})`,
            value: contractorEmployeesCount
        }
    ];

    const getColor = type => {
        if (type === pieData[0].type) {
            return '#04D294';
        } else if (type === pieData[1].type) {
            return '#03A8F5';
        } else if (type === pieData[2].type) {
            return '#FD9800';
        }
        return '#FFD702';
    };

    var config = {
        width: 415,
        autoFit: false,
        renderer: 'svg',
        appendPadding: [0, 33, 0, 0],
        data: pieData,
        angleField: 'value',
        colorField: 'type',
        color: ({ type }) => {
            return getColor(type);
        },
        radius: 1,
        innerRadius: 0.64,
        meta: {
            value: {
                formatter: function formatter(v) {
                    return ''.concat(v, ' ');
                }
            }
        },
        label: {
            type: 'inner',
            offset: '-50%',
            style: { textAlign: 'center' },
            autoRotate: false,
            content: '{value}'
        },
        interactions: [],
        tooltip: {
            customContent: (value, items) => {
                return (
                    <div className="pie-tooltip" data-testid="pie-tooltip">
                        {value}
                    </div>
                );
            }
        },
        legend: {
            position: 'right',
            marker: {
                symbol: 'square',
                spacing: 10
            },
            itemHeight: 20,
            itemSpacing: 130,
            itemName: {
                style: {
                    cursor: 'pointer'
                }
            },
            background: {
                padding: [0, 0, -130, -20],
                style: {
                    stroke: 'none'
                }
            }
        },
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                },
                customHtml: function customHtml() {
                    return (
                        <>
                            <div className="total-div">
                                <div className="total-count">{total}</div>
                                <div className="total-desc">Total</div>
                            </div>
                        </>
                    );
                }
            }
        },
        pieStyle: {
            stroke: 'white'
        }
    };

    const handleRedirect = id => {
        if (canAccessPeopleCurrent(permissions)) {
            const element = pieData.find(x => x.type === id);

            if (element) {
                let tempFilters;
                pieData.forEach(item => {
                    if (item.type === element.type) {
                        tempFilters = {
                            ...defaultFilters,
                            mentormaterTypeId: item.filterValueId
                        };
                    }
                });
                history.push({
                    pathname: STATIC_ROUTES_PATHS.peopleDashboard,
                    state: {
                        subsetTab: 1,
                        filters: tempFilters
                    }
                });
            } else {
                errorNotification('Something went wrong');
            }
        }
    };

    return (
        <StyledMentormaterTypeCounter>
            <div className="type-counter-content">
                <div className="type-box">
                    <div className="label">Type Of MentorMater</div>
                    <Pie
                        {...config}
                        onReady={plot => {
                            plot.on('legend-item-name:click', evt => {
                                handleRedirect(
                                    evt.gEvent.target.cfg.delegateObject.item
                                        .name
                                );
                            });
                        }}
                    />
                </div>
            </div>
        </StyledMentormaterTypeCounter>
    );
};
