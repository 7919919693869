import Upload from 'antd/lib/upload/Upload';
import { CloseButton } from 'shared/components/CloseButton';

import { CustomButton } from '.';
import { CustomConfirmationModal } from '.';
import StyledUploadModal from './styled/UploadModal.styled';
export const UploadModal = ({
    title,
    visible = false,
    buttonType = 'normal',
    onRemove,
    beforeUpload,
    maxCount = null,
    multiple = false,
    fileSelectorButtonText,
    fileList,
    onUploadClick,
    isConfirmationModalVisible,
    handleConfirmation,
    setConfirmationModalVisible,
    closeConfirmationModalCallback
}) => {
    const renderTitle = () => [
        <div
            style={{ display: 'flex', justifyContent: 'space-between' }}
            key="modal-title"
        >
            {title}
            <CloseButton handleClose={onRemove} />
        </div>
    ];

    const renderFooter = () => (
        <>
            <div className="custom-modal-footer"></div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
                key="modal-footer"
            >
                <div>
                    <CustomButton
                        text="Cancel"
                        type="normal footer-button"
                        onClick={onRemove}
                    />
                </div>
                <div>
                    <CustomButton
                        text="Upload"
                        type="filled footer-button"
                        onClick={onUploadClick}
                        disabled={fileList?.length < 1}
                    />
                </div>
            </div>
        </>
    );

    return (
        <StyledUploadModal
            visible={visible}
            setVisible={onRemove}
            title={renderTitle()}
            footer={renderFooter()}
        >
            <Upload
                fileList={fileList}
                showUploadList={true}
                multiple={maxCount > 1 || multiple}
                onRemove={onRemove}
                beforeUpload={beforeUpload}
                maxCount={maxCount}
            >
                <div className="button-container">
                    <CustomButton
                        width={'100%'}
                        type={buttonType}
                        text={fileSelectorButtonText}
                    />
                </div>
            </Upload>
            <CustomConfirmationModal
                title={'Confirmation'}
                visible={isConfirmationModalVisible}
                setVisible={setConfirmationModalVisible}
                contentText={`Are you sure you want to replace the existing file(s) with the same name?`}
                handleConfirm={handleConfirmation}
                closeCallback={closeConfirmationModalCallback}
            />
        </StyledUploadModal>
    );
};
