import React, { useEffect, useState } from 'react';

import TabItem from './TabItem';

const TabsWrapper = props => {
    const { activeItemIndex, handleTabChange, children } = props;
    const label = children[activeItemIndex].props.label;
    const [activeTab, setActiveTab] = useState(label);

    const onClickTabItem = tab => {
        setActiveTab(tab);
        handleTabChange && handleTabChange(tab);
    };

    useEffect(() => {
        setActiveTab(label);
    }, [label]);

    return (
        <div className="tabs">
            <ol className="tab-list">
                {children.map(child => {
                    const { label, disabled } = child.props;
                    return (
                        <TabItem
                            activeTab={activeTab}
                            key={label}
                            label={label}
                            onClick={onClickTabItem}
                            disabled={disabled}
                        />
                    );
                })}
            </ol>
            <div className="tab-content">
                {children.map(child => {
                    if (child.props.label !== activeTab) return undefined;
                    return child.props.children;
                })}
            </div>
        </div>
    );
};

export default TabsWrapper;
