/**
 * Truncates a string to the provided max length, concatenating an ellipsis at the end
 * @param {string} string
 * @param {number} maxLength
 * @returns {string} Truncated string with ellipsis
 */
export const truncate = (string, maxLength) => {
    if (typeof string !== 'string')
        throw new Error('Truncate expects a string as its first argument.');

    if (typeof maxLength !== 'number')
        throw new Error('Truncate expects a number as its second argument.');

    return string.substring(0, maxLength) + '...';
};
