const calculateFakeValues = departmentData => {
    const dataObj = {
        administrationEmployeesCount:
            departmentData.administrationEmployeesCount,
        baEmployeesCount: departmentData.baEmployeesCount,
        cloudAndDevOpsEmployeesCount:
            departmentData.cloudAndDevOpsEmployeesCount,
        continuousEngineeringEmployeesCount:
            departmentData.continuousEngineeringEmployeesCount,
        dcoeEmployeesCount: departmentData.dcoeEmployeesCount,
        designEmployeesCount: departmentData.designEmployeesCount,
        dotNetEmployeesCount: departmentData.dotNetEmployeesCount,
        iosEmployeesCount: departmentData.iosEmployeesCount,
        javaAndroidEmployeesCount: departmentData.javaAndroidEmployeesCount,
        lampFeEmployeesCount: departmentData.lampFeEmployeesCount,
        otherEmployeesCount: departmentData.otherEmployeesCount,
        qeEmployeesCount: departmentData.qeEmployeesCount,
        deliveryManagementCount: departmentData.deliveryManagementCount,
        mobileEmployeesCount: departmentData.mobileEmployeesCount,
        enterpriseCybersecurityAndComplianceEmployeesCount:
            departmentData.enterpriseCybersecurityAndComplianceEmployeesCount
    };

    let sortable = sortDepartments(dataObj);

    let resultObj = {
        [sortable[0].name]: sortable[0].value
    };
    let percentage = 0.99;
    for (let index = 1; index < sortable.length; index++) {
        const firstItem = sortable[0].value;
        const currentItem = sortable[index].value;
        if (
            currentItem === sortable[index + 1]?.value &&
            index !== sortable.length - 1
        ) {
            resultObj = {
                ...resultObj,
                [sortable[index].name]: firstItem * percentage
            };
        } else {
            if (currentItem === firstItem) {
                resultObj = {
                    ...resultObj,
                    [sortable[index].name]: sortable[index].value
                };
                continue;
            }
            resultObj = {
                ...resultObj,
                [sortable[index].name]: firstItem * percentage
            };
            percentage = Math.round((percentage - 0.01) * 100) / 100;
        }
    }

    return resultObj;
};

const sortDepartments = dataObj => {
    let sortable = [];
    for (let key in dataObj) {
        if (dataObj[key] > 0) {
            sortable.push({ name: key, value: dataObj[key] });
        }
    }

    sortable.sort((a, b) => {
        return b.value - a.value;
    });

    return sortable;
};

const nameDictionary = {
    administration: 'Administration',
    ba: 'BA',
    cloudAndDevOps: 'Cloud & DevOps',
    ce: 'CE',
    dcoe: 'DCOE',
    design: 'Design',
    dotNet: '.NET',
    ios: 'iOS',
    javaAndroid: 'Java',
    lampFe: 'LAMP/FE',
    qe: 'QE',
    other: 'Other',
    deliveryManagement: 'Delivery management',
    mobile: 'Mobile',
    cybersecurityAndCompliance: 'ECC'
};

const classNamesDictionary = {
    [nameDictionary.administration]: 'administrationSection',
    [nameDictionary.ba]: 'baSection',
    [nameDictionary.cloudAndDevOps]: 'cloudAndDevOpsSection',
    [nameDictionary.ce]: 'ceSection',
    [nameDictionary.dcoe]: 'dcoeSection',
    [nameDictionary.design]: 'designSection',
    [nameDictionary.dotNet]: 'dotnetSection',
    [nameDictionary.ios]: 'iosSection',
    [nameDictionary.javaAndroid]: 'javaAndroidSection',
    [nameDictionary.lampFe]: 'lampFeSection',
    [nameDictionary.qe]: 'qeSection',
    [nameDictionary.other]: 'otherSection',
    [nameDictionary.deliveryManagement]: 'deliveryManagementSection',
    [nameDictionary.mobile]: 'mobileSection',
    [nameDictionary.cybersecurityAndCompliance]: 'eccSection'
};

const dataTreeMap = (data, calculatedData) => {
    const {
        administrationEmployeesCount,
        baEmployeesCount,
        cloudAndDevOpsEmployeesCount,
        continuousEngineeringEmployeesCount,
        dcoeEmployeesCount,
        designEmployeesCount,
        dotNetEmployeesCount,
        iosEmployeesCount,
        javaAndroidEmployeesCount,
        lampFeEmployeesCount,
        otherEmployeesCount,
        qeEmployeesCount,
        deliveryManagementCount,
        mobileEmployeesCount,
        enterpriseCybersecurityAndComplianceEmployeesCount
    } = data;

    return {
        name: 'flare',
        children: [
            {
                filterValueId: 10,
                name: nameDictionary.administration,
                size:
                    administrationEmployeesCount === 0
                        ? 0
                        : calculatedData.administrationEmployeesCount,
                realValue: administrationEmployeesCount,
                color: '#06f5c1'
            },
            {
                filterValueId: 6,
                name: nameDictionary.ba,
                size:
                    baEmployeesCount === 0
                        ? 0
                        : calculatedData.baEmployeesCount,
                realValue: baEmployeesCount,
                color: '#FFD702'
            },
            {
                filterValueId: 7,
                name: nameDictionary.cloudAndDevOps,
                size:
                    cloudAndDevOpsEmployeesCount === 0
                        ? 0
                        : calculatedData.cloudAndDevOpsEmployeesCount,
                realValue: cloudAndDevOpsEmployeesCount,
                color: '#bd06f5'
            },
            {
                filterValueId: 11,
                name: nameDictionary.ce,
                size:
                    continuousEngineeringEmployeesCount === 0
                        ? 0
                        : calculatedData.continuousEngineeringEmployeesCount,
                realValue: continuousEngineeringEmployeesCount,
                color: '#F04008'
            },
            {
                filterValueId: 8,
                name: nameDictionary.dcoe,
                size:
                    dcoeEmployeesCount === 0
                        ? 0
                        : calculatedData.dcoeEmployeesCount,
                realValue: dcoeEmployeesCount,
                color: '#f506d5'
            },
            {
                filterValueId: 9,
                name: nameDictionary.design,
                size:
                    designEmployeesCount === 0
                        ? 0
                        : calculatedData.designEmployeesCount,
                realValue: designEmployeesCount,
                color: '#FC7900'
            },
            {
                filterValueId: 1,
                name: nameDictionary.dotNet,
                size:
                    dotNetEmployeesCount === 0
                        ? 0
                        : calculatedData.dotNetEmployeesCount,
                realValue: dotNetEmployeesCount,
                color: '#03A8F5'
            },
            {
                filterValueId: 5,
                name: nameDictionary.ios,
                size:
                    iosEmployeesCount === 0
                        ? 0
                        : calculatedData.iosEmployeesCount,
                realValue: iosEmployeesCount,
                color: '#FF0000'
            },
            {
                filterValueId: 4,
                name: nameDictionary.javaAndroid,
                size:
                    javaAndroidEmployeesCount === 0
                        ? 0
                        : calculatedData.javaAndroidEmployeesCount,
                realValue: javaAndroidEmployeesCount,
                color: '#FD9800'
            },
            {
                filterValueId: 2,
                name: nameDictionary.lampFe,
                size:
                    lampFeEmployeesCount === 0
                        ? 0
                        : calculatedData.lampFeEmployeesCount,
                realValue: lampFeEmployeesCount,
                color: '#087AFF'
            },
            {
                filterValueId: 3,
                name: nameDictionary.qe,
                size:
                    qeEmployeesCount === 0
                        ? 0
                        : calculatedData.qeEmployeesCount,
                realValue: qeEmployeesCount,
                color: '#77D4FF'
            },
            {
                filterValueId: 12,
                name: nameDictionary.other,
                size:
                    otherEmployeesCount === 0
                        ? 0
                        : calculatedData.otherEmployeesCount,
                realValue: otherEmployeesCount,
                color: '#99e65a'
            },
            {
                filterValueId: 36,
                name: nameDictionary.deliveryManagement,
                size:
                    deliveryManagementCount === 0
                        ? 0
                        : calculatedData.deliveryManagementCount,
                realValue: deliveryManagementCount,
                color: '#FF00AA'
            },
            {
                filterValueId: 33,
                name: nameDictionary.mobile,
                size:
                    mobileEmployeesCount === 0
                        ? 0
                        : calculatedData.mobileEmployeesCount,
                realValue: mobileEmployeesCount,
                color: '#128f01'
            },
            {
                filterValueId: 37,
                name: nameDictionary.cybersecurityAndCompliance,
                size:
                    mobileEmployeesCount === 0
                        ? 0
                        : calculatedData.enterpriseCybersecurityAndComplianceEmployeesCount,
                realValue: enterpriseCybersecurityAndComplianceEmployeesCount,
                color: '#FC8C78'
            }
        ]
    };
};

export {
    calculateFakeValues,
    nameDictionary,
    classNamesDictionary,
    dataTreeMap
};
