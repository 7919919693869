import styled from 'styled-components';

const StyledCheckbox = styled.div`
    .ant-checkbox-wrapper {
        margin: 5px 0px;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #03a8f5;
        border-color: #03a8f5;
    }
`;

export { StyledCheckbox };
