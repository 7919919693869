import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchBenefitTypeNamesDropDown } from 'redux/slices/dropdown';

import AllTabs from './AllTabs';
import { StyledDashboardHeader } from '../../../people/components/Dashboard/styled/DashboardHeader.styled';
import {
    fetchBenefitsCategoriesCounts,
    resetPersonState
} from '../../../redux/slices/people';
import { Spinner } from '../../../shared/components/Spinner';

const BenefitsDashboardHeader = ({ history, updateCount }) => {
    const dispatch = useDispatch();

    const { categoriesCount, isLoadingCategories } = useSelector(
        state => state.people
    );

    //fetch data
    useEffect(() => {
        dispatch(fetchBenefitsCategoriesCounts());
        dispatch(fetchBenefitTypeNamesDropDown());
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetPersonState());
        };
    }, []);

    return (
        <StyledDashboardHeader>
            <div className="people-dashboard-header-wrapper">
                <div className="nav-list-items">
                    {isLoadingCategories ? (
                        <div datatest-id="nav-spinner" className="spinner-div">
                            <Spinner />
                        </div>
                    ) : (
                        <AllTabs
                            allPeopleCount={categoriesCount.all}
                            newPeopleCount={categoriesCount.new}
                            onLeavePeopleCount={categoriesCount.onLeave}
                            leaversPeopleCount={categoriesCount.leavers}
                            history={history}
                            updateCount={updateCount}
                        />
                    )}
                </div>
            </div>
        </StyledDashboardHeader>
    );
};

export default BenefitsDashboardHeader;
