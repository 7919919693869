import styled from 'styled-components';

const StyledFiltersBox = styled.div`
    position: absolute;
    z-index: 5;
    width: 535px;
    top: 45px;
    right: 0px;
    background: #ffffff;
    box-shadow: 2px 10px 30px rgba(17, 17, 17, 0.1);
    border-radius: 10px;
    padding: 21px 20px;

    label {
        display: block;
    }

    .filters-box-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 20px;
        margin-bottom: 10px;
    }

    .filters-box-header .filters-box-heading {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
    }

    .filters-box-header .filters-box-actions span.anticon.anticon-close {
        background: rgba(153, 153, 153, 0.16);
        border-radius: 6px;
        padding: 6px;
        color: var(--mainBlue);
    }

    .filters-box-header
        .filters-box-actions
        span.anticon.anticon-close
        svg
        path {
        stroke-width: 50;
        stroke: var(--mainBlue);
    }

    .filters-box-header .filters-box-actions svg {
        width: 8px;
        height: 8px;
    }

    .filters-box-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        color: var(--darkNavy);
    }

    .filters-box-footer {
        margin-top: 6px;
    }

    .custom-datepicker {
        width: 240px;
        margin: 12px 0 14px 0;
    }
`;

export default StyledFiltersBox;
