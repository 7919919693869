import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { StyledHistoricalDataModal } from './styled/HistoricalDataModal.styled';
import {
    fetchHistoricalDataById,
    resetHistoryState,
    updateManyHistories
} from '../../../../redux/slices/history';
import { CustomButton } from '../../../../shared/components/Button';
import { CloseButton } from '../../../../shared/components/CloseButton';
import { CustomSelect } from '../../../../shared/components/Select';
import { Spinner } from '../../../../shared/components/Spinner';
import { TakesEffectModal } from '../../../../shared/components/TakesEffectModal';
import { CustomTimeline } from '../../../../shared/components/TimeLine/CustomTimeline';
import { convertAndSeparateData } from '../../../../shared/utils/timeLineHelper';
import {
    groupByYear,
    HISTORICAL_DATA_OPTIONS,
    mapData
} from '../../../utils/Details/HistoricalData/historicalDataHelper';

const HistoricalDataModal = ({
    isModalOpen,
    setIsModalOpen,
    title,
    personId
}) => {
    const [historicalState, setHistoricalState] = useState({
        personId: personId,
        filterBy: 'AllId'
    });
    const [transformedHistoryItems, setTransformedHistoryItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [itemsForEdit, setItemsForEdit] = useState([]);
    const [allowEditMany, setAllowEditMany] = useState(false);
    const { person } = useSelector(state => state.people);
    const { startDate, leaveDate } = person.workingPeriods[0];

    const {
        isLoading: isLoadingHistoricalItems,
        historicalItems,
        error
    } = useSelector(state => state.history);

    const dropDownState = useSelector(state => state.dropdown);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchHistoricalDataById(historicalState));
        setIsLoading(true);
    }, [historicalState]);

    useEffect(() => {
        return () => {
            dispatch(resetHistoryState());
        };
    }, []);

    useEffect(() => {
        const mappedData = mapData(historicalItems, dropDownState);
        const sortedData = groupByYear(mappedData);
        setTransformedHistoryItems(sortedData);
        setItemsForEdit(sortedData.map(s => s.timelineItems).flat(1));
        setIsLoading(false);
    }, [historicalItems]);

    useEffect(() => {
        if (itemsForEdit.filter(i => i.isSelected).length > 0) {
            setAllowEditMany(true);
        } else {
            setAllowEditMany(false);
        }
    }, [itemsForEdit]);

    const handleFilterValueUpdate = async (which, val) => {
        setHistoricalState(state => {
            return { ...state, [which]: val };
        });
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const onDeleteCallback = () => {
        setHistoricalState({
            personId: personId,
            filterBy: 'AllId'
        });
    };

    const handleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    };

    const handleModalSubmit = takesEffectOn => {
        const dataForEdit = itemsForEdit.filter(i => i.isSelected);
        const { personHistoryData, workingPeriodHistoryData } =
            convertAndSeparateData(dataForEdit.reverse(), takesEffectOn);

        dispatch(
            updateManyHistories(
                personHistoryData,
                workingPeriodHistoryData,
                historicalState
            )
        );

        handleEditModal();
    };

    const { filterBy } = historicalState;

    return (
        <StyledHistoricalDataModal
            visible={isModalOpen}
            setVisible={setIsModalOpen}
            title={[
                <div className="custom-modal-header" key="modal-title">
                    {title}
                    <CloseButton handleClose={handleClose} />
                </div>
            ]}
            footer={<></>}
        >
            <div className="modal-body-section">
                <div className="filter">
                    <CustomSelect
                        placeholder={'Select a filter'}
                        options={HISTORICAL_DATA_OPTIONS}
                        name={'filterBy'}
                        value={filterBy}
                        onChange={(name, val) =>
                            handleFilterValueUpdate(name, val)
                        }
                        hasInput={true}
                    />
                    <div className="history-modal-edit-btn">
                        <CustomButton
                            type="normal"
                            disabled={!allowEditMany}
                            text={'Edit historical data'}
                            onClick={handleEditModal}
                        />
                    </div>
                </div>
                {isLoadingHistoricalItems || isLoading ? (
                    <div className="loading-handler">
                        <Spinner />
                    </div>
                ) : (
                    <>
                        {error ? (
                            <div className="error">{error}</div>
                        ) : (
                            <div className="historical-items">
                                <CustomTimeline
                                    mode="alternate"
                                    sorted={transformedHistoryItems}
                                    historicalState={historicalState}
                                    onDeleteCallback={onDeleteCallback}
                                    setItemsForEdit={setItemsForEdit}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
            {editModalOpen && (
                <TakesEffectModal
                    visible={editModalOpen}
                    setVisible={setEditModalOpen}
                    title={'Set Date'}
                    handleSubmit={handleModalSubmit}
                    startWorkingPeriodDate={startDate}
                    endWorkingPeriodDate={leaveDate}
                />
            )}
        </StyledHistoricalDataModal>
    );
};

export { HistoricalDataModal };
