import React from 'react';

export const ColumnVertical = () => {
    return (
        <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="20" height="3" fill="white" rx="2" />
            <rect y="10" width="20" height="3" fill="white" rx="2" />
            <rect y="5" width="20" height="3" fill="white" rx="2" />
            <rect y="15" width="20" height="3" fill="white" rx="2" />
        </svg>
    );
};
