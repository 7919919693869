import React, { useContext } from 'react';

import PermissionsContext from 'shared/utils/permissionStore';

import { STATIC_ROUTES_PATHS } from '../../../routes';
import { CustomTableWithFixedColumns } from '../../../shared/components/CustomTableWithFixedColumns';

const PersonBenefitsTable = ({
    tabType,
    totalCount,
    defaultSort,
    handleCreateTableColumns,
    peopleData,
    loading,
    sorted,
    handleSortUpdate,
    boundaryRef
}) => {
    const permissions = useContext(PermissionsContext).permissions;

    const handleRedirectToDetails = personId => {
        return STATIC_ROUTES_PATHS.personDetails
            .replace(':id', personId)
            .replace(':activeTab', 'benefits');
    };

    return (
        <div className="table-wrapper">
            <CustomTableWithFixedColumns
                total={totalCount}
                defaultSorted={defaultSort}
                columns={handleCreateTableColumns(
                    peopleData,
                    handleRedirectToDetails,
                    tabType,
                    permissions
                )}
                data={peopleData}
                pageSize={10}
                loading={loading}
                noDataText={'No Employees Found'}
                hasPagination={false}
                sorted={sorted}
                handleSortingChange={handleSortUpdate}
                sortable={true}
                resizable={true}
            />
            <section ref={boundaryRef} />
        </div>
    );
};

export default PersonBenefitsTable;
