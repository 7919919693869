export const validateRoleForm = values => {
    const { roleName } = values;
    let errors = {};

    if (roleName) {
        let roleNameError;
        if (roleName?.trim() === '') {
            roleNameError = 'Role name is required.';
        }
        if (roleName.length > 50) {
            roleNameError = 'Role name has max length of 50 characters.';
        }
        errors = { ...errors, roleName: roleNameError };
    }

    return errors;
};

export const validateRoleOnSubmit = values => {
    const { roleName, permissions } = values;

    let errors = {};

    if (roleName) {
        let roleNameError;
        if (roleName?.trim() === '') {
            roleNameError = 'Role name is required.';
        }
        errors = { ...errors, roleName: roleNameError };
    } else {
        let roleNameError = 'Role name is required.';
        errors = { ...errors, roleName: roleNameError };
    }

    if (permissions.length === 0) {
        let permissionsError = 'You have to select at least one permission.';
        errors = { ...errors, permissions: permissionsError };
    }

    return errors;
};

export const setClassName = val => {
    if (val) {
        return 'error-input';
    }
};

export const canSubmitRoleForm = (values, error) => {
    if (
        values.roleName &&
        !error?.roleName &&
        values.permissions.length > 0 &&
        !error?.permissions
    ) {
        return true;
    }

    return false;
};

export const getEditInitialValues = role => {
    const permissionIds = role.permissions.map(x => x.id);

    return {
        roleName: role.name,
        permissions: [...permissionIds]
    };
};

export const getSelectedPermissionOnEdit = role => {
    return role.permissions.map(x => {
        return { value: x.id, label: x.description };
    });
};
