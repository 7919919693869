import React from 'react';

export const ColumnCard = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <rect width="9" height="9" fill="#999" rx="2"></rect>
            <rect width="9" height="9" x="11" fill="#999" rx="2"></rect>
            <rect width="9" height="9" y="11" fill="#999" rx="2"></rect>
            <rect width="9" height="9" x="11" y="11" fill="#999" rx="2"></rect>
        </svg>
    );
};
