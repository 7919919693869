import styled from 'styled-components';

const StyledPageHeading = styled.div`
    .page-heading {
        height: 110px;
        background: #1c84fe;
    }
    .page-heading-inner {
        padding-top: 26px;
        padding-left: 90px;
    }
`;

export { StyledPageHeading };
