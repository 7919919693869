export const shouldBenefitsPageTabBeDisabled = (label, permissions) => {
    if (!permissions.canViewBenefitsPage) {
        return true;
    }

    if (label == 'All') {
        return !(
            permissions.canViewBenefitsAllTab &&
            permissions.canViewBenefitsNewTab &&
            permissions.canViewBenefitsLeaversTab &&
            permissions.canViewBenefitsMaternityTab
        );
    }

    if (label == 'New') {
        return !permissions.canViewBenefitsNewTab;
    }

    if (label == 'Leavers') {
        return !permissions.canViewBenefitsLeaversTab;
    }

    if (label == 'On Leave') {
        return !permissions.canViewBenefitsMaternityTab;
    }

    return true;
};

export const setDefaultTabBenefitsPage = (tabNumber, permissions) => {
    const tabLabelMappingPeoplePage = {
        0: 'All',
        1: 'New',
        2: 'On Leave',
        3: 'Leavers'
    };

    const allPeopleTabNumber = 0;

    if (
        !shouldBenefitsPageTabBeDisabled(
            tabLabelMappingPeoplePage[tabNumber],
            permissions
        )
    ) {
        return tabNumber;
    }

    for (const tabNumber in tabLabelMappingPeoplePage) {
        if (Object.hasOwnProperty.call(tabLabelMappingPeoplePage, tabNumber)) {
            const label = tabLabelMappingPeoplePage[tabNumber];

            if (!shouldBenefitsPageTabBeDisabled(label, permissions)) {
                return Number(tabNumber);
            }
        }
    }

    return allPeopleTabNumber;
};
