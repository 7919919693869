import { useState, useContext } from 'react';

import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
    CustomButton,
    CustomSelect,
    CustomDateSelector,
    TextArea,
    CustomInput
} from 'shared/components';
import PermissionsContext from 'shared/utils/permissionStore';
import { mapDataForDropdown } from 'utils/dropdownHelper';

import BenefitRequestInfo from './BenefitRequestInfo';
import {
    STATUSES_IDS,
    REQUEST_TYPES_IDS,
    validateApprovedForm,
    MAX_TEXTAREA_LENGTH,
    mapDataForMessagesDropdown,
    handleOnChangeBenefitStatusId,
    handleOnChangeBenefitRequestTemplateId
} from './utils';
import { updateRequest } from '../../../redux/slices/request';
import StyledModal from '../styled/Modal.styled';

const getOptions = (statuses, { requestTypeId }, permissions) => {
    const filteredOptions =
        requestTypeId === REQUEST_TYPES_IDS.ACTIVATION
            ? statuses.filter(status => status.id !== STATUSES_IDS.PENDING)
            : statuses.filter(status =>
                  [STATUSES_IDS.APPROVED, STATUSES_IDS.REJECTED].includes(
                      status.id
                  )
              );
    const _options = filteredOptions.map(option => {
        if (option.id === STATUSES_IDS.DELIVERED) {
            return {
                ...option,
                disabled: !permissions.canChangeStatusFromApprovedToDelivered
            };
        }
        if (option.id === STATUSES_IDS.REJECTED) {
            return {
                ...option,
                disabled: !permissions.canChangeStatusFromApprovedToRejected
            };
        }
        return option;
    });

    return mapDataForDropdown(_options);
};

const RequestModal = ({ isVisible, onClose, requestInfo }) => {
    const permissions = useContext(PermissionsContext).permissions;
    const benefitStatuses = useSelector(
        state => state.dropdown.benefitStatuses
    );
    const benefitRequestMessages = useSelector(
        state => state.request.benefitRequestMessages
    );
    const [messageOptions, setMessageOptions] = useState(
        mapDataForMessagesDropdown(
            benefitRequestMessages,
            STATUSES_IDS.APPROVED
        )
    );
    const dispatch = useDispatch();
    const [isFreeTextInputDisabled, setIsFreeTextInputDisabled] =
        useState(true);

    let INITIAL_VALUES = {
        benefitRequestStatusId: STATUSES_IDS.APPROVED,
        benefitRequestTemplateMessageId:
            requestInfo.latestSentMessage.benefitRequestTemplateMessageId,
        benefitRequestMessage:
            requestInfo.latestSentMessage.message ??
            benefitRequestMessages.find(
                ({ benefitRequestTemplateMessageId, active }) =>
                    active &&
                    benefitRequestTemplateMessageId ===
                        requestInfo.latestSentMessage
                            .benefitRequestTemplateMessageId
            ).message,
        startDate: requestInfo.startDate,
        endDate: requestInfo.endDate
    };

    if (requestInfo.showRelativeMemberInformation) {
        INITIAL_VALUES = {
            ...INITIAL_VALUES,
            relativeMemberName: requestInfo.relativeMemberName,
            relativeMemberNameBg: requestInfo.relativeMemberNameBg
        };
    }

    const handleSubmit = values => {
        dispatch(
            updateRequest(requestInfo.requestId, {
                benefitRequestTypeId: requestInfo.benefitRequestTypeId,
                benefitRequestStatusId: values.benefitRequestStatusId,
                benefitRequestTemplateMessageId:
                    values.benefitRequestTemplateMessageId,
                benefitRequestMessage: values.benefitRequestMessage?.trim()
            })
        );
        onClose();
    };

    return (
        <Formik
            initialValues={INITIAL_VALUES}
            enableReinitialize
            validate={validateApprovedForm}
            validateOnBlur={false}
        >
            {({ values, errors, setFieldValue, validateForm, setErrors }) => (
                <StyledModal
                    visible={isVisible}
                    setVisible={onClose}
                    closable={true}
                    enableClose={true}
                    footer={
                        <div className="footer">
                            <CustomButton
                                text="Cancel"
                                type="normal footer-button"
                                onClick={onClose}
                            />
                            <CustomButton
                                text="Submit"
                                type="filled footer-button"
                                onClick={async () => {
                                    const errors = await validateForm();
                                    if (!isEmpty(errors)) return;
                                    handleSubmit(values);
                                }}
                                disabled={
                                    values.benefitRequestStatusId ===
                                        STATUSES_IDS.APPROVED ||
                                    !isEmpty(errors)
                                }
                            />
                        </div>
                    }
                >
                    <BenefitRequestInfo requestInfo={requestInfo} />
                    <Form>
                        {requestInfo.showRelativeMemberInformation && (
                            <div className="row">
                                <CustomInput
                                    label="Family Member Name (Latin)"
                                    value={values.relativeMemberName}
                                    name="relativeMemberName"
                                    disabled
                                />
                                <CustomInput
                                    label="Family Member Name (Cyrillic)"
                                    value={values.relativeMemberNameBg}
                                    name="relativeMemberNameBg"
                                    disabled
                                />
                            </div>
                        )}
                        <div className="row">
                            <CustomSelect
                                label="Status *"
                                name="benefitRequestStatusId"
                                id="benefitRequestStatusId"
                                className={
                                    !!errors.benefitRequestStatusId &&
                                    'error-input'
                                }
                                options={getOptions(
                                    benefitStatuses,
                                    {
                                        requestTypeId:
                                            requestInfo.benefitRequestTypeId
                                    },
                                    permissions
                                )}
                                value={values.benefitRequestStatusId}
                                onChange={(name, value) => {
                                    handleOnChangeBenefitStatusId(
                                        name,
                                        value,
                                        benefitRequestMessages,
                                        requestInfo,
                                        setErrors,
                                        setFieldValue,
                                        setMessageOptions,
                                        setIsFreeTextInputDisabled
                                    );
                                }}
                            />
                            {errors.benefitRequestStatusId && (
                                <div className="error-message">
                                    {errors.benefitRequestStatusId}
                                </div>
                            )}
                            <div>
                                <CustomSelect
                                    label={`Message ${
                                        [
                                            STATUSES_IDS.DELIVERED,
                                            STATUSES_IDS.REJECTED
                                        ].includes(
                                            values.benefitRequestStatusId
                                        ) &&
                                        requestInfo.benefitRequestTypeId !==
                                            REQUEST_TYPES_IDS.CHANGE_OF_COVERAGE
                                            ? '*'
                                            : ''
                                    }`}
                                    name="benefitRequestTemplateMessageId"
                                    id="benefitRequestTemplateMessageId"
                                    className={
                                        !!errors.benefitRequestTemplateMessageId &&
                                        'error-input'
                                    }
                                    options={messageOptions}
                                    disabled={
                                        ![
                                            STATUSES_IDS.DELIVERED,
                                            STATUSES_IDS.REJECTED
                                        ].includes(
                                            values.benefitRequestStatusId
                                        ) ||
                                        requestInfo.benefitRequestTypeId ===
                                            REQUEST_TYPES_IDS.CHANGE_OF_COVERAGE
                                    }
                                    value={
                                        values.benefitRequestTemplateMessageId
                                    }
                                    onChange={(name, value) =>
                                        handleOnChangeBenefitRequestTemplateId(
                                            name,
                                            value,
                                            benefitRequestMessages,
                                            setFieldValue,
                                            setIsFreeTextInputDisabled,
                                            setErrors
                                        )
                                    }
                                />
                                {errors.benefitRequestTemplateMessageId && (
                                    <div className="error-message">
                                        {errors.benefitRequestTemplateMessageId}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div>
                            <TextArea
                                id="benefitRequestMessage"
                                label={`Message Text ${
                                    isFreeTextInputDisabled ? '' : '*'
                                }`}
                                name={'benefitRequestMessage'}
                                hasError={!!errors.benefitRequestMessage}
                                allowClear={true}
                                disabled={isFreeTextInputDisabled}
                                rows={4}
                                maxLength={MAX_TEXTAREA_LENGTH}
                                showCount={!isFreeTextInputDisabled}
                                value={values.benefitRequestMessage}
                                onChange={setFieldValue}
                            />
                            {errors.benefitRequestMessage && (
                                <div className="error-message">
                                    {errors.benefitRequestMessage}
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <CustomDateSelector
                                value={values.startDate}
                                label="Start Date"
                                name="startDate"
                                id="startDate"
                                onChange={setFieldValue}
                                disabledDate={requestInfo.entryDate}
                                disabled
                            />
                            <CustomDateSelector
                                value={values.endDate}
                                label="End Date"
                                name="endDate"
                                id="endDate"
                                onChange={setFieldValue}
                                disabledDate={
                                    values.startDate
                                        ? values.startDate
                                        : requestInfo.entryDate
                                }
                                disabled
                            />
                        </div>
                    </Form>
                </StyledModal>
            )}
        </Formik>
    );
};

export default RequestModal;
