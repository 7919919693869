import React from 'react';

import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';

import 'react-table-hoc-fixed-columns/lib/styles.css';
import { StyledCustomTableWithFixedColumn } from './styled/CustomTableWithFixedColumn.styled';

const TABLE_ID = 'custom-table';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export const CustomTableWithFixedColumns = ({
    height = '',
    loading,
    data = [],
    handleFetch,
    noDataText,
    columns,
    total = 0,
    defaultSorted = [],
    resizable = false,
    sortable = false,
    manual = true,
    hasPagination = false,
    ...props
}) => {
    const { page, pageSize, handleSortingChange, sorted } = props;

    return (
        <StyledCustomTableWithFixedColumn height={height}>
            <div className="custom-table" id={TABLE_ID}>
                <ReactTableFixedColumns
                    {...props}
                    manual={true}
                    onSortedChange={handleSortingChange}
                    showPagination={hasPagination}
                    sorted={sorted}
                    resizable={true}
                    sortable={sortable}
                    data={data}
                    defaultSorted={defaultSorted}
                    noDataText={noDataText}
                    columns={columns}
                    loading={loading}
                    page={page}
                    pageSize={pageSize}
                />
            </div>
        </StyledCustomTableWithFixedColumn>
    );
};
