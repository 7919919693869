import { Tooltip } from 'antd';
import { EditIcon } from 'shared/theme/assets/icons/EditIcon';
import { TrashIcon } from 'shared/theme/assets/icons/TrashIcon';

import { StyledIcons } from './styled/StyledIcons.styled';
import { simpleTableBackgroundHelper } from './tableUtil';

export const createUserTableColumns = (
    handleOpenEdit,
    handleOpenDelete,
    permissions
) => {
    return [
        {
            Header: 'Email',
            id: 'email',
            accessor: 'email',
            Cell: ({ original }) => {
                return (
                    <div className="tooltip-span-text">
                        <Tooltip
                            title={original.userEmail}
                            placement="bottomLeft"
                        >
                            <span>{original.userEmail}</span>
                        </Tooltip>
                    </div>
                );
            },
            resizable: true,
            sortable: true,
            minWidth: 180,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Role',
            id: 'role',
            accessor: 'role',
            Cell: ({ original }) => {
                return (
                    <div className="tooltip-span-text">
                        <Tooltip
                            title={original.assignedRole}
                            placement="bottomLeft"
                        >
                            <span>{original.assignedRole}</span>
                        </Tooltip>
                    </div>
                );
            },
            resizable: true,
            sortable: true,
            minWidth: 180,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Actions',
            id: 'actions',
            accessor: 'actions',
            Cell: ({ original }) => (
                <>
                    {!original.isSelf && (
                        <StyledIcons>
                            <div className="icons">
                                <div
                                    data-testid="user-edit"
                                    className="icon icon-edit"
                                    onClick={() =>
                                        permissions.canEditUser
                                            ? handleOpenEdit(original)
                                            : undefined
                                    }
                                >
                                    <EditIcon />
                                </div>
                                <div
                                    data-testid="user-delete"
                                    className="icon icon-delete"
                                    onClick={() =>
                                        permissions.canDeleteUser
                                            ? handleOpenDelete(original)
                                            : undefined
                                    }
                                >
                                    <TrashIcon />
                                </div>
                            </div>
                        </StyledIcons>
                    )}
                </>
            ),
            resizable: true,
            sortable: false,
            minWidth: 90,
            maxWidth: 350,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        }
    ];
};

const typeHandlerHelper = (state, rowInfo, column) => {
    return simpleTableBackgroundHelper(state, rowInfo, column);
};
