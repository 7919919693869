import styled from 'styled-components';

const StyledBenefitReportTab = styled.div`
    padding-top: 20px;
    .ant-spin {
        display: flex;
        justify-content: center;
    }
`;

export default StyledBenefitReportTab;
