export const MAX_LENGTH_RICH_TEXT = 1000;
export const MIN_LENGTH_RICH_TEXT = 3;

const IMAGE_ALT_TEXT_MAX_LENGTH = 120;
const NAME_MAX_LENGTH = 50;
const EMPY_RICH_TEXT_FIELD_VALUE = '<p><br></p>';

export const handleOfferLocationOnChange = async (
    name,
    values,
    setFieldValue,
    setFieldTouched
) => {
    const augmentedValues = values
        .map(value => {
            return {
                description: value.label,
                isNew: value.__isNew__ ?? false,
                id: value.__isNew__ ? null : value.value
            };
        })
        .sort((a, b) => a.description.localeCompare(b.description));

    await setFieldValue(name, augmentedValues);
    await setFieldTouched(name);
};

export const setClassName = val => {
    if (val) {
        return 'error-input';
    }
};

export const getSelectedLocationOnEdit = offer => {
    return offer.locations.map(x => {
        return { value: x.id, label: x.description };
    });
};

class OfferValidator {
    constructor(values) {
        this.errors = {};
        this.values = values;
    }

    validateImage = () => {
        if (!this.values.image) {
            this.errors.image = 'Offer image is required.';
        }

        return this;
    };

    validateImageAltText = maxLength => {
        const imageAltText = this.values.imageAltText?.trim();

        if (!imageAltText) {
            this.errors.imageAltText = 'Offer image Alt text is required.';
        } else if (imageAltText?.length > maxLength) {
            this.errors.imageAltText = `Offer image Alt text has max length of ${maxLength} characters.`;
        }

        return this;
    };

    validateName = maxLength => {
        const name = this.values.name?.trim();

        if (!name) {
            this.errors.name = 'Offer name is required.';
        } else if (name?.length > maxLength) {
            this.errors.name = `Offer name has max length of ${maxLength} characters.`;
        }

        return this;
    };

    validateShortDescription = (minLength, maxLength) => {
        const shortDescription = this.values.shortDescription?.trim();

        if (!shortDescription) {
            this.errors.shortDescription = 'Short description is required.';
        } else if (
            shortDescription?.length < minLength ||
            shortDescription?.length > maxLength
        ) {
            this.errors.shortDescription = `Short description should be between ${minLength} and ${maxLength} characters long.`;
        }

        return this;
    };

    validateHowMuch = (maxLength, emptyRichTextFieldValue) => {
        const howMuchPlainText = this.values.howMuch_plainText?.trim();
        const howMuch = this.values.howMuch?.trim();

        if (!howMuch || howMuch === emptyRichTextFieldValue) {
            this.errors.howMuch = 'How much is required.';
        } else if (howMuchPlainText && howMuchPlainText.length > maxLength) {
            this.errors.howMuch = `How to use must be up to ${maxLength} long.`;
        }

        return this;
    };

    validateHowToUse = (maxLength, emptyRichTextFieldValue) => {
        const howToUsePlainText = this.values.howToUse_plainText?.trim();
        const howToUse = this.values.howToUse?.trim();

        if (!howToUse || howToUse === emptyRichTextFieldValue) {
            this.errors.howToUse = 'How to use is required.';
        } else if (howToUsePlainText && howToUsePlainText.length > maxLength) {
            this.errors.howToUse = `How to use must be up to ${maxLength} long.`;
        }

        return this;
    };

    validateLocations = () => {
        const locations = this.values.locations;

        if (!locations?.length) {
            this.errors.locations = 'Location is required.';
        }

        return this;
    };
}

export const validateOffer = values => {
    const validator = new OfferValidator(values);

    validator
        .validateImage()
        .validateHowMuch(MAX_LENGTH_RICH_TEXT, EMPY_RICH_TEXT_FIELD_VALUE)
        .validateHowToUse(MAX_LENGTH_RICH_TEXT, EMPY_RICH_TEXT_FIELD_VALUE)
        .validateLocations()
        .validateImageAltText(IMAGE_ALT_TEXT_MAX_LENGTH)
        .validateName(NAME_MAX_LENGTH)
        .validateShortDescription(MIN_LENGTH_RICH_TEXT, MAX_LENGTH_RICH_TEXT);

    return validator.errors;
};
