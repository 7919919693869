import { useEffect, useState } from 'react';

import {
    canSubmitUserForm,
    validateUserOnSubmit
} from 'admin/utils/shared/userModalHelper';
import { Form, Formik } from 'formik';
import dropdownService from 'people/services/dropdownService';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRolesDropDown } from 'redux/slices/dropdown';
import { resetSingleUser } from 'redux/slices/user';
import {
    AsyncSearchSelect,
    CloseButton,
    CustomButton,
    CustomSelect,
    FieldsSection,
    CustomInput,
    errorNotification,
    Spinner
} from 'shared/components';
import { selectPlaceholder, setClassName } from 'utils/componentPropHelpers';
import { mapDataForDropdown } from 'utils/dropdownHelper';
import { checkTokenExp, redirectTokenExp } from 'utils/tokenHelper';

import { StyledUserModal } from './styled/UserModal.styled';

const INITIAL_VALUES = {
    personId: undefined,
    roleId: undefined
};

export const UserModal = ({
    title,
    initialValue = INITIAL_VALUES,
    visible,
    isEdit,
    setVisible = () => {},
    handleSubmit = () => {},
    closeCallback = () => {}
}) => {
    const dispatch = useDispatch();

    const { roles } = useSelector(x => x.dropdown);
    const roleOptions = mapDataForDropdown(roles);

    const [errors, setErrors] = useState({});

    const [editInitialValues, setEditInitialValues] = useState({});

    const { isLoadingFetchSingle, errorFetchSingle, userSingleData } =
        useSelector(state => state.user);

    const handleClose = () => {
        closeCallback();
        setVisible(false);
    };

    const localSubmit = values => {
        if (isEdit) {
            handleSubmit(values);
            return;
        }

        let validationErrors = validateUserOnSubmit(values);
        setErrors(validationErrors);

        if (canSubmitUserForm(values, errors)) {
            handleSubmit(values);
        }
    };

    const fetchWorkEmailsData = async (filter, previousId = null) => {
        try {
            if (checkTokenExp()) {
                redirectTokenExp();
            }
            const resp = await dropdownService.getWorkEmails(
                filter,
                previousId
            );
            const { data } = resp;
            return data;
        } catch (e) {
            const msg = e.message;
            errorNotification(msg);
        }
    };

    const handleDisableSubmitButton = (values, errors) => {
        if (isEdit) {
            return false;
        }

        return !canSubmitUserForm(values, errors);
    };

    useEffect(() => {
        dispatch(fetchRolesDropDown());
    }, [dispatch]);

    useEffect(() => {
        if (isEdit && userSingleData) {
            const initialValuesEdit = {
                userId: userSingleData.userId,
                userEmail: userSingleData.userEmail,
                roleId: userSingleData.assignedRoleId
            };

            setEditInitialValues(initialValuesEdit);
        }
    }, [userSingleData]);

    useEffect(() => {
        if (errorFetchSingle) {
            errorNotification(errorFetchSingle);
        }
    }, [errorFetchSingle]);

    useEffect(() => {
        return () => {
            if (isEdit) {
                dispatch(resetSingleUser());
            }
        };
    }, []);

    const formikValues = isEdit ? editInitialValues : initialValue;
    return (
        <Formik initialValues={formikValues} enableReinitialize={true}>
            {props => {
                const { values, setFieldValue } = props;
                return (
                    <StyledUserModal
                        visible={visible}
                        setVisible={setVisible}
                        title={[
                            <div
                                className="custom-modal-header"
                                key="modal-title"
                            >
                                {title}
                                <CloseButton handleClose={handleClose} />
                            </div>
                        ]}
                        footer={[
                            <div
                                className="custom-modal-footer"
                                key="modal-footer"
                            >
                                <div className="button-section">
                                    <CustomButton
                                        text="Close"
                                        type="normal footer-button"
                                        onClick={handleClose}
                                    />
                                </div>
                                <div className="button-section">
                                    <CustomButton
                                        text={isEdit ? 'Update' : 'Add'}
                                        type="filled footer-button"
                                        onClick={() => localSubmit(values)}
                                        disabled={handleDisableSubmitButton(
                                            values,
                                            errors
                                        )}
                                    />
                                </div>
                            </div>
                        ]}
                        handleCustomCancel={closeCallback}
                    >
                        {isLoadingFetchSingle || values === null ? (
                            <Spinner />
                        ) : (
                            <Form>
                                <div className="modal-body">
                                    <FieldsSection>
                                        <div className="section-inputs">
                                            {isEdit ? (
                                                <CustomInput
                                                    label="Email"
                                                    placeholder="Email..."
                                                    isNormalChange={false}
                                                    allowClear={true}
                                                    value={values.userEmail}
                                                    name={'userEmail'}
                                                    disabled={true}
                                                />
                                            ) : (
                                                <AsyncSearchSelect
                                                    label="Email"
                                                    value={values.personId}
                                                    name={'personId'}
                                                    displayDefaultOption={false}
                                                    setValueOption={
                                                        setFieldValue
                                                    }
                                                    placeholder={
                                                        selectPlaceholder
                                                    }
                                                    getData={
                                                        fetchWorkEmailsData
                                                    }
                                                    allowClear={true}
                                                    initialCallProps={[
                                                        '',
                                                        [values.personId]
                                                    ]}
                                                />
                                            )}
                                            <CustomSelect
                                                label={'Role'}
                                                name={'roleId'}
                                                placeholder={
                                                    'Select a role for the user'
                                                }
                                                value={values.roleId}
                                                onChange={(name, val) => {
                                                    setFieldValue(name, val);
                                                }}
                                                options={roleOptions}
                                            />
                                        </div>
                                    </FieldsSection>
                                </div>
                            </Form>
                        )}
                    </StyledUserModal>
                );
            }}
        </Formik>
    );
};
