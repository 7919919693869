import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const getRelativeMembers = async personId => {
    const data = await httpService.get(
        url.GET_RELATIVE_MEMBERS_BY_PERSON_ID(personId)
    );
    return data.data;
};

const postRelativeMember = async personId => {
    const data = await httpService.post(
        url.CREATE_RELATIVE_MEMBER_BY_PERSON_ID(personId)
    );
    return data.data;
};

const patchRelativeMemberById = async (relativeMemberId, requestData) => {
    const data = await httpService.patch(
        url.UPDATE_RELATIVE_MEMBER(relativeMemberId),
        {
            ...requestData
        }
    );
    return data.data;
};

const deleteRelativeMemberById = async relativeMemberId => {
    const data = await httpService.delete(
        url.DELETE_RELATIVE_MEMBER(relativeMemberId)
    );
    return data.data;
};

export default {
    getRelativeMembers,
    postRelativeMember,
    patchRelativeMemberById,
    deleteRelativeMemberById
};
