import {
    bulgarianLettersRegex,
    nameRegex
} from 'shared/constants/regexConstants';

export const STATUSES_IDS = {
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3,
    DELIVERED: 4
};

export const REQUEST_TYPES_IDS = {
    ACTIVATION: 1,
    DEACTIVATION: 2,
    CHANGE_OF_COVERAGE: 3
};

export const FREE_TEXT_MESSAGE_IDS = [8, 24, 19];

export const MAX_TEXTAREA_LENGTH = 300;

export const mapDataForMessagesDropdown = (
    benefitRequestMessages,
    benefitRequestStatusId,
    benefitRequestTypeId
) => {
    let filteredMessages = benefitRequestMessages.filter(
        benefitRequestMessage =>
            benefitRequestMessage.benefitRequestStatusId ===
                benefitRequestStatusId && benefitRequestMessage.active
    );

    if (benefitRequestTypeId) {
        filteredMessages = filteredMessages.filter(
            benefitRequestMessage =>
                benefitRequestMessage.benefitRequestTypeId ===
                benefitRequestTypeId
        );
    }

    return filteredMessages.map(
        ({ benefitRequestTemplateMessageId, shortMessage, message }) => ({
            value: benefitRequestTemplateMessageId,
            label: shortMessage ?? '',
            messasge: message
        })
    );
};

export const validatePendingForm = (
    {
        benefitRequestStatusId,
        benefitRequestTemplateMessageId,
        benefitRequestMessage,
        startDate,
        endDate,
        currentEndDate,
        relativeMemberName,
        relativeMemberNameBg
    },
    benefitRequestTypeId,
    showRelativeMemberInformation
) => {
    const errors = {};

    if (!benefitRequestStatusId) {
        errors.benefitRequestStatusId = 'Required';
    }

    if (!benefitRequestTemplateMessageId) {
        errors.benefitRequestTemplateMessageId = 'Required';
    }

    if (!benefitRequestMessage.trim()) {
        errors.benefitRequestMessage = 'Required';
    }

    if (benefitRequestMessage.trim().length > MAX_TEXTAREA_LENGTH) {
        errors.benefitRequestMessage = `The message must be less than ${MAX_TEXTAREA_LENGTH} characters long`;
    }

    if (
        !startDate &&
        benefitRequestStatusId === STATUSES_IDS.APPROVED &&
        benefitRequestTypeId !== REQUEST_TYPES_IDS.DEACTIVATION
    ) {
        errors.startDate = 'Required';
    }

    if (
        !endDate &&
        benefitRequestStatusId === STATUSES_IDS.APPROVED &&
        benefitRequestTypeId === REQUEST_TYPES_IDS.DEACTIVATION
    ) {
        errors.endDate = 'Required';
    }

    if (
        !currentEndDate &&
        benefitRequestStatusId === STATUSES_IDS.APPROVED &&
        benefitRequestTypeId === REQUEST_TYPES_IDS.CHANGE_OF_COVERAGE
    ) {
        errors.currentEndDate = 'Required';
    }

    if (showRelativeMemberInformation) {
        if (
            (!relativeMemberName || !relativeMemberName.trim()) &&
            showRelativeMemberInformation
        ) {
            errors.relativeMemberName = 'Required';
        }

        if (
            (!relativeMemberNameBg || !relativeMemberNameBg.trim()) &&
            showRelativeMemberInformation
        ) {
            errors.relativeMemberNameBg = 'Required';
        }

        if (relativeMemberName && relativeMemberName.length > 200) {
            errors.relativeMemberName =
                'The name must be less than 200 characters';
        }

        if (relativeMemberNameBg && relativeMemberNameBg.length > 200) {
            errors.relativeMemberNameBg =
                'The name must be less than 200 characters';
        }

        if (relativeMemberName && !nameRegex.test(relativeMemberName)) {
            errors.relativeMemberName = 'Name contains invalid characters';
        }

        if (
            relativeMemberNameBg &&
            !bulgarianLettersRegex.test(relativeMemberNameBg)
        ) {
            errors.relativeMemberNameBg = 'Name contains invalid characters';
        }
    }

    return errors;
};

export const validateApprovedForm = ({
    benefitRequestStatusId,
    benefitRequestTemplateMessageId,
    benefitRequestMessage
}) => {
    const errors = {};

    if (!benefitRequestStatusId) {
        errors.benefitRequestStatusId = 'Required';
    }

    if (!benefitRequestTemplateMessageId) {
        errors.benefitRequestTemplateMessageId = 'Required';
    }

    if (
        FREE_TEXT_MESSAGE_IDS.includes(benefitRequestTemplateMessageId) &&
        !benefitRequestMessage.trim()
    ) {
        errors.benefitRequestMessage = 'Required';
    }

    if (benefitRequestMessage.trim().length > MAX_TEXTAREA_LENGTH) {
        errors.benefitRequestMessage = `The message must be less than ${MAX_TEXTAREA_LENGTH} characters long`;
    }

    return errors;
};

export const validateDeliveredForm = ({
    benefitRequestStatusId,
    benefitRequestTemplateMessageId,
    benefitRequestMessage
}) => {
    const errors = {};

    if (!benefitRequestStatusId) {
        errors.benefitRequestStatusId = 'Required';
    }

    if (!benefitRequestTemplateMessageId) {
        errors.benefitRequestTemplateMessageId = 'Required';
    }

    if (
        FREE_TEXT_MESSAGE_IDS.includes(benefitRequestTemplateMessageId) &&
        !benefitRequestMessage.trim()
    ) {
        errors.benefitRequestMessage = 'Required';
    }

    if (
        FREE_TEXT_MESSAGE_IDS.includes(benefitRequestTemplateMessageId) &&
        benefitRequestMessage.trim().length > MAX_TEXTAREA_LENGTH
    ) {
        errors.benefitRequestMessage = `The message must be less than ${MAX_TEXTAREA_LENGTH} characters long`;
    }

    return errors;
};

export const handleOnChangeBenefitStatusId = (
    name,
    statusId,
    benefitRequestMessages,
    requestInfo,
    setErrors,
    setFieldValue,
    setMessageOptions,
    setIsFreeTextInputDisabled
) => {
    setErrors({});
    setFieldValue(name, statusId);
    setMessageOptions(
        mapDataForMessagesDropdown(
            benefitRequestMessages,
            statusId,
            requestInfo.benefitRequestTypeId
        )
    );
    const messageObj = benefitRequestMessages.find(
        ({ benefitRequestStatusId, benefitRequestTypeId, active }) =>
            active &&
            benefitRequestStatusId === statusId &&
            benefitRequestTypeId === requestInfo.benefitRequestTypeId
    );

    if (statusId === STATUSES_IDS.PENDING) {
        setFieldValue('benefitRequestTemplateMessageId', null);
        setFieldValue('benefitRequestMessage', '');
    } else {
        setFieldValue(
            'benefitRequestTemplateMessageId',
            messageObj.benefitRequestTemplateMessageId
        );
        setFieldValue('benefitRequestMessage', messageObj.message ?? '');
    }

    setIsFreeTextInputDisabled(true);
};

export const handleOnChangeBenefitRequestTemplateId = (
    name,
    templateId,
    benefitRequestMessages,
    setFieldValue,
    setIsFreeTextInputDisabled,
    setErrors
) => {
    setErrors({});
    setFieldValue(name, templateId);

    const requestMessage = benefitRequestMessages.find(
        x => x.benefitRequestTemplateMessageId === templateId && x.active
    );

    setFieldValue('benefitRequestMessage', requestMessage.message ?? '');
    setIsFreeTextInputDisabled(!(requestMessage.shortMessage === 'Free text'));
};
