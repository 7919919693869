import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
    changeSorting,
    clearState,
    createRole,
    fetchRoles,
    resetShowCreateMessage,
    resetSorting
} from 'redux/slices/role';
import { CustomButton, CustomConfirmationModal } from 'shared/components';
import Footer from 'shared/components/layout/Footer';
import {
    errorNotification,
    successNotification
} from 'shared/components/Notifications';
import useInfiniteScroll from 'shared/hooks/useInfiniteScroll';

import { StyledRolesTab } from './styled/RolesTab.style';
import { RolesTable } from './Table/RolesTable';
import { RoleModal } from '../shared/RoleModal/RoleModal';

const DEFAULT_SORT = [{ id: 'role', desc: false }];

export const RolesTab = ({ handleCreateColumns, permissions }) => {
    const dispatch = useDispatch();

    const { roles, isLoading, totalCount, error, showCreateMessage } =
        useSelector(state => state.role);

    const [sortState, setSortState] = useState(DEFAULT_SORT);
    const [addRoleModal, setAddRoleModal] = useState(false);

    useEffect(() => {
        dispatch(fetchRoles());
    }, [dispatch, sortState]);

    useEffect(() => {
        return () => {
            dispatch(resetSorting());
        };
    }, []);

    useEffect(() => {
        if (error) {
            errorNotification(error);
        }
    }, [error]);

    useEffect(() => {
        if (showCreateMessage) {
            successNotification('Successfully added a role.');
            dispatch(resetShowCreateMessage());
        }
    }, [showCreateMessage]);

    const handleSortUpdate = value => {
        setSortState(value);
        dispatch(changeSorting(value));
        dispatch(clearState());
    };

    // infinite scroll handling
    const boundaryRef = useInfiniteScroll({
        onLoadMore: () => {
            dispatch(fetchRoles(true));
        },
        isLoading,
        hasMore: Boolean(roles.length < totalCount)
    });

    const handleOpenAddModal = () => {
        setAddRoleModal(true);
    };

    const handleSubmitCreate = values => {
        const requestData = {
            permissions: [...values.permissions],
            roleName: values.roleName
        };
        dispatch(createRole(requestData));
        setAddRoleModal(false);
    };

    return (
        <>
            <StyledRolesTab>
                <div className="roles-container">
                    <div className="roles-extra-header">
                        <CustomButton
                            type={'normal'}
                            text={'Add New Role'}
                            onClick={() => {
                                permissions.canCreateRole
                                    ? handleOpenAddModal()
                                    : undefined;
                            }}
                            disabled={!permissions.canCreateRole}
                        />
                    </div>
                    <RolesTable
                        totalCount={totalCount}
                        handleCreateTableColumns={handleCreateColumns}
                        rolesData={roles}
                        pageSize={10}
                        loading={isLoading}
                        noDataText={'No Roles Found'}
                        hasPagination={false}
                        sorted={sortState}
                        handleSortUpdate={handleSortUpdate}
                        boundaryRef={boundaryRef}
                        permissions={permissions}
                    />
                    {addRoleModal && (
                        <RoleModal
                            title={'Add New Role'}
                            visible={addRoleModal}
                            isEditModal={false}
                            setVisible={setAddRoleModal}
                            handleSubmit={handleSubmitCreate}
                        />
                    )}
                </div>
            </StyledRolesTab>
            {!Boolean(roles.length < totalCount) && (
                <Footer className={'special'} />
            )}
        </>
    );
};
