import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'shared/components/Spinner';

import AllTabs from './AllTabs';
import { StyledDashboardHeader } from './styled/DashboardHeader.styled';
import { fetchAllBenefitRequestStatuses } from '../../redux/slices/dropdown';
import {
    fetchBenefitRequestsMessages,
    fetchRequestCounts
} from '../../redux/slices/request';

const DashboardHeader = ({ history }) => {
    const dispatch = useDispatch();
    const isLoadingTotalCounts = useSelector(
        state => state.request.isLoadingTotalCounts
    );

    useEffect(() => {
        dispatch(fetchRequestCounts());
        dispatch(fetchBenefitRequestsMessages());
        dispatch(fetchAllBenefitRequestStatuses());
    }, []);

    return (
        <StyledDashboardHeader>
            <div className="people-dashboard-header-wrapper">
                <div className="nav-list-items">
                    {isLoadingTotalCounts ? (
                        <div datatest-id="nav-spinner" className="spinner-div">
                            <Spinner />
                        </div>
                    ) : (
                        <AllTabs history={history} />
                    )}
                </div>
            </div>
        </StyledDashboardHeader>
    );
};

export default DashboardHeader;
