import styled from 'styled-components';

const StyledDatePicker = styled.div`
    .custom-datepicker {
        width: var(--defaultInputWrapWidth);
    }
    .custom-datepicker .input-label,
    .custom-datepicker .label-disabled {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 2px;
    }
    .custom-datepicker .label-disabled {
        color: var(--darkGray);
    }
    .custom-datepicker input {
        font-weight: normal;
    }
    .custom-datepicker .ant-picker {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border: 1px solid var(--gray);
        box-sizing: border-box;
        border-radius: 10px;
        padding: 10px 12px;
    }
    .anticon svg {
        fill: var(--secondaryBlue);
    }
    .ant-picker-clear {
        display: flex;
        align-items: center;
        margin-right: 23px;
    }
    .error-input .ant-picker {
        border-color: var(--red);
    }
`;

export { StyledDatePicker };
