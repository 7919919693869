import { createSlice } from '@reduxjs/toolkit';
import userService from 'admin/services/userService';

const initialState = {
    take: 10,
    sortBy: 'Email',
    sortAsc: true,

    users: [],
    usersTotal: 0,
    totalCount: 0,

    isLoading: false,
    error: null,

    isLoadingCreate: false,
    showCreateMessage: false,

    isLoadingFetchSingle: false,
    userSingleData: null,
    errorFetchSingle: null,

    isLoadingEdit: false,
    editError: null,
    showUpdateMessage: false,

    isLoadingDelete: false,
    deleteError: null,
    showDeleteMessage: false
};

const { reducer: userReducer, actions } = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // fetch users
        fetchUsersStart: state => {
            state.isLoading = true;
        },
        fetchUsersSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.isLoading = false;
            state.error = null;
            state.users = [...state.users, ...data];
            state.usersTotal = state.usersTotal + data.length;
            state.totalCount = totalCount;
        },
        fetchUsersFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        changeSortingState: (state, action) => {
            const { sortBy, sortAsc } = action.payload;

            state.sortBy = sortBy;
            state.sortAsc = sortAsc;
        },

        // create user
        createUserStart: state => {
            state.isLoading = true;
            state.isLoadingCreate = true;
        },

        createUserSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.isLoading = false;
            state.isLoadingCreate = false;
            state.showCreateMessage = true;
            state.error = null;

            state.users = [...data];
            state.usersTotal = data.length;
            state.totalCount = totalCount;
        },
        createUserFailure: (state, action) => {
            state.isLoading = false;
            state.isLoadingCreate = false;
            state.error = action.payload;
        },

        // fetch single user
        fetchSingleUserStart: state => {
            state.isLoadingFetchSingle = true;
        },
        fetchSingleUserSuccess: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.errorFetchSingle = null;
            state.userSingleData = action.payload;
        },
        fetchSingleUserFailure: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.errorFetchSingle = action.payload;
        },

        // update user
        updateUserStart: state => {
            state.isLoading = true;
            state.isLoadingEdit = true;
            state.editError = null;
        },
        updateUserSuccess: (state, action) => {
            state.isLoadingEdit = false;
            state.isLoading = false;
            state.editError = null;
            state.showUpdateMessage = true;
            const { data, totalCount } = action.payload;

            state.users = [...data];
            state.usersTotal = data.length;
            state.totalCount = totalCount;
            state.error = null;
        },
        updateUserFailure: (state, action) => {
            state.isLoadingEdit = false;
            state.isLoading = false;
            state.editError = action.payload;
        },

        // delete user
        deleteUserStart: state => {
            state.isLoadingDelete = true;
            state.deleteError = null;
            state.isLoading = true;
        },
        deleteUserSuccess: (state, action) => {
            state.isLoadingDelete = false;
            state.isLoading = false;
            state.deleteError = null;
            state.showDeleteMessage = true;
            const { data, totalCount } = action.payload;

            state.users = [...data];
            state.usersTotal = data.length;
            state.totalCount = totalCount;
            state.error = null;
        },
        deleteUserFailure: (state, action) => {
            state.isLoadingDelete = false;
            state.isLoading = false;
            state.deleteError = action.payload;
        },

        resetUpdateMessage: state => {
            state.showUpdateMessage = false;
        },

        resetDeleteMessage: state => {
            state.showDeleteMessage = false;
        },

        resetSingleUser: state => {
            state.isLoadingFetchSingle = false;
            state.userSingleData = null;
            state.errorFetchSingle = false;
        },
        resetCreateMessage: state => {
            state.showCreateMessage = false;
        },

        clearState: state => {
            state.take = 10;
            state.users = [];
            state.usersTotal = 0;
            state.totalCount = 0;
            state.isLoading = false;
            state.error = null;
        },

        resetSorting: state => {
            state.sortAsc = true;
            state.sortBy = 'Email';
            state.take = 10;
            state.users = [];
            state.usersTotal = 0;
            state.totalCount = 0;
            state.isLoading = false;
            state.error = null;
        }
    }
});

const fetchUsers = (searchPhrase, isScrolled) => {
    return async (dispatch, getState) => {
        const usersState = getState().user;

        if (usersState.isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchUsersStart());

            let skip = usersState.usersTotal;
            let take = usersState.take;

            if (!isScrolled) {
                skip = 0;
                take = 10;
                dispatch(actions.clearState());
            }

            const { sortAsc, sortBy } = usersState;

            const response = await userService.getAllUsers({
                skip,
                take,
                sortBy,
                sortAsc,
                searchPhrase
            });

            const {
                data,
                metadata: { totalCount }
            } = response;

            dispatch(actions.fetchUsersSuccess({ data, totalCount }));
        } catch (err) {
            dispatch(actions.fetchUsersFailure(err));
        }
    };
};

const createUser = requestData => {
    return async (dispatch, getState) => {
        if (getState().user.isLoadingCreate) {
            return;
        }

        try {
            dispatch(actions.createUserStart());
            await userService.createUser(requestData);
            const skip = 0;
            const take = getState().user.take;
            const resp = await userService.getAllUsers({ skip, take }, true);
            const {
                data,
                metadata: { totalCount }
            } = resp;
            dispatch(actions.createUserSuccess({ data, totalCount }));
        } catch (error) {
            const userHasAccessMessage =
                'User already has access to the system.';

            var messages = error.response.data.messages;

            if (messages.includes(userHasAccessMessage)) {
                dispatch(actions.createUserFailure(userHasAccessMessage));
            } else {
                dispatch(actions.createUserFailure('Something went wrong.'));
            }
        }
    };
};

const changeSorting = value => {
    const { id, desc } = value[0];
    const sortBy = id.charAt(0).toUpperCase() + id.slice(1);
    const sortAsc = sortBy === 'User' && desc === false ? true : !desc;
    return async dispatch => {
        dispatch(actions.changeSortingState({ sortBy, sortAsc }));
    };
};

const clearState = () => {
    return dispatch => {
        dispatch(actions.clearState());
    };
};

const resetSorting = () => {
    return dispatch => {
        dispatch(actions.resetSorting());
    };
};

const fetchSingleUser = userId => {
    return async (dispatch, getState) => {
        if (getState().user.isLoadingFetchSingle) {
            return;
        }

        try {
            dispatch(actions.fetchSingleUserStart());
            const data = await userService.getUserById(userId);
            dispatch(actions.fetchSingleUserSuccess(data.data));
        } catch (err) {
            dispatch(actions.fetchSingleUserFailure('Something went wrong.'));
        }
    };
};

const updateUser = (userId, requestData) => {
    return async (dispatch, getState) => {
        if (getState().user.isLoadingEdit) {
            return;
        }

        try {
            dispatch(actions.updateUserStart());
            await userService.updateUser(userId, requestData);

            const skip = 0;
            const take = getState().user.take;
            const response = await userService.getAllUsers(
                { skip, take },
                true
            );
            const {
                data,
                metadata: { totalCount }
            } = response;

            dispatch(actions.updateUserSuccess({ data, totalCount }));
        } catch (err) {
            dispatch(actions.updateUserFailure('Something went wrong.'));
        }
    };
};

const deleteUser = userId => {
    return async (dispatch, getState) => {
        if (getState().user.isLoadingDelete) {
            return;
        }
        dispatch(actions.deleteUserStart());

        try {
            const response = await userService.deleteUser(userId);

            if (response) {
                const skip = 0;
                const take = getState().user.take;
                const resp = await userService.getAllUsers(
                    { skip, take },
                    true
                );
                const {
                    data,
                    metadata: { totalCount }
                } = resp;
                dispatch(actions.deleteUserSuccess({ data, totalCount }));
            } else {
                dispatch(actions.deleteUserFailure('Something went wrong'));
            }
        } catch (err) {
            dispatch(actions.deleteUserFailure('Something went wrong'));
        }
    };
};

const resetShowCreateMessage = () => {
    return dispatch => {
        dispatch(actions.resetCreateMessage());
    };
};

const resetShowUpdateMessage = () => {
    return dispatch => {
        dispatch(actions.resetUpdateMessage());
    };
};

const resetShowDeleteMessage = () => {
    return dispatch => {
        dispatch(actions.resetDeleteMessage());
    };
};

const resetSingleUser = () => {
    return dispatch => {
        dispatch(actions.resetSingleUser());
    };
};

export {
    userReducer,
    actions as userActions,
    fetchUsers,
    fetchSingleUser,
    createUser,
    updateUser,
    deleteUser,
    resetShowUpdateMessage,
    resetShowDeleteMessage,
    resetSingleUser,
    clearState,
    resetSorting,
    changeSorting,
    resetShowCreateMessage
};
