/* eslint-disable prettier/prettier */
import styled from 'styled-components';

export const StyledTab = styled.div`
    .table-wrapper {
        margin-left: -20px;
        margin-right: -15px;
    }

    @media screen and (max-width: 768px) {
        .table-wrapper {
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    .container {
        width: 100%;
        padding-top: 10px;
    }

    .table-name-section {
        display: flex;
    }

    .name-info {
        padding-left: 10px;
    }

    .bold-name {
        font-weight: 600;
        color: var(--darkNavy);
    }

    .pointer > tr {
        margin: 10px;
    }

    .rt-tbody {
        .rt-tr {
            cursor: ${({ tabName }) =>
                tabName === 'cancelled' ? '' : 'pointer'};
            &:hover {
                background-color: var(--shadowSortedTableHeader);
                & > .rt-td {
                    background-color: transparent !important;
                }
            }
            .rt-td {
                background: none;
            }
        }
    }
`;
