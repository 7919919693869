import React, { useContext } from 'react';

import { Route, Switch } from 'react-router-dom';

import AdminDashboard from './admin/pages/AdminDashboard';
import Login from './auth/pages/Login';
import BenefitsDashboard from './benefits/pages/BenefitsDashboard';
import AuthorizedNotFoundPage from './main/pages/AuthenticatedNotFoundPage';
import MainDashboard from './main/pages/MainDashboard';
import Dashboard from './people/pages/Dashboard';
import EmployeeDetails from './people/pages/EmployeeDetails';
import ReportsDashboard from './reports/pages/ReportsDashboard';
import RequestsDashboard from './requests/pages/RequestsDashboard';
import { CustomRoute } from './shared/components/CustomRoute';

//Static routes
export const STATIC_ROUTES_PATHS = {
    login: '/',
    main: '/main',

    //People
    peopleDashboard: '/people',
    personDetails: '/people/details/:id/:activeTab',

    //Benefits requests
    requests: '/requests',

    //Benefits
    benefitsDashboard: '/benefits',

    //Reports
    reportsDashboard: '/reports',

    //Admin Panel
    adminDashboard: '/admin'
};

export const ROUTES = [
    {
        path: STATIC_ROUTES_PATHS.login,
        exact: true,
        authenticated: false,
        component: props => <Login {...props} />
    },
    {
        path: STATIC_ROUTES_PATHS.main,
        exact: true,
        authenticated: true,
        component: props => <MainDashboard {...props} />
    },
    {
        path: STATIC_ROUTES_PATHS.peopleDashboard,
        exact: true,
        authenticated: true,
        component: props => <Dashboard {...props} />
    },
    {
        path: STATIC_ROUTES_PATHS.requests,
        exact: true,
        authenticated: true,
        component: props => <RequestsDashboard {...props} />
    },
    {
        path: STATIC_ROUTES_PATHS.benefitsDashboard,
        exact: true,
        authenticated: true,
        component: props => <BenefitsDashboard {...props} />
    },
    {
        path: STATIC_ROUTES_PATHS.personDetails,
        exact: true,
        authenticated: true,
        component: props => <EmployeeDetails {...props} />
    },
    {
        path: STATIC_ROUTES_PATHS.reportsDashboard,
        exact: true,
        authenticated: true,
        component: props => <ReportsDashboard {...props} />
    },
    {
        path: STATIC_ROUTES_PATHS.adminDashboard,
        exact: true,
        authenticated: true,
        component: props => <AdminDashboard {...props} />
    }
];

const Routes = () => {
    return (
        <Switch>
            <Route path={STATIC_ROUTES_PATHS.login} exact component={Login} />
            {ROUTES.map((route, i) => (
                <CustomRoute key={i} {...route} />
            ))}
            <Route
                path={'/unauthorized'}
                render={props => (
                    <AuthorizedNotFoundPage
                        {...props}
                        message={"You don't have permission to view this page."}
                    />
                )}
            />
            <Route
                render={props => (
                    <AuthorizedNotFoundPage
                        {...props}
                        message={'Something went wrong.'}
                    />
                )}
            />
        </Switch>
    );
};

export default Routes;
