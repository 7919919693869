import React from 'react';

const DownloadIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            fill="none"
            viewBox="0 0 21 20"
        >
            <path
                stroke="#03A8F5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M18 12.5v3.333a1.666 1.666 0 01-1.667 1.667H4.667A1.667 1.667 0 013 15.833V12.5M6.333 8.334L10.5 12.5l4.167-4.166M10.5 12.5v-10"
            ></path>
        </svg>
    );
};

export default DownloadIcon;
