import { REQUEST_TYPES_IDS } from './utils';
import StyledBenefitRequestInfo from '../styled/BenefitRequestInfo.styled';

const BenefitRequestInfo = ({ requestInfo }) => {
    return (
        <StyledBenefitRequestInfo>
            <div>
                <span className="label">Benefit:</span> {requestInfo.benefit}
            </div>
            <div>
                <span className="label">Benefit Amount:</span>{' '}
                {requestInfo.benefitAmount}
            </div>
            <div>
                <span className="label">Employee Name:</span>{' '}
                {requestInfo.employeeName}
            </div>
            <div>
                <span className="label">Location:</span>{' '}
                {requestInfo.location || (
                    <span className="italic">No location registered</span>
                )}
            </div>
            <div>
                <span className="label">Type of Request:</span>{' '}
                {requestInfo.benefitRequestType}
            </div>
            <div>
                <span className="label">
                    {requestInfo.benefitRequestTypeId ===
                    REQUEST_TYPES_IDS.DEACTIVATION
                        ? 'Current'
                        : 'Requested'}{' '}
                    Coverage:
                </span>{' '}
                {requestInfo.coveredBy}
            </div>
            {requestInfo.showRelativeMemberInformation && (
                <>
                    <div>
                        <span className="label">Family Relationship:</span>{' '}
                        {requestInfo.relativeMemberRelation}
                    </div>
                    <div>
                        <span className="label">Family Member Birth Date:</span>{' '}
                        {requestInfo.relativeMemberBirthDate
                            ? requestInfo.relativeMemberBirthDate
                            : 'Information absent'}
                    </div>
                </>
            )}
        </StyledBenefitRequestInfo>
    );
};
export default BenefitRequestInfo;
