import styled from 'styled-components';

const StyledCustomTimeline = styled.div`
    margin-top: 10px;
    .edit {
        .vertical-timeline-element-content {
            padding: 12px 17px 12px 12px !important;
        }
    }
    .action-btn {
        cursor: pointer;
    }
    .year-label {
        margin: 0 auto;
        justify-content: center;
        display: flex;
        background: var(--orange);
        color: white;
        width: 53px;
        height: 27px;
        font-size: 14px;
        border: 2px solid var(--primaryWhite);
        box-sizing: border-box;
        border-radius: 6px;
    }
    .vertical-timeline {
        width: 95%;
        max-width: 1170px;
        margin: 0 auto;
        position: relative;
        padding: 20px 0;
        ::after {
            content: '';
            display: table;
            clear: both;
        }
        ::before {
            content: '';
            position: absolute;
            top: 0;
            left: 18px;
            height: 100%;
            width: 4px;
            background: #fff;
        }
        * {
            box-sizing: border-box;
        }
    }
    .vertical-timeline.custom-line::before {
        background: #eaeaea;
    }
    .no-data {
        padding-left: 20px;
        padding-bottom: 20px;
    }
    .vertical-timeline-element-content-arrow {
        content: '';
        position: absolute;
        top: 16px;
        right: 100%;
        height: 0;
        width: 0;
        border: 7px solid transparent;
        border-right: 7px solid var(--primaryWhite) !important;
    }
    .vertical-timeline-element {
        position: relative;
        margin: 0 !important;
        margin-bottom: -35px !important;
        > div {
            min-height: 1px;
        }
        :after {
            content: '';
            display: table;
            clear: both;
        }
        :first-child {
            margin-top: 0;
        }
        :last-child {
            margin-bottom: 0px !important;
        }
    }
    .vertical-timeline-element-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
            0 3px 0 4px rgba(0, 0, 0, 0.05);
        -webkit-transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        svg {
            display: block;
            width: 24px;
            height: 24px;
            position: relative;
            left: 50%;
            top: 50%;
            margin-left: -12px;
            margin-top: -12px;
        }
    }
    .vertical-timeline-element-content {
        position: relative;
        margin-left: 60px;
        background: #fff;
        border-radius: 8px;
        padding: 1em;
        box-shadow: 2px 3px 10px rgba(39, 70, 91, 0.16);
        z-index: 5;
        h2 {
            color: #303e49;
        }
        .vertical-timeline-element-date {
            font-size: 12px;
            font-weight: 500;
        }
        p {
            font-size: 12px;
            font-weight: 500;
        }
        .vertical-timeline-element-date {
            display: none;
        }
        p {
            margin: 1em 0 0;
            line-height: 1.6;
        }
        .vertical-timeline-element-date {
            float: left;
            padding: 0.8em 0;
            opacity: 0.7;
        }
        :after {
            content: '';
            display: table;
            clear: both;
        }
    }
    .vertical-timeline--animate {
        .vertical-timeline-element-icon.is-hidden {
            visibility: hidden;
        }
        .vertical-timeline-element-icon.bounce-in {
            visibility: visible;
            -webkit-animation: cd-bounce-1 0.6s;
            -moz-animation: cd-bounce-1 0.6s;
            animation: cd-bounce-1 0.6s;
        }
        .vertical-timeline-element-content.is-hidden {
            visibility: hidden;
        }
        .vertical-timeline-element-content.bounce-in {
            visibility: visible;
            -webkit-animation: cd-bounce-2 0.6s;
            -moz-animation: cd-bounce-2 0.6s;
            animation: cd-bounce-2 0.6s;
        }
    }
    .vertical-timeline-element--no-children {
        .vertical-timeline-element-content {
            background: 0 0;
            box-shadow: none;
            ::before {
                display: none;
            }
        }
        .vertical-timeline-element-content-arrow {
            display: none;
        }
    }

    @media only screen and (min-width: 768px) {
        .vertical-timeline.vertical-timeline--two-columns {
            width: 90%;
        }
        .vertical-timeline.vertical-timeline--two-columns:before {
            left: 50%;
            margin-left: -2px;
        }
    }
    @media only screen and (min-width: 768px) {
        .vertical-timeline-element {
            margin: 4em 0;
        }
        .vertical-timeline-element:first-child {
            margin-top: 0;
        }
        .vertical-timeline-element:last-child {
            margin-bottom: 0;
        }
    }
    @media only screen and (min-width: 768px) {
        .vertical-timeline--two-columns .vertical-timeline-element-icon {
            width: 15px;
            height: 15px;
            left: 50%;
            margin-left: -7.4px;
            margin-top: 25px;
        }
    }
    @media only screen and (max-width: 768px) {
        .vertical-timeline--two-columns
            .vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content,
        .vertical-timeline--two-columns
            .vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content {
            float: right;
            margin-right: 35px !important;
            width: 200px !important;
        }
        .vertical-timeline--two-columns .vertical-timeline-element-content {
            margin-left: 35px !important;
            width: 200px !important;
        }
        .vertical-timeline.custom-line::before {
            background: #eaeaea;
            left: 50% !important;
        }
        span.vertical-timeline-element-icon.bounce-in {
            width: 15px;
            height: 15px;
            left: 50%;
        }
        /**
            copy of upper one
            special for edit view
            right side of the timeline
        */
        .vertical-timeline--two-columns
            .edit.vertical-timeline-element.vertical-timeline-element--left
            .vertical-timeline-element-content,
        .vertical-timeline--two-.vertical-timeline.vertical-timeline--two-columns
            .edit.vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--right
            )
            .vertical-timeline-element-content {
            margin-right: 94px !important;
            margin-left: -24px !important;
            width: 259px !important;
        }
        /**
            copy of upper one
            special for edit view
            right side of the timeline
        */
        .vertical-timeline--two-columns
            .edit.vertical-timeline-element.vertical-timeline-element--left
            .vertical-timeline-element-content
            .vertical-timeline-element-date,
        .vertical-timeline--two-.vertical-timeline.vertical-timeline--two-columns
            .edit.vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--right
            )
            .vertical-timeline-element-content
            .vertical-timeline-element-date {
            left: 118.5% !important;
        }
        /**
            copy of upper one
            special for edit view
            left side of the timeline
        */
        .vertical-timeline--two-columns
            .edit.vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content,
        .vertical-timeline--two-.vertical-timeline.vertical-timeline--two-columns
            .edit.vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content {
            margin-left: 94px !important;
            margin-right: -24px !important;
            width: 259px !important;
        }
        /**
            copy of upper one
            special for edit view
            left side of the timeline
        */
        .vertical-timeline--two-columns
            .edit.vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content
            .vertical-timeline-element-date,
        .vertical-timeline--two-columns
            .edit.vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content
            .vertical-timeline-element-date {
            right: 118.5% !important;
        }
    }
    @media only screen and (max-width: 426px) {
        .vertical-timeline--two-columns
            .vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content,
        .vertical-timeline--two-columns
            .vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content {
            float: right;
            margin-right: 5px !important;
        }
        .vertical-timeline--two-columns .vertical-timeline-element-content {
            margin-left: 5px !important;
        }
        .vertical-timeline.custom-line::before {
            background: #eaeaea;
            left: 50% !important;
        }
        span.vertical-timeline-element-icon.bounce-in {
            width: 15px;
            height: 15px;
            left: 50%;
            margin-left: -1px;
            margin-top: 10px;
        }
        /**
            copy of upper one
            special for edit view
            right side of the timeline
        */
        .vertical-timeline--two-columns
            .edit.vertical-timeline-element.vertical-timeline-element--left
            .vertical-timeline-element-content,
        .vertical-timeline--two-.vertical-timeline.vertical-timeline--two-columns
            .edit.vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--right
            )
            .vertical-timeline-element-content {
            margin-right: 94px !important;
            margin-left: 0px !important;
            width: 170px !important;
            padding: 10px !important;
        }
        /**
            copy of upper one
            special for edit view
            right side of the timeline
        */
        .vertical-timeline--two-columns
            .edit.vertical-timeline-element.vertical-timeline-element--left
            .vertical-timeline-element-content
            .vertical-timeline-element-date,
        .vertical-timeline--two-.vertical-timeline.vertical-timeline--two-columns
            .edit.vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--right
            )
            .vertical-timeline-element-content
            .vertical-timeline-element-date {
            left: 118.5% !important;
        }
        /**
            copy of upper one
            special for edit view
            left side of the timeline
        */
        .vertical-timeline--two-columns
            .edit.vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content,
        .vertical-timeline--two-.vertical-timeline.vertical-timeline--two-columns
            .edit.vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content {
            margin-left: 94px !important;
            margin-right: 0px !important;
            width: 170px !important;
            padding: 10px !important;
        }
        /**
            copy of upper one
            special for edit view
            left side of the timeline
        */
        .vertical-timeline--two-columns
            .edit.vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content
            .vertical-timeline-element-date,
        .vertical-timeline--two-columns
            .edit.vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content
            .vertical-timeline-element-date {
            right: 118.5% !important;
        }
    }
    @media only screen and (min-width: 768px) {
        .vertical-timeline--two-columns .vertical-timeline-element-content {
            width: 250px;
            background: white;
            color: black;
            padding: 12px 11px 12px 16px;
            margin-left: 134px;
        }
        .vertical-timeline--two-columns
            .vertical-timeline-element-content-arrow {
            top: 24px;
            left: 100%;
            transform: rotate(180deg);
        }
        .vertical-timeline--two-columns
            .vertical-timeline-element-content
            .vertical-timeline-element-date {
            position: absolute;
            left: 124%;
            top: 15px;
            background: #f2f4f6;
            width: 80px;
            border-radius: 2px;
            font-size: 12px;
            padding: 5px 10px;
        }
        .vertical-timeline--two-columns
            .vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content,
        .vertical-timeline--two-columns
            .vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content {
            float: right;
            width: 250px;
            background: white;
            color: black;
            padding: 12px 11px 12px 16px;
            margin-right: 134px;
            margin-left: 0px;
        }
        /**
            copy of upper one
            special for edit view
            right side of the timeline
        */
        .vertical-timeline--two-columns
            .edit.vertical-timeline-element.vertical-timeline-element--left
            .vertical-timeline-element-content,
        .vertical-timeline--two-.vertical-timeline.vertical-timeline--two-columns
            .edit.vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--right
            )
            .vertical-timeline-element-content {
            width: 259px;
        }
        /**
            copy of upper one
            special for edit view
            right side of the timeline
        */
        .vertical-timeline--two-columns
            .edit.vertical-timeline-element.vertical-timeline-element--left
            .vertical-timeline-element-content
            .vertical-timeline-element-date,
        .vertical-timeline--two-.vertical-timeline.vertical-timeline--two-columns
            .edit.vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--right
            )
            .vertical-timeline-element-content
            .vertical-timeline-element-date {
            left: 119.6%;
        }
        /**
            copy of upper one
            special for edit view
            left side of the timeline
        */
        .vertical-timeline--two-columns
            .edit.vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content,
        .vertical-timeline--two-.vertical-timeline.vertical-timeline--two-columns
            .edit.vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content {
            width: 259px;
        }
        /**
            copy of upper one
            special for edit view
            left side of the timeline
        */
        .vertical-timeline--two-columns
            .edit.vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content
            .vertical-timeline-element-date,
        .vertical-timeline--two-columns
            .edit.vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content
            .vertical-timeline-element-date {
            right: 119.6%;
        }
        .vertical-timeline--two-columns
            .vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content-arrow,
        .vertical-timeline--two-columns
            .vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content-arrow {
            top: 24px;
            left: auto;
            right: 100%;
            transform: rotate(0);
        }
        .vertical-timeline--one-column-right
            .vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content-arrow,
        .vertical-timeline--one-column-right
            .vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content-arrow {
            top: 24px;
            left: 100%;
            right: auto;
            transform: rotate(0);
        }
        .vertical-timeline--two-columns
            .vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content
            .vertical-timeline-element-date,
        .vertical-timeline--two-columns
            .vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content
            .vertical-timeline-element-date {
            left: auto;
            right: 124%;
            text-align: right;
        }
    }
    @media only screen and (min-width: 425px) and (max-width: 767px) {
        span.vertical-timeline-element-icon.bounce-in {
            left: 49% !important;
            margin-top: 24px;
        }
        .vertical-timeline--two-columns .vertical-timeline-element-content {
            width: 150px !important;
            margin-left: 20px !important;
        }
        .vertical-timeline--two-columns
            .vertical-timeline-element-content-arrow {
            top: 24px;
            left: 100%;
            transform: rotate(180deg);
        }
        .vertical-timeline--two-columns
            .vertical-timeline-element-content
            .vertical-timeline-element-date {
            position: absolute;
            left: 124%;
            top: 15px;
            background: #f2f4f6;
            width: 80px;
            border-radius: 2px;
            font-size: 12px;
            padding: 5px 10px;
        }
        .vertical-timeline--two-columns
            .vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content,
        .vertical-timeline--two-columns
            .vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content {
            float: right;
            width: 150px !important;
            margin-right: 15px !important;
        }
        .vertical-timeline--two-columns
            .vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content-arrow,
        .vertical-timeline--two-columns
            .vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content-arrow {
            top: 24px;
            left: auto;
            right: 100%;
            transform: rotate(0);
        }
        .vertical-timeline--one-column-right
            .vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content-arrow,
        .vertical-timeline--one-column-right
            .vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content-arrow {
            top: 24px;
            left: 100%;
            right: auto;
            transform: rotate(0);
        }
        .vertical-timeline--two-columns
            .vertical-timeline-element.vertical-timeline-element--right
            .vertical-timeline-element-content
            .vertical-timeline-element-date,
        .vertical-timeline--two-columns
            .vertical-timeline-element:nth-child(even):not(
                .vertical-timeline-element--left
            )
            .vertical-timeline-element-content
            .vertical-timeline-element-date {
            left: auto;
            right: 128%;
            text-align: right;
            margin-top: 8px;
        }
    }
`;

export { StyledCustomTimeline };
