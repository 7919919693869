import { createContext, useContext, useReducer } from 'react';

const initialState = {
    entryDate: { label: null, value: null },
    benefitType: { label: null, value: null },
    requestType: { label: null, value: null },
    coveredBy: { label: null, value: null },
    workingTime: { label: null, value: null },
    location: { label: null, value: null }
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'add':
            return {
                ...state,
                [payload.filterName]: {
                    value: payload.value,
                    label: payload.label
                }
            };
        case 'remove':
            return {
                ...state,
                [payload.filterName]: {
                    value: null,
                    label: null
                }
            };
        case 'resetAll':
            return initialState;
        default:
            return state;
    }
};

const FilterContext = createContext(undefined);

export const FilterProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const addFilter = ({ filterName, value, label }) => {
        dispatch({
            type: 'add',
            payload: {
                filterName,
                value,
                label
            }
        });
    };

    const removeFilter = filterName => {
        dispatch({
            type: 'remove',
            payload: {
                filterName
            }
        });
    };

    const resetFilters = () => {
        dispatch({ type: 'resetAll' });
    };

    return (
        <FilterContext.Provider
            value={{ filters: state, addFilter, removeFilter, resetFilters }}
        >
            {children}
        </FilterContext.Provider>
    );
};

const useFilter = () => {
    const context = useContext(FilterContext);

    if (context === undefined) {
        throw new Error('useFilter must be used within FilterContext');
    }

    return context;
};

export default useFilter;
