import React from 'react';

export const SuccessIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
            viewBox="0 0 14 14"
        >
            <path
                fill="#04D294"
                d="M5.852 8.04L4.61 6.662a.34.34 0 00-.253-.116.34.34 0 00-.252.116.427.427 0 000 .56l1.497 1.663c.14.155.365.155.505 0l3.788-4.207a.428.428 0 000-.56A.34.34 0 009.643 4a.34.34 0 00-.253.116L5.852 8.041z"
            ></path>
            <rect
                width="13"
                height="13"
                x="0.5"
                y="0.5"
                stroke="#04D294"
                rx="6.5"
            ></rect>
        </svg>
    );
};
