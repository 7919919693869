import React from 'react';

import { Helmet } from 'react-helmet';

import { StyledReportsDashboard } from './styled/ReportsDashboard.styled';
import { Main } from '../../shared/components/layout/Main';
import DashboardHeader from '../components/DashboardHeader';

const ReportsDashboard = props => {
    const { history } = props;
    const pageTitle = 'Humate';

    return (
        <StyledReportsDashboard>
            <Helmet title={pageTitle} />
            <Main
                hasHeader={true}
                hasNavigation={true}
                hasHeading={false}
                hasCustomHeading={true}
                customHeadingComponent={<DashboardHeader history={history} />}
                history={history}
            />
        </StyledReportsDashboard>
    );
};

export default ReportsDashboard;
