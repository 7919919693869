import React from 'react';

import { Tooltip } from 'antd';
import CoveredByCell from 'benefits/components/Table/CoveredByCell';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import {
    getColumnWidth,
    backgroundHelper
} from '../../../people/utils/Dashboard/tableUtil';
import { Media } from '../../../shared/components/Media';

export const createPeopleBenefitsTableColumns = (
    people,
    handleRedirectToDetails,
    columnType,
    permissions
) => {
    return [
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            Cell: ({ original }) => {
                let nameCellContent = (
                    <div className="table-name-section">
                        <Media
                            className={'wide-avatar'}
                            photoUrl={original.photo}
                        />
                        <div className="name-info bold-name">
                            {original.name} <br></br>({original.nameBg})
                        </div>
                    </div>
                );

                if (permissions.canViewPeopleDetailsPage) {
                    nameCellContent = (
                        <Link to={handleRedirectToDetails(original.personId)}>
                            {nameCellContent}
                        </Link>
                    );
                }

                return nameCellContent;
            },

            width:
                people && people.length > 0
                    ? getColumnWidth(people, 'Name')
                    : 220,
            resizable: false,
            sortable: true,
            fixed: 'left',
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column, columnType)
                };
            }
        },
        {
            Header: 'Personal ID',
            id: 'personalId',
            accessor: 'personalId',
            Cell: ({ original }) => {
                const len = !isEmpty(original.personalId)
                    ? original.personalId.length
                    : 0;
                if (len >= 10)
                    return (
                        <div className="tooltip-span-text">
                            <Tooltip
                                title={original.personalId}
                                placement="bottom"
                            >
                                <span>{original.personalId}</span>
                            </Tooltip>
                        </div>
                    );
                else if (len < 10)
                    return (
                        <div className="tooltip-span-text">
                            <span>{original.personalId}</span>
                        </div>
                    );
            },
            sortable: true,
            resizable: true,
            minWidth: 100,
            width: 120,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column, columnType)
                };
            }
        },
        {
            Header: 'Location',
            id: 'location',
            accessor: 'location',
            Cell: ({ original }) => {
                const len = !isEmpty(original.location)
                    ? original.location.length
                    : 0;
                if (len >= 10)
                    return (
                        <div className="tooltip-span-text">
                            <Tooltip
                                title={original.location}
                                placement="bottom"
                            >
                                <span>{original.location}</span>
                            </Tooltip>
                        </div>
                    );
                else if (len < 10)
                    return (
                        <div className="tooltip-span-text">
                            <span>{original.location}</span>
                        </div>
                    );
            },
            sortable: true,
            resizable: true,
            minWidth: 100,
            width: 120,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column, columnType)
                };
            }
        },
        {
            Header: 'Type of MentorMater',
            id: 'mentormaterType',
            accessor: 'mentormaterType',
            Cell: ({ original }) => {
                const len = !isEmpty(original.mentormator)
                    ? original.mentormator.length
                    : 0;
                if (len >= 10)
                    return (
                        <div className="tooltip-span-text">
                            <Tooltip
                                title={original.mentormator}
                                placement="bottom"
                            >
                                <span>{original.mentormator}</span>
                            </Tooltip>
                        </div>
                    );
                else if (len < 10)
                    return (
                        <div className="tooltip-span-text">
                            <span>{original.mentormator}</span>
                        </div>
                    );
            },
            resizable: true,
            sortable: false,
            minWidth: 95,
            maxWidth: 130,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column, columnType)
                };
            }
        },
        {
            Header: `Covered by MM`,
            id: 'benefitsCoveredByMM',
            accessor: 'benefitsCoveredByMM',
            Cell: ({ original, maxWidth }) => (
                <CoveredByCell
                    benefits={original.benefitsCoveredByMM}
                    maxWidth={maxWidth}
                />
            ),
            sortable: false,
            resizable: true,
            getProps: (state, rowInfo, column) => ({
                style: {
                    ...typeHandlerHelper(state, rowInfo, column, columnType),
                    height: 'auto'
                }
            })
        },
        {
            Header: `Covered by employee`,
            id: 'benefitsEmployee',
            accessor: 'benefitsEmployee',
            Cell: ({ original, maxWidth }) => (
                <CoveredByCell
                    benefits={original.benefitsCoveredByEmployee}
                    width={maxWidth}
                />
            ),
            sortable: false,
            resizable: true,
            getProps: (state, rowInfo, column) => ({
                style: {
                    ...typeHandlerHelper(state, rowInfo, column, columnType),
                    height: 'auto'
                }
            })
        }
    ];
};

const typeHandlerHelper = (state, rowInfo, column) => {
    return backgroundHelper(state, rowInfo, column);
};
