import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { StyledDashboard } from './styled/Dashboard.styled';
import { Main } from '../../shared/components/layout/Main';
import { errorNotification } from '../../shared/components/Notifications';
import DashboardHeader from '../components/DashboardHeader';

const RequestsDashboard = () => {
    const history = useHistory();
    const [tableCount, setTableCount] = useState(0);
    const { errorFetchPerson } = useSelector(state => state.people);
    const pageTitle = 'Humate';

    useEffect(() => {
        if (errorFetchPerson) {
            errorNotification(errorFetchPerson, 5);
        }
    }, [errorFetchPerson]);

    const handleScroll = () => {
        const rect = document.getElementsByClassName('rt-tbody')[0];
        const elementSticky =
            document.getElementsByClassName('rt-thead -header')[0];
        const table = document.getElementsByClassName('ReactTable')[0];
        if (rect && elementSticky && table) {
            let currentPosition = rect.getBoundingClientRect();
            let tableTop = table.getBoundingClientRect();
            if (currentPosition.top <= 105) {
                elementSticky.className = 'rt-thead -header header-static';
                elementSticky.style.top = `${Math.abs(tableTop.top - 60)}px`;
            } else {
                elementSticky.className = 'rt-thead -header';
                elementSticky.style.top = '0';
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        handleScroll();
    }, [tableCount]);

    const handleUpdateCount = countData => {
        setTableCount(countData);
    };

    return (
        <>
            <Helmet title={pageTitle} />
            <StyledDashboard>
                <Main
                    history={history}
                    hasHeader={true}
                    hasNavigation={true}
                    hasHeading={false}
                    hasCustomHeading={true}
                    customHeadingComponent={
                        <DashboardHeader history={history} />
                    }
                />
            </StyledDashboard>
        </>
    );
};

export default RequestsDashboard;
