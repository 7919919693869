import React, { useContext } from 'react';

import { createDepartmentTableColumns } from 'admin/utils/Dashboard/departmentColumns';
import { createLevelTableColumns } from 'admin/utils/Dashboard/levelColumns';
import { shouldDisableTab } from 'admin/utils/shared/adminPermissionsHelper';
import PermissionsContext from 'shared/utils/permissionStore';

import { DepartmentsSubtab } from './DepartmentsSubtab';
import { LevelsSubtab } from './LevelsSubtab';
import { LocationsSubtab } from './LocationsSubtab';
import { PositionsSubtab } from './PositionsSubtab';
import { StyledDashboardTabs } from './styled/DashboardTabs.styled';
import TabsWrapper from '../../../shared/components/DashboardTabs/TabsWrapper';
import { createLocationTableColumns } from '../../utils/Dashboard/locationColumns';
import { createPositionTableColumns } from '../../utils/Dashboard/positionColumns';

export const ResourcesDashboardTabs = ({ history }) => {
    const permissions = useContext(PermissionsContext).permissions;

    const TABS = [
        {
            label: 'Positions',
            value: 1,
            disabled: shouldDisableTab,
            handleCreateColumns: createPositionTableColumns,
            child: props => (
                <PositionsSubtab
                    subset={props.element}
                    handleCreateColumns={props.element.handleCreateColumns}
                    disabled={props.element.disabled}
                    permissions={props.permissions}
                />
            )
        },
        {
            label: 'Locations',
            value: 2,
            disabled: shouldDisableTab,
            handleCreateColumns: createLocationTableColumns,
            child: props => (
                <LocationsSubtab
                    subset={props.element}
                    handleCreateColumns={props.element.handleCreateColumns}
                    disabled={props.element.disabled}
                    permissions={props.permissions}
                />
            )
        },
        {
            label: 'Departments',
            value: 3,
            disabled: shouldDisableTab,
            handleCreateColumns: createDepartmentTableColumns,
            child: props => (
                <DepartmentsSubtab
                    subset={props.element}
                    handleCreateColumns={props.element.handleCreateColumns}
                    disabled={props.element.disabled}
                    permissions={props.permissions}
                />
            )
        },
        {
            label: 'Levels',
            value: 4,
            disabled: shouldDisableTab,
            handleCreateColumns: createLevelTableColumns,
            child: props => (
                <LevelsSubtab
                    subset={props.element}
                    handleCreateColumns={props.element.handleCreateColumns}
                    disabled={props.element.disabled}
                    permissions={props.permissions}
                />
            )
        }
    ];

    const tabElements = TABS.map((element, i) => {
        return (
            <div
                key={`${i}tab`}
                label={`${element.label}`}
                disabled={element.disabled(element.label, permissions)}
            >
                {element.child({ element, history, permissions })}
            </div>
        );
    });

    return (
        <StyledDashboardTabs>
            <TabsWrapper activeItemIndex={0}>{tabElements}</TabsWrapper>
        </StyledDashboardTabs>
    );
};
