/* eslint-disable prettier/prettier */
import styled from 'styled-components';

const StyledPeopleSubsetTab = styled.div`
    .table-wrapper {
        margin-left: -20px;
        margin-right: -15px;
    }
    @media screen and (max-width: 768px) {
        .table-wrapper {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    .all-people-container {
        width: 100%;
        padding-top: 10px;
    }
    .table-name-section {
        display: flex;
        cursor: pointer;
    }
    .name-info {
        padding-left: 10px;
    }
    .bold-name {
        font-weight: 600;
        color: var(--darkNavy);
    }
    .pointer > tr {
        margin: 10px;
    }

    // filter toggle
    .filter-toggle {
        align-self: center;
    }

    .inner-section {
        display: flex;
        align-items: center;
        height: 20px;
        padding: 0px;
        font-family: 'Source-Sans-Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
    }
    .inner-section svg {
        margin: 0px 0px 2px 8px;
    }

    // layout change icons
    .search-sort {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 18px;
    }
    .view-buttons {
        display: flex;
        width: 68px;
        justify-content: space-between;
        margin-left: 20px;
    }
    .view-button {
        padding-top: 6px;
        width: 32px;
        height: 32px;
        border: 1px solid var(--gray);
        border-radius: 5px;
        background-color: var(--gray);
    }
    .view-button.is-selected {
        fill: white;
        border: 1px solid var(--mainBlue);
        border-radius: 5px;
        background-color: var(--mainBlue) !important;
    }
    .view-button svg {
        display: block;
        margin: auto;
    }
    .view-button svg rect {
        fill: white !important;
    }
    .list-cell {
        white-space: nowrap;
        overflow-x: hidden;
        cursor: pointer;
        .list-cell-item {
            overflow-x: hidden;
            text-overflow: ellipsis;
        }
    }
`;

export { StyledPeopleSubsetTab };
