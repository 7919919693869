import styled from 'styled-components';

const StyledHeader = styled.div`
    .header {
        position: fixed;
        top: 0px;
        z-index: 100;
        width: 100%;
        height: 60px;
        background: var(--primaryWhite);
        box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
    }
    .sub-menu {
        display: none;
        position: absolute;
        padding-top: 20px;
        right: -15px;
    }
    .icons {
        display: flex;
        justify-content: space-between;
    }
    .user-profile-icon {
        position: relative;
    }
    .user-profile-icon:hover .sub-menu {
        display: block;
    }
    .header-inner {
        display: flex;
        padding: 0px 90px;
        height: 60px;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .header-navigation {
        display: inline-flex;
    }
    .logo {
        width: 120px;
    }
    .logo h4 {
        font-size: 24px;
        font-weight: bold;
        padding-top: 10px;
        color: var(--secondaryBlue);
    }
    .logo a {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
    }
    .widgets {
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
    }
    .header-buttons .button {
        padding-right: 1rem;
        padding-top: 0.25rem;
        .button-inner {
            padding-bottom: 4px;
            padding-left: 12.5px;
        }
    }
    .wide-avatar-bordered {
        object-fit: cover;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 1px solid var(--secondaryBlue);
        background: var(--secondaryBlue);
        box-sizing: border-box;
    }
    @media screen and (max-width: 1024px) {
        .logo {
            margin: 0;
        }
        .header-inner {
            padding: 0px 80px;
        }
    }
    @media screen and (max-width: 768px) {
        .header-search .customInput {
            width: 300px;
        }
        .logo {
            padding-right: 60px;
            margin: 0;
            width: fit-content;
        }
        .header-inner {
            padding: 0px 24px;
        }
    }
    @media screen and (max-width: 426px) {
        .header-search .customInput {
            width: 150px;
            margin-right: 20px;
        }
    }
`;

export { StyledHeader };
