import { useState, useCallback, useEffect, useMemo, useContext } from 'react';

import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchAllBenefitsByPersonId,
    resetBenefitState
} from 'redux/slices/benefit';
import { formatDate } from 'redux/utils/reduxHelper';
import {
    CustomButton,
    CustomSelect,
    CustomDateSelector,
    TextArea,
    CustomInput,
    Spinner
} from 'shared/components';
import PermissionsContext from 'shared/utils/permissionStore';
import { mapDataForDropdown } from 'utils/dropdownHelper';

import BenefitRequestInfo from './BenefitRequestInfo';
import {
    REQUEST_TYPES_IDS,
    STATUSES_IDS,
    validatePendingForm,
    MAX_TEXTAREA_LENGTH,
    handleOnChangeBenefitStatusId,
    handleOnChangeBenefitRequestTemplateId
} from './utils';
import { updateRequest } from '../../../redux/slices/request';
import StyledModal from '../styled/Modal.styled';

const getOptions = (statuses, permissions) => {
    const filteredOptions = statuses.filter(
        status => status.id !== STATUSES_IDS.DELIVERED
    );

    var _options = filteredOptions.map(option => {
        if (option.id === STATUSES_IDS.APPROVED) {
            return {
                ...option,
                disabled: !permissions.canChangeStatusFromPendingToApproved
            };
        }
        if (option.id === STATUSES_IDS.REJECTED) {
            return {
                ...option,
                disabled: !permissions.canChangeStatusFromPendingToRejected
            };
        }
        return option;
    });

    return mapDataForDropdown(_options);
};

const RequestModal = ({ isVisible, onClose, requestInfo }) => {
    const permissions = useContext(PermissionsContext).permissions;

    const benefitStatuses = useSelector(
        state => state.dropdown.benefitStatuses
    );
    const benefitRequestMessages = useSelector(state =>
        state.request.benefitRequestMessages.filter(brm => brm.active)
    );
    const [isLoading, currentBenefits] = useSelector(state => [
        state.benefits.isLoading,
        state.benefits.currentBenefits
    ]);

    const [isFreeTextInputDisabled, setIsFreeTextInputDisabled] =
        useState(true);

    const [messageOptions, setMessageOptions] = useState([]);
    const dispatch = useDispatch();

    let INITIAL_VALUES = {
        benefitRequestStatusId: STATUSES_IDS.PENDING,
        benefitRequestTemplateMessageId: null,
        benefitRequestMessage: '',
        startDate: requestInfo.startDate,
        endDate: null
    };

    const firstPossibleStartDate = useMemo(() => {
        if (
            !currentBenefits ||
            !currentBenefits.length ||
            requestInfo.coveredBy === 'Personally'
        ) {
            return null;
        }
        const benefitsCoveredByMentorMateWithEndDate = currentBenefits.filter(
            benefit => benefit.endDate && benefit.coveredById === 1
        );

        if (benefitsCoveredByMentorMateWithEndDate.length > 1) {
            const benefitsOrderedByEndDate =
                benefitsCoveredByMentorMateWithEndDate.sort(
                    (a, b) => new Date(a.endDate) - new Date(b.endDate)
                );

            return benefitsOrderedByEndDate[0].endDate;
        }
        return benefitsCoveredByMentorMateWithEndDate[0]?.endDate;
    }, [currentBenefits]);

    useEffect(() => {
        requestInfo?.personId &&
            dispatch(fetchAllBenefitsByPersonId(requestInfo?.personId));
        return () => {
            dispatch(resetBenefitState());
        };
    }, [requestInfo?.personId]);

    if (
        requestInfo.benefitRequestTypeId ===
        REQUEST_TYPES_IDS.CHANGE_OF_COVERAGE
    ) {
        INITIAL_VALUES = {
            ...INITIAL_VALUES,
            currentStartDate: requestInfo.startDate,
            currentEndDate: null,
            startDate: null
        };
    }

    if (requestInfo.showRelativeMemberInformation) {
        INITIAL_VALUES = {
            ...INITIAL_VALUES,
            relativeMemberName: requestInfo.relativeMemberName,
            relativeMemberNameBg: requestInfo.relativeMemberNameBg
        };
    }

    const handleSubmit = values => {
        const requestData = {
            ...values,
            benefitRequestTypeId: requestInfo.benefitRequestTypeId,
            startDate: formatDate(values.startDate),
            endDate: formatDate(values.endDate),
            currentEndDate: formatDate(values.currentEndDate)
        };

        dispatch(updateRequest(requestInfo.requestId, requestData));
        onClose();
    };

    const validate = useCallback(
        values => {
            return validatePendingForm(
                values,
                requestInfo.benefitRequestTypeId,
                requestInfo.showRelativeMemberInformation
            );
        },
        [requestInfo]
    );

    return isLoading ? (
        <Spinner />
    ) : (
        <Formik
            initialValues={INITIAL_VALUES}
            validate={validate}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({ values, errors, setFieldValue, validateForm, setErrors }) => (
                <StyledModal
                    visible={isVisible}
                    setVisible={onClose}
                    closable={true}
                    enableClose={true}
                    footer={
                        <div className="footer">
                            <CustomButton
                                text="Cancel"
                                type="normal footer-button"
                                onClick={onClose}
                            />
                            <CustomButton
                                text="Submit"
                                type="filled footer-button"
                                onClick={async () => {
                                    const errors = await validateForm();
                                    if (!isEmpty(errors)) return;
                                    handleSubmit(values);
                                }}
                                disabled={
                                    values.benefitRequestStatusId ===
                                    STATUSES_IDS.PENDING
                                }
                            />
                        </div>
                    }
                >
                    <BenefitRequestInfo requestInfo={requestInfo} />
                    <Form>
                        {requestInfo.showRelativeMemberInformation && (
                            <div className="row">
                                <div>
                                    <CustomInput
                                        label={`Family Member Name (Latin) ${
                                            values.benefitRequestStatusId !==
                                            STATUSES_IDS.PENDING
                                                ? '*'
                                                : ''
                                        }`}
                                        name="relativeMemberName"
                                        value={values.relativeMemberName}
                                        className={
                                            !!errors.relativeMemberName
                                                ? 'error-input'
                                                : ''
                                        }
                                        isNormalChange={false}
                                        onChange={setFieldValue}
                                        allowLettersOnly
                                        maxLettersLength={200}
                                        disabled
                                    />
                                    {errors.relativeMemberName && (
                                        <div className="error-message">
                                            {errors.relativeMemberName}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <CustomInput
                                        label={`Family Member Name (Cyrillic) ${
                                            values.benefitRequestStatusId !==
                                            STATUSES_IDS.PENDING
                                                ? '*'
                                                : ''
                                        }`}
                                        name="relativeMemberNameBg"
                                        value={values.relativeMemberNameBg}
                                        className={
                                            !!errors.relativeMemberNameBg
                                                ? 'error-input'
                                                : ''
                                        }
                                        isNormalChange={false}
                                        onChange={setFieldValue}
                                        allowLettersOnly
                                        maxLettersLength={200}
                                        disabled
                                    />
                                    {errors.relativeMemberNameBg && (
                                        <div className="error-message">
                                            {errors.relativeMemberNameBg}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div>
                                <CustomSelect
                                    label="Status *"
                                    name="benefitRequestStatusId"
                                    id="benefitRequestStatusId"
                                    options={getOptions(
                                        benefitStatuses,
                                        permissions
                                    )}
                                    className={
                                        !!errors.benefitRequestStatusId &&
                                        'error-input'
                                    }
                                    value={values.benefitRequestStatusId}
                                    onChange={(name, value) => {
                                        handleOnChangeBenefitStatusId(
                                            name,
                                            value,
                                            benefitRequestMessages,
                                            requestInfo,
                                            setErrors,
                                            setFieldValue,
                                            setMessageOptions,
                                            setIsFreeTextInputDisabled
                                        );
                                    }}
                                />
                                {errors.benefitRequestStatusId && (
                                    <div className="error-message">
                                        {errors.benefitRequestStatusId}
                                    </div>
                                )}
                            </div>
                            <div>
                                <CustomSelect
                                    label={`Message ${
                                        values.benefitRequestStatusId ===
                                            STATUSES_IDS.REJECTED &&
                                        requestInfo.benefitRequestTypeId !==
                                            REQUEST_TYPES_IDS.CHANGE_OF_COVERAGE
                                            ? '*'
                                            : ''
                                    }`}
                                    name="benefitRequestTemplateMessageId"
                                    id="benefitRequestTemplateMessageId"
                                    options={messageOptions}
                                    className={
                                        !!errors.benefitRequestTemplateMessageId &&
                                        'error-input'
                                    }
                                    disabled={
                                        values.benefitRequestStatusId !==
                                            STATUSES_IDS.REJECTED ||
                                        requestInfo.benefitRequestTypeId ===
                                            REQUEST_TYPES_IDS.CHANGE_OF_COVERAGE
                                    }
                                    value={
                                        values.benefitRequestTemplateMessageId
                                    }
                                    onChange={(name, value) =>
                                        handleOnChangeBenefitRequestTemplateId(
                                            name,
                                            value,
                                            benefitRequestMessages,
                                            setFieldValue,
                                            setIsFreeTextInputDisabled,
                                            setErrors
                                        )
                                    }
                                />
                                {errors.benefitRequestTemplateMessageId && (
                                    <div className="error-message">
                                        {errors.benefitRequestTemplateMessageId}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <TextArea
                                id="benefitRequestMessage"
                                label={`Message Text${
                                    isFreeTextInputDisabled ? '' : ' *'
                                }`}
                                allowClear={true}
                                onChange={setFieldValue}
                                value={values.benefitRequestMessage}
                                name={'benefitRequestMessage'}
                                disabled={isFreeTextInputDisabled}
                                rows={4}
                                hasError={!!errors.benefitRequestMessage}
                                showCount={!isFreeTextInputDisabled}
                                maxLength={MAX_TEXTAREA_LENGTH}
                            />
                            {errors.benefitRequestMessage && (
                                <div className="error-message">
                                    {errors.benefitRequestMessage}
                                </div>
                            )}
                        </div>
                        {requestInfo.benefitRequestTypeId !==
                            REQUEST_TYPES_IDS.CHANGE_OF_COVERAGE && (
                            <div className="row">
                                <div>
                                    <CustomDateSelector
                                        label={`Start Date ${
                                            [
                                                STATUSES_IDS.PENDING,
                                                STATUSES_IDS.REJECTED
                                            ].includes(
                                                values.benefitRequestStatusId
                                            ) ||
                                            (values.benefitRequestStatusId ===
                                                STATUSES_IDS.APPROVED &&
                                                requestInfo.benefitRequestTypeId !==
                                                    REQUEST_TYPES_IDS.ACTIVATION)
                                                ? ''
                                                : '*'
                                        }`}
                                        name="startDate"
                                        id="startDate"
                                        disabledDate={firstPossibleStartDate}
                                        disabled={
                                            [
                                                STATUSES_IDS.PENDING,
                                                STATUSES_IDS.REJECTED
                                            ].includes(
                                                values.benefitRequestStatusId
                                            ) ||
                                            (values.benefitRequestStatusId ===
                                                STATUSES_IDS.APPROVED &&
                                                requestInfo.benefitRequestTypeId !==
                                                    REQUEST_TYPES_IDS.ACTIVATION)
                                        }
                                        value={values.startDate}
                                        className={
                                            !!errors.startDate && 'error-input'
                                        }
                                        onChange={setFieldValue}
                                    />
                                    {errors.startDate && (
                                        <div className="error-message">
                                            {errors.startDate}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <CustomDateSelector
                                        label={`End Date ${
                                            values.benefitRequestStatusId ===
                                                STATUSES_IDS.APPROVED &&
                                            requestInfo.benefitRequestTypeId !==
                                                REQUEST_TYPES_IDS.ACTIVATION
                                                ? '*'
                                                : ''
                                        }`}
                                        name="endDate"
                                        id="endDate"
                                        className={
                                            !!errors.endDate && 'error-input'
                                        }
                                        disabledDate={
                                            values.startDate
                                                ? values.startDate
                                                : requestInfo.entryDate
                                        }
                                        disabled={[
                                            STATUSES_IDS.PENDING,
                                            STATUSES_IDS.REJECTED
                                        ].includes(
                                            values.benefitRequestStatusId
                                        )}
                                        value={values.endDate}
                                        onChange={setFieldValue}
                                    />
                                    {errors.endDate && (
                                        <div className="error-message">
                                            {errors.endDate}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {requestInfo.benefitRequestTypeId ===
                            REQUEST_TYPES_IDS.CHANGE_OF_COVERAGE && (
                            <>
                                <div className="row">
                                    <div>
                                        <CustomDateSelector
                                            label={`Start Date ${
                                                [
                                                    STATUSES_IDS.PENDING,
                                                    STATUSES_IDS.REJECTED
                                                ].includes(
                                                    values.benefitRequestStatusId
                                                ) ||
                                                (values.benefitRequestStatusId ===
                                                    STATUSES_IDS.APPROVED &&
                                                    requestInfo.benefitRequestTypeId !==
                                                        REQUEST_TYPES_IDS.ACTIVATION)
                                                    ? ''
                                                    : '*'
                                            }`}
                                            name="currentStartDate"
                                            id="currentStartDate"
                                            disabledDate={requestInfo.entryDate}
                                            disabled={
                                                [
                                                    STATUSES_IDS.PENDING,
                                                    STATUSES_IDS.REJECTED
                                                ].includes(
                                                    values.benefitRequestStatusId
                                                ) ||
                                                (values.benefitRequestStatusId ===
                                                    STATUSES_IDS.APPROVED &&
                                                    requestInfo.benefitRequestTypeId !==
                                                        REQUEST_TYPES_IDS.ACTIVATION)
                                            }
                                            value={values.currentStartDate}
                                            className={
                                                !!errors.currentStartDate &&
                                                'error-input'
                                            }
                                            onChange={setFieldValue}
                                        />
                                        {errors.currentStartDate && (
                                            <div className="error-message">
                                                {errors.currentStartDate}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <CustomDateSelector
                                            label={`End Date ${
                                                values.benefitRequestStatusId ===
                                                STATUSES_IDS.APPROVED
                                                    ? '*'
                                                    : ''
                                            }`}
                                            name="currentEndDate"
                                            id="currentEndDate"
                                            className={
                                                !!errors.currentEndDate &&
                                                'error-input'
                                            }
                                            disabledDate={
                                                values.currentStartDate
                                                    ? values.currentStartDate
                                                    : requestInfo.entryDate
                                            }
                                            disabled={[
                                                STATUSES_IDS.PENDING,
                                                STATUSES_IDS.REJECTED
                                            ].includes(
                                                values.benefitRequestStatusId
                                            )}
                                            value={values.currentEndDate}
                                            onChange={setFieldValue}
                                        />
                                        {errors.currentEndDate && (
                                            <div className="error-message">
                                                {errors.currentEndDate}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {values.benefitRequestStatusId ===
                                    STATUSES_IDS.APPROVED && (
                                    <div className="row">
                                        <div>
                                            <CustomDateSelector
                                                label="Start Date for new benefit period *"
                                                name="startDate"
                                                id="startDate"
                                                disabledDate={
                                                    values.currentEndDate
                                                }
                                                value={values.startDate}
                                                className={
                                                    !!errors.startDate &&
                                                    'error-input'
                                                }
                                                onChange={setFieldValue}
                                            />
                                            {errors.startDate && (
                                                <div className="error-message">
                                                    {errors.startDate}
                                                </div>
                                            )}
                                        </div>
                                        <CustomDateSelector
                                            label="End Date for new benefit period"
                                            name="endDate"
                                            id="endDate"
                                            disabledDate={values.startDate}
                                            value={values.endDate}
                                            onChange={setFieldValue}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </Form>
                </StyledModal>
            )}
        </Formik>
    );
};

export default RequestModal;
