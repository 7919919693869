import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { STATIC_ROUTES_PATHS } from '../../routes';
import { Heading } from '../../shared/components/Heading';
import { Main } from '../../shared/components/layout/Main';

const AuthenticatedNotFoundPage = ({ message }) => {
    const pageTitle = 'Humate';

    return (
        <div className="authenticated-not-found-container">
            <Helmet title={pageTitle} />
            <Main hasHeader={false} hasNavigation={false} hasHeading={false}>
                <Heading size={4} text={message} />
                <Link to={STATIC_ROUTES_PATHS.main}>Go back to Home</Link>
            </Main>
        </div>
    );
};

export default AuthenticatedNotFoundPage;
