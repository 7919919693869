import React from 'react';

import { useSelector } from 'react-redux';

import {
    selectPlaceholder,
    setClassName
} from '../../../../utils/componentPropHelpers';
import { getDescription } from '../../../../utils/dropdownHelper';
import {
    getCurrentOptions,
    getHistoricalInputType
} from '../../../utils/inputFieldHelper';
import { CustomAutoComplete } from '../../AutoCompleteInput';
import { CustomDateSelector } from '../../DatePicker';
import { CustomInput } from '../../Input';
import { CustomSelect } from '../../Select';

const GenerateEditField = ({
    changedPropertyName,
    value,
    name,
    setFieldValue = () => {},
    errors = {}
}) => {
    const dropDown = useSelector(state => state.dropdown);
    const inputType = getHistoricalInputType(changedPropertyName);
    const positions = dropDown.positions;
    const options = getCurrentOptions(dropDown, changedPropertyName);
    const valueForPosition =
        positions && (getDescription(parseInt(value), positions) ?? undefined);

    return (
        <>
            {inputType === 'datePicker' && (
                <CustomDateSelector
                    label={changedPropertyName}
                    value={value}
                    name={name}
                    allowClear={false}
                    disabled={true}
                    onChange={setFieldValue}
                    hideSuffix={true}
                />
            )}
            {inputType === 'input' && (
                <CustomInput
                    className={setClassName(errors?.[name])}
                    label={changedPropertyName}
                    value={value}
                    allowClear={true}
                    name={name}
                    placeholder="Type..."
                    isNormalChange={false}
                    disabled={true}
                    onChange={(name, val) => {
                        setFieldValue(name, val);
                    }}
                />
            )}
            {/* Currently not used for any case. */}
            {inputType === 'autoComplete' && (
                <CustomAutoComplete
                    label={changedPropertyName}
                    placeholder="Type to autocomplete..."
                    options={options}
                    value={valueForPosition}
                    name={name}
                    disabled={true}
                    onChange={setFieldValue}
                    // className={setClassName(errors?.[name])}
                />
            )}
            {inputType === 'select' && (
                <CustomSelect
                    value={isNaN(parseInt(value)) ? undefined : parseInt(value)}
                    label={changedPropertyName}
                    name={name}
                    placeholder={selectPlaceholder}
                    options={options}
                    disabled={true}
                    onChange={setFieldValue}
                    hideSuffix={true}
                />
            )}
            {inputType === 'selectSearch' && (
                <CustomSelect
                    label={changedPropertyName}
                    placeholder={selectPlaceholder}
                    options={options}
                    value={isNaN(parseInt(value)) ? undefined : parseInt(value)}
                    name={name}
                    onChange={setFieldValue}
                    disabled={true}
                    hasInput={true}
                    hideSuffix={true}
                />
            )}
        </>
    );
};

export { GenerateEditField };
