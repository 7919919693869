import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_COLUMN_STATE = [
    { value: '-2', label: 'Name', isClosable: false },
    { value: '-1', label: 'Egn', isClosable: false },
    { value: '0', label: 'Price', isClosable: false },
    { value: '1', label: 'Office' }
];

const initialState = {
    benefitTags: [],
    columnTags: DEFAULT_COLUMN_STATE,
    filterTags: [],
    monthPickerStartValue: null,
    monthPickerEndValue: null,
    includeOnlyFinishing: false
};

const { reducer: benefitReportReducer, actions } = createSlice({
    name: 'benefitReport',
    initialState,
    reducers: {
        addBenefitsTag: (state, action) => {
            const { label, value } = action.payload;

            if (!state.benefitTags.find(e => e.value === value)) {
                state.benefitTags = [{ label, value }];
            }
        },
        removeBenefitsTag: (state, action) => {
            state.benefitTags = [
                ...state.benefitTags.filter(e => e.value !== action.payload)
            ];
        },

        addColumnsTag: (state, action) => {
            const { label, value } = action.payload;

            if (!state.columnTags.find(e => e.value === value)) {
                state.columnTags = [...state.columnTags, { label, value }];
            }
        },
        removeColumnsTag: (state, action) => {
            state.columnTags = [
                ...state.columnTags.filter(e => e.value !== action.payload)
            ];
        },

        addFiltersTag: (state, action) => {
            const { label, value } = action.payload;

            if (!state.filterTags.find(e => e.value === value)) {
                state.filterTags = [...state.filterTags, { label, value }];
            }
        },
        removeFiltersTag: (state, action) => {
            state.filterTags = [
                ...state.filterTags.filter(e => e.value !== action.payload)
            ];
        },

        setMonthPickerStartValue: (state, action) => {
            state.monthPickerStartValue = action.payload;
        },

        setMonthPickerEndValue: (state, action) => {
            state.monthPickerEndValue = action.payload;
        },

        setIncludeOnlyFinishing: (state, action) => {
            state.includeOnlyFinishing = action.payload;
        },

        resetState: state => {
            state.benefitTags = [];
            state.columnTags = DEFAULT_COLUMN_STATE;
            state.filterTags = [];
            state.monthPickerStartValue = null;
            state.monthPickerEndValue = null;
            state.includeOnlyFinishing = false;
        }
    }
});

export { benefitReportReducer, actions as benefitReportActions };
