import React, { useContext, useEffect } from 'react';

import { canSeeTabsPeopleDetails } from 'people/utils/peoplePermissionsHelper';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import PermissionsContext from 'shared/utils/permissionStore';

import { StyledEmployeeDetails } from './styled/EmployeeDetails.styled';
import { resetDropdown } from '../../redux/slices/dropdown';
import {
    fetchEmployeeById,
    refetchEmployeeById,
    resetPersonProp
} from '../../redux/slices/people';
import { STATIC_ROUTES_PATHS } from '../../routes';
import { Main } from '../../shared/components/layout/Main';
import { Spinner } from '../../shared/components/Spinner';
import {
    DELETE_PERSON_MESSAGE,
    INVALID_PERSON_MESSAGE
} from '../../shared/constants/generalConstants';
import EmployeeDetailsHeader from '../components/Details/EmployeeDetailsHeader';
import { EmployeeDetailsSide } from '../components/Details/EmployeeDetailsSide';
import EmployeeInfoTabs from '../components/Details/EmployeeInfoTabs';

const EmployeeDetails = props => {
    const permissions = useContext(PermissionsContext).permissions;
    const {
        match: {
            params: { id }
        },
        history
    } = props;

    const {
        person,
        isLoadingFetchPerson,
        errorFetchPerson,
        shouldRefetchPerson
    } = useSelector(state => state.people);
    const pageTitle = person.name ?? document.title;

    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        //fetch data
        dispatch(fetchEmployeeById(id));
    }, [id, dispatch]);

    useEffect(() => {
        if (errorFetchPerson === DELETE_PERSON_MESSAGE) {
            history.push(STATIC_ROUTES_PATHS.peopleDashboard);
        }
        if (errorFetchPerson === INVALID_PERSON_MESSAGE) {
            history.push(STATIC_ROUTES_PATHS.peopleDashboard);
        }
    }, [errorFetchPerson]);

    useEffect(() => {
        return () => {
            dispatch(resetPersonProp());
            dispatch(resetDropdown());
        };
    }, []);

    useEffect(() => {
        if (shouldRefetchPerson) {
            dispatch(refetchEmployeeById(id));
        }
    }, [shouldRefetchPerson]);

    return (
        <StyledEmployeeDetails>
            <Helmet title={pageTitle} />
            {isLoadingFetchPerson ? (
                <Main
                    history={history}
                    hasHeader={true}
                    hasNavigation={true}
                    hasHeading={true}
                    hasFooter={true}
                >
                    <Spinner />
                </Main>
            ) : (
                <Main
                    history={history}
                    hasHeader={true}
                    hasNavigation={true}
                    hasHeading={false}
                    hasCustomHeading={true}
                    hasFooter={true}
                    customHeadingComponent={
                        <EmployeeDetailsHeader
                            person={person}
                            history={history}
                            personId={id}
                            allowDelete={true}
                            permissions={permissions}
                        />
                    }
                >
                    <div className="employee-details-container">
                        <div className="employee-details-sidebar">
                            <EmployeeDetailsSide
                                history={history}
                                permissions={permissions}
                            />
                        </div>
                        <div className="employee-details-tabs">
                            {/* tabs */}
                            {canSeeTabsPeopleDetails(permissions) && (
                                <EmployeeInfoTabs
                                    personId={id}
                                    permissions={permissions}
                                />
                            )}
                        </div>
                    </div>
                </Main>
            )}
        </StyledEmployeeDetails>
    );
};

export default EmployeeDetails;
