import { useEffect, useState } from 'react';

import { RoleModal } from 'admin/components/shared/RoleModal/RoleModal';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteRole,
    fetchSingleRole,
    resetShowDeleteMessage,
    resetShowUpdateMessage,
    updateRole
} from 'redux/slices/role';
import {
    CustomConfirmationModal,
    CustomTableWithFixedColumns
} from 'shared/components';
import {
    errorNotification,
    successNotification
} from 'shared/components/Notifications';

import { getCannotDeleteRoleMessage } from '../../../utils/shared/rolesTableHelper';

export const RolesTable = ({
    totalCount,
    defaultSort,
    handleCreateTableColumns,
    rolesData,
    pageSize,
    loading,
    noDataText,
    hasPagination,
    sorted,
    handleSortUpdate,
    boundaryRef,
    permissions
}) => {
    const dispatch = useDispatch();

    const { showUpdateMessage, showDeleteMessage, editError, deleteError } =
        useSelector(state => state.role);

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteConfirmationModal, setDeleteConfirmationModal] =
        useState(false);

    const [selectedRole, setSelectedRole] = useState(null);

    const handleOpenEdit = role => {
        setSelectedRole(role);
        setEditModalOpen(true);
        dispatch(fetchSingleRole(role.id));
    };

    const handleOpenDelete = role => {
        setDeleteConfirmationModal(true);
        setSelectedRole(role);
    };

    useEffect(() => {
        if (showUpdateMessage) {
            successNotification('Successfully updated a role.');
            dispatch(resetShowUpdateMessage());
        }
    }, [showUpdateMessage]);

    useEffect(() => {
        if (showDeleteMessage) {
            successNotification('Successfully deleted a role.');
            dispatch(resetShowDeleteMessage());
        }
    }, [showDeleteMessage]);

    useEffect(() => {
        if (editError) {
            errorNotification(editError);
        }
    }, [editError]);

    useEffect(() => {
        if (deleteError) {
            errorNotification(deleteError);
        }
    }, [deleteError]);

    const handleEditRole = data => {
        window.scrollTo(0, 0);
        const roleId = selectedRole.id;

        const requestData = {
            permissions: [...data.permissions],
            roleName: data.roleName
        };

        dispatch(updateRole(roleId, requestData));
        setEditModalOpen(false);
    };

    const handleDelete = () => {
        const roleId = selectedRole.id;
        const numberOfAssignedUsers = selectedRole.assignedUsersCount;

        if (numberOfAssignedUsers <= 0) {
            dispatch(deleteRole(roleId));
        } else {
            errorNotification(
                getCannotDeleteRoleMessage(
                    selectedRole.name,
                    numberOfAssignedUsers
                )
            );
        }

        setDeleteConfirmationModal(false);
    };

    const closeModalCallback = () => {
        setSelectedRole(null);
    };

    return (
        <div className="table-wrapper">
            <CustomTableWithFixedColumns
                total={totalCount}
                defaultSorted={defaultSort}
                columns={handleCreateTableColumns(
                    handleOpenEdit,
                    handleOpenDelete,
                    permissions
                )}
                data={rolesData}
                pageSize={pageSize}
                loading={loading}
                noDataText={noDataText}
                hasPagination={hasPagination}
                sorted={sorted}
                handleSortingChange={handleSortUpdate}
                sortable={true}
                resizable={false}
            />
            <section ref={boundaryRef} />
            {editModalOpen && (
                <RoleModal
                    title={'Edit Role'}
                    visible={editModalOpen}
                    isEdit={true}
                    setVisible={setEditModalOpen}
                    handleSubmit={handleEditRole}
                    closeCallback={closeModalCallback}
                />
            )}
            {deleteConfirmationModal && (
                <CustomConfirmationModal
                    title={'Confirmation'}
                    visible={deleteConfirmationModal}
                    setVisible={setDeleteConfirmationModal}
                    contentText={`You will delete role with name "${selectedRole.name}"`}
                    handleConfirm={handleDelete}
                    closeCallback={closeModalCallback}
                />
            )}
        </div>
    );
};
