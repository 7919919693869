import { isEmpty } from 'lodash';
import moment from 'moment';

export const buildPagedQueryData = (subset, requestModel) => {
    const { skip, take, searchPhrase, filterValues, sort } = requestModel;
    let baseParams = transformPagedQueryData(skip, take);

    if (!isEmpty(sort)) {
        baseParams += transformSortQueryData(sort);
    }

    if (subset) {
        baseParams += transformCategoryQueryData(subset);
    }

    // if there are any provided filters that have values, add them to the baseParams variable
    if (!isEmpty(filterValues)) {
        baseParams += transformFilterQueryData(filterValues);
    }

    //if there is given search string
    if (!isEmpty(searchPhrase)) {
        baseParams += transformSearchQueryData(searchPhrase);
    }

    return baseParams;
};

const transformPagedQueryData = (skip, take) => {
    return `Skip=${skip}&Take=${take}`;
};

const transformSortQueryData = sortParams => {
    const { id, desc } = sortParams[0];
    const sortBy =
        id === 'reportsTo'
            ? 'Manager'
            : id.charAt(0).toUpperCase() + id.slice(1);
    const sortAsc =
        (sortBy === 'Name' || sortBy === 'EndDate') && desc === false
            ? true
            : !desc;

    return `&SortBy=${sortBy}&SortAsc=${sortAsc}`;
};

const transformCategoryQueryData = category => {
    return `&EmployeeCategory=${category}`;
};

const transformSearchQueryData = searchedValue => {
    return `&Query=${searchedValue}`;
};

const transformFilterQueryData = filterValues => {
    let filterParams = ``;

    const {
        departmentId,
        levelId,
        locationId,
        mentormaterTypeId,
        positionId,
        managerId,
        gender,
        startDate,
        startDateBtnState,
        endDate,
        endDateBtnState,
        startMonth,
        startYear,
        benefitTypeId,
        benefitTypeName
    } = filterValues;

    if (departmentId !== undefined) {
        filterParams += `&DepartmentId=${departmentId}`;
    }

    if (levelId !== undefined) {
        filterParams += `&LevelId=${levelId}`;
    }

    if (locationId !== undefined) {
        filterParams += `&LocationId=${locationId}`;
    }

    if (mentormaterTypeId !== undefined) {
        filterParams += `&MentormaterTypeId=${mentormaterTypeId}`;
    }

    if (positionId !== undefined) {
        filterParams += `&PositionId=${positionId}`;
    }

    if (managerId !== undefined) {
        filterParams += `&ManagerId=${managerId}`;
    }

    if (gender !== undefined) {
        filterParams += `&Gender=${gender}`;
    }

    if (benefitTypeId !== undefined) {
        filterParams += `&BenefitTypeId=${benefitTypeId}`;
    }

    if (benefitTypeName !== undefined) {
        filterParams += `&BenefitTypeName=${benefitTypeName}`;
    }

    if (startDate !== undefined) {
        const dateParams = !isEmpty(startDateBtnState)
            ? `&StartDate=${moment(startDate).format(
                  'YYYY-MM-DD'
              )}&StartDateFilter=${startDateBtnState}`
            : `&StartDate=${moment(startDate).format('YYYY-MM-DD')}`;

        filterParams += dateParams;
    }

    if (endDate !== undefined) {
        const dateParams = !isEmpty(endDateBtnState)
            ? `&EndDate=${moment(endDate).format(
                  'YYYY-MM-DD'
              )}&EndDateFilter=${endDateBtnState}`
            : `&EndDate=${moment(endDate).format('YYYY-MM-DD')}`;

        filterParams += dateParams;
    }

    if (startMonth !== undefined) {
        filterParams += `&StartMonthInMM=${startMonth}`;
    }

    if (startYear !== undefined) {
        filterParams += `&StartYearInMM=${moment(startYear).format(
            'YYYY-MM-DD'
        )}`;
    }

    return filterParams;
};
