import { isEmpty } from 'lodash';

const mapDataForDropdown = data => {
    if (!data) {
        return [];
    }
    const transformedData = data.map(item => ({
        label: item.description,
        value: item.id,
        disabled: item.disabled,
        active: item.active
    }));
    return transformedData;
};

const mapDataForAutoComplete = data => {
    const transformedData = data.map(item => ({
        value: item.description
    }));

    return transformedData;
};

const mapDataForUploadDocument = (
    currentBenefits,
    pastBenefits,
    benefitTypes,
    relativeMembers,
    name
) => {
    const benefitTypeOptions = mapDataForDropdown(benefitTypes);
    const relativeMemberOptions = mapDataForDropdown(relativeMembers);

    const currentBenefitsData = currentBenefits
        ?.filter(x => x.benefitCoverPeriodId > 0)
        .map(item => {
            return {
                label: benefitTypeOptions
                    .find(x => x.value === item.benefitTypeId)
                    .label.concat(
                        getRelativeMember(
                            item.personRelativeMemberId,
                            relativeMemberOptions,
                            name
                        )
                    ),
                value: item.benefitCoverPeriodId
            };
        });

    const transformedData = currentBenefitsData?.concat(
        pastBenefits?.map(item => ({
            label: item.benefitTypeName.concat(
                getRelativeMember(
                    item.personRelativeMemberId,
                    relativeMemberOptions,
                    name
                )
            ),
            value: item.benefitCoverPeriodId
        }))
    );
    return transformedData;
};

const getRelativeMember = (id, data, name) => {
    return data.find(x => x.value === id)?.label === undefined
        ? ` - ${name}`
        : ` - ${data.find(x => x.value === id)?.label}`;
};

const getDescription = (id, data) => {
    if (id === null || !data?.length) {
        return null;
    }
    return data.find(x => x.id === id)?.description;
};

const mapNationalitiesForDropdown = data => {
    if (!data) {
        return [];
    }

    const transformedData = data.map(item => {
        if (!isEmpty(item)) {
            return {
                label: item,
                value: item
            };
        }
    });

    return transformedData.filter(x => x !== undefined);
};

const getCoveredByOptions = name => {
    return [
        { label: 'MentorMate', value: 1 },
        { label: name, value: 2 }
    ];
};

const getRelativeMemberOptions = (name, id, data) => {
    const transformedData = data?.map(item => ({
        label: item.description,
        value: item.id
    }));

    transformedData.unshift({ label: name, value: id * -1 });
    return transformedData;
};

const enumToDropdownOptions = data => {
    if (!data) {
        return [];
    }

    let transformedData = [];
    for (const key in data) {
        const value = data[key];
        transformedData.push({ label: key, value });
    }

    return transformedData;
};

const mapDataForDropdownWithActive = data => {
    if (!data) {
        return [];
    }
    const transformedData = data.map(item => {
        const inActiveClassName = !item.active && 'in-active';

        return {
            label: item.description,
            value: item.id,
            isActive: inActiveClassName
        };
    });
    return transformedData;
};

export {
    mapDataForDropdown,
    mapDataForAutoComplete,
    mapDataForUploadDocument,
    getDescription,
    mapNationalitiesForDropdown,
    getCoveredByOptions,
    getRelativeMemberOptions,
    enumToDropdownOptions,
    mapDataForDropdownWithActive
};
