import React from 'react';

import { DepartmentSubSection } from './DepartmentSubSection';
import { classNamesDictionary } from '../../../utils/Main/departmentCounterHelper';

const DepartmentMainSection = ({
    allData,
    history,
    defaultFilters,
    canClickSubSection
}) => {
    return (
        <>
            {allData
                .filter(x => x.size > 0)
                .map((item, index) => {
                    return (
                        <DepartmentSubSection
                            key={`department${index}`}
                            elementId={classNamesDictionary[item.name]}
                            itemProps={item}
                            history={history}
                            defaultFilters={defaultFilters}
                            isClickable={canClickSubSection}
                        />
                    );
                })}
        </>
    );
};

export { DepartmentMainSection };
