import React, { useContext, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import PermissionsContext from 'shared/utils/permissionStore';

import { StyledAllTabs } from '../../../people/components/Dashboard/styled/AllTabs.styled';
import TabsWrapper from '../../../shared/components/DashboardTabs/TabsWrapper';
import {
    shouldBenefitsPageTabBeDisabled,
    setDefaultTabBenefitsPage
} from '../../utils/benefitsPermissionsHelper';
import { createPeopleBenefitsTableColumns } from '../../utils/Dashboard/peopleBenefitsColumns';
import BenefitsSubsetTab from '../Dashboard/BenefitsSubsetTab';

const AllTabs = ({
    allPeopleCount,
    newPeopleCount,
    onLeavePeopleCount,
    leaversPeopleCount,
    history,
    updateCount
}) => {
    const permissions = useContext(PermissionsContext).permissions;

    const [activeTabIndex, setActiveTabIndex] = useState(null);
    const [redirectFilterState, setRedirectFilterState] = useState({});

    const {
        location: { state }
    } = history;

    useEffect(() => {
        if (!isEmpty(state)) {
            const { subsetTab, filters } = state;
            setActiveTabIndex(
                setDefaultTabBenefitsPage(subsetTab, permissions)
            );
            setRedirectFilterState(filters);
            window.scrollTo(0, 0);
        } else {
            setActiveTabIndex(setDefaultTabBenefitsPage(0, permissions));
        }
        return () => {
            setActiveTabIndex(null);
            setRedirectFilterState({});
            window.history.replaceState({}, document.title);
        };
    }, []);

    const TABS = [
        {
            label: 'All',
            value: 1,
            totalCount: allPeopleCount,
            disabled: shouldBenefitsPageTabBeDisabled,
            handleCreateColumns: createPeopleBenefitsTableColumns,
            defaultSort: [{ id: 'name', desc: false }]
        },
        {
            label: 'New',
            value: 3,
            totalCount: newPeopleCount,
            disabled: shouldBenefitsPageTabBeDisabled,
            handleCreateColumns: createPeopleBenefitsTableColumns,
            defaultSort: [{ id: 'startDate', desc: false }]
        },
        {
            label: 'On Leave',
            value: 6,
            totalCount: onLeavePeopleCount,
            disabled: shouldBenefitsPageTabBeDisabled,
            handleCreateColumns: createPeopleBenefitsTableColumns,
            defaultSort: [{ id: 'startDate', desc: false }]
        },
        {
            label: 'Leavers',
            value: 4,
            totalCount: leaversPeopleCount,
            disabled: shouldBenefitsPageTabBeDisabled,
            handleCreateColumns: createPeopleBenefitsTableColumns,
            defaultSort: [{ id: 'endDate', desc: false }]
        }
    ];

    const tabElements = TABS.map((element, i) => {
        return (
            <div
                key={i}
                label={`${element.label} (${element.totalCount})`}
                disabled={element.disabled(element.label, permissions)}
            >
                <BenefitsSubsetTab
                    history={history}
                    subset={element}
                    handleCreateColumns={element.handleCreateColumns}
                    tabType={element.label}
                    defaultSort={element.defaultSort}
                    redirectFilterState={redirectFilterState}
                    updateCount={updateCount}
                />
            </div>
        );
    });

    const handleTabChange = tab => {
        setRedirectFilterState({});
    };

    return (
        <StyledAllTabs>
            <div>
                {activeTabIndex != null ? (
                    <TabsWrapper
                        activeItemIndex={activeTabIndex}
                        handleTabChange={handleTabChange}
                    >
                        {tabElements}
                    </TabsWrapper>
                ) : (
                    <>
                        <div className="tab-error">
                            An error occurred and data could not be loaded.
                        </div>
                    </>
                )}
            </div>
        </StyledAllTabs>
    );
};

export default AllTabs;
