export const setClassName = val => {
    if (val) {
        return 'error-input';
    }
};

export const setAllowClear = val => {
    return val !== null && val !== undefined;
};

export const selectPlaceholder = (
    <div className="placeholder" key="select-placeholder">
        Click to select...
    </div>
);
