import styled from 'styled-components';

const FilterContainer = styled.div`
    position: relative;

    .filterButton {
        width: 88px;
        height: 34px;
        cursor: pointer;
        padding: 6px 10px 6px 16px;
        border: 1px solid #03a8f5;
        border-radius: 10px;
        display: flex;
        align-items: center;
        font-family: 'Source-Sans-Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-align: center;

        svg {
            margin: 0px 0px 2px 8px;
        }

        &.open {
            background-color: var(--mainBlue);
            color: var(--primaryWhite);
        }

        &.closed {
            background-color: var(--primaryWhite);
            color: var(--mainBlue);
        }
    }
`;

export default FilterContainer;
