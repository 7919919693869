import { useMemo } from 'react';

import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
    CustomButton,
    CustomSelect,
    CustomDateSelector,
    TextArea,
    CustomInput
} from 'shared/components';
import { mapDataForDropdown } from 'utils/dropdownHelper';

import BenefitRequestInfo from './BenefitRequestInfo';
import {
    STATUSES_IDS,
    mapDataForMessagesDropdown,
    FREE_TEXT_MESSAGE_IDS,
    validateDeliveredForm,
    MAX_TEXTAREA_LENGTH
} from './utils';
import { updateRequest } from '../../../redux/slices/request';
import StyledModal from '../styled/Modal.styled';

const RequestModal = ({ isVisible, onClose, requestInfo }) => {
    const benefitStatuses = useSelector(
        state => state.dropdown.benefitStatuses
    );
    const benefitRequestMessages = useSelector(
        state => state.request.benefitRequestMessages
    );
    const messageOptions = useMemo(
        () =>
            mapDataForMessagesDropdown(
                benefitRequestMessages,
                STATUSES_IDS.DELIVERED
            ),
        [benefitRequestMessages]
    );

    let INITIAL_VALUES = {
        benefitRequestStatusId: STATUSES_IDS.DELIVERED,
        benefitRequestTemplateMessageId:
            requestInfo.latestSentMessage.benefitRequestTemplateMessageId,
        benefitRequestMessage:
            requestInfo.latestSentMessage.message ??
            benefitRequestMessages.find(
                ({ benefitRequestTemplateMessageId }) =>
                    benefitRequestTemplateMessageId ===
                    requestInfo.latestSentMessage
                        .benefitRequestTemplateMessageId
            ).message,
        startDate: requestInfo.startDate,
        endDate: requestInfo.endDate
    };

    if (requestInfo.showRelativeMemberInformation) {
        INITIAL_VALUES = {
            ...INITIAL_VALUES,
            relativeMemberName: requestInfo.relativeMemberName,
            relativeMemberNameBg: requestInfo.relativeMemberNameBg
        };
    }

    const dispatch = useDispatch();

    const handleSubmit = values => {
        dispatch(
            updateRequest(requestInfo.requestId, {
                benefitRequestTypeId: requestInfo.benefitRequestTypeId,
                benefitRequestStatusId: values.benefitRequestStatusId,
                benefitRequestTemplateMessageId:
                    values.benefitRequestTemplateMessageId,
                benefitRequestMessage: values.benefitRequestMessage?.trim()
            })
        );
        onClose();
    };

    return (
        <Formik
            initialValues={INITIAL_VALUES}
            enableReinitialize
            validate={validateDeliveredForm}
            validateOnBlur={false}
        >
            {({ values, errors, validateForm, setFieldValue, setErrors }) => (
                <StyledModal
                    visible={isVisible}
                    setVisible={onClose}
                    closable={true}
                    enableClose={true}
                    footer={
                        <div className="footer">
                            <CustomButton
                                text="Cancel"
                                type="normal footer-button"
                                onClick={onClose}
                            />
                            <CustomButton
                                text="Submit"
                                type="filled footer-button"
                                onClick={async () => {
                                    const errors = await validateForm();
                                    if (!isEmpty(errors)) return;
                                    handleSubmit(values);
                                }}
                                disabled={!isEmpty(errors)}
                            />
                        </div>
                    }
                >
                    <BenefitRequestInfo requestInfo={requestInfo} />
                    <Form>
                        {requestInfo.showRelativeMemberInformation && (
                            <div className="row">
                                <CustomInput
                                    label="Family Member Name (Latin)"
                                    value={values.relativeMemberName}
                                    name="relativeMemberName"
                                    id="relativeMemberName"
                                    disabled
                                />
                                <CustomInput
                                    label="Family Member Name (Cyrillic)"
                                    value={values.relativeMemberNameBg}
                                    name="relativeMemberNameBg"
                                    id="relativeMemberNameBg"
                                    disabled
                                />
                            </div>
                        )}
                        <div className="row">
                            <div>
                                <CustomSelect
                                    label="Status"
                                    name="benefitRequestStatusId"
                                    id="benefitRequestStatusId"
                                    className={
                                        !!errors.benefitRequestStatusId &&
                                        'error-input'
                                    }
                                    value={values.benefitRequestStatusId}
                                    options={mapDataForDropdown(
                                        benefitStatuses
                                    )}
                                    disabled
                                />
                                {errors.benefitRequestStatusId && (
                                    <div className="error-message">
                                        {errors.benefitRequestStatusId}
                                    </div>
                                )}
                            </div>
                            <div>
                                <CustomSelect
                                    label="Message *"
                                    name="benefitRequestTemplateMessageId"
                                    id="benefitRequestTemplateMessageId"
                                    className={
                                        !!errors.benefitRequestTemplateMessageId &&
                                        'error-input'
                                    }
                                    value={
                                        values.benefitRequestTemplateMessageId
                                    }
                                    options={messageOptions}
                                    onChange={(name, value) => {
                                        setErrors({});
                                        setFieldValue(name, value);
                                        const messageObj =
                                            benefitRequestMessages.find(
                                                ({
                                                    benefitRequestTemplateMessageId
                                                }) =>
                                                    benefitRequestTemplateMessageId ===
                                                    value
                                            );
                                        setFieldValue(
                                            'benefitRequestMessage',
                                            messageObj.message ?? ''
                                        );
                                    }}
                                />
                                {errors.benefitRequestTemplateMessageId && (
                                    <div className="error-message">
                                        {errors.benefitRequestTemplateMessageId}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <TextArea
                                id="benefitRequestMessage"
                                label={`Message Text ${
                                    FREE_TEXT_MESSAGE_IDS.includes(
                                        values.benefitRequestTemplateMessageId
                                    )
                                        ? '*'
                                        : ''
                                }`}
                                allowClear={true}
                                onChange={setFieldValue}
                                value={values.benefitRequestMessage}
                                name={'benefitRequestMessage'}
                                disabled={
                                    !FREE_TEXT_MESSAGE_IDS.includes(
                                        values.benefitRequestTemplateMessageId
                                    )
                                }
                                rows={4}
                                maxLength={MAX_TEXTAREA_LENGTH}
                                showCount={
                                    !FREE_TEXT_MESSAGE_IDS.includes(
                                        values.benefitRequestTemplateMessageId
                                    )
                                }
                                hasError={!!errors.benefitRequestMessage}
                            />
                            {errors.benefitRequestMessage && (
                                <div className="error-message">
                                    {errors.benefitRequestMessage}
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div>
                                <CustomDateSelector
                                    label="Start Date"
                                    name="startDate"
                                    id="startDate"
                                    className={
                                        !!errors.startDate && 'error-input'
                                    }
                                    value={values.startDate}
                                    disabledDate={requestInfo.entryDate}
                                    disabled
                                />
                                {errors.startDate && (
                                    <div className="error-message">
                                        {errors.startDate}
                                    </div>
                                )}
                            </div>
                            <CustomDateSelector
                                label="End Date"
                                name="endDate"
                                id="endDate"
                                value={values.endDate}
                                disabledDate={
                                    values.startDate
                                        ? values.startDate
                                        : requestInfo.entryDate
                                }
                                disabled
                            />
                        </div>
                    </Form>
                </StyledModal>
            )}
        </Formik>
    );
};

export default RequestModal;
