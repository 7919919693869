import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { setClassName } from '../../../../utils/componentPropHelpers';
import { CustomButton } from '../../Button';
import { CustomConfirmationModal } from '../../ConfirmationModal';
import { CustomDateSelector } from '../../DatePicker';

const EditTimeLineWrapper = ({
    changedPropertyName,
    disabled,
    values,
    setFieldValue = () => {},
    errors = {},
    handleSubmit = () => {},
    handleChangeView,
    isSpecial = false,
    periodStartDate,
    periodEndDate,
    children
}) => {
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const { isLoading } = useSelector(state => state.history);

    const handleConfirmation = () => {
        setOpenConfirmation(!openConfirmation);
    };

    const addDays = (date, days) => {
        return new Date(date).setDate(new Date(date).getDate() - days);
    };
    return (
        <>
            <div className="vertical-timeline-element-title">
                {changedPropertyName} - Edit
            </div>
            <div className="vertical-timeline-edit-section">
                {children}
                {!isSpecial && (
                    <CustomDateSelector
                        label={'Date'}
                        value={values.takesEffectOn}
                        name="takesEffectOn"
                        onChange={setFieldValue}
                        allowClear={false}
                        className={setClassName(errors?.takesEffectOn)}
                        disabledDate={addDays(periodStartDate, 1)}
                        disabledFutureDate={periodEndDate}
                    />
                )}
            </div>
            <div className="vertical-timeline-button-section">
                <CustomButton
                    text="Cancel"
                    type={`normal footer-button ${isSpecial ? 'btn-full' : ''}`}
                    onClick={handleChangeView}
                />
                {!isSpecial && (
                    <CustomButton
                        text="Save"
                        type="filled footer-button"
                        disabled={disabled || isLoading}
                        onClick={handleConfirmation}
                    />
                )}
            </div>
            {openConfirmation && (
                <CustomConfirmationModal
                    visible={openConfirmation}
                    setVisible={setOpenConfirmation}
                    title={'Confirmation'}
                    contentText={`You are going to edit this record.`}
                    handleConfirm={() => {
                        handleSubmit(values);
                        setOpenConfirmation(false);
                    }}
                />
            )}
        </>
    );
};

export { EditTimeLineWrapper };
