import styled from 'styled-components';

const StyledNormalView = styled.div`
    .working-period {
        margin-top: 7px;
        font-size: 14px;
        line-height: 15px;
        color: gray;
    }
    strong {
        font-weight: 600;
    }
    .action-buttons {
        display: flex;
        justify-content: space-between;
        .vertical-timeline-element-title {
            margin-bottom: 6px;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: var(--blackShadow);
        }
        .item-select {
            padding-right: 4px;
            margin-top: -1px;
        }
        .options-section {
            cursor: pointer;
            margin-top: -9px;
            margin-bottom: 9px;
            margin-right: 5px;
        }
        .left-box {
            position: absolute;
            top: 21.5px;
            left: 215px;
            .options {
                min-width: 70px;
                padding: 10px;
                background: #fff;
                box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                font-size: 14px;
                text-align: left;
            }
        }
    }

    .section-splitter {
        border-top: 1px solid var(--secondaryBlue);
        margin-top: 2px;
        margin-bottom: 10px;
    }

    .vertical-timeline-element-subtitle {
        font-size: 14px;
        line-height: 16px;
        color: var(--darkNavy);
    }

    .on-leave {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    @media only screen and (min-width: 768px) {
        .action-buttons {
            .left-box {
                position: absolute;
                left: 170px !important;
            }
        }
    }
    @media only screen and (min-width: 425px) and (max-width: 767px) {
        .action-buttons {
            .left-box {
                position: absolute;
                top: 35px;
                left: 120px !important;
            }
        }
    }
`;

export { StyledNormalView };
