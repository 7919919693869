import moment from 'moment';

import { bulgarianEgnRegex } from '../../../../shared/constants/regexConstants';

export const checkAndUpdateByPersonalID = (
    inputName,
    value,
    requestObj,
    setFieldValue
) => {
    if (inputName === 'personalId') {
        const egnRegex = new RegExp(bulgarianEgnRegex);
        if (egnRegex.test(value)) {
            const birthday = calculateYear(value);
            if (birthday.isValid()) {
                //set field value for birthday
                setFieldValue('birthday', birthday);

                return {
                    ...requestObj,
                    birthday: birthday
                };
            }
        }
    }

    return requestObj;
};

const calculateYear = value => {
    let year = value.substring(0, 2);
    let month = value.substring(2, 4);
    const day = value.substring(4, 6);

    const checkMonthTwenty = month - 20;
    const checkMonthForty = month - 40;
    if (checkMonthTwenty > 0 && checkMonthTwenty <= 12) {
        year = '18' + year.toString();
        month = month - 20;
    } else if (checkMonthForty > 0 && checkMonthForty <= 12) {
        year = '20' + year.toString();
        month = month - 40;
    } else if (month > 0 && month <= 12) {
        year = '19' + year.toString();
    }

    return moment(`${year}-${month}-${day}`);
};
