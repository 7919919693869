import moment from 'moment';
import { getFormData } from 'utils/getFormData';

const MAX_LENGTH_NAME = 30;
const MAX_LENGTH_SHORT_DESCRIPTION = 1000;
const MIN_LENGTH_SHORT_DESCRIPTION = 3;
const MAX_LENGTH_IMAGE_ALT_TEXT = 120;
const EMPY_RICH_TEXT_FIELD_VALUE = '<p><br></p>';

const validateCategory = value => {
    let errors = { discountCategory: undefined };

    if (!value?.label) {
        const numbersOnly = /[0-9]/;

        if (numbersOnly.test(value)) {
            errors.discountCategory =
                'The category name can contain only letters.';
        }

        if (!value?.trim()) {
            errors.discountCategory = 'Category name required.';
        }
    }
    return errors;
};

export const MAX_LENGTH_RICH_TEXT = 1000;

export const handleDiscountCategoryKeyDown = (
    keyboardEvent,
    setFieldValue,
    discountSearchedCategory,
    discountCategoryOptions
) => {
    const optionExists = discountCategoryOptions
        .map(d => d.label)
        .includes(discountSearchedCategory);

    if (keyboardEvent.key === 'Enter' && !optionExists) {
        discountCategoryOptions.unshift({
            label: discountSearchedCategory,
            value: undefined
        });
        setFieldValue('discountCategory', discountSearchedCategory);
    }
};

export const handleDiscountCategoryOnChange = (
    name,
    val,
    categories,
    setFieldValue
) => {
    const newCategory = categories.find(x => x.value === val);

    delete newCategory.disabled;
    delete newCategory.active;

    setFieldValue(name, newCategory);
};

export const handleDiscountCategoryOnSearch = (
    value,
    setDiscountCategorySearchValue,
    setDiscountSearchedCategory
) => {
    const discountCategoryErrors = validateCategory(value);

    if (
        discountCategoryErrors.discountCategory ===
        'The category name can contain only letters.'
    ) {
        return;
    }

    if (discountCategoryErrors.discountCategory === 'Category name required.') {
        setDiscountCategorySearchValue(''); //necessary for fully clearing the value with backspace
        return;
    }

    setDiscountCategorySearchValue(value?.label ?? value);
    setDiscountSearchedCategory({
        label: value?.label ?? value,
        value: value?.value ?? undefined
    });
};

export const handleDiscountCategoryOnInputKeyDown = (
    keyboardEvent,
    setFieldValue,
    discountSearchedCategory,
    setDiscountSearchedCategory,
    discountCategoryOptions,
    setDiscountCategoryOptions
) => {
    const existingOption = discountCategoryOptions.find(
        x => discountSearchedCategory.label === x.label
    );

    if (keyboardEvent.key === 'Enter' && discountSearchedCategory.label) {
        if (existingOption) {
            setFieldValue('discountCategory', existingOption);
        } else {
            setDiscountCategoryOptions(existing => [
                discountSearchedCategory,
                ...existing
            ]);
            setFieldValue('discountCategory', discountSearchedCategory);
        }
        setDiscountSearchedCategory('');
    }
};

export const handleDiscountLocationOnChange = async (
    name,
    values,
    setFieldValue,
    setFieldTouched
) => {
    const augmentedValues = values
        .map(value => {
            return {
                name: value.label,
                isNew: value.__isNew__ ?? false,
                id: value.__isNew__ ? null : value.value
            };
        })
        .sort((a, b) => a.id - b.id);

    await setFieldValue(name, augmentedValues);
    await setFieldTouched(name);
};

export const handleOnImageUpload = (file, setFieldValue, setErrors) => {
    setFieldValue('discountImage', file);
    setErrors(prev => {
        return {
            ...prev,
            discountImageError: undefined
        };
    });
};

export const handleOnActiveStatusChange = (isActive, setFieldValue) => {
    setFieldValue('discountIsActive', isActive);

    if (!isActive) {
        setFieldValue('discountVisible', false);
    }
};

export const getFileNameWithDate = file => {
    const nameParts = file.name.split('.');
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();

    const formattedDate = `[${month}/${day}/${year}]`;
    const newName = `${nameParts[0]} ${formattedDate}.${nameParts[1]}`;
    return { ...file, ...{ name: newName } };
};

export const getSelectedLocationOptions = discountLocations =>
    discountLocations.map(x => {
        return { value: x.id, label: x.name };
    });

export const handleSubmit = (
    editModalDiscountId,
    requestData,
    dispatch,
    createDiscount,
    updateDiscount,
    setIsDiscountModalOpen
) => {
    const isEditMode = typeof editModalDiscountId !== 'undefined';
    const mappedData = {};
    mappedData.name = requestData.discountName;
    mappedData.shortDescription = requestData.discountShortDescription;
    mappedData.categoryName = requestData.discountCategory.label;
    mappedData.categoryId = requestData.discountCategory.value;
    mappedData.minimum = requestData.discountMinimum;
    mappedData.website = requestData.discountWebsite;
    mappedData.howToUse = requestData.discountHowToUse.html;
    mappedData.howMuch = requestData.discountHowMuch.html;
    mappedData.active = requestData.discountIsActive;
    mappedData.visible = requestData.discountVisible;
    mappedData.image =
        typeof requestData.discountImage === 'string'
            ? null
            : requestData.discountImage;
    mappedData.imageAltText = requestData.discountImageAltText;

    requestData.discountLocations.forEach(dl => {
        mappedData.locations = [
            ...(mappedData?.locations ?? []),
            {
                name: dl.name,
                isNew: dl?.isNew ?? false,
                id: dl?.id ?? null
            }
        ];
    });

    if (requestData.discountMaximum) {
        mappedData.maximum = requestData.discountMaximum;
    }

    if (requestData.discountCategory.value) {
        mappedData.categoryId = requestData.discountCategory.value;
    }

    if (
        requestData.discountFilesSetForDeletion.filter(fileId =>
            Number.isInteger(fileId)
        ).length > 0 &&
        isEditMode
    ) {
        mappedData.filesSetForDeletion =
            requestData.discountFilesSetForDeletion;
    }

    const formData = getFormData(mappedData, '', null);

    requestData.discountFiles
        .filter(file => typeof file !== 'string')
        .forEach(file => {
            formData.append('files', file);
        });

    isEditMode
        ? dispatch(updateDiscount(editModalDiscountId, formData))
        : dispatch(createDiscount(formData));

    setIsDiscountModalOpen(false);
};

export const setEditModalState = (
    discountData,
    setSelectedDiscountLocations,
    setDiscountCategorySearchValue,
    setDiscountSearchedCategory
) => {
    setSelectedDiscountLocations(
        getSelectedLocationOptions(discountData.discountLocations)
    );

    setDiscountCategorySearchValue(discountData.discountCategoryName);
    setDiscountSearchedCategory({
        label: discountData.discountCategoryName,
        value: discountData.discountCategoryId
    });
};

class DiscountValidator {
    constructor(values) {
        this.errors = {};
        this.values = values;
    }

    validateName = nameMaxLength => {
        if (!this.values.discountName?.trim()) {
            this.errors.discountName = 'Discount name is required.';
        } else if (this.values.discountName.trim().length > nameMaxLength) {
            this.errors.discountName = `Discount name must be between 1 and ${nameMaxLength} characters long.`;
        }

        return this;
    };

    validateShortDescription = (maxLength, minLength) => {
        const shortDescription = this.values.discountShortDescription?.trim();

        if (!shortDescription) {
            this.errors.discountShortDescription =
                'Discount short description is required.';
        } else if (shortDescription.length > maxLength) {
            this.errors.discountShortDescription = `Discount short Description should not exceed ${maxLength} characters.`;
        } else if (shortDescription.length < minLength) {
            this.errors.discountShortDescription = `Discount short Description should be more than ${minLength} characters.`;
        }

        return this;
    };

    validatePrice = () => {
        const discountMinimum = this.values.discountMinimum?.toString()?.trim();
        const discountMaximum = this.values.discountMaximum?.toString()?.trim();

        if (!discountMinimum) {
            this.errors.discountMinimum = 'Discount minimum required.';
        } else if (
            discountMaximum &&
            Number(discountMinimum) >= Number(discountMaximum)
        ) {
            this.errors.discountMinimum =
                'Discount minimum has to be smaller than the discount maximum';
            this.errors.discountMaximum =
                'Discount maximum has to be larger than the discount minimum';
        }

        return this;
    };

    validateHowMuch = (maxLength, emptyRichTextFieldValue) => {
        const howMuchPlainText = this.values.discountHowMuch.plainText?.trim();
        const howMuchHtml = this.values.discountHowMuch.html?.trim();

        if (!howMuchHtml || howMuchHtml === emptyRichTextFieldValue) {
            this.errors.discountHowMuch = 'Discount how much required.';
        } else if (howMuchPlainText && howMuchPlainText?.length > maxLength) {
            this.errors.discountHowMuch = `Discount how much must be up to ${maxLength} long.`;
        }

        return this;
    };

    validateHowToUse = (maxLength, emptyRichTextFieldValue) => {
        const howToUsePlainText =
            this.values.discountHowToUse.plainText?.trim();
        const howToUseHtml = this.values.discountHowToUse.html?.trim();

        if (!howToUseHtml || howToUseHtml === emptyRichTextFieldValue) {
            this.errors.discountHowToUse = 'Discount how to use required.';
        } else if (howToUseHtml && howToUsePlainText.length > maxLength) {
            this.errors.discountHowToUse = `Discount how to use must be up to ${maxLength} long.`;
        }

        return this;
    };

    validateImageAltText = maxLength => {
        const discountImageAltText = this.values.discountImageAltText?.trim();

        if (!discountImageAltText) {
            this.errors.discountImageAltText =
                'Discount image alt text is required.';
        } else if (discountImageAltText.length > maxLength) {
            this.errors.discountImageAltText = `Discount image alt text should not exceed ${maxLength} characters.`;
        }

        return this;
    };

    validateLocations = () => {
        if (!this.values.discountLocations?.length) {
            this.errors.discountLocations = 'Discount location is required.';
        }

        return this;
    };

    validateCategory = () => {
        if (!this.values.discountCategory) {
            this.errors.discountCategory = 'Discount category is required.';
        }

        return this;
    };
}

export const validateDiscount = values => {
    const validator = new DiscountValidator(values);

    validator
        .validateName(MAX_LENGTH_NAME)
        .validateShortDescription(
            MAX_LENGTH_SHORT_DESCRIPTION,
            MIN_LENGTH_SHORT_DESCRIPTION
        )
        .validatePrice()
        .validateHowMuch(MAX_LENGTH_RICH_TEXT, EMPY_RICH_TEXT_FIELD_VALUE)
        .validateHowToUse(MAX_LENGTH_RICH_TEXT, EMPY_RICH_TEXT_FIELD_VALUE)
        .validateImageAltText(MAX_LENGTH_IMAGE_ALT_TEXT)
        .validateLocations()
        .validateCategory();

    return validator.errors;
};
