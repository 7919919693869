import styled from 'styled-components';

const StyledUploadImage = styled.div.attrs(() => ({
    className: 'upload-image'
}))`
    img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        z-index: 0;
        object-fit: contain;
    }
    .upload-button {
        border: 1px solid var(--mainBlue);
        border-radius: 6px;
    }
    .upload-button {
        width: 100%;
        display: flex;
        gap: 5px;
        justify-content: center;
        background: var(--primaryWhite);
        box-sizing: border-box;
        color: var(--mainBlue);
        font-family: 'Source-Sans-Pro';
        font-style: normal;
        font-weight: bold;
        line-height: 16px;
        text-align: center;
        white-space: nowrap;
        padding: 8px 0;
        font-size: 16px;
        &:hover,
        &:focus {
            color: var(--secondaryBlue);
        }
        > * {
            align-self: center;
        }

        &.external-error {
            border-color: red;
        }
    }
    .image-group {
        height: 100%;
        position: relative;
    }
    .button-group {
        position: absolute;
        z-index: 1;
        color: var(--secondaryBlue);
        display: flex;
        padding: 32px 32px 0 0;
        gap: 24px;
        justify-content: end;
        width: 100%;
        .delete-button,
        .edit-button {
            background: var(--primaryWhite);
            padding: 4px;
            border-radius: 5px;
            font-size: 20px;
            display: inline-block;
            display: flex;
            align-items: center;
        }
    }
    .error-message {
        text-align: center;
        color: var(--red);
    }
`;
export default StyledUploadImage;
