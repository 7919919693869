/* istanbul ignore file */
import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

import StyledSelectedFilterTags from '../styled/SelectedFilterTags.styled';

const SelectedFilterTags = ({ filters, onTagRemove, onFiltersReset }) => {
    const elements = Object.keys(filters)
        .filter(filterName => filters[filterName].value)
        .map(selection => (
            <Tag
                className="tag"
                key={selection}
                selection={selection}
                closable={true}
                onClose={() => onTagRemove(selection)}
            >
                {filters[selection]?.label}
            </Tag>
        ));

    return (
        <>
            {elements && elements.length > 0 && (
                <StyledSelectedFilterTags>
                    <div className="tags">{elements}</div>
                    <button
                        type="button"
                        className="clear-all"
                        onClick={onFiltersReset}
                    >
                        Clear All{' '}
                        <div className="close-icon">
                            <CloseOutlined />
                        </div>
                    </button>
                </StyledSelectedFilterTags>
            )}
        </>
    );
};

export default SelectedFilterTags;
