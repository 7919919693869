import { createSlice } from '@reduxjs/toolkit';
import { errorNotification, successNotification } from 'shared/components';

import requestService from '../../requests/services/requestService';

const initialState = {
    requests: {
        pending: [],
        approved: [],
        delivered: [],
        rejected: [],
        cancelled: []
    },
    isLoadingRequests: false,
    requestsError: null,
    shouldRefetchRequests: false,

    filteredCounts: {
        pending: 0,
        approved: 0,
        rejected: 0,
        delivered: 0,
        cancelled: 0
    },

    totalCounts: {
        pending: 0,
        approved: 0,
        rejected: 0,
        delivered: 0,
        cancelled: 0
    },
    isLoadingTotalCounts: true,
    totalCountsError: null,

    benefitRequestMessages: [],
    isLoadingBenefitRequestMessages: false,
    benefitRequestMessagesError: null
};

const { reducer: requestReducer, actions: requestActions } = createSlice({
    name: 'request',
    initialState,
    reducers: {
        //Fetch Requests
        fetchRequestsStart: state => {
            state.isLoadingRequests = true;
        },
        fetchRequestsSuccess: (state, action) => {
            state.filteredCounts[action.payload.requestType] =
                action.payload.totalCount;
            state.totalCounts[action.payload.requestType] =
                action.payload.totalCount;
            state.requests[action.payload.requestType] = action.payload.data;
            state.isLoadingRequests = false;
            state.requestsError = null;
            state.shouldRefetchRequests = false;
        },
        updateRequestsListSuccess: (state, action) => {
            state.filteredCounts[action.payload.requestType] =
                action.payload.totalCount;
            state.requests[action.payload.requestType] = [
                ...state.requests[action.payload.requestType],
                ...action.payload.data
            ];
            state.isLoadingRequests = false;
            state.requestsError = null;
        },
        fetchRequestsFailure: (state, action) => {
            state.requests = initialState.requests;
            state.isLoadingRequests = false;
            state.requestsError = action.payload;
            state.shouldRefetchRequests = false;
        },

        //Filter Requests
        filterRequestsStart: state => {
            state.isLoadingRequests = true;
        },
        filterRequestsSuccess: (state, action) => {
            state.filteredCounts[action.payload.requestType] =
                action.payload.totalCount;
            state.requests[action.payload.requestType] = action.payload.data;
            state.isLoadingRequests = false;
            state.requestsError = null;
            state.shouldRefetchRequests = false;
        },
        filterRequestsFailure: (state, action) => {
            state.requests = initialState.requests;
            state.isLoadingRequests = false;
            state.requestsError = action.payload;
            state.shouldRefetchRequests = false;
        },

        //Fetch Counts
        fetchCountsStart: state => {
            state.isLoadingTotalCounts = true;
        },
        fetchCountsSuccess: (state, action) => {
            state.totalCounts = {
                pending: action.payload.pendingCount,
                approved: action.payload.approvedCount,
                rejected: action.payload.rejectedCount,
                delivered: action.payload.deliveredCount,
                cancelled: action.payload.cancelledCount
            };
            state.isLoadingTotalCounts = false;
            state.totalCountsError = null;
        },
        fetchCountsFailure: (state, action) => {
            state.requests = initialState.requests;
            state.totalCounts = initialState.totalCounts;
            state.isLoadingTotalCounts = false;
            state.totalCountsError = action.payload;
        },

        //Update Benefits
        updateRequestStart: state => {
            state.isLoadingRequests = true;
        },
        updateRequestSuccess: (state, action) => {
            state.totalCounts = {
                pending: action.payload.pendingCount,
                approved: action.payload.approvedCount,
                rejected: action.payload.rejectedCount,
                delivered: action.payload.deliveredCount,
                cancelled: action.payload.cancelledCount
            };
            state.isLoadingRequests = false;
            state.shouldRefetchRequests = true;
            state.requestsError = null;
        },
        updateRequestFailure: (state, action) => {
            state.isLoadingRequests = false;
            state.requestsError = action.payload;
        },

        //Fetch Benefit Request Messages
        fetchBenefitRequestsMessagesStart: state => {
            state.isLoadingBenefitRequestMessages = true;
        },
        fetchBenefitRequestsMessagesSuccess: (state, action) => {
            state.benefitRequestMessages = action.payload;
            state.isLoadingBenefitRequestMessages = false;
            state.benefitRequestMessagesError = null;
        },
        fetchBenefitRequestsMessagesFailure: (state, action) => {
            state.isLoadingRequests = false;
            state.benefitRequestMessagesError = action.payload;
        }
    }
});

const fetchRequests = (requestType, params) => {
    return async dispatch => {
        try {
            dispatch(requestActions.fetchRequestsStart());

            const {
                data,
                metadata: { totalCount }
            } = await requestService.getRequests(params);

            dispatch(
                requestActions.fetchRequestsSuccess({
                    requestType,
                    data,
                    totalCount
                })
            );
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(requestActions.updateRequestFailure(errorMessage));
        }
    };
};

const filterRequests = (requestType, params) => {
    return async dispatch => {
        try {
            dispatch(requestActions.filterRequestsStart());

            const {
                data,
                metadata: { totalCount }
            } = await requestService.getRequests(params);

            dispatch(
                requestActions.filterRequestsSuccess({
                    requestType,
                    data,
                    totalCount
                })
            );
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(requestActions.filterRequestsFailure(errorMessage));
        }
    };
};

const updateRequestsList = (requestType, params) => {
    return async dispatch => {
        try {
            dispatch(requestActions.fetchRequestsStart());

            const {
                data,
                metadata: { totalCount }
            } = await requestService.getRequests(params);

            dispatch(
                requestActions.updateRequestsListSuccess({
                    requestType,
                    data,
                    totalCount
                })
            );
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(requestActions.updateRequestFailure(errorMessage));
        }
    };
};

const fetchRequestCounts = () => {
    return async dispatch => {
        try {
            dispatch(requestActions.fetchCountsStart());

            const { data } = await requestService.getRequestCounts();

            dispatch(requestActions.fetchCountsSuccess(data));
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(requestActions.updateRequestFailure(errorMessage));
        }
    };
};

const updateRequest = (benefitRequestId, requestData) => {
    return async dispatch => {
        try {
            dispatch(requestActions.updateRequestStart());
            await requestService.patchRequest(benefitRequestId, requestData);
            const { data } = await requestService.getRequestCounts();
            dispatch(requestActions.updateRequestSuccess(data));
            successNotification('Successfully updated the benefit request');
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(requestActions.updateRequestFailure(errorMessage));
        }
    };
};

const fetchBenefitRequestsMessages = () => {
    return async dispatch => {
        try {
            dispatch(requestActions.fetchBenefitRequestsMessagesStart());

            const { data } = await requestService.getBenefitRequestMessages();

            dispatch(requestActions.fetchBenefitRequestsMessagesSuccess(data));
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(
                requestActions.fetchBenefitRequestsMessagesFailure(errorMessage)
            );
        }
    };
};

export {
    requestReducer,
    fetchRequests,
    fetchRequestCounts,
    updateRequestsList,
    updateRequest,
    fetchBenefitRequestsMessages,
    filterRequests,
    requestActions
};
