import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const getAllDiscounts = async requestModel => {
    const { skip, take, sortBy, sortAsc, searchPhrase, loadActive } =
        requestModel;
    const res = await httpService.get(url.GET_ALL_DISCOUNTS_PAGINATED(), {
        params: {
            skip,
            take,
            sortBy,
            sortAsc,
            query: searchPhrase,
            active: loadActive
        }
    });

    return res.data;
};

const getDiscountById = async discountId => {
    const res = await httpService.get(url.GET_DISCOUNT_BY_ID(discountId));

    return res.data;
};

const addDiscount = async requestData => {
    const data = await httpService.post(url.CREATE_DISCOUNT(), requestData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return data.data;
};

const updateDiscount = async (discountId, requestData) => {
    const data = await httpService.patch(
        url.UPDATE_DISCOUNT(discountId),
        requestData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
    return data.data;
};

const updateDiscountVisibility = async discountId => {
    const data = await httpService.patch(
        url.UPDATE_DISCOUNT_VISIBILITY(discountId)
    );
    return data.data;
};

const deleteDiscount = async discountId => {
    const data = await httpService.delete(url.DELETE_DISCOUNT(discountId));
    return data.data;
};

const getCategories = async () => {
    const data = await httpService.get(url.GET_DISCOUNT_CATEGORIES());
    return data.data;
};

export default {
    getAllDiscounts,
    getCategories,
    getDiscountById,
    addDiscount,
    updateDiscount,
    deleteDiscount,
    updateDiscountVisibility
};
