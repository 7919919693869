export const benefitColumnNames = {
    Office: '1',
    Email: '2'
};

export const benefitFilters = {
    ['Covered By']: '1',
    ['Relative']: '2',
    ['All Together']: '3'
};
