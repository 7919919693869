import React, { useEffect, useState, useContext } from 'react';

import { isEmpty } from 'lodash';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { createFakeBenefit } from 'redux/slices/benefit';
import PermissionsContext from 'shared/utils/permissionStore';

import { Benefit } from './Benefit';
import CurrentBenefit from './CurrentBenefit';
import { StyledPeriods } from './styled/Periods.styled';
import { resetBenefitLoadingStack } from '../../../../redux/slices/benefit';
import { errorNotification } from '../../../../shared/components/Notifications';
import { statusElementOpen } from '../../../utils/employeeDetailsHelper';
import { DetailsInnerContainer } from '../../shared/DetailsInnerContainer';

const periodType = {
    CURRENT: 'Current',
    PAST: 'Past'
};

export const Periods = ({ personId }) => {
    const [isCurrentOpen, setIsCurrentOpen] = useState(true);
    const [isPastOpen, setIsPastOpen] = useState(false);
    const [showInfoText, setShowInfoText] = useState(false);
    const [callCounter, setCallCounter] = useState(0);
    const [isSaved, setIsSaved] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const dispatch = useDispatch();
    const [currentBenefits, pastBenefits, isLoadingStack, error] = useSelector(
        state => [
            state.benefits.currentBenefits,
            state.benefits.pastBenefits,
            state.benefits.isLoadingStack,
            state.benefits.error
        ]
    );
    const { permissions } = useContext(PermissionsContext);

    useEffect(() => {
        if (!isEmpty(isLoadingStack)) {
            const loadingValues = Object.values(isLoadingStack);
            const tempt = [];
            loadingValues.map(x => tempt.push(...Object.values(x)));
            tempt.some(v => v === true) ? setIsSaved(false) : setIsSaved(true);
        }
    }, [isLoadingStack]);

    useEffect(() => {
        if (error) {
            errorNotification(error, 5);
            dispatch(resetBenefitLoadingStack());
        }
    }, [error]);

    const handleSection = type => {
        if (type === periodType.CURRENT) {
            setIsCurrentOpen(!isCurrentOpen);
            setShowInfoText(false);
            setCallCounter(0);
        } else {
            setIsPastOpen(!isPastOpen);
            setShowInfoText(false);
        }
    };

    const [disabledDate, setDisabledDate] = useState(null);

    const endingBenefitsCoveredByMentorMate = b =>
        b.coveredById === 1 && b.endDate !== null;
    const currentActiveBenefitsCoveredByMentorMate = b =>
        b.coveredById === 1 && b.endDate === null;

    useEffect(() => {
        if (currentBenefits.length > 0) {
            setIsDisabled(
                currentBenefits.filter(currentActiveBenefitsCoveredByMentorMate)
                    .length === 2
            );

            let endingBenefits = currentBenefits.filter(
                endingBenefitsCoveredByMentorMate
            );
            if (
                endingBenefits.length >= 1 &&
                currentBenefits.filter(currentActiveBenefitsCoveredByMentorMate)
                    .length == 1
            ) {
                let sortedEndingBenefits = endingBenefits.sort(
                    (a, b) => b.endDate - a.endDate
                );
                let latestEndingBenefitEndDate =
                    sortedEndingBenefits[0].endDate;
                setDisabledDate(latestEndingBenefitEndDate);
            } else if (endingBenefits.length === 2) {
                endingBenefits.sort((a, b) =>
                    moment(a.endDate).diff(moment(b.endDate))
                );

                let futureBenefitCreated = currentBenefits.filter(
                    b =>
                        b.startDate > endingBenefits[0].endDate &&
                        b.endDate === null
                );

                if (futureBenefitCreated.length > 0) {
                    setDisabledDate(endingBenefits[1].endDate);
                } else {
                    setDisabledDate(endingBenefits[0].endDate);
                }
            }
        }
    }, [currentBenefits]);

    const onNewBenefitAdded = () => {
        dispatch(createFakeBenefit());
    };

    return (
        <StyledPeriods>
            <DetailsInnerContainer
                bodyTitle={periodType.CURRENT}
                isOpen={isCurrentOpen}
                statusText={showInfoText && statusElementOpen(isSaved)}
                handleSection={() => handleSection(periodType.CURRENT)}
            >
                <div className="section-splitter" />
                {currentBenefits.map((benefit, index) => (
                    <CurrentBenefit
                        key={benefit.benefitCoverPeriodId}
                        benefit={benefit}
                        benefitIndex={index}
                        personId={personId}
                        setShowInfoText={setShowInfoText}
                        callCounter={callCounter}
                        setCallCounter={setCallCounter}
                        isPast={false}
                        disableCoveredByMentorMateOption={isDisabled}
                        disabledDate={
                            benefit.benefitCoverPeriodId < 0 && disabledDate
                        }
                    />
                ))}
                {currentBenefits.length <= 0 && (
                    <div className="not-found">No current benefits found.</div>
                )}
                {permissions.canAddBenefitToEmployee && (
                    <div
                        data-testid="add-benefit-section"
                        className="add-benefit-section"
                        onClick={() => onNewBenefitAdded()}
                    >
                        + Add new benefit
                    </div>
                )}
            </DetailsInnerContainer>
            <DetailsInnerContainer
                bodyTitle={periodType.PAST}
                isOpen={isPastOpen}
                handleSection={() => handleSection(periodType.PAST)}
            >
                {pastBenefits.map((benefit, index) => (
                    <Benefit
                        key={index}
                        benefit={benefit}
                        benefitIndex={currentBenefits.length + index}
                        personId={personId}
                        setShowInfoText={setShowInfoText}
                        callCounter={callCounter}
                        setCallCounter={setCallCounter}
                        isPast={true}
                    />
                ))}
                {pastBenefits.length <= 0 && (
                    <>
                        <div className="section-splitter" />
                        <div className="not-found">No past benefits found.</div>
                    </>
                )}
            </DetailsInnerContainer>
        </StyledPeriods>
    );
};
