import { isEmpty } from 'lodash';
import moment from 'moment';

export const prepareBenefitTypeUpdate = (
    requestData,
    benefitTypeInitialValues
) => {
    let convertedBenefitPrices = [];
    let benefitPrices = requestData.benefitPrices;
    for (const benefitPrice of benefitPrices) {
        let temptData = { ...benefitPrice };

        // handles deleted values before submitting data to BE
        if (!benefitPrice.active) {
            // Newly added benefit prices that has no id from BE
            if (benefitPrice.id <= 0) {
                continue;
            } else {
                // Find old value of item and set it correctly for BE call
                const currentElementState =
                    benefitTypeInitialValues.benefitPrices.find(
                        x => x.id === benefitPrice.id
                    );
                temptData = { ...currentElementState, active: false };
            }
        }

        // Set value for newly created benefit price for BE
        if (benefitPrice.id <= 0) {
            temptData.id = null;
        }

        // validate dates
        temptData.startDate = isEmpty(temptData.startDate)
            ? null
            : moment(temptData.startDate).format('YYYY-MM-DD');
        temptData.endDate = isEmpty(temptData.endDate)
            ? null
            : moment(temptData.endDate).format('YYYY-MM-DD');

        convertedBenefitPrices.push(temptData);
    }

    return { ...requestData, benefitPrices: [...convertedBenefitPrices] };
};
