import { createSlice } from '@reduxjs/toolkit';
import benefitTypeService from 'admin/services/benefitTypeService';
import { errorNotification } from 'shared/components';

const initialState = {
    take: 10,
    sortBy: 'Name',
    sortAsc: true,
    searchPhrase: '',
    loadActive: true,

    currentBenefits: [],
    currentTotal: 0,
    currentBenefitsTotalCount: 0,

    isLoading: false,
    error: null,

    isLoadingCreate: false,
    showCreateMessage: false,

    isLoadingDelete: false,
    showDeleteMessage: false,

    isLoadingFetchSingle: false,
    benefitTypeSingleData: null,
    errorFetchSingle: null,

    isLoadingEdit: false,
    showUpdateMessage: false
};

const { reducer: benefitTypeReducer, actions } = createSlice({
    name: 'benefitType',
    initialState,
    reducers: {
        // Fetch active benefit types
        changeLoadActive: (state, action) => {
            state.loadActive = action.payload;
        },
        updateSearchPhrase: (state, action) => {
            state.searchPhrase = action.payload;
        },
        fetchBenefitsStart: state => {
            state.isLoading = true;
        },
        fetchBenefitsSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.isLoading = false;
            state.error = null;
            state.currentBenefits = [...state.currentBenefits, ...data];
            state.currentTotal = state.currentTotal + data.length;
            state.currentBenefitsTotalCount = totalCount;
        },
        fetchBenefitsFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        changeOrderState: (state, action) => {
            const {
                payload: { sortBy, sortAsc }
            } = action;
            state.sortBy = sortBy;
            state.sortAsc = sortAsc;
        },

        // Fetch single benefit type by id
        fetchSingleBenefitTypeStart: state => {
            state.isLoadingFetchSingle = true;
        },
        fetchSingleBenefitTypeSuccess: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.errorFetchSingle = null;
            state.benefitTypeSingleData = action.payload;
        },
        fetchSingleBenefitTypeFailure: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.errorFetchSingle = action.payload;
        },

        // create benefit type with benefit price
        createBenefitTypeStart: state => {
            state.isLoading = true;
            state.isLoadingCreate = true;
        },
        createBenefitTypeSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.isLoading = false;
            state.isLoadingCreate = false;
            state.showCreateMessage = true;
            state.error = null;

            state.currentBenefits = [...data];
            state.currentTotal = data.length;
            state.currentBenefitsTotalCount = totalCount;
        },
        createBenefitTypeFailure: (state, action) => {
            state.isLoading = false;
            state.isLoadingCreate = false;
            state.error = action.payload;
        },

        // update benefit type
        updateBenefitTypeStart: state => {
            state.isLoading = true;
            state.editError = null;
            state.isLoadingEdit = true;
        },
        updateBenefitTypeSuccess: (state, action) => {
            state.isLoadingEdit = false;
            state.isLoading = false;
            state.showUpdateMessage = true;
            const { data, totalCount } = action.payload;

            state.currentBenefits = [...data];
            state.currentTotal = data.length;
            state.currentBenefitsTotalCount = totalCount;
        },
        updateBenefitTypeFailure: state => {
            state.isLoadingEdit = false;
            state.isLoading = false;
        },

        // delete benefit type
        deleteBenefitTypeStart: state => {
            state.isLoading = true;
            state.isLoadingDelete = true;
        },
        deleteBenefitTypeSuccess: (state, action) => {
            const { benefitTypeId } = action.payload;
            state.isLoading = false;
            state.isLoadingDelete = false;
            state.showDeleteMessage = true;
            state.error = null;

            state.currentBenefits = [
                ...state.currentBenefits.filter(
                    x => x.benefitTypeId !== benefitTypeId
                )
            ];
            state.currentTotal = state.currentTotal - 1;
            state.currentBenefitsTotalCount =
                state.currentBenefitsTotalCount - 1;
        },
        deleteBenefitTypeFailure: (state, action) => {
            state.isLoading = false;
            state.isLoadingDelete = false;
            state.error = action.payload;
        },

        // clear state
        cleanState: state => {
            state.take = 10;
            state.currentBenefits = [];
            state.currentTotal = 0;
            state.currentBenefitsTotalCount = 0;
            state.isLoading = false;
            state.error = null;
        },

        // reset filters
        resetFilters: state => {
            state.take = 10;
            state.sortBy = 'Name';
            state.sortAsc = true;
            state.searchPhrase = '';
            state.loadActive = true;
            state.currentBenefits = [];
            state.currentTotal = 0;
            state.currentBenefitsTotalCount = 0;
            state.isLoading = false;
            state.error = null;
        },

        resetShowCreate: state => {
            state.showCreateMessage = false;
        },

        resetShowUpdate: state => {
            state.showUpdateMessage = false;
        },

        resetShowDelete: state => {
            state.showDeleteMessage = false;
        },

        // reset edit flexible benefit type modal state
        resetBenefitTypeModal: state => {
            state.isLoadingFetchSingle = false;
            state.benefitTypeSingleData = null;
            state.errorFetchSingle = null;
        },

        setLoadStateToActive: state => {
            state.active = true;
        }
    }
});

const fetchBenefits = isScrolled => {
    return async (dispatch, getState) => {
        if (getState().benefitType.isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchBenefitsStart());
            let skip = getState().benefitType.currentTotal;
            let take = getState().benefitType.take;

            if (!isScrolled) {
                skip = 0;
                take = 10;
                dispatch(actions.cleanState());
            }

            const { sortAsc, sortBy, searchPhrase, loadActive } =
                getState().benefitType;
            const resp = await benefitTypeService.getAllBenefitTypes({
                skip,
                take,
                sortBy,
                sortAsc,
                searchPhrase,
                loadActive
            });
            const {
                data,
                metadata: { totalCount }
            } = resp;
            dispatch(actions.fetchBenefitsSuccess({ data, totalCount }));
        } catch (err) {
            dispatch(actions.fetchBenefitsFailure(err));
        }
    };
};

const fetchSingleBenefitType = benefitTypeId => {
    return async (dispatch, getState) => {
        if (getState().benefitType.isLoadingFetchSingle) {
            return;
        }

        try {
            dispatch(actions.fetchSingleBenefitTypeStart());
            const data = await benefitTypeService.getBenefitTypeById(
                benefitTypeId
            );
            const formattedBenefitPrices = data.data.benefitPrices.map(bp => {
                return { ...bp, active: true };
            });
            const formattedData = {
                ...data.data,
                benefitPrices: [...formattedBenefitPrices]
            };
            dispatch(actions.fetchSingleBenefitTypeSuccess(formattedData));
        } catch (err) {
            dispatch(
                actions.fetchSingleBenefitTypeFailure('Something went wrong.')
            );
        }
    };
};

const createBenefitType = requestData => {
    return async (dispatch, getState) => {
        if (getState().benefitType.isLoadingCreate) {
            return;
        }

        try {
            dispatch(actions.createBenefitTypeStart());
            await benefitTypeService.addBenefitType(requestData);
            const skip = 0;
            const { take, loadActive } = getState().benefitType;

            const resp = await benefitTypeService.getAllBenefitTypes({
                skip,
                take,
                loadActive
            });
            const {
                data,
                metadata: { totalCount }
            } = resp;
            dispatch(actions.createBenefitTypeSuccess({ data, totalCount }));
        } catch (error) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.createBenefitTypeFailure(errorMessage));
        }
    };
};

const updateBenefitType = (benefitTypeId, requestData) => {
    return async (dispatch, getState) => {
        if (getState().benefitType.isLoadingEdit) {
            return;
        }

        try {
            dispatch(actions.updateBenefitTypeStart());
            await benefitTypeService.updateBenefitType(
                benefitTypeId,
                requestData
            );

            const { sortAsc, sortBy, skip, take, searchPhrase, loadActive } =
                getState().benefitType;
            const resp = await benefitTypeService.getAllBenefitTypes({
                skip,
                take,
                sortBy,
                sortAsc,
                searchPhrase,
                loadActive
            });
            const {
                data,
                metadata: { totalCount }
            } = resp;
            dispatch(actions.updateBenefitTypeSuccess({ data, totalCount }));
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.updateBenefitTypeFailure(errorMessage));
        }
    };
};

const updateBenefitTypeVisibility = benefitTypeId => {
    return async (dispatch, getState) => {
        if (getState().benefitType.isLoadingEdit) {
            return;
        }

        try {
            dispatch(actions.updateBenefitTypeStart());
            await benefitTypeService.updateBenefitTypeVisibility(benefitTypeId);
            const skip = 0;
            const { take, loadActive } = getState().benefitType;
            const resp = await benefitTypeService.getAllBenefitTypes({
                skip,
                take,
                loadActive
            });
            const {
                data,
                metadata: { totalCount }
            } = resp;
            dispatch(actions.updateBenefitTypeSuccess({ data, totalCount }));
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.updateBenefitTypeFailure(errorMessage));
        }
    };
};

const changeOrder = orderValue => {
    const { id, desc } = orderValue[0];
    const sortBy = id.charAt(0).toUpperCase() + id.slice(1);
    const sortAsc = sortBy === 'Name' && desc === false ? true : !desc;
    return async dispatch => {
        dispatch(actions.changeOrderState({ sortBy, sortAsc }));
    };
};

const removeBenefitType = (benefitTypeId, isActive) => {
    return async (dispatch, getState) => {
        if (getState().benefitType.isLoadingDelete) {
            return;
        }
        try {
            dispatch(actions.deleteBenefitTypeStart());
            await benefitTypeService.deleteBenefitType(benefitTypeId);
            dispatch(
                actions.deleteBenefitTypeSuccess({ benefitTypeId, isActive })
            );
        } catch (error) {
            dispatch(actions.deleteBenefitTypeFailure('Something went wrong'));
        }
    };
};

const resetBenefitTypeState = () => {
    return dispatch => {
        dispatch(actions.cleanState());
    };
};

const resetFilters = () => {
    return dispatch => {
        dispatch(actions.resetFilters());
    };
};

const resetShowCreateMessage = () => {
    return dispatch => {
        dispatch(actions.resetShowCreate());
    };
};

const resetShowUpdateMessage = () => {
    return dispatch => {
        dispatch(actions.resetShowUpdate());
    };
};

const resetShowDeleteMessage = () => {
    return dispatch => {
        dispatch(actions.resetShowDelete());
    };
};

const resetBenefitType = () => {
    return dispatch => {
        dispatch(actions.resetBenefitTypeModal());
    };
};

const changeActiveState = loadActive => {
    return async dispatch => dispatch(actions.changeLoadActive(loadActive));
};

const updateSearchPhrase = searchPhrase => {
    return async dispatch => dispatch(actions.updateSearchPhrase(searchPhrase));
};

export {
    benefitTypeReducer,
    actions as benefitTypeActions,
    updateSearchPhrase,
    fetchBenefits,
    fetchSingleBenefitType,
    createBenefitType,
    updateBenefitType,
    changeOrder,
    resetBenefitTypeState,
    resetFilters,
    removeBenefitType,
    resetShowCreateMessage,
    resetShowUpdateMessage,
    resetShowDeleteMessage,
    resetBenefitType,
    updateBenefitTypeVisibility,
    changeActiveState
};
