import StyledModal from 'requests/components/styled/Modal.styled';
import styled from 'styled-components';

const StyledUploadModal = styled(StyledModal)`
    width: ${({ width }) => width || 'inherit'};

    .modal-content > span > div {
        width: 100%;
    }
`;

export default StyledUploadModal;
