import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';

const FORMAT = 'MM/DD/YYYY';

export const createColumns = (data, tabName, permissions) => {
    return [
        {
            Header: 'Employee Name',
            id: 'employeeName',
            accessor: 'employeeName',
            Cell: ({ original }) => (
                <div className="table-name-section">
                    <div className="name-info bold-name">
                        {original.employeeName} <br></br>(
                        {original.employeeNameBg})
                    </div>
                </div>
            ),

            width:
                data && data.length > 0
                    ? getColumnWidth(data, 'employeeName', 'Employee Name')
                    : 220,
            resizable: false,
            sortable: false,
            fixed: 'left',
            getProps: (state, _rowInfo, column) => ({
                style: getBackgroundColor(state, column)
            })
        },
        {
            Header: 'Personal ID (ЕГН)',
            id: 'personalId',
            accessor: 'personalId',
            Cell: ({ original }) => {
                const len = !isEmpty(original.personalId)
                    ? original.personalId.length
                    : 0;
                if (len >= 6)
                    return (
                        <div className="tooltip-span-text">
                            <Tooltip
                                title={original.personalId}
                                placement="bottom"
                            >
                                <span>{original.personalId}</span>
                            </Tooltip>
                        </div>
                    );
                else if (len < 6) return <div>{original.personalId}</div>;
            },
            resizable: true,
            sortable: false,
            width: 160,
            getProps: (state, _rowInfo, column) => ({
                style: getBackgroundColor(state, column)
            })
        },
        {
            Header: 'Location',
            id: 'location',
            accessor: 'location',
            Cell: ({ original }) => {
                const len = !isEmpty(original.location)
                    ? original.location.length
                    : 0;
                if (len >= 6)
                    return (
                        <div className="tooltip-span-text">
                            <Tooltip
                                title={original.location}
                                placement="bottom"
                            >
                                <span>{original.location}</span>
                            </Tooltip>
                        </div>
                    );
                else if (len < 6)
                    return (
                        <div>
                            <span>{original.location}</span>
                        </div>
                    );
            },
            resizable: true,
            sortable: false,
            width: 140,
            getProps: (state, _rowInfo, column) => ({
                style: getBackgroundColor(state, column)
            })
        },
        {
            Header: 'Working Time',
            id: 'workingTime',
            accessor: 'workingTime',
            Cell: ({ original }) => (
                <div>
                    <span>{original.workingTime}</span>
                </div>
            ),
            resizable: true,
            sortable: false,
            width: 130,
            getProps: (state, _rowInfo, column) => ({
                style: getBackgroundColor(state, column)
            })
        },
        {
            Header: 'Benefit',
            id: 'benefit',
            accessor: 'benefit',
            Cell: ({ original }) => (
                <div className="tooltip-span-text">
                    <Tooltip title={original.benefit} placement="bottom">
                        <span>{original.benefit}</span>
                    </Tooltip>
                </div>
            ),
            resizable: true,
            sortable: false,
            width: 'auto',
            getProps: (state, _rowInfo, column) => ({
                style: getBackgroundColor(state, column)
            })
        },
        {
            Header: 'Benefit Amount',
            id: 'benefitAmount',
            accessor: 'benefitAmount',
            Cell: ({ original }) => (
                <div>
                    <span>{original.benefitAmount}</span>
                </div>
            ),
            resizable: true,
            sortable: false,
            width: 140,
            getProps: (state, _rowInfo, column) => ({
                style: getBackgroundColor(state, column)
            })
        },
        {
            Header: 'Type of Request',
            id: 'benefitRequestType',
            accessor: 'benefitRequestType',
            Cell: ({ original }) => (
                <div>
                    <span>{original.benefitRequestType}</span>
                </div>
            ),
            resizable: true,
            sortable: false,
            width: 160,
            getProps: (state, _rowInfo, column) => ({
                style: getBackgroundColor(state, column)
            })
        },
        {
            Header: 'Covered By',
            id: 'coveredBy',
            accessor: 'coveredBy',
            Cell: ({ original }) => (
                <div>
                    <span>{original.coveredBy}</span>
                </div>
            ),
            resizable: true,
            sortable: false,
            width: 120,
            getProps: (state, _rowInfo, column) => ({
                style: getBackgroundColor(state, column)
            })
        },
        {
            Header: 'Entry Date',
            id: 'entryDate',
            accessor: 'entryDate',
            Cell: ({ original }) => (
                <div>
                    <span>{moment(original.entryDate).format(FORMAT)}</span>
                </div>
            ),
            resizable: true,
            sortable: true,
            width: 120,
            getProps: (state, _rowInfo, column) => ({
                style: getBackgroundColor(state, column)
            })
        },
        ...(tabName === 'cancelled'
            ? [
                  {
                      Header: 'Cancelled Date',
                      id: 'cancelledDate',
                      accessor: 'cancelledDate',
                      Cell: ({ original }) => (
                          <div>
                              <span>
                                  {moment(original.updatedOnUtc).format(FORMAT)}
                              </span>
                          </div>
                      ),
                      resizable: true,
                      sortable: false,
                      width: 130,
                      getProps: (state, _rowInfo, column) => ({
                          style: getBackgroundColor(state, column)
                      })
                  }
              ]
            : [])
    ];
};

const getColumnWidth = (data, propertyName, headerText) => {
    const maxWidth = 500;
    const magicSpacing = 10;

    let maxNameLength;
    const longestName = Math.max(
        ...data.map(el =>
            !isEmpty(el[propertyName]) ? el[propertyName].length : 0
        )
    );
    const longestBgName = Math.max(
        ...data.map(el =>
            !isEmpty(el[propertyName]) ? el[propertyName].length : 0
        )
    );

    if (longestName > longestBgName) {
        maxNameLength = longestName;
    } else if (longestName < longestBgName) {
        maxNameLength = longestBgName;
    } else {
        maxNameLength = longestName;
    }

    const cellLength = Math.max(maxNameLength, headerText.length);
    const finalLength = Math.min(maxWidth, cellLength * magicSpacing) + 50;
    if (finalLength <= 220) {
        return 220;
    }
    return finalLength;
};

const getBackgroundColor = (state, column) => {
    return { backgroundColor: state?.sorted[0]?.id === column.id && '#f9fbfc' };
};
