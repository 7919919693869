import styled from 'styled-components';

const StyledDetailsInnerContainer = styled.div`
    .info-body {
        margin-top: 14px;
        padding: 20px 20px 20px;
        border: 1px solid var(--gray);
        border-radius: 10px;
        .section-splitter {
            margin: 20px 0px;
        }
        .info-body-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .info-body-title {
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                letter-spacing: 0em;
                text-align: left;
                margin-right: 18px;
            }
            .info-body-status {
                display: flex;
                align-items: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: var(--green);
                svg {
                    margin-left: 5px;
                }
            }
            .status-state {
                color: var(--darkGray);
            }
            .info-body-button {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
        .delete-section {
            cursor: pointer;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: var(--lightNavy);
            width: fit-content;
        }
        .add-working-period-section {
            margin-top: 20px;
            margin-left: 0px;
            margin-bottom: 23px;
            cursor: pointer;
            width: fit-content;
            color: var(--mainBlue);
        }

        .delete-section.disabled {
            cursor: default;
            color: var(--navi60);
        }
    }
`;

export { StyledDetailsInnerContainer };
