/*istanbul ignore file*/
import React, { useEffect, useState, useContext } from 'react';

import { useMsal } from '@azure/msal-react';
import { CustomMicrosoftLogin } from 'auth/components/CustomMicrosoftLogin';
import jwtDecode from 'jwt-decode';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router';
import PermissionsContext from 'shared/utils/permissionStore';

import { StyledLogin } from './styled/Login.styled';
import { STATIC_ROUTES_PATHS } from '../../routes';
import { errorNotification } from '../../shared/components/Notifications';
import { Spinner } from '../../shared/components/Spinner';
import authService from '../services/authService';

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const errorTokenExp = localStorage.getItem('errorTokenExp');
    const pageTitle = 'Humate';
    const permissionsContext = useContext(PermissionsContext);
    const { instance, accounts, inProgress } = useMsal();

    useEffect(() => {
        if (localStorage.getItem('logout')) {
            localStorage.removeItem('logout');
        }
    }, []);

    const handleShowSessionExp = () => {
        errorNotification(errorTokenExp, 5);
        localStorage.removeItem('errorTokenExp');
    };

    const authHandler = async () => {
        if (!inProgress) return;

        setIsLoading(true);

        let data;
        try {
            if (
                accounts.length === 1 &&
                !accounts[0].username.includes('onmicrosoft.com')
            ) {
                data = await instance.acquireTokenSilent({
                    scopes: ['User.Read'],
                    account: accounts[0]
                });
            } else {
                data = await instance.acquireTokenPopup({
                    scopes: ['User.Read'],
                    prompt: 'select_account'
                });
            }

            const resData = await authService.loginWithMicrosoft(data.idToken);
            const decodedToken = jwtDecode(data.accessToken);

            localStorage.setItem('token', resData.data.token);
            localStorage.setItem(
                'profile',
                JSON.stringify({
                    email: decodedToken.unique_name,
                    given_name: decodedToken.given_name,
                    name: decodedToken.name,
                    picture: resData.data.photo
                })
            );

            permissionsContext.load(resData.data.token);
        } catch (error) {
            if (error.errorCode === 'user_cancelled') return; //this happens when the user closes the account selection modal present from MSAL

            errorNotification(
                'Something went wrong, please try to login again'
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {!authService.getJwt() ? (
                <StyledLogin>
                    <Helmet title={pageTitle} />
                    <div className="login-layer">
                        <div className="login-box">
                            <div>
                                <div className="header-section">Humate</div>
                            </div>
                            <div style={{ width: '100%' }}>
                                {isLoading ? (
                                    <Spinner style={{ width: '100%' }} />
                                ) : (
                                    <CustomMicrosoftLogin
                                        onClick={authHandler}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {errorTokenExp && handleShowSessionExp()}
                </StyledLogin>
            ) : (
                <Redirect
                    exact={true}
                    path={'/main'}
                    to={{
                        pathname: STATIC_ROUTES_PATHS.main
                    }}
                />
            )}
        </>
    );
};

export default Login;
