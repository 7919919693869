import styled from 'styled-components';

const StyledCustomTabs = styled.div`
    .ant-tabs-content-holder {
        width: 100%;
    }
    .tabs-container {
        width: 100%;
    }
    .tabs-container .ant-tabs {
        width: 100%;
        margin: 0 auto;
    }
    .tabs-container .ant-tabs-nav-wrap {
        background: 3px solid rgba(255, 255, 255, 0.3) !important;
    }
    .tabs-container .ant-tabs-tab {
        background: none !important;
        border: 0px !important;
        color: var(--primaryWhite) !important;
        padding: 0 25px !important;
        margin-right: 10px !important;
        font-size: 16px !important;
        line-height: 20px !important;
        height: 36px;
    }
    .tabs-container .ant-tabs-tab.ant-tabs-tab-active {
        color: rgba(255, 255, 255, 0.3) !important;
        background: rgba(255, 255, 255, 0.3) !important;
        border-radius: 8px 8px 0px 0px;
    }
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
        height: 3px;
        background: var(--primaryWhite) !important;
    }
    .tabs-container .ant-tabs-tab-btn {
        margin-bottom: 3px;
    }
    .tabs-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--primaryWhite);
        font-weight: 700;
    }
    .ant-tabs-ink-bar {
        //background: rgba(255, 255, 255, 0.3) !important;
        //border-bottom: 2px solid white;
    }
    @media screen and (max-width: 1024px) {
        .tabs-container {
            width: 900px;
        }
    }
    @media screen and (max-width: 768px) {
        .tabs-container {
            width: 650px;
        }
    }
`;

export { StyledCustomTabs };
