import React, { useState, useEffect } from 'react';

import { DetailsMainContainer } from 'people/components/shared';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchAllBenefitsByPersonId,
    resetBenefitState
} from 'redux/slices/benefit';
import { fetchBenefitsDropDowns } from 'redux/slices/dropdown';
import { Spinner, CustomButton } from 'shared/components';
import UploadIcon from 'shared/theme/assets/icons/UploadIcon';

import { BenefitModal } from './BenefitModal';
import { Periods } from './Periods';

export const Benefits = ({ personId }) => {
    const [isBenefitModalOpen, setIsBenefitModalOpen] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchBenefitsDropDowns(personId));
        dispatch(fetchAllBenefitsByPersonId(personId));
        return () => {
            dispatch(resetBenefitState());
        };
    }, []);

    const [
        isLoading,
        currentBenefits,
        shouldRefetchBenefits,
        isLoadingDropdown
    ] = useSelector(state => [
        state.benefits.isLoading,
        state.benefits.currentBenefits,
        state.benefits.shouldRefetchBenefits,
        state.dropdown.isLoadingBenefit
    ]);

    const handleShowModal = () => {
        setIsBenefitModalOpen(true);
    };

    const generateUploadDocumentButton = () => {
        return (
            <CustomButton
                style={{ width: '160px' }}
                type="normal"
                iconComponent={UploadIcon}
                text={'Upload document'}
                disabled={isLoading}
                onClick={handleShowModal}
            />
        );
    };

    useEffect(() => {
        if (shouldRefetchBenefits) {
            dispatch(fetchAllBenefitsByPersonId(personId));
        }
    }, [shouldRefetchBenefits]);

    return (
        <DetailsMainContainer
            title={'Benefits'}
            extraTitleRightElement={generateUploadDocumentButton}
        >
            {isLoading || isLoadingDropdown || currentBenefits === undefined ? (
                <Spinner />
            ) : (
                <Periods personId={personId} />
            )}
            {isBenefitModalOpen && (
                <BenefitModal
                    title={'Upload document'}
                    action={'uploaded'}
                    personId={personId}
                    isModalOpen={isBenefitModalOpen}
                    setIsModalOpen={setIsBenefitModalOpen}
                />
            )}
        </DetailsMainContainer>
    );
};
