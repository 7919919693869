import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { resetBenefitTypeState } from 'redux/slices/benefitType';
import { errorNotification } from 'shared/components/Notifications';

import { StyledDashboard } from './styled/AdminDashboard.styled';
import { Main } from '../../shared/components/layout/Main';
import { DashboardHeader } from '../components/Dashboard/AdminDashboardHeader';

const AdminDashboard = props => {
    const { history } = props;
    const { currentTotal, inactiveTotal, error } = useSelector(
        state => state.benefitType
    );
    const dispatch = useDispatch();
    const pageTitle = 'Humate';

    const handleScroll = () => {
        const rect = document.getElementsByClassName('rt-tbody')[0];
        const elementSticky =
            document.getElementsByClassName('rt-thead -header')[0];
        const table = document.getElementsByClassName('ReactTable')[0];
        if (rect && elementSticky && table) {
            let currentPosition = rect.getBoundingClientRect();
            let tableTop = table.getBoundingClientRect();
            if (currentPosition.top <= 105) {
                elementSticky.className = 'rt-thead -header header-static';
                elementSticky.style.top = `${Math.abs(tableTop.top - 60)}px`;
            } else {
                elementSticky.className = 'rt-thead -header';
                elementSticky.style.top = '0';
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        handleScroll();
    }, [currentTotal, inactiveTotal]);

    useEffect(() => {
        if (error) {
            errorNotification(error);
        }
    }, [error]);

    useEffect(() => {
        return () => {
            dispatch(resetBenefitTypeState());
        };
    }, []);

    return (
        <StyledDashboard>
            <Helmet title={pageTitle} />
            <Main
                history={history}
                hasHeader={true}
                hasNavigation={true}
                hasHeading={false}
                hasCustomHeading={true}
                customHeadingComponent={<DashboardHeader history={history} />}
            />
        </StyledDashboard>
    );
};

export default AdminDashboard;
