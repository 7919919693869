import { useContext } from 'react';

import PermissionsContext from 'shared/utils/permissionStore';

import { StyledProfileSubmenu } from './styled/ProfileSubmenu.styled';
import authService from '../../auth/services/authService';
import { STATIC_ROUTES_PATHS } from '../../routes';

const ProfileSubmenu = ({ userEmail, history }) => {
    const permissionsContext = useContext(PermissionsContext);

    const handleClickLogout = () => {
        authService.logout();
        history.push(STATIC_ROUTES_PATHS.login);
        permissionsContext.delete();
    };

    return (
        <StyledProfileSubmenu>
            <div className="profile-sub-menu">
                <div className="profile-content">
                    <div className="mail">{userEmail}</div>
                    <div className="links">
                        <a onClick={handleClickLogout}>Log out</a>
                    </div>
                </div>
            </div>
        </StyledProfileSubmenu>
    );
};

export default ProfileSubmenu;
