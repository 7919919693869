import styled from 'styled-components';

const StyledNavigationItem = styled.div`
    .nav-item > .label > a {
        font-size: 16px;
        color: var(--black);
    }
    .nav-item {
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 18px 37.5px;
    }
    .nav-item-active {
        background: var(--lighterBlue);
    }
    .nav-item-disabled .label {
        font-family: 'Source-Sans-Pro';
        font-size: 16px;
        color: var(--gray);
        text-align: center;
        padding-bottom: 0px;
    }
    .nav-item-disabled .label:hover {
        color: var(--gray);
    }
    .nav-item-active .label {
        font-family: 'Source-Sans-Pro';
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        color: var(--secondaryBlue);
        padding-bottom: 0px;
    }
    .nav-item-active > .label > a {
        color: var(--secondaryBlue);
    }
    @media screen and (max-width: 1024px) {
        .nav-item {
            padding: 18px 20px;
        }
    }
`;

export { StyledNavigationItem };
