import styled from 'styled-components';

export const StyledMainInformation = styled.div`
    .add-working-period-section {
        margin-top: 20px;
        margin-left: 20px;
        cursor: pointer;
        width: fit-content;
        color: var(--mainBlue);
    }

    .add-working-period-section.disabled {
        cursor: default;
        color: var(--navi60);
    }
`;
