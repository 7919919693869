import React from 'react';

import { EditTimeLineWrapper } from './EditTimeLineWrapper';
import { GenerateEditField } from './GenerateEditField';
import { StyledEditTimeline } from './styled/EditView.styled';

// Special component used only for vue specific case of working periods historical data
const ViewSpecialChange = ({ handleChangeView, initialValues }) => {
    const changedPropertyNames = [
        'End Date',
        'Main Reason for Leaving',
        'Leaver Type',
        'Voluntary'
    ];

    return (
        <StyledEditTimeline>
            <EditTimeLineWrapper
                changedPropertyName={'End Date Changes'}
                disabled={true}
                values={initialValues}
                handleChangeView={handleChangeView}
                isSpecial={true}
            >
                {initialValues.items.map((item, index) => (
                    <GenerateEditField
                        key={index}
                        changedPropertyName={changedPropertyNames[index]}
                        value={initialValues[item.normalizedName]}
                        name={item.normalizedName}
                    />
                ))}
            </EditTimeLineWrapper>
        </StyledEditTimeline>
    );
};

export { ViewSpecialChange };
