import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import benefitService from 'people/services/benefitService';
import positionService from 'people/services/positionService';
import { selectedPosition } from 'people/utils/Details/MainInformation/mainInfoFormHelper';
import { mapToWorkingPeriodNames } from 'redux/utils/workingPeriodHelper';

import { pictureActions } from './picture';
import { workingPeriodActions } from './workingPeriod';
import peopleService from '../../people/services/peopleService';
import {
    DELETE_PERSON_MESSAGE,
    INVALID_PERSON_MESSAGE
} from '../../shared/constants/generalConstants';
import { formatDate } from '../utils/reduxHelper';

const initialState = {
    //person
    person: {},
    isLoadingFetchPerson: false,
    errorFetchPerson: null,

    //refetch person
    isLoadingReFetchPerson: false,

    //create person
    showCreateUpdateMessage: false,
    createdPersonId: null,
    error: null,
    isLoadingPerson: false,

    //delete person
    isLoadingDelete: false,
    isPersonDeleted: null,
    deleteError: null,

    //Name exist
    namesExists: {},
    isLoadingExistenceCheck: false,
    existenceError: null,

    //People categories counts
    categoriesCount: {},
    isLoadingCategories: false,
    categoriesError: null,

    //Personal information
    personalInformation: {},
    isLoadingPersonalInformation: false,
    personalInformationError: null,

    //Personal information update state
    isLoadingStack: {}, //object that contains key/value pairs for every changed field
    errorStack: null,

    //Used for refetching employee details information
    shouldRefetchPerson: false
};

const { reducer: peopleReducer, actions } = createSlice({
    name: 'people',
    initialState,
    reducers: {
        //Fetch employee by id
        fetchEmployeeStart: state => {
            state.isLoadingFetchPerson = true;
        },
        fetchEmployeeSuccess: (state, action) => {
            const {
                payload: { data }
            } = action;
            state.isLoadingFetchPerson = false;
            state.person = data;
            state.errorFetchPerson = null;
            state.shouldRefetchPerson = false;
        },
        fetchEmployeeFailure: (state, action) => {
            state.person = {};
            state.isLoadingFetchPerson = false;
            state.errorFetchPerson = action.payload;
            state.shouldRefetchPerson = false;
        },

        //Refetch employee by id
        refetchEmployeeStart: state => {
            state.isLoadingReFetchPerson = true;
        },
        refetchEmployeeSuccess: (state, action) => {
            const {
                payload: { data }
            } = action;
            state.isLoadingReFetchPerson = false;
            state.person = data;
            state.errorFetchPerson = null;
            state.shouldRefetchPerson = false;
        },
        refetchEmployeeFailure: (state, action) => {
            state.person = {};
            state.isLoadingReFetchPerson = false;
            state.errorFetchPerson = action.payload;
            state.shouldRefetchPerson = false;
        },

        //Fetch personal information by id
        fetchPersonalInformationStart: state => {
            state.isLoadingPersonalInformation = true;
        },
        fetchPersonalInformationSuccess: (state, action) => {
            const {
                payload: { data }
            } = action;
            state.isLoadingPersonalInformation = false;
            state.personalInformation = data;
            state.personalInformationError = null;
        },
        fetchPersonalInformationFailure: (state, action) => {
            state.personalInformation = {};
            state.isLoadingPersonalInformation = false;
            state.personalInformationError = action.payload;
        },

        //Fetch people categories counts
        fetchPeopleCategoriesCountStart: state => {
            state.isLoadingCategories = true;
        },
        fetchPeopleCategoriesCountSuccess: (state, action) => {
            const { payload } = action;
            state.categoriesCount = payload;
            state.isLoadingCategories = false;
            state.categoriesError = null;
        },
        fetchPeopleCategoriesCountFailure: (state, action) => {
            state.isLoadingCategories = false;
            state.categoriesError = action.payload;
        },

        //Check if person name already exists
        checkPersonNameExistStart: state => {
            state.isLoadingExistenceCheck = true;
        },
        checkPersonNameExistSuccess: (state, action) => {
            const { data } = action.payload;
            state.isLoadingExistenceCheck = false;
            state.existenceError = null;
            state.namesExists = data;
        },
        checkPersonNameExistFailure: (state, action) => {
            state.isLoadingExistenceCheck = false;
            state.existenceError = action.payload;
        },

        //Create person
        createPersonStart: state => {
            state.isLoadingPerson = true;
            state.error = null;
        },
        createPersonSuccess: (state, action) => {
            const {
                payload: { data }
            } = action;
            state.isLoadingPerson = false;
            state.error = null;
            state.createdPersonId = data.id;
            state.showCreateUpdateMessage = true;
        },
        createPersonFailure: (state, action) => {
            state.showCreateUpdateMessage = false;
            state.isLoadingPerson = false;
            state.error = action.payload;
        },

        //Update person
        updatePersonStart: state => {
            state.isLoadingPerson = true;
            state.error = null;
        },
        updatePersonSuccess: (state, action) => {
            const {
                payload: { data }
            } = action;
            state.isLoadingPerson = false;
            state.error = null;
            state.showCreateUpdateMessage = true;
            state.person = {
                ...state.person,
                name: data.name,
                nameBG: data.nameBg,
                workEmail: data.workEmail,
                phoneNumber: data.personalPhone,
                excluCVUrl: data.excluCVUrl,
                oaid: data.oaid
            };
        },
        updatePersonFailure: (state, action) => {
            state.showCreateUpdateMessage = false;
            state.isLoadingPerson = false;
            state.error = action.payload;
        },

        //Update personal information
        updatePersonalInformationStart: (state, action) => {
            const {
                payload: { callIndex }
            } = action;
            state.isLoadingStack = {
                ...state.isLoadingStack,
                [callIndex]: true
            };
        },
        updatePersonalInformationSuccess: (state, action) => {
            const {
                payload: { callIndex, requestData }
            } = action;
            state.isLoadingStack = {
                ...state.isLoadingStack,
                [callIndex]: undefined
            };
            state.personalInformation = { ...requestData };
            state.person = {
                ...state.person,
                gender: requestData.gender
            };
            state.errorStack = null;
        },
        updatePersonalInformationFailure: (state, action) => {
            const {
                payload: { callIndex, errorMessage }
            } = action;
            state.isLoadingStack = {
                ...state.isLoadingStack,
                [callIndex]: undefined
            };
            state.errorStack = errorMessage;
        },

        //Reset modal state
        resetModalState: state => {
            state.error = null;
            state.createdPersonId = null;
            state.showCreateUpdateMessage = false;
            state.namesExists = {};
            state.isLoadingExistenceCheck = false;
            state.existenceError = null;
        },

        //Reset person state
        resetPersonState: state => {
            state.error = null;
            state.person = {};
            state.isLoadingFetchPerson = false;
            state.isLoadingReFetchPerson = false;
            state.shouldRefetchPerson = false;
            state.errorFetchPerson = null;
            state.isPersonDeleted = null;
            state.isLoadingDelete = false;
            state.deleteError = null;
        },

        //Reset person/people state to initial values
        resetState: state => {
            state.person = {};
            state.isLoadingFetchPerson = false;
            state.errorFetchPerson = null;
            state.createdPersonId = null;
            state.showCreateUpdateMessage = false;
            state.error = null;
            state.isLoadingPerson = false;
            state.categoriesCount = {};
            state.isLoadingCategories = false;
            state.categoriesError = null;
        },

        //Reset personal information state
        resetPersonalInformation: state => {
            state.personalInformation = {};
            state.isLoadingPersonalInformation = false;
            state.personalInformationError = null;
        },

        //Reset state for personal information
        resetLoadingStack: state => {
            state.isLoadingStack = {};
            state.errorStack = null;
        },

        //Delete person
        deletePersonStart: state => {
            state.isLoadingDelete = true;
        },
        deletePersonSuccess: (state, action) => {
            const { payload } = action;
            state.isLoadingDelete = false;
            state.isPersonDeleted = payload.data;
            state.deleteError = null;
        },
        deletePersonFailure: (state, action) => {
            state.isLoadingDelete = false;
            state.deleteError = action.payload;
        }
    },
    extraReducers: {
        [workingPeriodActions.createWorkingPeriodSuccess]: state => {
            state.person.positionTitle = '';
            state.shouldRefetchPerson = true;
        },
        [workingPeriodActions.updateWorkingPeriodSuccess]: (state, action) => {
            const { workingPeriodIndex, metaData } = action.payload;
            const { requestData } = metaData;
            if (workingPeriodIndex === 0) {
                if (
                    requestData.propertyName ===
                        mapToWorkingPeriodNames.reportsToId ||
                    requestData.propertyName ===
                        mapToWorkingPeriodNames.positionTitle
                ) {
                    state.shouldRefetchPerson = true;
                }
            }
            if (
                requestData.propertyName ===
                    mapToWorkingPeriodNames.startDate ||
                requestData.propertyName === mapToWorkingPeriodNames.endDate ||
                requestData.propertyName === mapToWorkingPeriodNames.trialPeriod
            ) {
                state.shouldRefetchPerson = true;
            }
        },
        [workingPeriodActions.updateEndDateWorkingPeriodSuccess]: state => {
            state.shouldRefetchPerson = true;
        },
        [workingPeriodActions.deleteWorkingPeriodSuccess]: (state, action) => {
            const { latestPositionTitle } = action.payload;
            state.person.positionTitle = latestPositionTitle;
            state.shouldRefetchPerson = true;
        },
        [workingPeriodActions.onLeavePeriodChangeSuccess]: state => {
            state.shouldRefetchPerson = true;
        },
        [pictureActions.uploadPictureSuccess]: (state, action) => {
            const { data } = action.payload;
            state.person.photo = data;
        }
    }
});

//Fetches employee by given id
const fetchEmployeeById = id => {
    return async (dispatch, getState) => {
        const isLoadingPerson = getState().people.isLoadingPerson;
        if (isLoadingPerson) {
            return;
        }

        try {
            dispatch(actions.fetchEmployeeStart());
            const employee = await peopleService.getEmployeeById(id);
            dispatch(actions.fetchEmployeeSuccess(employee));
        } catch (err) {
            const error = { ...err };
            if (error.response.status === 400) {
                dispatch(actions.fetchEmployeeFailure(INVALID_PERSON_MESSAGE));
                return;
            }
            if (error.response.status === 404) {
                dispatch(actions.fetchEmployeeFailure(DELETE_PERSON_MESSAGE));
                return;
            }
            dispatch(actions.fetchEmployeeFailure('Something went wrong'));
        }
    };
};

//Fetches employee by given id
const refetchEmployeeById = id => {
    return async (dispatch, getState) => {
        const isLoadingReFetchPerson = getState().people.isLoadingPerson;
        if (isLoadingReFetchPerson) {
            return;
        }

        try {
            dispatch(actions.refetchEmployeeStart());
            const employee = await peopleService.getEmployeeById(id);
            dispatch(actions.refetchEmployeeSuccess(employee));
        } catch (err) {
            const error = { ...err };
            if (error.response.status === 404) {
                dispatch(actions.refetchEmployeeFailure(DELETE_PERSON_MESSAGE));
                return;
            }
            dispatch(actions.refetchEmployeeFailure('Something went wrong'));
        }
    };
};

//Fetches personal information by person id
const fetchPersonalInformationById = personId => {
    return async (dispatch, getState) => {
        const isLoadingPersonalInformation =
            getState().people.isLoadingPersonalInformation;
        if (isLoadingPersonalInformation) {
            return;
        }

        try {
            dispatch(actions.fetchPersonalInformationStart());
            const personalInformation =
                await peopleService.getPersonalInformationById(personId);
            dispatch(
                actions.fetchPersonalInformationSuccess(personalInformation)
            );
        } catch (err) {
            dispatch(
                actions.fetchPersonalInformationFailure('Something went wrong')
            );
        }
    };
};

const fetchCategoriesCounts = () => {
    return async (dispatch, getState) => {
        const isLoading = getState().people.isLoadingCategories;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchPeopleCategoriesCountStart());
            const { data } = await peopleService.getPersonCategoriesCounts();
            dispatch(actions.fetchPeopleCategoriesCountSuccess(data));
        } catch (err) {
            dispatch(
                actions.fetchPeopleCategoriesCountFailure(
                    'Something went wrong'
                )
            );
        }
    };
};

const fetchBenefitsCategoriesCounts = () => {
    return async (dispatch, getState) => {
        const isLoading = getState().people.isLoadingCategories;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchPeopleCategoriesCountStart());
            const { data } = await benefitService.getBenefitsCategoriesCounts();
            dispatch(actions.fetchPeopleCategoriesCountSuccess(data));
        } catch (err) {
            dispatch(
                actions.fetchPeopleCategoriesCountFailure(
                    'Something went wrong'
                )
            );
        }
    };
};

const checkPersonNameExists = checkNamesRequestData => {
    return async (dispatch, getState) => {
        const { isLoadingExistenceCheck } = getState().people;
        if (isLoadingExistenceCheck) {
            return;
        }

        try {
            dispatch(actions.checkPersonNameExistStart());
            const { data } = await peopleService.checkPersonByNameExist(
                checkNamesRequestData
            );
            dispatch(actions.checkPersonNameExistSuccess({ data }));
        } catch (error) {
            dispatch(actions.checkPersonNameExistFailure(error));
        }
    };
};

// Creates new person
const createPerson = requestData => {
    return async (dispatch, getState) => {
        const isLoadingPerson = getState().people.isLoadingPerson;
        if (isLoadingPerson) {
            return;
        }

        const positions = getState().dropdown.positions;
        try {
            dispatch(actions.createPersonStart());
            const chosenPosition = selectedPosition(
                positions,
                requestData.positionTitle
            );
            if (
                requestData.positionTitle !== null &&
                requestData.positionTitle !== undefined &&
                requestData.positionTitle !== '' &&
                !chosenPosition
            ) {
                const createdPosition = await positionService.addPosition(
                    requestData.positionTitle
                );

                requestData = {
                    ...requestData,
                    positionId: createdPosition.data.id
                };
            } else {
                requestData = {
                    ...requestData,
                    positionId: chosenPosition?.id
                };
            }
            requestData = {
                ...requestData,
                trialPeriod:
                    requestData.trialPeriod?.toString().trim() === ''
                        ? null
                        : requestData.trialPeriod,
                workingTime:
                    requestData.workingTime?.toString().trim() === ''
                        ? null
                        : requestData.workingTime,
                startDate: formatDate(requestData.startDate)
            };
            const responseData = await peopleService.addPerson(requestData);
            dispatch(actions.createPersonSuccess(responseData));
        } catch (err) {
            dispatch(
                actions.createPersonFailure(
                    err?.response?.data.messages[0] ?? 'Something went wrong'
                )
            );
        }
    };
};

// Updates person
const updatePerson = (requestData, personId) => {
    return async (dispatch, getState) => {
        const isLoadingPerson = getState().people.isLoadingPerson;
        if (isLoadingPerson) {
            return;
        }

        try {
            dispatch(actions.updatePersonStart());
            requestData = {
                ...requestData
            };
            const responseData = await peopleService.editPerson(
                requestData,
                personId
            );
            dispatch(actions.updatePersonSuccess(responseData));
        } catch (err) {
            dispatch(
                actions.updatePersonFailure(
                    err?.response?.data.messages[0] ?? 'Something went wrong'
                )
            );
        }
    };
};

//Update personal information
const updatePersonalInformation = (
    callIndex,
    personId,
    requestData,
    permissions
) => {
    return async dispatch => {
        try {
            dispatch(
                actions.updatePersonalInformationStart({
                    callIndex
                })
            );
            requestData = {
                ...requestData,
                personalId: permissions.canViewPersonalId
                    ? requestData.personalId
                    : null,
                birthday:
                    requestData.birthday !== null
                        ? moment(requestData.birthday).format('YYYY-MM-DD')
                        : null
            };
            await peopleService.updatePersonalInformationById(
                personId,
                requestData
            );
            dispatch(
                actions.updatePersonalInformationSuccess({
                    callIndex,
                    requestData
                })
            );
        } catch (err) {
            dispatch(
                actions.updatePersonalInformationFailure({
                    callIndex,
                    errorMessage: 'Something went wrong'
                })
            );
        }
    };
};

const resetModalProps = () => {
    return dispatch => {
        dispatch(actions.resetModalState());
    };
};

const resetPersonProp = () => {
    return dispatch => {
        dispatch(actions.resetPersonState());
    };
};

const resetPersonState = () => {
    return dispatch => {
        dispatch(actions.resetState());
    };
};

const resetPersonalInformationState = () => {
    return dispatch => {
        dispatch(actions.resetPersonalInformation());
    };
};

const resetPeopleLoadingStack = () => {
    return dispatch => {
        dispatch(actions.resetLoadingStack());
    };
};

const deletePerson = personId => {
    return async (dispatch, getState) => {
        const isLoading = getState().people.isLoadingDelete;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.deletePersonStart());
            const success = await peopleService.deletePersonById(personId);
            if (success) {
                dispatch(actions.deletePersonSuccess(success));
            } else {
                dispatch(actions.deletePersonFailure('Something went wrong'));
            }
        } catch (err) {
            dispatch(actions.deletePersonFailure('Something went wrong'));
        }
    };
};

export {
    peopleReducer,
    actions as peopleActions,
    fetchEmployeeById,
    refetchEmployeeById,
    fetchPersonalInformationById,
    fetchCategoriesCounts,
    fetchBenefitsCategoriesCounts,
    checkPersonNameExists,
    createPerson,
    updatePerson,
    updatePersonalInformation,
    resetModalProps,
    resetPersonProp,
    resetPersonState,
    resetPeopleLoadingStack,
    resetPersonalInformationState,
    deletePerson
};
