import styled from 'styled-components';

const StyledButton = styled.div`
    .button {
        width: ${({ width }) => width || '150px'};
        height: 34px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 10px;
        background: var(--primaryWhite);
        border-radius: 8px;
        color: var(--mainBlue);
        font-family: 'Source-Sans-Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
    }
    .button:hover {
        color: var(--secondaryBlue);
    }
    .anticon svg {
        height: 14px;
    }
    .button-inner > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .button-inner {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .btn-disabled,
    .btn-disabled:hover {
        border: 1px solid var(--gray) !important;
        svg {
            path {
                stroke: var(--darkGray) !important;
            }
        }
        background-color: var(--gray) !important;
        color: var(--darkGray) !important;
        cursor: not-allowed !important;
    }
    .icon {
        margin-right: 5px;
    }
`;

export { StyledButton };
