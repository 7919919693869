import React, { useState } from 'react';

import { Upload } from 'antd';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from 'redux/slices/benefit';
import {
    CustomButton,
    CloseButton,
    FieldsSection,
    CustomSelect
} from 'shared/components';
import { successNotification } from 'shared/components/Notifications';
import { selectPlaceholder, setAllowClear } from 'utils/componentPropHelpers';
import {
    mapDataForDropdown,
    mapDataForUploadDocument
} from 'utils/dropdownHelper';

import { StyledBenefitModal } from './styled/BenefitModal.styled';

export const BenefitModal = ({
    isModalOpen,
    setIsModalOpen,
    action,
    title,
    personId
}) => {
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState([]);
    const handleClose = () => {
        setIsModalOpen(false);
    };

    const [
        currentBenefits,
        pastBenefits,
        benefitTypes,
        documentTypes,
        relativeMembers,
        personName
    ] = useSelector(state => [
        state.benefits.currentBenefits,
        state.benefits.pastBenefits,
        state.dropdown.benefitTypes,
        state.dropdown.documentTypes,
        state.dropdown.relativeMembers,
        state.people.person.name
    ]);

    const documentTypeOptions = mapDataForDropdown(documentTypes);

    const benefitOptions = mapDataForUploadDocument(
        currentBenefits,
        pastBenefits,
        benefitTypes,
        relativeMembers,
        personName
    );

    const uploadProps = {
        onRemove: files => {
            setFileList();
            return fileList;
        },
        beforeUpload: files => {
            setFileList(files);
            return false;
        }
    };

    const submitForm = values => {
        dispatch(
            uploadFile(
                fileList,
                personId,
                values.benefitCoverPeriodId,
                values.documentTypeId
            )
        );
        successNotification(`Successfully ${action} file`, 5);

        setIsModalOpen(false);
    };

    return (
        <Formik
            initialValues={{
                documentTypeId: '',
                benefitCoverPeriodId: ''
            }}
            onSubmit={submitForm}
        >
            {props => {
                const { values, submitForm, setFieldValue } = props;

                return (
                    <Form>
                        <StyledBenefitModal
                            visible={isModalOpen}
                            setVisible={setIsModalOpen}
                            title={[
                                <div
                                    className="custom-modal-header"
                                    key="modal-title"
                                >
                                    {title}
                                    <CloseButton handleClose={handleClose} />
                                </div>
                            ]}
                            footer={[
                                <div
                                    className="custom-modal-footer"
                                    key="modal-footer"
                                >
                                    <div className="button-section">
                                        <CustomButton
                                            text="Cancel"
                                            type="normal footer-button"
                                            onClick={handleClose}
                                        />
                                    </div>
                                    <div className="button-section">
                                        <CustomButton
                                            text="Upload"
                                            type="filled footer-button"
                                            onClick={submitForm}
                                            disabled={
                                                fileList === undefined ||
                                                values.documentTypeId === '' ||
                                                values.benefitCoverPeriodId ===
                                                    ''
                                            }
                                        />
                                    </div>
                                </div>
                            ]}
                        >
                            <div
                                data-testid="file-modal"
                                className="modal-body-section"
                            >
                                <div className="section-title">
                                    General Information
                                </div>
                                <FieldsSection>
                                    <div className="section-inputs">
                                        <CustomSelect
                                            value={values.documentId}
                                            label="Document type"
                                            name="documentTypeId"
                                            placeholder={selectPlaceholder}
                                            allowClear={setAllowClear([])}
                                            options={documentTypeOptions}
                                            onChange={(name, val) => {
                                                setFieldValue(name, val);
                                            }}
                                        />
                                        <CustomSelect
                                            value={values.benefitCoverPeriodId}
                                            label="Benefit"
                                            name="benefitCoverPeriodId"
                                            placeholder={selectPlaceholder}
                                            allowClear={setAllowClear([])}
                                            options={benefitOptions}
                                            onChange={(name, val) => {
                                                setFieldValue(name, val);
                                            }}
                                        />
                                    </div>
                                </FieldsSection>
                            </div>
                            <div className="modal-upload-section">
                                <div className="section-title">Select File</div>

                                <Upload
                                    multiple={true}
                                    {...uploadProps}
                                    maxCount={5}
                                >
                                    <CustomButton
                                        type="normal"
                                        text={'Browse Files'}
                                    />
                                </Upload>
                            </div>
                            {/* {isConfirmationOpen && (
                                <CustomConfirmationModal
                                    visible={isConfirmationOpen}
                                    setVisible={setIsConfirmationOpen}
                                    title={'Confirmation'}
                                    contentText={`There is already an existing person with ${confirmationExtraText}. Are you sure you want to proceed?`}
                                    handleConfirm={() => handleSave(values)}
                                />
                            )} */}
                        </StyledBenefitModal>
                    </Form>
                );
            }}
        </Formik>
    );
};
