import React, { useRef, useState } from 'react';

import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import reportService from 'reports/services/reportService';
import { errorNotification } from 'shared/components/Notifications';
import { Spinner } from 'shared/components/Spinner';

import StyledDownloadButton from './styled/DownloadButton.styled';
import DownloadIcon from '../../../shared/theme/assets/icons/DownloadIcon';

const downloadElementHandler = (blob, linkRef, fileName) => {
    let link = linkRef.current;
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName + '.xlsx';
    link.click();
    link.removeAttribute('href');
    link.removeAttribute('download');
};

const DownloadButton = props => {
    const { setFieldErrors } = props;
    const {
        benefitTags,
        columnTags,
        filterTags,
        monthPickerStartValue,
        monthPickerEndValue,
        includeOnlyFinishing
    } = useSelector(state => state.benefitReport);

    const [isLoading, setIsLoading] = useState(false);
    const downloadRef = useRef();
    const downloadClickHandler = async () => {
        if (isLoading) {
            return;
        }

        //validation
        if (
            benefitTags.length === 0 ||
            columnTags.length === 0 ||
            isEmpty(monthPickerStartValue)
        ) {
            if (isEmpty(monthPickerStartValue)) {
                setFieldErrors(prev => ({
                    ...prev,
                    monthStartPeriodError: true
                }));
            }

            if (benefitTags.length === 0) {
                setFieldErrors(prev => ({
                    ...prev,
                    benefitsError: true
                }));
            }

            return;
        }

        try {
            setIsLoading(true);

            const benefitTypeIds = benefitTags.map(b => b.value);

            const [month, day, year] = monthPickerStartValue.split('/');
            const reportName = benefitTags.map(b => b.label)[0];
            const fileName = reportName + ' - ' + month + ' / ' + year;
            const fileColumns = columnTags
                .filter(b => b.isClosable)
                .map(b => b.value);
            const filters = filterTags.map(b => b.value);
            const requestData = {
                benefitTypeIds,
                startPeriod: monthPickerStartValue,
                includeOnlyFinishing,
                fileColumns,
                filters,
                reportName
            };
            const { data } = await reportService.getBenefitReports(requestData);

            const blob = new Blob([data]);
            downloadElementHandler(blob, downloadRef, fileName);
            setIsLoading(false);
        } catch (error) {
            errorNotification('Something went wrong', 5);
            setIsLoading(false);
        }
    };

    const buttonIcon = isLoading ? <Spinner size="small" /> : <DownloadIcon />;

    return (
        <StyledDownloadButton>
            <button className="btn-normal" onClick={downloadClickHandler}>
                {buttonIcon}
                Download .xls
            </button>
            <a id="download-hidden" ref={downloadRef} />
        </StyledDownloadButton>
    );
};

export default DownloadButton;
