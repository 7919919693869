import jwtDecode from 'jwt-decode';
import { STATIC_ROUTES_PATHS } from 'routes';

import { getUserPermissionsObject } from './permissionsHelper';

export const canNavigate = route => {
    const token = localStorage.getItem('token');

    if (!token) {
        return false;
    }

    const decodedToken = jwtDecode(token);
    const permissionsFromToken = getUserPermissionsObject(
        decodedToken.permissions
    );

    const personDetailsRoute = STATIC_ROUTES_PATHS.personDetails;

    if (route == STATIC_ROUTES_PATHS.peopleDashboard) {
        return (
            permissionsFromToken.canViewPeoplePage &&
            (permissionsFromToken.canViewCurrentTab ||
                permissionsFromToken.canViewNewTab ||
                permissionsFromToken.canViewLeaversTab ||
                permissionsFromToken.canViewExTab)
        );
    }

    if (
        route.includes(
            personDetailsRoute.substring(0, personDetailsRoute.lastIndexOf('/'))
        )
    ) {
        return (
            permissionsFromToken.canViewPeoplePage &&
            permissionsFromToken.canViewPeopleDetailsPage &&
            (permissionsFromToken.canViewCurrentTab ||
                permissionsFromToken.canViewNewTab ||
                permissionsFromToken.canViewLeaversTab ||
                permissionsFromToken.canViewExTab)
        );
    }

    if (route == STATIC_ROUTES_PATHS.reportsDashboard) {
        return (
            permissionsFromToken.canViewReportsPage &&
            (permissionsFromToken.canViewPowerBIReportsTab ||
                permissionsFromToken.canViewBenefitsTab)
        );
    }

    if (route == STATIC_ROUTES_PATHS.adminDashboard) {
        return (
            permissionsFromToken.canViewAdminPage &&
            (permissionsFromToken.canViewBenefitTypesTab ||
                permissionsFromToken.canViewRolesTab ||
                permissionsFromToken.canViewUsersTab ||
                permissionsFromToken.canViewNonFlexibleBenefitTypesTab ||
                permissionsFromToken.canViewOffersTab ||
                permissionsFromToken.canViewDiscountsTab)
        );
    }

    if (route == STATIC_ROUTES_PATHS.benefitsDashboard) {
        return (
            permissionsFromToken.canViewBenefitsPage &&
            (permissionsFromToken.canViewBenefitsAllTab ||
                permissionsFromToken.canViewBenefitsNewTab ||
                permissionsFromToken.canViewBenefitsMaternityTab ||
                permissionsFromToken.canViewBenefitsLeaversTab)
        );
    }

    if (route == STATIC_ROUTES_PATHS.requests) {
        return (
            permissionsFromToken.canViewRequestsPage &&
            (permissionsFromToken.canViewRequestsPagePendingTab ||
                permissionsFromToken.canViewRequestsPageApprovedTab ||
                permissionsFromToken.canViewRequestsPageDeliveredTab ||
                permissionsFromToken.canViewRequestsPageRejectedTab ||
                permissionsFromToken.canViewRequestsPageCancelledTab)
        );
    }

    return true;
};
