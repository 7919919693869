import { CustomModal } from 'shared/components/Modal';
import styled from 'styled-components';

const StyledModal = styled(CustomModal)`
    width: 520px;
    form {
        display: flex;
        flex-direction: column;
        gap: 18px;
        margin-top: 20px;
    }
    .ant-modal-header {
        margin: 0px 20px;
        padding-top: 21px;
        padding-bottom: 17px;
    }
    .ant-modal-body {
        margin: 0px 20px;
        .modal-content {
            padding-top: 24px;
            padding-bottom: 45px;
            .ant-select,
            .custom-datepicker {
                width: 100%;
                .ant-select-selector {
                    width: 100%;
                }
            }
        }
    }
    .custom-select-div {
        margin: 0;
    }
    .ant-modal-footer {
        margin: 0px 20px;
        border-top: none;
        padding: 18px 0px;
        .footer {
            display: flex;
            gap: 20px;
            width: 100%;
            > * {
                height: 34px;
                flex: 1 1 0;
                .button {
                    width: 100%;
                }
            }
        }
    }
    .error-input {
        .ant-picker,
        &.customInput input:not(.ant-input-affix-wrapper .ant-input) {
            border-color: var(--red);
        }
    }
    .error-message {
        color: var(--red);
        font-size: 12px;
    }
    .customInput,
    .custom-select-div {
        width: 100%;
        min-width: 0;
    }
    .customInput .label-disabled {
        color: inherit;
    }
    .row {
        display: flex;
        gap: 20px;
        > * {
            flex: 1 1 0;
        }
    }
    input:disabled,
    .ant-select-disabled .ant-select-selection-item {
        color: var(--darkGray);
    }
`;

export default StyledModal;
