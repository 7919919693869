import styled from 'styled-components';

const StyledAllTabs = styled.div`
    .tab-error {
        margin-top: 80px;
        font-size: 16px;
    }
    .tab-list {
        padding-left: 0;
        height: 3.75rem;
        border-bottom: 3px solid rgba(229, 229, 229, 0.5);
    }
    .tab-list-item {
        display: inline-block;
        list-style: none;
        margin-bottom: -1px;
        padding: 0.5rem 1.75rem 1.5rem;
        font-size: 16px;
        color: var(--black);
        cursor: pointer;
    }
    .tab-list-active {
        background: rgba(255, 255, 255, 0.3);
        border-radius: 8px 8px 0px 0px;
        border-bottom: 3px solid var(--secondaryBlue);
        color: var(--secondaryBlue);
        font-size: 16px;
        font-weight: 700;
        padding-left: 20px;
        padding-right: 20px;
    }
    .disabled {
        pointer-events: none;
        opacity: 0.6;
    }
`;

export { StyledAllTabs };
