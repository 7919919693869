import styled from 'styled-components';

const StyledEditTimeline = styled.div`
    .vertical-timeline-element-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: var(--blackShadow);
        margin-bottom: 0px;
    }
    .vertical-timeline-edit-section {
        padding-bottom: 17px;
        .custom-datepicker {
            padding-top: 12px;
            width: 230px;
            .ant-picker-disabled {
                cursor: auto;
                .ant-picker-input {
                    cursor: auto;
                    input {
                        cursor: auto;
                        color: var(--black) !important;
                    }
                }
            }
        }
        .custom-ac {
            padding-top: 12px;
            .ant-select {
                width: 230px !important;
            }
        }
        .customInput {
            padding-top: 12px;
            width: 230px;
            .label-disabled {
                color: var(--black);
            }
            .ant-input-affix-wrapper-disabled {
                cursor: auto;
                .ant-input-disabled {
                    cursor: auto;
                    color: var(--black) !important;
                }
            }
        }
        .custom-select-div {
            padding: 0px !important;
            padding-top: 12px !important;
            margin-bottom: 0px !important;
            .ant-select-selector {
                width: 230px;
            }
            .ant-select-disabled {
                .ant-select-selector {
                    cursor: auto;
                    .ant-select-selection-item {
                        cursor: auto;
                        color: var(--black) !important;
                    }
                }
            }
        }
    }
    .vertical-timeline-button-section {
        display: flex;
        justify-content: space-between;
        .button {
            width: 110px;
            height: 30px;
        }
        .button.btn-full {
            width: 230px !important;
        }
    }
    @media only screen and (max-width: 426px) {
        .vertical-timeline-edit-section {
            padding-bottom: 17px;
            .custom-datepicker {
                padding-top: 12px;
                width: 150px !important;
            }
            .custom-ac {
                padding-top: 12px;
                .ant-select {
                    width: 150px !important;
                }
            }
            .customInput {
                padding-top: 12px;
                width: 150px !important;
            }
            .custom-select-div {
                padding: 0px !important;
                padding-top: 12px !important;
                margin-bottom: 0px !important;
                .ant-select-selector {
                    width: 150px !important;
                }
            }
        }
        .vertical-timeline-button-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            .button {
                width: 110px;
                height: 30px;
                margin-bottom: 2px !important;
            }
            .button.btn-full {
                width: 150px !important;
            }
        }
    }
`;

export { StyledEditTimeline };
