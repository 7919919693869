import styled from 'styled-components';

const StyledDepartmentSubSection = styled.div`
    position: absolute;
    left: ${props => props.positionObj.left};
    top: ${props => props.positionObj.top};
    width: ${props => props.positionObj.width};
    height: ${props => props.positionObj.height};

    .department-inner-section {
        display: flex;
        position: absolute;
        border-radius: 10px;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        background: #ffffff;
        top: 5px;
        left: 5px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: inherit;

        .department-context {
            .department-flex {
                display: flex;
                padding-left: 20px;
                padding-right: 20px;
                justify-content: center;
                align-items: center;
                flex-flow: row wrap;
                .inner-text {
                    color: ${props => props.itemColor};
                    font-weight: 600;
                    font-size: 2em;
                    cursor: pointer;
                    padding-right: 5px;
                    max-width: 200px;
                    text-align: center;
                }
                .number-count {
                    color: #111111;
                    font-weight: bold;
                    font-size: 2em;
                    cursor: pointer;
                }
            }
        }
    }

    .department-inner-section:hover {
        background: ${props => props.itemColor};
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.16);
        .department-context {
            .inner-text {
                color: #ffffff;
            }
            .number-count {
                color: #ffffff;
            }
        }
    }
`;

export { StyledDepartmentSubSection };
