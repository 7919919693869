import styled from 'styled-components';

const StyledBenefitPriceSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    > * {
        flex: 1;
    }
    .customInput,
    .custom-datepicker {
        width: 100%;
    }
    .custom-select-div {
        margin-top: 0px;
        margin-bottom: 0px;
        width: 100%;
    }

    .ant-select {
        width: 100%;
    }

    .ant-select-selector {
        width: 100% !important;
    }
`;

export default StyledBenefitPriceSection;
