import styled from 'styled-components';

const StyledDashboardHeader = styled.div`
    .people-dashboard-header-wrapper {
        display: flex;
        flex-direction: column;
        height: 110px;
        padding: 24px 90px 0px 90px;
    }
    .nav-list-items {
        display: flex;
        margin-top: -12px;
        margin-bottom: 0px;
        padding: 0px;
    }
    .nav-list-items > div {
        width: 100%;
    }
    .nav-list-items .spinner-div .ant-spin.ant-spin-lg.ant-spin-spinning {
        padding-top: 100px;
        display: flex;
        justify-content: center;
    }
`;

export { StyledDashboardHeader };
