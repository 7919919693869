import React from 'react';

function UploadIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            width="21"
            height="16"
            fill="none"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 1024 1024"
        >
            <path
                d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 0 0-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"
                fill="#03A8F5"
            />
            <rect
                x="0"
                y="0"
                width="1024"
                height="1024"
                fill="rgba(0, 0, 0, 0)"
            />
        </svg>
    );
}

export default UploadIcon;
