import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { formatDate } from 'redux/utils/reduxHelper';

import benefitService from '../../people/services/benefitService';
import OnLeaveService from '../../people/services/OnLeaveService';
import workingPeriodService from '../../people/services/workingPeriodService';
import { warningNotification } from '../../shared/components/Notifications';
import { getUserPermissionsObject } from '../../shared/utils/permissionsHelper';
import {
    formatWorkingPeriodUpdateData,
    handlePositionId,
    mapToWorkingPeriodNames
} from '../utils/workingPeriodHelper';

const initialState = {
    workingPeriods: [],
    error: null,
    isLoading: false,
    isLoadingStack: {}
};

const { reducer: workingPeriodReducer, actions } = createSlice({
    name: 'workingPeriods',
    initialState,
    reducers: {
        //Fetch working periods
        fetchWorkingPeriodStart: state => {
            state.isLoading = true;
        },
        fetchWorkingPeriodSuccess: (state, action) => {
            const {
                payload: { data }
            } = action;
            state.isLoading = false;
            state.workingPeriods = data;
            state.error = null;
        },
        fetchWorkingPeriodFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        //Create working period
        createWorkingPeriodStart: state => {
            state.isLoading = true;
        },
        createWorkingPeriodSuccess: (state, action) => {
            const {
                payload: { data }
            } = action;
            state.isLoading = false;
            state.workingPeriods = data;
            state.error = null;
        },
        createWorkingPeriodFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        //Update working period
        updateWorkingPeriodStart: (state, action) => {
            const {
                payload: { workingPeriodIndex, callIndex }
            } = action;
            state.isLoadingStack[workingPeriodIndex] = {
                ...state.isLoadingStack[workingPeriodIndex],
                [callIndex]: true
            };
        },
        updateWorkingPeriodSuccess: (state, action) => {
            const {
                payload: { workingPeriodIndex, callIndex, updatedWorkingPeriod }
            } = action;

            state.workingPeriods[workingPeriodIndex] = {
                ...state.workingPeriods[workingPeriodIndex],
                ...updatedWorkingPeriod
            };
            state.isLoadingStack[workingPeriodIndex] = {
                ...state.isLoadingStack[workingPeriodIndex],
                [callIndex]: undefined
            };
            state.error = null;
        },
        updateWorkingPeriodFailure: (state, action) => {
            const {
                payload: { workingPeriodIndex, callIndex, errorMessage }
            } = action;
            state.isLoadingStack[workingPeriodIndex] = {
                ...state.isLoadingStack[workingPeriodIndex],
                [callIndex]: undefined
            };
            state.error = errorMessage;
        },

        //Update working period with end date changes
        updateEndDateWorkingPeriodStart: (state, action) => {
            const {
                payload: { workingPeriodIndex, callIndex }
            } = action;
            state.isLoadingStack[workingPeriodIndex] = {
                ...state.isLoadingStack[workingPeriodIndex],
                [callIndex]: true
            };
        },
        updateEndDateWorkingPeriodSuccess: (state, action) => {
            const {
                payload: { workingPeriodIndex, callIndex, updatedWorkingPeriod }
            } = action;

            state.workingPeriods[workingPeriodIndex] = {
                ...state.workingPeriods[workingPeriodIndex],
                ...updatedWorkingPeriod
            };
            state.isLoadingStack[workingPeriodIndex] = {
                ...state.isLoadingStack[workingPeriodIndex],
                [callIndex]: undefined
            };
            state.error = null;
        },
        updateEndDateWorkingPeriodFailure: (state, action) => {
            const {
                payload: { workingPeriodIndex, callIndex, errorMessage }
            } = action;
            state.isLoadingStack[workingPeriodIndex] = {
                ...state.isLoadingStack[workingPeriodIndex],
                [callIndex]: undefined
            };
            state.error = errorMessage;
        },

        //Delete working period
        deleteWorkingPeriodStart: state => {
            state.isLoading = true;
        },
        deleteWorkingPeriodSuccess: (state, action) => {
            const {
                payload: { data }
            } = action;
            state.isLoading = false;
            state.workingPeriods = data;
            state.error = null;
        },
        deleteWorkingPeriodFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        //On Leave Period
        onLeavePeriodChangeSuccess: (state, action) => {
            const {
                payload: { workingPeriodIndex, callIndex, data }
            } = action;
            state.workingPeriods = data;
            state.isLoadingStack[workingPeriodIndex] = {
                ...state.isLoadingStack[workingPeriodIndex],
                [callIndex]: undefined
            };
            state.error = null;
        },

        //Clear state
        clearState: state => {
            state.workingPeriods = [];
            state.isLoading = false;
            state.error = null;
            state.isLoadingStack = {};
        },

        //Clear isLoadingStack
        clearLoadingStack: (state, action) => {
            state.isLoadingStack = {
                ...state.isLoadingStack,
                [action.payload]: undefined
            };
            state.error = null;
        }
    }
});

const fetchWorkingPeriods = personId => {
    return async (dispatch, getState) => {
        const isLoading = getState().workingPeriods.isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchWorkingPeriodStart());
            const workingPeriods = await workingPeriodService.getWorkingPeriods(
                personId
            );
            dispatch(actions.fetchWorkingPeriodSuccess(workingPeriods));
        } catch (err) {
            dispatch(actions.fetchWorkingPeriodFailure('Something went wrong'));
        }
    };
};

const addWorkingPeriod = requestData => {
    return async (dispatch, getState) => {
        const isLoading = getState().workingPeriods.isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.createWorkingPeriodStart());
            await workingPeriodService.postWorkingPeriod(requestData);
            const workingPeriods = await workingPeriodService.getWorkingPeriods(
                requestData.personId
            );
            dispatch(actions.createWorkingPeriodSuccess(workingPeriods));
        } catch (err) {
            dispatch(
                actions.createWorkingPeriodFailure('Something went wrong')
            );
        }
    };
};

const updateWorkingPeriod = (
    workingPeriodIndex,
    callIndex,
    workingPeriodId,
    requestObj
) => {
    return async (dispatch, getState) => {
        const positions = getState().dropdown.positions;
        try {
            dispatch(
                actions.updateWorkingPeriodStart({
                    workingPeriodIndex,
                    callIndex
                })
            );

            // Set meta data for positions to get updated in redux state
            let positionExist = true;
            let isPositionCreated = false;
            let positionObject = {};

            let requestData = {
                propertyName: mapToWorkingPeriodNames[requestObj.inputName],
                value: requestObj.value,
                takesEffectOn: requestObj.takesEffectOn
            };

            // If the change is PositionId checks if it exist.
            // If not create new position and returns the newly created position id from database.
            // If exist returns correct position id from database.
            if (requestData.propertyName === 'PositionId') {
                // Before transformation get title of position
                positionObject = {
                    description: requestData.value
                };

                // Set correct position id in request data and returns value if new position were created
                const { chosenPosition, incomeData } = await handlePositionId(
                    positions,
                    requestData
                );

                // Setup meta data
                positionExist = Boolean(chosenPosition);
                requestData = incomeData;
                // After transformation get id of position
                positionObject = {
                    ...positionObject,
                    id: requestData.value
                };
                isPositionCreated = Boolean(
                    positionObject.description !== null &&
                        positionObject.description !== undefined &&
                        positionObject.description !== '' &&
                        !positionExist
                );
            }

            // Formats dates correctly
            requestData = formatWorkingPeriodUpdateData(requestData);

            const { data: updatedWorkingPeriod } =
                await workingPeriodService.editWorkingPeriodById(
                    workingPeriodId,
                    requestData
                );

            dispatch(
                actions.updateWorkingPeriodSuccess({
                    updatedWorkingPeriod,
                    workingPeriodIndex,
                    callIndex,
                    metaData: {
                        positionObject,
                        isPositionCreated,
                        requestData
                    }
                })
            );
        } catch (err) {
            dispatch(
                actions.updateWorkingPeriodFailure({
                    workingPeriodIndex,
                    callIndex,
                    errorMessage: 'Something went wrong'
                })
            );
        }
    };
};

const createEndDateWorkingPeriod = (
    workingPeriodIndex,
    callIndex,
    workingPeriodId,
    requestObj,
    personId
) => {
    return async dispatch => {
        try {
            dispatch(
                actions.updateEndDateWorkingPeriodStart({
                    workingPeriodIndex,
                    callIndex
                })
            );

            const token = localStorage.getItem('token');
            if (!token) {
                return false;
            }
            const decodedToken = jwtDecode(token);
            const permissionsFromToken = getUserPermissionsObject(
                decodedToken.permissions
            );

            const hasBenefitPermissions =
                permissionsFromToken.canEditBenefitType &&
                permissionsFromToken.canViewBenefitTypesTab;

            let areBenefitsToBeChanged = false;
            if (hasBenefitPermissions) {
                const benefits = await benefitService.getAllBenefitsByPersonId(
                    personId
                );
                let currentBenefits = benefits.data.currentBenefits;
                if (currentBenefits.length > 0) {
                    areBenefitsToBeChanged =
                        currentBenefits.filter(
                            benefit => benefit.endDate == null
                        ).length > 0;
                }
            }

            // Format end date
            requestObj.endDate = formatDate(requestObj.endDate);

            const { data: updatedWorkingPeriod } =
                await workingPeriodService.createEndDateWorkingPeriodById(
                    workingPeriodId,
                    requestObj
                );

            dispatch(
                actions.updateEndDateWorkingPeriodSuccess({
                    updatedWorkingPeriod,
                    workingPeriodIndex,
                    callIndex
                })
            );

            if (hasBenefitPermissions && areBenefitsToBeChanged) {
                // eslint-disable-next-line no-console
                console.log('Warning');
                warningNotification(
                    `Current benefits were also set with an end date. Check if any correction is needed.`,
                    5
                );
            }
        } catch (err) {
            dispatch(
                actions.updateEndDateWorkingPeriodFailure({
                    workingPeriodIndex,
                    callIndex,
                    errorMessage: 'Something went wrong'
                })
            );
        }
    };
};

const deleteEndDateWorkingPeriod = (
    workingPeriodIndex,
    callIndex,
    workingPeriodId
) => {
    return async dispatch => {
        try {
            dispatch(
                actions.updateEndDateWorkingPeriodStart({
                    workingPeriodIndex,
                    callIndex
                })
            );

            const { data: updatedWorkingPeriod } =
                await workingPeriodService.deleteEndDateWorkingPeriodById(
                    workingPeriodId
                );

            dispatch(
                actions.updateEndDateWorkingPeriodSuccess({
                    updatedWorkingPeriod,
                    workingPeriodIndex,
                    callIndex
                })
            );
        } catch (err) {
            dispatch(
                actions.updateEndDateWorkingPeriodFailure({
                    workingPeriodIndex,
                    callIndex,
                    errorMessage: 'Something went wrong'
                })
            );
        }
    };
};

const removeWorkingPeriod = (workingPeriodId, personId, positionsData) => {
    return async (dispatch, getState) => {
        const isLoading = getState().workingPeriods.isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.deleteWorkingPeriodStart());
            await workingPeriodService.deleteWorkingPeriodById(workingPeriodId);
            const workingPeriods = await workingPeriodService.getWorkingPeriods(
                personId
            );
            const data = workingPeriods.data;
            const latestPositionTitle = positionsData.find(
                x => x.id === data[0].positionId
            )?.description;
            dispatch(
                actions.deleteWorkingPeriodSuccess({
                    data,
                    latestPositionTitle
                })
            );
        } catch (err) {
            dispatch(
                actions.deleteWorkingPeriodFailure('Something went wrong')
            );
        }
    };
};

const addOnLeavePeriod = ({
    workingPeriodId,
    workingPeriodIndex,
    personId
}) => {
    return async (dispatch, getState) => {
        const isLoading = getState().workingPeriods.isLoading;
        if (isLoading) {
            return;
        }

        const isLoadingStack = getState().workingPeriods.isLoadingStack;
        const callIndex = Object.keys(isLoadingStack).length + 1;

        try {
            dispatch(
                actions.updateWorkingPeriodStart({
                    workingPeriodIndex,
                    callIndex
                })
            );
            await OnLeaveService.postOnLeave(workingPeriodId);
            const { data } = await workingPeriodService.getWorkingPeriods(
                personId
            );

            dispatch(
                actions.onLeavePeriodChangeSuccess({
                    data,
                    workingPeriodIndex,
                    callIndex
                })
            );
        } catch (err) {
            const errorMessage =
                err.response.data.messages[0] || 'Something went wrong';
            dispatch(
                actions.updateWorkingPeriodFailure({
                    errorMessage: errorMessage,
                    workingPeriodIndex,
                    callIndex
                })
            );
        }
    };
};

const updateOnLeavePeriod = ({
    onLeavePeriodId,
    workingPeriodIndex,
    workingPeriodId,
    propertyName,
    value,
    personId
}) => {
    return async (dispatch, getState) => {
        const isLoading = getState().workingPeriods.isLoading;
        if (isLoading) {
            return;
        }

        const isLoadingStack = getState().workingPeriods.isLoadingStack;
        const callIndex = Object.keys(isLoadingStack).length + 1;

        try {
            dispatch(
                actions.updateWorkingPeriodStart({
                    workingPeriodIndex,
                    callIndex
                })
            );
            await OnLeaveService.updateOnLeave(onLeavePeriodId, {
                workingPeriodId,
                propertyName,
                value: formatDate(value)
            });
            const { data } = await workingPeriodService.getWorkingPeriods(
                personId
            );

            dispatch(
                actions.onLeavePeriodChangeSuccess({
                    data,
                    workingPeriodIndex,
                    callIndex
                })
            );
        } catch (err) {
            const errorMessage =
                err.response.data.messages[0] || 'Something went wrong';
            dispatch(
                actions.updateWorkingPeriodFailure({
                    errorMessage: errorMessage,
                    workingPeriodIndex,
                    callIndex
                })
            );
        }
    };
};

const deleteOnLeavePeriod = ({
    onLeavePeriodId,
    workingPeriodIndex,
    personId
}) => {
    return async (dispatch, getState) => {
        const isLoading = getState().workingPeriods.isLoading;
        if (isLoading) {
            return;
        }

        const isLoadingStack = getState().workingPeriods.isLoadingStack;
        const callIndex = Object.keys(isLoadingStack).length + 1;

        try {
            dispatch(
                actions.updateWorkingPeriodStart({
                    workingPeriodIndex,
                    callIndex
                })
            );
            await OnLeaveService.deleteOnLeave(onLeavePeriodId);
            const { data } = await workingPeriodService.getWorkingPeriods(
                personId
            );

            dispatch(
                actions.onLeavePeriodChangeSuccess({
                    data,
                    workingPeriodIndex,
                    callIndex
                })
            );
        } catch (err) {
            const errorMessage =
                err.response.data.messages[0] || 'Something went wrong';
            dispatch(
                actions.updateWorkingPeriodFailure({
                    errorMessage: errorMessage,
                    workingPeriodIndex,
                    callIndex
                })
            );
        }
    };
};

const resetWorkingPeriodState = () => {
    return dispatch => {
        dispatch(actions.clearState());
    };
};

const resetWorkingPeriodLoadingStack = workingPeriodIndex => {
    return dispatch => {
        dispatch(actions.clearLoadingStack(workingPeriodIndex));
    };
};

export {
    workingPeriodReducer,
    actions as workingPeriodActions,
    fetchWorkingPeriods,
    addWorkingPeriod,
    updateWorkingPeriod,
    createEndDateWorkingPeriod,
    deleteEndDateWorkingPeriod,
    removeWorkingPeriod,
    resetWorkingPeriodState,
    resetWorkingPeriodLoadingStack,
    addOnLeavePeriod,
    updateOnLeavePeriod,
    deleteOnLeavePeriod
};
