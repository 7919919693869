import { createSlice } from '@reduxjs/toolkit';

import pictureService from '../../people/services/pictureService';

const initialState = {
    error: null,
    isLoading: false
};

const { reducer: pictureReducer, actions } = createSlice({
    name: 'picture',
    initialState,
    reducers: {
        //Upload picture
        uploadPictureStart: state => {
            state.isLoading = true;
        },
        uploadPictureSuccess: state => {
            state.isLoading = false;
            state.error = null;
        },
        uploadPictureFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

const uploadProfilePicture = (imageFile, personId) => {
    return async (dispatch, getState) => {
        const isLoading = getState().isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.uploadPictureStart());
            const avatarUrl = await pictureService.uploadPicture(
                imageFile,
                personId
            );
            dispatch(actions.uploadPictureSuccess(avatarUrl));
        } catch (err) {
            dispatch(actions.uploadPictureFailure('Something went wrong'));
        }
    };
};

export { pictureReducer, actions as pictureActions, uploadProfilePicture };
