import React from 'react';

import { Modal } from 'antd';

export const CustomModal = ({
    visible,
    setVisible,
    title,
    children,
    closable = false,
    centered = true,
    destroyOnClose = true,
    enableClose = true,
    maxWidth = false,
    footer,
    className,
    dataTestid,
    handleCustomCancel = () => {} //be aware of specific cases it is used
}) => {
    return (
        <Modal
            open={visible}
            onCancel={() => {
                if (enableClose) {
                    setVisible(false);
                    handleCustomCancel();
                }
            }}
            closable={closable}
            destroyOnClose={destroyOnClose}
            footer={footer}
            title={title}
            className={className}
            centered={centered}
            data-testid={dataTestid}
        >
            <div className={`modal-content${maxWidth ? ' maxWidth' : ' '}`}>
                {children}
            </div>
        </Modal>
    );
};
