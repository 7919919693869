import axios from 'axios';

import url from '../../apiUrls';
import { buildPagedQueryData } from '../../benefits/utils/benefitsServiceHelper';
import httpService from '../../shared/services/httpService';

const postBenefit = async requestData => {
    const data = await httpService.post(url.CREATE_BENEFIT(), {
        ...requestData
    });
    return data.data;
};

const getAllBenefitsByPersonId = async personId => {
    const data = await httpService.get(
        url.GET_ALL_BENEFITS_BY_PERSON_ID(personId)
    );
    return data.data;
};

const deleteBenefitById = async benefitId => {
    const data = await httpService.delete(url.DELETE_BENEFIT_BY_ID(benefitId));
    return data.data;
};

const patchBenefits = async (benefitId, requestData) => {
    const data = await httpService.patch(url.UPDATE_BENEFIT(benefitId), {
        ...requestData
    });
    return data.data;
};

const getBenefitsCategoriesCounts = async () => {
    const res = await httpService.get(url.GET_BENEFITS_CATEGORIES_COUNTS());
    return res.data;
};

const uploadFile = async (file, personId, benefitId, documentTypeId) => {
    const extension = file.name.split('.')[1];
    const formFile = new FormData();
    formFile.append('file', file);
    formFile.append('personId', personId);
    formFile.append('benefitCoverPeriodId', benefitId);
    formFile.append('documentTypeId', documentTypeId);
    formFile.append('extension', extension);

    const data = await httpService.post(
        url.UPLOAD_FILE_BY_PERSON_AND_BENEFIT_ID(benefitId),
        formFile,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );

    return data.data;
};

const deleteFileById = async fileId => {
    const data = await httpService.delete(url.DELETE_FILE_BY_ID(fileId));
    return data.data;
};

const getExcelFile = async (subset, requestModel) => {
    const baseParams = buildPagedQueryData(subset, requestModel);

    const requestUrl = url.GET_EXCEL_BENEFIT(baseParams);

    const res = await axios({
        url: requestUrl,
        method: 'GET',
        responseType: 'blob'
    });
    return res;
};

export default {
    postBenefit,
    getAllBenefitsByPersonId,
    getBenefitsCategoriesCounts,
    deleteBenefitById,
    patchBenefits,
    uploadFile,
    deleteFileById,
    getExcelFile
};
