import { isEmpty } from 'lodash';

export const getColumnWidth = (data, headerText) => {
    const maxWidth = 500;
    const magicSpacing = 10;

    let maxNameLength;
    const longestName = Math.max(
        ...data.map(el => (!isEmpty(el.name) ? el.name.length : 0))
    );

    maxNameLength = longestName;

    const cellLength = Math.max(maxNameLength, headerText.length);
    const finalLength = Math.min(maxWidth, cellLength * magicSpacing) + 50;

    if (finalLength <= 140) {
        return 140;
    }

    return finalLength;
};

export const allBenefitsBackgroundHelper = (state, rowInfo, column) => {
    const hasLeft = rowInfo?.original?.active === false;

    if (hasLeft) {
        return { background: '#F0F3F6' };
    }

    return {
        background: state?.sorted[0]?.id === column.id && '#f9fbfc'
    };
};

export const simpleTableBackgroundHelper = (state, _, column) => {
    return { background: state?.sorted[0]?.id === column.id && '#f9fbfc' };
};
