import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const getWorkingPeriods = async personId => {
    const data = await httpService.get(
        url.GET_WORKING_PERIODS_BY_PERSON_ID(personId)
    );
    return data.data;
};

const postWorkingPeriod = async requestData => {
    const data = await httpService.post(url.CREATE_WORKING_PERIOD(), {
        ...requestData
    });
    return data.data;
};

const editWorkingPeriodById = async (workingPeriodId, requestData) => {
    const data = await httpService.patch(
        url.UPDATE_WORKING_PERIOD(workingPeriodId),
        {
            ...requestData
        }
    );
    return data.data;
};

const createEndDateWorkingPeriodById = async (workingPeriodId, requestData) => {
    const data = await httpService.patch(
        url.CREATE_END_DATE_WORKING_PERIOD(workingPeriodId),
        {
            ...requestData
        }
    );
    return data.data;
};

const deleteEndDateWorkingPeriodById = async workingPeriodId => {
    const data = await httpService.patch(
        url.DELETE_END_DATE_WORKING_PERIOD(workingPeriodId)
    );
    return data.data;
};

const deleteWorkingPeriodById = async workingPeriodId => {
    const data = await httpService.delete(
        url.DELETE_WORKING_PERIOD(workingPeriodId)
    );
    return data.data;
};

export default {
    getWorkingPeriods,
    postWorkingPeriod,
    editWorkingPeriodById,
    createEndDateWorkingPeriodById,
    deleteEndDateWorkingPeriodById,
    deleteWorkingPeriodById
};
