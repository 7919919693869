import React, { useEffect, useState } from 'react';

import placeholder from '../theme/assets/images/placeholder-profile.png';

const Media = ({
    className,
    photoUrl,
    onClick = () => {},
    dataTestid,
    defaultPicture = placeholder
}) => {
    const [avatarUrl, setAvatarUrl] = useState(photoUrl);

    useEffect(() => {
        if (photoUrl !== avatarUrl) {
            setAvatarUrl(photoUrl);
        }
    }, [photoUrl, setAvatarUrl]);

    const handleError = () => {
        setAvatarUrl(defaultPicture);
    };

    return (
        <img
            data-testid={dataTestid}
            className={className}
            src={avatarUrl ?? defaultPicture}
            onError={handleError}
            onClick={onClick}
        />
    );
};

export { Media };
