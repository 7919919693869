import React, { useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import { namesMapping } from 'people/utils/takesEffectOnModalHelper';

import { CustomButton } from './Button';
import { CloseButton } from './CloseButton';
import { CustomDateSelector } from './DatePicker';
import { StyledTakesEffectModal } from './styled/TakesEffectModal.styled';

const INITIAL_VALUES = {
    takesEffectOn: null
};

const TakesEffectModal = ({
    initialValues = INITIAL_VALUES,
    visible,
    setVisible,
    title,
    handleSubmit,
    onCancel = () => {},
    valueForChangeName,
    startWorkingPeriodDate,
    endWorkingPeriodDate,
    className
}) => {
    useEffect(() => {
        const takesEffectDatePicker = document.querySelector(
            "[data-testid='takesEffectOn']"
        );
        var clickEvent = document.createEvent('MouseEvents');
        clickEvent.initEvent('mousedown', true, true);
        takesEffectDatePicker.dispatchEvent(clickEvent);
    }, []);

    const handleClose = () => {
        setVisible(false);
        onCancel();
    };
    const [errors, setErrors] = useState({});

    const validate = values => {
        const foundErrors = !!values.takesEffectOn
            ? {}
            : { takesEffectOn: 'Takes effect on is required.' };
        setErrors(foundErrors);
    };

    const handleLocalSubmit = values => {
        validate(values);
        if (values.takesEffectOn) {
            handleSubmit(values.takesEffectOn);
        }
    };

    return (
        <Formik initialValues={initialValues} validate={validate}>
            {props => {
                const { values, setFieldValue } = props;

                return (
                    <Form>
                        <StyledTakesEffectModal
                            visible={visible}
                            setVisible={setVisible}
                            handleCustomCancel={handleClose}
                            title={[
                                <div
                                    className="custom-modal-header"
                                    key="date-modal-title"
                                >
                                    {title}
                                    <CloseButton handleClose={handleClose} />
                                </div>
                            ]}
                            footer={[
                                <div
                                    className="custom-modal-footer"
                                    key="date-modal-footer"
                                >
                                    <div className="button-section">
                                        <CustomButton
                                            text="Cancel"
                                            type="normal footer-button"
                                            onClick={handleClose}
                                        />
                                    </div>
                                    <div className="button-section">
                                        <CustomButton
                                            text="Set Date"
                                            disabled={
                                                values.takesEffectOn == null
                                            }
                                            type="filled footer-button"
                                            onClick={() =>
                                                handleLocalSubmit(values)
                                            }
                                        />
                                    </div>
                                </div>
                            ]}
                            className={className}
                        >
                            {valueForChangeName && (
                                <div className="section-title">
                                    {namesMapping[valueForChangeName].label}
                                </div>
                            )}
                            <CustomDateSelector
                                value={values.takesEffectOn}
                                label="Date"
                                name="takesEffectOn"
                                onChange={setFieldValue}
                                allowClear={false}
                                disabledDate={startWorkingPeriodDate}
                                disabledFutureDate={endWorkingPeriodDate}
                                className={
                                    errors?.takesEffectOn && 'error-input'
                                }
                            />
                        </StyledTakesEffectModal>
                    </Form>
                );
            }}
        </Formik>
    );
};

export { TakesEffectModal };
