import styled from 'styled-components';

const StyledBenefit = styled.div`
    .custom-upload-div {
        width: 25%;
        margin-bottom: 14px;
    }
    .ant-upload-list-item-card-actions-btn {
        opacity: 1;
    }
    .ant-select-selection-search-input {
        width: 226px !important;
    }
    .custom-upload-div .custom-upload-label {
        font-family: 'Source-Sans-Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        padding-bottom: 4px;
    }
    .anticon > svg {
        fill: var(--secondaryBlue);
    }
`;

export { StyledBenefit };
