import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CustomDateSelector } from 'shared/components/DatePicker';
import { CustomInput } from 'shared/components/Input';
import { setClassName } from 'utils/componentPropHelpers';
import { mapDataForDropdown } from 'utils/dropdownHelper';

import StyledBenefitPriceSection from './styled/BenefitPriceSection.styled';
import { fetchBenefitPriceGroupsDropDown } from '../../../../redux/slices/dropdown';
import { FieldsSection } from '../../../../shared/components/FieldsSection';
import { CustomSelect } from '../../../../shared/components/Select';

const BenefitPriceSection = ({
    onChange,
    values,
    benefitPriceId,
    benefitPriceErrors,
    shouldDisableFields,
    disabledStartDate,
    isEndDateRequired,
    disabledEndDate,
    setFieldTouched,
    touched
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchBenefitPriceGroupsDropDown());
    }, [dispatch]);

    const [benefitPriceGroups] = useSelector(state => [
        state.dropdown.benefitPriceGroups
    ]);

    const benefitPriceGroupOptions = mapDataForDropdown(benefitPriceGroups);

    return (
        <div data-testid="inner-relative-section">
            <FieldsSection>
                <StyledBenefitPriceSection>
                    <CustomInput
                        label={`Price ${shouldDisableFields ? '' : '*'}`}
                        placeholder="Type..."
                        value={values.benefitPrice}
                        className={setClassName(
                            touched.benefitPrice &&
                                benefitPriceErrors?.benefitPrice
                        )}
                        name={'benefitPrice'}
                        isNormalChange={false}
                        onChange={onChange}
                        allowClear={true}
                        disabled={shouldDisableFields}
                        onBlur={() => setFieldTouched('benefitPrice')}
                        onFocus={() => setFieldTouched('benefitPrice', false)}
                    />
                    <CustomSelect
                        value={values.benefitPriceGroupId}
                        label="AHI Group"
                        name="benefitPriceGroupId"
                        placeholder="Select from the dropdown"
                        allowClear={true}
                        className={setClassName(
                            touched.benefitPriceGroupId &&
                                benefitPriceErrors?.benefitPriceGroupId
                        )}
                        options={benefitPriceGroupOptions}
                        onChange={onChange}
                        onBlur={() => setFieldTouched('benefitPriceGroupId')}
                        onFocus={() =>
                            setFieldTouched('benefitPriceGroupId', false)
                        }
                    />
                    <CustomDateSelector
                        value={values.startDate}
                        label={`Start Date ${shouldDisableFields ? '' : '*'}`}
                        name="startDate"
                        id={`startDate${benefitPriceId}`}
                        className={setClassName(
                            touched.startDate && benefitPriceErrors?.startDate
                        )}
                        onChange={onChange}
                        disabled={shouldDisableFields}
                        disabledDate={disabledStartDate}
                        onBlur={() => setFieldTouched('startDate')}
                    />
                    <CustomDateSelector
                        value={values.endDate}
                        label={`End Date ${isEndDateRequired ? '*' : ''}`}
                        name="endDate"
                        id={`endDate${benefitPriceId}`}
                        className={setClassName(
                            touched.endDate && benefitPriceErrors?.endDate
                        )}
                        disabledDate={values.startDate}
                        disabledFutureDate={disabledEndDate}
                        disabledDateIncluding
                        onChange={onChange}
                        disabled={shouldDisableFields}
                        onBlur={() => setFieldTouched('endDate')}
                    />
                </StyledBenefitPriceSection>
            </FieldsSection>
        </div>
    );
};

export { BenefitPriceSection };
