import React from 'react';

const TabItem = props => {
    const onClick = () => {
        const { label, onClick } = props;
        onClick(label);
    };

    const { activeTab, label, disabled } = props;

    let className = 'tab-list-item';

    if (activeTab === label) {
        className += ' tab-list-active';
    }

    if (disabled == true) {
        className += ' disabled';
    }

    return (
        <li className={className} onClick={onClick}>
            {label}
        </li>
    );
};

export default TabItem;
