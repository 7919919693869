import React, { useState, useEffect } from 'react';

import { DatePicker, Button } from 'antd';
import moment from 'moment';

import { StyledPeriodPicker } from '../styled/PeriodPicker.styled';

const LOCALE = 'en';
const FORMAT = 'MM/DD/YYYY';

const PeriodPicker = ({
    onChange,
    value,
    name,
    label = '',
    onBlur = () => {},
    disabledDate,
    allowClear = false,
    validateDateInFuture,
    showFooter,
    ...rest
}) => {
    const initialVal = value;
    const [open, setOpen] = useState(false);
    const [tempDate, setTempDate] = useState(null);
    const [buttonVal, setButtonVal] = useState(null);
    const [isInside, setIsInside] = useState(false);

    //set temp date to the initial value if it exists
    useEffect(() => {
        if (initialVal) {
            setTempDate(moment(initialVal));
        }
    }, []);

    //trigger handle change when the button value is changed & when temp date exists
    useEffect(() => {
        if (tempDate) {
            handleChange(tempDate);
        }
    }, [buttonVal]);

    //handle outside of datepicker click closure of picker area
    useEffect(() => {
        const handleMouseOut = () => {
            if (!isInside) {
                setOpen(false);
            }
        };
        document.addEventListener('click', handleMouseOut);
        return () => {
            document.removeEventListener('click', handleMouseOut);
        };
    }, [isInside]);

    const handleChange = val => {
        if (val) {
            //if val is the same as temp date
            if (tempDate && val.format(FORMAT) == tempDate.format(FORMAT)) {
                setTempDate(val);
                onChange(name, val.format(FORMAT), buttonVal);
            } else {
                setTempDate(val);
                setButtonVal(null);
                onChange(name, val.format(FORMAT), null);
            }
        } else {
            setTempDate(null);
            setButtonVal(null);
            onChange(name, val, null);
        }
    };

    const handleButtonChange = (e, name, value) => {
        setButtonVal(name);

        if (tempDate) {
            if (value !== tempDate.format(FORMAT)) {
                onChange(name, value.format(FORMAT), buttonVal);
            }
        }

        setOpen(false);
    };

    const handleMouseOver = e => {
        if (e.target) {
            setIsInside(true);
        }
    };

    const handleMouseLeave = e => {
        if (e.target) {
            setIsInside(false);
        }
    };

    const handleDisableDate = current => {
        if (validateDateInFuture) {
            return current && current < moment(disabledDate).endOf('day');
        }
        return current && current >= moment(disabledDate).startOf('day');
    };

    return (
        <StyledPeriodPicker>
            <div
                className="period-picker-container"
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}
            >
                {label && <div className="label">{label}</div>}
                <DatePicker
                    className="period-picker"
                    dropdownClassName="period-picker-dropdown"
                    allowClear={allowClear}
                    shouldCloseOnSelect={false}
                    value={value && moment(value)}
                    onChange={handleChange}
                    locale={LOCALE}
                    format={FORMAT}
                    disabledDate={disabledDate && handleDisableDate}
                    renderExtraFooter={() => (
                        <>
                            {showFooter && value && (
                                <div className="range-picker-btn-footer">
                                    <Button
                                        size="small"
                                        name="before"
                                        className={`${
                                            buttonVal === 'before'
                                                ? 'clicked'
                                                : ''
                                        }`}
                                        onClick={e =>
                                            handleButtonChange(
                                                e,
                                                'before',
                                                value
                                            )
                                        }
                                    >
                                        Before
                                    </Button>
                                    <Button
                                        size="small"
                                        name="after"
                                        className={`${
                                            buttonVal === 'after'
                                                ? 'clicked'
                                                : ''
                                        }`}
                                        onClick={e =>
                                            handleButtonChange(
                                                e,
                                                'after',
                                                value
                                            )
                                        }
                                    >
                                        After
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                    showToday={false}
                    open={open}
                    onOpenChange={() => {
                        setOpen(true);
                    }}
                />
            </div>
        </StyledPeriodPicker>
    );
};

export { PeriodPicker };
