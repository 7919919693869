/* eslint-disable prettier/prettier */
import styled from 'styled-components';

const StyledOffersTab = styled.div`
    .all-offers-container {
        .table-wrapper {
            margin-left: -20px;
            margin-right: -15px;
        }
        .inactive-offers-button {
            height: 34px;
            width: 68px;
            color: #03a8f5;
            font-weight: 900;
            margin-left: 10px !important;

            :hover {
                color: #1c84fe;
            }
        }

        .inactive-offers-button-active {
            height: 34px;
            width: 68px;
            color: white;
            background-color: #03a8f5 !important;
            font-weight: 900;
            margin-left: 10px !important;
        }
    }

    .active-button-container {
        flex-grow: 10;
    }

    @media screen and (max-width: 768px) {
        .table-wrapper {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    .all-offers-container {
        width: 100%;
        padding-top: 10px;
    }
    .table-name-section {
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;
    }
    .name-info {
        padding-left: 10px;
    }
    .bold-name {
        font-weight: 600;
        color: var(--darkNavy);
    }
    .pointer > tr {
        margin: 10px;
    }
    .offers-extra-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .visibility-cell {
        margin: 0 auto;
    }
`;

export { StyledOffersTab };
