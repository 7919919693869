import React from 'react';

import { Tooltip, Switch } from 'antd';
import moment from 'moment';
import { Media } from 'shared/components/Media';
import { EditIcon } from 'shared/theme/assets/icons/EditIcon';
import blankImage from 'shared/theme/assets/images/blank.png';

import { StyledIcons } from './styled/StyledIcons.styled';
import { getColumnWidth, allBenefitsBackgroundHelper } from './tableUtil';

const FORMAT = 'MM/DD/YYYY';

export const createBenefitTableColumns = (
    benefits,
    handleOpenEdit,
    handleToggleVisibility,
    permissions
) => {
    return [
        {
            Header: 'Visibility',
            id: 'visibility',
            accessor: 'visibility',
            Cell: ({ original }) => (
                <div className="visibility-cell">
                    <Switch
                        checked={original.selectable}
                        onChange={() =>
                            handleToggleVisibility(original.benefitTypeId)
                        }
                        disabled={!original?.active}
                    />
                </div>
            ),
            width: 100,
            sortable: false,
            fixed: 'left',
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            Cell: ({ original }) => (
                <div className="table-name-section">
                    <Media
                        className={'wide-avatar'}
                        photoUrl={original.photo}
                        defaultPicture={blankImage}
                    />
                    <div className="tooltip-span-text">
                        <Tooltip title={original.name} placement="bottomLeft">
                            <span>{original.name}</span>
                        </Tooltip>
                    </div>
                </div>
            ),
            width:
                benefits && benefits.length > 0
                    ? getColumnWidth(benefits, 'Name')
                    : 325,
            resizable: false,
            sortable: true,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Start Date',
            id: 'startDate',
            accessor: 'startDate',
            Cell: ({ original }) => (
                <>
                    {original.startDate
                        ? moment(original.startDate).format(FORMAT)
                        : '-'}
                </>
            ),
            resizable: true,
            sortable: false,
            minWidth: 90,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'End Date',
            id: 'endDate',
            accessor: 'endDate',
            Cell: ({ original }) => (
                <>
                    {original.endDate
                        ? moment(original.endDate).format(FORMAT)
                        : '-'}
                </>
            ),
            resizable: true,
            sortable: true,
            minWidth: 90,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Price',
            id: 'price',
            accessor: 'price',
            Cell: ({ original }) => (
                <>
                    {original.price !== null
                        ? `${parseFloat(
                              Math.round(original.price * 100) / 100
                          ).toFixed(2)} BGN`
                        : '-'}
                </>
            ),
            resizable: true,
            sortable: false,
            minWidth: 90,
            maxWidth: 260,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Actions',
            id: 'actions',
            accessor: 'actions',
            Cell: ({ original }) => (
                <>
                    <StyledIcons>
                        <div className="icons">
                            <div
                                data-testid="benefit-type-edit"
                                className="icon icon-edit"
                                onClick={() => {
                                    permissions.canEditBenefitType
                                        ? handleOpenEdit(original.benefitTypeId)
                                        : undefined;
                                }}
                            >
                                <EditIcon />
                            </div>
                        </div>
                    </StyledIcons>
                </>
            ),
            resizable: true,
            sortable: false,
            minWidth: 90,
            maxWidth: 200,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        }
    ];
};

// This is used if we want to change background of each column row
const typeHandlerHelper = (state, rowInfo, column) => {
    return allBenefitsBackgroundHelper(state, rowInfo, column);
};
