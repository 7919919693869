import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { GeneralInformation } from './GeneralInformation';
import { RelativeInformation } from './RelativeInformation';
import { StyledPersonalInformation } from './styled/PersonalInformation.styled';
import { fetchPersonalInformationDropDowns } from '../../../../redux/slices/dropdown';
import {
    fetchPersonalInformationById,
    resetPersonalInformationState
} from '../../../../redux/slices/people';
import {
    fetchRelativeMembers,
    resetRelativeMemberState
} from '../../../../redux/slices/relativeMember';
import { Spinner } from '../../../../shared/components/Spinner';
import { DetailsMainContainer } from '../../shared/DetailsMainContainer';

const PersonalInformation = ({ personId, permissions }) => {
    const dispatch = useDispatch();
    const { isLoadingDropdownPersonalInfo } = useSelector(
        state => state.dropdown
    );
    const { personalInformation, isLoadingPersonalInformation } = useSelector(
        state => state.people
    );
    const { isLoading } = useSelector(state => state.relativeMembers);

    useEffect(() => {
        dispatch(fetchPersonalInformationDropDowns());
        dispatch(fetchPersonalInformationById(personId));
        dispatch(fetchRelativeMembers(personId));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetPersonalInformationState());
            dispatch(resetRelativeMemberState());
        };
    }, []);

    return (
        <DetailsMainContainer title={'Personal information'}>
            <StyledPersonalInformation>
                {isLoading ||
                isLoadingPersonalInformation ||
                isLoadingDropdownPersonalInfo ||
                !Object.keys(personalInformation).length ? (
                    <Spinner />
                ) : (
                    <>
                        <GeneralInformation
                            isSectionOpen={true}
                            initialValues={personalInformation}
                            personId={personId}
                            permissions={permissions}
                        />
                        <RelativeInformation
                            isSectionOpen={true}
                            personId={personId}
                            permissions={permissions}
                        />
                    </>
                )}
            </StyledPersonalInformation>
        </DetailsMainContainer>
    );
};

export { PersonalInformation };
