export const shouldRequestsPageTabBeDisabled = (label, permissions) => {
    if (!permissions.canViewRequestsPage) {
        return true;
    }

    if (label == 'Pending') {
        return !permissions.canViewRequestsPagePendingTab;
    }

    if (label == 'Approved') {
        return !permissions.canViewRequestsPageApprovedTab;
    }

    if (label == 'Delivered') {
        return !permissions.canViewRequestsPageDeliveredTab;
    }

    if (label == 'Rejected') {
        return !permissions.canViewRequestsPageRejectedTab;
    }

    if (label == 'Cancelled') {
        return !permissions.canViewRequestsPageCancelledTab;
    }

    return true;
};

export const setDefaultTabRequestsPage = (tabNumber, permissions) => {
    const tabLabelMappingRequestsPage = {
        0: 'Pending',
        1: 'Approved',
        2: 'Delivered',
        3: 'Rejected',
        4: 'Cancelled'
    };

    if (
        !shouldRequestsPageTabBeDisabled(
            tabLabelMappingRequestsPage[tabNumber],
            permissions
        )
    ) {
        return tabNumber;
    }

    for (const tabNumber in tabLabelMappingRequestsPage) {
        if (
            Object.hasOwnProperty.call(tabLabelMappingRequestsPage, tabNumber)
        ) {
            const label = tabLabelMappingRequestsPage[tabNumber];

            if (!shouldRequestsPageTabBeDisabled(label, permissions)) {
                return Number(tabNumber);
            }
        }
    }

    return 0;
};
