import styled from 'styled-components';

import { CustomModal } from '../../../../shared/components/Modal';

const StyledPersonModal = styled(CustomModal)`
    width: 62% !important;
    transition: all;
    .ant-spin {
        padding: 30px 0px 30px 0px !important;
        display: flex;
        justify-content: center;
    }
    .custom-modal-header {
        display: flex;
        justify-content: space-between;
    }
    .custom-modal-footer {
        display: flex;
        justify-content: flex-end;
        .footer-button {
            width: 100% !important;
            height: 100% !important;
            .button-inner {
                padding: 12px 56px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
            }
        }
        .button-section:first-child {
            padding-right: 10px;
        }
    }
    .modal-body-section {
        padding-top: 20px;
        border-bottom: 1px solid var(--whiteGray);
        .section-splitter {
            padding-bottom: 20px;
        }
        .section-title {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            padding-bottom: 20px;
        }
    }
    @media screen and (max-width: 768px) {
        width: 590px !important;
    }
    @media screen and (max-width: 426px) {
        width: 415px !important;
    }
`;

export { StyledPersonModal };
