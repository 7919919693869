import { useState, useEffect } from 'react';

import Icon from '@ant-design/icons/lib/components/Icon';
import FilterArrowDownIcon from 'shared/theme/assets/icons/FilterArrowDownIcon';
import SelectIcon from 'shared/theme/assets/icons/SelectIcon';

import FiltersBox from './FiltersBox';
import FilterContainer from '../styled/FilterContainer.styled';

const FilterButton = () => {
    const [showFilter, setShowFilters] = useState(false);
    const [isInside, setIsInside] = useState(false);

    const handleClick = () => {
        setShowFilters(!showFilter);
    };

    const handleClose = () => {
        setShowFilters(false);
    };

    useEffect(() => {
        const handleMouseOut = () => {
            if (showFilter && !isInside) {
                setShowFilters(false);
            }
        };
        document.addEventListener('click', handleMouseOut);
        return () => {
            document.removeEventListener('click', handleMouseOut);
        };
    }, [showFilter, isInside]);

    const onEnter = () => {
        setIsInside(true);
    };

    const onLeave = () => {
        setIsInside(false);
    };

    return (
        <FilterContainer>
            <button
                className={`filterButton ${showFilter ? 'open' : 'closed'}`}
                onClick={handleClick}
                type="button"
            >
                Filter
                {showFilter ? (
                    <FilterArrowDownIcon />
                ) : (
                    <Icon component={SelectIcon} />
                )}
            </button>
            <FiltersBox
                isVisible={showFilter}
                onClose={handleClose}
                handleEnter={onEnter}
                handleLeave={onLeave}
            />
        </FilterContainer>
    );
};

export default FilterButton;
