import {
    openIdRegex,
    positionRegex
} from '../../../../shared/constants/regexConstants';

export const validateForm = ({
    workingTime,
    trialPeriod,
    workLocation,
    positionTitle,
    hiringChannelText
}) => {
    let errors = {};

    const intRegexExp = new RegExp(openIdRegex);
    if (workingTime) {
        let workingTimeError;
        if (!intRegexExp.test(workingTime)) {
            workingTimeError = `Working time can contains only numbers.`;
        } else if (parseInt(workingTime) < 0 || parseInt(workingTime) > 16) {
            workingTimeError = 'Working time is between 0 and 16 hours.';
        }
        errors = { ...errors, workingTime: workingTimeError };
    }
    if (trialPeriod) {
        let trialPeriodError;
        if (!intRegexExp.test(trialPeriod)) {
            trialPeriodError = `Working time can contains only numbers.`;
        } else if (parseInt(trialPeriod) < 0 || parseInt(trialPeriod) > 24) {
            trialPeriodError = 'Trial period is between 0 and 24 hours.';
        }
        errors = { ...errors, trialPeriod: trialPeriodError };
    }
    if (workLocation) {
        let workLocationError;
        if (workLocation?.trim() === '') {
            workLocationError = `Work location cannot contains only spaces.`;
        } else if (workLocation?.length > 50) {
            workLocationError = 'Work location is too long.';
        }
        errors = { ...errors, workLocation: workLocationError };
    }
    if (positionTitle) {
        let positionTitleError;
        const positionRegExp = new RegExp(positionRegex);
        if (positionTitle?.trim() === '') {
            positionTitleError = `Position cannot contain only spaces.`;
        } else if (positionTitle.length > 100) {
            positionTitleError = 'Position is too long.';
        } else if (!positionRegExp.test(positionTitle)) {
            positionTitleError = 'Position contains invalid characters.';
        }
        errors = { ...errors, positionTitle: positionTitleError };
    }
    if (hiringChannelText) {
        let hiringChannelTextError;
        if (hiringChannelText?.trim() === '') {
            hiringChannelTextError = `Hiring channel cannot contains only spaces.`;
        } else if (hiringChannelText?.length > 50) {
            hiringChannelTextError = 'Hiring channel is too long.';
        }
        errors = { ...errors, hiringChannelText: hiringChannelTextError };
    }

    return errors;
};

export const selectedPosition = (positions, inputPosition) => {
    return positions.find(p => {
        const desc = p.description?.replace(/\s\s+/g, ' ');
        const incomePosition = inputPosition?.replace(/\s\s+/g, ' ');
        return (
            desc?.toLowerCase().trim() === incomePosition?.toLowerCase().trim()
        );
    });
};
