import {
    bulgarianLettersRegex,
    nameRegex
} from '../../../../shared/constants/regexConstants';

export const validateForm = ({ name, nameBg, personalId }) => {
    let errors = {};

    if (name) {
        let nameError;
        const nameRegExp = new RegExp(nameRegex);
        if (name?.trim() === '') {
            nameError = 'Name is required.';
        } else if (name.length > 40) {
            nameError = 'Name is too long.';
        } else if (!nameRegExp.test(name)) {
            nameError = 'Name contains invalid characters.';
        }
        errors = { ...errors, name: nameError };
    }
    if (nameBg) {
        let nameBgError;
        const bgLettersRegex = new RegExp(bulgarianLettersRegex);
        if (nameBg?.trim() === '') {
            nameBgError = `Bulgarian Name cannot contain only spaces.`;
        } else if (nameBg.length > 40) {
            nameBgError = 'Bulgarian Name is too long.';
        } else if (!bgLettersRegex.test(nameBg)) {
            nameBgError = 'Bulgarian Name contains invalid characters.';
        }
        errors = { ...errors, nameBg: nameBgError };
    }
    if (personalId) {
        let personalIdError;
        if (personalId?.trim() === '') {
            personalIdError = `Personal Id cannot contain only spaces.`;
        }
        errors = { ...errors, personalId: personalIdError };
    }

    return errors;
};
