import React from 'react';

import { Tooltip, Switch } from 'antd';
import moment from 'moment';
import { Media } from 'shared/components/Media';
import { EditIcon } from 'shared/theme/assets/icons/EditIcon';
import { TrashIcon } from 'shared/theme/assets/icons/TrashIcon';
import blankImage from 'shared/theme/assets/images/blank.png';

import { StyledIcons } from './styled/StyledIcons.styled';
import { getColumnWidth, allBenefitsBackgroundHelper } from './tableUtil';

const FORMAT = 'MM/DD/YYYY';

const getDiscountRate = original => {
    const discountRateMinimum = original.discountMinimum.toFixed(2);
    const discountRateMaximum = original.discountMaximum?.toFixed(2) ?? '';

    return `${discountRateMinimum}% ${
        discountRateMaximum ? '   -   ' + discountRateMaximum + '%' : ''
    }`;
};

export const createDiscountTableColumns = (
    discounts,
    handleOpenEdit,
    handleOpenDelete,
    handleToggleVisibility,
    permissions,
    areDiscountsActive = true
) => {
    return [
        {
            Header: 'Visibility',
            id: 'visibility',
            accessor: 'visibility',
            Cell: ({ original }) => (
                <div className="visibility-cell">
                    <Switch
                        checked={original.visibleInMNet}
                        onChange={() => handleToggleVisibility(original.id)}
                        disabled={!areDiscountsActive}
                    />
                </div>
            ),
            width: 100,
            sortable: false,
            fixed: 'left',
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            Cell: ({ original }) => (
                <div className="table-name-section">
                    <Media
                        className={'wide-avatar'}
                        photoUrl={original.image}
                        defaultPicture={blankImage}
                    />
                    <div className="tooltip-span-text">
                        <Tooltip title={original.name} placement="bottomLeft">
                            <span>{original.name}</span>
                        </Tooltip>
                    </div>
                </div>
            ),
            width:
                discounts && discounts.length > 0
                    ? getColumnWidth(discounts, 'Name')
                    : 325,
            resizable: true,
            sortable: true,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Location',
            id: 'location',
            accessor: 'location',
            Cell: ({ original }) => (
                <div className="table-name-section">
                    <div className="tooltip-span-text">
                        <div style={{ textWrap: 'wrap' }}>
                            {' '}
                            {original.locations != null
                                ? original.locations.map(x => x.name).join(', ')
                                : ''}
                        </div>
                    </div>
                </div>
            ),
            width: 200,
            resizable: false,
            sortable: false,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Updated On',
            id: 'updatedOn',
            accessor: 'updatedOn',
            Cell: ({ original }) => (
                <>{moment(original.updatedOn).format(FORMAT)} </>
            ),
            resizable: true,
            sortable: true,
            minWidth: 125,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Created On',
            id: 'createdOn',
            accessor: 'createdOn',
            Cell: ({ original }) => (
                <>{moment(original.createdOn).format(FORMAT)} </>
            ),
            resizable: false,
            sortable: false,
            minWidth: 125,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'How Much',
            id: 'howMuch',
            accessor: 'howMuch',
            Cell: ({ original }) => <>{getDiscountRate(original)} </>,
            width: 325,
            resizable: false,
            sortable: false,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Actions',
            id: 'actions',
            accessor: 'actions',
            Cell: ({ original }) => (
                <>
                    <StyledIcons>
                        <div className="icons">
                            <div
                                data-testid="discount-type-edit"
                                className="icon icon-edit"
                                onClick={() => {
                                    permissions.canEditDiscount
                                        ? handleOpenEdit(true, original.id)
                                        : undefined;
                                }}
                            >
                                <EditIcon />
                            </div>
                            {permissions.canDeleteDiscount &&
                                !areDiscountsActive && (
                                    <div
                                        data-testid="discount-delete"
                                        className="icon icon-delete"
                                        onClick={() =>
                                            handleOpenDelete(original)
                                        }
                                    >
                                        <TrashIcon />
                                    </div>
                                )}
                        </div>
                    </StyledIcons>
                </>
            ),
            resizable: true,
            sortable: false,
            minWidth: 90,
            maxWidth: 200,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        }
    ];
};

// This is used if we want to change background of each column row
const typeHandlerHelper = (state, rowInfo, column) => {
    return allBenefitsBackgroundHelper(state, rowInfo, column);
};
