import { useState, useEffect } from 'react';

import { DatePicker } from 'antd';
import moment from 'moment';

import StyledCustomMonthYearPicker from './styled/MonthYearPicker.styled';

const LOCALE = 'en';
const FORMAT = 'YYYY';

const CustomYearPicker = ({
    name,
    placeholder,
    label,
    value,
    onChange,
    disabled,
    allowClear
}) => {
    const handleChange = val => {
        if (val) {
            onChange(name, val.format(FORMAT), null);
        } else {
            onChange(name, val, null);
        }
    };

    return (
        <StyledCustomMonthYearPicker>
            <div className={`custom-month-year-picker`}>
                {label && <div className="picker-label">{label}</div>}
                <DatePicker
                    name={name}
                    picker="year"
                    value={value && moment(value)}
                    onChange={handleChange}
                    format={FORMAT}
                    locale={LOCALE}
                    placeholder={placeholder}
                    disabled={disabled}
                    allowClear={allowClear}
                />
            </div>
        </StyledCustomMonthYearPicker>
    );
};

export default CustomYearPicker;
