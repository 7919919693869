import styled from 'styled-components';

const StyledCustomMonthYearPicker = styled.div`
    .custom-month-year-picker {
        margin-top: 12px;
        width: 240px;
        margin-bottom: 14px;
        .picker-label {
            font-family: 'Source-Sans-Pro';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            padding-top: 4px;
            padding-bottom: 4px;
        }
        .ant-picker {
            width: 100%;
            height: 40px;
            background: var(--primaryWhite);
            border: 1px solid var(--gray);
            box-sizing: border-box;
            border-radius: 8px;
            .ant-picker-input {
                .anticon {
                    svg {
                        fill: var(--mainBlue);
                        width: 18px;
                        height: 18px;
                    }
                }
                .ant-picker-clear {
                    display: flex;
                    align-items: center;
                    margin-right: 23px;
                }
            }
        }
    }
    .error-input .ant-picker {
        border-color: var(--red);
    }
`;

export default StyledCustomMonthYearPicker;
