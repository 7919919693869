import React from 'react';

import { ColumnCard } from '../../../../shared/theme/assets/icons/ColumnCard';
import { ColumnVertical } from '../../../../shared/theme/assets/icons/ColumnVertical';

export const LayoutButtons = ({ activeLayout, onLayoutChange }) => {
    return (
        <div className="view-buttons">
            <div
                data-testid="table-layout-button"
                className={`view-button pointer ${
                    activeLayout === 'base' ? 'is-selected' : ''
                }`}
                onClick={() => onLayoutChange('base')}
            >
                <ColumnVertical />
            </div>
            <div
                data-testid="card-layout-button"
                className={`view-button pointer ${
                    activeLayout === 'card' ? 'is-selected' : ''
                }`}
                onClick={() => onLayoutChange('card')}
            >
                <ColumnCard />
            </div>
        </div>
    );
};
