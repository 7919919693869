import styled from 'styled-components';

import { CustomModal } from '../Modal';

const StyledTakesEffectModal = styled(CustomModal)`
    max-width: 361px;
    .ant-modal-header {
        margin: 0px 20px;
        padding-top: 21px;
        padding-bottom: 17px;
        .custom-modal-header {
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            font-size: 18px;
            line-height: 23px;
            .icon-holder {
                position: absolute;
                right: 20px;
            }
        }
    }
    .ant-modal-body {
        margin: 0px 20px;
        .modal-content {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .section-title {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            padding-bottom: 20px;
        }
    }
    .ant-modal-footer {
        margin: 0px 20px;
        border-top: none;
        padding: 18px 0px;
        .custom-modal-footer {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .button-section {
                .button {
                    width: 155px;
                    height: 34px;
                }
            }
        }
    }
`;

export { StyledTakesEffectModal };
