import { Tooltip } from 'antd';

const BenefitsTooltip = ({ children, benefits }) => {
    return (
        <Tooltip
            overlayStyle={{ maxWidth: 'none' }}
            overlayClassName="tooltip-overlay"
            overlayInnerStyle={{ maxWidth: 400 }}
            title={
                <ul>
                    {benefits.map(({ benefitName, benefitCoverPeriodId }) => (
                        <li key={benefitCoverPeriodId}>{benefitName}</li>
                    ))}
                </ul>
            }
            placement="top"
        >
            {children}
        </Tooltip>
    );
};

export default BenefitsTooltip;
