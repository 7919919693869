import { useState } from 'react';

import Icon from '@ant-design/icons';
import { Select } from 'antd';

import { StyledCustomSelect } from './styled/CustomSelect.styled';
import { SelectIcon } from '../theme/assets/icons/SelectIcon';
import { SelectIconUp } from '../theme/assets/icons/SelectIconUp';

const { Option } = Select;

const DEFAULT_FILTER_OPTIONS = (input, option) => {
    return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const SelectWithCreate = ({
    label = '',
    value,
    onChange = () => {}, // setFieldValue needed when used in formik
    disabled = false,
    name,
    placeholder = '',
    options = [],
    allowClear = false,
    onBlur = () => {},
    setFieldTouched = () => {},
    filterOption = DEFAULT_FILTER_OPTIONS,
    id,
    onSearch,
    onInputKeyDown,
    searchValue,
    ...rest
}) => {
    const { hideSuffix, className, onSelect } = rest;
    const [isOpen, setIsOpen] = useState(false);
    const handleChange = (val, option) => {
        onChange(name, val, option);
    };
    const suffix = () => {
        if (hideSuffix) {
            return null;
        }

        return suffixIcon;
    };

    const suffixIcon = isOpen ? (
        <Icon component={SelectIconUp} />
    ) : (
        <Icon component={SelectIcon} />
    );

    const selectOptions = options.map((x, i) => (
        <Option
            key={i}
            value={x.value}
            title={x.title}
            className={x.isActive}
            disabled={x.disabled}
        >
            {x.label}
        </Option>
    ));

    const computedClassName = className ? ` ${className}` : '';

    return (
        <>
            <StyledCustomSelect>
                <div className={`custom-select-div${computedClassName}`}>
                    {label && (
                        <label htmlFor={id} className="custom-select-label">
                            {label}
                        </label>
                    )}

                    <Select
                        onInputKeyDown={onInputKeyDown}
                        showSearch={true}
                        data-testid={name}
                        id={id}
                        name={name}
                        placeholder={placeholder}
                        disabled={disabled}
                        value={value === '' ? undefined : value}
                        onChange={handleChange}
                        suffixIcon={suffix()}
                        onDropdownVisibleChange={setIsOpen}
                        virtual={false}
                        allowClear={allowClear}
                        onSelect={onSelect}
                        onSearch={onSearch}
                        searchValue={searchValue}
                        filterOption={filterOption}
                        onBlur={() => {
                            setFieldTouched(name);
                            onBlur();
                        }}
                        onFocus={() => {
                            setFieldTouched(name, false);
                        }}
                    >
                        {selectOptions}
                    </Select>
                </div>
            </StyledCustomSelect>
        </>
    );
};
