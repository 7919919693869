import React from 'react';

function AddUserIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="16"
            fill="none"
            viewBox="0 0 21 16"
        >
            <path
                stroke="#03A8F5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13.612 15v-1.556a3.11 3.11 0 00-3.111-3.11H5.056a3.111 3.111 0 00-3.11 3.11V15M7.778 7.222a3.111 3.111 0 100-6.222 3.111 3.111 0 000 6.222zM16.722 4.889v4.667M19.055 7.222h-4.667"
            ></path>
        </svg>
    );
}

export default AddUserIcon;
