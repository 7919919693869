import React from 'react';

import { StyledDetailsMainContainer } from './styled/DetailsMainContainer.styled';

const DetailsMainContainer = ({
    title,
    extraTitleRightElement = () => {},
    children
}) => {
    return (
        <StyledDetailsMainContainer>
            <div className="info-header">
                <div>
                    <div className="info-header-title">{title}</div>
                    <div className="info-heder-info">
                        The information is saved automatically.
                    </div>
                </div>
                {extraTitleRightElement()}
            </div>
            {children}
        </StyledDetailsMainContainer>
    );
};

export { DetailsMainContainer };
