export const shouldTabBeDisabled = (label, permissions) => {
    if (!permissions.canViewReportsPage) {
        return true;
    }

    if (label == 'All') {
        return !permissions.canViewPowerBIReportsTab;
    }

    if (label == 'Benefits') {
        return !permissions.canViewBenefitsTab;
    }

    return true;
};

export const setDefaultTab = permissions => {
    if (permissions.canViewPowerBIReportsTab) {
        return 0;
    }
    if (permissions.canViewBenefitsTab) {
        return 1;
    }

    return 0;
};
