import styled from 'styled-components';

const StyledSubsetTabFilter = styled.div`
    .filter-toggle-div {
        width: 88px;
        height: 34px;
        cursor: pointer;
        padding: 6px 10px 6px 16px;
        border: 1px solid #03a8f5;
        border-radius: 10px;
    }
    .filter-toggle-div.visible {
        background-color: var(--mainBlue);
        color: var(--primaryWhite);
    }
    .filter-toggle-div.non-visible {
        background-color: var(--primaryWhite);
        color: var(--mainBlue);
    }

    .filters-box {
        position: relative;
        z-index: 5;
        width: 535px;
        top: 20px;
        right: 372px;
        background: #ffffff;
        box-shadow: 2px 10px 30px rgba(17, 17, 17, 0.1);
        border-radius: 10px;
        padding: 21px 20px;
    }
    .filters-box .filters-box-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 20px;
        margin-bottom: 10px;
    }
    .filters-box .filters-box-header .filters-box-heading {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
    }
    .filters-box
        .filters-box-header
        .filters-box-actions
        span.anticon.anticon-close {
        background: rgba(153, 153, 153, 0.16);
        border-radius: 6px;
        padding: 6px;
        color: var(--mainBlue);
    }
    .filters-box
        .filters-box-header
        .filters-box-actions
        span.anticon.anticon-close
        svg
        path {
        stroke-width: 50;
        stroke: var(--mainBlue);
    }
    .filters-box .filters-box-header .filters-box-actions svg {
        width: 8px;
        height: 8px;
    }
    .filters-box .filters-box-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        color: var(--darkNavy);
    }
    .filters-box .filters-box-footer {
        margin-top: 6px;
    }

    .selected-container {
        display: flex;
        flex-direction: row;
    }
    .selected-container .inner-tags {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        color: var(--mainBlue);
        width: 395px;
    }
    .selected-container .inner-tags .custom-tag {
        padding-bottom: 5px;
    }
    .selected-container .inner-tags .custom-tag .ant-tag {
        padding: 6px 10px;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: var(--mainBlue);
        background: rgba(28, 132, 254, 0.15);
        border-radius: 5px;
        border: 0px;
        margin-top: 4px;
    }
    .selected-container
        .inner-tags
        .custom-tag
        .ant-tag
        .ant-tag-close-icon
        svg {
        margin-bottom: 1px;
        color: var(--secondaryBlue);
    }
    .selected-container
        .inner-tags
        .custom-tag
        .ant-tag
        .ant-tag-close-icon
        svg
        path {
        stroke-width: 40;
        stroke: var(--secondaryBlue);
    }
    .selected-container .clear-filters-box {
        align-self: flex-start;
        color: var(--mainBlue);
        padding-left: 11px;
        border-left: 1px solid var(--gray);
        font-size: 16px;
        margin-top: 5px;
    }
    .selected-container .clear-filters-box .close-icon {
        display: inline-flex;
        padding-left: 5px;
    }
    .selected-container
        .clear-filters-box
        .close-icon
        span.anticon.anticon-close {
        background: rgba(153, 153, 153, 0.16);
        border-radius: 6px;
        padding: 6px;
        color: var(--mainBlue);
    }
    .selected-container
        .clear-filters-box
        .close-icon
        span.anticon.anticon-close
        svg
        path {
        stroke-width: 50;
        stroke: var(--mainBlue);
    }
    .selected-container .clear-filters-box .close-icon svg {
        width: 8px;
        height: 8px;
    }
`;

export default StyledSubsetTabFilter;
