import styled from 'styled-components';

const StyledTagsSection = styled.div`
    padding-top: 20px;
    .tags-benefits,
    .tags-columns,
    .tags-filters {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .tag-headers {
        font-size: 18px;
        line-height: 20px;
        padding-right: 10px;
        margin-bottom: 10px;
    }
    .custom-tag {
        margin-bottom: 10px;
        .ant-tag {
            padding: 4px 9px;
            font-size: 18px;
            line-height: 20px;
            color: var(--mainBlue);
            background: rgba(28, 132, 254, 0.15);
            border-radius: 8px;
            border-color: transparent;
            .anticon.anticon-close.ant-tag-close-icon {
                color: var(--secondaryBlue);
                svg {
                    width: 13px;
                    height: 13px;
                }
            }
        }
    }
`;

export default StyledTagsSection;
