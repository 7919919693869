import React from 'react';

const ShrinkIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            data-testid="shrink-icon"
        >
            <path stroke="#03A8F5" d="M5 10h10"></path>
            <rect
                width="19"
                height="19"
                x="0.5"
                y="0.5"
                stroke="#03A8F5"
                rx="4.5"
            ></rect>
        </svg>
    );
};

export { ShrinkIcon };
