import React from 'react';

import Icon from '@ant-design/icons';

import { CloseIcon } from '../../shared/theme/assets/icons/CloseIcon';

export const CloseButton = ({ handleClose }) => {
    return (
        <div
            className="icon-holder"
            onClick={handleClose}
            data-testid="modal-close-button"
        >
            <Icon component={CloseIcon} />
        </div>
    );
};
