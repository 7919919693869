/* istanbul ignore file */
import React from 'react';

import { CloseOutlined } from '@ant-design/icons';

import { ColourTag } from '../../../../shared/components/ColourTag';

export const SelectedFilterTags = ({
    selected,
    handleRemove,
    handleFiltersReset
}) => {
    const tags = selected.filter(el => el !== undefined);
    const elements = tags.map((selection, i) => {
        return (
            <ColourTag
                closable={true}
                text={selection?.label}
                handleOnClose={selection => handleRemove(selection)}
                selection={selection}
                key={selection?.label}
            />
        );
    });

    return (
        <>
            {elements && elements.length > 0 ? (
                <div className="selected-container">
                    <div className="inner-tags">{elements}</div>
                    <div
                        className="clear-filters-box"
                        onClick={handleFiltersReset}
                    >
                        Clear All{' '}
                        <div className="close-icon">
                            <CloseOutlined />
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};
