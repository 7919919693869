import React from 'react';

export const EditIcon = ({ strokeColor = '#000', width = 24, height = 24 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 2.828a2.827 2.827 0 114 4l-13.5 13.5-5.5 1.5 1.5-5.5L17 2.828z"
            ></path>
        </svg>
    );
};
