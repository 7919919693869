import React from 'react';

import { StyledReportTab } from './styled/ReportTab.styled';

export const NewReportTab = ({ reportElement }) => {
    // in the src of the iframe, filterPaneEnabled and navContentPaneEnabled are sent as query params
    // to hide the filters and the nav pane. remove if necessary for future releases
    return (
        <StyledReportTab>
            <div className="reports-container">
                <iframe
                    className="reports-iframe"
                    width="1100"
                    height="600"
                    src={process.env.REACT_APP_REPORTS_URL}
                    frameBorder="0"
                    allowFullScreen={true}
                ></iframe>
            </div>
        </StyledReportTab>
    );
};
