import React from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';

import { StyledGlobalSearch } from './styled/GlobalSearch.styled';

export const GlobalSearch = ({
    autoCompleteClassName,
    inputClassName,
    inputPlaceholder,
    options,
    value,
    shouldShowInput,
    autoCompleteOnChange,
    autoCompleteOnSelect,
    autoCompleteOnClick,
    autoCompleteOnPaste,
    inputOnBlur,
    inputOnKeyPress,
    allowClear
}) => {
    return (
        <StyledGlobalSearch>
            <div
                className={`header-search ${
                    shouldShowInput
                        ? 'header-visible-autocomplete'
                        : 'header-hidden-autocomplete'
                }`}
            >
                <AutoComplete
                    className={autoCompleteClassName}
                    options={options}
                    value={value}
                    onChange={autoCompleteOnChange}
                    onSelect={autoCompleteOnSelect}
                    onClick={autoCompleteOnClick}
                    onPaste={autoCompleteOnPaste}
                >
                    <Input
                        className={inputClassName}
                        style={{
                            width: shouldShowInput ? 250 : 40,
                            borderRadius: 10
                        }}
                        value={value}
                        placeholder={inputPlaceholder}
                        size="large"
                        onBlur={inputOnBlur}
                        onKeyPress={inputOnKeyPress}
                        prefix={<SearchOutlined />}
                        allowClear={allowClear}
                    />
                </AutoComplete>
            </div>
        </StyledGlobalSearch>
    );
};
