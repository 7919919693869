import styled from 'styled-components';

const StyledMain = styled.div`
    .main {
        padding: 32px var(--gutter);
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
    }
`;

export { StyledMain };
