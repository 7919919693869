import { truncate } from 'utils/truncate';

import BenefitsTooltip from './BenefitsTooltip';

const NUM_OF_DISPLAYED_BENEFITS = 3;

const CoveredByCell = ({ benefits, maxWidth }) => {
    if (benefits.length > 3) {
        return (
            <BenefitsTooltip benefits={benefits}>
                <ul
                    style={{
                        maxWidth
                    }}
                    className="list-cell"
                >
                    {benefits
                        .slice(0, NUM_OF_DISPLAYED_BENEFITS)
                        .map(({ benefitName, benefitCoverPeriodId }, index) =>
                            index !== 2 ? (
                                <li
                                    className="list-cell-item"
                                    style={{
                                        maxWidth
                                    }}
                                    key={benefitCoverPeriodId}
                                >
                                    {benefitName}
                                </li>
                            ) : (
                                <li
                                    className="list-cell-item"
                                    style={{
                                        maxWidth
                                    }}
                                    key={benefitCoverPeriodId}
                                >
                                    {truncate(
                                        benefitName,
                                        Math.floor(benefitName.length / 2)
                                    )}
                                </li>
                            )
                        )}
                </ul>
            </BenefitsTooltip>
        );
    }

    return (
        <BenefitsTooltip benefits={benefits}>
            <ul
                style={{
                    maxWidth
                }}
                className="list-cell"
            >
                {benefits.map(({ benefitName, benefitCoverPeriodId }) => (
                    <li
                        className="list-cell-item"
                        style={{
                            maxWidth
                        }}
                        key={benefitCoverPeriodId}
                    >
                        {benefitName}
                    </li>
                ))}
            </ul>
        </BenefitsTooltip>
    );
};

export default CoveredByCell;
