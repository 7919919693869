import React, { useContext, useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'shared/components/Spinner';
import PermissionsContext from 'shared/utils/permissionStore';

import { PersonModalExtension } from './PersonModalExtension';
import { StyledPersonModal } from './styled/PersonModal.styled';
import {
    createPerson,
    updatePerson,
    resetModalProps,
    checkPersonNameExists
} from '../../../redux/slices/people';
import { STATIC_ROUTES_PATHS } from '../../../routes';
import { CustomButton } from '../../../shared/components/Button';
import { CloseButton } from '../../../shared/components/CloseButton';
import { CustomConfirmationModal } from '../../../shared/components/ConfirmationModal';
import { FieldsSection } from '../../../shared/components/FieldsSection';
import { CustomInput } from '../../../shared/components/Input';
import {
    errorNotification,
    successNotification
} from '../../../shared/components/Notifications';
import {
    advancedDisableSubmitButton,
    disableSubmitButton,
    generatesNameConfirmationText,
    validateForm,
    validateWorkingPeriod,
    validateHiringChannel
} from '../../utils/personModalHelper';

const INITIAL_VALUES = {
    name: '',
    nameBg: '',
    workEmail: '',
    personalPhone: '',
    excluCVUrl: '',
    startDate: null,
    departmentId: undefined,
    levelId: undefined,
    typeOfWorkId: undefined,
    hiringChannelId: undefined,
    hiringChannelText: null,
    mentormaterId: undefined,
    locationId: undefined,
    workLocation: null,
    workingTime: null,
    trialPeriod: null,
    reportsToId: undefined,
    positionId: undefined
};

const PersonModal = ({
    history,
    isModalOpen,
    setIsModalOpen,
    title,
    initialValues = INITIAL_VALUES,
    action,
    personId
}) => {
    const permissions = useContext(PermissionsContext).permissions;

    const {
        error,
        showCreateUpdateMessage,
        createdPersonId,
        isLoadingPerson,
        namesExists,
        isLoadingExistenceCheck
    } = useSelector(state => state.people);

    const isLoadingDropdownState = useSelector(
        state => state.dropdown.isLoading
    );

    const dispatch = useDispatch();

    const [errors, setErrors] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [formState, setFormState] = useState({});
    const [confirmationExtraText, setConfirmationExtraText] = useState('');
    const isEditMode = action === 'updated';

    const hiddenValues = {
        personalPhone: initialValues.personalPhone
    };

    initialValues = {
        ...initialValues,
        personalPhone: permissions.canSeeContactInformationPhoneNumber
            ? initialValues.personalPhone
            : null
    };

    const validate = values => {
        let errorsIncome = validateForm(values);
        if (!isEditMode) {
            errorsIncome = {
                ...errorsIncome,
                ...validateWorkingPeriod(values),
                ...validateHiringChannel(values)
            };
            setErrors(errorsIncome);
            setDisabled(advancedDisableSubmitButton(values, errorsIncome));
        } else {
            setDisabled(disableSubmitButton(values, errorsIncome));
        }
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const handleClickSave = values => {
        setFormState(values);
        if (
            values.name !== initialValues.name ||
            (values.nameBg !== initialValues.nameBg && !isEmpty(values.nameBg))
        ) {
            const checkNamesRequestData = {
                name: values.name,
                nameBg: values.nameBg
            };
            dispatch(checkPersonNameExists(checkNamesRequestData));
        } else {
            handleSave(values);
        }
    };

    const handleSave = data => {
        if (isEditMode) {
            if (!permissions.canSeeContactInformationPhoneNumber) {
                data = { ...data, ...hiddenValues };
            }
            dispatch(updatePerson(data, personId));
        } else {
            dispatch(createPerson(data));
        }
    };

    useEffect(() => {
        const namesExistsArray = Object.values(namesExists);
        if (
            namesExistsArray.length > 0 &&
            !isLoadingExistenceCheck &&
            Object.keys(formState).length > 0
        ) {
            namesExistsArray[0] =
                namesExistsArray[0] && formState.name !== initialValues.name;
            namesExistsArray[1] =
                namesExistsArray[1] &&
                formState.nameBg !== initialValues.nameBg;

            if (namesExistsArray.some(x => x === true)) {
                generatesNameConfirmationText(
                    setConfirmationExtraText,
                    setIsConfirmationOpen,
                    namesExistsArray,
                    formState
                );
            } else {
                handleSave(formState);
            }
            return;
        }
    }, [namesExists, isLoadingExistenceCheck, formState]);

    useEffect(() => {
        if (showCreateUpdateMessage) {
            successNotification(`Successfully ${action} person`, 5);

            if (action !== 'updated') {
                history.push(
                    STATIC_ROUTES_PATHS.personDetails
                        .replace(':id', createdPersonId)
                        .replace(':activeTab', 'main-information')
                );
            }
            setIsModalOpen(false);
        }
    }, [showCreateUpdateMessage]);

    useEffect(() => {
        if (error) {
            errorNotification(error, 5);
            setIsConfirmationOpen(false);
        }
    }, [error]);

    useEffect(() => {
        return () => {
            dispatch(resetModalProps());
        };
    }, []);

    return (
        <Formik initialValues={initialValues} validate={validate}>
            {props => {
                const { values, handleChange, setFieldValue } = props;

                return (
                    <Form>
                        <StyledPersonModal
                            visible={isModalOpen}
                            setVisible={setIsModalOpen}
                            title={[
                                <div
                                    className="custom-modal-header"
                                    key="modal-title"
                                >
                                    {title}
                                    <CloseButton handleClose={handleClose} />
                                </div>
                            ]}
                            footer={[
                                <div
                                    className="custom-modal-footer"
                                    key="modal-footer"
                                >
                                    <div className="button-section">
                                        <CustomButton
                                            text="Close"
                                            type="normal footer-button"
                                            onClick={handleClose}
                                        />
                                    </div>
                                    <div className="button-section">
                                        <CustomButton
                                            disabled={
                                                disabled || isLoadingPerson
                                            }
                                            text="Save"
                                            type="filled footer-button"
                                            onClick={() =>
                                                handleClickSave(values)
                                            }
                                        />
                                    </div>
                                </div>
                            ]}
                        >
                            {isLoadingDropdownState ? (
                                <Spinner />
                            ) : (
                                <>
                                    <div
                                        data-testid="person-modal"
                                        className="modal-body-section"
                                    >
                                        <div className="section-title">
                                            General Information
                                        </div>
                                        <FieldsSection>
                                            <div className="section-inputs">
                                                <CustomInput
                                                    label="Name (Latin) *"
                                                    allowClear={true}
                                                    placeholder="Type..."
                                                    value={values.name}
                                                    name={'name'}
                                                    onChange={handleChange}
                                                    className={
                                                        errors?.name &&
                                                        'error-input'
                                                    }
                                                    disabled={isLoadingPerson}
                                                    isEditMode={isEditMode}
                                                />
                                                <CustomInput
                                                    label="Name (Cyrillic)"
                                                    allowClear={true}
                                                    placeholder="Type..."
                                                    value={values.nameBg}
                                                    name={'nameBg'}
                                                    onChange={handleChange}
                                                    className={
                                                        errors?.nameBg &&
                                                        'error-input'
                                                    }
                                                    disabled={isLoadingPerson}
                                                    isEditMode={isEditMode}
                                                />
                                            </div>
                                        </FieldsSection>
                                    </div>
                                    <div className="modal-body-section">
                                        <div className="section-title">
                                            Contact Information & Work
                                        </div>
                                        <FieldsSection>
                                            <div className="section-inputs">
                                                <CustomInput
                                                    label="Work Email*"
                                                    allowClear={true}
                                                    placeholder="Type..."
                                                    value={values.workEmail}
                                                    name={'workEmail'}
                                                    onChange={handleChange}
                                                    className={
                                                        errors?.workEmail &&
                                                        'error-input'
                                                    }
                                                    disabled={isLoadingPerson}
                                                    isEditMode={isEditMode}
                                                />
                                                <CustomInput
                                                    label="Phone number"
                                                    allowClear={true}
                                                    placeholder="Type..."
                                                    value={values.personalPhone}
                                                    name={'personalPhone'}
                                                    onChange={handleChange}
                                                    className={
                                                        errors?.personalPhone &&
                                                        'error-input'
                                                    }
                                                    disabled={
                                                        isLoadingPerson ||
                                                        !permissions.canSeeContactInformationPhoneNumber
                                                    }
                                                    isEditMode={isEditMode}
                                                />
                                                <CustomInput
                                                    label="ExcluCV link"
                                                    allowClear={true}
                                                    placeholder="Type..."
                                                    value={values.excluCVUrl}
                                                    name={'excluCVUrl'}
                                                    onChange={handleChange}
                                                    disabled={isLoadingPerson}
                                                    className={
                                                        errors?.excluCVUrl &&
                                                        'error-input'
                                                    }
                                                    isEditMode={isEditMode}
                                                />
                                            </div>
                                        </FieldsSection>
                                    </div>
                                    {action === 'created' && (
                                        <PersonModalExtension
                                            values={values}
                                            errors={errors}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                    {isConfirmationOpen && (
                                        <CustomConfirmationModal
                                            visible={isConfirmationOpen}
                                            setVisible={setIsConfirmationOpen}
                                            title={'Confirmation'}
                                            contentText={`There is already an existing person with ${confirmationExtraText}. Are you sure you want to proceed?`}
                                            handleConfirm={() =>
                                                handleSave(values)
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </StyledPersonModal>
                    </Form>
                );
            }}
        </Formik>
    );
};

export { PersonModal };
