import React, { useCallback, useEffect, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { useDebounce } from 'use-debounce';

import StyledSubsetTabSearch from './styled/SubsetTabSearch.styled';
import { CustomInput } from '../../../../shared/components/Input';

const SubsetTabSearch = props => {
    const { handleValueUpdate } = props.search;
    const searchPhrase = props.searchPhrase;
    const allowLettersOnly = props.allowLettersOnly;

    const [searchStringValue, setSearchStringValue] = useState(searchPhrase);

    //update the input on type but debounce the search
    const [debouncedValue] = useDebounce(searchStringValue, 1000);

    //this calls the service only once with the debounced val
    useEffect(() => {
        if (debouncedValue !== undefined) {
            handleValueUpdate('searchPhrase', debouncedValue); //TODO
        }
    }, [debouncedValue]);

    const handleInputChange = useCallback(e => {
        const val = e.target.value;
        setSearchStringValue(val);
    }, []);

    return (
        <StyledSubsetTabSearch>
            <CustomInput
                allowClear={true}
                disabled={false}
                value={searchStringValue}
                onChange={handleInputChange}
                placeholder={'Search'}
                prefix={<SearchOutlined />}
                hasMargins={true}
                allowLettersOnly={allowLettersOnly}
                maxLettersLength={50}
            />
        </StyledSubsetTabSearch>
    );
};

export default SubsetTabSearch;
