import React from 'react';

import { CustomButton } from './Button';
import { CloseButton } from './CloseButton';
import { StyledCustomConfirmationModal } from './styled/ConfirmationModal.styled';

export const CustomConfirmationModal = ({
    visible,
    setVisible,
    title,
    contentText,
    handleConfirm,
    className,
    funcParams,
    dataTestid,
    secondaryTitle = '',
    closeCallback = () => {}
}) => {
    const handleClose = () => {
        closeCallback();
        setVisible(false);
    };

    return (
        <StyledCustomConfirmationModal
            visible={visible}
            setVisible={setVisible}
            title={[
                <div
                    data-testid={dataTestid}
                    className="custom-modal-header"
                    key="confirmation-modal-title"
                >
                    {title}
                    <CloseButton handleClose={handleClose} />
                </div>
            ]}
            footer={[
                <div
                    className="custom-modal-footer"
                    key="confirmaition-modal-footer"
                >
                    <div className="button-section">
                        <CustomButton
                            text="Cancel"
                            type="normal footer-button"
                            onClick={handleClose}
                        />
                    </div>
                    <div className="button-section">
                        <CustomButton
                            text="Confirm"
                            type="filled footer-button"
                            onClick={() => handleConfirm(funcParams)}
                        />
                    </div>
                </div>
            ]}
            handleCustomCancel={closeCallback}
            className={className}
        >
            <div className="secondary-title">{secondaryTitle}</div>
            <div className="inner-modal-content">{contentText}</div>
        </StyledCustomConfirmationModal>
    );
};
