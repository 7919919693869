import styled from 'styled-components';

const StyledGlobalSearch = styled.div`
    .header-visible-autocomplete input {
        display: initial;
    }
    .header-hidden-autocomplete input {
        display: none;
    }
    .header-autocomplete {
        border-radius: 6px;
    }
    .header-search.header-hidden-autocomplete {
        padding-right: 10px;
        margin-right: 10px;
        border-right: 2px solid #e5e5e5;
    }
    .header-search.header-visible-autocomplete {
        padding-right: 10px;
        margin-right: 10px;
        border-right: 2px solid #e5e5e5;
    }
    .header-visible-autocomplete input {
        font-size: 14px;
    }
    .ant-select-selector > input.ant-select-selection-search-input {
        color: var(--gray);
    }
    .anticon svg {
        fill: var(--secondaryBlue);
    }
    .ant-select-item-option-content > strong {
        font-weight: 600 !important;
    }
`;

export { StyledGlobalSearch };
