import styled from 'styled-components';

const StyledReportTab = styled.div`
    .embed-report {
        height: 600px;
        padding-top: 20px;
    }
    .reports-container {
        padding-top: 20px;
        display: flex;
        justify-content: center;
    }
    .reports-iframe {
        width: 100%;
        height: 70vh;
    }
`;

export { StyledReportTab };
