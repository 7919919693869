import React from 'react';

import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { StyledEmployeeDetailsSide } from './styled/EmployeeDetailsSide.styled';
import { STATIC_ROUTES_PATHS } from '../../../routes';
import { Media } from '../../../shared/components/Media';
import { infoNotification } from '../../../shared/components/Notifications';
import { Spinner } from '../../../shared/components/Spinner';
import {
    calculateAndFormatTimeDifference,
    calculateOverallWorkingPeriod,
    isInTrial,
    calculateOverallOnLeavePeriod
} from '../../utils/dateHelpers';
import {
    transformLink,
    transformWorkplaceLink
} from '../../utils/employeeDetailsHelper';

const EmployeeDetailsSide = ({ history, permissions }) => {
    const { person, isLoadingFetchPerson, isLoadingReFetchPerson } =
        useSelector(state => state.people);

    const { workEmail, phoneNumber, excluCVUrl, manager, workingPeriods } =
        person;

    const handleManagerRedirect = managerId => {
        history.push(
            STATIC_ROUTES_PATHS.personDetails
                .replace(':id', managerId)
                .replace(':activeTab', 'main-information')
        );
    };

    const handleMissingLinkClick = () =>
        infoNotification('There is no existing data for this link', 5);

    const transformedDeskLink = transformWorkplaceLink(workEmail);
    const transformedExcluCVlink = transformLink(excluCVUrl);

    return (
        <StyledEmployeeDetailsSide>
            <div className="employee-details-box contact-information">
                <div className="employee-details-box-heading">
                    Contact Information
                </div>

                {isLoadingFetchPerson || isLoadingReFetchPerson ? (
                    <Spinner />
                ) : (
                    <>
                        <div className="employee-details-box-text">
                            <div className="employee-details-box-text-email">
                                {workEmail && workEmail.length > 36 ? (
                                    <Tooltip
                                        title={workEmail}
                                        placement="bottom"
                                    >
                                        {workEmail}
                                    </Tooltip>
                                ) : (
                                    <>{workEmail}</>
                                )}
                            </div>
                        </div>
                        <div className="employee-details-box-text">
                            {permissions.canSeeContactInformationPhoneNumber
                                ? phoneNumber
                                : ''}
                        </div>
                        <div className="employee-details-box-links">
                            <div>
                                Workplace:{' '}
                                {!isEmpty(transformedDeskLink) ? (
                                    <a
                                        href={transformedDeskLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Desk Location
                                    </a>
                                ) : (
                                    <a
                                        data-testid="handle-missing-link"
                                        type="button"
                                        onClick={handleMissingLinkClick}
                                    >
                                        Desk Location
                                    </a>
                                )}
                            </div>
                            <div>
                                {!isEmpty(transformedExcluCVlink) ? (
                                    <a
                                        href={transformedExcluCVlink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        ExcluCV
                                    </a>
                                ) : (
                                    <a
                                        type="button"
                                        onClick={handleMissingLinkClick}
                                    >
                                        ExcluCV
                                    </a>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="employee-details-box service-length">
                <div className="employee-details-box-heading">
                    Service Length
                </div>
                {isLoadingFetchPerson || isLoadingReFetchPerson ? (
                    <Spinner />
                ) : (
                    <div className="employee-details-box-text">
                        {workingPeriods && workingPeriods.length > 0 && (
                            <>
                                {workingPeriods?.map((period, i) => (
                                    <div className="employee-service" key={i}>
                                        {(!isEmpty(period.startDate) ||
                                            !isEmpty(period.leaveDate)) && (
                                            <>
                                                <div className="working-dates">
                                                    {!isEmpty(
                                                        period.startDate
                                                    ) && (
                                                        <>
                                                            {moment(
                                                                period.startDate
                                                            ).format(
                                                                'MMM YYYY'
                                                            )}
                                                        </>
                                                    )}
                                                    {'  •  '}
                                                    {isEmpty(
                                                        period.leaveDate
                                                    ) ? (
                                                        'Present'
                                                    ) : (
                                                        <>
                                                            {moment(
                                                                period.leaveDate
                                                            ).format(
                                                                'MMM YYYY'
                                                            )}
                                                        </>
                                                    )}
                                                    {'  •  '}
                                                    {calculateAndFormatTimeDifference(
                                                        period.startDate,
                                                        period.leaveDate
                                                    )}
                                                </div>
                                                <div className="working-trial">
                                                    {i === 0 ? (
                                                        <>
                                                            {isInTrial(
                                                                period
                                                            ) && (
                                                                <div className="trial">
                                                                    Trial period
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : null}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}
                        {workingPeriods && workingPeriods.length > 1 && (
                            <>
                                <div className="working-dates-total">
                                    Overall{'  •  '}
                                    {'  '}
                                    {calculateOverallWorkingPeriod(
                                        workingPeriods
                                    )}
                                </div>
                            </>
                        )}
                        {!!workingPeriods && !!workingPeriods.length && (
                            <div className="working-dates-total">
                                Overall On Leave{'  •  '}
                                {calculateOverallOnLeavePeriod(workingPeriods)}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="employee-details-box reports-to">
                <div className="employee-details-box-heading">Reports to</div>
                {isLoadingFetchPerson || isLoadingReFetchPerson ? (
                    <Spinner />
                ) : (
                    <div className="employee-details-box-manager">
                        {manager && (
                            <>
                                <div className="employee-details-box-manager-img">
                                    <Media
                                        className={'manager-img'}
                                        photoUrl={person.manager?.photo}
                                    />
                                </div>
                                <div
                                    data-testid="employee-details-box-manager-info"
                                    className="employee-details-box-manager-info"
                                    onClick={() =>
                                        handleManagerRedirect(manager.personId)
                                    }
                                >
                                    <div className="employee-details-box-manager-info-names">
                                        {manager?.name}
                                    </div>
                                    <div className="employee-details-box-manager-info-title">
                                        {manager?.positionTitle}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
            <div className="employee-details-box">
                <div className="employee-details-box-heading">Email Groups</div>
            </div>
        </StyledEmployeeDetailsSide>
    );
};

export { EmployeeDetailsSide };
