import styled from 'styled-components';

const Column = styled.div.attrs(() => ({
    className: 'column'
}))`
    display: flex;
    flex-direction: column;
    width: ${({ width }) => width || 'auto'};
    height: ${({ height }) => height || 'auto'};
    gap: ${({ gap }) => gap || '24px'};
    width: ${({ width }) => width || 'inherit'};
`;

export default Column;
