import React from 'react';

import { StyledDepartmentSubSection } from './styled/DepartmentSubSection.styled';
import { STATIC_ROUTES_PATHS } from '../../../../routes';

const DepartmentSubSection = ({
    elementId,
    itemProps,
    history,
    defaultFilters,
    isClickable
}) => {
    const path = document.getElementById(elementId);
    const pathPosition = path.getBBox();
    const pathX = path.attributes['fakeX'].value;
    const pathY = path.attributes['fakeY'].value;
    const positionObj = {
        left: `${pathX}px`,
        top: `${pathY}px`,
        width: `${pathPosition.width}px`,
        height: `${pathPosition.height}px`
    };

    const handleRedirect = () => {
        if (isClickable) {
            history.push({
                pathname: STATIC_ROUTES_PATHS.peopleDashboard,
                state: {
                    subsetTab: 1,
                    filters: {
                        ...defaultFilters,
                        departmentId: itemProps.filterValueId
                    }
                }
            });
        }
    };

    return (
        <StyledDepartmentSubSection
            positionObj={positionObj}
            pathPosition={pathPosition}
            itemColor={itemProps.color}
        >
            <div
                className="department-inner-section"
                data-testid={`${itemProps.name}_dataTestId`}
                onClick={handleRedirect}
            >
                <div className="department-context">
                    <div className="department-flex">
                        <div className="inner-text">{itemProps.name}</div>
                        <div className="number-count">
                            {itemProps.realValue}
                        </div>
                    </div>
                </div>
            </div>
        </StyledDepartmentSubSection>
    );
};

export { DepartmentSubSection };
