import React from 'react';

import Footer from './Footer';
import Header from './Header';
import PageHeading from './PageHeading';
import { StyledMain } from './styled/Main.styled';

export const Main = ({
    hasHeader = true,
    hasHeading = true,
    hasCustomHeading = false,
    hasFooter = false,
    customHeadingComponent,
    children,
    history
}) => (
    <>
        {hasHeader && <Header history={history} />}
        {hasHeading && <PageHeading />}
        {hasCustomHeading && <>{customHeadingComponent}</>}
        <StyledMain>
            <div className="main">{children}</div>
        </StyledMain>
        {hasFooter && <Footer />}
    </>
);
