const NAME_MAX_LENGTH = 2;

export const validateLevelName = (input, maxLength) => {
    if (!input?.trim()) {
        return 'Name is required';
    }

    if (input.trim().length > maxLength) {
        return `Name must be between 1 and ${maxLength} characters long`;
    }

    return '';
};

export const validateLevel = values => {
    const errors = {};
    const levelNameError = validateLevelName(values.name, NAME_MAX_LENGTH);

    if (levelNameError) {
        errors.name = levelNameError;
    }

    return errors;
};

export const handleSubmit = (
    editModalLevelId,
    requestData,
    dispatch,
    createLevel,
    updateLevel
) => {
    const isEditMode = typeof editModalLevelId !== 'undefined';

    isEditMode
        ? dispatch(updateLevel(editModalLevelId, requestData))
        : dispatch(createLevel(requestData));
};
