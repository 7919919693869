import React from 'react';

import FilterButton from './FilterButton';
import SubsetTabSearch from '../../../people/components/Dashboard/PeopleSubsetTab/SubsetTabSearch';
import StyledActionsSection from '../styled/ActionsSection.styled';

const ActionsSection = props => {
    const { search, allowLettersOnly, searchPhrase, count } = props;
    const resultsCount = `${count} results`;

    return (
        <StyledActionsSection>
            <SubsetTabSearch
                search={search}
                allowLettersOnly={allowLettersOnly}
                searchPhrase={searchPhrase}
            />
            <div className="filter-icons-container">
                <div className="people-count-info">{resultsCount}</div>
                <FilterButton />
            </div>
        </StyledActionsSection>
    );
};

export default ActionsSection;
