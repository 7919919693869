import { CustomModal } from 'shared/components/Modal';
import styled from 'styled-components';

export const StyledPositionModal = styled(CustomModal)`
    height: 40vh;
    width: 25% !important;

    form {
        height: 100%;
    }

    form > div {
        margin-top: 20px;
    }

    .error-input {
        border-color: var(--red);
    }

    .error-input .dropdown-container {
        border-color: var(--red);
    }

    .ant-modal-content {
        overflow-y: auto;
        height: 100%;
        display: flex;
        flex-direction: column;

        .ant-modal-header {
            margin-left: 20px;
            margin-right: 20px;
            padding: 25px 0px;
            position: sticky;
            .ant-modal-title {
                .custom-modal-header {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }

        .ant-modal-body {
            margin-left: 20px;
            margin-right: 20px;
            padding-top: 20px;
            flex: 1;
            .modal-content {
                height: 100%;
                .spinner-container {
                    display: flex;
                    justify-content: center;
                    height: 100%;
                    align-items: center;
                }
                .checkbox-group {
                    display: flex;
                    gap: 20px;
                    .checkbox {
                        display: inline-flex;
                        gap: 4px;
                        .ant-checkbox-checked .ant-checkbox-inner {
                            background-color: var(--secondaryBlue);
                            border-color: var(--secondaryBlue);
                        }
                    }
                }
                .input-label {
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 2px;
                    display: inline-block;
                    text-align: center;
                }
                .customInput {
                    width: 100%;
                    .label-disabled {
                        color: inherit;
                    }
                }
                .image-group {
                    height: 300px;
                }
                .description {
                    flex: 1;
                }
                .ant-switch-checked {
                    background-color: var(--secondaryBlue);
                }
            }

            .ant-tabs {
                height: 100%;
                .ant-tabs-tab {
                    padding: 20px 16px;
                    &.ant-tabs-tab-active .ant-tabs-tab-btn {
                        color: var(--secondaryBlue);
                        font-weight: bold;
                    }
                }
                .ant-tabs-ink-bar {
                    background: var(--secondaryBlue);
                }
                .ant-tabs-content {
                    height: 100%;
                }
            }
        }

        .ant-modal-footer {
            border: none;
            padding: 24px 0px;
            margin: 20px 20px 0px 20px;
            .custom-modal-footer {
                display: flex;
                justify-content: space-between;
                .button-section:first-child {
                    padding-right: 10px;
                }
            }
        }

        .ant-select {
            width: 100%;
        }

        .ant-select-selector {
            width: 100% !important;
        }

        .selected-item {
            margin-bottom: 0px !important;
        }

        .clear-item {
            margin-bottom: 0px !important;
        }
    }
`;
