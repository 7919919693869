import React, { Fragment, useRef, useMemo } from 'react';

import { isEqual } from 'lodash';
import moment from 'moment';

import { BenefitPriceSection } from './BenefitPriceSection';
import { prepareBenefitPrices } from '../../../utils/shared/benefitModalHelper';

const BenefitTypeModalUpdate = ({
    initialValues,
    benefitPrices,
    setFieldValue,
    setFieldTouched,
    benefitPriceErrors,
    touched
}) => {
    const fakeId = useRef(-1);
    const transformedBenefitPrices = useMemo(
        () => prepareBenefitPrices(benefitPrices),
        [benefitPrices]
    );

    const handleAddBenefitPrice = latestBenefitIndex => {
        const newBenefitPrice = {
            id: fakeId.current,
            benefitPrice: '',
            startDate: benefitPrices[latestBenefitIndex]?.endDate
                ? moment(benefitPrices[latestBenefitIndex]?.endDate).add(1, 'd')
                : undefined,
            endDate: undefined,
            active: true
        };

        setFieldValue('benefitPrices', [...benefitPrices, newBenefitPrice]);
        fakeId.current = fakeId.current - 1;
    };

    const handleChange = benefitIndex => (propName, propValue) => {
        let valueCopy = [...benefitPrices];
        valueCopy[benefitIndex] = {
            ...valueCopy[benefitIndex],
            [propName]: propValue
        };

        setFieldValue('benefitPrices', valueCopy);
    };

    const handleDelete = benefitIndex => () => {
        let valueCopy = [...benefitPrices];
        valueCopy[benefitIndex] = {
            ...valueCopy[benefitIndex],
            active: false
        };

        setFieldValue('benefitPrices', valueCopy);
    };

    const activePrices = benefitPrices.filter(
        benefitPrice => benefitPrice.active
    );

    return (
        <>
            {transformedBenefitPrices.map(
                ({ benefitPrice, previousActive, nextActive }, index) => {
                    if (!benefitPrice.active) return null;

                    const isLast = isEqual(
                        activePrices[activePrices.length - 1],
                        benefitPrice
                    );
                    const isCompleted =
                        benefitPrice.endDate &&
                        benefitPrice.startDate &&
                        benefitPrice.benefitPrice;
                    const enableAddPrice = isLast && isCompleted;
                    const shouldDisableFields =
                        !isLast &&
                        isEqual(initialValues?.[index], benefitPrice) &&
                        isCompleted;

                    return (
                        <Fragment key={benefitPrice.id}>
                            <BenefitPriceSection
                                benefitTypeIndex={index}
                                onChange={handleChange(index)}
                                values={benefitPrice}
                                benefitPriceErrors={
                                    benefitPriceErrors
                                        ? benefitPriceErrors[index]
                                        : []
                                }
                                benefitPriceId={benefitPrice.id}
                                shouldDisableFields={shouldDisableFields}
                                disabledStartDate={previousActive?.endDate}
                                disabledEndDate={nextActive?.startDate}
                                isEndDateRequired={
                                    !isLast && !shouldDisableFields
                                }
                                setFieldTouched={setFieldTouched}
                                touched={touched}
                            />
                            <div
                                className={`bottom-section ${
                                    isLast ? 'last' : ''
                                }`}
                            >
                                {enableAddPrice && (
                                    <button
                                        data-testid="add-benefit-price"
                                        className="btn-add"
                                        onClick={() =>
                                            handleAddBenefitPrice(index)
                                        }
                                        type="button"
                                    >
                                        + Add price
                                    </button>
                                )}
                                {isLast && activePrices.length > 1 && (
                                    <button
                                        data-testid="delete-benefit-price"
                                        className="btn-delete"
                                        onClick={handleDelete(index)}
                                        type="button"
                                    >
                                        Delete
                                    </button>
                                )}
                            </div>
                        </Fragment>
                    );
                }
            )}
        </>
    );
};

export { BenefitTypeModalUpdate };
