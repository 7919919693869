import httpService from 'shared/services/httpService';

import url from '../../apiUrls';

const getAllUsers = async requestModel => {
    const { skip, take, sortBy, sortAsc, searchPhrase } = requestModel;
    const res = await httpService.get(url.GET_ALL_USERS_PAGINATED(), {
        params: { skip, take, sortBy, sortAsc, searchPhrase }
    });

    return res.data;
};

const getUserById = async userId => {
    const res = await httpService.get(url.GET_USER(userId));
    return res.data;
};

const createUser = async requestModel => {
    const res = await httpService.post(url.CREATE_USER(), { ...requestModel });
    return res.data;
};

const updateUser = async (userId, requestData) => {
    const res = await httpService.patch(url.UPDATE_USER(userId), {
        ...requestData
    });

    return res.data;
};

const deleteUser = async userId => {
    const res = await httpService.delete(url.DELETE_USER(userId));

    return res.data;
};

export default { getAllUsers, createUser, getUserById, updateUser, deleteUser };
