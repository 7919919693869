import React from 'react';

const ExpandIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            data-testid="expand-icon"
        >
            <rect
                width="19"
                height="19"
                x="0.5"
                y="0.5"
                fill="#03A8F5"
                rx="4.5"
            ></rect>
            <path stroke="#fff" d="M5 10h10M10 15V5"></path>
        </svg>
    );
};

export { ExpandIcon };
