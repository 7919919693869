import React from 'react';

import { Tabs } from 'antd';

import { StyledCustomTabs } from './styled/CustomTabs.styled';

const { TabPane } = Tabs;

export const CustomTabs = ({ tabs }) => {
    return (
        <StyledCustomTabs>
            <div className="tabs-container">
                <Tabs
                    tabPosition="top"
                    animated={{ inkBar: false, tabPane: false }}
                >
                    {tabs.map((tab, i) => (
                        <TabPane
                            key={i}
                            tab={tab.title}
                            disabled={tab.disabled}
                        >
                            <tab.Component />
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        </StyledCustomTabs>
    );
};
