import styled from 'styled-components';

const StyledSubsetTabDownload = styled.div`
    padding-right: 20px;
    .btn-normal {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 5px 10px;
        min-width: 85px;
        color: var(--mainBlue);
        font-weight: 600;
        svg {
            padding-right: 4px;
        }
    }
    .btn-normal:hover {
        color: var(--secondaryBlue);
    }
    #download-hidden {
        display: none;
    }
    .btn-normal:disabled,
    .btn-btn-normal:disabled:hover {
        border: 1px solid var(--gray) !important;
        svg {
            path {
                stroke: var(--darkGray) !important;
            }
        }
        background-color: var(--gray) !important;
        color: var(--darkGray) !important;
        cursor: not-allowed !important;
    }
`;

export default StyledSubsetTabDownload;
