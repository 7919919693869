import styled from 'styled-components';

const StyledAutoCompleteInput = styled.div`
    .custom-ac {
        width: var(--defaultInputWrapWidth);
    }
    .custom-ac.inputMargin {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .custom-ac .input-label,
    .custom-ac .label-disabled {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 2px;
    }
    .custom-ac .label-disabled {
        color: var(--darkGray);
    }
    .custom-ac input {
        font-weight: normal;
    }
    .custom-ac .ant-select {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .custom-ac .ant-select-selector {
        display: flex;
        align-items: center;
        height: 40px;
        border: 1px solid var(--gray);
        box-sizing: border-box;
        border-radius: 10px;
        width: var(--defaultInputWrapWidth);
    }
    .ant-select > .ant-select-clear {
        display: flex;
        justify-content: flex-end;
        width: 16px;
        margin-left: 4px;
    }
    .ant-select-selector > .ant-select-selection-search {
        display: flex;
        align-items: center;
    }
    .ant-select-selector > input.ant-select-selection-search-input {
        color: var(--gray);
    }
    .ant-select-selection-search > input.ant-select-selection-search-input {
        width: var(--defaultInputWidth);
    }
    .anticon svg {
        fill: var(--secondaryBlue);
    }
    //when having error
    .error-input .ant-select .ant-select-selector {
        border-color: var(--red);
    }
    .error-input
        > .ant-select-focused:not(.ant-select-disabled).ant-select:not(
            .ant-select-customize-input
        )
        .ant-select-selector {
        border-color: var(--red) !important;
        -webkit-box-shadow: var(--shadowColorOnError) !important;
        box-shadow: var(--shadowColorOnError) !important;
    }
`;

export { StyledAutoCompleteInput };
