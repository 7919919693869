import { CustomModal } from 'shared/components/Modal';
import styled from 'styled-components';

export const StyledRoleModal = styled(CustomModal)`
    width: 360px !important;
    .ant-modal-content {
        align-self: center;
        .ant-modal-header {
            margin-left: 20px;
            margin-right: 20px;
            padding: 20px 0px 16px 0px;
            .ant-modal-title {
                .custom-modal-header {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
        .ant-modal-body {
            margin-left: 20px;
            margin-right: 20px;
            padding-top: 20px;
            .ant-spin.ant-spin-lg.ant-spin-spinning {
                display: flex !important;
                justify-content: center !important;
            }
            .modal-body-upper {
                .section-inputs {
                    width: 100%;
                    .customInput {
                        width: 320px;
                        margin-right: 0px;
                        padding-bottom: 20px;
                    }
                }
            }
            .modal-body-lower {
                .section-inputs {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding-top: 20px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid var(--whiteGray);
                    .customInput {
                        width: 140px;
                        margin-right: 0px;
                        padding: 0px;
                        .ant-picker {
                            width: 140px !important;
                        }
                    }
                }
                .bottom-section {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    padding-top: 10px;
                    justify-content: flex-end;
                    .btn-add {
                        color: var(--mainBlue);
                        font-size: 14px;
                        line-height: 20px;
                    }
                    .btn-delete {
                        color: var(--darkGray);
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
                .last {
                    justify-content: space-between !important;
                }
                .no-elements {
                    padding: 20px 0px;
                    justify-content: flex-start !important;
                    border-bottom: 1px solid var(--whiteGray);
                }
            }
        }
        .ant-modal-footer {
            border: none;
            padding-right: 0px;
            margin-left: 20px;
            margin-right: 20px;
            padding: 24px 0px;
            .custom-modal-footer {
                display: flex;
                justify-content: space-between;
                .button-section:first-child {
                    padding-right: 10px;
                }
            }
        }
        .selectors-section {
            display: flex;
            flex-wrap: wrap;
            .selector {
                padding-right: 20px;
                padding-bottom: 20px;
                .custom-select-div {
                    margin: 0px;
                }
                .custom-select-div.wider {
                    width: 320px;
                    .ant-select {
                        width: 100%;
                        .ant-select-selector {
                            width: 100%;
                        }
                    }
                }
                .custom-month-year-picker {
                    margin: 0px;
                    width: 150px !important;
                }
                :nth-child(3) {
                    align-self: end;
                }
            }
        }
    }
`;
