/* eslint-disable import/order */
import React, { useContext, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import useInfiniteScroll from '../../../shared/hooks/useInfiniteScroll';
import SubsetTabActions from '../../../people/components/Dashboard/PeopleSubsetTab/SubsetTabActions';
import peopleService from '../../../people/services/peopleService';
import Footer from '../../../shared/components/layout/Footer';
import { StyledPeopleSubsetTab } from '../../../people/components/Dashboard/styled/PeopleSubsetTab.styled';
import { checkTokenExp, redirectTokenExp } from '../../../utils/tokenHelper';
import PermissionsContext from 'shared/utils/permissionStore';
import PersonBenefitsTable from '../Table/PersonBenefitsTable';

const DEFAULT_HANDLERS = {
    loading: true,
    error: undefined,
    currentTotal: undefined,
    allPeopleTotalCount: undefined
};

const TAB = 'benefits';

const DEFAULT_VALUES = {
    startDate: undefined,
    endDate: undefined,
    locationId: undefined,
    locationName: undefined,
    benefitTypeId: undefined,
    benefitTypeName: undefined
};

let searchPhraseCache = undefined;

const BenefitsSubsetTab = ({
    subset,
    handleCreateColumns,
    defaultSort,
    tabType,
    redirectFilterState,
    updateCount
}) => {
    const DEFAULT_STATE = {
        skip: 0,
        take: 10,
        searchPhrase: searchPhraseCache,
        filterValues: !isEmpty(redirectFilterState)
            ? redirectFilterState
            : DEFAULT_VALUES,
        sort: defaultSort
    };
    const [peopleWithBenefits, setPeopleWithBenefits] = useState([]);
    const [state, setState] = useState(DEFAULT_STATE);
    const [handlers, setHandlers] = useState(DEFAULT_HANDLERS);
    const [typeOfLayout, setTypeOfLayout] = useState('base');

    const { loading, currentTotal, allPeopleTotalCount } = handlers;
    const { sort, skip, take, filterValues } = state;

    const permissions = useContext(PermissionsContext).permissions;

    useEffect(() => {
        fetchData();
    }, [state]);

    useEffect(() => {
        return () => (searchPhraseCache = undefined);
    }, []);

    const fetchData = async () => {
        setHandlers(prev => {
            return {
                ...prev,
                loading: DEFAULT_HANDLERS.loading,
                error: DEFAULT_HANDLERS.error
            };
        });
        try {
            if (checkTokenExp()) {
                redirectTokenExp();
            }
            const resp = await peopleService.getAllEmployeesWithBenefits(
                subset.value,
                state
            );
            const {
                data,
                metadata: { totalCount }
            } = resp;
            setHandlers(prev => {
                return {
                    ...prev,
                    currentTotal: data.length,
                    allPeopleTotalCount: totalCount,
                    loading: false
                };
            });
            setPeopleWithBenefits(data);
            updateCount(data.length);
        } catch (err) {
            setHandlers({
                ...DEFAULT_HANDLERS,
                error: err.message,
                loading: false
            });
        }
    };

    const handleValueUpdate = (which, val) => {
        searchPhraseCache = val;
        setState(previous => {
            return { ...previous, [which]: val };
        });
    };

    // function to update filter state
    const handleFilterValueUpdate = (key, val, label) => {
        const newFilters = {
            ...filterValues,
            [key]: val
        };

        if (key === 'locationId') {
            newFilters.locationName = label;
        }

        if (key === 'benefitTypeId') {
            newFilters.benefitTypeName = label;
        }

        setState(previous => {
            return { ...previous, filterValues: newFilters };
        });
    };

    const handleUpdate = which => value => {
        setState({ ...state, [which]: value });
    };

    // infinite scroll handling
    const boundaryRef = useInfiniteScroll({
        onLoadMore: () => {
            handleLoadMoreData();
        },
        loading,
        hasMore: Boolean(peopleWithBenefits.length < allPeopleTotalCount)
    });

    const handleLoadMoreData = async () => {
        setHandlers(prev => {
            return {
                ...prev,
                loading: DEFAULT_HANDLERS.loading,
                error: DEFAULT_HANDLERS.error
            };
        });
        try {
            const resp = await peopleService.getAllEmployeesWithBenefits(
                subset.value,
                {
                    ...state,
                    skip: currentTotal,
                    take: take
                }
            );
            setPeopleWithBenefits(prev => {
                let tempt = [...prev];
                tempt.push(...resp.data);
                return tempt;
            });

            const currentTotalCount = currentTotal + resp.data.length;
            setHandlers(prev => {
                return {
                    ...prev,
                    currentTotal: currentTotalCount,
                    allPeopleTotalCount: resp.metadata.totalCount,
                    loading: false
                };
            });
            updateCount(currentTotalCount);
        } catch {
            setHandlers({ ...DEFAULT_HANDLERS, loading: false });
        }
    };

    // change layout type
    const handleLayoutChange = type => {
        setTypeOfLayout(type);
    };

    const handleTagRemove = selection => {
        const { stateValueProp } = selection;
        handleFilterValueUpdate(stateValueProp, undefined);
    };

    const handleFiltersReset = () => {
        setState(previous => {
            return { ...previous, filterValues: DEFAULT_VALUES };
        });
    };

    return (
        <>
            <StyledPeopleSubsetTab data-testid="benefits-subset-tab">
                <div className="all-people-container">
                    <SubsetTabActions
                        totalCount={allPeopleTotalCount}
                        layOut={{ typeOfLayout, handleLayoutChange }}
                        filter={{
                            state,
                            filterValues,
                            tab: TAB,
                            handleFilterValueUpdate,
                            handleTagRemove,
                            handleFiltersReset
                        }}
                        search={{
                            handleValueUpdate
                        }}
                        download={{
                            state,
                            subset,
                            disableDownload:
                                !permissions.canExportPeopleBenefitsList,
                            tab: TAB
                        }}
                        allowLettersOnly={true}
                        searchPhrase={state.searchPhrase}
                    />

                    {/* switch people view based on layout setting */}
                    {typeOfLayout === 'base' ? (
                        <PersonBenefitsTable
                            tabType={tabType}
                            totalCount={currentTotal}
                            defaultSort={defaultSort}
                            handleCreateTableColumns={handleCreateColumns}
                            peopleData={peopleWithBenefits}
                            loading={loading}
                            sorted={sort}
                            handleSortUpdate={handleUpdate('sort')}
                            boundaryRef={boundaryRef}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </StyledPeopleSubsetTab>
            {!Boolean(
                peopleWithBenefits.length < allPeopleTotalCount && skip
            ) && <Footer className={'special'} />}
        </>
    );
};

export default BenefitsSubsetTab;
