import styled from 'styled-components';

import { CustomModal } from '../Modal';

const StyledCustomConfirmationModal = styled(CustomModal)`
    max-width: 361px;
    .ant-modal-header {
        margin: 0px 20px;
        padding-top: 21px;
        padding-bottom: 17px;
        .custom-modal-header {
            height: 20px;
            display: flex;
            justify-content: center;
            .icon-holder {
                position: absolute;
                right: 20px;
            }
        }
    }
    .ant-modal-body {
        margin: 0px 20px;
        .modal-content {
            padding-top: 24px;
            padding-bottom: 45px;
            .secondary-title {
                font-size: 18px;
                font-style: normal;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: center;
            }
            .inner-modal-content {
                padding-top: 9px;
                font-size: 14px;
                font-style: normal;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
    }
    .ant-modal-footer {
        margin: 0px 20px;
        border-top: none;
        padding: 18px 0px;
        .custom-modal-footer {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .button-section {
                .button {
                    width: 155px;
                    height: 34px;
                }
            }
        }
    }
`;

export { StyledCustomConfirmationModal };
