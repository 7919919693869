import React from 'react';

import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

import { StyledButton } from './styled/Button.styled';

export const CustomButton = ({
    width,
    className,
    text,
    onClick,
    style,
    icon,
    iconComponent,
    disabled,
    type,
    tooltipTitle,
    tooltipPlacement,
    ...rest
}) => {
    const { id } = rest;
    const btnClass = `button ${disabled ? 'btn-disabled ' : ''}`;
    const typeClass = type ? `btn-${type}` : '';
    return (
        <>
            <StyledButton width={width} className={className}>
                <div
                    className={`${btnClass}${typeClass}`}
                    onClick={disabled ? undefined : onClick}
                    role="button"
                    style={style}
                    id={id}
                >
                    {tooltipTitle ? (
                        <Tooltip
                            title={tooltipTitle}
                            placement={tooltipPlacement}
                        >
                            <div className="button-inner">
                                {icon && (
                                    <div className="icon">
                                        <Icon type={icon} />
                                    </div>
                                )}
                                {iconComponent && (
                                    <div className="icon">
                                        <Icon component={iconComponent} />
                                    </div>
                                )}
                                <div className="text">{text}</div>
                            </div>
                        </Tooltip>
                    ) : (
                        <div className="button-inner">
                            {icon && (
                                <div className="icon">
                                    <Icon type={icon} />
                                </div>
                            )}
                            {iconComponent && (
                                <div className="icon">
                                    <Icon component={iconComponent} />
                                </div>
                            )}
                            <div className="text">{text}</div>
                        </div>
                    )}
                </div>
            </StyledButton>
        </>
    );
};
