import React from 'react';

import { canNavigate } from 'shared/utils/navigationHelper';

import { NavigationItem } from './NavigationItem';
import { StyledNavigation } from './styled/Navigation.styled';

//Update the hardcoded string here to work with the STATIC_ROUTES_PATHS corresponding item once we have those pages done
const NAV_ITEMS = [
    { route: '/main', label: 'Home' },
    { route: '/people', label: 'People' },
    { route: '/requests', label: 'Requests' },
    { route: '/benefits', label: 'Benefits' },
    { route: '/reports', label: 'Reports' },
    { route: '/admin', label: 'Admin Panel' }
];

const Navigation = () => {
    const path = window.location.pathname;

    const navOptions = NAV_ITEMS.map((x, i) => (
        <NavigationItem
            key={i}
            route={x.route}
            label={x.label}
            active={path.startsWith(x.route)}
            disabled={!canNavigate(x.route)}
        />
    ));

    return (
        <StyledNavigation>
            <div className="navigation-wrapper">
                <div className="navigation-inner">{navOptions}</div>
            </div>
        </StyledNavigation>
    );
};

export default Navigation;
