import styled from 'styled-components';

const StyledProfileSubmenu = styled.div`
    .profile-sub-menu {
        min-width: 200px;
        z-index: 999;
        padding: 20px 10px;
        background: #fff;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        font-size: 14px;
    }
    .profile-sub-menu > div {
        flex: 1;
    }
    .profile-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 10px;
    }
    .mail {
        margin-bottom: 16px;
    }
    .links {
        border-top: 1px solid var(--whiteGray);
        padding-top: 16px;
    }
    .links > a {
        color: var(--gray);
    }
`;

export { StyledProfileSubmenu };
