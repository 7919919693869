import { formatDate } from './reduxHelper';
import positionService from '../../people/services/positionService';
import { selectedPosition } from '../../people/utils/Details/MainInformation/mainInfoFormHelper';

export const mapToWorkingPeriodNames = {
    startDate: 'StartDate',
    endDate: 'EndDate',
    departmentId: 'DepartmentId',
    industryId: 'IndustryId',
    levelId: 'LevelId',
    reasonForLeavingId: 'ReasonForLeavingId',
    leaverTypeId: 'LeaverTypeId',
    typeOfWorkId: 'TypeOfWorkId',
    hiringChannelId: 'HiringChannelId',
    hiringChannelText: 'HiringChannelText',
    mentormaterId: 'MentormaterId',
    locationId: 'LocationId',
    workLocation: 'WorkLocation',
    voluntaryTypeId: 'VoluntaryTypeId',
    workingTime: 'WorkingTime',
    trialPeriod: 'TrialPeriod',
    reportsToId: 'ReportsToId',
    positionTitle: 'PositionId'
};

// Returns update model data in correct format
export const formatWorkingPeriodUpdateData = incomeData => {
    if (
        incomeData.propertyName === 'StartDate' ||
        incomeData.propertyName === 'EndDate'
    ) {
        return {
            ...incomeData,
            value: formatDate(incomeData.value),
            takesEffectOn: formatDate(incomeData.takesEffectOn)
        };
    }

    incomeData.value =
        incomeData.value === '' || incomeData.value === undefined
            ? null
            : incomeData.value;

    return {
        ...incomeData,
        takesEffectOn: formatDate(incomeData.takesEffectOn)
    };
};

// Returns correct id for positions in update request data
export const handlePositionId = async (positions, incomeData) => {
    const chosenPosition = selectedPosition(positions, incomeData.value);

    // If the position is new, create new position and get id for data
    if (
        incomeData.value !== null &&
        incomeData.value !== undefined &&
        incomeData.value !== '' &&
        !chosenPosition
    ) {
        const createdPosition = await positionService.addPosition(
            incomeData.value
        );

        incomeData = updateRequestDataPositionId(
            incomeData,
            createdPosition.data.id
        );
    }
    // If position exist set it in data
    else {
        incomeData = updateRequestDataPositionId(
            incomeData,
            chosenPosition?.id
        );
    }

    return { chosenPosition, incomeData };
};

const updateRequestDataPositionId = (data, positionId) => {
    data = {
        ...data,
        value: positionId?.toString()
    };

    return data;
};
