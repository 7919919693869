import moment from 'moment';
import { EditIcon } from 'shared/theme/assets/icons/EditIcon';

import { StyledIcons } from './styled/StyledIcons.styled';
import { simpleTableBackgroundHelper } from './tableUtil';

export const createLevelTableColumns = (handleOpenEdit, permissions) => {
    const FORMAT = 'MM/DD/YYYY';

    return [
        {
            Header: 'Level',
            id: 'level',
            accessor: 'level',
            Cell: ({ original }) => {
                return (
                    <div className="tooltip-span-text">
                        <span>{original.name}</span>
                    </div>
                );
            },
            minWidth: 150,
            resizable: true,
            sortable: false,
            fixed: 'left',
            getProps: (state, _, column) => {
                return {
                    style: simpleTableBackgroundHelper(state, _, column)
                };
            }
        },
        {
            Header: 'Modified On',
            id: 'modifiedOn',
            accessor: 'modifiedOn',
            Cell: ({ original }) => (
                <>{moment(original.modifiedOn).format(FORMAT)} </>
            ),
            resizable: true,
            sortable: true,
            minWidth: 125,
            getProps: (state, rowInfo, column) => {
                return {
                    style: simpleTableBackgroundHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Actions',
            id: 'actions',
            accessor: 'actions',
            maxWidth: 90,
            resizable: false,
            sortable: false,
            Cell: ({ original }) => (
                <>
                    <StyledIcons>
                        <div className="icons">
                            <div
                                data-testid="level-type-edit"
                                className="icon icon-edit"
                                onClick={() => {
                                    permissions.canEditResources
                                        ? handleOpenEdit(true, original.id)
                                        : undefined;
                                }}
                            >
                                <EditIcon />
                            </div>
                        </div>
                    </StyledIcons>
                </>
            )
        }
    ];
};
