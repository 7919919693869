import styled from 'styled-components';

const Row = styled.div.attrs(() => ({
    className: 'row'
}))`
    display: flex;
    flex-direction: row;
    gap: ${({ gap }) => gap || '24px'};
    justify-content: ${({ justifyContent }) => justifyContent || ''};
`;

export default Row;
