import React, { useEffect } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDateSelector, CustomSelect } from 'shared/components';
import { mapDataForDropdown } from 'utils/dropdownHelper';

import SelectedFilterTags from './SelectedFilterTags';
import { fetchRequestPageFilterDropDowns } from '../../../redux/slices/dropdown';
import { WORKING_TIMES } from '../../../shared/constants/workingTimes';
import useFilter from '../../contexts/FilterContext';
import StyledFiltersBox from '../styled/FiltersBox.styled';

const FiltersBox = ({ isVisible, onClose, handleEnter, handleLeave }) => {
    const { filters, addFilter, removeFilter, resetFilters } = useFilter();
    const dropdownState = useSelector(state => state.dropdown);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchRequestPageFilterDropDowns());
    }, []);

    const handleMouseEnter = e => {
        if (e.target) {
            handleEnter();
        }
    };

    const handleMouseLeave = e => {
        if (e.target) {
            handleLeave();
        }
    };

    if (!isVisible) return null;

    const handleTagRemove = filterName => {
        removeFilter(filterName);
    };

    const handleFilterReset = () => {
        resetFilters();
    };

    return (
        <StyledFiltersBox
            className="filters-box"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="filters-box-header">
                <h4 className="filters-box-heading">Filter</h4>
                <button
                    type="button"
                    className="filters-box-actions"
                    aria-label="Close filters"
                    onClick={onClose}
                >
                    <CloseOutlined />
                </button>
            </div>
            <div className="filters-box-body">
                <CustomDateSelector
                    value={filters.entryDate.value}
                    label="Entry Date"
                    name="entryDate"
                    id="entryDate"
                    onChange={(name, value) =>
                        addFilter({
                            filterName: name,
                            value,
                            label: value
                        })
                    }
                    placeholder="Select a date"
                />
                <CustomSelect
                    value={filters.benefitType.value}
                    label="Benefit"
                    name="benefitType"
                    id="benefitType"
                    placeholder="Select a benefit"
                    options={mapDataForDropdown(dropdownState.benefitTypes)}
                    disabled={
                        dropdownState.isLoading ||
                        isEmpty(dropdownState.benefitTypes)
                    }
                    onChange={(name, value, option) =>
                        addFilter({
                            filterName: name,
                            value,
                            label: option.children
                        })
                    }
                />
                <CustomSelect
                    value={filters.requestType.value}
                    label="Type of request"
                    name="requestType"
                    id="requestType"
                    placeholder="Select a type of request"
                    options={mapDataForDropdown(
                        dropdownState.benefitRequestTypes
                    )}
                    disabled={
                        dropdownState.isLoading ||
                        isEmpty(dropdownState.benefitRequestTypes)
                    }
                    onChange={(name, value, option) =>
                        addFilter({
                            filterName: name,
                            value,
                            label: option.children
                        })
                    }
                />
                <CustomSelect
                    value={filters.coveredBy.value}
                    label="Covered by"
                    name="coveredBy"
                    id="coveredBy"
                    placeholder="Select covered by"
                    options={mapDataForDropdown(dropdownState.benefitCoveredBy)}
                    disabled={
                        dropdownState.isLoading ||
                        isEmpty(dropdownState.benefitCoveredBy)
                    }
                    onChange={(name, value, option) =>
                        addFilter({
                            filterName: name,
                            value,
                            label: option.children
                        })
                    }
                />
                <CustomSelect
                    value={filters.workingTime.value}
                    label="Working Time"
                    name="workingTime"
                    id="workingTime"
                    placeholder="Select working time"
                    options={WORKING_TIMES}
                    disabled={dropdownState.isLoading}
                    onChange={(name, value, option) =>
                        addFilter({
                            filterName: name,
                            value,
                            label: option.children
                        })
                    }
                />
                <CustomSelect
                    label={'Location'}
                    placeholder={'Select a Location'}
                    name="location"
                    disabled={dropdownState.isLoading}
                    value={filters.location.value}
                    onChange={(name, value, option) =>
                        addFilter({
                            filterName: name,
                            value,
                            label: option.children
                        })
                    }
                    options={mapDataForDropdown(dropdownState.locations)}
                />
            </div>
            <div className="filters-box-footer">
                <SelectedFilterTags
                    filters={filters}
                    onTagRemove={handleTagRemove}
                    onFiltersReset={handleFilterReset}
                />
            </div>
        </StyledFiltersBox>
    );
};

export default FiltersBox;
