import React from 'react';

import { Tooltip, Switch } from 'antd';
import moment from 'moment';
import { Media } from 'shared/components/Media';
import { EditIcon } from 'shared/theme/assets/icons/EditIcon';
import { TrashIcon } from 'shared/theme/assets/icons/TrashIcon';
import blankImage from 'shared/theme/assets/images/blank.png';

import { StyledIcons, StyledLocation } from './styled/StyledIcons.styled';
import { getColumnWidth, allBenefitsBackgroundHelper } from './tableUtil';

const FORMAT = 'MM/DD/YYYY';

export const createOfferTableColumns = (
    offers,
    handleOpenEdit,
    handleToggleVisibility,
    handleOpenDelete,
    permissions
) => {
    return [
        {
            Header: 'Visibility',
            id: 'visibility',
            accessor: 'visibility',
            Cell: ({ original }) => (
                <div className="visibility-cell">
                    <Switch
                        checked={original.selectable}
                        onChange={() => handleToggleVisibility(original)}
                        disabled={!original?.active}
                    />
                </div>
            ),
            width: 100,
            sortable: false,
            fixed: 'left',
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            Cell: ({ original }) => (
                <div className="table-name-section">
                    <Media
                        className={'wide-avatar'}
                        photoUrl={original.image}
                        defaultPicture={blankImage}
                        alt={original.imageAltText}
                    />
                    <div className="tooltip-span-text">
                        <Tooltip title={original.name} placement="bottomLeft">
                            <span>{original.name}</span>
                        </Tooltip>
                    </div>
                </div>
            ),
            width:
                offers && offers.length > 0
                    ? getColumnWidth(offers, 'Name')
                    : 325,
            resizable: false,
            sortable: true,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Location',
            id: 'location',
            accessor: 'location',
            Cell: ({ original }) => (
                <div className="table-name-section">
                    <StyledLocation>
                        {original.locations != null
                            ? original.locations.join(', ')
                            : ''}
                    </StyledLocation>
                </div>
            ),
            width: 200,
            resizable: false,
            sortable: false,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Updated On',
            id: 'updatedOn',
            accessor: 'updatedOn',
            Cell: ({ original }) => (
                <>{moment(original.updatedOn).format(FORMAT)} </>
            ),
            resizable: true,
            sortable: true,
            minWidth: 125,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Created On',
            id: 'createdOn',
            accessor: 'createdOn',
            Cell: ({ original }) => (
                <>{moment(original.createdOn).format(FORMAT)} </>
            ),
            resizable: true,
            sortable: false,
            minWidth: 125,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'How much',
            id: 'howMuch',
            accessor: 'howMuch',
            Cell: ({ original }) => (
                <div style={{ width: 150 }} className="table-name-section">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: original.howMuch
                        }}
                    />
                </div>
            ),
            width: 325,
            resizable: false,
            sortable: false,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        },
        {
            Header: 'Actions',
            id: 'actions',
            accessor: 'actions',
            Cell: ({ original }) => (
                <>
                    <StyledIcons>
                        <div className="icons">
                            <div
                                data-testid="offer-type-edit"
                                className="icon icon-edit"
                                onClick={() => {
                                    permissions.canEditOffers
                                        ? handleOpenEdit(original)
                                        : undefined;
                                }}
                            >
                                <EditIcon />
                            </div>
                            {permissions.canDeleteOffer && !original.active && (
                                <div
                                    data-testid="offer-delete"
                                    className="icon icon-delete"
                                    onClick={() =>
                                        permissions.canDeleteOffer
                                            ? handleOpenDelete(original)
                                            : undefined
                                    }
                                >
                                    <TrashIcon />
                                </div>
                            )}
                        </div>
                    </StyledIcons>
                </>
            ),
            resizable: true,
            sortable: false,
            minWidth: 90,
            maxWidth: 200,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column)
                };
            }
        }
    ];
};

// This is used if we want to change background of each column row
const typeHandlerHelper = (state, rowInfo, column) => {
    return allBenefitsBackgroundHelper(state, rowInfo, column);
};
