import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const getAllPositions = async requestModel => {
    const { skip, take, sortBy, sortAsc, searchPhrase, loadActive } =
        requestModel;
    const res = await httpService.get(url.GET_ALL_POSITIONS_PAGINATED(), {
        params: {
            skip,
            take,
            sortBy,
            sortAsc,
            query: searchPhrase,
            active: loadActive
        }
    });

    return res.data;
};

const getPositionById = async positionId => {
    const res = await httpService.get(url.GET_POSITION_BY_ID(positionId));

    return res.data;
};

const addPosition = async requestData => {
    const data = await httpService.post(url.CREATE_POSITION(), requestData, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return data.data;
};

const updatePosition = async (positionId, requestData) => {
    const data = await httpService.patch(
        url.UPDATE_POSITION(positionId),
        requestData,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
    return data.data;
};

export default {
    getAllPositions,
    getPositionById,
    addPosition,
    updatePosition
};
