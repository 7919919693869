import httpService from './httpService';
import url from '../../apiUrls';
import authService from '../../auth/services/authService';

const getHistoricalData = async historicalState => {
    const { personId, filterBy } = historicalState;
    let baseParams = `?PersonId=${personId}`;
    if (filterBy === 'AllId' || filterBy === undefined) {
        baseParams += '';
    } else {
        baseParams += `&PropertyFilter=${filterBy}`;
    }
    const data = await httpService.get(url.GET_HISTORICAL_DATA(baseParams));
    return data.data;
};

const editPersonHistory = async (personHistoryId, requestData) => {
    const data = await httpService.patch(
        url.EDIT_PERSON_HISTORICAL_DATA(personHistoryId),
        { ...requestData }
    );
    return data.data;
};

const deletePersonHistory = async (personHistoryId, requestObj) => {
    const data = await httpService.delete(
        url.DELETE_PERSON_HISTORICAL_DATA(personHistoryId),
        {
            headers: {
                Authorization: authService.getJwt()
            },
            data: { ...requestObj }
        }
    );
    return data.data;
};

const editWorkingPeriodHistory = async (
    workingPeriodHistoryId,
    requestData
) => {
    const data = await httpService.patch(
        url.EDIT_WORKING_PERIOD_HISTORICAL_DATA(workingPeriodHistoryId),
        { ...requestData }
    );
    return data.data;
};

const deleteWorkingPeriodHistory = async (
    workingPeriodHistoryId,
    requestObj
) => {
    const data = await httpService.delete(
        url.DELETE_WORKING_PERIOD_HISTORICAL_DATA(workingPeriodHistoryId),
        {
            headers: {
                Authorization: authService.getJwt()
            },
            data: { ...requestObj }
        }
    );
    return data.data;
};

const editManyPersonHistory = async requestData => {
    const data = await httpService.patch(
        url.EDIT_MANY_PERSON_HISTORICAL_DATA(),
        [...requestData]
    );
    return data.data;
};

const editManyWorkingPeriodsHistory = async requestData => {
    const data = await httpService.patch(
        url.EDIT_MANY_WORKING_PERIOD_HISTORICAL_DATA(),
        [...requestData]
    );
    return data.data;
};

export default {
    getHistoricalData,
    editPersonHistory,
    deletePersonHistory,
    editWorkingPeriodHistory,
    deleteWorkingPeriodHistory,
    editManyPersonHistory,
    editManyWorkingPeriodsHistory
};
