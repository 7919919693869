/**
 * @name PredicateFunction
 * @function
 * @param {any} value Each value contained in the given object
 */

/**
 * Returns the first key of an object that complies with the given predicate
 * @param {Object} object
 * @param {PredicateFunction} predicate
 * @returns {object}
 */
export const getKey = (object, predicate) => {
    if (typeof object !== 'object' || Array.isArray(object))
        throw new Error('getKey expects an object as its first argument.');

    if (typeof predicate !== 'function')
        throw new Error('getKey expects a function as its second argument.');

    return Object.keys(object).find(key => predicate(object[key]));
};
