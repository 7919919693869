import React from 'react';

import { ResourcesDashboardTabs } from './ResourcesDashboardTabs';
import { StyledDashboardHeader } from './styled/DashboardHeader.styled';

const ResourcesDashboardHeader = ({ history }) => {
    return (
        <StyledDashboardHeader>
            <div className="admin-dashboard-header-wrapper">
                <div className="nav-list-items">
                    <ResourcesDashboardTabs history={history} />
                </div>
            </div>
        </StyledDashboardHeader>
    );
};

export { ResourcesDashboardHeader };
