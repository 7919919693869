import styled from 'styled-components';

const StyledOnLeaveFormSection = styled.div`
    button {
        margin-bottom: 20px;
        cursor: pointer;
        width: fit-content;
        color: var(--mainBlue);
        &.delete {
            margin-top: 5px;
            color: var(--red);
        }
    }
    button:disabled {
        cursor: default;
        color: var(--navi60);
    }
    .title {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .overall-on-leave {
        display: flex;
        flex-direction: column;
        .label {
            font-weight: 600;
        }
    }
    .section-inputs {
        .custom-datepicker {
            padding-bottom: 0px;
        }
    }
`;

export { StyledOnLeaveFormSection };
