const NAME_MAX_LENGTH = 50;

export const validateLocationName = (input, maxLength) => {
    if (!input?.trim()) {
        return 'Name is required';
    }

    if (input.trim().length >= maxLength) {
        return `Name must be between 1 and ${maxLength} characters long`;
    }

    return '';
};

export const validateLocation = values => {
    const errors = {};

    const locationNameError = validateLocationName(
        values.name,
        NAME_MAX_LENGTH
    );

    if (locationNameError) {
        errors.name = locationNameError;
    }

    return errors;
};

export const handleSubmit = (
    editModalLocationId,
    requestData,
    dispatch,
    createLocation,
    updateLocation
) => {
    const isEditMode = typeof editModalLocationId !== 'undefined';

    isEditMode
        ? dispatch(updateLocation(editModalLocationId, requestData))
        : dispatch(createLocation(requestData));
};
