import React, { useState } from 'react';

import { Formik } from 'formik';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { EditTimeLineWrapper } from './EditTimeLineWrapper';
import { GenerateEditField } from './GenerateEditField';
import { StyledEditTimeline } from './styled/EditView.styled';
import {
    updatePersonHistoryById,
    updateWorkingPeriodHistoryById
} from '../../../../redux/slices/history';
import { camelCasePropName } from '../../../utils/inputFieldHelper';

const EditView = ({ dataObj, handleChangeView, historicalState }) => {
    const [disabled, setDisabled] = useState(true);
    const dispatch = useDispatch();
    const isOnLeave = dataObj.initialPropName === 'OnLeave';
    const isSpecial =
        dataObj.initialPropName === 'EndDate' ||
        dataObj.initialPropName === 'ProfileCreation' ||
        isOnLeave;

    const {
        changedPropertyName,
        initialPropName,
        editValue,
        changedDate,
        forPeriod
    } = dataObj;

    const normalName = camelCasePropName(dataObj.initialPropName);

    const initialValues = () => {
        return {
            propertyName: initialPropName,
            oldValue: editValue,
            newValue: editValue,
            [normalName]: editValue,
            takesEffectOn: changedDate
        };
    };

    const validate = values => {
        const oldTakeEffectValue = moment(initialValues().takesEffectOn).format(
            'MM/DD/YYYY'
        );
        const changesSecondProp = values.takesEffectOn === oldTakeEffectValue;
        /** When there is no errors, no changes. Set disable true. */

        if (changesSecondProp) {
            setDisabled(true);
            return;
        }
        setDisabled(false);
    };

    const handleSubmit = values => {
        const requestObj = {
            propertyName: values.propertyName,
            takesEffectOn: moment(values.takesEffectOn).format('YYYY-MM-DD')
        };
        if (dataObj.personHistoryId !== null) {
            const personHistoryId = dataObj.personHistoryId;
            dispatch(
                updatePersonHistoryById(
                    personHistoryId,
                    requestObj,
                    historicalState
                )
            );
        } else if (dataObj.personWorkingPeriodHistoryId !== null) {
            const workingPeriodHistoryId = dataObj.personWorkingPeriodHistoryId;
            dispatch(
                updateWorkingPeriodHistoryById(
                    workingPeriodHistoryId,
                    requestObj,
                    historicalState
                )
            );
        }
    };

    return (
        <StyledEditTimeline>
            <Formik initialValues={initialValues()} validate={validate}>
                {props => {
                    const { values, setFieldValue } = props;

                    return (
                        <EditTimeLineWrapper
                            changedPropertyName={changedPropertyName}
                            disabled={disabled}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleSubmit={handleSubmit}
                            handleChangeView={handleChangeView}
                            isSpecial={isSpecial}
                            periodStartDate={forPeriod?.start}
                            periodEndDate={forPeriod?.end}
                        >
                            {isOnLeave ? (
                                <>
                                    <GenerateEditField
                                        changedPropertyName={'Start Date'}
                                        value={values[normalName].start}
                                        name="Start Date"
                                        setFieldValue={setFieldValue}
                                    />
                                    {!!values[normalName].end && (
                                        <GenerateEditField
                                            changedPropertyName={'End Date'}
                                            value={values[normalName].end}
                                            name="End Date"
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </>
                            ) : (
                                <GenerateEditField
                                    changedPropertyName={changedPropertyName}
                                    value={values[normalName]}
                                    name={normalName}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </EditTimeLineWrapper>
                    );
                }}
            </Formik>
        </StyledEditTimeline>
    );
};

export { EditView };
