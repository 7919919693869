import styled from 'styled-components';

const StyledAsyncSearchSelect = styled.div`
    .async-search-select {
        .async-search-select-label {
            font-family: 'Source-Sans-Pro';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }
`;

export { StyledAsyncSearchSelect };
