import styled from 'styled-components';

const StyledSubsetTabActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .filter-icons-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        .people-count-info {
            padding-right: 20px;
        }
    }
`;

export default StyledSubsetTabActions;
