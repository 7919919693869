import React from 'react';

import { validateLevel } from 'admin/utils/shared/levelsHelper';
import { Col, Row, Switch } from 'antd';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { CustomInput } from 'shared/components';
import { CustomButton } from 'shared/components/Button';
import { CloseButton } from 'shared/components/CloseButton';
import { setClassName } from 'utils/componentPropHelpers';

import { StyledLevelModal } from '../LevelModal/styled/LevelModal.styled';

const LevelModal = ({
    title,
    visible,
    setVisible = () => {},
    handleSubmit = () => {}
}) => {
    const { singleLevelData, isLoadingFetchSingle } = useSelector(
        state => state.level
    );

    const switchColumnStyle = {
        display: 'flex',
        flexDirection: 'column'
    };

    const switchesRowStyle = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '50px'
    };

    return (
        <Formik
            initialValues={singleLevelData}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={true}
            validate={validateLevel}
        >
            {props => {
                const {
                    setFieldValue,
                    setFieldTouched,
                    resetForm,
                    errors,
                    values,
                    touched,
                    isValid,
                    dirty
                } = props;

                const handleCloseModal = () => {
                    setVisible(false);
                    resetForm();
                };

                const handleOnSave = () => {
                    handleSubmit(values);
                    handleCloseModal();
                };

                const renderTitle = () => [
                    <div className="custom-modal-header" key="modal-title">
                        {title}
                        <CloseButton handleClose={handleCloseModal} />
                    </div>
                ];

                const renderFooter = () => (
                    <div className="custom-modal-footer" key="modal-footer">
                        <div className="button-section">
                            <CustomButton
                                text="Close"
                                type="normal footer-button"
                                onClick={handleCloseModal}
                                disabled={false}
                            />
                        </div>
                        <div className="button-section">
                            <CustomButton
                                text={'Save'}
                                type="filled footer-button"
                                onClick={handleOnSave}
                                disabled={!(dirty && isValid)}
                            />
                        </div>
                    </div>
                );

                return (
                    <StyledLevelModal
                        visible={visible && !isLoadingFetchSingle}
                        setVisible={handleCloseModal}
                        title={renderTitle()}
                        footer={renderFooter()}
                        data-testid="level-modal"
                    >
                        <Form>
                            <CustomInput
                                label="Name *"
                                placeholder="Name..."
                                value={values.name}
                                name="name"
                                className={setClassName(
                                    touched.name && errors.name
                                )}
                                isNormalChange={false}
                                onChange={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                allowClear={true}
                            />
                            <Row style={switchesRowStyle}>
                                <Col style={switchColumnStyle}>
                                    <div className="input-label">Status</div>
                                    <Switch
                                        checked={values.active}
                                        checkedChildren="Active"
                                        unCheckedChildren="Inactive"
                                        onChange={value => {
                                            setFieldValue('active', value);
                                        }}
                                        title="active"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </StyledLevelModal>
                );
            }}
        </Formik>
    );
};

export { LevelModal };
