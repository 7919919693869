import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const getAllBenefitTypes = async requestModel => {
    const { skip, take, sortBy, sortAsc, searchPhrase, loadActive } =
        requestModel;
    const res = await httpService.get(url.GET_ALL_BENEFIT_TYPES_PAGINATED(), {
        params: {
            skip,
            take,
            sortBy,
            sortAsc,
            searchPhrase,
            active: loadActive
        }
    });

    return res.data;
};

const getBenefitTypeById = async benefitTypeId => {
    const res = await httpService.get(
        url.GET_BENEFIT_TYPE_BY_ID(benefitTypeId)
    );

    return res.data;
};

const addBenefitType = async requestData => {
    const data = await httpService.post(
        url.CREATE_BENEFIT_TYPE(),
        requestData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
    return data.data;
};

const updateBenefitType = async (benefitTypeId, requestData) => {
    const data = await httpService.patch(
        url.UPDATE_BENEFIT_TYPE(benefitTypeId),
        requestData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
    return data.data;
};

const updateBenefitTypeVisibility = async benefitTypeId => {
    const data = await httpService.patch(
        url.UPDATE_BENEFIT_TYPE_VISIBILITY(benefitTypeId)
    );
    return data.data;
};

const deleteBenefitType = async benefitTypeId => {
    const data = await httpService.delete(
        url.DELETE_BENEFIT_TYPE(benefitTypeId)
    );
    return data.data;
};

export default {
    getAllBenefitTypes,
    getBenefitTypeById,
    addBenefitType,
    updateBenefitType,
    deleteBenefitType,
    updateBenefitTypeVisibility
};
