import styled from 'styled-components';

const StyledMentormaterTypeCounter = styled.div`
    .type-counter-content {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
    }
    .type-counter-content .type-box {
        display: flex;
        flex-direction: column;
        width: 455px;
        height: 354px;
        background: #ffffff;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin-bottom: 15px;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }
    .type-counter-content .type-box .label {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
    }
    .total-desc {
        color: #c4c4c4;
        font-style: normal;
        font-size: 16px;
        line-height: 23px;
    }
    .pie-tooltip {
        padding: 10px;
    }
`;

export { StyledMentormaterTypeCounter };
