import moment from 'moment';

import { camelCasePropName } from './inputFieldHelper';

// Returns array of historical changes after edit
const convertSpecialData = (values, takesEffectOn) => {
    let requestData = [];
    for (let i = 0; i < values.length; i++) {
        const currentItem = values[i];
        const requestObj = {
            historyId: currentItem.personWorkingPeriodHistoryId,
            propertyName: currentItem.changedPropertyName,
            newValue: currentItem.newValue,
            oldValue: currentItem.oldValue,
            takesEffectOn: moment(takesEffectOn).format('YYYY-MM-DD')
        };
        requestObj.newValue =
            requestObj.newValue === null ? null : requestObj.newValue;

        if (i === 0) {
            requestObj.newValue =
                requestObj.newValue === null
                    ? null
                    : moment(requestObj.newValue).format('YYYY-MM-DD');
            requestObj.oldValue =
                requestObj.oldValue === null
                    ? null
                    : moment(requestObj.oldValue).format('YYYY-MM-DD');
        }

        requestData.push(requestObj);
    }

    return requestData;
};

const convertNormalData = (data, type, takesEffectOn) => {
    let requestObj = {};
    if (type === 'workingPeriod') {
        requestObj = {
            historyId: data.personWorkingPeriodHistoryId,
            propertyName: data.initialPropName,
            newValue: data.editValue,
            oldValue: data.oldValue,
            takesEffectOn: moment(takesEffectOn).format('YYYY-MM-DD')
        };
        if (
            data.initialPropName === 'StartDate' ||
            data.initialPropName === 'EndDate'
        ) {
            requestObj.newValue =
                requestObj.newValue === null
                    ? null
                    : moment(requestObj.newValue).format('YYYY-MM-DD');
            requestObj.oldValue =
                requestObj.oldValue === null
                    ? null
                    : moment(requestObj.oldValue).format('YYYY-MM-DD');
        }
    } else {
        requestObj = {
            historyId: data.personHistoryId,
            propertyName: data.initialPropName,
            newValue: data.editValue,
            oldValue: data.oldValue,
            takesEffectOn: moment(takesEffectOn).format('YYYY-MM-DD')
        };
    }

    return requestObj;
};

// Converts any type of historical data to correct request format for edit many historical data
export const convertAndSeparateData = (data, takesEffectOn) => {
    let personHistoryData = [];
    let workingPeriodHistoryData = [];
    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element.isSpecial) {
            const items = element.items;
            const convertedData = convertSpecialData(items, takesEffectOn);

            workingPeriodHistoryData = [
                ...workingPeriodHistoryData,
                ...convertedData
            ];
        } else {
            if (element.personHistoryId !== null) {
                const requestObj = convertNormalData(
                    element,
                    'person',
                    takesEffectOn
                );
                personHistoryData = [...personHistoryData, requestObj];
            } else {
                const requestObj = convertNormalData(
                    element,
                    'workingPeriod',
                    takesEffectOn
                );
                workingPeriodHistoryData = [
                    ...workingPeriodHistoryData,
                    requestObj
                ];
            }
        }
    }

    return { personHistoryData, workingPeriodHistoryData };
};

export const generateSpecialInitialValues = ({ changedDate, items }) => {
    return {
        items: [
            ...items.map(item => {
                const normalName = camelCasePropName(item.changedPropertyName);
                return {
                    historyId: item.personWorkingPeriodHistoryId,
                    propertyName: item.changedPropertyName,
                    newValue: item.newValue,
                    oldValue: item.newValue,
                    normalizedName: normalName
                };
            })
        ],
        [camelCasePropName(items[0].changedPropertyName)]: items[0].newValue,
        [camelCasePropName(items[1].changedPropertyName)]: items[1].newValue,
        [camelCasePropName(items[2].changedPropertyName)]: items[2].newValue,
        [camelCasePropName(items[3].changedPropertyName)]: items[3].newValue,
        takesEffectOn: changedDate
    };
};
