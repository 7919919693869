import styled from 'styled-components';

export const StyledSelectedOptions = styled.div`
    .list {
        min-width: 320px;
        display: flex;
        align-items: center !important;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .selected-item {
        display: inline-flex;
        align-items: center;
        border-radius: 5px;
        background: #ddedff;
        color: #1c84fe;
        padding: 5px;
        margin-right: 15px;
        margin-bottom: 15px;
    }
    .name {
        margin-right: 5px;
    }
    .clear-item {
        border-left: 1px solid #e5e5e5;
        height: 30px;
        display: inline-flex;
        align-items: center;
        color: #1c84fe;
        margin-bottom: 15px;
    }
    .text {
        padding-left: 10px;
        margin-right: 5px;
        font-size: 16px;
    }
`;
