import styled from 'styled-components';

const StyledEmployeeDetailsHeader = styled.div`
    .employee-details-header-wrapper {
        display: flex;
        flex-direction: row;
        height: 100px;
        padding: 0px 90px;
        background-color: var(--secondaryBlue);
    }
    @media screen and (max-width: 1024px) {
        .employee-details-header-wrapper {
            padding: 0px 80px;
        }
    }
    @media screen and (max-width: 768px) {
        .employee-details-header-wrapper {
            padding: 0px 24px;
        }
    }
    .employee-details-container {
        display: flex;
        width: 100%;
    }
    .employee-basic-info {
        display: flex;
        flex-direction: column;
        justify-content: normal;
        margin-left: 14px;
        margin-top: 24px;
        width: 100%;
    }
    .employee-pic {
        margin-top: 16px;
        height: 120px;
        position: relative;
        .gender-indicator {
            background-color: var(--mainBlue);
            border-radius: 50%;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 90px;
            left: 90px;
            color: var(--primaryWhite);
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
        }
    }
    .avatar {
        object-fit: cover;
        background: white;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        border: 5px solid var(--primaryWhite);
        cursor: pointer;
    }

    .avatar.disabled {
        cursor: default;
    }

    .employee-name {
        display: flex;
        font-weight: bold;
        font-size: 24px;
        line-height: 34px;
        color: var(--primaryWhite);
        letter-spacing: 1px;
        .icon-holder {
            width: 30px;
            height: 30px;
            margin-left: 9px;
            margin-top: 2.5px;
            border-color: var(--primaryWhite) !important;
            border-radius: 6px;
            svg {
                width: 18px;
                height: 18px;
            }
            path {
                stroke: var(--primaryWhite) !important;
            }
        }
        .icon-holder.disabled {
            cursor: default;
        }
    }
    .employee-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 34px;
        color: var(--primaryWhite);
    }
    .employee-title-delete-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--primaryWhite);
    }
    .delete-section {
        font-weight: 500;
        font-size: 12px;
        line-height: 34px;
        cursor: pointer;
        font-style: normal;
    }

    .delete-section.disabled {
        cursor: default;
    }
`;

export { StyledEmployeeDetailsHeader };
