import styled from 'styled-components';

const StyledCustomTableWithFixedColumn = styled.div`
    .custom-table .ReactTable {
        //box-shadow: 0 5px 15px 0 rgba(91, 172, 169, 0.2);
        border: none;
        border-collapse: collapse;
    }
    .custom-table .ReactTable .rt-table {
        overflow-y: hidden;
        //width: 750px;
    }
    .ReactTable .rt-table {
        width: calc(100% + 5px);
    }
    .custom-table .ReactTable .-loading > div {
        top: 80%;
        position: relative;
    }
    .custom-table .ReactTable .rt-thead {
        background: #ffffff;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        height: 40px;
        margin: 20px 20px 0px 20px;
    }
    .custom-table .ReactTable .rt-th {
        text-align: center;
        font-size: 14px;
        font-weight: normal;
        padding: 10px 20px;
        text-align: left;
        color: var(--secondaryBlue);
        border: none;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .rt-th.rthfc-th-fixed.rthfc-th-fixed-left.rthfc-th-fixed-left-last {
        position: sticky;
    }
    .custom-table .ReactTable .rt-td {
        font-size: 14px;
        font-weight: normal;
        color: var(--darkNavy);
        padding: 10px 24px;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100px;
        overflow: hidden;
        align-self: center;
        height: auto;
        display: flex;
        align-items: center;
    }
    .custom-table .ReactTable .rt-tbody .rt-td {
        min-width: 80px !important;
        border-right: none;
    }
    .custom-table .ReactTable .rt-resizable-header {
        min-width: 80px !important;
        border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
    }
    .custom-table .ReactTable .rt-tbody .rt-tr-group {
        margin: 1px 20px;
        border-bottom: solid 1px rgba(0, 0, 0, 0.07);
    }
    .custom-table .ReactTable .rt-tbody .rt-tr-group:hover {
        box-sizing: border-box;
        box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
    }
    .custom-table .ReactTable .rt-noData {
        top: 47%;
        background: none;
    }
    .custom-table .rt-resizable-header-content {
        width: auto;
    }
    .custom-table .-sort-desc,
    .custom-table .-sort-asc {
        display: flex;
        box-shadow: none !important;
        background-color: var(--shadowSortedTableHeader);
        border-radius: 8px;
    }
    .custom-table .-sort-desc:after {
        content: '▼';
        color: var(--mainBlue);
        margin: 5px 5px;
        font-size: 7px;
    }
    .custom-table .-sort-asc:after {
        content: '▲';
        color: var(--mainBlue);
        margin: 5px 5px;
        font-size: 7px;
    }
    .rt-tbody {
        .rt-tr:hover {
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.04);
            border-radius: 10px;
        }
        .rt-tr:hover > .rt-td {
            background-color: var(--primaryWhite) !important;
        }
    }
    @media screen and (max-width: 1024px) {
        .custom-table .ReactTable .rt-table {
            width: 840px;
        }
    }
    @media screen and (max-width: 768px) {
        .custom-table .ReactTable .rt-table {
            width: 703px;
        }
        .custom-table .ReactTable .rt-tbody .rt-tr-group {
            margin: 0px;
        }
        .custom-table .ReactTable .rt-thead {
            margin: 0px;
        }
    }
`;

export { StyledCustomTableWithFixedColumn };
