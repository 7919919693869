import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const getAllDataForDashboard = async () => {
    const data = await httpService.get(url.GET_ALL_DASHBOARD_DATA());
    return data.data;
};

const getAllManagers = async () => {
    const data = await httpService.get(url.GET_ALL_MANAGERS());
    return data.data;
};

const getAllPosition = async () => {
    const data = await httpService.get(url.GET_ALL_POSITIONS());
    return data.data;
};

const getAllLevels = async () => {
    const data = await httpService.get(url.GET_ALL_LEVELS());
    return data.data;
};

const getAllDepartments = async () => {
    const data = await httpService.get(url.GET_ALL_DEPARTMENTS());
    return data.data;
};

const getAllIndustries = async () => {
    const data = await httpService.get(url.GET_ALL_INDUSTRIES());
    return data.data;
};

const getPeopleLocations = async () => {
    const data = await httpService.get(url.GET_PEOPLE_LOCATIONS());
    return data.data;
};

const getDiscountLocations = async () => {
    const data = await httpService.get(url.GET_DISCOUNT_LOCATIONS());
    return data.data;
};

const getAllTypesOfMentormater = async () => {
    const data = await httpService.get(url.GET_ALL_WORKSPLACE_TYPES());
    return data.data;
};

const getAllReasonsForLeaving = async () => {
    const data = await httpService.get(url.GET_ALL_REASON_FOR_LEAVE());
    return data.data;
};

const getAllLeaverTypes = async () => {
    const data = await httpService.get(url.GET_ALL_LEAVER_TYPES());
    return data.data;
};

const getAllVoluntaryTypes = async () => {
    const data = await httpService.get(url.GET_ALL_VOLUNTARY_TYPES());
    return data.data;
};

const getAllTypesOfWork = async () => {
    const data = await httpService.get(url.GET_ALL_TYPES_OF_WORK());
    return data.data;
};

const getAllHiringChannels = async () => {
    const data = await httpService.get(url.GET_ALL_HIRING_CHANNELS());
    return data.data;
};

const getAllClothSizes = async () => {
    const data = await httpService.get(url.GET_ALL_CLOTH_SIZES());
    return data.data;
};

const getAllBenefitTypes = async includePast => {
    const data = await httpService.get(
        url.GET_ALL_BENEFIT_TYPES(Boolean(includePast))
    );
    return data.data;
};

const getAllBenefitTypeNames = async () => {
    const data = await httpService.get(url.GET_ALL_BENEFIT_TYPE_NAMES());
    return data.data;
};

const getAllDocumentTypes = async () => {
    const data = await httpService.get(url.GET_ALL_DOCUMENT_TYPES());
    return data.data;
};

const getAllRelationTypes = async () => {
    const data = await httpService.get(url.GET_ALL_RELATION_TYPES());
    return data.data;
};

const getAllRelativeMembers = async personId => {
    const data = await httpService.get(url.GET_ALL_RELATIVE_MEMBERS(personId));
    return data.data;
};

const getAllBenefitsForReports = async () => {
    const data = await httpService.get(url.GET_ALL_BENEFITS_REPORTS());
    return data.data;
};

const getReportsTo = async (searchString, previousId) => {
    const requestData = {
        searchParam: searchString,
        previousId: previousId,
        take: 20
    };
    const data = await httpService.get(url.GET_REPORTS_TO(), {
        params: { ...requestData }
    });
    return data.data;
};

const getWorkEmails = async (searchString, previousId) => {
    const requestData = {
        searchParam: searchString,
        previousId: previousId,
        take: 20
    };

    const data = await httpService.get(url.GET_WORK_EMAILS(), {
        params: { ...requestData }
    });

    return data.data;
};

const getAllPermissions = async () => {
    const data = await httpService.get(url.GET_ALL_PERMISSIONS());
    return data.data;
};

const getAllRoles = async () => {
    const data = await httpService.get(url.GET_ALL_ROLES());
    return data.data;
};

const getBeneficiaries = async () => {
    const data = await httpService.get(url.GET_BENEFICIARIES());
    return data.data;
};

const getAllBenefitRequestStatuses = async () => {
    const data = await httpService.get(url.GET_ALL_BENEFIT_REQUEST_STATUSES());
    return data.data;
};

const getAllBenefitRequestTypes = async () => {
    const data = await httpService.get(url.GET_ALL_BENEFIT_REQUEST_TYPES());
    return data.data;
};

const getAllBenefitCoveredBy = async () => {
    const data = await httpService.get(url.GET_BENEFIT_COVERED_BY());
    return data.data;
};

const getAllBenefitPriceGroups = async () => {
    const data = await httpService.get(url.GET_BENEFIT_PRICE_GROUPS());
    return data.data;
};

export default {
    getAllDataForDashboard,
    getAllManagers,
    getAllPosition,
    getAllLevels,
    getAllDepartments,
    getAllIndustries,
    getPeopleLocations,
    getDiscountLocations,
    getAllTypesOfMentormater,
    getAllReasonsForLeaving,
    getAllLeaverTypes,
    getAllVoluntaryTypes,
    getAllTypesOfWork,
    getAllHiringChannels,
    getAllClothSizes,
    getAllBenefitTypes,
    getAllBenefitTypeNames,
    getAllDocumentTypes,
    getAllRelativeMembers,
    getAllRelationTypes,
    getReportsTo,
    getWorkEmails,
    getAllBenefitsForReports,
    getAllPermissions,
    getAllRoles,
    getBeneficiaries,
    getAllBenefitRequestStatuses,
    getAllBenefitRequestTypes,
    getAllBenefitCoveredBy,
    getAllBenefitPriceGroups
};
