const NAME_MAX_LENGTH = 50;

export const validateDepartmentName = (input, maxLength) => {
    if (!input?.trim()) {
        return 'Name is required';
    }

    if (input.trim().length > maxLength) {
        return `Name must be between 1 and ${maxLength} characters long`;
    }

    return '';
};

export const validateDepartment = values => {
    const errors = {};
    const departmentNameError = validateDepartmentName(
        values.name,
        NAME_MAX_LENGTH
    );

    if (departmentNameError) {
        errors.name = departmentNameError;
    }

    return errors;
};

export const handleSubmit = (
    editModalDepartmentId,
    requestData,
    dispatch,
    createDepartment,
    updateDepartment
) => {
    const isEditMode = typeof editModalDepartmentId !== 'undefined';

    isEditMode
        ? dispatch(updateDepartment(editModalDepartmentId, requestData))
        : dispatch(createDepartment(requestData));
};
