/*istanbul ignore file*/
import React from 'react';

import MicrosoftIcon from 'shared/theme/assets/icons/MicrosoftIcon';

import { StyledLoginButton } from './styled/LoginButton.styled';

const CustomMicrosoftLogin = ({ onClick }) => {
    return (
        <StyledLoginButton
            text={'Login with Microsoft'}
            onClick={onClick}
            iconComponent={MicrosoftIcon}
        />
    );
};

export { CustomMicrosoftLogin };
