import { useState } from 'react';

import Icon from '@ant-design/icons';
import { Select } from 'antd';

import { StyledCustomSelect } from './styled/CustomSelect.styled';
import { SelectIcon } from '../theme/assets/icons/SelectIcon';
import { SelectIconUp } from '../theme/assets/icons/SelectIconUp';

const { Option } = Select;

const DEFAULT_FILTER_OPTIONS = (input, option) => {
    return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const CustomSelect = ({
    label = '',
    value,
    onChange = () => {}, // setFieldValue needed when used in formik
    disabled = false,
    name,
    placeholder = '',
    options = [],
    allowClear = false,
    onBlur = () => {},
    filterOption = DEFAULT_FILTER_OPTIONS,
    id,
    ...rest
}) => {
    const {
        hasInput,
        onSearch = () => {},
        hideSuffix,
        className,
        onSelect
    } = rest;
    const [isOpen, setIsOpen] = useState(false);
    const handleChange = (val, option) => {
        onChange(name, val, option);
        onBlur(name, val);
    };
    const suffix = () => {
        if (hideSuffix) {
            return null;
        }

        return suffixIcon;
    };

    const suffixIcon = isOpen ? (
        <Icon component={SelectIconUp} />
    ) : (
        <Icon component={SelectIcon} />
    );

    const selectOptions = options.map((x, i) => (
        <Option
            key={i}
            value={x.value}
            title={x.title}
            className={x.isActive}
            disabled={x.disabled}
        >
            {x.label}
        </Option>
    ));

    const computedClassName = className ? ` ${className}` : '';

    return (
        <>
            <StyledCustomSelect>
                <div className={`custom-select-div${computedClassName}`}>
                    {label && (
                        <label htmlFor={id} className="custom-select-label">
                            {label}
                        </label>
                    )}
                    {hasInput ? (
                        <Select
                            data-testid={name}
                            id={id}
                            showSearch={true}
                            optionFilterProp="label"
                            filterOption={filterOption}
                            name={name}
                            placeholder={placeholder}
                            disabled={disabled}
                            value={
                                value === '' || value === null
                                    ? undefined
                                    : value
                            }
                            onChange={handleChange}
                            onSearch={onSearch}
                            suffixIcon={suffix()}
                            onDropdownVisibleChange={setIsOpen}
                            virtual={false}
                            allowClear={allowClear}
                            onSelect={onSelect}
                        >
                            {selectOptions}
                        </Select>
                    ) : (
                        <Select
                            data-testid={name}
                            id={id}
                            name={name}
                            placeholder={placeholder}
                            disabled={disabled}
                            value={value === '' ? undefined : value}
                            onChange={handleChange}
                            suffixIcon={suffix()}
                            onDropdownVisibleChange={setIsOpen}
                            virtual={false}
                            allowClear={allowClear}
                            onSelect={onSelect}
                        >
                            {selectOptions}
                        </Select>
                    )}
                </div>
            </StyledCustomSelect>
        </>
    );
};
