import { CloseIcon } from 'shared/theme/assets/icons/CloseIcon';

import { SelectedOption } from './SelectedOption';
import { StyledSelectedOptions } from './styled/SelectedOptions.styled';

const SelectedOptions = ({ options, onChange, values, handleValues }) => {
    const handleDeleteSelectedOptions = () => {
        handleValues('permissions', []);
        onChange([]);
    };

    return (
        <StyledSelectedOptions>
            <div className="container">
                {options.length > 0 && (
                    <div className="list">
                        {options.map(option => (
                            <SelectedOption
                                key={option.value}
                                item={option}
                                values={values}
                                value={option.value}
                                onChange={onChange}
                                options={options}
                                handleValues={handleValues}
                            />
                        ))}
                        <div className="clear-item">
                            <div className="text">Clear all</div>
                            <div
                                className="icon-holder"
                                onClick={handleDeleteSelectedOptions}
                            >
                                <CloseIcon
                                    onClick={handleDeleteSelectedOptions}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </StyledSelectedOptions>
    );
};

export { SelectedOptions };
