import { createSlice } from '@reduxjs/toolkit';

import { workingPeriodActions } from './workingPeriod';
import dropdownService from '../../people/services/dropdownService';
import {
    allNationalities,
    distinct,
    sortByNationalities
} from '../utils/dropDownHelper';

const initialState = {
    positions: [],
    managers: [],
    levels: [],
    departments: [],
    industries: [],
    locations: [],
    typesOfMentormater: [],
    reasonsForLeaving: [],
    leaverTypes: [],
    voluntaryTypes: [],
    typesOfWork: [],
    hiringChannels: [],
    clothSizes: [],
    nationalities: [],
    benefitTypes: [],
    benefitStatuses: [],
    benefitTypeNames: [],
    benefitRequestTypes: [],
    benefitCoveredBy: [],
    relativeMembers: [],
    relations: [],
    documentTypes: [],
    permissions: [],
    roles: [],
    benefitPriceGroups: [],
    beneficiaries: [],
    error: null,
    isLoading: false,
    isLoadingDropdownPersonalInfo: false,
    dropdownPersonalInfoError: null,
    isLoadingBenefit: false,
    benefitError: null,

    isLoading: false,
    benefitReportsTypes: [],
    isLoadingBenefitReports: false,
    benefitReportsError: null
};

const { reducer: dropdownReducer, actions } = createSlice({
    name: 'dropdown',
    initialState,
    reducers: {
        //Fetch Benefits drop down data
        fetchBenefitsStart: state => {
            state.isLoadingBenefit = true;
        },
        fetchBenefitsSuccess: (state, action) => {
            const {
                payload: { benefitTypes, documentTypes, relativeMembers }
            } = action;
            state.isLoadingBenefit = false;
            state.benefitTypes = benefitTypes.data;
            state.documentTypes = documentTypes.data;
            state.relativeMembers = relativeMembers.data;
            state.benefitError = null;
        },
        fetchRelativeMembersSuccess: (state, action) => {
            const {
                payload: { relativeMembers }
            } = action;
            state.isLoadingBenefit = false;
            state.relativeMembers = relativeMembers.data;
        },
        deleteRelativeMember: (state, action) => {
            state.relativeMembers = state.relativeMembers.filter(
                rm => rm.id !== action.payload
            );
        },
        fetchBenefitsFailure: (state, action) => {
            state.documentTypes = [];
            state.benefitTypes = [];
            state.isLoadingBenefit = false;
            state.benefitError = action.payload;
        },

        //Fetch Benefits Type Names
        fetchBenefitTypeNamesStart: state => {
            state.isLoading = true;
        },
        fetchBenefitTypeNamesSuccess: (state, action) => {
            state.isLoading = false;
            state.benefitTypeNames = action.payload;
            state.error = [];
        },
        fetchBenefitTypeNamesFailure: (state, action) => {
            state.isLoading = false;
            state.benefitTypeNames = [];
            state.error = action.payload;
        },

        //Fetch main information drop downs state
        fetchMainInfoDropDownStart: state => {
            state.isLoading = true;
        },
        fetchMainInfoDropDownSuccess: (state, action) => {
            const {
                payload: {
                    typesOfMentormater,
                    locations,
                    reasonsForLeaving,
                    leaverTypes,
                    voluntaryTypes,
                    levels,
                    departments,
                    industries,
                    typesOfWork,
                    hiringChannels,
                    positions,
                    managers
                }
            } = action;
            state.isLoading = false;
            state.typesOfMentormater = typesOfMentormater.data;
            state.locations = locations.data;
            state.reasonsForLeaving = reasonsForLeaving.data;
            state.leaverTypes = leaverTypes.data;
            state.voluntaryTypes = voluntaryTypes.data;
            state.levels = levels.data;
            state.departments = departments.data;
            state.industries = industries.data;
            state.typesOfWork = typesOfWork.data;
            state.hiringChannels = hiringChannels.data;
            state.positions = positions.data;
            state.managers = managers.data;
            state.error = null;
        },
        fetchMainInfoDropDownFailure: (state, action) => {
            state.locations = [];
            state.isLoading = false;
            state.error = action.payload;
        },

        //Fetch dashboard dropdowns
        fetchDashboardDropdownStart: state => {
            state.isLoading = true;
        },
        fetchDashboardDropdownSuccess: (state, action) => {
            const {
                payload: {
                    levels,
                    departments,
                    locations,
                    positionTitles,
                    managers,
                    workplaceTypes
                }
            } = action;
            state.isLoading = false;
            state.levels = levels;
            state.departments = departments;
            state.locations = locations;
            state.positions = positionTitles;
            state.managers = managers;
            state.typesOfMentormater = workplaceTypes;
            state.error = null;
        },
        fetchDashboardDropdownFailure: (state, action) => {
            state.locations = [];
            state.isLoading = false;
            state.error = action.payload;
        },

        //Fetch data for personal information
        fetchPersonalInformationDropDownsStart: state => {
            state.isLoadingDropdownPersonalInfo = true;
        },
        fetchPersonalInformationDropDownsSuccess: (state, action) => {
            const {
                payload: { clothSizes, nationalities, relations }
            } = action;
            state.isLoadingDropdownPersonalInfo = false;
            state.clothSizes = clothSizes.data;
            state.nationalities = nationalities;
            state.relations = relations.data;
            state.dropdownPersonalInfoError = null;
        },
        fetchPersonalInformationDropDownsFailure: (state, action) => {
            state.clothSizes = [];
            state.isLoadingDropdownPersonalInfo = false;
            state.dropdownPersonalInfoError = action.payload;
        },

        //Fetch Benefits drop down data
        fetchBenefitReportsStart: state => {
            state.isLoadingBenefitReports = true;
        },
        fetchBenefitReportsSuccess: (state, action) => {
            state.isLoadingBenefitReports = false;
            state.benefitReportsTypes = action.payload;
            state.benefitReportsError = null;
        },
        fetchBenefitReportsFailure: (state, action) => {
            state.isLoadingBenefitReports = false;
            state.benefitReportsTypes = [];
            state.benefitReportsError = action.payload;
        },

        //Fetch benefit request modal dropdowns
        fetchAllBenefitRequestStatusesStart: state => {
            state.isLoading = true;
        },
        fetchAllBenefitRequestStatusesSuccess: (state, action) => {
            state.isLoading = false;
            state.benefitStatuses = action.payload;
            state.error = [];
        },
        fetchAllBenefitRequestStatusesFailure: (state, action) => {
            state.isLoading = false;
            state.benefitStatuses = [];
            state.error = action.payload;
        },

        //Fetch request page filter dropdowns
        fetchRequestPageFilterDropdownsStart: state => {
            state.isLoading = true;
        },
        fetchRequestPageFilterDropdownsSuccess: (state, action) => {
            const {
                payload: { coveredBy, requestTypes, benefitTypes, locations }
            } = action;
            state.isLoading = false;
            state.benefitCoveredBy = coveredBy.data;
            state.benefitRequestTypes = requestTypes.data;
            state.benefitTypes = benefitTypes.data;
            state.locations = locations.data;
            state.error = null;
        },
        fetchRequestPageFilterDropdownsFailure: (state, action) => {
            state.isLoading = false;
            state.benefitCoveredBy = [];
            state.benefitRequestTypes = [];
            state.benefitTypes = [];
            state.locations = [];
            state.error = action.payload;
        },

        //Fetch covered by dropdown
        fetchCoveredByDropDownStart: state => {
            state.isLoading = true;
        },
        fetchCoveredByDropDownSuccess: (state, action) => {
            state.isLoading = false;
            state.benefitCoveredBy = action.payload;
            state.error = null;
        },
        fetchCoveredByDropDownFailure: (state, action) => {
            state.isLoading = false;
            state.benefitCoveredBy = [];
            state.error = action.payload;
        },

        //Fetch benefit price groups by dropdown
        fetchBenefitPriceGroupsDropDownStart: state => {
            state.isLoading = true;
        },
        fetchBenefitPriceGroupsDropDownSuccess: (state, action) => {
            state.isLoading = false;
            state.benefitPriceGroups = action.payload;
            state.error = null;
        },
        fetchBenefitPriceGroupsDropDownFailure: (state, action) => {
            state.isLoading = false;
            state.benefitPriceGroups = [];
            state.error = action.payload;
        },

        //Clear state for benefit reports
        clearBenefitsReports: state => {
            state.isLoadingBenefitReports = false;
            state.benefitReportsTypes = [];
            state.benefitReportsError = null;
        },

        //Fetch Permissions drop down data
        fetchPermissionsStart: state => {
            state.isLoading = true;
        },
        fetchPermissionsSuccess: (state, action) => {
            state.isLoading = false;
            state.permissions = action.payload;
            state.error = [];
        },
        fetchPermissionsFailure: (state, action) => {
            state.isLoading = false;
            state.permissions = [];
            state.error = action.payload;
        },

        //Fetch Roles drop down data
        fetchRolesStart: state => {
            state.isLoading = true;
        },
        fetchRolesSuccess: (state, action) => {
            state.isLoading = false;
            state.roles = action.payload;
            state.error = [];
        },
        fetchRolesFailure: (state, action) => {
            state.isLoading = false;
            state.roles = [];
            state.error = action.payload;
        },
        //Fetch Beneficiaries drop down data
        fetchBeneficiariesStart: state => {
            state.isLoading = true;
        },
        fetchBeneficiariesSuccess: (state, action) => {
            state.isLoading = false;
            state.beneficiaries = action.payload;
            state.error = [];
        },
        fetchBeneficiariesFailure: (state, action) => {
            state.isLoading = false;
            state.beneficiaries = [];
            state.error = action.payload;
        },

        //Clear state
        clearDropdown: state => {
            state.positions = [];
            state.managers = [];
            state.levels = [];
            state.departments = [];
            state.industries = [];
            state.locations = [];
            state.typesOfMentormater = [];
            state.reasonsForLeaving = [];
            state.leaverTypes = [];
            state.voluntaryTypes = [];
            state.typesOfWork = [];
            state.hiringChannels = [];
            state.clothSizes = [];
            state.nationalities = [];
            state.benefitTypes = [];
            state.benefitStatuses = [];
            state.benefitRequestTypes = [];
            state.benefitCoveredBy = [];
            state.documentTypes = [];
            state.relativeMembers = [];
            state.permissions = [];
            state.roles = [];
            state.beneficiaries = [];
            state.isLoading = false;
            state.error = null;
            state.isLoadingDropdownPersonalInfo = false;
            state.dropdownPersonalInfoError = null;
        }
    },
    extraReducers: {
        [workingPeriodActions.updateWorkingPeriodSuccess]: (state, action) => {
            const { metaData } = action.payload;
            const { positionObject, isPositionCreated } = metaData;
            if (isPositionCreated) {
                state.positions.push(positionObject);
            }
        }
    }
});

const fetchMainInformationDropDowns = () => {
    return async (dispatch, getState) => {
        const isLoading = getState().isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchMainInfoDropDownStart());
            const typesOfMentormater =
                await dropdownService.getAllTypesOfMentormater();
            const locations = await dropdownService.getPeopleLocations();
            const reasonsForLeaving =
                await dropdownService.getAllReasonsForLeaving();
            const leaverTypes = await dropdownService.getAllLeaverTypes();
            const voluntaryTypes = await dropdownService.getAllVoluntaryTypes();
            const levels = await dropdownService.getAllLevels();
            const departments = await dropdownService.getAllDepartments();
            const industries = await dropdownService.getAllIndustries();
            const typesOfWork = await dropdownService.getAllTypesOfWork();
            const hiringChannels = await dropdownService.getAllHiringChannels();
            const positions = await dropdownService.getAllPosition();
            const managers = await dropdownService.getAllManagers();
            dispatch(
                actions.fetchMainInfoDropDownSuccess({
                    typesOfMentormater,
                    locations,
                    reasonsForLeaving,
                    leaverTypes,
                    voluntaryTypes,
                    levels,
                    departments,
                    industries,
                    typesOfWork,
                    hiringChannels,
                    positions,
                    managers
                })
            );
        } catch (err) {
            dispatch(
                actions.fetchMainInfoDropDownFailure('Something went wrong')
            );
        }
    };
};

const fetchDashboardDropdowns = () => {
    return async (dispatch, getState) => {
        const isLoading = getState().isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchDashboardDropdownStart());
            const dropdownData = await dropdownService.getAllDataForDashboard();
            const {
                data: {
                    levels,
                    departments,
                    locations,
                    positionTitles,
                    managers,
                    workplaceTypes
                }
            } = dropdownData;
            dispatch(
                actions.fetchDashboardDropdownSuccess({
                    levels,
                    departments,
                    locations,
                    positionTitles,
                    managers,
                    workplaceTypes
                })
            );
        } catch (err) {
            dispatch(
                actions.fetchDashboardDropdownFailure('Something went wrong')
            );
        }
    };
};

const fetchPersonalInformationDropDowns = () => {
    return async (dispatch, getState) => {
        const isLoadingDropdownPersonalInfo =
            getState().isLoadingDropdownPersonalInfo;
        if (isLoadingDropdownPersonalInfo) {
            return;
        }

        try {
            dispatch(actions.fetchPersonalInformationDropDownsStart());
            const clothSizes = await dropdownService.getAllClothSizes();
            // In case there are duplicates we distinct them so dropdown component wont brake
            const nationalities = allNationalities
                .filter(distinct)
                .sort(sortByNationalities);
            const relations = await dropdownService.getAllRelationTypes();
            dispatch(
                actions.fetchPersonalInformationDropDownsSuccess({
                    clothSizes,
                    nationalities,
                    relations
                })
            );
        } catch (err) {
            dispatch(
                actions.fetchPersonalInformationDropDownsFailure(
                    'Something went wrong'
                )
            );
        }
    };
};

const fetchBenefitsDropDowns = personId => {
    return async (dispatch, getState) => {
        const isLoading = getState().dropdown.isLoadingBenefit;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchBenefitsStart());
            const benefitTypes = await dropdownService.getAllBenefitTypes(true);
            const documentTypes = await dropdownService.getAllDocumentTypes();
            const relativeMembers = await dropdownService.getAllRelativeMembers(
                personId
            );
            dispatch(
                actions.fetchBenefitsSuccess({
                    benefitTypes,
                    documentTypes,
                    relativeMembers
                })
            );
        } catch (err) {
            dispatch(actions.fetchBenefitsFailure('Something went wrong'));
        }
    };
};

const fetchBenefitTypeNamesDropDown = () => {
    return async (dispatch, getState) => {
        const isLoading = getState().dropdown.isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchBenefitTypeNamesStart());
            const { data } = await dropdownService.getAllBenefitTypeNames();
            dispatch(actions.fetchBenefitTypeNamesSuccess(data));
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            dispatch(actions.fetchBenefitTypeNamesFailure(errorMessage));
        }
    };
};

const updateRelativeMembersData = personId => {
    return async (dispatch, getState) => {
        const isLoading = getState().dropdown.isLoadingBenefit;
        if (isLoading) {
            return;
        }

        try {
            const relativeMembers = await dropdownService.getAllRelativeMembers(
                personId
            );
            dispatch(actions.fetchRelativeMembersSuccess({ relativeMembers }));
        } catch (err) {
            dispatch(actions.fetchBenefitsFailure('Something went wrong'));
        }
    };
};

const deleteDropdownRelativeMember = personRelativeMemberId => {
    return async dispatch => {
        dispatch(actions.deleteRelativeMember(personRelativeMemberId));
    };
};

const fetchBenefitReportsDropDowns = () => {
    return async (dispatch, getState) => {
        const isLoading = getState().dropdown.isLoadingBenefitReports;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchBenefitReportsStart());
            const { data } = await dropdownService.getAllBenefitsForReports();
            dispatch(actions.fetchBenefitReportsSuccess(data));
        } catch (err) {
            dispatch(
                actions.fetchBenefitReportsFailure('Something went wrong')
            );
        }
    };
};

const fetchPermissionsDropDown = () => {
    return async (dispatch, getState) => {
        const isLoading = getState().isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchPermissionsStart());
            const { data } = await dropdownService.getAllPermissions();
            dispatch(actions.fetchPermissionsSuccess(data));
        } catch (err) {
            dispatch(actions.fetchPermissionsFailure('Something went wrong'));
        }
    };
};

const fetchRolesDropDown = () => {
    return async (dispatch, getState) => {
        const isLoading = getState().isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchRolesStart());
            const { data } = await dropdownService.getAllRoles();
            dispatch(actions.fetchRolesSuccess(data));
        } catch (err) {
            dispatch(actions.fetchRolesFailure('Something went wrong'));
        }
    };
};

const fetchBeneficiariesDropDown = () => {
    return async (dispatch, getState) => {
        const isLoading = getState().isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchBeneficiariesStart());
            const { data } = await dropdownService.getBeneficiaries();
            dispatch(actions.fetchBeneficiariesSuccess(data));
        } catch (err) {
            dispatch(actions.fetchBeneficiariesFailure('Something went wrong'));
        }
    };
};

const fetchAllBenefitRequestStatuses = () => {
    return async dispatch => {
        try {
            dispatch(actions.fetchAllBenefitRequestStatusesStart());

            const { data: statuses } =
                await dropdownService.getAllBenefitRequestStatuses();

            dispatch(actions.fetchAllBenefitRequestStatusesSuccess(statuses));
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            dispatch(
                actions.fetchAllBenefitRequestStatusesFailure(errorMessage)
            );
        }
    };
};

const fetchCoveredByDropDown = () => {
    return async (dispatch, getState) => {
        const isLoading = getState().dropdown.isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchCoveredByDropDownStart());
            const { data } = await dropdownService.getAllBenefitCoveredBy();

            dispatch(actions.fetchCoveredByDropDownSuccess(data));
        } catch (err) {
            dispatch(
                actions.fetchCoveredByDropDownFailure('Something went wrong')
            );
        }
    };
};

const fetchBenefitPriceGroupsDropDown = () => {
    return async (dispatch, getState) => {
        const isLoading = getState().dropdown.isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchBenefitPriceGroupsDropDownStart());
            const { data } = await dropdownService.getAllBenefitPriceGroups();

            dispatch(actions.fetchBenefitPriceGroupsDropDownSuccess(data));
        } catch (err) {
            dispatch(
                actions.fetchBenefitPriceGroupsDropDownFailure(
                    'Something went wrong'
                )
            );
        }
    };
};

const fetchRequestPageFilterDropDowns = () => {
    return async (dispatch, getState) => {
        const isLoading = getState().dropdown.isLoading;
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchRequestPageFilterDropdownsStart());
            const getAllBenefitCoveredBy =
                dropdownService.getAllBenefitCoveredBy();
            const getAllBenefitRequestTypes =
                dropdownService.getAllBenefitRequestTypes();
            const getAllBenefitTypes = dropdownService.getAllBenefitTypes();
            const getPeopleLocations = dropdownService.getPeopleLocations();

            const [coveredBy, requestTypes, benefitTypes, locations] =
                await Promise.all([
                    getAllBenefitCoveredBy,
                    getAllBenefitRequestTypes,
                    getAllBenefitTypes,
                    getPeopleLocations
                ]);

            dispatch(
                actions.fetchRequestPageFilterDropdownsSuccess({
                    coveredBy,
                    requestTypes,
                    benefitTypes,
                    locations
                })
            );
        } catch (err) {
            dispatch(
                actions.fetchRequestPageFilterDropdownsFailure(
                    'Something went wrong'
                )
            );
        }
    };
};

const resetDropdown = () => {
    return dispatch => {
        dispatch(actions.clearDropdown());
    };
};

export {
    dropdownReducer,
    actions as dropdownActions,
    fetchMainInformationDropDowns,
    fetchDashboardDropdowns,
    fetchPersonalInformationDropDowns,
    fetchBenefitsDropDowns,
    fetchBenefitTypeNamesDropDown,
    fetchBenefitReportsDropDowns,
    fetchAllBenefitRequestStatuses,
    fetchRequestPageFilterDropDowns,
    fetchPermissionsDropDown,
    fetchRolesDropDown,
    fetchBeneficiariesDropDown,
    resetDropdown,
    fetchCoveredByDropDown,
    updateRelativeMembersData,
    deleteDropdownRelativeMember,
    fetchBenefitPriceGroupsDropDown
};
