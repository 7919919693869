import { emailRegexString } from '../../../../shared/constants/regexConstants';

export const validateForm = ({ personalId, personalEmail, addressById }) => {
    let errors = {};

    if (personalId) {
        let personalIdError;
        if (personalId?.trim() === '') {
            personalIdError = `Personal Id cannot contain only spaces.`;
        }
        errors = { ...errors, personalId: personalIdError };
    }
    if (personalEmail) {
        let personalEmailError;
        /** This validation is on purpose like that, with RegExp there are some cases. */
        if (!emailRegexString.test(personalEmail)) {
            personalEmailError = `Personal Email should contain only valid characters.`;
        } else if (personalEmail.length > 100) {
            personalEmailError = `Personal Email should contains up to 100 characters.`;
        }
        errors = { ...errors, personalEmail: personalEmailError };
    }
    if (addressById) {
        let addressByIdError;
        if (addressById?.trim() === '') {
            addressByIdError = `Address By Id cannot contain only spaces.`;
        } else if (addressById?.length > 200) {
            addressByIdError = `Address By Id should contains up to 200 characters.`;
        }
        errors = { ...errors, addressById: addressByIdError };
    }
    return errors;
};
