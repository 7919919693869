import React, { useState } from 'react';

import moment from 'moment';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';

import { EditView } from './EditTimeline/EditView';
import { ViewSpecialChange } from './EditTimeline/ViewSpecialChange';
import { NormalView } from './NormalView';
import { generateSpecialInitialValues } from '../../utils/timeLineHelper';

const FORMAT = 'MM/DD/YYYY';

const formatDate = date => {
    return date !== null ? moment(date).format(FORMAT) : null;
};

const TimelineItem = ({
    item,
    indexKey,
    historicalState,
    onDeleteCallback,
    setItemsForEdit
}) => {
    const [optionsDivVisible, setOptionsDivVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const handleOptionsClick = () => {
        setOptionsDivVisible(!optionsDivVisible);
    };

    const handleChangeView = () => {
        setEditMode(!editMode);
    };

    const {
        changedPropertyName,
        changedDate,
        oldValue,
        newValue,
        changedBy,
        forPeriod
    } = item;

    const formattedDate = moment(changedDate).format(FORMAT);
    let formattedOldDate = oldValue;
    let formattedNewDate = newValue;
    if (
        changedPropertyName === 'Profile Creation' ||
        changedPropertyName === 'End Date'
    ) {
        formattedOldDate = formatDate(oldValue);
        formattedNewDate = formatDate(newValue);
    }

    const position = indexKey % 2 == 0 ? 'right' : 'left';

    const normalViewObjectData = {
        changedPropertyName,
        oldValue: formattedOldDate,
        newValue: formattedNewDate,
        changedBy,
        personHistoryId: item.personHistoryId,
        personWorkingPeriodHistoryId: item.personWorkingPeriodHistoryId,
        items: item.items,
        changedDate: item.changedDate,
        initialPropName: item.initialPropName,
        deleteNewValue: item.editValue,
        deleteOldValue: item.oldEditValue,
        forPeriod:
            changedPropertyName === 'On Leave'
                ? {
                      start: formatDate(forPeriod.start),
                      end: formatDate(forPeriod.end)
                  }
                : null
    };

    return (
        <VerticalTimelineElement
            key={indexKey}
            className={`vertical-timeline-element--work ${editMode && 'edit'}`}
            position={position}
            contentArrowStyle={{
                borderRight: '6px solid var(--mainBlue)'
            }}
            date={formattedDate}
            iconStyle={{
                background: 'rgb(33, 150, 243)'
            }}
        >
            {editMode ? (
                item.isSpecial ? (
                    <ViewSpecialChange
                        handleChangeView={handleChangeView}
                        initialValues={generateSpecialInitialValues(item)}
                    />
                ) : (
                    <EditView
                        dataObj={item}
                        handleChangeView={handleChangeView}
                        historicalState={historicalState}
                    />
                )
            ) : (
                <NormalView
                    itemIndex={indexKey}
                    handleOptionsClick={handleOptionsClick}
                    optionsDivVisible={optionsDivVisible}
                    handleChangeView={handleChangeView}
                    changedPropertyName={changedPropertyName}
                    normalViewObjectData={normalViewObjectData}
                    onDeleteCallback={onDeleteCallback}
                    setItemsForEdit={setItemsForEdit}
                />
            )}
        </VerticalTimelineElement>
    );
};

export { TimelineItem };
