import { useEffect, useState } from 'react';

import { handleSubmit } from 'admin/utils/shared/departmentHelper';
import SubsetTabSearch from 'people/components/Dashboard/PeopleSubsetTab/SubsetTabSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
    createDepartment,
    fetchDepartments,
    updateSearchPhrase,
    resetNotificationState,
    changeOrder,
    resetFilters,
    fetchSingleDepartment,
    updateDepartment,
    resetDepartment,
    changeActiveState
} from 'redux/slices/department';
import { CustomButton } from 'shared/components';
import { ActivityStatusPicker } from 'shared/components/ActivityStatusPicker';
import Footer from 'shared/components/layout/Footer';
import { successNotification } from 'shared/components/Notifications';
import useInfiniteScroll from 'shared/hooks/useInfiniteScroll';

import { StyledDepartmentsSubtab } from './styled/DepartmentsSubtab.styled';
import { DepartmentsTable } from './Table/DepartmentsTable';
import { DepartmentModal } from '../shared/DepartmentModal/DepartmentModal';

export const DepartmentsSubtab = ({ handleCreateColumns, permissions }) => {
    const dispatch = useDispatch();

    const {
        departments,
        searchPhrase,
        isLoading,
        totalCount,
        isAddedSuccessfully,
        isEditedSuccessfully,
        sortBy,
        sortAsc,
        departmentsTotalCount,
        isEditMode,
        loadActive
    } = useSelector(state => state.department);

    useEffect(() => {
        if (isEditedSuccessfully) {
            successNotification('Successfully updated the Department.');
        } else if (isAddedSuccessfully) {
            successNotification('Successfully added a Department.');
        }

        dispatch(resetNotificationState());
    }, [isAddedSuccessfully, isEditedSuccessfully]);

    const [sortState, setSortState] = useState([
        { id: 'updatedOn', desc: true }
    ]);

    const [isDepartmentModalOpen, setIsDepartmentModalOpen] = useState(false);
    const [editModalDepartmentId, setEditModalDepartmentId] =
        useState(undefined);
    const [fetchDepartmentForEdit, setFetchDepartmentForEdit] = useState(false);

    useEffect(() => {
        if (editModalDepartmentId && fetchDepartmentForEdit) {
            dispatch(fetchSingleDepartment(editModalDepartmentId));
            setIsDepartmentModalOpen(true);
            setFetchDepartmentForEdit(false);
        }
    }, [fetchDepartmentForEdit]);

    useEffect(() => {
        dispatch(fetchDepartments(false));
    }, [searchPhrase, sortBy, sortAsc, loadActive]);

    useEffect(() => {
        return () => {
            dispatch(resetFilters());
        };
    }, []);

    const handleSortUpdate = value => {
        setSortState(value);
        dispatch(changeOrder(value));
        dispatch(resetDepartment());
    };

    // infinite scroll handling
    const boundaryRef = useInfiniteScroll({
        onLoadMore: () => {
            dispatch(fetchDepartments(true));
        },
        isLoading,
        hasMore: Boolean(departments.length < departmentsTotalCount)
    });

    const handleValueUpdate = (_, searchPhrase) => {
        dispatch(updateSearchPhrase(searchPhrase));
    };

    const handleDepartmentModalOpen = (isOpen, departmentId) => {
        if (isOpen && departmentId) {
            setEditModalDepartmentId(departmentId);
            setFetchDepartmentForEdit(true);
            return;
        }

        setIsDepartmentModalOpen(isOpen);
        setEditModalDepartmentId(undefined);
        dispatch(resetDepartment());
    };

    return (
        <>
            <StyledDepartmentsSubtab>
                <div className="departments-container">
                    <div className="departments-extra-header">
                        <SubsetTabSearch
                            search={{ handleValueUpdate }}
                            allowLettersOnly={false}
                        />
                        <ActivityStatusPicker
                            activeButtonOnClick={() =>
                                dispatch(changeActiveState(true))
                            }
                            inactiveButtonOnClick={() =>
                                dispatch(changeActiveState(false))
                            }
                            loadActive={loadActive}
                        />
                        <CustomButton
                            type={'normal'}
                            text={'Add Department'}
                            onClick={() => handleDepartmentModalOpen(true)}
                            disabled={!permissions.canAddResources}
                        />
                    </div>
                    <DepartmentsTable
                        totalCount={totalCount}
                        handleCreateTableColumns={() =>
                            handleCreateColumns(
                                handleDepartmentModalOpen,
                                permissions
                            )
                        }
                        departmentsData={departments}
                        pageSize={10}
                        loading={isLoading}
                        noDataText={'No Departments Found'}
                        hasPagination={false}
                        sorted={sortState}
                        handleSortUpdate={handleSortUpdate}
                        boundaryRef={boundaryRef}
                        permissions={permissions}
                    />

                    <DepartmentModal
                        title={
                            isEditMode ? 'Edit Department' : 'Add Department'
                        }
                        visible={isDepartmentModalOpen}
                        setVisible={handleDepartmentModalOpen}
                        handleSubmit={formikValues =>
                            handleSubmit(
                                editModalDepartmentId,
                                formikValues,
                                dispatch,
                                createDepartment,
                                updateDepartment,
                                handleDepartmentModalOpen
                            )
                        }
                    />
                </div>
            </StyledDepartmentsSubtab>
            {!Boolean(departments.length < totalCount) && (
                <Footer className={'special'} />
            )}
        </>
    );
};
