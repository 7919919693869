/* eslint-disable import/order */
import React, { useContext, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import useInfiniteScroll from '../../../shared/hooks/useInfiniteScroll';
import SubsetTabActions from './PeopleSubsetTab/SubsetTabActions';
import { EmployeesTable } from './Table/EmployeesTable';
import peopleService from '../../services/peopleService';
import Footer from '../../../shared/components/layout/Footer';
import { StyledPeopleSubsetTab } from './styled/PeopleSubsetTab.styled';
import { checkTokenExp, redirectTokenExp } from '../../../utils/tokenHelper';
import PermissionsContext from 'shared/utils/permissionStore';

const DEFAULT_HANDLERS = {
    loading: true,
    error: undefined,
    currentTotal: undefined,
    allPeopleTotalCount: undefined
};

const TAB = 'people';

const DEFAULT_VALUES = {
    departmentId: undefined,
    levelId: undefined,
    locationId: undefined,
    gender: undefined,
    managerId: undefined,
    positionId: undefined,
    mentormaterTypeId: undefined,
    startDate: undefined,
    startDateBtnState: undefined,
    endDate: undefined,
    endDateBtnState: undefined,
    startYear: undefined,
    startMonth: undefined
};

let searchPhraseCache = undefined;

export const PeopleSubsetTab = ({
    subset,
    handleCreateColumns,
    defaultSort,
    tabType,
    redirectFilterState,
    updateCount
}) => {
    const DEFAULT_STATE = {
        skip: 0,
        take: 10,
        searchPhrase: searchPhraseCache,
        filterValues: !isEmpty(redirectFilterState)
            ? redirectFilterState
            : DEFAULT_VALUES,
        sort: defaultSort
    };
    const [people, setPeople] = useState([]);
    const [state, setState] = useState(DEFAULT_STATE);
    const [handlers, setHandlers] = useState(DEFAULT_HANDLERS);
    const [typeOfLayout, setTypeOfLayout] = useState('base');

    const { loading, currentTotal, allPeopleTotalCount } = handlers;
    const { sort, skip, take, filterValues } = state;

    const permissions = useContext(PermissionsContext).permissions;

    useEffect(() => {
        fetchData();
    }, [state]);

    useEffect(() => {
        return () => (searchPhraseCache = undefined);
    }, []);

    const fetchData = async () => {
        setHandlers(prev => {
            return {
                ...prev,
                loading: DEFAULT_HANDLERS.loading,
                error: DEFAULT_HANDLERS.error
            };
        });
        try {
            if (checkTokenExp()) {
                redirectTokenExp();
            }
            const resp = await peopleService.getAllEmployees(
                subset.value,
                state
            );
            const {
                data,
                metadata: { totalCount }
            } = resp;
            setHandlers(prev => {
                return {
                    ...prev,
                    currentTotal: data.length,
                    allPeopleTotalCount: totalCount,
                    loading: false
                };
            });
            setPeople(data);
            updateCount(data.length);
        } catch (err) {
            setHandlers({
                ...DEFAULT_HANDLERS,
                error: err.message,
                loading: false
            });
        }
    };

    const handleValueUpdate = (which, val) => {
        searchPhraseCache = val;
        setState(previous => {
            return { ...previous, [which]: val };
        });
    };

    // function to update filter state
    const handleFilterValueUpdate = (which, val, btnState) => {
        let whichBtnState =
            which === 'startDate'
                ? 'startDateBtnState'
                : which === 'endDate'
                ? 'endDateBtnState'
                : '';
        let newFilters = {
            ...filterValues,
            [which]: val,
            [whichBtnState]: btnState
        };
        setState(previous => {
            return { ...previous, filterValues: newFilters };
        });
    };

    const handleUpdate = which => value => {
        setState({ ...state, [which]: value });
    };

    // infinite scroll handling
    const boundaryRef = useInfiniteScroll({
        onLoadMore: () => {
            handleLoadMoreData();
        },
        loading,
        hasMore: Boolean(people.length < allPeopleTotalCount)
    });

    const handleLoadMoreData = async () => {
        setHandlers(prev => {
            return {
                ...prev,
                loading: DEFAULT_HANDLERS.loading,
                error: DEFAULT_HANDLERS.error
            };
        });
        try {
            const resp = await peopleService.getAllEmployees(subset.value, {
                ...state,
                skip: currentTotal,
                take: take
            });
            setPeople(prev => {
                let tempt = [...prev];
                tempt.push(...resp.data);
                return tempt;
            });

            const currentTotalCount = currentTotal + resp.data.length;
            setHandlers(prev => {
                return {
                    ...prev,
                    currentTotal: currentTotalCount,
                    allPeopleTotalCount: resp.metadata.totalCount,
                    loading: false
                };
            });
            updateCount(currentTotalCount);
        } catch {
            setHandlers({ ...DEFAULT_HANDLERS, loading: false });
        }
    };

    // change layout type
    const handleLayoutChange = type => {
        setTypeOfLayout(type);
    };

    const handleTagRemove = selection => {
        const { stateValueProp } = selection;
        handleFilterValueUpdate(stateValueProp, undefined);
    };

    const handleFiltersReset = () => {
        setState(previous => {
            return { ...previous, filterValues: DEFAULT_VALUES };
        });
    };

    return (
        <>
            <StyledPeopleSubsetTab>
                <div className="all-people-container">
                    <SubsetTabActions
                        totalCount={allPeopleTotalCount}
                        layOut={{ typeOfLayout, handleLayoutChange }}
                        filter={{
                            state,
                            tab: TAB,
                            filterValues,
                            handleFilterValueUpdate,
                            handleTagRemove,
                            handleFiltersReset
                        }}
                        search={{
                            handleValueUpdate
                        }}
                        download={{
                            state,
                            subset,
                            disableDownload: !permissions.canExportList,
                            tab: TAB
                        }}
                        allowLettersOnly={true}
                        searchPhrase={state.searchPhrase}
                    />

                    {/* switch people view based on layout setting */}
                    {typeOfLayout === 'base' ? (
                        <EmployeesTable
                            tabType={tabType}
                            totalCount={currentTotal}
                            defaultSort={defaultSort}
                            handleCreateTableColumns={handleCreateColumns}
                            peopleData={people}
                            pageSize={
                                10
                            } /* added to handle if there is no data so that we show only 10 rows */
                            loading={loading}
                            noDataText={'No Employees Found'}
                            hasPagination={false}
                            sorted={sort}
                            handleSortUpdate={handleUpdate('sort')}
                            boundaryRef={boundaryRef}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </StyledPeopleSubsetTab>
            {!Boolean(people.length < allPeopleTotalCount && skip) && (
                <Footer className={'special'} />
            )}
        </>
    );
};
