import styled from 'styled-components';

const StyledIcons = styled.div`
    .icons {
        display: flex;
        .icon {
            padding-right: 10px;
            svg {
                cursor: pointer;
                width: 18px !important;
                height: 18px !important;
            }
        }
        .icon-edit {
            svg {
                path {
                    stroke: var(--mainBlue);
                }
            }
        }
        .icon-delete {
            svg {
                path {
                    stroke: var(--red);
                }
            }
        }
    }
`;

const StyledLocation = styled.div`
    text-wrap: wrap !important;
`;

export { StyledIcons, StyledLocation };
