import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const getPositionById = async id => {
    const data = await httpService.get(url.GET_POSITION_BY_ID(id));
    return data.data;
};

const addPosition = async title => {
    const data = await httpService.post(url.CREATE_POSITION(), { title });
    return data.data;
};

export default { getPositionById, addPosition };
