import React, { useCallback, useEffect, useState } from 'react';

import { Input } from 'antd';
import { useDebouncedCallback } from 'use-debounce';

import StyledTextArea from './styled/TextArea.styled';

const INPUT_DELAY = 500;

export const TextArea = ({
    className,
    value,
    onChange,
    rows,
    label,
    name,
    placeholder,
    disabled = false,
    allowClear = false,
    limitToMaxLength = false,
    maxLength,
    showCount,
    hasError,
    id
}) => {
    const [innerValue, setInnerValue] = useState('');

    useEffect(() => {
        if (value) {
            setInnerValue(value);
        } else {
            setInnerValue('');
        }
    }, [value]);

    const debouncedHandleChange = useDebouncedCallback(event => {
        onChange && onChange(name, event.target.value);
    }, INPUT_DELAY);

    const handleChange = useCallback(event => {
        event.persist();

        setInnerValue(event.currentTarget.value);
        debouncedHandleChange(event);
    }, []);

    const classes = [className ? className : '', hasError ? 'error' : ''].join(
        ' '
    );

    return (
        <StyledTextArea className={classes}>
            {label && (
                <label className={'input-label'} htmlFor={id}>
                    {label}
                </label>
            )}
            <Input.TextArea
                allowClear={allowClear}
                name={name}
                id={id}
                placeholder={placeholder}
                onChange={handleChange}
                value={innerValue}
                disabled={disabled}
                rows={rows}
                maxLength={limitToMaxLength ? maxLength : undefined}
                showCount={limitToMaxLength ? showCount : undefined}
            />
            {showCount && !limitToMaxLength && maxLength && (
                <div className="counter" id="counter">
                    {innerValue.length} / {maxLength}
                </div>
            )}
        </StyledTextArea>
    );
};
