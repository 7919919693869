import { useCallback, useState, useMemo, useEffect } from 'react';

import { Upload } from 'antd';
import moment from 'moment';
import { CustomButton } from 'shared/components';
import { UploadModal } from 'shared/components/UploadModal';

export const ExtendedFileManager = ({
    files,
    filesFormikFieldName,
    filesToDeleteFormikFieldName,
    setFieldValue,
    setFieldTouched,
    uploadModalTitle
}) => {
    const [temporaryFilesList, setTemporaryFilesList] = useState([]); //the file list shown immediately after choosing what the file(s) you select for upload
    const [finalFilesList, setFinalFilesList] = useState(files); // the file list including existing files, plus files selected for upload
    const [replacementFiles, setReplacementFiles] = useState(); //the file list that holds new, duplicate files that are duplicate to files from the other two lists
    const [filesForDeletion, setFilesForDeletion] = useState([]); //list of file ids for files that are going to be deleted from blob storage

    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
        useState(false);
    const [hasSetInitialFilesListAlready, setHasSetInitialFilesListAlready] =
        useState(false);

    const onRemoveFiles = file => {
        if (file?.name) {
            setTemporaryFilesList(prev =>
                prev.filter(f => f.name !== file.name)
            );
        } else {
            setTemporaryFilesList([]);
            setIsUploadModalVisible(false);
        }
        return false;
    };

    const beforeUploadFiles = useCallback(
        file => {
            const replacementFile = getReplacementFile(
                file,
                temporaryFilesList,
                finalFilesList
            );

            const existingFileNeedsReplacing = replacementFile !== null;

            if (existingFileNeedsReplacing) {
                setReplacementFiles(prev => {
                    const previousFiles = prev ?? [];
                    return [
                        replacementFile,
                        ...getUniqueFiles(replacementFile, previousFiles)
                    ];
                });

                setIsConfirmationModalVisible(true);

                setTemporaryFilesList(prev => {
                    const previousFiles = prev ?? [];
                    return [
                        replacementFile,
                        ...getUniqueFiles(replacementFile, previousFiles)
                    ];
                });

                return false;
            }

            setTemporaryFilesList(prev => {
                return [...prev, file];
            });

            return false;
        },
        [temporaryFilesList, finalFilesList]
    );

    const onUploadClick = () => {
        if (temporaryFilesList?.length > 0) {
            const newFileNames = temporaryFilesList.map(fl => fl.name);
            const existingFilesWithoutCopies = finalFilesList.filter(
                p => !newFileNames.includes(p.name)
            );

            let uniqueFiles = [...temporaryFilesList];

            if (existingFilesWithoutCopies.length > 0) {
                uniqueFiles = uniqueFiles.concat(existingFilesWithoutCopies);
            }

            setFinalFilesList(uniqueFiles);
            setReplacementFiles([]);
            setFieldValue(filesFormikFieldName, uniqueFiles);
            onRemoveFiles();
        }
    };

    const getUploadedFilesCount = () =>
        `Uploaded files: ${
            finalFilesList.filter(x => x?.createdOnUtc)?.length ?? 0
        }`;

    const handlePreview = file => {
        if (file.isNew) {
            return;
        }

        const newWindow = window.open(
            file.url,
            '_blank',
            'noopener,noreferrer'
        );
        if (newWindow) newWindow.opener = null;
    };

    const getFileNamesWithDates = fileList => {
        return (
            fileList?.map(file => {
                return {
                    ...file,
                    name: file.createdOnUtc
                        ? file.name +
                          ' (' +
                          moment(file.createdOnUtc).format('MM/DD/YYYY') +
                          ')'
                        : file.name
                };
            }) ?? []
        );
    };

    const getFileNameWithoutDate = fileName =>
        fileName.replace(/\s{1}\(\d{2}\/\d{2}\/\d{4}\)/, '');

    const getReplacementFile = (file, temporaryFilesList, finalFilesList) => {
        const existingFileNames = [
            ...(temporaryFilesList ?? []),
            ...finalFilesList
        ].map(ef => ef.name);

        if (
            existingFileNames?.some(
                existingFileName => existingFileName === file.name
            )
        ) {
            return file;
        }

        return null;
    };

    const getUniqueFiles = (replacementFile, files) => {
        return files.filter(
            f =>
                getFileNameWithoutDate(f.name) !==
                getFileNameWithoutDate(replacementFile.name)
        );
    };

    const handleUploadConfirmation = () => {
        const filesNeedReplacing = replacementFiles?.length > 0;

        if (filesNeedReplacing) {
            const replacementFileNames = replacementFiles.map(rf => rf.name);
            const newFiles =
                temporaryFilesList?.filter(
                    f => !replacementFileNames.includes(f.name)
                ) ?? [];
            setTemporaryFilesList([...newFiles, ...replacementFiles]);
            setIsConfirmationModalVisible(false);
            setReplacementFiles([]);
            return;
        }
    };

    const closeConfirmationModalCallback = () => {
        setTemporaryFilesList(previousTemporaryFilesList => {
            return previousTemporaryFilesList.filter(
                cff => !finalFilesList.map(ftbu => ftbu.name).includes(cff.name)
            );
        });
        setReplacementFiles([]);

        setIsConfirmationModalVisible(false);
    };

    const fileProps = useMemo(() => {
        return {
            showUploadList: {
                showRemoveIcon: true,
                showPreviewIcon: true
            },
            onRemove: file => {
                file.name = getFileNameWithoutDate(file.name);
                setFinalFilesList(previousFinalFiles => {
                    const fileToDelete = previousFinalFiles.find(
                        f => file.url && f.name === file.name
                    );

                    const currentAndNewFilesToDelete = filesForDeletion;

                    if (fileToDelete?.id) {
                        currentAndNewFilesToDelete.push(fileToDelete.id);
                    }

                    setFilesForDeletion(currentAndNewFilesToDelete);

                    setFieldValue(
                        filesToDeleteFormikFieldName,
                        currentAndNewFilesToDelete
                    );

                    const remainingFiles = previousFinalFiles.filter(
                        f => f.name !== file.name
                    );

                    setFieldValue(filesFormikFieldName, remainingFiles);
                    setFieldTouched(filesFormikFieldName, true);

                    return remainingFiles;
                });
            },
            fileList: getFileNamesWithDates(finalFilesList),
            handlePreview: handlePreview
        };
    }, [finalFilesList]);

    //for compatibility with Offers modal
    useEffect(() => {
        if (
            finalFilesList.length === 0 &&
            files.length > 0 &&
            !hasSetInitialFilesListAlready
        ) {
            setHasSetInitialFilesListAlready(true);
            setFinalFilesList(files);
        }
    }, [files]);

    return (
        <>
            <UploadModal
                title={uploadModalTitle}
                multiple={true}
                visible={isUploadModalVisible}
                onRemove={onRemoveFiles}
                beforeUpload={beforeUploadFiles}
                fileSelectorButtonText={'Browse files'}
                fileList={temporaryFilesList}
                onUploadClick={onUploadClick}
                isConfirmationModalVisible={isConfirmationModalVisible}
                handleConfirmation={handleUploadConfirmation}
                setConfirmationModalVisible={setIsConfirmationModalVisible}
                closeConfirmationModalCallback={closeConfirmationModalCallback}
            />
            <CustomButton
                width="100%"
                type="normal"
                text="Upload documents"
                onClick={() => setIsUploadModalVisible(true)}
            />
            <div className="custom-upload-div">
                <div className="custom-upload-label">
                    {getUploadedFilesCount()}
                </div>
                <Upload {...fileProps} />
            </div>
        </>
    );
};
