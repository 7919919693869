import axios from 'axios';

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    patch: axios.patch,
    delete: axios.delete,
    all: axios.all,
    axios
};
