import { isEmpty } from 'lodash';
import moment from 'moment';

export const buildPagedQueryData = (subset, requestModel) => {
    const { skip, take, searchPhrase, filterValues, sort } = requestModel;
    let baseParams = transformPagedQueryData(skip, take);

    if (!isEmpty(sort)) {
        baseParams += transformSortQueryData(sort);
    }

    if (subset) {
        baseParams += transformCategoryQueryData(subset);
    }

    // if there are any provided filters that have values, add them to the baseParams variable
    if (!isEmpty(filterValues)) {
        baseParams += transformFilterQueryData(filterValues);
    }

    //if there is given search string
    if (!isEmpty(searchPhrase)) {
        baseParams += transformSearchQueryData(searchPhrase);
    }

    return baseParams;
};

const transformPagedQueryData = (skip, take) => {
    return `Skip=${skip}&Take=${take}`;
};

const transformSortQueryData = sortParams => {
    const { id, desc } = sortParams[0];
    const sortBy = id.charAt(0).toUpperCase() + id.slice(1);
    const sortAsc =
        (sortBy === 'Name' || sortBy === 'EndDate') && desc === false
            ? true
            : !desc;

    return `&SortBy=${sortBy}&SortAsc=${sortAsc}`;
};

const transformCategoryQueryData = category => {
    return `&EmployeeCategory=${category}`;
};

const transformSearchQueryData = searchedValue => {
    return `&Query=${searchedValue}`;
};

const transformFilterQueryData = filterValues => {
    let filterParams = ``;

    const {
        locationId,
        locationName,
        benefitTypeId,
        benefitTypeName,
        startDate,
        endDate
    } = filterValues;

    if (locationId !== undefined) {
        filterParams += `&LocationId=${locationId}`;
    }

    if (locationName !== undefined) {
        filterParams += `&LocationName=${locationName}`;
    }

    if (benefitTypeId !== undefined) {
        filterParams += `&BenefitTypeId=${benefitTypeId}`;
    }
    if (benefitTypeName !== undefined) {
        filterParams += `&BenefitTypeName=${benefitTypeName}`;
    }

    if (startDate !== undefined) {
        filterParams += `&StartDate=${moment(startDate).format('YYYY-MM-DD')}`;
    }

    if (endDate !== undefined) {
        filterParams += `&EndDate=${moment(endDate).format('YYYY-MM-DD')}`;
    }

    return filterParams;
};
