/* istanbul ignore file */
import axios from 'axios';
import qs from 'qs';

import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const getEmbedReportData = async reportCategory => {
    const data = await httpService.get(
        url.GET_EMBED_REPORT_DATA(reportCategory)
    );
    return data.data;
};

const getBenefitReports = async requestData => {
    const requestUrl = url.GET_BENEFIT_REPORT();

    const res = await axios({
        url: requestUrl,
        params: { ...requestData },
        paramsSerializer: params => {
            return qs.stringify(params);
        },
        method: 'GET',
        responseType: 'blob'
    });
    return res;
};

export default {
    getEmbedReportData,
    getBenefitReports
};
