import { useEffect, useState } from 'react';

import { UserModal } from 'admin/components/shared/UserModal/UserModal';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteUser,
    fetchSingleUser,
    resetShowDeleteMessage,
    resetShowUpdateMessage,
    updateUser
} from 'redux/slices/user';
import {
    CustomConfirmationModal,
    CustomTableWithFixedColumns,
    errorNotification,
    successNotification
} from 'shared/components';

export const UsersTable = ({
    totalCount,
    defaultSort,
    handleCreateTableColumns,
    usersData,
    pageSize,
    loading,
    noDataText,
    hasPagination,
    sorted,
    handleSortUpdate,
    boundaryRef,
    permissions
}) => {
    const [editModalOpen, setEditModalOpen] = useState(false);

    const dispatch = useDispatch();

    const { showUpdateMessage, showDeleteMessage, editError, deleteError } =
        useSelector(state => state.user);

    const [selectedUser, setSelectedUser] = useState(null);
    const [deleteConfirmationModal, setDeleteConfirmationModal] =
        useState(false);

    const handleOpenEdit = user => {
        setSelectedUser(user);
        setEditModalOpen(true);
        dispatch(fetchSingleUser(user.userId));
    };

    const handleOpenDelete = user => {
        setDeleteConfirmationModal(true);
        setSelectedUser(user);
    };

    const handleEditUser = userData => {
        window.scrollTo(0, 0);
        dispatch(updateUser(userData.userId, { roleId: userData.roleId }));
        setEditModalOpen(false);
    };

    const handleDelete = () => {
        dispatch(deleteUser(selectedUser.userId));
        setDeleteConfirmationModal(false);
    };

    const closeModalCallback = () => {
        setSelectedUser(null);
    };

    useEffect(() => {
        if (showUpdateMessage) {
            successNotification('Successfully updated a user.');
            dispatch(resetShowUpdateMessage());
        }
    }, [showUpdateMessage]);

    useEffect(() => {
        if (showDeleteMessage) {
            successNotification('Successfully deleted a user.');
            dispatch(resetShowDeleteMessage());
        }
    }, [showDeleteMessage]);

    useEffect(() => {
        if (editError) {
            errorNotification(editError);
        }
    }, [editError]);

    useEffect(() => {
        if (deleteError) {
            errorNotification(deleteError);
        }
    }, [deleteError]);

    return (
        <div className="table-wrapper">
            <CustomTableWithFixedColumns
                total={totalCount}
                defaultSorted={defaultSort}
                columns={handleCreateTableColumns(
                    handleOpenEdit,
                    handleOpenDelete,
                    permissions
                )}
                data={usersData}
                pageSize={pageSize}
                loading={loading}
                noDataText={noDataText}
                hasPagination={hasPagination}
                sorted={sorted}
                handleSortingChange={handleSortUpdate}
                sortable={true}
                resizable={false}
            />
            <section ref={boundaryRef} />
            {editModalOpen && (
                <UserModal
                    title={'Edit User'}
                    visible={editModalOpen}
                    isEdit={true}
                    setVisible={setEditModalOpen}
                    handleSubmit={handleEditUser}
                    closeCallback={closeModalCallback}
                />
            )}
            {deleteConfirmationModal && (
                <CustomConfirmationModal
                    title={'Confirmation'}
                    visible={deleteConfirmationModal}
                    setVisible={setDeleteConfirmationModal}
                    contentText={`The following person "${selectedUser.userEmail}" will no longer have access to the system!`}
                    handleConfirm={handleDelete}
                    closeCallback={closeModalCallback}
                />
            )}
        </div>
    );
};
