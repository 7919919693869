import React from 'react';

import { StyledDetailsInnerContainer } from './styled/DetailsInnerContainer.styled';
import { ExpandIcon } from '../../../shared/theme/assets/icons/ExpandIcon';
import { ShrinkIcon } from '../../../shared/theme/assets/icons/ShrinkIcon';

const DetailsInnerContainer = ({
    bodyTitle,
    statusText,
    isOpen,
    handleSection,
    children
}) => {
    const boxResizeIcon = isOpen ? <ShrinkIcon /> : <ExpandIcon />;

    return (
        <StyledDetailsInnerContainer>
            <div className="info-body">
                <div className="info-body-header">
                    <div className="flex">
                        <div className="info-body-title">{bodyTitle}</div>
                        <div className="info-body-status">{statusText}</div>
                    </div>
                    <div
                        data-testid="shrink-button"
                        className="info-body-button"
                        onClick={handleSection}
                    >
                        {boxResizeIcon}
                    </div>
                </div>

                {isOpen && <>{children}</>}
            </div>
        </StyledDetailsInnerContainer>
    );
};

export { DetailsInnerContainer };
