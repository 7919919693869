import React, { useEffect } from 'react';

import { Checkbox, Switch } from 'antd';
import { Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchBeneficiariesDropDown,
    fetchBenefitReportsDropDowns
} from 'redux/slices';
import { CustomSelect } from 'shared/components';
import { CustomInput } from 'shared/components/Input';
import RichText from 'shared/components/RichText';
import Column from 'shared/components/styled/Column.styled';
import Row from 'shared/components/styled/Row.styled';
import { UploadImage } from 'shared/components/UploadImage';
import { setClassName } from 'utils/componentPropHelpers';
import { mapDataForDropdown } from 'utils/dropdownHelper';

import { BenefitPriceWrapper } from './BenefitPriceWrapper';
import StyledBenefitPriceSection from './styled/BenefitPriceSection.styled';
import { RequestPropertyCollection } from '../../../../benefits/constants/RequestPropertyCollection';
import { MAX_LENGTH_RICH_TEXT } from '../../../utils/shared/benefitModalHelper';

const BenefitDetailsTab = ({
    isEditModal,
    formikValues,
    values,
    setFieldValue,
    setFieldTouched,
    touched,
    errors
}) => {
    const dispatch = useDispatch();
    const { beneficiaries, benefitReportsTypes } = useSelector(x => x.dropdown);
    const coveredByOptions = useSelector(
        state => state.dropdown.benefitCoveredBy
    );

    const handleOnChangeBenefitCoverType = (checked, id) => {
        let nextBenefitTypeCoveredBy;
        if (checked) {
            nextBenefitTypeCoveredBy = [
                option.id.toString(),
                ...values.benefitTypeCoveredBy
            ];
        } else {
            nextBenefitTypeCoveredBy = values.benefitTypeCoveredBy.filter(
                btcb => btcb != option.id
            );
        }

        nextBenefitTypeCoveredBy.sort((a, b) => a - b);

        setFieldValue('benefitTypeCoveredBy', nextBenefitTypeCoveredBy);
    };

    useEffect(() => {
        dispatch(fetchBeneficiariesDropDown());
        dispatch(fetchBenefitReportsDropDowns());
    }, [dispatch]);

    return (
        <Column height="100%">
            <UploadImage
                onUpload={async file => {
                    await setFieldValue('benefitTypeImage', file);
                    await setFieldValue('benefitTypeImageAltText', '');
                    await setFieldTouched('benefitTypeImage');
                }}
                imageText="Select Image *"
                initialImage={values.benefitTypeImage}
                maxSize={MAX_IMAGE_SIZE}
                externalError={setClassName(
                    touched.benefitTypeImage ? errors?.benefitTypeImage : false
                )}
                hideDeleteButton={true}
            />

            <CustomInput
                label="Image Alt Text *"
                placeholder="Image Alt Text..."
                value={values.benefitTypeImageAltText}
                name="benefitTypeImageAltText"
                className={setClassName(
                    touched.benefitTypeImageAltText
                        ? errors?.benefitTypeImageAltText
                        : false
                )}
                isNormalChange={false}
                onChange={setFieldValue}
                allowClear={true}
                setFieldTouched={setFieldTouched}
            />

            <CustomInput
                label="Flexible Benefit Name *"
                placeholder="Type..."
                value={values.benefitTypeName}
                name={'benefitTypeName'}
                className={setClassName(
                    touched.benefitTypeName ? errors?.benefitTypeName : false
                )}
                isNormalChange={false}
                onChange={setFieldValue}
                allowClear={true}
                setFieldTouched={setFieldTouched}
            />

            <CustomInput
                label="Short Flexible Benefit Description *"
                placeholder="short description..."
                value={values.benefitTypeShortDescription}
                name={'benefitTypeShortDescription'}
                className={setClassName(
                    touched.benefitTypeShortDescription
                        ? errors?.benefitTypeShortDescription
                        : false
                )}
                isNormalChange={false}
                onChange={setFieldValue}
                allowClear={true}
                setFieldTouched={setFieldTouched}
            />

            <RichText
                label="Description"
                placeholder="Benefit Description ..."
                value={values.benefitTypeDescription.html}
                name={'benefitTypeDescription'}
                hasError={
                    touched.benefitTypeDescription
                        ? errors?.benefitTypeDescription
                        : false
                }
                onChange={(name, values) =>
                    setFieldValue(name, {
                        html: values.html,
                        plainText: values.plainText
                    })
                }
                maxLength={MAX_LENGTH_RICH_TEXT}
                id={'benefitTypeDescription'}
                setFieldTouched={setFieldTouched}
            />
            <BenefitPriceWrapper
                isEditModal={isEditModal}
                initialValues={formikValues?.benefitPrices}
                benefitPrices={values.benefitPrices}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                benefitPriceErrors={errors.benefitPrices}
                touched={touched}
            />
            <StyledBenefitPriceSection>
                <Row>
                    <Column width={'100%'}>
                        <CustomSelect
                            label={'Beneficiary'}
                            name={'benefitBeneficiaryId'}
                            placeholder={'Select a beneficiary'}
                            value={values.benefitBeneficiaryId}
                            onChange={(name, val) => {
                                setFieldValue(name, val);

                                if (!val) {
                                    setFieldValue(
                                        'benefitRequestProperty',
                                        null
                                    );
                                }
                            }}
                            options={mapDataForDropdown(beneficiaries)}
                            allowClear={true}
                        />
                    </Column>
                    <Column width={'100%'}>
                        <CustomSelect
                            label={'Request Property'}
                            name={'benefitRequestProperty'}
                            placeholder={'Select request property'}
                            value={values.benefitRequestProperty}
                            onChange={setFieldValue}
                            disabled={!values.benefitBeneficiaryId}
                            options={RequestPropertyCollection}
                            allowClear={true}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column width={'100%'}>
                        <CustomSelect
                            label="Report Group"
                            name={'benefitGroupId'}
                            value={values.benefitGroupId} //TODO
                            placeholder={'Type or select benefit'}
                            onChange={setFieldValue}
                            options={mapDataForDropdown(benefitReportsTypes)}
                            allowClear
                        />
                    </Column>

                    <Column width={'100%'}>
                        <CustomInput
                            label="Contentful ID"
                            placeholder={'Type Contentful ID'}
                            name={'benefitContentfulId'}
                            value={values.benefitContentfulId}
                            className={setClassName(
                                errors?.benefitContentfulId
                            )}
                            isNormalChange={false}
                            onChange={setFieldValue}
                            allowClear={true}
                            setFieldTouched={setFieldTouched}
                        />
                    </Column>
                </Row>
            </StyledBenefitPriceSection>
            <Column gap="5px">
                <span id="coveredBy" className="input-label">
                    Covered By
                </span>
                <div
                    role="group"
                    aria-labelledby="coveredBy"
                    className="checkbox-group"
                >
                    {coveredByOptions.map(({ id, description }) => (
                        <div className="checkbox" key={id}>
                            <Checkbox
                                name="benefitTypeCoveredBy"
                                value={id.toString()}
                                onChange={change => {
                                    handleOnChangeBenefitCoverType(
                                        change.target.checked,
                                        id
                                    );
                                }}
                                onClick={() => {
                                    setFieldTouched(
                                        'benefitTypeCoveredBy',
                                        true
                                    );
                                }}
                                id={description}
                                checked={values.benefitTypeCoveredBy.find(
                                    x => x == id
                                )}
                            />
                            <label htmlFor={description}>{description}</label>
                        </div>
                    ))}
                </div>
            </Column>

            <Row justifyContent="space-between">
                <span className="input-label">
                    Make this Flexible Benefit visible on MentorNet
                </span>
                <Row>
                    <Switch
                        checked={values.benefitTypeSelectable}
                        checkedChildren="YES"
                        unCheckedChildren="NO"
                        onChange={checked => {
                            setFieldValue('benefitTypeSelectable', checked);
                        }}
                    />
                </Row>
            </Row>
        </Column>
    );
};

export default BenefitDetailsTab;

const MAX_IMAGE_SIZE = 5242880; // 1024*1024*5 -> 5MB
