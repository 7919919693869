import React, { useEffect, useState } from 'react';

import discountService from 'admin/services/discountService';
import {
    handleDiscountLocationOnChange,
    handleDiscountCategoryOnChange,
    handleDiscountCategoryOnSearch,
    handleDiscountCategoryOnInputKeyDown,
    handleOnActiveStatusChange,
    setEditModalState,
    MAX_LENGTH_RICH_TEXT,
    validateDiscount
} from 'admin/utils/shared/discountModalHelper';
import { Switch } from 'antd';
import { Form, Formik } from 'formik';
import dropdownService from 'people/services/dropdownService';
import { useSelector, useDispatch } from 'react-redux';
import { resetDiscount } from 'redux/slices/discount';
import { CustomInput } from 'shared/components';
import { CustomButton } from 'shared/components/Button';
import { CloseButton } from 'shared/components/CloseButton';
import { CustomMultiSelect } from 'shared/components/MultiSelect';
import RichText from 'shared/components/RichText';
import { SelectWithCreate } from 'shared/components/SelectWithCreate';
import Column from 'shared/components/styled/Column.styled';
import Row from 'shared/components/styled/Row.styled';
import { UploadImage } from 'shared/components/UploadImage';
import { setClassName } from 'utils/componentPropHelpers';
import { mapDataForDropdown } from 'utils/dropdownHelper';

import { ExtendedFileManager } from '../../../../shared/components/ExtendedFileManager';
import { SelectedOptions } from '../DiscountModal/SelectedOptions';
import { StyledDiscountModal } from '../DiscountModal/styled/DiscountModal.styled';

const DiscountModal = ({
    title,
    visible,
    setVisible = () => {},
    handleSubmit = () => {}
}) => {
    const dispatch = useDispatch();
    const { singleDiscountData, isEditMode, isLoadingFetchSingle } =
        useSelector(state => state.discount);
    const [locations, setLocations] = useState([]);
    const [selectedDiscountLocations, setSelectedDiscountLocations] = useState(
        []
    );
    const [discounCategorySearchValue, setDiscountCategorySearchValue] =
        useState('');
    const [categories, setCategories] = useState([]);
    const [discountSearchedCategory, setDiscountSearchedCategory] =
        useState('');

    const getLocationsForDropdown = async () => {
        const locations = await dropdownService.getDiscountLocations();
        setLocations(mapDataForDropdown(locations.data));
    };

    const getCategoriesForDropdown = async () => {
        const categories = await discountService.getCategories();
        setCategories(mapDataForDropdown(categories.data));
    };

    useEffect(() => {
        getLocationsForDropdown();
        getCategoriesForDropdown();
    }, []);

    useEffect(() => {
        if (isEditMode && singleDiscountData.discountName) {
            setEditModalState(
                singleDiscountData,
                setSelectedDiscountLocations,
                setDiscountCategorySearchValue,
                setDiscountSearchedCategory
            );
        }
    }, [singleDiscountData]);

    const renderTitle = () => [
        <div className="custom-modal-header" key="modal-title">
            {title}
            <CloseButton handleClose={() => handleCloseModal(false)} />
        </div>
    ];

    const handleCloseModal = visible => {
        dispatch(resetDiscount());
        setVisible(visible);
    };

    return (
        <Formik
            initialValues={singleDiscountData}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={true}
            validate={validateDiscount}
        >
            {props => {
                const {
                    setFieldValue,
                    setFieldTouched,
                    errors,
                    values,
                    touched,
                    isValid,
                    dirty
                } = props;
                return (
                    <StyledDiscountModal
                        visible={
                            visible &&
                            !isLoadingFetchSingle &&
                            locations.length > 0 &&
                            categories.length > 0
                        }
                        setVisible={() => handleCloseModal(false)}
                        title={renderTitle()}
                        footer={
                            <div
                                className="custom-modal-footer"
                                key="modal-footer"
                            >
                                <div className="button-section">
                                    <CustomButton
                                        text="Close"
                                        type="normal footer-button"
                                        onClick={() => handleCloseModal(false)}
                                        disabled={false}
                                    />
                                </div>
                                <div className="button-section">
                                    <CustomButton
                                        text={'Save'}
                                        type="filled footer-button"
                                        onClick={() => {
                                            handleSubmit(values);
                                        }}
                                        disabled={!(dirty && isValid)}
                                    />
                                </div>
                            </div>
                        }
                        data-testid="discount-modal"
                    >
                        <Form>
                            <UploadImage
                                imageText="Select Image *"
                                externalError={
                                    touched.discountImage &&
                                    errors.discountImageError
                                }
                                onUpload={async file => {
                                    await setFieldValue('discountImage', file);
                                    await setFieldValue(
                                        'discountImageAltText',
                                        ''
                                    );
                                    await setFieldTouched('discountImage');
                                }}
                                initialImage={values.discountImage}
                                hideDeleteButton
                                maxSize={MAX_IMAGE_SIZE}
                            />
                            <CustomInput
                                label="Image Alt Text *"
                                placeholder="Image Alt Text..."
                                value={values.discountImageAltText}
                                name="discountImageAltText"
                                className={setClassName(
                                    touched.discountImageAltText &&
                                        errors.discountImageAltText
                                )}
                                isNormalChange={false}
                                onChange={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                allowClear={true}
                            />
                            <CustomInput
                                className={setClassName(
                                    touched.discountName && errors.discountName
                                )}
                                label="Discount *"
                                placeholder="Discount..."
                                isNormalChange={false}
                                allowClear={true}
                                value={values.discountName}
                                name={'discountName'}
                                onChange={setFieldValue}
                                setFieldTouched={setFieldTouched}
                            />
                            <CustomInput
                                label="Short Description *"
                                placeholder="Short description..."
                                value={values.discountShortDescription}
                                name="discountShortDescription"
                                className={setClassName(
                                    touched.discountShortDescription &&
                                        errors.discountShortDescription
                                )}
                                isNormalChange={false}
                                onChange={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                allowClear={true}
                            />
                            <SelectWithCreate
                                label="Category *"
                                name={'discountCategory'}
                                className={setClassName(
                                    touched.discountCategory &&
                                        errors.discountCategory
                                )}
                                value={values.discountCategory}
                                placeholder={'Category...'}
                                onChange={(name, value) => {
                                    handleDiscountCategoryOnChange(
                                        name,
                                        value,
                                        categories,
                                        setFieldValue
                                    );
                                }}
                                options={categories}
                                showSearch={true}
                                onSearch={value =>
                                    handleDiscountCategoryOnSearch(
                                        value,
                                        setDiscountCategorySearchValue,
                                        setDiscountSearchedCategory
                                    )
                                }
                                onInputKeyDown={event =>
                                    handleDiscountCategoryOnInputKeyDown(
                                        event,
                                        setFieldValue,
                                        discountSearchedCategory,
                                        setDiscountSearchedCategory,
                                        categories,
                                        setCategories
                                    )
                                }
                                searchValue={discounCategorySearchValue}
                                setFieldTouched={setFieldTouched}
                            />
                            <CustomMultiSelect
                                canCreate={true}
                                label="Location *"
                                className={setClassName(
                                    touched.discountLocations &&
                                        errors.discountLocations
                                )}
                                hasSelectAll={false}
                                disableSearch={false}
                                value={values.discountLocations}
                                name={'discountLocations'}
                                options={locations}
                                onChange={(name, _, values) =>
                                    handleDiscountLocationOnChange(
                                        name,
                                        values,
                                        setFieldValue,
                                        setFieldTouched
                                    )
                                }
                                onSelection={setSelectedDiscountLocations}
                                selected={selectedDiscountLocations}
                                placeholder={'Location...'}
                                onClick={isMenuOpened => {
                                    setFieldTouched(
                                        'discountLocations',
                                        !isMenuOpened
                                    );
                                }}
                            />
                            {selectedDiscountLocations && (
                                <SelectedOptions
                                    options={selectedDiscountLocations}
                                    values={values.discountLocations}
                                    onChange={values => {
                                        handleDiscountLocationOnChange(
                                            'discountLocations',
                                            values,
                                            setFieldValue,
                                            setFieldTouched
                                        );

                                        setSelectedDiscountLocations(values);
                                    }}
                                    handleValues={setFieldValue}
                                />
                            )}

                            <CustomInput
                                label="Website"
                                placeholder="Website..."
                                value={values.discountWebsite}
                                name={'discountWebsite'}
                                isNormalChange={false}
                                onChange={setFieldValue}
                                allowClear={true}
                            />
                            <Row justifyContent="space-between">
                                <Column width="40%">
                                    <CustomInput
                                        className={setClassName(
                                            touched.discountMinimum &&
                                                errors.discountMinimum
                                        )}
                                        label="Discount minimum % *"
                                        placeholder="Discount minimum..."
                                        value={values.discountMinimum}
                                        name={'discountMinimum'}
                                        isNormalChange={false}
                                        onChange={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        allowClear={true}
                                        type="number"
                                    />
                                </Column>
                                <Column width="40%">
                                    <CustomInput
                                        label="Discount maximum %"
                                        placeholder="Discount maximum..."
                                        value={values.discountMaximum}
                                        name={'discountMaximum'}
                                        className={setClassName(
                                            touched.discountMaximum &&
                                                errors.discountMaximum
                                        )}
                                        isNormalChange={false}
                                        onChange={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        allowClear={true}
                                        type="number"
                                    />
                                </Column>
                            </Row>
                            <RichText
                                label="How much *"
                                placeholder="How much..."
                                value={values.discountHowMuch.html}
                                name="discountHowMuch"
                                hasError={
                                    touched.discountHowMuch &&
                                    errors.discountHowMuch
                                }
                                onChange={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                maxLength={MAX_LENGTH_RICH_TEXT}
                                id={'discountHowMuch'}
                            />
                            <RichText
                                label="How to use *"
                                placeholder="How to use..."
                                value={values.discountHowToUse.html}
                                name="discountHowToUse"
                                hasError={
                                    touched.discountHowToUse &&
                                    errors.discountHowToUse
                                }
                                onChange={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                maxLength={MAX_LENGTH_RICH_TEXT}
                                id={'discountHowToUse'}
                            />
                            <ExtendedFileManager
                                uploadModalTitle={'Upload document(s)'}
                                files={values.discountFiles}
                                filesFormikFieldName={'discountFiles'}
                                filesToDeleteFormikFieldName={
                                    'discountFilesSetForDeletion'
                                }
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                            />
                            <Row justifyContent="space-between">
                                <span className="input-label">
                                    Make this discount active in Humate
                                </span>
                                <Row>
                                    <Switch
                                        checked={values.discountIsActive}
                                        checkedChildren="YES"
                                        unCheckedChildren="NO"
                                        onChange={value =>
                                            handleOnActiveStatusChange(
                                                value,
                                                setFieldValue
                                            )
                                        }
                                    />
                                </Row>
                            </Row>
                            <Row justifyContent="space-between">
                                <span className="input-label">
                                    Make this discount visible on MentorNet
                                </span>
                                <Row>
                                    <Switch
                                        checked={
                                            values.discountIsActive &&
                                            values.discountVisible
                                        }
                                        checkedChildren="YES"
                                        unCheckedChildren="NO"
                                        onChange={value => {
                                            setFieldValue(
                                                'discountVisible',
                                                value
                                            );
                                        }}
                                        disabled={!values.discountIsActive}
                                    />
                                </Row>
                            </Row>
                        </Form>
                    </StyledDiscountModal>
                );
            }}
        </Formik>
    );
};

const MAX_IMAGE_SIZE = 5242880; // 1024*1024*5 -> 5MB

export { DiscountModal };
