import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { StyledDashboard } from '../../people/pages/styled/Dashboard.styled';
import { Main } from '../../shared/components/layout/Main';
import { errorNotification } from '../../shared/components/Notifications';
import BenefitsDashboardHeader from '../components/Dashboard/BenefitsDashboardHeader';

const Dashboard = props => {
    const { history } = props;
    const [tableCount, setTableCount] = useState(0);
    const { errorFetchPerson } = useSelector(state => state.people);
    const pageTitle = 'Humate';

    useEffect(() => {
        if (errorFetchPerson) {
            errorNotification(errorFetchPerson, 5);
        }
    }, [errorFetchPerson]);

    const handleScroll = () => {
        const rect = document.getElementsByClassName('rt-tbody')[0];
        const elementSticky =
            document.getElementsByClassName('rt-thead -header')[0];
        const table = document.getElementsByClassName('ReactTable')[0];
        if (rect && elementSticky && table) {
            let currentPosition = rect.getBoundingClientRect();
            let tableTop = table.getBoundingClientRect();
            if (currentPosition.top <= 105) {
                elementSticky.className = 'rt-thead -header header-static';
                elementSticky.style.top = `${Math.abs(tableTop.top - 60)}px`;
            } else {
                elementSticky.className = 'rt-thead -header';
                elementSticky.style.top = '0';
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        handleScroll();
    }, [tableCount]);

    const handleUpdateCount = countData => {
        setTableCount(countData);
    };

    return (
        <StyledDashboard>
            <div>
                <Helmet title={pageTitle} />
                <Main
                    history={history}
                    hasHeader={true}
                    hasNavigation={true}
                    hasHeading={false}
                    hasCustomHeading={true}
                    customHeadingComponent={
                        <BenefitsDashboardHeader
                            history={history}
                            updateCount={handleUpdateCount}
                        />
                    }
                />
            </div>
        </StyledDashboard>
    );
};

export default Dashboard;
