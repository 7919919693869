import React, { useContext } from 'react';

import PermissionsContext from 'shared/utils/permissionStore';

import { STATIC_ROUTES_PATHS } from '../../../../routes';
import { CustomTableWithFixedColumns } from '../../../../shared/components/CustomTableWithFixedColumns';

export const EmployeesTable = ({
    tabType,
    totalCount,
    defaultSort,
    handleCreateTableColumns,
    peopleData,
    pageSize,
    loading,
    noDataText,
    hasPagination,
    sorted,
    handleSortUpdate,
    boundaryRef
}) => {
    const permissions = useContext(PermissionsContext).permissions;

    const handleRedirectToDetails = personId => {
        return STATIC_ROUTES_PATHS.personDetails
            .replace(':id', personId)
            .replace(':activeTab', 'main-information');
    };

    return (
        <div className="table-wrapper">
            <CustomTableWithFixedColumns
                total={totalCount}
                defaultSorted={defaultSort}
                columns={handleCreateTableColumns(
                    peopleData,
                    handleRedirectToDetails,
                    tabType,
                    permissions
                )}
                data={peopleData}
                pageSize={pageSize}
                loading={loading}
                noDataText={noDataText}
                hasPagination={hasPagination}
                sorted={sorted}
                handleSortingChange={handleSortUpdate}
                sortable={true}
                resizable={true}
            />
            <section ref={boundaryRef} />
        </div>
    );
};
