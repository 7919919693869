import React from 'react';

import { DatePicker } from 'antd';
import moment from 'moment';

import StyledCustomMonthYearPicker from './styled/MonthYearPicker.styled';

const FORMAT = 'MM/YYYY';

const CustomMonthPicker = props => {
    const {
        label,
        value,
        className,
        onChange,
        disabled,
        allowClear,
        disabledDate,
        validateDateInFuture = true
    } = props;

    const handleChange = value => {
        onChange(value);
    };

    const handleDisableDate = current => {
        if (validateDateInFuture) {
            return current && current < moment(disabledDate).endOf('month');
        }
        return current && current > moment(disabledDate).endOf('month');
    };

    return (
        <StyledCustomMonthYearPicker>
            <div className={`custom-month-year-picker ${className}`}>
                {label && <div className="picker-label">{label}</div>}
                <DatePicker
                    picker="month"
                    value={value && moment(value)}
                    onChange={handleChange}
                    format={FORMAT}
                    placeholder={FORMAT}
                    disabled={disabled}
                    disabledDate={disabledDate && handleDisableDate}
                    allowClear={allowClear}
                />
            </div>
        </StyledCustomMonthYearPicker>
    );
};

export default CustomMonthPicker;
