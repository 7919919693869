import React from 'react';

import { AllTabs } from './AllTabs';
import { StyledDashboardHeader } from './styled/DashboardHeader.styled';

const DashboardHeader = ({ history }) => {
    return (
        <StyledDashboardHeader>
            <div className="reports-dashboard-header-wrapper">
                <div className="nav-list-items">
                    <AllTabs history={history} />
                </div>
            </div>
        </StyledDashboardHeader>
    );
};

export default DashboardHeader;
