import axios from 'axios';

import url from '../../apiUrls';
import { buildPagedQueryData } from '../../people/utils/serviceHelpers/personServiceHelper';
import httpService from '../../shared/services/httpService';

const getEmployeeById = async id => {
    const data = await httpService.get(url.GET_SINGLE_PERSON(id));
    return data.data;
};

const getAllEmployees = async (subset, requestModel) => {
    const baseParams = buildPagedQueryData(subset, requestModel);

    const res = await httpService.get(url.GET_ALL_PEOPLE_PAGINATED(baseParams));
    return res.data;
};

const getAllEmployeesWithBenefits = async (subset, requestModel) => {
    const baseParams = buildPagedQueryData(subset, requestModel);

    const res = await httpService.get(
        url.GET_ALL_PEOPLE_WITH_BENEFITS_PAGINATED(baseParams)
    );
    return res.data;
};

const getPersonCategoriesCounts = async () => {
    const res = await httpService.get(url.GET_PERSON_CATEGORIES_COUNTS());
    return res.data;
};

const addPerson = async requestData => {
    const data = await httpService.post(url.CREATE_PERSON(), {
        ...requestData
    });
    return data.data;
};

const editPerson = async (requestData, personId) => {
    const data = await httpService.patch(url.UPDATE_PERSON(personId), {
        ...requestData
    });
    return data.data;
};

const getHistoricalData = async historicalState => {
    const { personId, filterBy } = historicalState;
    let baseParams = `${personId}/history`;
    if (filterBy === 'AllId' || filterBy === undefined) {
        baseParams += '';
    } else {
        baseParams += `?PropertyFilter=${filterBy}`;
    }
    const data = await httpService.get(url.GET_HISTORICAL_DATA(baseParams));
    return data.data;
};

const deletePersonById = async personId => {
    const data = await httpService.delete(url.DELETE_PERSON(personId));
    return data.data;
};

const searchPeople = async searchText => {
    const response = await httpService.get(url.GLOBAL_SEARCH(searchText));
    return response.data.data;
};

const checkPersonByNameExist = async checkNamesRequestData => {
    const data = await httpService.get(url.CHECK_PERSON_BY_NAME_EXISTS(), {
        params: {
            ...checkNamesRequestData
        }
    });
    return data.data;
};

const getPersonalInformationById = async personId => {
    const data = await httpService.get(url.GET_PERSONAL_INFORMATION(personId));
    return data.data;
};

const updatePersonalInformationById = async (personId, requestData) => {
    const data = await httpService.patch(
        url.UPDATE_PERSONAL_INFORMATION(personId),
        {
            ...requestData
        }
    );
    return data.data;
};

const getCurrentPeopleCounter = async () => {
    const data = await httpService.get(url.GET_CURRENT_COUNTER());
    return data.data;
};

const getExcelFile = async (subset, requestModel) => {
    const baseParams = buildPagedQueryData(subset, requestModel);

    const requestUrl = url.GET_EXCEL(baseParams);

    const res = await axios({
        url: requestUrl,
        method: 'GET',
        responseType: 'blob'
    });
    return res;
};

export default {
    getEmployeeById,
    addPerson,
    editPerson,
    updatePersonalInformationById,
    getAllEmployees,
    getAllEmployeesWithBenefits,
    getHistoricalData,
    deletePersonById,
    searchPeople,
    checkPersonByNameExist,
    getPersonCategoriesCounts,
    getPersonalInformationById,
    getCurrentPeopleCounter,
    getExcelFile
};
