import styled from 'styled-components';

const StyledDepartmentCounter = styled.div`
    .department-counter-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        .department-box {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-radius: 10px;
            margin-bottom: 15px;
            padding: 20px;
            display: flex;
            justify-content: space-between;
            .label {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 23px;
                margin-bottom: 10px;
            }
        }
    }
    .container {
        width: 1220px;
        height: 400px;
        position: relative;
    }
    .custom-chart {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .stack-top {
        z-index: 9;
        position: absolute;
    }
`;

export { StyledDepartmentCounter };
