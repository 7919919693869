export const ThreeDots = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="3"
            fill="none"
            viewBox="0 0 12 3"
        >
            <circle
                r="1.5"
                fill="#C4C4C4"
                transform="matrix(0 1 1 0 1.5 1.5)"
            ></circle>
            <circle
                r="1.5"
                fill="#C4C4C4"
                transform="matrix(0 1 1 0 6 1.5)"
            ></circle>
            <circle
                r="1.5"
                fill="#C4C4C4"
                transform="matrix(0 1 1 0 10.5 1.5)"
            ></circle>
        </svg>
    );
};
