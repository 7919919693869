import { useState, useEffect } from 'react';

import { DetailsInnerContainer } from 'people/components/shared';
import { useSelector } from 'react-redux';

import { Benefit } from './Benefit';

const CurrentBenefit = props => {
    const { benefit } = props;
    const [isOpen, setIsOpen] = useState(false);

    const [containerName, setContainerName] = useState('');

    const [benefitTypes, personName] = useSelector(state => [
        state.dropdown.benefitTypes,
        state.people.person.name
    ]);

    const handleDetailsContainer = () => {
        setIsOpen(!isOpen);
    };

    const handleBenefitLoad = benefit => {
        const benefitType = benefitTypes.find(
            x => x.id == benefit.benefitTypeId
        );

        const coveredBy = benefit.coveredById === 1 ? 'MentorMate' : personName;

        setContainerName(coveredBy + ' - ' + benefitType?.description);
    };

    useEffect(() => {
        if (benefit.benefitTypeId) {
            handleBenefitLoad(benefit);
        } else {
            setIsOpen(true);
            setContainerName('New benefit');
        }
    }, []);

    return (
        <DetailsInnerContainer
            bodyTitle={containerName}
            isOpen={isOpen}
            handleSection={() => handleDetailsContainer()}
        >
            <Benefit {...props} onCurrentBenefitUpdate={handleBenefitLoad} />
        </DetailsInnerContainer>
    );
};

export default CurrentBenefit;
