import styled from 'styled-components';

const StyledSummaryCounter = styled.div`
    .summary-counter-content {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        margin-right: 15px;
    }
    .summary-counter-content .summary-box {
        width: 290px;
        height: 108px;
        background: #ffffff;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin-bottom: 15px;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }
    .summary-box .labels .label {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #999999;
        cursor: pointer;
    }
    .summary-box .labels .count {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 45px;
        color: #000000;
        cursor: pointer;
    }
    .summary-box .icons {
        align-self: center;
        margin-right: 6px;
    }
`;

export { StyledSummaryCounter };
