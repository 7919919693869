import jwtDecode from 'jwt-decode';

export const checkTokenExp = () => {
    const token = localStorage.getItem('token');
    const logout = localStorage.getItem('logout');
    return (!token || jwtDecode(token)?.exp < Date.now() / 1000) && !logout;
};

export const redirectTokenExp = () => {
    localStorage.clear();
    localStorage.setItem('errorTokenExp', 'Your session expired.');
    window.location.pathname = '/';
};

export const getUserIdFromToken = () => {
    const token = localStorage.getItem('token');

    return token ? jwtDecode(token)?.id : null;
};
