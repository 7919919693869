import styled from 'styled-components';

const StyledOfficeCounter = styled.div`
    .office-counter-content {
        margin-top: 30px;
        margin-right: 16px;
    }
    .office-counter-content .office-box {
        width: 484px;
        height: 354px;
        background: #ffffff;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin-bottom: 15px;
        padding: 20px;
    }
    .office-box .title {
        position: absolute;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
    }
    .office-box .country-tabs .tab-list {
        display: flex;
        justify-content: flex-end;
    }
    .office-box .country-tabs .tab-list-item {
        display: inline-block;
        list-style: none;
        margin-bottom: -1px;
        padding: 5px 20px;
        font-size: 12px;
        cursor: pointer;
        border-bottom: 3px solid rgba(229, 229, 229, 0.5);
        transition: all 0.5s ease;
    }
    .office-box .country-tabs .tab-list-active {
        background: rgba(28, 132, 254, 0.12);
        border-radius: 8px 8px 0px 0px;
        border-bottom: 3px solid var(--mainBlue);
        font-size: 12px;
        font-weight: 700;
        padding-left: 20px;
        padding-right: 20px;
        transition: all 0.5s ease;
        color: var(--mainBlue);
    }
    .office-box .country-tabs .tab-content {
        margin-top: 10px;
    }
    .city-dot {
        cursor: pointer;
        position: relative;
        background: var(--secondaryBlue);
        border-radius: 50%;
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: var(--primaryWhite);
    }
    .city-dot.sofia {
        bottom: 165px;
        left: 50px;
        width: 55px;
        height: 55px;
        padding: 15px;
    }
    .city-dot.plovdiv {
        bottom: 180px;
        left: 168px;
        width: 47px;
        height: 47px;
        padding: 12px;
    }
    .city-dot.varna {
        bottom: 311px;
        left: 350px;
        width: 43px;
        height: 43px;
        padding: 10px;
    }
    .city-dot.vt {
        bottom: 353px;
        left: 204px;
        width: 37px;
        height: 37px;
        padding: 7px;
    }
    .city-dot.ruse {
        bottom: 406px;
        left: 240px;
        width: 33px;
        height: 33px;
        padding: 5px;
    }
    .city-dot.minneapolis {
        bottom: 233px;
        left: 215px;
        width: 55px;
        height: 55px;
        padding: 15px;
    }
    .remote-dot {
        cursor: pointer;
        background: #f0f3f6;
        border-radius: 50%;
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: var(--secondaryBlue);
        width: 56px;
        height: 56px;
        position: relative;
        bottom: 271px;
        left: 375px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .remote-dot .label {
        font-size: 10px;
        font-weight: 600;
        color: black;
    }
    .remote-dot .count {
        line-height: 15px;
    }
    .remote-dot.minneapolis {
        background: #f0f3f6;
        border-radius: 50%;
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: var(--secondaryBlue);
        width: 56px;
        height: 56px;
        position: relative;
        bottom: 108px;
        left: 393px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @media screen and (max-width: 1440px) {
        .office-counter-content {
            margin-right: 0px;
        }
    }
`;

export { StyledOfficeCounter };
