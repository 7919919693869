import React, { useContext, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import {
    shouldPeoplePageTabBeDisabled,
    setDefaultTabPeoplePage
} from 'people/utils/peoplePermissionsHelper';
import PermissionsContext from 'shared/utils/permissionStore';

import { PeopleSubsetTab } from './PeopleSubsetTab';
import { StyledAllTabs } from './styled/AllTabs.styled';
import TabsWrapper from '../../../shared/components/DashboardTabs/TabsWrapper';
import { createPeopleTableColumns } from '../../utils/Dashboard/peopleColumns';

export const AllTabs = ({
    allPeopleCount,
    currentPeopleCount,
    newPeopleCount,
    leaversPeopleCount,
    exPeopleCount,
    history,
    updateCount
}) => {
    const permissions = useContext(PermissionsContext).permissions;

    const [activeTabIndex, setActiveTabIndex] = useState(null);
    const [redirectFilterState, setRedirectFilterState] = useState({});

    const {
        location: { state }
    } = history;

    useEffect(() => {
        if (!isEmpty(state)) {
            const { subsetTab, filters } = state;
            setActiveTabIndex(setDefaultTabPeoplePage(subsetTab, permissions));
            setRedirectFilterState(filters);
            window.scrollTo(0, 0);
        } else {
            setActiveTabIndex(setDefaultTabPeoplePage(1, permissions));
        }
        return () => {
            setActiveTabIndex(null);
            setRedirectFilterState({});
            window.history.replaceState({}, document.title);
        };
    }, []);

    const TABS = [
        {
            label: 'All',
            value: 1,
            totalCount: allPeopleCount,
            disabled: shouldPeoplePageTabBeDisabled,
            handleCreateColumns: createPeopleTableColumns,
            defaultSort: [{ id: 'name', desc: false }]
        },
        {
            label: 'Current',
            value: 2,
            totalCount: currentPeopleCount,
            disabled: shouldPeoplePageTabBeDisabled,
            handleCreateColumns: createPeopleTableColumns,
            defaultSort: [{ id: 'name', desc: false }]
        },
        {
            label: 'New',
            value: 3,
            totalCount: newPeopleCount,
            disabled: shouldPeoplePageTabBeDisabled,
            handleCreateColumns: createPeopleTableColumns,
            defaultSort: [{ id: 'startDate', desc: false }]
        },
        {
            label: 'Leavers',
            value: 4,
            totalCount: leaversPeopleCount,
            disabled: shouldPeoplePageTabBeDisabled,
            handleCreateColumns: createPeopleTableColumns,
            defaultSort: [{ id: 'endDate', desc: false }]
        },
        {
            label: 'Ex',
            value: 5,
            totalCount: exPeopleCount,
            disabled: shouldPeoplePageTabBeDisabled,
            handleCreateColumns: createPeopleTableColumns,
            defaultSort: [{ id: 'endDate', desc: true }]
        }
    ];

    const tabElements = TABS.map((element, i) => {
        return (
            <div
                key={i}
                label={`${element.label} (${element.totalCount})`}
                disabled={element.disabled(element.label, permissions)}
            >
                <PeopleSubsetTab
                    history={history}
                    subset={element}
                    handleCreateColumns={element.handleCreateColumns}
                    tabType={element.label}
                    defaultSort={element.defaultSort}
                    redirectFilterState={redirectFilterState}
                    updateCount={updateCount}
                />
            </div>
        );
    });

    const handleTabChange = tab => {
        setRedirectFilterState({});
    };

    return (
        <StyledAllTabs>
            {activeTabIndex != null ? (
                <TabsWrapper
                    activeItemIndex={activeTabIndex}
                    handleTabChange={handleTabChange}
                >
                    {tabElements}
                </TabsWrapper>
            ) : (
                <>
                    <div className="tab-error">
                        An error occurred and data could not be loaded.
                    </div>
                </>
            )}
        </StyledAllTabs>
    );
};
