import React from 'react';

import { VerticalTimeline } from 'react-vertical-timeline-component';

import { StyledCustomTimeline } from './styled/CustomTimeline.styled';
import { TimelineItem } from './TimelineItem';

export const CustomTimeline = ({
    sorted,
    historicalState,
    onDeleteCallback,
    setItemsForEdit
}) => {
    let counter = 0;
    return (
        <StyledCustomTimeline>
            {sorted.length > 0 ? (
                <>
                    {sorted.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className="year-label">{item.title}</div>
                                <VerticalTimeline
                                    layout="2-columns"
                                    className="custom-line"
                                    animate={false}
                                    key={index}
                                >
                                    {item.timelineItems.map((item, i) => {
                                        return (
                                            <TimelineItem
                                                item={item}
                                                indexKey={counter++}
                                                key={i}
                                                historicalState={
                                                    historicalState
                                                }
                                                onDeleteCallback={
                                                    onDeleteCallback
                                                }
                                                setItemsForEdit={
                                                    setItemsForEdit
                                                }
                                            />
                                        );
                                    })}
                                </VerticalTimeline>
                            </div>
                        );
                    })}
                </>
            ) : (
                <div className="no-data">No historical data available.</div>
            )}
        </StyledCustomTimeline>
    );
};
