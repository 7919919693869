import React, { useContext, useEffect } from 'react';

import * as d3 from 'd3';
import {
    canAccessPeopleCurrent,
    getClassForCurrent
} from 'main/utils/Main/permissionsHelper';
import { render } from 'react-dom';
import PermissionsContext from 'shared/utils/permissionStore';

import { DepartmentMainSection } from './common/DepartmentMainSection';
import { StyledDepartmentCounter } from './styled/DepartmentCounter.styled';
import {
    classNamesDictionary,
    dataTreeMap
} from '../../utils/Main/departmentCounterHelper';

export const DepartmentCounter = ({
    data,
    history,
    defaultFilters,
    calculatedData
}) => {
    const permissions = useContext(PermissionsContext).permissions;

    const generateOther = data => {
        const svg = d3.select('svg.important'),
            width = +svg.attr('width'),
            height = +svg.attr('height');

        const treemap = d3
            .treemap()
            .tile(d3.treemapSquarify)
            .size([width, height])
            .round(true);

        const root = d3
            .hierarchy(data)
            .eachBefore(d => (d.data.id = classNamesDictionary[d.data.name]))
            .sum(d => d.size)
            .sort((a, b) => b.height - a.height || b.value - a.value);

        treemap(root);

        const cell = svg
            .selectAll('g')
            .data(root.leaves())
            .enter()
            .append('g')
            .attr('transform', d => {
                return 'translate(' + d.x0 + ',' + d.y0 + ')';
            });

        cell.append('rect')
            .attr('id', d => d.data.id)
            .attr('width', d => d.x1 - d.x0)
            .attr('height', d => d.y1 - d.y0)
            .attr('fakeX', d => d.x0)
            .attr('fakeY', d => d.y0)
            .attr('fill', '#ffffff');

        const base = document.querySelector('.stack-top');
        const allData = data.children;

        render(
            <DepartmentMainSection
                classNamesDictionary={classNamesDictionary}
                allData={allData}
                history={history}
                defaultFilters={defaultFilters}
                canClickSubSection={canAccessPeopleCurrent(permissions)}
            />,
            base
        );
    };

    useEffect(() => {
        generateOther(dataTreeMap(data, calculatedData));
    }, []);

    return (
        <StyledDepartmentCounter>
            <div
                className={`department-counter-content ${getClassForCurrent(
                    permissions
                )}`}
            >
                <div className="department-box">
                    <div className="label">Departments</div>
                    <div className="container">
                        <svg
                            className="important"
                            width="1220"
                            height="400"
                        ></svg>
                        {/* empty element that we will fill with elements */}
                        <div className="custom-chart stack-top"></div>
                    </div>
                </div>
            </div>
        </StyledDepartmentCounter>
    );
};
