import styled from 'styled-components';

export const StyledPeriods = styled.div`
    .add-benefit-section {
        margin-top: 20px;
        margin-left: 0px;
        cursor: pointer;
        width: fit-content;
        color: var(--mainBlue);
    }
`;
