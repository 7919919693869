import React, { useRef, useState } from 'react';

import benefitService from 'people/services/benefitService';
import { errorNotification } from 'shared/components/Notifications';
import { Spinner } from 'shared/components/Spinner';

import StyledSubsetTabDownload from './styled/SubsetTabDownload.styled';
import DownloadIcon from '../../../../shared/theme/assets/icons/DownloadIcon';
import { checkTokenExp, redirectTokenExp } from '../../../../utils/tokenHelper';
import peopleService from '../../../services/peopleService';

const SubsetTabDownload = props => {
    const { download } = props;
    const { state, subset, disableDownload, tab } = download;

    // Handle downloading excel file
    const downloadElementHandler = (blob, itemRef) => {
        let link = itemRef.current;
        link.href = window.URL.createObjectURL(blob);
        link.download =
            download.tab === 'benefits' ? 'benefits.xlsx' : 'People.xlsx';
        link.click();
        link.removeAttribute('href');
        link.removeAttribute('download');
    };

    const downloadRef = useRef();
    const [isLoading, setIsLoading] = useState(false);

    const exportToExcelFileHandler = async () => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);

        try {
            if (checkTokenExp()) {
                redirectTokenExp();
            }
            let data;
            if (tab === 'benefits') {
                const response = await benefitService.getExcelFile(
                    subset.value,
                    {
                        ...state,
                        skip: 0,
                        take: 0
                    }
                );
                data = response.data;
            } else {
                const response = await peopleService.getExcelFile(
                    subset.value,
                    {
                        ...state,
                        skip: 0,
                        take: 0
                    }
                );
                data = response.data;
            }

            const blob = new Blob([data]);

            downloadElementHandler(blob, downloadRef);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            errorNotification('Something went wrong.');
        }
    };

    const buttonContent = () => {
        return isLoading ? (
            <Spinner size="small" />
        ) : (
            <>
                <DownloadIcon /> Export
            </>
        );
    };

    return (
        <StyledSubsetTabDownload>
            <button
                className="btn-normal"
                onClick={exportToExcelFileHandler}
                disabled={disableDownload}
            >
                {buttonContent()}
            </button>
            <a id="download-hidden" ref={downloadRef} />
        </StyledSubsetTabDownload>
    );
};

export default SubsetTabDownload;
