import { useEffect, useState } from 'react';

import { handleSubmit } from 'admin/utils/shared/positionsHelper';
import SubsetTabSearch from 'people/components/Dashboard/PeopleSubsetTab/SubsetTabSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
    createPosition,
    fetchPositions,
    updateSearchPhrase,
    resetNotificationState,
    changeOrder,
    resetFilters,
    fetchSinglePosition,
    updatePosition,
    resetPosition,
    changeActiveState
} from 'redux/slices/position';
import { CustomButton } from 'shared/components';
import { ActivityStatusPicker } from 'shared/components/ActivityStatusPicker';
import Footer from 'shared/components/layout/Footer';
import { successNotification } from 'shared/components/Notifications';
import useInfiniteScroll from 'shared/hooks/useInfiniteScroll';

import { StyledPositionsSubtab } from './styled/PositionsSubtab.styled';
import { PositionsTable } from './Table/PositionsTable';
import { PositionModal } from '../shared/PositionModal/PositionModal';

export const PositionsSubtab = ({ handleCreateColumns, permissions }) => {
    const dispatch = useDispatch();

    const {
        positions,
        searchPhrase,
        isLoading,
        totalCount,
        isAddedSuccessfully,
        isEditedSuccessfully,
        sortBy,
        sortAsc,
        positionsTotalCount,
        isEditMode,
        loadActive
    } = useSelector(state => state.position);

    useEffect(() => {
        if (isEditedSuccessfully) {
            successNotification('Successfully updated the Position.');
        } else if (isAddedSuccessfully) {
            successNotification('Successfully added a Position.');
        }

        dispatch(resetNotificationState());
    }, [isAddedSuccessfully, isEditedSuccessfully]);

    const [sortState, setSortState] = useState([
        { id: 'updatedOn', desc: true }
    ]);

    const [isPositionModalOpen, setIsPositionModalOpen] = useState(false);
    const [editModalPositionId, setEditModalPositionId] = useState(undefined);
    const [fetchPositionForEdit, setFetchPositionForEdit] = useState(false);

    useEffect(() => {
        if (editModalPositionId && fetchPositionForEdit) {
            dispatch(fetchSinglePosition(editModalPositionId));
            setIsPositionModalOpen(true);
            setFetchPositionForEdit(false);
        }
    }, [fetchPositionForEdit]);

    useEffect(() => {
        dispatch(fetchPositions(false));
    }, [searchPhrase, sortBy, sortAsc, loadActive]);

    useEffect(() => {
        return () => {
            dispatch(resetFilters());
        };
    }, []);

    const handleSortUpdate = value => {
        setSortState(value);
        dispatch(changeOrder(value));
        dispatch(resetPosition());
    };

    // infinite scroll handling
    const boundaryRef = useInfiniteScroll({
        onLoadMore: () => {
            dispatch(fetchPositions(true));
        },
        isLoading,
        hasMore: Boolean(positions.length < positionsTotalCount)
    });

    const handleValueUpdate = (_, searchPhrase) => {
        dispatch(updateSearchPhrase(searchPhrase));
    };

    const handlePositionModalOpen = (isOpen, positionId) => {
        if (isOpen && positionId) {
            setEditModalPositionId(positionId);
            setFetchPositionForEdit(true);
            return;
        }

        setIsPositionModalOpen(isOpen);
        setEditModalPositionId(undefined);
        dispatch(resetPosition());
    };

    return (
        <>
            <StyledPositionsSubtab>
                <div className="positions-container">
                    <div className="positions-extra-header">
                        <SubsetTabSearch
                            search={{ handleValueUpdate }}
                            allowLettersOnly={false}
                        />
                        <ActivityStatusPicker
                            activeButtonOnClick={() =>
                                dispatch(changeActiveState(true))
                            }
                            inactiveButtonOnClick={() =>
                                dispatch(changeActiveState(false))
                            }
                            loadActive={loadActive}
                        />
                        <CustomButton
                            type={'normal'}
                            text={'Add Position'}
                            onClick={() => handlePositionModalOpen(true)}
                            disabled={!permissions.canAddResources}
                        />
                    </div>
                    <PositionsTable
                        totalCount={totalCount}
                        handleCreateTableColumns={() =>
                            handleCreateColumns(
                                handlePositionModalOpen,
                                permissions
                            )
                        }
                        positionsData={positions}
                        pageSize={10}
                        loading={isLoading}
                        noDataText={'No Positions Found'}
                        hasPagination={false}
                        sorted={sortState}
                        handleSortUpdate={handleSortUpdate}
                        boundaryRef={boundaryRef}
                        permissions={permissions}
                    />

                    <PositionModal
                        title={isEditMode ? 'Edit Position' : 'Add Position'}
                        visible={isPositionModalOpen}
                        setVisible={handlePositionModalOpen}
                        handleSubmit={formikValues =>
                            handleSubmit(
                                editModalPositionId,
                                formikValues,
                                dispatch,
                                createPosition,
                                updatePosition,
                                handlePositionModalOpen
                            )
                        }
                    />
                </div>
            </StyledPositionsSubtab>
            {!Boolean(positions.length < totalCount) && (
                <Footer className={'special'} />
            )}
        </>
    );
};
