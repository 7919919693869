import React from 'react';

import { AutoComplete } from 'antd';

import { StyledAutoCompleteInput } from './styled/AutoCompleteInput.styled';

// currently it's working with static data for options
// if needed something else read antd documentation for AutoComplete component
export const CustomAutoComplete = ({
    label,
    options,
    placeholder,
    disabled = false,
    allowClear = false,
    hasMargins = false,
    value,
    onChange, // give setFieldValue when use with formik
    onBlur = () => {},
    onClose = () => {},
    name,
    className,
    ...rest
}) => {
    const { isEditMode } = rest;
    const handleChange = value => {
        onChange(name, value);
    };
    const handleBlur = () => onBlur(name);
    const handleKeyDown = e => {
        if (e.keyCode === 13) {
            onBlur(name);
        }
    };
    const handleClose = () => {
        onClose(name);
    };
    const handleClick = target => {
        if (
            value &&
            isEditMode &&
            target.target.classList.value ===
                'ant-select-selection-search-input'
        ) {
            target.target.setSelectionRange(0, target.target.value.length);
        }
    };

    return (
        <StyledAutoCompleteInput>
            <div
                className={`custom-ac ${className}
                ${hasMargins ? ' inputMargin' : ''}`}
            >
                {label && (
                    <div
                        className={disabled ? 'label-disabled' : 'input-label'}
                    >
                        {label}
                    </div>
                )}
                <AutoComplete
                    style={{
                        width: 270
                    }}
                    aria-label={name}
                    allowClear={allowClear}
                    options={options}
                    value={value}
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    onClear={handleClose}
                    placeholder={placeholder}
                    disabled={disabled}
                    filterOption={(inputValue, option) =>
                        option.value
                            .toUpperCase()
                            .indexOf(inputValue?.toUpperCase()) !== -1
                    }
                    virtual={false}
                    onClick={handleClick}
                />
            </div>
        </StyledAutoCompleteInput>
    );
};
