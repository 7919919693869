import React from 'react';

import { BenefitPriceSection } from './BenefitPriceSection';

const BenefitTypeModalAdd = ({
    benefitPrices,
    setFieldValue,
    setFieldTouched,
    benefitPriceErrors,
    touched
}) => {
    const handleChange = (propName, propValue) => {
        let valueCopy = [...benefitPrices];
        valueCopy[0] = {
            ...valueCopy[0],
            [propName]: propValue
        };

        setFieldValue('benefitPrices', valueCopy);
    };

    return (
        <>
            <BenefitPriceSection
                onChange={handleChange}
                values={benefitPrices[0]}
                benefitPriceId={benefitPrices[0].id}
                benefitPriceErrors={
                    benefitPriceErrors ? benefitPriceErrors[0] : {}
                }
                shouldDisableFields={false}
                isEndDateRequired={false}
                setFieldTouched={setFieldTouched}
                touched={touched}
            />
            <div className="bottom-section last"></div>
        </>
    );
};

export { BenefitTypeModalAdd };
