import React, { Fragment, useState, useRef, useEffect } from 'react';

import { FieldArray, useFormikContext } from 'formik';
import moment from 'moment';
import {
    calculatePeriodInMonths,
    isFutureDate,
    isPastDate
} from 'people/utils/dateHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { CustomConfirmationModal } from 'shared/components/ConfirmationModal';
import { CustomDateSelector } from 'shared/components/DatePicker';
import { FieldsSection } from 'shared/components/FieldsSection';

import { StyledOnLeaveFormSection } from './styled/OnLeaveFormSection.styled';
import {
    addOnLeavePeriod,
    deleteOnLeavePeriod,
    updateOnLeavePeriod
} from '../../../../redux/slices/workingPeriod';

const OnLeaveFormSection = ({
    canEdit,
    workingPeriodIndex,
    personId,
    setShowInfoText
}) => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onLeavePeriodIdToDelete = useRef(null);
    const isFirstRun = useRef(true);
    const {
        person: { name, workingPeriods }
    } = useSelector(state => state.people);
    const personFirstName = name?.split(' ')[0];
    const {
        values: {
            onLeavePeriods,
            workingPeriodId,
            startDate: workingPeriodStartDate,
            endDate: workingPeriodEndDate
        },
        setFieldValue
    } = useFormikContext();
    const updatedOnLeavePeriods =
        workingPeriods[workingPeriodIndex].onLeavePeriods;

    const handleAddOnLeavePeriod = () => {
        dispatch(
            addOnLeavePeriod({
                workingPeriodId,
                workingPeriodIndex,
                startDate: moment().format('YYYY-MM-DD'),
                personId
            })
        );

        setShowInfoText(true);
    };

    const handleBlur = (onLeavePeriodId, propertyName, value) => {
        dispatch(
            updateOnLeavePeriod({
                onLeavePeriodId,
                workingPeriodId,
                workingPeriodIndex,
                propertyName,
                value,
                personId
            })
        );

        setShowInfoText(true);
    };

    const handleDelete = onLeavePeriodId => {
        onLeavePeriodIdToDelete.current = onLeavePeriodId;

        setIsModalOpen(true);
    };

    const handleDeleteConfirm = () => {
        dispatch(
            deleteOnLeavePeriod({
                onLeavePeriodId: onLeavePeriodIdToDelete.current,
                workingPeriodIndex,
                personId
            })
        );

        setIsModalOpen(false);
    };

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        setFieldValue('onLeavePeriods', updatedOnLeavePeriods);
    }, [updatedOnLeavePeriods]);

    const shouldDisableFields = workingPeriods[workingPeriodIndex].leaveDate
        ? isPastDate(workingPeriods[workingPeriodIndex].leaveDate)
        : false;

    const shouldDisableAddOnLeaveButton =
        isFutureDate(workingPeriods[workingPeriodIndex].startDate) ||
        shouldDisableFields ||
        onLeavePeriods.some(onLeavePeriod => {
            return moment(workingPeriods[workingPeriodIndex].leaveDate).isSame(
                onLeavePeriod.endDate
            );
        });

    return (
        <>
            <StyledOnLeaveFormSection>
                <h4 className="title">On Leave</h4>
                {!!onLeavePeriods.length && (
                    <FieldsSection>
                        <FieldArray name="onLeavePeriods">
                            {() =>
                                onLeavePeriods.map((onLeavePeriod, i) => (
                                    <Fragment
                                        key={onLeavePeriod.onLeavePeriodId}
                                    >
                                        <div className="section-inputs">
                                            <div>
                                                <CustomDateSelector
                                                    allowClear={false}
                                                    value={
                                                        onLeavePeriod.startDate
                                                    }
                                                    label="Start Date"
                                                    name={`onLeavePeriods.${i}.startDate`}
                                                    onChange={setFieldValue}
                                                    onBlur={(_name, value) => {
                                                        handleBlur(
                                                            onLeavePeriod.onLeavePeriodId,
                                                            'StartDate',
                                                            value
                                                        );
                                                    }}
                                                    disabledDate={
                                                        workingPeriodStartDate
                                                    }
                                                    disabledFutureDate={
                                                        moment(
                                                            workingPeriodEndDate
                                                        ).isBefore(
                                                            moment(
                                                                onLeavePeriod.endDate
                                                            )
                                                        ) ||
                                                        onLeavePeriod.endDate ===
                                                            null
                                                            ? workingPeriodEndDate
                                                            : onLeavePeriod.endDate
                                                    }
                                                    disabledDateIncluding={true}
                                                    disabled={
                                                        canEdit ||
                                                        shouldDisableFields
                                                    }
                                                />
                                                <button
                                                    className="delete"
                                                    type="button"
                                                    onClick={() =>
                                                        handleDelete(
                                                            onLeavePeriod.onLeavePeriodId
                                                        )
                                                    }
                                                    disabled={
                                                        shouldDisableFields
                                                    }
                                                >
                                                    Delete on leave period
                                                </button>
                                            </div>
                                            <CustomDateSelector
                                                value={onLeavePeriod.endDate}
                                                label="End Date"
                                                name={`onLeavePeriods.${i}.endDate`}
                                                onChange={setFieldValue}
                                                onBlur={(_name, value) => {
                                                    handleBlur(
                                                        onLeavePeriod.onLeavePeriodId,
                                                        'EndDate',
                                                        value
                                                    );
                                                }}
                                                disabledDate={
                                                    onLeavePeriod.startDate
                                                }
                                                disabledFutureDate={
                                                    workingPeriodEndDate
                                                }
                                                disabled={
                                                    canEdit ||
                                                    shouldDisableFields
                                                }
                                            />
                                            <div className="overall-on-leave">
                                                <span className="label">
                                                    Overall On Leave
                                                </span>
                                                <span>
                                                    {isFutureDate(
                                                        onLeavePeriod.startDate
                                                    ) && !onLeavePeriod.endDate
                                                        ? '-'
                                                        : calculatePeriodInMonths(
                                                              onLeavePeriod.startDate,
                                                              onLeavePeriod.endDate ||
                                                                  new Date()
                                                          ) + ' mo'}
                                                </span>
                                            </div>
                                        </div>
                                    </Fragment>
                                ))
                            }
                        </FieldArray>
                    </FieldsSection>
                )}

                {(!onLeavePeriods.length ||
                    onLeavePeriods.every(
                        onLeavePeriod => !!onLeavePeriod.endDate
                    )) && (
                    <button
                        type="button"
                        onClick={handleAddOnLeavePeriod}
                        disabled={shouldDisableAddOnLeaveButton}
                    >
                        + Add On Leave period
                    </button>
                )}
            </StyledOnLeaveFormSection>
            <CustomConfirmationModal
                visible={isModalOpen}
                setVisible={setIsModalOpen}
                title={'Confirmation'}
                contentText={`You are going to delete ${personFirstName}’s record.`}
                handleConfirm={handleDeleteConfirm}
            />
        </>
    );
};

export default OnLeaveFormSection;
