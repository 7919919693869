import { CloseIcon } from 'shared/theme/assets/icons/CloseIcon';

import { StyledSelectedOption } from './styled/SelectedOption.styled';

const SelectedOption = ({
    item,
    onChange,
    options,
    handleValues,
    values,
    value
}) => {
    const handleClose = e => {
        const description =
            e.target.parentNode.parentNode.parentNode.firstElementChild
                .innerText;

        const filteredOptions = options.filter(o => o.label !== description);
        const filteredValues = values.filter(
            option => option.description !== description
        );

        handleValues('locations', filteredValues);
        onChange(filteredOptions);
    };

    return (
        <StyledSelectedOption>
            <div className="selected-item" key={item.value}>
                <div className="name">{item.label}</div>
                <div className="icon-holder-inbound">
                    <CloseIcon onClick={handleClose} />
                </div>
            </div>
        </StyledSelectedOption>
    );
};

export { SelectedOption };
