import React from 'react';

import { DatePicker } from 'antd';
import moment from 'moment';

import { StyledDatePicker } from './styled/DatePicker.styled';
import { CalendarIcon } from '../theme/assets/icons/CalendarIcon';

const LOCALE = 'en';
const FORMAT = 'MM/DD/YYYY';

const CustomDateSelector = ({
    onChange,
    value,
    name,
    label = '',
    onBlur = () => {},
    disabledDate,
    disabledFutureDate,
    disabledDateIncluding = false,
    disabled = false,
    allowClear = true,
    validateDateInFuture = true,
    className,
    specialHandleChange = () => {},
    id,
    placeholder,
    ...rest
}) => {
    const { hideSuffix } = rest;

    const suffix = () => {
        if (hideSuffix) {
            return null;
        }

        return <CalendarIcon />;
    };

    const handleChange = async val => {
        if (val) {
            await onChange(name, moment(val).format(FORMAT));
            await onBlur(name, moment(val).format(FORMAT));
        } else {
            await onChange(name, null);
            await onBlur(name, null);
            await specialHandleChange(onChange);
        }
    };

    const handleDisableDate = current => {
        if (validateDateInFuture) {
            if (disabledFutureDate) {
                return disabledDateIncluding
                    ? current &&
                          (current <= moment(disabledDate) ||
                              current >= moment(disabledFutureDate))
                    : current &&
                          (current < moment(disabledDate) ||
                              current >
                                  moment(disabledFutureDate).endOf('day'));
            }
            return current && current < moment(disabledDate).endOf('day');
        }
        return current && current > moment(disabledDate).endOf('day');
    };

    return (
        <StyledDatePicker>
            <div className={`custom-datepicker ${className}`}>
                {label && (
                    <label htmlFor={id} className="input-label">
                        {label}
                    </label>
                )}
                <DatePicker
                    data-testid={name}
                    id={id}
                    value={value && moment(value)}
                    onChange={handleChange}
                    locale={LOCALE}
                    format={FORMAT}
                    placeholder={placeholder || FORMAT}
                    suffixIcon={suffix()}
                    disabledDate={disabledDate && handleDisableDate}
                    disabled={disabled}
                    allowClear={allowClear}
                />
            </div>
        </StyledDatePicker>
    );
};

export { CustomDateSelector };
