import React from 'react';

import { StyledPageHeading } from './styled/PageHeading.styled';

const PageHeading = () => {
    return (
        <StyledPageHeading>
            <div className="page-heading">
                <div className="page-heading-inner"> </div>
            </div>
        </StyledPageHeading>
    );
};

export default PageHeading;
