import React from 'react';

import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

import {
    getColumnWidth,
    allPeopleBackgroundHelper,
    backgroundHelper
} from './tableUtil';
import { Media } from '../../../shared/components/Media';

const FORMAT = 'MM/DD/YYYY';

export const createPeopleTableColumns = (
    people,
    handleRedirectToDetails,
    columnType,
    permissions
) => {
    return [
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            Cell: ({ original }) => {
                let nameCellContent = (
                    <div className="table-name-section">
                        <Media
                            className={'wide-avatar'}
                            photoUrl={original.photo}
                        />
                        <div className="name-info bold-name">
                            {original.name} <br></br>({original.nameBg})
                        </div>
                    </div>
                );

                if (permissions.canViewPeopleDetailsPage) {
                    nameCellContent = (
                        <Link to={handleRedirectToDetails(original.personId)}>
                            {nameCellContent}
                        </Link>
                    );
                }

                return nameCellContent;
            },

            width:
                people && people.length > 0
                    ? getColumnWidth(people, 'Name')
                    : 220,
            resizable: false,
            sortable: true,
            fixed: 'left',
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column, columnType)
                };
            }
        },
        {
            Header: `Email`,
            id: 'email',
            Cell: ({ original }) => <>{original.email ? original.email : ''}</>,
            sortable: false,
            resizable: true,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column, columnType)
                };
            }
        },
        {
            Header: `Position`,
            id: 'title',
            Cell: ({ original }) => {
                const len = !isEmpty(original.title)
                    ? original.title.length
                    : 0;
                if (len >= 10)
                    return (
                        <div className="tooltip-span-text">
                            <Tooltip title={original.title} placement="bottom">
                                <span>{original.title}</span>
                            </Tooltip>
                        </div>
                    );
                else if (len < 10)
                    return (
                        <div className="tooltip-span-text">
                            <span>{original.title}</span>
                        </div>
                    );
            },
            sortable: false,
            resizable: true,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column, columnType)
                };
            }
        },
        {
            Header: 'Level',
            id: 'level',
            accessor: 'level',
            Cell: ({ original }) => {
                if (!isEmpty(original.level)) {
                    return <div>{original.level}</div>;
                } else {
                    return <div className="empty-cell"> </div>;
                }
            },
            sortable: true,
            resizable: true,
            minWidth: 70,
            width: 90,
            maxWidth: 90,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column, columnType)
                };
            }
        },
        {
            Header: 'Location',
            id: 'location',
            accessor: 'location',
            Cell: ({ original }) => {
                const len = !isEmpty(original.location)
                    ? original.location.length
                    : 0;
                if (len >= 10)
                    return (
                        <div className="tooltip-span-text">
                            <Tooltip
                                title={original.location}
                                placement="bottom"
                            >
                                <span>{original.location}</span>
                            </Tooltip>
                        </div>
                    );
                else if (len < 10)
                    return (
                        <div className="tooltip-span-text">
                            <span>{original.location}</span>
                        </div>
                    );
            },
            sortable: true,
            resizable: true,
            minWidth: 100,
            width: 120,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column, columnType)
                };
            }
        },
        {
            Header: 'Department',
            id: 'department',
            accessor: 'department',
            Cell: ({ original }) => {
                const len = !isEmpty(original.department)
                    ? original.department.length
                    : 0;
                if (len >= 6)
                    return (
                        <div className="tooltip-span-text">
                            <Tooltip
                                title={original.department}
                                placement="bottom"
                            >
                                <span>{original.department}</span>
                            </Tooltip>
                        </div>
                    );
                else if (len < 6)
                    return (
                        <div className="tooltip-span-text">
                            <span>{original.department}</span>
                        </div>
                    );
            },
            resizable: true,
            sortable: false,
            minWidth: 80,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column, columnType)
                };
            }
        },
        {
            Header: 'Reports to',
            id: 'reportsTo',
            accessor: 'reportsTo',
            Cell: ({ original }) => {
                if (original.manager) {
                    const showTooltip =
                        original.manager.name?.length > 7 ||
                        original.manager.nameBg?.length > 7;
                    const names = [
                        original.manager.name,
                        original.manager.nameBg
                    ];

                    let reportsToCellContent;

                    if (showTooltip) {
                        reportsToCellContent = (
                            <div className="tooltip-span-text">
                                <Tooltip
                                    title={names.map((x, i) => (
                                        <div key={i}>{x}</div>
                                    ))}
                                    placement="bottom"
                                >
                                    <span className="tooltip-span-text-bold">
                                        {original.manager.name}
                                    </span>
                                    <br></br>
                                    <span className="tooltip-span-text-bold">
                                        ({original.manager.nameBg})
                                    </span>
                                </Tooltip>
                            </div>
                        );
                    } else {
                        reportsToCellContent = (
                            <div className="tooltip-span-text">
                                <span className="tooltip-span-text-bold">
                                    {original.manager.name}
                                </span>
                                <br></br>
                                <span className="tooltip-span-text-bold">
                                    ({original.manager.nameBg})
                                </span>
                            </div>
                        );
                    }

                    if (permissions.canViewPeopleDetailsPage) {
                        reportsToCellContent = (
                            <Link
                                to={handleRedirectToDetails(
                                    original.manager.personId
                                )}
                            >
                                {reportsToCellContent}
                            </Link>
                        );
                    }

                    return reportsToCellContent;
                } else {
                    return <></>;
                }
            },
            sortable: true,
            resizable: true,
            width: 150,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column, columnType)
                };
            }
        },
        {
            Header: 'Start Date',
            id: 'startDate',
            accessor: 'startDate',
            Cell: ({ original }) => (
                <>
                    {original.startDate
                        ? moment(original.startDate).format(FORMAT)
                        : '-'}
                </>
            ),
            resizable: true,
            sortable: true,
            minWidth: 90,
            maxWidth: 130,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column, columnType)
                };
            }
        },
        {
            Header: 'End Date',
            id: 'endDate',
            accessor: 'endDate',
            Cell: ({ original }) => (
                <>
                    {original.endDate
                        ? moment(original.endDate).format(FORMAT)
                        : '-'}
                </>
            ),
            resizable: true,
            sortable: true,
            minWidth: 90,
            maxWidth: 130,
            getProps: (state, rowInfo, column) => {
                return {
                    style: typeHandlerHelper(state, rowInfo, column, columnType)
                };
            }
        }
    ];
};

const typeHandlerHelper = (state, rowInfo, column, columnType) => {
    if (columnType === 'All') {
        return allPeopleBackgroundHelper(state, rowInfo, column);
    } else {
        return backgroundHelper(state, rowInfo, column);
    }
};
