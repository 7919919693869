import styled from 'styled-components';

export const StyledDepartmentsSubtab = styled.div`
    .departments-container {
        width: 100%;
        padding-top: 10px;
        .table-wrapper {
            margin-left: -20px;
            margin-right: -15px;
        }
    }

    .departments-extra-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
    }

    @media screen and (max-width: 768px) {
        .table-wrapper {
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    .table-name-section {
        display: flex;
        cursor: pointer;
    }

    .name-info {
        padding-left: 10px;
    }

    .bold-name {
        font-weight: 600;
        color: var(--darkNavy);
    }

    .pointer > tr {
        margin: 10px;
    }
`;
