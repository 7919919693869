import styled from 'styled-components';

const StyledEmployeeDetails = styled.div`
    .main {
        box-sizing: border-box;
        padding: 14px 90px !important;
    }
    .main .ant-spin.ant-spin-lg.ant-spin-spinning {
        padding: 30px 0px;
        display: flex;
        align-self: center;
        justify-content: center;
        transition: all ease 0.5s;
    }
    .main .employee-details-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        justify-content: flex-between;
    }
    .main .employee-details-container .employee-details-sidebar {
        padding-top: 40px;
        width: 310px;
        display: flex;
        flex-direction: column;
        flex: 0 0 310px;
    }
    .main .employee-details-container .employee-details-tabs {
        padding-top: 14px;
        flex: 1;
        width: calc(100% - 300px);
    }

    @media screen and (max-width: 1400px) {
        .main .employee-details-container .employee-details-tabs {
        }
    }
    @media screen and (max-width: 1024px) {
        .main {
            padding: 14px 80px !important;
        }
        .main .employee-details-container .employee-details-tabs {
        }
    }
    @media screen and (max-width: 768px) {
        .main {
            max-width: inherit !important;
            padding: 14px 24px !important;
        }
        .employee-details-tabs {
            padding-top: 14px;
            width: 100%;
        }
    }
    @media screen and (max-width: 426px) {
        .main {
            max-width: inherit !important;
            padding: 14px 12px !important;
        }
        .main .employee-details-container {
            display: flex;
            flex-direction: column !important;
        }
        .employee-details-sidebar {
            justify-content: center;
            margin: 0 auto;
        }
        .main .employee-details-container .employee-details-tabs {
            padding-top: 14px;
            width: 400px;
        }
    }
`;

export { StyledEmployeeDetails };
