import React from 'react';

import * as msal from '@azure/msal-browser';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PermissionsContextProvider } from 'shared/utils/permissionStore';
import { config } from 'utils/loginServerConfigs';

import App from './App';
import { configureStore } from './redux/index';
import * as serviceWorker from './registerServiceWorker';
import './index.css';

const store = configureStore();

export const msalInstance = await msal.createStandardPublicClientApplication({
    auth: {
        clientId: config.microsoft_client_id,
        authority: config.microsoft_tenant_url
    }
});

ReactDOM.render(
    <React.StrictMode>
        <PermissionsContextProvider>
            <Provider store={store}>
                <App msalInstance={msalInstance} />
            </Provider>
        </PermissionsContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
