import { notification } from 'antd';

export const successNotification = (message, duration) =>
    notification.success({ message, duration });

export const errorNotification = (message, duration) =>
    notification.error({ message, duration });

export const warningNotification = (message, duration) =>
    notification.warning({ message, duration });

export const infoNotification = (message, duration) =>
    notification.info({ message, duration });
