import React, { useEffect, useState } from 'react';

import {
    canSubmitRoleForm,
    getEditInitialValues,
    getSelectedPermissionOnEdit,
    setClassName,
    validateRoleForm,
    validateRoleOnSubmit
} from 'admin/utils/shared/roleModalHelper';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPermissionsDropDown } from 'redux/slices/dropdown';
import { resetSingleRole } from 'redux/slices/role';
import { CustomButton } from 'shared/components/Button';
import { CloseButton } from 'shared/components/CloseButton';
import { FieldsSection } from 'shared/components/FieldsSection';
import { CustomInput } from 'shared/components/Input';
import { CustomMultiSelect } from 'shared/components/MultiSelect';
import { errorNotification } from 'shared/components/Notifications';
import { Spinner } from 'shared/components/Spinner';
import { mapDataForDropdown } from 'utils/dropdownHelper';

import { SelectedOptions } from './SelectedOptions';
import { StyledRoleModal } from './styled/RoleModal.styled';

const INITIAL_VALUES = {
    roleName: '',
    permissions: []
};

const RoleModal = ({
    title,
    initialValue = INITIAL_VALUES,
    visible,
    isEdit,
    setVisible = () => {},
    handleSubmit = () => {},
    closeCallback = () => {}
}) => {
    const dispatch = useDispatch();
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [errors, setErrors] = useState({});

    const [editInitialValues, setEditInitialValues] = useState({});

    const { isLoadingFetchSingle, errorFetchSingle, roleSingleData } =
        useSelector(state => state.role);

    const { permissions } = useSelector(state => state.dropdown);
    const permissionOptions = mapDataForDropdown(permissions);

    useEffect(() => {
        dispatch(fetchPermissionsDropDown());
    }, [dispatch]);

    useEffect(() => {
        if (errorFetchSingle) {
            errorNotification(errorFetchSingle);
        }
    }, [errorFetchSingle]);

    useEffect(() => {
        return () => {
            if (isEdit) {
                dispatch(resetSingleRole());
            }
        };
    }, []);

    useEffect(() => {
        if (isEdit && roleSingleData) {
            const initialValuesEdit = getEditInitialValues(roleSingleData);
            setEditInitialValues(initialValuesEdit);

            setSelectedPermissions(getSelectedPermissionOnEdit(roleSingleData));
        }
    }, [roleSingleData]);

    const handleClose = () => {
        closeCallback();
        setVisible(false);
    };

    // This validates only the role name
    const validateName = (name, val) => {
        const currentData = {
            [name]: val
        };
        let errorsIncome = validateRoleForm(currentData);
        setErrors(errorsIncome);
    };

    const localSubmit = submitValues => {
        let errorsIncome = validateRoleOnSubmit(submitValues);
        setErrors(errorsIncome);

        const canSubmit = canSubmitRoleForm(submitValues, errors);

        if (canSubmit) {
            handleSubmit(submitValues);
        }
    };

    const formikValues = isEdit ? editInitialValues : initialValue;

    return (
        <Formik initialValues={formikValues} enableReinitialize={true}>
            {props => {
                const { values, setFieldValue } = props;
                return (
                    <StyledRoleModal
                        visible={visible}
                        setVisible={setVisible}
                        title={[
                            <div
                                className="custom-modal-header"
                                key="modal-title"
                            >
                                {title}
                                <CloseButton handleClose={handleClose} />
                            </div>
                        ]}
                        data-testid="role-modal"
                        footer={[
                            <div
                                className="custom-modal-footer"
                                key="modal-footer"
                            >
                                <div className="button-section">
                                    <CustomButton
                                        text="Cancel"
                                        type="normal footer-button"
                                        onClick={handleClose}
                                        disabled={isLoadingFetchSingle}
                                    />
                                </div>
                                <div className="button-section">
                                    <CustomButton
                                        text={isEdit ? 'Update' : 'Add'}
                                        type="filled footer-button"
                                        onClick={() => localSubmit(values)}
                                        disabled={
                                            !canSubmitRoleForm(values, errors)
                                        }
                                    />
                                </div>
                            </div>
                        ]}
                    >
                        {isLoadingFetchSingle || values === null ? (
                            <Spinner />
                        ) : (
                            <Form>
                                <div className="modal-body-upper">
                                    <FieldsSection>
                                        <div className="section-inputs">
                                            <CustomInput
                                                label="Role Name"
                                                placeholder="Type your role here"
                                                isNormalChange={false}
                                                allowClear={true}
                                                value={values.roleName}
                                                name={'roleName'}
                                                className={setClassName(
                                                    errors?.roleName
                                                )}
                                                onChange={(name, val) => {
                                                    setFieldValue(name, val);
                                                    validateName(name, val);
                                                }}
                                            />
                                        </div>
                                        <div className="selectors-section">
                                            <div className="selector">
                                                <CustomMultiSelect
                                                    className={`wider ${setClassName(
                                                        errors?.permissions
                                                    )}`}
                                                    label="Permissions"
                                                    hasSelectAll={false}
                                                    disableSearch={true}
                                                    value={values.permissions}
                                                    name={'permissions'}
                                                    options={permissionOptions}
                                                    onChange={setFieldValue}
                                                    onSelection={
                                                        setSelectedPermissions
                                                    }
                                                    selected={
                                                        selectedPermissions
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <SelectedOptions
                                            options={selectedPermissions}
                                            values={values.permissions}
                                            onChange={setSelectedPermissions}
                                            handleValues={setFieldValue}
                                        />
                                    </FieldsSection>
                                </div>
                            </Form>
                        )}
                    </StyledRoleModal>
                );
            }}
        </Formik>
    );
};

export { RoleModal };
