import { CustomModal } from 'shared/components/Modal';
import styled from 'styled-components';

export const StyledUserModal = styled(CustomModal)`
    .ant-modal-content {
        width: 360px;
        margin: 0 auto;
        .ant-modal-header {
            margin-left: 20px;
            margin-right: 20px;
            padding: 20px 0px 16px 0px;
            .ant-modal-title {
                .custom-modal-header {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }

        .ant-modal-body {
            margin-left: 20px;
            margin-right: 20px;
            padding-top: 20px;
            .ant-spin.ant-spin-lg.ant-spin-spinning {
                display: flex !important;
                justify-content: center !important;
            }
            .section-inputs {
                width: 100px;
                .ant-select-selector {
                    width: 320px !important;
                }
                .ant-input-affix-wrapper{
                    width: 320px;
                }
            }
        }

        .ant-modal-footer {
            border: none;
            padding-right: 0px;
            margin-left: 20px;
            margin-right: 20px;
            padding: 24px 0px;
            .custom-modal-footer {
                display: flex;
                justify-content: space-between;
                .button-section:first-child {
                    padding-right: 10px;
                }
            }

        //on error

        .error-input {
            border-color: var(--red);
        }
    }
`;
