import styled from 'styled-components';

const StyledCustomMultiSelect = styled.div`
    .custom-select-div {
        margin-top: 12px;
        min-width: 240px;
        margin-bottom: 14px;
    }
    .custom-select-div input {
        cursor: pointer;
    }
    .custom-select-div .custom-select-label {
        font-family: 'Source-Sans-Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .dropdown-container {
        border: 1px solid #c4c4c4;
        border-radius: 10px;
    }
    .icon {
        cursor: pointer;
    }
    .icon path {
        fill: #03a8f5;
    }
`;

export { StyledCustomMultiSelect };
