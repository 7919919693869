import { useContext } from 'react';

import {
    canAccessPeopleCurrent,
    getClassForCurrent
} from 'main/utils/Main/permissionsHelper';
import PermissionsContext from 'shared/utils/permissionStore';

import { StyledOfficeCounter } from './styled/OfficeCounter.styled';
import { STATIC_ROUTES_PATHS } from '../../../routes';
import TabsWrapper from '../../../shared/components/DashboardTabs/TabsWrapper';
import { BulgariaIcon } from '../../../shared/theme/assets/icons/BulgariaIcon';
import { UnitedStatesIcon } from '../../../shared/theme/assets/icons/UnitedStatesIcon';

const LOCATION_OPTIONS = [
    { label: 'Sofia', value: 1 },
    { label: 'Plovdiv', value: 2 },
    { label: 'Varna', value: 3 },
    { label: 'Veliko Turnovo', value: 4 },
    { label: 'Ruse', value: 5 },
    { label: 'Minneapolis', value: 6 },
    { label: 'Remote', value: 7 }
];

export const OfficeCounter = ({ data, history, defaultFilters }) => {
    const permissions = useContext(PermissionsContext).permissions;
    const {
        sofiaEmployeesCount,
        plovdivEmployeesCount,
        varnaEmployeesCount,
        ruseEmployeesCount,
        velikoTurnovoEmployeesCount,
        minneapolisEmployeesCount,
        remoteEmployeesCount
    } = data;

    const handleOnLocationClick = prop => {
        if (canAccessPeopleCurrent(permissions)) {
            const locationValue = LOCATION_OPTIONS.find(
                x => x.label === prop
            ).value;
            history.push({
                pathname: STATIC_ROUTES_PATHS.peopleDashboard,
                state: {
                    subsetTab: 1,
                    filters: { ...defaultFilters, locationId: locationValue }
                }
            });
        }
    };

    const handleTabChange = () => ({});

    return (
        <StyledOfficeCounter>
            <div
                className={`office-counter-content ${getClassForCurrent(
                    permissions
                )}`}
            >
                <div className="office-box">
                    <div className="title">Counter by Office</div>
                    <div className="country-tabs">
                        <TabsWrapper
                            activeItemIndex={0}
                            handleTabChange={handleTabChange}
                        >
                            <div key={0} label={`Bulgaria`} disabled={false}>
                                <div className="box">
                                    <BulgariaIcon />
                                    <div
                                        className="city-dot sofia"
                                        data-testid="sofia-dot"
                                        onClick={() =>
                                            handleOnLocationClick('Sofia')
                                        }
                                    >
                                        {sofiaEmployeesCount}
                                    </div>
                                    <div
                                        className="city-dot plovdiv"
                                        data-testid="plovdiv-dot"
                                        onClick={() =>
                                            handleOnLocationClick('Plovdiv')
                                        }
                                    >
                                        {plovdivEmployeesCount}
                                    </div>
                                    <div
                                        className="city-dot varna"
                                        data-testid="varna-dot"
                                        onClick={() =>
                                            handleOnLocationClick('Varna')
                                        }
                                    >
                                        {varnaEmployeesCount}
                                    </div>
                                    <div
                                        className="city-dot ruse"
                                        data-testid="ruse-dot"
                                        onClick={() =>
                                            handleOnLocationClick('Ruse')
                                        }
                                    >
                                        {ruseEmployeesCount}
                                    </div>
                                    <div
                                        className="city-dot vt"
                                        data-testid="vt-dot"
                                        onClick={() =>
                                            handleOnLocationClick(
                                                'Veliko Turnovo'
                                            )
                                        }
                                    >
                                        {velikoTurnovoEmployeesCount}
                                    </div>
                                    <div
                                        className="remote-dot"
                                        data-testid="remote-dot"
                                        onClick={() =>
                                            handleOnLocationClick('Remote')
                                        }
                                    >
                                        <div className="label">Remote:</div>
                                        <div className="count">
                                            {remoteEmployeesCount}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                key={1}
                                label={`United States`}
                                disabled={false}
                            >
                                <div className="box">
                                    <UnitedStatesIcon />
                                    <div
                                        className="city-dot minneapolis"
                                        data-testid="minneapolis-dot"
                                        onClick={() =>
                                            handleOnLocationClick('Minneapolis')
                                        }
                                    >
                                        {minneapolisEmployeesCount}
                                    </div>
                                    <div
                                        className="remote-dot minneapolis"
                                        data-testid="remote-usa-dot"
                                        onClick={() =>
                                            handleOnLocationClick('Remote')
                                        }
                                    >
                                        <div className="label">Remote:</div>
                                        <div className="count">
                                            {remoteEmployeesCount}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabsWrapper>
                    </div>
                </div>
            </div>
        </StyledOfficeCounter>
    );
};
