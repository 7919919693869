import styled from 'styled-components';

const StyledDetailsMainContainer = styled.div`
    .info-header {
        display: flex;
        justify-content: space-between;
        .info-header-title {
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;
        }
        .info-heder-info {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
        }
        .btn-normal {
            width: 147px;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0em;
            padding: 8px 15px;
            .icon {
                width: 18px;
                height: 18px;
                margin-right: 9px;
                align-self: center;
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
            path {
                stroke: var(--mainBlue) !important;
            }
        }
        .btn-disabled,
        .btn-disabled:hover {
            color: var(--darkGray) !important;
            path {
                stroke: var(--darkGray) !important;
            }
        }
        .ant-spin {
            padding-top: 50px !important;
            padding-bottom: 20px;
        }
    }
`;

export { StyledDetailsMainContainer };
