import styled from 'styled-components';

const StyledEmployeeDetailsSide = styled.div`
    .employee-details-box.contact-information {
        min-height: 190px;
    }
    .employee-details-box.service-length {
        min-height: 89px;
    }
    .employee-details-box.reports-to {
        min-height: 75px;
    }

    .employee-details-box {
        width: 290px;
        background: var(--primaryWhite);
        box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 20px;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .employee-details-box .employee-details-box-heading {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        color: #000000;
        margin-bottom: 16px;
    }
    .employee-details-box .employee-details-box-text {
        font-size: 14px;
        line-height: 18px;
    }
    .employee-details-box
        .employee-details-box-text
        .employee-details-box-text-email {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .employee-details-box .employee-details-box-text .employee-service {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        padding-bottom: 4px;
    }
    .working-dates-total {
        padding-top: 4px;
    }
    .trial {
        display: flex;
        align-items: center;
        height: 21px;
        width: 70px;
        margin-left: 5px;
        padding: 2px 9px;
        background: #fd9800;
        border-radius: 5px;
        color: white;
        font-style: normal;
        font-weight: 600;
        line-height: 13px;
        letter-spacing: 0em;
        font-size: 10px;
        text-align: left;
    }
    .manager-img {
        object-fit: cover;
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }
    .employee-details-box .employee-details-box-manager {
        display: flex;
    }
    .employee-details-box-manager {
        .employee-details-box-manager-img {
            align-self: center;
        }
    }
    .employee-details-box
        .employee-details-box-manager
        .employee-details-box-manager-info {
        margin-left: 10px;
        flex-direction: column;
        cursor: pointer;
    }
    .employee-details-box
        .employee-details-box-manager
        .employee-details-box-manager-info
        .employee-details-box-manager-info-names {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
    }
    .employee-details-box
        .employee-details-box-manager
        .employee-details-box-manager-info
        .employee-details-box-manager-info-title {
        margin-top: 8px;
        font-size: 12px;
    }
    .employee-details-box .employee-details-box-text {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;
    }
    .employee-details-box .employee-details-box-links {
        border-top: 1px solid var(--whiteGray);
        padding-top: 10px;
    }
    .employee-details-box .employee-details-box-links .org-button {
        color: var(--linkColor);
    }
    .employee-details-box .employee-details-box-links > div {
        padding-bottom: 10px;
    }
    @media screen and (max-width: 426px) {
        .employee-details-box {
            width: 312px;
        }
    }
`;

export { StyledEmployeeDetailsSide };
