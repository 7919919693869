import styled from 'styled-components';

const StyledDiscountsTab = styled.div`
    .inactive-discounts-button {
        height: 34px;
        width: 68px;
        color: #03a8f5;
        font-weight: 900;
        margin-left: 10px !important;

        :hover {
            color: #1c84fe;
        }

        &.active {
            color: white;
            background-color: #03a8f5 !important;
        }
    }

    .active-button-container {
        flex-grow: 10;
    }

    .all-discounts-container {
        .table-wrapper {
            margin-left: -20px;
            margin-right: -15px;
        }
    }

    @media screen and (max-width: 768px) {
        .table-wrapper {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    .all-discounts-container {
        width: 100%;
        padding-top: 10px;
    }
    .table-name-section {
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;
    }
    .name-info {
        padding-left: 10px;
    }
    .bold-name {
        font-weight: 600;
        color: var(--darkNavy);
    }
    .pointer > tr {
        margin: 10px;
    }
    .discounts-extra-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .visibility-cell {
        margin: 0 auto;
    }
`;

export { StyledDiscountsTab };
