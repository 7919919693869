import React from 'react';

import { Input } from 'antd';
import { isEmpty } from 'lodash';

import { StyledCustomInput } from './styled/Input.styled';
import { tableSearchRegex } from '../constants/regexConstants';

export const CustomInput = ({
    width,
    className,
    value,
    onChange,
    label,
    placeholder,
    disabled = false,
    type = 'text',
    hasMargins = false,
    allowClear = false,
    onBlur = () => {},
    onKeyPress = () => {},
    onPressEnter = () => {},
    onPaste = () => {},
    onClear = () => {},
    setFieldTouched = () => {},
    onFocus = () => {},
    allowLettersOnly = false,
    maxLettersLength,
    isNormalChange = true,
    accept,
    ...rest
}) => {
    const { prefix, name, isEditMode, noLabelStyle, specialEnter } = rest;
    const handleBlur = () => {
        onBlur(name);
        setFieldTouched(name);
    };

    const handleFocus = () => {
        onFocus(name);
        setFieldTouched(name, false);
    };

    const handlePressEnter = () => {
        if (specialEnter) {
            onBlur(name);
        } else {
            onPressEnter();
        }
    };
    const handleClick = target => {
        if (value && isEditMode) {
            target.target.setSelectionRange(0, target.target.value.length);
        }
    };

    const handleKeyPress = e => {
        if (allowLettersOnly) {
            const pressedKey = String.fromCharCode(
                !e.charCode ? e.which : e.charCode
            );

            const matchesRegex = new RegExp(tableSearchRegex).test(pressedKey);

            if (maxLettersLength) {
                const isAboveLimit =
                    e.target.value.length > maxLettersLength - 1;

                const shouldProhibitTyping = matchesRegex && isAboveLimit;
                if (shouldProhibitTyping) {
                    e.preventDefault();
                }
            }

            if (!matchesRegex) {
                e.preventDefault();
            }
        }
    };

    const handlePaste = e => {
        const clipboardString = e.clipboardData.getData('Text').toString();
        const matchesRegex = new RegExp(tableSearchRegex).test(clipboardString);
        let shouldProhibitPaste;
        //if current value exists compare the clipboard data against it
        if (!isEmpty(value)) {
            const totalLength = value.length + clipboardString.length;
            shouldProhibitPaste =
                (allowLettersOnly && !matchesRegex) ||
                totalLength > maxLettersLength;
        } else {
            shouldProhibitPaste =
                (allowLettersOnly && !matchesRegex) ||
                clipboardString.length > maxLettersLength;
        }

        if (shouldProhibitPaste) {
            e.preventDefault();
        }
    };

    const handleChange = e => {
        if (isNormalChange) {
            if (e.target.value === '') {
                onClear();
            }
            onChange(e);
        } else {
            onChange(name, e.target.value);
        }
    };

    return (
        <StyledCustomInput width={width}>
            <div
                className={
                    `customInput` +
                    `${className ? ` ${className}` : ''}` +
                    `${hasMargins ? ' inputMargin' : ''}` +
                    `${prefix ? ' hasPrefix' : ''}` +
                    `${noLabelStyle ? ' no-label' : ''}`
                }
            >
                {label && (
                    <div
                        className={disabled ? 'label-disabled' : 'input-label'}
                    >
                        {label}
                    </div>
                )}
                {allowLettersOnly ? (
                    <Input
                        onFocus={handleFocus}
                        prefix={prefix}
                        allowClear={allowClear}
                        name={name}
                        aria-label={name}
                        placeholder={placeholder}
                        value={value}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        onPaste={handlePaste}
                        onBlur={handleBlur}
                        type={type}
                        disabled={disabled}
                        accept={accept}
                    />
                ) : (
                    <Input
                        onFocus={handleFocus}
                        prefix={prefix}
                        allowClear={allowClear}
                        name={name}
                        aria-label={name}
                        placeholder={placeholder}
                        value={value}
                        onChange={handleChange}
                        type={type}
                        disabled={disabled}
                        onBlur={handleBlur}
                        onClick={handleClick}
                        onPressEnter={handlePressEnter}
                        accept={accept}
                    />
                )}
            </div>
        </StyledCustomInput>
    );
};
