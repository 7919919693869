import { mapDataForDropdown } from '../../utils/dropdownHelper';

/** Generated based on historicalDataHelper. */
const datePickerInputNames = [
    'Profile Creation',
    'End Date',
    'Start Date',
    'End Date'
];
const inputFieldNames = [
    'Latin Name',
    'Bulgarian Name',
    'Trial Period',
    'Working Time',
    'Work Location'
];
// const autoCopleteFieldNames = [];
const selectFieldNames = [
    'Department',
    'Level',
    'Main Reason for Leaving',
    'Hiring Channel',
    'Type Of Work',
    'Voluntary',
    'Leaver Type',
    'Type of Mentormater',
    'Dedicated Office'
];
const selectSearchFieldNames = ['Position', 'Reports To'];

export const getHistoricalInputType = inputName => {
    if (datePickerInputNames.includes(inputName)) {
        return 'datePicker';
    }
    if (inputFieldNames.includes(inputName)) {
        return 'input';
    }
    // if (autoCopleteFieldNames.includes(inputName)) {
    //     return 'autoComplete';
    // }
    if (selectFieldNames.includes(inputName)) {
        return 'select';
    }
    if (selectSearchFieldNames.includes(inputName)) {
        return 'selectSearch';
    }
};

export const getCurrentOptions = (dropDownState, inputName) => {
    const {
        positions,
        managers,
        levels,
        departments,
        locations,
        typesOfMentormater,
        reasonsForLeaving,
        leaverTypes,
        voluntaryTypes,
        typesOfWork,
        hiringChannels
    } = dropDownState;
    if (
        selectFieldNames.includes(inputName) ||
        selectSearchFieldNames.includes(inputName)
    ) {
        if (inputName === 'Department') {
            return mapDataForDropdown(departments);
        } else if (inputName === 'Level') {
            return mapDataForDropdown(levels);
        } else if (inputName === 'Main Reason for Leaving') {
            return mapDataForDropdown(reasonsForLeaving);
        } else if (inputName === 'Hiring Channel') {
            return mapDataForDropdown(hiringChannels);
        } else if (inputName === 'Voluntary') {
            return mapDataForDropdown(voluntaryTypes);
        } else if (inputName === 'Leaver Type') {
            return mapDataForDropdown(leaverTypes);
        } else if (inputName === 'Type Of Work') {
            return mapDataForDropdown(typesOfWork);
        } else if (inputName === 'Type of Mentormater') {
            return mapDataForDropdown(typesOfMentormater);
        } else if (inputName === 'Dedicated Office') {
            return mapDataForDropdown(locations);
        } else if (inputName === 'Reports To') {
            return mapDataForDropdown(managers);
        } else if (inputName === 'Position') {
            return mapDataForDropdown(positions);
        }
    }

    return undefined;
};

export const camelCasePropName = propName => {
    return propName.charAt(0).toLowerCase() + propName.slice(1);
};
