import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { mapDataForDropdown } from 'utils/dropdownHelper';

import { updateRelativeMembersData } from '../../../../redux/slices/dropdown';
import {
    removeRelativeMember,
    updateRelativeMember
} from '../../../../redux/slices/relativeMember';
import { CustomConfirmationModal } from '../../../../shared/components/ConfirmationModal';
import { CustomDateSelector } from '../../../../shared/components/DatePicker';
import { FieldsSection } from '../../../../shared/components/FieldsSection';
import { CustomInput } from '../../../../shared/components/Input';
import { CustomSelect } from '../../../../shared/components/Select';
import {
    selectPlaceholder,
    setAllowClear,
    setClassName
} from '../../../../utils/componentPropHelpers';
import { validateForm } from '../../../utils/Details/PersonalInformation/relativeSectionFormHelper';
import { checkAndUpdateByPersonalID } from '../../../utils/Details/PersonalInformation/sharedHelper';
import { normalizeRequestData } from '../../../utils/modelMapper';

const INITIAL_VALUES = {
    personRelativeMemberId: null,
    birthday: null,
    personalId: null,
    relationId: undefined,
    name: null,
    nameBg: null
};

const RelativeSubSection = ({
    initialValues = INITIAL_VALUES,
    relativeMemberIndex,
    callCounter,
    setCallCounter,
    setShowInfoText,
    personId,
    permissions
}) => {
    const dropdownsState = useSelector(state => state.dropdown);
    const [errors, setErrors] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [initialValuesFormik, setInitialValuesFormik] =
        useState(initialValues);
    const dispatch = useDispatch();
    const relationsOptions = mapDataForDropdown(dropdownsState.relations);

    const validate = values => {
        const errorsIncome = validateForm(values);
        setErrors(errorsIncome);
    };

    const handleDeleteRelativeMember = () => {
        setIsModalOpen(false);
        dispatch(removeRelativeMember(initialValues.personRelativeMemberId));
    };

    const handleBlur = (
        inputName,
        value,
        setFieldValue = () => {},
        permissions
    ) => {
        const newValue = value?.toString().trim() ?? '';
        const initialVal = initialValuesFormik[inputName]?.toString() ?? '';
        if (newValue !== initialVal && !errors[inputName]) {
            setShowInfoText(true);
            setInitialValuesFormik(prev => {
                let temp = { ...prev };
                temp[inputName] = value;
                return temp;
            });
            setCallCounter(prev => prev + 1);

            let requestObj = { ...initialValuesFormik, [inputName]: value };

            requestObj = checkAndUpdateByPersonalID(
                inputName,
                value,
                requestObj,
                setFieldValue
            );

            let result = dispatch(
                updateRelativeMember(
                    relativeMemberIndex,
                    callCounter + 1,
                    initialValues.personRelativeMemberId,
                    normalizeRequestData(requestObj),
                    permissions
                )
            );

            if (result && inputName === 'name') {
                result.then(() =>
                    dispatch(updateRelativeMembersData(personId))
                );
            }
        }
    };

    return (
        <div data-testid="inner-relative-section">
            <Formik initialValues={initialValues} validate={validate}>
                {props => {
                    const { values, setFieldValue } = props;

                    return (
                        <Form>
                            <FieldsSection>
                                <div className="section-inputs">
                                    <CustomInput
                                        className={setClassName(errors?.nameBg)}
                                        label="Name(Cyrillic)"
                                        name="nameBg"
                                        placeholder="Type..."
                                        allowClear={true}
                                        specialEnter={true}
                                        isNormalChange={false}
                                        value={values.nameBg}
                                        onChange={(name, val) => {
                                            setFieldValue(name, val);
                                        }}
                                        onBlur={name => {
                                            handleBlur(
                                                name,
                                                values.nameBg,
                                                null,
                                                permissions
                                            );
                                        }}
                                    />
                                    <CustomInput
                                        className={setClassName(errors?.name)}
                                        label="Name(Latin)"
                                        name="name"
                                        placeholder="Type..."
                                        allowClear={true}
                                        specialEnter={true}
                                        isNormalChange={false}
                                        value={values.name}
                                        onChange={(name, val) => {
                                            setFieldValue(name, val);
                                        }}
                                        onBlur={name => {
                                            handleBlur(
                                                name,
                                                values.name,
                                                null,
                                                permissions
                                            );
                                        }}
                                    />
                                </div>
                                <div className="section-inputs">
                                    <CustomInput
                                        className={setClassName(
                                            errors?.personalId
                                        )}
                                        label="Personal ID"
                                        name="personalId"
                                        placeholder={
                                            permissions.canViewRelativePersonalId
                                                ? 'Type...'
                                                : ''
                                        }
                                        allowClear={true}
                                        specialEnter={true}
                                        isNormalChange={false}
                                        disabled={
                                            !permissions.canViewRelativePersonalId
                                        }
                                        value={values.personalId}
                                        onChange={(name, val) => {
                                            setFieldValue(name, val);
                                        }}
                                        onBlur={name => {
                                            handleBlur(
                                                name,
                                                values.personalId,
                                                setFieldValue,
                                                permissions
                                            );
                                        }}
                                    />
                                    <CustomDateSelector
                                        value={values.birthday}
                                        label="Birth Date"
                                        name="birthday"
                                        onChange={setFieldValue}
                                        onBlur={(name, val) => {
                                            handleBlur(
                                                name,
                                                val,
                                                null,
                                                permissions
                                            );
                                        }}
                                    />
                                    <CustomSelect
                                        value={values.relationId}
                                        label="Type Of Relation"
                                        name="relationId"
                                        placeholder={selectPlaceholder}
                                        allowClear={setAllowClear(
                                            values.relationId
                                        )}
                                        options={relationsOptions}
                                        onChange={setFieldValue}
                                        onBlur={(name, val) => {
                                            handleBlur(
                                                name,
                                                val,
                                                null,
                                                permissions
                                            );
                                        }}
                                    />
                                </div>
                            </FieldsSection>
                            <div
                                data-testid="delete-section"
                                className="delete-section"
                                onClick={() => setIsModalOpen(true)}
                            >
                                Delete relative member
                            </div>
                            <div className="section-splitter"></div>
                        </Form>
                    );
                }}
            </Formik>
            {isModalOpen && (
                <CustomConfirmationModal
                    visible={isModalOpen}
                    setVisible={setIsModalOpen}
                    title={'Confirmation'}
                    contentText={`You are going to delete this record.`}
                    handleConfirm={handleDeleteRelativeMember}
                />
            )}
        </div>
    );
};

export { RelativeSubSection };
