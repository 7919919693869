import React, { useEffect, useState } from 'react';

import { Switch } from 'antd';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetOffer,
    loadOfferLocations,
    addOffer,
    updateOffer
} from 'redux/slices/offer';
import { CustomButton } from 'shared/components/Button';
import { CloseButton } from 'shared/components/CloseButton';
import { ExtendedFileManager } from 'shared/components/ExtendedFileManager';
import { CustomInput } from 'shared/components/Input';
import { CustomMultiSelect } from 'shared/components/MultiSelect';
import { errorNotification } from 'shared/components/Notifications';
import RichText from 'shared/components/RichText';
import { Spinner } from 'shared/components/Spinner';
import Row from 'shared/components/styled/Row.styled';
import { UploadImage } from 'shared/components/UploadImage';
import { mapDataForDropdown } from 'utils/dropdownHelper';

import {
    handleOfferLocationOnChange,
    MAX_LENGTH_RICH_TEXT,
    getSelectedLocationOnEdit,
    setClassName,
    validateOffer
} from './offerModalHelper';
import { SelectedOptions } from './SelectedOptions';
import { StyledOfferModal } from '../OfferModal/styled/OfferModal.styled';

const OfferModal = ({
    title,
    visible,
    setVisible = () => {},
    closeCallback = () => {}
}) => {
    const dispatch = useDispatch();
    const {
        isLoadingFetchSingle,
        offerSingleData,
        errorFetchSingle,
        locations,
        isEditMode
    } = useSelector(state => state.offer);

    const [selectedLocations, setSelectedLocations] = useState([]);
    const locationOptions = mapDataForDropdown(locations);

    useEffect(() => {
        if (isEditMode && offerSingleData) {
            setSelectedLocations(getSelectedLocationOnEdit(offerSingleData));
        }
    }, [offerSingleData]);

    useEffect(() => {
        if (errorFetchSingle) {
            errorNotification(errorFetchSingle);
        }
    }, [errorFetchSingle]);

    const handleClose = () => {
        closeCallback();
        dispatch(setVisible(false));
    };

    useEffect(() => {
        if (visible) {
            dispatch(loadOfferLocations());
        }
    }, [visible]);

    const handleRichTextChange = (name, values, setFieldValue) => {
        setFieldValue(name, values.html);
        setFieldValue(name + '_plainText', values.plainText);
    };

    const localSubmit = (submitValues, dirty, isValid) => {
        if (dirty && isValid) {
            isEditMode
                ? dispatch(updateOffer(submitValues))
                : dispatch(addOffer(submitValues));
        }
    };

    useEffect(() => {
        return () => {
            dispatch(resetOffer());
        };
    }, []);

    const changeActive = (checked, setFieldValue) => {
        setFieldValue('active', checked);

        if (!checked) {
            setFieldValue('selectable', false);
        }
    };

    return (
        <Formik
            initialValues={offerSingleData}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={true}
            validate={validateOffer}
        >
            {props => {
                const {
                    values,
                    errors,
                    touched,
                    isValid,
                    dirty,
                    setFieldValue,
                    setFieldTouched
                } = props;
                return (
                    <StyledOfferModal
                        visible={visible}
                        setVisible={setVisible}
                        title={[
                            <div
                                className="custom-modal-header"
                                key="modal-title"
                            >
                                {title}
                                <CloseButton handleClose={handleClose} />
                            </div>
                        ]}
                        footer={
                            !isLoadingFetchSingle && (
                                <div
                                    className="custom-modal-footer"
                                    key="modal-footer"
                                >
                                    <div className="button-section">
                                        <CustomButton
                                            text="Close"
                                            type="normal footer-button"
                                            onClick={handleClose}
                                            disabled={isLoadingFetchSingle}
                                        />
                                    </div>
                                    <div className="button-section">
                                        <CustomButton
                                            text="Save"
                                            type="filled footer-button"
                                            onClick={() =>
                                                localSubmit(
                                                    values,
                                                    dirty,
                                                    isValid
                                                )
                                            }
                                            disabled={!(dirty && isValid)}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        handleCustomCancel={closeCallback}
                        data-testid="offer-type-modal"
                    >
                        {isLoadingFetchSingle ? (
                            <div className="spinner-container">
                                <Spinner />
                            </div>
                        ) : (
                            <Form>
                                <UploadImage
                                    imageText="Select Image *"
                                    onUpload={file => {
                                        setFieldValue('image', file);
                                        setFieldValue('updateImage', true);
                                        setFieldTouched('image');
                                    }}
                                    initialImage={values.image}
                                    maxSize={MAX_IMAGE_SIZE}
                                    hideDeleteButton={true}
                                    externalError={
                                        touched.image && errors?.image
                                    }
                                />
                                <CustomInput
                                    label="Image Alt Text *"
                                    placeholder="Image Alt Text..."
                                    value={values.imageAltText}
                                    name={'imageAltText'}
                                    className={setClassName(
                                        touched.imageAltText &&
                                            errors?.imageAltText
                                    )}
                                    isNormalChange={false}
                                    onChange={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    allowClear={true}
                                />
                                <CustomInput
                                    label="Offer *"
                                    placeholder="Offer..."
                                    value={values.name}
                                    name={'name'}
                                    className={setClassName(
                                        touched.name && errors?.name
                                    )}
                                    isNormalChange={false}
                                    onChange={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    allowClear={true}
                                />
                                <CustomInput
                                    label="Short Offer Description *"
                                    placeholder="Short Offer Description..."
                                    value={values.shortDescription}
                                    name={'shortDescription'}
                                    className={setClassName(
                                        touched.shortDescription &&
                                            errors?.shortDescription
                                    )}
                                    isNormalChange={false}
                                    onChange={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    allowClear={true}
                                />
                                <div className="selectors-section">
                                    <div className="selector">
                                        <CustomMultiSelect
                                            className={`wider ${setClassName(
                                                touched.locations &&
                                                    errors?.locations
                                            )}`}
                                            label="Location *"
                                            hasSelectAll={false}
                                            disableSearch={false}
                                            canCreate={true}
                                            value={values.locations}
                                            name={'locations'}
                                            options={locationOptions}
                                            onChange={(name, _, values) =>
                                                handleOfferLocationOnChange(
                                                    name,
                                                    values,
                                                    setFieldValue,
                                                    setFieldTouched
                                                )
                                            }
                                            onClick={isMenuOpened => {
                                                setFieldTouched(
                                                    'locations',
                                                    !isMenuOpened
                                                );
                                            }}
                                            onSelection={setSelectedLocations}
                                            selected={selectedLocations}
                                        />
                                    </div>
                                </div>
                                <SelectedOptions
                                    options={selectedLocations}
                                    values={values.locations}
                                    onChange={value => {
                                        setSelectedLocations(value);
                                        handleOfferLocationOnChange(
                                            'locations',
                                            value,
                                            setFieldValue,
                                            setFieldTouched
                                        );
                                    }}
                                    handleValues={setFieldValue}
                                />
                                <CustomInput
                                    label="Website"
                                    placeholder="Website..."
                                    value={values.website}
                                    name={'website'}
                                    className={setClassName(errors?.website)}
                                    isNormalChange={false}
                                    onChange={setFieldValue}
                                    allowClear={true}
                                />
                                <RichText
                                    label="How much *"
                                    placeholder="How much ...*"
                                    value={values.howMuch}
                                    name={'howMuch'}
                                    hasError={
                                        touched.howMuch && errors?.howMuch
                                    }
                                    onChange={(name, values) =>
                                        handleRichTextChange(
                                            name,
                                            values,
                                            setFieldValue
                                        )
                                    }
                                    setFieldTouched={setFieldTouched}
                                    maxLength={MAX_LENGTH_RICH_TEXT}
                                    id={'howMuch'}
                                />
                                <RichText
                                    label="How to use *"
                                    placeholder="How to use ...*"
                                    value={values.howToUse}
                                    name={'howToUse'}
                                    hasError={
                                        touched.howToUse && errors?.howToUse
                                    }
                                    onChange={(name, values) =>
                                        handleRichTextChange(
                                            name,
                                            values,
                                            setFieldValue
                                        )
                                    }
                                    setFieldTouched={setFieldTouched}
                                    maxLength={MAX_LENGTH_RICH_TEXT}
                                    id={'howToUse'}
                                />

                                <ExtendedFileManager
                                    uploadModalTitle={'Upload document(s)'}
                                    files={values.files}
                                    filesFormikFieldName={'files'}
                                    filesToDeleteFormikFieldName={
                                        'deletedFiles'
                                    }
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                />

                                <Row justifyContent="space-between">
                                    <span className="input-label">
                                        Make this Offer to be visible on
                                        MentorNet
                                    </span>
                                    <Row>
                                        <Switch
                                            checked={values.selectable}
                                            checkedChildren="YES"
                                            unCheckedChildren="NO"
                                            disabled={!values.active}
                                            onChange={checked => {
                                                setFieldValue(
                                                    'selectable',
                                                    checked
                                                );
                                            }}
                                        />
                                    </Row>
                                </Row>
                                <Row justifyContent="space-between">
                                    <span className="input-label">
                                        Make this Offer active in Humate
                                    </span>
                                    <Row>
                                        <Switch
                                            checked={values.active}
                                            checkedChildren="YES"
                                            unCheckedChildren="NO"
                                            onChange={checked => {
                                                changeActive(
                                                    checked,
                                                    setFieldValue
                                                );
                                            }}
                                        />
                                    </Row>
                                </Row>
                            </Form>
                        )}
                    </StyledOfferModal>
                );
            }}
        </Formik>
    );
};

export { OfferModal };

const MAX_IMAGE_SIZE = 5242880; // 1024*1024*5 -> 5MB
