import { useEffect, useState } from 'react';

import { getUsersWithSelfFlag } from 'admin/utils/Dashboard/usersTabHelper';
import SubsetTabSearch from 'people/components/Dashboard/PeopleSubsetTab/SubsetTabSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeSorting,
    clearState,
    createUser,
    fetchUsers,
    resetShowCreateMessage,
    resetSorting
} from 'redux/slices/user';
import { CustomButton, successNotification } from 'shared/components';
import Footer from 'shared/components/layout/Footer';
import { errorNotification } from 'shared/components/Notifications';
import useInfiniteScroll from 'shared/hooks/useInfiniteScroll';
import { getUserIdFromToken } from 'utils/tokenHelper';

import { StyledUsersTab } from './styled/UsersTab.style';
import { UsersTable } from './Table/UsersTable';
import { UserModal } from '../shared/UserModal/UserModal';

const DEFAULT_SORT = [{ id: 'email', desc: false }];

const DEFAULT_STATE = {
    sort: DEFAULT_SORT,
    searchPhrase: undefined
};

export const UsersTab = ({ handleCreateColumns, permissions }) => {
    const dispatch = useDispatch();

    const { users, isLoading, totalCount, error, showCreateMessage } =
        useSelector(state => state.user);

    const [state, setState] = useState(DEFAULT_STATE);

    const [addUserModal, setAddUserModal] = useState(false);

    const currentUserId = getUserIdFromToken();
    const usersData = getUsersWithSelfFlag(users, currentUserId);

    useEffect(() => {
        dispatch(fetchUsers(state.searchPhrase));
    }, [dispatch, state]);

    useEffect(() => {
        return () => {
            dispatch(resetSorting());
        };
    }, []);

    useEffect(() => {
        if (error) {
            errorNotification(error);
        }
    }, [error]);

    useEffect(() => {
        if (showCreateMessage) {
            successNotification('Successfully added a user.');
            dispatch(resetShowCreateMessage());
        }
    }, [showCreateMessage]);

    const handleSortUpdate = value => {
        setState({ ...state, ['sort']: value });
        dispatch(changeSorting(value));
        dispatch(clearState());
    };

    const handleValueUpdate = (which, val) => {
        setState(previous => {
            return { ...previous, [which]: val };
        });
    };

    // infinite scroll handling
    const boundaryRef = useInfiniteScroll({
        onLoadMore: () => {
            dispatch(fetchUsers(state.searchPhrase, true));
        },
        isLoading,
        hasMore: Boolean(users.length < totalCount)
    });

    const handleOpenAddModal = () => {
        setAddUserModal(true);
    };

    const handleSubmitAdd = values => {
        const requestData = {
            personId: values.personId,
            roleId: values.roleId
        };

        dispatch(createUser(requestData));
        setAddUserModal(false);
    };

    return (
        <>
            <StyledUsersTab>
                <div className="users-container">
                    <div className="users-extra-header">
                        <SubsetTabSearch
                            search={{ handleValueUpdate }}
                            allowLettersOnly={false}
                            searchPhrase={state.searchPhrase}
                        />
                        <CustomButton
                            type={'normal'}
                            text={'Add New User'}
                            onClick={() => {
                                permissions.canCreateUser
                                    ? handleOpenAddModal()
                                    : undefined;
                            }}
                            disabled={!permissions.canCreateUser}
                        />
                    </div>
                    <UsersTable
                        totalCount={totalCount}
                        handleCreateTableColumns={handleCreateColumns}
                        usersData={usersData}
                        pageSize={10}
                        loading={isLoading}
                        noDataText={'No Users Found'}
                        hasPagination={false}
                        sorted={state.sort}
                        handleSortUpdate={handleSortUpdate}
                        boundaryRef={boundaryRef}
                        permissions={permissions}
                    />
                    {addUserModal && (
                        <UserModal
                            title={'Add New User'}
                            visible={addUserModal}
                            isEdit={false}
                            setVisible={setAddUserModal}
                            handleSubmit={handleSubmitAdd}
                        />
                    )}
                </div>
                {!Boolean(users.length < totalCount) && (
                    <Footer className={'special'} />
                )}
            </StyledUsersTab>
        </>
    );
};
