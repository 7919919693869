const { clientId, microsoftClinetId, microsoftTenantUrl } = {
    clientId: process.env.REACT_APP_CLIENT_ID,
    microsoftClinetId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    microsoftTenantUrl: process.env.REACT_APP_MICROSOFT_TENANT_URL
};

export const config = {
    client_id: clientId,
    microsoft_client_id: microsoftClinetId,
    microsoft_tenant_url: microsoftTenantUrl,
    scope: 'openid profile email'
};
