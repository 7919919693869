import React, { useMemo } from 'react';

import { CustomTableWithFixedColumns } from 'shared/components/CustomTableWithFixedColumns';

import { createColumns } from '../utils/createColumns';

const Table = ({
    defaultSort,
    data,
    tabName,
    pageSize,
    loading,
    noDataText,
    hasPagination,
    boundaryRef,
    handleRowClick,
    handleSortingChange,
    sorted,
    permissions
}) => {
    const columns = useMemo(
        () => createColumns(data, tabName, permissions),
        [data]
    );

    return (
        <div className="table-wrapper">
            <CustomTableWithFixedColumns
                defaultSorted={defaultSort}
                sorted={sorted}
                columns={columns}
                data={data}
                pageSize={pageSize}
                loading={loading}
                noDataText={noDataText}
                hasPagination={hasPagination}
                handleSortingChange={handleSortingChange}
                resizable={true}
                sortable={true}
                getTrProps={(_state, rowInfo) => ({
                    ...(tabName !== 'cancelled'
                        ? { onClick: () => handleRowClick(rowInfo.original) }
                        : {})
                })}
            />
            <section ref={boundaryRef} />
        </div>
    );
};

export default Table;
