import { PERMISSIONS_MAPPING } from 'shared/constants/permissionsMapping';

export const getUserPermissionsObject = permissionEncodings => {
    const obj = {};

    if (!Array.isArray(permissionEncodings)) {
        obj[PERMISSIONS_MAPPING[Number(permissionEncodings)]] = true;
        return obj;
    }

    for (let i = 0; i < permissionEncodings.length; i++) {
        const permission = Number(permissionEncodings[i]);
        obj[PERMISSIONS_MAPPING[permission]] = true;
    }

    return obj;
};
