import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const getAllDepartments = async requestModel => {
    const { skip, take, sortBy, sortAsc, searchPhrase, loadActive } =
        requestModel;
    const res = await httpService.get(url.GET_ALL_DEPARTMENTS_PAGINATED(), {
        params: {
            skip,
            take,
            sortBy,
            sortAsc,
            query: searchPhrase,
            active: loadActive
        }
    });

    return res.data;
};

const getDepartmentById = async departmentId => {
    const res = await httpService.get(url.GET_DEPARTMENT_BY_ID(departmentId));

    return res.data;
};

const addDepartment = async requestData => {
    const data = await httpService.post(url.CREATE_DEPARTMENT(), requestData, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return data.data;
};

const updateDepartment = async (departmentId, requestData) => {
    const data = await httpService.patch(
        url.UPDATE_DEPARTMENT(departmentId),
        requestData,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
    return data.data;
};

export default {
    getAllDepartments,
    getDepartmentById,
    addDepartment,
    updateDepartment
};
