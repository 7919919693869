import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
    resetShowUpdateMessage,
    updateDiscountVisibility,
    deleteDiscount
} from 'redux/slices/discount';
import { CustomConfirmationModal } from 'shared/components';
import { successNotification } from 'shared/components/Notifications';

import { CustomTableWithFixedColumns } from '../../../../shared/components/CustomTableWithFixedColumns';

export const DiscountsTable = ({
    totalCount,
    defaultSort,
    handleCreateTableColumns,
    pageSize,
    loading,
    noDataText,
    hasPagination,
    sorted,
    handleSortUpdate,
    boundaryRef,
    permissions,
    handleOpenDiscountModal
}) => {
    const dispatch = useDispatch();
    const { showUpdateMessage, loadActive, discounts } = useSelector(
        state => state.discount
    );
    const [isVisibilityModalOpen, setIsVisibilityModalOpen] = useState(false);
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
        useState(false);
    const [selectedDiscount, setSelectedDiscount] = useState(null);

    useEffect(() => {
        if (showUpdateMessage) {
            successNotification('Successfully updated the Discount.');
            dispatch(resetShowUpdateMessage());
        }
    }, [showUpdateMessage]);

    const handleToggleVisibility = discountId => {
        setSelectedDiscount(discountId);
        setIsVisibilityModalOpen(true);
    };

    const handleVisibilityModalCancel = () => {
        setSelectedDiscount(null);
        setIsVisibilityModalOpen(false);
    };

    const handleToggleVisibilityConfirm = () => {
        dispatch(updateDiscountVisibility(selectedDiscount));
        setSelectedDiscount(null);
        setIsVisibilityModalOpen(false);
    };

    const handleOpenDelete = discount => {
        setIsDeleteConfirmationModalOpen(true);
        setSelectedDiscount(discount);
    };

    const handleDeleteDiscount = () => {
        dispatch(deleteDiscount(selectedDiscount.id));
        setIsDeleteConfirmationModalOpen(false);
    };

    return (
        <div className="table-wrapper">
            <CustomTableWithFixedColumns
                height="auto"
                total={totalCount}
                defaultSorted={defaultSort}
                columns={handleCreateTableColumns(
                    discounts,
                    handleOpenDiscountModal,
                    handleOpenDelete,
                    handleToggleVisibility,
                    permissions,
                    loadActive
                )}
                data={discounts}
                pageSize={pageSize}
                loading={loading}
                noDataText={noDataText}
                hasPagination={hasPagination}
                sorted={sorted}
                handleSortingChange={handleSortUpdate}
                sortable={true}
                resizable={false}
            />
            <section ref={boundaryRef} />
            {isVisibilityModalOpen && (
                <CustomConfirmationModal
                    visible={isVisibilityModalOpen}
                    setVisible={setIsVisibilityModalOpen}
                    closeCallback={handleVisibilityModalCancel}
                    handleConfirm={handleToggleVisibilityConfirm}
                    secondaryTitle={
                        'Are you sure you want to show/hide the discount?'
                    }
                />
            )}
            {isDeleteConfirmationModalOpen && (
                <CustomConfirmationModal
                    title={'Confirmation'}
                    visible={isDeleteConfirmationModalOpen}
                    setVisible={setIsDeleteConfirmationModalOpen}
                    contentText={`Are you sure you want to delete "${selectedDiscount.name}" from the system ?`}
                    handleConfirm={handleDeleteDiscount}
                    closeCallback={() => setSelectedDiscount(null)}
                    secondaryTitle=""
                />
            )}
        </div>
    );
};
