import React from 'react';

import { StyledActivityStatusPicker } from './styled/ActivityStatusPicker.styled';

export const ActivityStatusPicker = ({
    activeButtonOnClick,
    inactiveButtonOnClick,
    loadActive
}) => {
    const getClass = active => {
        return `inactive-button${active ? ' active' : ''} btn-normal`;
    };

    return (
        <StyledActivityStatusPicker style={{ width: '100%', flexGrow: '10' }}>
            <button
                data-testid="active-show-button"
                className={getClass(loadActive)}
                onClick={activeButtonOnClick}
            >
                Active
            </button>
            <button
                data-testid="inactive-show-button"
                className={getClass(!loadActive)}
                onClick={inactiveButtonOnClick}
            >
                Inactive
            </button>
        </StyledActivityStatusPicker>
    );
};
