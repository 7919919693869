import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const getAllLocations = async requestModel => {
    const { skip, take, sortBy, sortAsc, searchPhrase, loadActive } =
        requestModel;
    const res = await httpService.get(url.GET_ALL_LOCATIONS_PAGINATED(), {
        params: {
            skip,
            take,
            sortBy,
            sortAsc,
            query: searchPhrase,
            active: loadActive
        }
    });

    return res.data;
};

const getLocationById = async locationId => {
    const res = await httpService.get(url.GET_LOCATION_BY_ID(locationId));

    return res.data;
};

const addLocation = async requestData => {
    const data = await httpService.post(url.CREATE_LOCATION(), requestData, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return data.data;
};

const updateLocation = async (locationId, requestData) => {
    const data = await httpService.patch(
        url.UPDATE_LOCATION(locationId),
        requestData,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
    return data.data;
};

export default {
    getAllLocations,
    getLocationById,
    addLocation,
    updateLocation
};
