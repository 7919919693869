import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { errorNotification } from 'shared/components/Notifications';
import { Spinner } from 'shared/components/Spinner';

import ActionsSection from './BenefitReportTab/ActionsSection';
import TagsSection from './BenefitReportTab/TagsSection';
import StyledBenefitReportTab from './styled/BenefitReportTab.styled';
import { benefitReportActions } from '../../redux/slices/benefitReport';
import {
    dropdownActions,
    fetchBenefitReportsDropDowns
} from '../../redux/slices/dropdown';

const BenefitReportTab = props => {
    const dispatch = useDispatch();
    const { isLoadingBenefitReports, benefitReportsError } = useSelector(
        state => state.dropdown
    );

    useEffect(() => {
        dispatch(fetchBenefitReportsDropDowns());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(benefitReportActions.resetState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (benefitReportsError) {
            errorNotification(benefitReportsError, 5);
            dispatch(dropdownActions.clearBenefitsReports());
        }
    }, [benefitReportsError, dispatch]);

    return (
        <StyledBenefitReportTab>
            {isLoadingBenefitReports ? (
                <Spinner />
            ) : (
                <>
                    <ActionsSection />
                    {/* <TagsSection /> */}
                </>
            )}
        </StyledBenefitReportTab>
    );
};

export default BenefitReportTab;
