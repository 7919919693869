import styled from 'styled-components';

const StyledCustomInput = styled.div`
    .customInput {
        ${({ width }) => width || '500px'};
    }
    .customInput.inputMargin {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .customInput .input-label,
    .customInput .label-disabled {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 2px;
    }
    .customInput .label-disabled {
        color: var(--darkGray);
    }
    .customInput input {
        font-weight: normal;
    }
    .customInput .ant-input-affix-wrapper,
    .customInput .ant-input:not(.ant-input-affix-wrapper .ant-input),
    .customInput
        .ant-input-disabled:not(
            .ant-input-affix-wrapper-disabled .ant-input-disabled
        ) {
        height: 40px;
        border: 1px solid var(--gray);
        box-sizing: border-box;
        border-radius: 10px;
    }
    .ant-input-affix-wrapper > input.ant-input {
        color: var(--black);
    }
    .customInput .ant-input-affix-wrapper .ant-input-prefix {
        left: 20px;
        margin-right: 6px;
    }
    .hasPrefix input {
        padding-left: 10px !important;
    }
    .anticon svg {
        fill: var(--secondaryBlue);
    }
    input:disabled:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px #f5f5f5 inset; /* Change the color to your own background color */
    }
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px #fff inset; /* Change the color to your own background color */
    }

    input:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 50px #fff inset; /*your box-shadow*/
    }
    //when having error
    .error-input .ant-input-affix-wrapper {
        border-color: var(--red);
    }
    .error-input .ant-input-affix-wrapper-focused {
        -webkit-box-shadow: var(--shadowColorOnError);
        box-shadow: var(--shadowColorOnError);
    }
`;

export { StyledCustomInput };
