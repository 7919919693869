import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Footer from 'shared/components/layout/Footer';
import useInfiniteScroll from 'shared/hooks/useInfiniteScroll';

import ActionsSection from './Actions/ActionsSection';
import {
    PendingRequestModal,
    ApprovedRequestModal,
    RejectedRequestModal,
    DeliveredRequestModal
} from './RequestModal';
import { STATUSES_IDS } from './RequestModal/utils';
import { StyledTab } from './styled/Tab';
import Table from './Table';
import {
    fetchRequests,
    updateRequestsList,
    filterRequests
} from '../../redux/slices/request';
import useFilter from '../contexts/FilterContext';
import { formatFilters } from '../utils/formatFilters';

let searchPhraseCache = undefined;

const Tab = ({ defaultSort, tabName, requestStatusId, permissions }) => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rowInfo, setRowInfo] = useState({});
    const [searchPhrase, setSearchPhrase] = useState(searchPhraseCache);
    const { filters } = useFilter();
    const requests = useSelector(state => state.request.requests);
    const filteredCounts = useSelector(state => state.request.filteredCounts);
    const shouldRefetchRequests = useSelector(
        state => state.request.shouldRefetchRequests
    );
    const isLoadingRequests = useSelector(
        state => state.request.isLoadingRequests
    );
    const hasMore =
        !!requests[tabName].length &&
        requests[tabName].length < filteredCounts[tabName];
    const [sortParams, setSortParams] = useState(null);
    const params = {
        requestStatusId,
        take: 20,
        skip: 0,
        query: searchPhraseCache,
        ...formatFilters(filters)
    };
    const currentTotalRequestLoaded = useRef(20);

    const getSortObject = sortParams => {
        if (!sortParams) {
            return {};
        }

        return {
            sortBy:
                sortParams[0].id[0].toUpperCase() + sortParams[0].id.slice(1),
            sortAsc: sortParams[0].desc ? true : false
        };
    };

    useEffect(() => {
        currentTotalRequestLoaded.current = 20;
        dispatch(
            filterRequests(tabName, { ...params, ...getSortObject(sortParams) })
        );
    }, [filters, searchPhrase, sortParams]);

    useEffect(() => {
        if (shouldRefetchRequests) {
            dispatch(
                fetchRequests(tabName, {
                    ...params,
                    ...getSortObject(sortParams),
                    take: currentTotalRequestLoaded.current
                })
            );
        }
    }, [shouldRefetchRequests]);

    useEffect(() => {
        return () => (searchPhraseCache = undefined);
    }, []);

    const boundaryRef = useInfiniteScroll({
        onLoadMore: () => {
            dispatch(
                updateRequestsList(tabName, {
                    ...params,
                    ...getSortObject(sortParams),
                    take: 10,
                    skip: currentTotalRequestLoaded.current
                })
            );
            currentTotalRequestLoaded.current =
                currentTotalRequestLoaded.current + 10;
        },
        loading: isLoadingRequests,
        hasMore
    });

    const handleValueUpdate = (which, val) => {
        searchPhraseCache = val;
        setSearchPhrase(previous => {
            return { ...previous, [which]: val };
        });
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <StyledTab tabName={tabName}>
                <div className="container">
                    <ActionsSection
                        search={{ handleValueUpdate }}
                        searchPhrase={searchPhrase}
                        allowLettersOnly={true}
                        count={filteredCounts[tabName]}
                    />
                    <Table
                        permissions={permissions}
                        defaultSort={defaultSort}
                        data={requests[tabName]}
                        tabName={tabName}
                        pageSize={10}
                        loading={isLoadingRequests}
                        noDataText={'No Requests Found'}
                        handleSortingChange={setSortParams}
                        hasPagination={false}
                        sorted={sortParams ?? defaultSort}
                        boundaryRef={boundaryRef}
                        handleRowClick={rowInfo => {
                            setRowInfo(rowInfo);
                            setIsModalOpen(true);
                        }}
                    />
                </div>
            </StyledTab>
            {!hasMore && <Footer className={'special'} />}
            {isModalOpen && (
                <>
                    {rowInfo.benefitRequestStatusId ===
                        STATUSES_IDS.PENDING && (
                        <PendingRequestModal
                            isVisible={isModalOpen}
                            onClose={handleClose}
                            requestInfo={rowInfo}
                        />
                    )}
                    {rowInfo.benefitRequestStatusId ===
                        STATUSES_IDS.APPROVED && (
                        <ApprovedRequestModal
                            isVisible={isModalOpen}
                            onClose={handleClose}
                            requestInfo={rowInfo}
                        />
                    )}
                    {rowInfo.benefitRequestStatusId ===
                        STATUSES_IDS.REJECTED && (
                        <RejectedRequestModal
                            isVisible={isModalOpen}
                            onClose={handleClose}
                            requestInfo={rowInfo}
                        />
                    )}
                    {rowInfo.benefitRequestStatusId ===
                        STATUSES_IDS.DELIVERED && (
                        <DeliveredRequestModal
                            isVisible={isModalOpen}
                            onClose={handleClose}
                            requestInfo={rowInfo}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Tab;
