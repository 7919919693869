import { createSlice } from '@reduxjs/toolkit';

import { reduxInitialState } from '../../people/utils/Details/HistoricalData/historicalDataHelper';
import historyService from '../../shared/services/historicalService';

const initialState = {
    historicalItems: [],
    error: null,
    isLoading: false,
    isLoadingDelete: false
};

const { reducer: historyReducer, actions } = createSlice({
    name: 'history',
    initialState,
    reducers: {
        //Fetch historical data
        fetchHistoricalDataStart: state => {
            state.isLoading = true;
        },
        fetchHistoricalDataSuccess: (state, action) => {
            const { payload } = action;
            state.isLoading = false;
            state.error = null;
            state.historicalItems = payload;
        },
        fetchHistoricalDataFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        //Update person history
        updatePersonHistoryStart: state => {
            state.isLoading = true;
        },
        updatePersonHistorySuccess: (state, action) => {
            const { payload } = action;
            state.isLoading = false;
            state.error = null;
            state.historicalItems = payload;
        },
        updatePersonHistoryFailure: (state, action) => {
            const { payload } = action;
            state.isLoading = false;
            state.error = payload;
        },

        //Delete person history
        removePersonHistoryStart: state => {
            state.isLoadingDelete = true;
        },
        removePersonHistorySuccess: state => {
            state.isLoadingDelete = false;
            state.error = null;
        },
        removePersonHistoryFailure: (state, action) => {
            const { payload } = action;
            state.isLoadingDelete = false;
            state.error = payload;
        },

        //Update working period history
        updateWorkingPeriodHistoryStart: state => {
            state.isLoading = true;
        },
        updateWorkingPeriodHistorySuccess: (state, action) => {
            const { payload } = action;
            state.isLoading = false;
            state.error = null;
            state.historicalItems = payload;
        },
        updateWorkingPeriodHistoryFailure: (state, action) => {
            const { payload } = action;
            state.isLoading = false;
            state.error = payload;
        },

        //Delete working period history
        removeWorkingPeriodHistoryStart: state => {
            state.isLoadingDelete = true;
        },
        removeWorkingPeriodHistorySuccess: state => {
            state.isLoadingDelete = false;
            state.error = null;
        },
        removeWorkingPeriodHistoryFailure: (state, action) => {
            const { payload } = action;
            state.isLoadingDelete = false;
            state.error = payload;
        },

        //Update many histories
        updateManyHistoriesStart: state => {
            state.isLoading = true;
        },
        updateManyHistoriesSuccess: (state, action) => {
            const { payload } = action;
            state.isLoading = false;
            state.error = null;
            state.historicalItems = payload;
        },
        updateManyHistoriesFailure: (state, action) => {
            const { payload } = action;
            state.isLoading = false;
            state.error = payload;
        },

        //Reset state
        clearHistory: state => {
            state.historicalItems = [];
            state.isLoading = false;
            state.isLoadingDelete = false;
            state.error = null;
        }
    }
});

//Fetched historical data
const fetchHistoricalDataById = historicalState => {
    return async (dispatch, getState) => {
        const { isLoading } = getState();
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchHistoricalDataStart());
            const { data } = await historyService.getHistoricalData(
                historicalState
            );

            const reduxData = reduxInitialState(data);
            dispatch(actions.fetchHistoricalDataSuccess(reduxData));
        } catch (err) {
            dispatch(
                actions.fetchHistoricalDataFailure('Something went wrong')
            );
        }
    };
};

//Update person history
const updatePersonHistoryById = (
    personHistoryId,
    requestData,
    historicalState
) => {
    return async (dispatch, getState) => {
        const { isLoading } = getState();
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.updatePersonHistoryStart());
            await historyService.editPersonHistory(
                personHistoryId,
                requestData
            );
            const { data } = await historyService.getHistoricalData(
                historicalState
            );
            const reduxData = reduxInitialState(data);
            dispatch(actions.updatePersonHistorySuccess(reduxData));
        } catch (err) {
            dispatch(
                actions.updatePersonHistoryFailure('Something went wrong')
            );
        }
    };
};

//Delete person history
const deletePersonHistoryById = (personHistoryId, requestObj) => {
    return async (dispatch, getState) => {
        const { isLoadingDelete } = getState();
        if (isLoadingDelete) {
            return;
        }

        try {
            dispatch(actions.removePersonHistoryStart());
            await historyService.deletePersonHistory(
                personHistoryId,
                requestObj
            );
            dispatch(actions.removePersonHistorySuccess());
        } catch (err) {
            dispatch(
                actions.removePersonHistoryFailure('Something went wrong')
            );
        }
    };
};

//Update working period history
const updateWorkingPeriodHistoryById = (
    workingPeriodHistoryId,
    requestData,
    historicalState
) => {
    return async (dispatch, getState) => {
        const { isLoading } = getState();
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.updateWorkingPeriodHistoryStart());
            await historyService.editWorkingPeriodHistory(
                workingPeriodHistoryId,
                requestData
            );
            const { data } = await historyService.getHistoricalData(
                historicalState
            );
            const reduxData = reduxInitialState(data);
            dispatch(actions.updateWorkingPeriodHistorySuccess(reduxData));
        } catch (err) {
            dispatch(
                actions.updateWorkingPeriodHistoryFailure(
                    'Something went wrong'
                )
            );
        }
    };
};

//Delete working period history
const deleteWorkingPeriodHistoryById = (workingPeriodHistoryId, requestObj) => {
    return async (dispatch, getState) => {
        const { isLoadingDelete } = getState();
        if (isLoadingDelete) {
            return;
        }

        try {
            dispatch(actions.removeWorkingPeriodHistoryStart());
            await historyService.deleteWorkingPeriodHistory(
                workingPeriodHistoryId,
                requestObj
            );
            dispatch(actions.removeWorkingPeriodHistorySuccess());
        } catch (err) {
            dispatch(
                actions.removeWorkingPeriodHistoryFailure(
                    'Something went wrong'
                )
            );
        }
    };
};

//Update working period history
const updateManyHistories = (
    requestPersonData,
    requestWorkingPeriodData,
    historicalState
) => {
    return async (dispatch, getState) => {
        const { isLoading } = getState();
        if (isLoading) {
            return;
        }

        try {
            dispatch(actions.updateManyHistoriesStart());

            if (requestWorkingPeriodData.length > 0) {
                await historyService.editManyWorkingPeriodsHistory(
                    requestWorkingPeriodData
                );
            }
            if (requestPersonData.length > 0) {
                await historyService.editManyPersonHistory(requestPersonData);
            }

            const { data } = await historyService.getHistoricalData(
                historicalState
            );
            const reduxData = reduxInitialState(data);
            dispatch(actions.updateManyHistoriesSuccess(reduxData));
        } catch (err) {
            dispatch(
                actions.updateManyHistoriesFailure('Something went wrong')
            );
        }
    };
};

const resetHistoryState = () => {
    return dispatch => {
        dispatch(actions.clearHistory());
    };
};

export {
    historyReducer,
    actions as historyActions,
    fetchHistoricalDataById,
    updatePersonHistoryById,
    deletePersonHistoryById,
    updateWorkingPeriodHistoryById,
    deleteWorkingPeriodHistoryById,
    updateManyHistories,
    resetHistoryState
};
