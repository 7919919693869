import moment from 'moment';

export const formatFilters = ({
    entryDate,
    benefitType,
    requestType,
    coveredBy,
    workingTime,
    location
}) => {
    return {
        entryDate:
            entryDate.value && moment(entryDate.value).format('YYYY-MM-DD'),
        benefitTypeId: benefitType.value,
        requestTypeId: requestType.value,
        coveredById: coveredBy.value,
        workingTime: workingTime.value,
        location: location.value
    };
};
