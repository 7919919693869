import styled from 'styled-components';

const StyledRichText = styled.div`
    &.error {
        .ql-toolbar.ql-snow {
            border-color: var(--red);
            border-bottom-color: inherit;
        }
        .ql-toolbar.ql-snow + .ql-container.ql-snow {
            border-color: var(--red);
        }
        .counter {
            color: var(--red);
        }
    }
    .ql-editor {
        overflow-y: auto;
        word-break: break-word;
    }
    .ql-toolbar.ql-snow {
        border-radius: 10px 10px 0 0;
    }
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
        border-radius: 0 0 10px 10px;
    }
    .input-label {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 2px;
    }
    .counter {
        float: right;
        color: rgba(0, 0, 0, 0.45);
    }
`;

export default StyledRichText;
