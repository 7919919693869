export const validateUserOnSubmit = values => {
    const { personId, roleId } = values;

    let errors = {};

    if (!personId) {
        errors = { ...errors, personError: 'Person email is required' };
    }

    if (!roleId) {
        errors = { ...errors, roleError: 'Role is required' };
    }

    return errors;
};

export const canSubmitUserForm = (values, errors) => {
    if (
        values.personId &&
        !errors?.personErrors &&
        values.roleId &&
        !errors?.roleErrors
    ) {
        return true;
    }

    return false;
};
