import React from 'react';

const UploadFileInput = ({ inputRef, uploadFile }) => {
    return (
        <input
            data-testid="input-upload-picture"
            type="file"
            ref={inputRef}
            accept="image/*"
            style={{ display: 'none' }}
            onChange={e => {
                uploadFile(e.target.files?.[0]);
            }}
        />
    );
};

export { UploadFileInput };
