import React from 'react';

import StyledSubsetTabActions from './styled/SubsetTabActions.styled';
import SubsetTabDownload from './SubsetTabDownload';
import SubsetTabFilter from './SubsetTabFilter';
import SubsetTabSearch from './SubsetTabSearch';
import { LayoutButtons } from '../Table/LayoutButtons';

const SubsetTabActions = props => {
    const {
        totalCount,
        layOut,
        filter,
        search,
        download,
        allowLettersOnly,
        searchPhrase
    } = props;
    const { typeOfLayout, handleLayoutChange } = layOut;

    const tableInfoContent =
        totalCount === undefined ? '' : `${totalCount} results`;

    return (
        <StyledSubsetTabActions>
            <SubsetTabSearch
                search={search}
                allowLettersOnly={allowLettersOnly}
                searchPhrase={searchPhrase}
            />
            <div className="filter-icons-container">
                <div className="people-count-info">{tableInfoContent}</div>
                <SubsetTabDownload download={download} />
                <SubsetTabFilter filter={filter} />
                <LayoutButtons
                    activeLayout={typeOfLayout}
                    onLayoutChange={handleLayoutChange}
                />
            </div>
        </StyledSubsetTabActions>
    );
};

export default SubsetTabActions;
