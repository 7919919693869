import styled from 'styled-components';

const StyledPeriodPicker = styled.div`
    .period-picker-container {
        width: 240px;
        margin-top: 12px;
        margin-bottom: 14px;
    }
    .period-picker-container .label {
        font-weight: 600;
        padding-top: 2px;
    }
    .period-picker-container .input-label,
    .period-picker-container .label-disabled {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 2px;
    }
    .period-picker-container .label-disabled {
        color: var(--darkGray);
    }
    .period-picker-container input {
        font-weight: normal;
    }
    .period-picker-container input::placeholder {
        color: var(--navy) !important;
    }
    .period-picker-container .ant-picker {
        width: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border: 1px solid var(--gray);
        box-sizing: border-box;
        border-radius: 8px;
        padding: 10px 12px;
    }
    .anticon svg {
        fill: var(--mainBlue);
    }
    span.anticon.anticon-calendar {
        font-size: 18px;
        padding: 2px;
    }
    .ant-picker-clear {
        display: flex;
        align-items: center;
        margin-right: 23px;
    }
`;

export { StyledPeriodPicker };
