import React, { useState, useRef } from 'react';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { EditIcon } from 'shared/theme/assets/icons/EditIcon';

import StyledUploadImage from './styled/UploadImage.styled';

const UploadImage = ({
    onUpload,
    initialImage,
    maxSize,
    onDelete,
    externalError = '',
    imageText = 'Select Image',
    hideDeleteButton = false
}) => {
    const inputRef = useRef();
    const [image, setImage] = useState(null);
    const [error, setError] = useState('');

    const handleFileChange = e => {
        const file = e.target.files[0];

        if (file.type.match(/^image\//i)) {
            if (file.size <= maxSize) {
                setImage(URL.createObjectURL(file));
                onUpload(file);
                setError('');
            } else {
                file.value = null;
                setImage(null);
                setError('File size must be less than 5MB');
            }
        } else {
            file.value = null;
            setImage(null);
            setError('Only image files (png, jpg, jpeg) are allowed');
        }
    };

    const handleDelete = () => {
        setImage(null);
        onUpload(null);
        onDelete();
        inputRef.current.value = null;
    };

    return (
        <StyledUploadImage>
            {!image && !initialImage && (
                <button
                    type="button"
                    tabIndex="0"
                    onClick={() => inputRef.current.click()}
                    className={`upload-button${
                        externalError ? ' external-error' : ''
                    }`}
                >
                    <PlusOutlined />
                    <span>{imageText}</span>
                </button>
            )}
            <input
                ref={inputRef}
                type="file"
                id="image"
                accept=".png, .jpg, .jpeg"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            {(image || initialImage) && (
                <div className="image-group">
                    <div className="button-group">
                        <button
                            type="button"
                            className="edit-button"
                            onClick={() => inputRef.current.click()}
                        >
                            <EditIcon
                                strokeColor="var(--secondaryBlue)"
                                height={20}
                                width={20}
                            />
                        </button>
                        {!hideDeleteButton && (
                            <button
                                type="button"
                                className="delete-button"
                                onClick={handleDelete}
                            >
                                <DeleteOutlined />
                            </button>
                        )}
                    </div>
                    <img src={image || initialImage} alt="Uploaded Image" />
                </div>
            )}
            {!!error && <div className="error-message">{error}</div>}
        </StyledUploadImage>
    );
};

export { UploadImage };
