import { isEmpty } from 'lodash';

export const getColumnWidth = (data, headerText) => {
    const maxWidth = 500;
    const magicSpacing = 10;

    let maxNameLength;
    const longestName = Math.max(
        ...data.map(el => (!isEmpty(el.name) ? el.name.length : 0))
    );
    const longestBgName = Math.max(
        ...data.map(el => (!isEmpty(el.nameBg) ? el.nameBg.length : 0))
    );

    if (longestName > longestBgName) {
        maxNameLength = longestName;
    } else if (longestName < longestBgName) {
        maxNameLength = longestBgName;
    } else {
        maxNameLength = longestName;
    }

    const cellLength = Math.max(maxNameLength, headerText.length);
    const finalLength = Math.min(maxWidth, cellLength * magicSpacing) + 50;
    if (finalLength <= 140) {
        return 140;
    }
    return finalLength;
};

export const allPeopleBackgroundHelper = (state, rowInfo, column) => {
    const isSorted = state?.sorted[0]?.id === column.id;
    const hasLeft = rowInfo?.original?.hasLeft;

    //case when sorted column & left employee cell overwrap each other
    if (hasLeft && isSorted) {
        return { background: '#F0F3F6' };
    }

    //case when the employee has left and column is not sorted
    if (hasLeft && !isSorted) {
        return { background: '#F0F3F6' };
    }

    return {
        background: state?.sorted[0]?.id === column.id && '#f9fbfc'
    };
};

export const backgroundHelper = (state, rowInfo, column) => {
    return {
        background: state?.sorted[0]?.id === column.id && '#f9fbfc'
    };
};
