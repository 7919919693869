import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const getAllRoles = async requestModel => {
    const { skip, take, sortBy, sortAsc } = requestModel;
    const res = await httpService.get(url.GET_ALL_ROLES_PAGINATED(), {
        params: { skip, take, sortBy, sortAsc }
    });

    return res.data;
};

const getRoleById = async roleId => {
    const res = await httpService.get(url.GET_ROLE_BY_ID(roleId));

    return res.data;
};

const addRole = async requestData => {
    const data = await httpService.post(url.CREATE_ROLE(), {
        ...requestData
    });
    return data.data;
};

const updateRole = async (roleId, requestData) => {
    const data = await httpService.patch(url.UPDATE_ROLE(roleId), {
        ...requestData
    });

    return data.data;
};

const deleteRole = async roleId => {
    const data = await httpService.delete(url.DELETE_ROLE(roleId));

    return data.data;
};

export default { getAllRoles, getRoleById, addRole, updateRole, deleteRole };
