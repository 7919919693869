import { createSlice } from '@reduxjs/toolkit';
import roleService from 'admin/services/roleService';

const initialState = {
    take: 10,
    sortBy: 'Role',
    sortAsc: true,

    roles: [],
    rolesTotal: 0,
    totalCount: 0,

    isLoading: false,
    error: null,

    isLoadingCreate: false,
    showCreateMessage: false,

    isLoadingFetchSingle: false,
    roleSingleData: null,
    errorFetchSingle: null,

    isLoadingEdit: false,
    editError: null,
    showUpdateMessage: false,

    isLoadingDelete: false,
    deleteError: null,
    showDeleteMessage: false
};

const { reducer: roleReducer, actions } = createSlice({
    name: 'role',
    initialState,
    reducers: {
        // fetch roles
        fetchRolesStart: state => {
            state.isLoading = true;
        },
        fetchRolesSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.isLoading = false;
            state.error = null;
            state.roles = [...state.roles, ...data];
            state.rolesTotal = state.rolesTotal + data.length;
            state.totalCount = totalCount;
        },
        fetchRolesFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        changeSortingState: (state, action) => {
            const { sortBy, sortAsc } = action.payload;

            state.sortBy = sortBy;
            state.sortAsc = sortAsc;
        },

        clearState: state => {
            state.take = 10;
            state.roles = [];
            state.rolesTotal = 0;
            state.totalCount = 0;
            state.isLoading = false;
            state.error = null;
        },

        resetSorting: state => {
            state.sortAsc = true;
            state.sortBy = 'Role';
            state.take = 10;
            state.roles = [];
            state.rolesTotal = 0;
            state.totalCount = 0;
            state.isLoading = false;
            state.error = null;
        },

        // fetch single role
        fetchSingleRoleStart: state => {
            state.isLoadingFetchSingle = true;
        },
        fetchSingleRoleSuccess: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.errorFetchSingle = null;
            state.roleSingleData = action.payload;
        },
        fetchSingleRoleFailure: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.errorFetchSingle = action.payload;
        },

        // create a role with permissions
        createRoleStart: state => {
            state.isLoading = true;
            state.isLoadingCreate = true;
        },
        createRoleSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.isLoading = false;
            state.isLoadingCreate = false;
            state.showCreateMessage = true;
            state.error = null;

            state.roles = [...data];
            state.rolesTotal = data.length;
            state.totalCount = totalCount;
        },
        createRoleFailure: (state, action) => {
            state.isLoading = false;
            state.isLoadingCreate = false;
            state.error = action.payload;
        },

        // update role
        updateRoleStart: state => {
            state.isLoading = true;
            state.isLoadingEdit = true;
            state.editError = null;
        },
        updateRoleSuccess: (state, action) => {
            state.isLoadingEdit = false;
            state.isLoading = false;
            state.editError = null;
            state.showUpdateMessage = true;
            const { data, totalCount } = action.payload;

            state.roles = [...data];
            state.rolesTotal = data.length;
            state.totalCount = totalCount;
            state.error = null;
        },
        updateRoleFailure: (state, action) => {
            state.isLoadingEdit = false;
            state.isLoading = false;
            state.editError = action.payload;
        },

        // delete role
        deleteRoleStart: state => {
            state.isLoadingDelete = true;
            state.isLoading = true;
        },
        deleteRoleSuccess: (state, action) => {
            state.isLoadingDelete = false;
            state.isLoading = false;
            state.deleteError = null;
            state.showDeleteMessage = true;
            const { data, totalCount } = action.payload;

            state.roles = [...data];
            state.rolesTotal = data.length;
            state.totalCount = totalCount;
            state.error = null;
        },
        deleteRoleFailure: (state, action) => {
            state.isLoadingEdit = false;
            state.isLoading = false;
            state.deleteError = action.payload;
        },

        resetCreateMessage: state => {
            state.showCreateMessage = false;
        },

        resetUpdateMessage: state => {
            state.showUpdateMessage = false;
        },

        resetDeleteMessage: state => {
            state.showDeleteMessage = false;
        },

        resetSingleRole: state => {
            state.isLoadingFetchSingle = false;
            state.roleSingleData = null;
            state.errorFetchSingle = null;
        }
    }
});

const fetchRoles = isScrolled => {
    return async (dispatch, getState) => {
        const rolesState = getState().role;

        if (rolesState.isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchRolesStart());

            let skip = rolesState.rolesTotal;
            let take = rolesState.take;

            if (!isScrolled) {
                skip = 0;
                take = 10;
                dispatch(actions.clearState());
            }

            const { sortAsc, sortBy } = rolesState;

            const response = await roleService.getAllRoles({
                skip,
                take,
                sortBy,
                sortAsc
            });

            const {
                data,
                metadata: { totalCount }
            } = response;

            dispatch(actions.fetchRolesSuccess({ data, totalCount }));
        } catch (err) {
            dispatch(actions.fetchRolesFailure(err));
        }
    };
};

const changeSorting = value => {
    const { id, desc } = value[0];
    const sortBy = id.charAt(0).toUpperCase() + id.slice(1);
    const sortAsc = sortBy === 'Role' && desc === false ? true : !desc;
    return async dispatch => {
        dispatch(actions.changeSortingState({ sortBy, sortAsc }));
    };
};

const clearState = () => {
    return dispatch => {
        dispatch(actions.clearState());
    };
};

const resetSorting = () => {
    return dispatch => {
        dispatch(actions.resetSorting());
    };
};

const fetchSingleRole = roleId => {
    return async (dispatch, getState) => {
        if (getState().role.isLoadingFetchSingle) {
            return;
        }

        try {
            dispatch(actions.fetchSingleRoleStart());
            const data = await roleService.getRoleById(roleId);
            dispatch(actions.fetchSingleRoleSuccess(data.data));
        } catch (err) {
            dispatch(actions.fetchSingleRoleFailure('Something went wrong.'));
        }
    };
};

const createRole = requestData => {
    return async (dispatch, getState) => {
        if (getState().role.isLoadingCreate) {
            return;
        }

        try {
            dispatch(actions.createRoleStart());
            await roleService.addRole(requestData);
            const skip = 0;
            const take = getState().role.take;
            const resp = await roleService.getAllRoles({ skip, take }, true);
            const {
                data,
                metadata: { totalCount }
            } = resp;
            dispatch(actions.createRoleSuccess({ data, totalCount }));
        } catch (error) {
            dispatch(actions.createRoleFailure('Something went wrong.'));
        }
    };
};

const updateRole = (roleId, requestData) => {
    return async (dispatch, getState) => {
        if (getState().role.isLoadingEdit) {
            return;
        }

        try {
            dispatch(actions.updateRoleStart());
            await roleService.updateRole(roleId, requestData);

            const skip = 0;
            const take = getState().role.take;
            const response = await roleService.getAllRoles(
                { skip, take },
                true
            );
            const {
                data,
                metadata: { totalCount }
            } = response;

            dispatch(actions.updateRoleSuccess({ data, totalCount }));
        } catch (err) {
            dispatch(actions.updateRoleFailure('Something went wrong.'));
        }
    };
};

const deleteRole = roleId => {
    return async (dispatch, getState) => {
        if (getState().role.isLoadingDelete) {
            return;
        }
        dispatch(actions.deleteRoleStart());

        try {
            const response = await roleService.deleteRole(roleId);

            if (response) {
                const skip = 0;
                const take = getState().role.take;
                const resp = await roleService.getAllRoles(
                    { skip, take },
                    true
                );
                const {
                    data,
                    metadata: { totalCount }
                } = resp;
                dispatch(actions.deleteRoleSuccess({ data, totalCount }));
            } else {
                dispatch(actions.deleteRoleFailure('Something went wrong'));
            }
        } catch (err) {
            dispatch(actions.deleteRoleFailure('Something went wrong'));
        }
    };
};

const resetShowCreateMessage = () => {
    return dispatch => {
        dispatch(actions.resetCreateMessage());
    };
};

const resetShowUpdateMessage = () => {
    return dispatch => {
        dispatch(actions.resetUpdateMessage());
    };
};

const resetShowDeleteMessage = () => {
    return dispatch => {
        dispatch(actions.resetDeleteMessage());
    };
};

const resetSingleRole = () => {
    return dispatch => {
        dispatch(actions.resetSingleRole());
    };
};

export {
    roleReducer,
    actions as roleActions,
    fetchRoles,
    fetchSingleRole,
    clearState,
    resetSorting,
    changeSorting,
    createRole,
    updateRole,
    deleteRole,
    resetShowCreateMessage,
    resetShowUpdateMessage,
    resetShowDeleteMessage,
    resetSingleRole
};
