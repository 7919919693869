import { createSlice } from '@reduxjs/toolkit';
import locationService from 'admin/services/locationService';
import { errorNotification } from 'shared/components';

const initialState = {
    take: 10,
    sortBy: 'EligibleForPeople',
    searchPhrase: null,
    sortAsc: false,

    locations: [],
    currentTotal: 0,
    locationsTotalCount: 0,

    isLoading: false,
    error: null,
    isEditMode: false,
    isAddedSuccessfully: undefined,
    isEditedSuccessfully: undefined,
    isLoadingFetchSingle: false,
    loadActive: true,

    singleLocationData: {
        id: 0,
        name: '',
        eligibleForPeople: false,
        eligibleForDiscounts: false,
        eligibleForOffers: false
    }
};

const { reducer: locationReducer, actions } = createSlice({
    name: 'location',
    initialState,
    reducers: {
        // Fetch locations
        changeLoadActive: (state, action) => {
            state.loadActive = action.payload;
        },
        updateSearchPhrase: (state, action) => {
            state.searchPhrase = action.payload;
        },
        resetNotifications: state => {
            state.isAddedSuccessfully = undefined;
            state.isEditedSuccessfully = undefined;
        },
        fetchLocationsStart: state => {
            state.isLoading = true;
        },
        fetchLocationsSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.isLoading = false;
            state.error = null;
            state.locations = [...state.locations, ...data];
            state.currentTotal = state.currentTotal + data.length;
            state.locationsTotalCount = totalCount;
        },
        fetchLocationsFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        changeOrderState: (state, action) => {
            const {
                payload: { sortBy, sortAsc }
            } = action;
            state.sortBy = sortBy;
            state.sortAsc = sortAsc;
        },

        updateLocationSuccess: state => {
            state.isLoadingEdit = false;
            state.isLoading = false;
            state.isEditedSuccessfully = true;
        },
        updateLocationFailure: state => {
            state.isLoadingEdit = false;
            state.isLoading = false;
        },

        // clear state
        cleanState: state => {
            state.take = 10;
            state.locations = [];
            state.currentTotal = 0;
            state.locationsTotalCount = 0;
            state.isLoading = false;
            state.error = null;
        },

        resetFilters: state => {
            state.skip = 0;
            state.take = 10;
            state.sortBy = 'EligibleForPeople';
            state.sortAsc = false;
            state.searchPhrase = '';
            state.locations = [];
            state.currentTotal = 0;
            state.locationsTotalCount = 0;
            state.isLoading = false;
            state.error = null;
        },

        updateLocationStart: state => {
            state.isLoading = true;
            state.editError = null;
            state.isLoadingEdit = true;
        },

        resetShowUpdate: state => {
            state.showUpdateMessage = false;
        },
        createLocationStart: state => {
            state.isLoading = true;
            state.isLoadingCreate = true;
        },
        createLocationFailure: (state, action) => {
            state.isLoading = false;
            state.isLoadingCreate = false;
            state.error = action.payload;
        },
        createLocationSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.isLoading = false;
            state.isLoadingCreate = false;
            state.showCreateMessage = true;
            state.error = null;

            state.locations = [...data];
            state.currentTotal = data.length;
            state.locationsTotalCount = totalCount;
            state.isAddedSuccessfully = true;
        },

        resetLocationModal: state => {
            state.singleLocationData = initialState.singleLocationData;
            state.isEditMode = false;
        },

        fetchSingleLocationStart: state => {
            state.isLoadingFetchSingle = true;
            state.isEditMode = true;
        },

        fetchSingleLocationSuccess: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.error = null;
            state.singleLocationData = action.payload;
        },

        fetchSingleLocationFailure: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.error = action.payload;
        },
        reloadLocationsSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.locations = data;
            state.currentTotal = data.length;
            state.locationsTotalCount = totalCount;
        }
    }
});

const fetchLocations = isScrolled => {
    return async (dispatch, getState) => {
        if (getState().location.isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchLocationsStart());
            let skip = getState().location.currentTotal;
            let take = getState().location.take;

            if (!isScrolled) {
                skip = 0;
                take = 10;
                dispatch(actions.cleanState());
            }

            const { sortAsc, sortBy, searchPhrase, loadActive } =
                getState().location;
            const resp = await locationService.getAllLocations({
                skip,
                take,
                sortBy,
                sortAsc,
                searchPhrase,
                loadActive
            });
            const totalCount = resp.metadata.totalCount;
            const data = resp.data;
            dispatch(actions.fetchLocationsSuccess({ data, totalCount }));
        } catch (err) {
            dispatch(actions.fetchLocationsFailure(err));
        }
    };
};

const changeOrder = orderValue => {
    const { id, desc } = orderValue[0];
    const sortBy = id.charAt(0).toUpperCase() + id.slice(1);
    const sortAsc = !desc;
    return async dispatch => {
        dispatch(actions.changeOrderState({ sortBy, sortAsc }));
    };
};

const resetLocationState = () => {
    return dispatch => {
        dispatch(actions.cleanState());
    };
};

const resetFilters = () => {
    return dispatch => {
        dispatch(actions.resetFilters());
    };
};

const resetLocation = () => {
    return dispatch => {
        dispatch(actions.resetLocationModal());
    };
};

const resetShowUpdateMessage = () => {
    return dispatch => {
        dispatch(actions.resetShowUpdate());
    };
};

const fetchSingleLocation = locationId => {
    return async (dispatch, getState) => {
        if (getState().role.isLoadingFetchSingle) {
            return;
        }

        try {
            dispatch(actions.fetchSingleLocationStart());
            const data = await locationService.getLocationById(locationId);
            dispatch(actions.fetchSingleLocationSuccess(data.data));
        } catch (err) {
            dispatch(
                actions.fetchSingleLocationFailure('Something went wrong.')
            );
        }
    };
};

const createLocation = requestData => {
    return async (dispatch, getState) => {
        if (getState().location.isLoadingCreate) {
            return;
        }

        try {
            dispatch(actions.createLocationStart());
            await locationService.addLocation(requestData);
            const skip = 0;
            const { take, sortBy, sortAsc, searchPhrase, loadActive } =
                getState().location;
            const resp = await locationService.getAllLocations({
                skip,
                take,
                sortBy,
                sortAsc,
                searchPhrase,
                loadActive
            });
            const {
                data,
                metadata: { totalCount }
            } = resp;
            dispatch(actions.createLocationSuccess({ data, totalCount }));
        } catch (error) {
            const errorMessage =
                error.response?.data?.messages?.[0] ?? 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.createLocationFailure(errorMessage));
        }
    };
};

const reloadLocations = async (dispatch, getState) => {
    const { sortAsc, sortBy, searchPhrase, loadActive } = getState().location;

    const skip = 0;
    const take = 10;

    const resp = await locationService.getAllLocations({
        skip,
        take,
        sortBy,
        sortAsc,
        searchPhrase,
        loadActive
    });
    const {
        data,
        metadata: { totalCount }
    } = resp;
    dispatch(actions.reloadLocationsSuccess({ data, totalCount }));
};

const updateLocation = (locationId, locationData) => {
    return async (dispatch, getState) => {
        try {
            dispatch(actions.updateLocationStart());
            await locationService.updateLocation(locationId, locationData);
            await reloadLocations(dispatch, getState);
            await resetFilters();
            dispatch(actions.updateLocationSuccess());
            dispatch(actions.resetLocationModal());
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] ?? 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.updateLocationFailure(errorMessage));
        }
    };
};

const resetNotificationState = () => {
    return async dispatch => dispatch(actions.resetNotifications());
};

const updateSearchPhrase = searchPhrase => {
    return async dispatch => dispatch(actions.updateSearchPhrase(searchPhrase));
};

const changeActiveState = loadActive => {
    return async dispatch => dispatch(actions.changeLoadActive(loadActive));
};

export {
    locationReducer,
    actions as locationActions,
    updateSearchPhrase,
    fetchLocations,
    changeOrder,
    resetLocationState,
    resetFilters,
    resetLocation,
    resetShowUpdateMessage,
    fetchSingleLocation,
    createLocation,
    updateLocation,
    resetNotificationState,
    changeActiveState
};
