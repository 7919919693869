import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const postOnLeave = async workingPeriodId => {
    const { data } = await httpService.post(
        url.CREATE_ON_LEAVE(workingPeriodId)
    );
    return data;
};

const updateOnLeave = async (onLeavePeriodId, requestData) => {
    const { data } = await httpService.patch(
        url.UPDATE_ON_LEAVE(onLeavePeriodId),
        requestData
    );
    return data;
};

const deleteOnLeave = async onLeavePeriodId => {
    const { data } = await httpService.delete(
        url.DELETE_ON_LEAVE(onLeavePeriodId)
    );
    return data;
};

export default {
    postOnLeave,
    updateOnLeave,
    deleteOnLeave
};
