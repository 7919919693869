import styled from 'styled-components';

const StyledEmployeeInfoTabs = styled.div`
    .employee-info-tabs {
        width: 100%;
    }
    .employee-info-tabs .rc-tabs-top .rc-tabs-tab {
        margin-right: 10px;
    }
    .employee-info-tabs .rc-tabs-top {
        border: none;
        padding: 0px 10px 0px 0px;
    }
    .employee-info-tabs .rc-tabs-nav-wrap {
        font-size: 16px;
        line-height: 20px;
    }
    .employee-info-tabs .rc-tabs-tabpane {
        padding: 17px 25px 23px 25px;
        background: #ffffff;
        box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.06);
        border-radius: 0px 0px 10px 10px;
    }
    .employee-info-tabs .rc-tabs-bar {
        margin: 0 15px;
    }
    .employee-info-tabs .rc-tabs-content.rc-tabs-content-animated {
        transform: none !important;
        padding: 0px 0px 20px 15px;
        width: 100%;
    }
    .employee-info-tabs .rc-tabs-tabpane.rc-tabs-tabpane-inactive {
        display: none;
    }
    .employee-info-tabs .rc-tabs-tab-active.rc-tabs-tab {
        font-family: 'Source-Sans-Pro';
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #1c84fe;
        background: rgba(28, 132, 254, 0.12);
        border-radius: 8px 8px 0px 0px;
    }
    @media screen and (max-width: 1200px) {
        .employee-info-tabs {
            width: 100%;
        }
    }
    @media screen and (max-width: 1024px) {
        .employee-info-tabs {
            width: 100%;
        }
        .employee-info-tabs .rc-tabs-content.rc-tabs-content-animated {
        }
    }
    @media screen and (max-width: 768px) {
        .employee-info-tabs {
            width: 100%;
        }
        .employee-info-tabs .rc-tabs-content.rc-tabs-content-animated {
        }
    }
    @media screen and (max-width: 425px) {
        .employee-info-tabs {
            width: 340px;
        }
        .employee-info-tabs .rc-tabs-content.rc-tabs-content-animated {
            width: 92%;
        }
    }
`;

export { StyledEmployeeInfoTabs };
