import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { StyledNormalView } from './styled/NormalView.styled';
import {
    deletePersonHistoryById,
    deleteWorkingPeriodHistoryById
} from '../../../redux/slices/history';
import { ThreeDots } from '../../theme/assets/icons/ThreeDots';
import { CustomConfirmationModal } from '../ConfirmationModal';

const NormalView = ({
    itemIndex,
    handleOptionsClick,
    optionsDivVisible,
    handleChangeView,
    normalViewObjectData,
    onDeleteCallback,
    setItemsForEdit
}) => {
    const dispatch = useDispatch();
    const { isLoadingDelete } = useSelector(state => state.history);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [isInside, setIsInside] = useState(false);
    const [deleteCalled, setDeleteCalled] = useState(false);

    useEffect(() => {
        const handleMouseOut = e => {
            if (optionsDivVisible && !isInside) {
                handleOptionsClick();
            }
        };
        document.addEventListener('click', handleMouseOut);
        return () => {
            document.removeEventListener('click', handleMouseOut);
        };
    }, [optionsDivVisible, isInside]);

    useEffect(() => {
        if (deleteCalled && !isLoadingDelete) {
            onDeleteCallback();
        }
    }, [isLoadingDelete, deleteCalled]);

    const {
        changedPropertyName,
        forPeriod,
        oldValue,
        newValue,
        personHistoryId,
        personWorkingPeriodHistoryId,
        changedDate,
        initialPropName,
        deleteNewValue,
        deleteOldValue
    } = normalViewObjectData;

    const isDateInvolved =
        changedPropertyName.includes('End Date') ||
        changedPropertyName.includes('Profile Creation') ||
        changedPropertyName.includes('On Leave');

    const handleMouseOver = e => {
        if (e.target) {
            setIsInside(true);
        }
    };

    const handleMouseLeave = e => {
        if (e.target) {
            setIsInside(false);
        }
    };

    const handleConfirmationModal = () => {
        setOpenConfirmation(!openConfirmation);
    };

    const handleDelete = () => {
        const requestObj = {
            propertyName: initialPropName,
            oldValue: deleteOldValue,
            newValue: deleteNewValue
        };
        if (personHistoryId !== null && personHistoryId !== undefined) {
            dispatch(deletePersonHistoryById(personHistoryId, requestObj));
        } else {
            dispatch(
                deleteWorkingPeriodHistoryById(
                    personWorkingPeriodHistoryId,
                    requestObj
                )
            );
        }
        setDeleteCalled(true);
        handleConfirmationModal();
    };

    const handleSelect = el => {
        const isSelected = el.target.checked;
        setItemsForEdit(prev => {
            let temp = [...prev];
            let targetItem = temp[itemIndex];
            targetItem = { ...targetItem, isSelected: isSelected };
            temp[itemIndex] = targetItem;
            return temp;
        });
    };

    return (
        <StyledNormalView data-testid="historical-change-item">
            <div className="action-buttons">
                <div className="flex">
                    {!isDateInvolved && (
                        <div className="item-select">
                            <input
                                type="checkbox"
                                data-testid="select-historical-change"
                                onClick={handleSelect}
                            />
                        </div>
                    )}
                    <div className="flex">
                        <div className="vertical-timeline-element-title">
                            {changedPropertyName}
                        </div>
                    </div>
                </div>
                <div
                    data-testid="options-section"
                    className="options-section"
                    onClick={handleOptionsClick}
                >
                    <ThreeDots />
                </div>
                {optionsDivVisible && (
                    <div className="left-box">
                        <div
                            data-testid="left-box-section"
                            className="options"
                            onMouseOver={handleMouseOver}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div
                                data-testid="historical-change-edit"
                                onClick={() => {
                                    handleChangeView();
                                    handleOptionsClick();
                                }}
                                className="action-btn"
                            >
                                {isDateInvolved ? 'View' : 'Edit'}
                            </div>
                            {!isDateInvolved && (
                                <div
                                    data-testid="historical-change-delete"
                                    onClick={handleConfirmationModal}
                                    className="action-btn"
                                >
                                    Delete
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="section-splitter"></div>
            <div className="vertical-timeline-element-subtitle">
                {changedPropertyName === 'Profile Creation' ? (
                    <>
                        Working period started <strong>{newValue}</strong>
                    </>
                ) : !isEmpty(oldValue) && isEmpty(newValue) ? (
                    <>
                        Deleted the old value <strong>{oldValue}</strong>
                    </>
                ) : isEmpty(oldValue) && !isEmpty(newValue) ? (
                    <>
                        Added a new value <strong>{newValue}</strong>
                    </>
                ) : !isEmpty(oldValue) && !isEmpty(newValue) ? (
                    <>
                        Changed from <strong>{oldValue}</strong> to{' '}
                        <strong>{newValue}</strong>
                    </>
                ) : changedPropertyName === 'On Leave' ? (
                    <div className="on-leave">
                        <div>
                            On leave period started{' '}
                            <strong>{forPeriod.start}</strong>
                        </div>
                        {forPeriod.end && (
                            <div>
                                On leave period ended{' '}
                                <strong>{forPeriod.end}</strong>
                            </div>
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <div className="working-period">
                {moment(changedDate).format('MM/DD/YYYY')}
            </div>
            {openConfirmation && (
                <CustomConfirmationModal
                    visible={openConfirmation}
                    setVisible={setOpenConfirmation}
                    title={'Confirmation'}
                    contentText={`You are going to delete this record.`}
                    handleConfirm={handleDelete}
                />
            )}
        </StyledNormalView>
    );
};

export { NormalView };
