import styled from 'styled-components';

const StyledCustomSelect = styled.div`
    label {
        display: block;
    }

    .custom-select-div {
        margin-top: 12px;
        min-width: 240px;
        margin-bottom: 14px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        width: 240px;
        height: 40px;
        background: var(--primaryWhite);
        border: 1px solid var(--gray);
        box-sizing: border-box;
        border-radius: 8px;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        color: var(--black);
        background: #f5f5f5;
    }
    .ant-select-selection-search-input {
        width: 226px !important;
    }
    .custom-select-div .custom-select-label {
        font-family: 'Source-Sans-Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .ant-select-selection-placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        font-family: 'Source-Sans-Pro';
        margin-top: 4px;
        color: var(--navi60);
    }
    .ant-select-selection-item {
        font-family: 'Source-Sans-Pro';
        margin-top: 4px;
    }
    .ant-select-item-option {
        font-family: 'Source-Sans-Pro';
        font-size: 14px;
    }
    .ant-select-arrow {
        top: 50%;
        right: 12px;
    }
    .ant-select-arrow svg rect {
        fill: none;
    }
    .ant-select-selection-search {
        display: flex;
        align-items: center;
    }
    .ant-select-arrow .anticon:not(.ant-select-suffix) {
        pointer-events: none;
    }
    .ant-select-clear {
        display: flex;
        align-items: center;
        margin-right: 26px;
    }
    .anticon > svg {
        fill: var(--secondaryBlue);
    }
    .error-input
        .ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border-color: var(--red) !important;
    }
`;

export { StyledCustomSelect };
